<kui-modal #modal
    [title]="data.title || ('DIALOG.ERROR' | translate)"
    [actions]="modalActions"
    (onClose)="detachModal()">
    <div class="p-4">
        <div class="d-flex flex-row pb-4">
            <div>
                <kui-icon size="xl"
                    name="exclamation-triangle"
                    class="text-primary"></kui-icon>
            </div>
            <div class="flex-1 pl-3">
                {{ data.message }}
                <div *ngIf="data.error" class="mt-2">
                    <strong>{{ data.error.name }}</strong>: {{ data.error.message }}
                </div>
            </div>
        </div>
    </div>
</kui-modal>