import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { LayerClickEvent } from './map.component';


@Injectable({
    providedIn: 'root',
})
export class MapEventsService {
    private layerClickSubject = new Subject<LayerClickEvent>();
    layerClick$: Observable<LayerClickEvent> = this.layerClickSubject.asObservable();

    private layerDblClickSubject = new Subject<LayerClickEvent>();
    layerDblClick$: Observable<LayerClickEvent> = this.layerDblClickSubject.asObservable();

    constructor() {
    }

    emitLayerEvent(type: 'click' | 'dblclick', event: LayerClickEvent) {
        switch (type) {
            case 'click':
                this.layerClickSubject.next(event);
                break;
            case 'dblclick':
                this.layerDblClickSubject.next(event);
                break;
        }
    }
}
