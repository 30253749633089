import { CommonModule } from '@angular/common';
import { Component, OnInit, Signal, computed, input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TelemetryResponse } from '@key-telematics/fleet-api-client';
import { TranslateModule } from '@ngx-translate/core';
import { KuiIconModule } from 'app/key-ui';
import { MeasurementUnitsService } from 'app/services';
import { MapTelemetry, MapTripService } from 'app/shared/components';
import { SelectionItem } from 'app/shared/components/selection/selection.model';
import { PipeModule } from 'app/shared/pipes/pipe.module';
import { DirectionalIconPipe } from '../pipes/directional-icon.pipe';
import { EventDisplayPipe } from '../pipes/event-display.pipe';

@Component({
    selector: 'key-telemetry-information',
    templateUrl: 'telemetry-information.component.html',
    styleUrls: ['telemetry-information.component.scss'],
    imports: [CommonModule, TranslateModule, DirectionalIconPipe, KuiIconModule, EventDisplayPipe, PipeModule],
    standalone: true,
})
export class TelemetryInformationComponent implements OnInit {

    asset = input.required<SelectionItem>();
    telemetry = input.required<MapTelemetry>();

    public roadSpeed: Signal<number> = computed(() => {
        if (this.telemetry() && this.telemetry().spd && this.telemetry().spd.rd) {
            return this.units.fromBackend('speed', this.telemetry().spd.un === 0 ? this.telemetry().spd.rd : this.telemetry().spd.rd * 1.609344, 0);
        }
        if (this.telemetry() && this.telemetry().spd && this.telemetry().spd.md) {
            return this.units.fromBackend('speed', this.telemetry().spd.un === 0 ? this.telemetry().spd.md : this.telemetry().spd.md * 1.609344, 0);
        }
    });

    constructor(
        private tripService: MapTripService,
        private sanitizer: DomSanitizer,
        private units: MeasurementUnitsService
    ) { }

    ngOnInit() {}

    getMarkerIcon(telemetry: TelemetryResponse) {
        const color = this.asset().color ?? 'blue';
        return this.sanitizer.bypassSecurityTrustHtml(this.tripService.getMarkerIcon(telemetry, color, 35).html);
    }

}
