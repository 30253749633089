import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';

export interface ReportListItem {
    id: string;
    name: string;
    description: string;
    type: string;
    tags: string[];
    starred?: boolean;
    template?: boolean;
    muted: boolean;
}

@Component({
    selector: 'key-report-list-item',
    templateUrl: './report-item.component.html',
    styleUrls: ['report-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportListItemComponent {


    @Input() item: ReportListItem;
    @Input() selected = false;
    @Input() starred = false;

    @Output() onSelect = new EventEmitter<ReportListItem>();
    @Output() onStarred = new EventEmitter<ReportListItem>();
    
}
