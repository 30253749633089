import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeModule, TreeDraggedElement } from '@ali-hm/angular-tree-component';
import { KuiTreeSelectComponent } from './tree-select.component';
import { KuiLoaderModule } from '../loader/loader.module';
import { KuiIconModule } from '../icon';

@NgModule({
    imports: [CommonModule, TreeModule, KuiLoaderModule, KuiIconModule],
    exports: [KuiTreeSelectComponent],
    declarations: [KuiTreeSelectComponent],
    providers: [TreeDraggedElement],
})
export class KuiTreeSelectModule {}
