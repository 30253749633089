import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Point } from '../../../map.component';
import { MapOptionService } from '../../map-option.service';
import { ZoneEditorService } from '../../services';
import { MapOptionComponent } from '../map-option.component';



@Component({
    styleUrls: ['./map-option-simple-zone-editor.component.scss'],
    templateUrl: './map-option-simple-zone-editor.component.html',
})
export class MapOptionSimpleZoneEditorComponent implements MapOptionComponent<any>, OnInit, OnDestroy {

    static ID = 'map-option-simple-zone-editor-component';
    static ICON = 'draw-polygon';

    private destroyed: Subject<void> = new Subject();

    data: any;
    latestPolygon: Point[] = [];

    constructor(
        private editorService: ZoneEditorService,
        private mapOptionService: MapOptionService,
    ) { }

    ngOnInit(): void {

        this.editorService.fireEditEvent({
            action: 'zoneedit',
        });
        this.editorService.polygonEdit$.pipe(
            takeUntil(this.destroyed)
        ).subscribe(res => {
            this.latestPolygon = res.points;
        })
    }

    ngOnDestroy(): void {
        this.destroyed.next();
        this.editorService.cancelMapEditing();
    }

    saveMapEdits() {
        if (this.latestPolygon.length > 0) {
            this.editorService.completeMapEdits();
            this.latestPolygon = [];
            this.mapOptionService.back();
        }
    }

    removeLastPoint() {
        this.editorService.removeLastPoint();
    }

    finishDrawing() {
        this.editorService.autoCompleteGeometry();
    }

    cancel() {
        this.mapOptionService.back();
    }
}