import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { KuiModule } from 'app/key-ui';

import { KeyListingComponent } from './listing.component';
import { KeyListingTitleComponent } from './listing-title/listing-title.component';
import { KeyListingDescriptionComponent } from './listing-description/listing-description.component';
import { KeyListingStatComponent } from './listing-stat/listing-stat.component';
import { KeyListingAvatarComponent } from './listing-avatar/listing-avatar.component';
import { KuiLoaderModule } from 'app/key-ui/loader/loader.module';

const COMPONENTS = [
  KeyListingComponent,
  KeyListingTitleComponent,
  KeyListingDescriptionComponent,
  KeyListingStatComponent,
  KeyListingAvatarComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    KuiModule,
    KuiLoaderModule,
  ],
  exports: COMPONENTS,
  declarations: COMPONENTS,
})
export class KeyListingModule { }
