import { KuiTabBarComponent } from './tab-bar.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KuiActionModule } from 'app/key-ui/action/action.module';
import { KuiIconModule } from 'app/key-ui/icon/icon.module';
import { DragulaModule } from 'ng2-dragula';

@NgModule({
    imports: [
        CommonModule,
        KuiActionModule,
        KuiIconModule,
        DragulaModule.forRoot(),
    ],
    exports: [KuiTabBarComponent],
    declarations: [KuiTabBarComponent],
})
export class KuiTabBarModule { }
