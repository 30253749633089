import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilderDefinition } from 'app/shared/components/form-builder';
import { KuiModalRefComponent } from 'app/key-ui/modal/modal-ref.component';
import { IKuiModalAction } from 'app/key-ui';

export interface PresetModalData {
    title: string;
    form: FormBuilderDefinition;
    values: { [key: string]: any };
}

@Component({
    selector: 'key-analytics-settings-presets-modal',
    templateUrl: './modal.component.html',
})
export class AnalyticsSettingsPresetsModalComponent extends KuiModalRefComponent<PresetModalData> implements OnInit {
    modalActions: IKuiModalAction[];

    constructor(private i18n: TranslateService) {
        super();
    }

    ngOnInit() {
        this.modalActions = [{
            text: this.i18n.instant('DIALOG.APPLY'),
            style: 'primary',
            keypress: 13,
            action: () => {
                this.actions.apply(this.data.values);
            },
        }];
    }
}
