<key-date-select
    [date]="date()"
    [fireDebounceLeadingEdge]="false"
    [currentString]="'REPORTING.NOW' | translate"
    (onDateSelection)="handleDateChange($event.date)">
</key-date-select>

<div class="trips">

    @if(loadingTrips()) {
        <key-listing
            class="border-width">
            <key-listing-description>
                <div class="d-flex align-items-center">
                    <kui-loader class="mr-1"
                        size="small"></kui-loader> {{ 'HISTORY.LIST.LOADING_TRIPS' | translate }}
                </div>
            </key-listing-description>
        </key-listing>
    } @else {
        @for(trip of trips(); track trip) {
            <key-listing
                (click)="selectTrip(trip)"
                [active]="selectedTrip() === trip"
                [loading]="loading() === trip"
                [id]="'id-' + trip.id">
                <key-listing-avatar>
                    <div [innerHTML]="getTripIcon(trip)"></div>
                </key-listing-avatar>
                <key-listing-stat>
                    {{ trip.dateStart | units: { unit: 'time', variant: 'short'} }} 
                    - 
                    {{ trip.dateEnd | units: { unit: 'time', variant: 'short'} }}
                    </key-listing-stat>
                <key-listing-title>
                    {{ getListTitle(trip.addressStart, trip.addressEnd) }}
                </key-listing-title>
                <key-listing-description>
                    <div class="container pt-1">
                        <div class="row">
                            <div class="flex-1 text-left p-0">
                                @for(linkedAsset of trip.linkedAssets; track linkedAsset) {
                                    <div>
                                        <kui-icon [name]="linkedAsset.icon?.name"
                                            class="mr-1"></kui-icon>
                                        {{ linkedAsset.name }}
                                    </div>
                                }
                                <span class="small distance-duration">
                                    {{ trip.distance | units: 'distance' }} &middot; {{ trip.duration | formatSeconds}}
                                    @if(trip.inprogress) {
                                        <span> &middot; {{ 'HISTORY.LIST.IN_PROGRESS' | translate}}</span>
                                    }
                                </span>
                            </div>
                            <div class="text-right p-0">
                                @if(trip.rating && (trip?.rating?.score !== 'null' || trip?.rating?.score !== 'undefined')) {
                                    <kui-icon name="star"
                                        [fill]="true"
                                        [class.text-danger]="trip.rating.score < 100/3"
                                        [class.text-warning]="trip.rating.score <= 100/1.5 && trip.rating.score >= 100/3"
                                        [class.text-success]="trip.rating.score > 100/1.5"
                                        [kui-tooltip]="'SHARED.TOOLTIP.DRIVER_RATING' | translate: { score: trip.rating.score }"
                                        align="right">
                                    </kui-icon>
                                }
                            </div>
                        </div>
                    </div>
                </key-listing-description>
            </key-listing>
        } @empty {
            <kui-alert type="info" class="key-alert-wrapper">
                <kui-icon name="lightbulb"
                    size="sm"></kui-icon>
                <span>{{ 'HISTORY.LIST.NO_TRIPS_FOR_DATE' | translate: { date: endDate() | units: { unit: 'date', variant:
                    'long' } } }}</span>
            </kui-alert>
        }
    }
</div>