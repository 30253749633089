<kui-button-group size="sm">
    <button
        *ngFor="let button of radioValues"
        kui-button
        (click)="changeValue(button.id)"
        [color]="selected === button.id ? 'primary' : 'secondary'"
    >
        {{button.value}}
    </button>
</kui-button-group>
<div *ngIf="error"
    class="invalid-feedback d-block">
    <div>{{ error }}</div>
</div>