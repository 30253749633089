import { Injectable, OnDestroy } from '@angular/core';
import { AppService } from 'app/app.service';
import { Contact } from '@key-telematics/fleet-api-client';
import * as arrayToTree from 'array-to-tree';
import { escapeRqlValue } from 'app/shared/utils/rql';

export interface AssetGroupingItem {
    id: string;
    name: string;
    parent: string;
    index?: number;
    contacts?: Array<Contact>;
    data?: any;
}

export interface AssetGroupingTreeItem {
    id: string;
    name: string;
    contacts?: Array<Contact>;
    children?: AssetGroupingTreeItem[];
}

/** The AssetGroupingService is used to wrap and speed up loading asset grouping info from the backend with a cache  */
@Injectable()
export class AssetGroupingService implements OnDestroy {


    private cache: {
        [key: string]: { items: AssetGroupingItem[], limit: number, count: number };
    } = {};

    private globalClientId: string; // client id of the currently loaded client

    constructor(
        private app: AppService
    ) {
        this.app.client$.subscribe(client => {
            this.clear();
            this.globalClientId = client && client.id;
        });
    }

    static forClient(app: AppService, ownerId: string): AssetGroupingService {
        const result = new this(app);
        result.globalClientId = ownerId;
        return result;
    }

    static toTree(items: AssetGroupingItem[]): AssetGroupingTreeItem[] {
        return arrayToTree(items, {
            parentId: 'root',
            parentProperty: 'parent',
        } as any);
    }

    static toAssetGroupingItems(entities: any[]): AssetGroupingItem[] {
        return entities.map(item => ({
            id: item.id,
            name: item.name,
            parent: item.parent ? item.parent.id : 'root',
            index: item.index,
            contacts: item.contacts,
            data: item,
        }));
    }

    static treeToComboValues(tree: AssetGroupingTreeItem[], startingDepth: number = 0): { key: string, value: string, indent: number }[] {
        const result = [];
        const recurseTree = (items: AssetGroupingTreeItem[], depth: number) => {
            if (items) {
                items.sort((a, b) => a.name.localeCompare(b.name));
                items.forEach(item => {
                    result.push({ key: item.id, value: item.name, indent: depth });
                    recurseTree(item.children, depth + 1);
                });
            }
        };
        recurseTree(tree, startingDepth);
        return result;
    }

    static createRootNode(clientId: string): AssetGroupingItem {
        const data = {
            id: 'root',
            name: 'Root',
            parent: { id: null },
            contacts: [],
            owner: { id: clientId },
        };
        return {
            id: data.id,
            name: data.name,
            parent: null,
            data: data,
        };
    }


    ngOnDestroy() {
        this.clear();
    }

    clear() {
        this.cache = {};
    }

    findCCInCCTree(tree: AssetGroupingTreeItem[], costCentre: AssetGroupingItem): AssetGroupingTreeItem[] {
        const costCentreTreeItem: AssetGroupingTreeItem[] = [];
        const recurseTree = (treeItem: AssetGroupingTreeItem[]) => {
            treeItem.forEach(item => {
                if (item.id && item.id === costCentre.id) {
                    costCentreTreeItem.push(item);
                } else {
                    if (item.children && item.children.length > 0) {
                        recurseTree(item.children);
                    }
                }
            });
            
        };
        recurseTree(tree);
        return [...costCentreTreeItem];
    }

    getFilteredCostCentres(costCentreTree: AssetGroupingTreeItem[], costCentres: AssetGroupingItem[]): AssetGroupingItem[] {
        const costCentreGroup: AssetGroupingItem[] = [];
        const recurseTree = (treeItem: AssetGroupingTreeItem[]) => {
            treeItem.forEach(item => {
                if (item && item.id) {
                    const costCentre = costCentres.find(x => x.id === item.id);
                    if (costCentre) {
                        costCentreGroup.push(costCentre);
                    }

                    if (item.children && item.children.length > 0) {
                        recurseTree(item.children);
                    }
                }
            });
            
        };
        recurseTree(costCentreTree);
        return costCentreGroup;
    }

    /** will look for an item and return only it (and it's children) */
    findInTree(tree: AssetGroupingTreeItem[], id: string): AssetGroupingTreeItem {
        const recurseTree = (items: AssetGroupingTreeItem[]): AssetGroupingTreeItem => {
            for (const item of items) {
                if (item.id === id) {
                    return item;
                }
                if (item.children && item.children.length > 0) {
                    const result = recurseTree(item.children);
                    if (result) { return result; }
                }
            }
            return null;

        };
        return recurseTree(tree);
    }

    private get(clientId: string, name: string, apiMethod: (id: string,  offset?: number, limit?: number, sort?: string, filter?: string) => Promise<any>, filter?: string): Promise<AssetGroupingItem[]> {
        const ownerId = clientId || this.globalClientId;
        const key = filter ? `${ownerId}/${name}/${filter}` : `${ownerId}/${name}`;
        return this.cache[key]
            ? Promise.resolve(this.cache[key].items.map(x => ({ ...x })))
            : apiMethod(ownerId, 0, 1000, 'name:asc', filter ? `&name=*${escapeRqlValue(filter)}*` : '').then(result => {
                result.items.sort((a, b) => a.name.localeCompare(b.name));
                this.cache[key] = { items: AssetGroupingService.toAssetGroupingItems(result.items), count: result.count, limit: result.limit };
                return this.cache[key].items;
            }).catch(error => {
                if (error.name === 'ForbiddenError') {
                    // this should technically not happen, but if it does don't throw an error... rather just send an empty array
                    return [];
                }
                throw error;
            });
    }

    private getAsTree(clientId: string, name: string, apiMethod: (id: string) => Promise<any>, filter?: string): Promise<AssetGroupingTreeItem[]> {
        return this.get(clientId, name, apiMethod, filter).then(items => {
            return AssetGroupingService.toTree(items);
        });
    }

    getGroupResultInfo(clientId: string, type: 'costCentres' | 'assetGroups' | 'zoneGroups' | 'assetCategories' | 'assetTypes' | 'deviceTypes' | 'assetTagTypes'): { limit: number, count: number } {
        const { count, limit } = this.cache[`${clientId}/${type}`];
        return { count, limit };
    }

    getCostCentres(clientId?: string, filter?: string): Promise<AssetGroupingItem[]> {
        return this.get(clientId, 'costCentres', this.app.api.entities.listCostCentres.bind(this.app.api.entities), filter);
    }


    getCostCentresAsTree(clientId?: string, filter?: string): Promise<AssetGroupingTreeItem[]> {
        return this.getAsTree(clientId, 'costCentres', this.app.api.entities.listCostCentres.bind(this.app.api.entities), filter);
    }
    async getCostCentresAsTreeForUser(clientId?: string, filter?: string): Promise<AssetGroupingTreeItem[]> {
        const items = await this.getAsTree(clientId, 'costCentres', this.app.api.entities.listCostCentres.bind(this.app.api.entities), filter);
        if (this.app.user.costCentre?.id) {
            // we need to filter out items that are not children of the user's cost centre. to do that, turn it into a tree and then back into a list again
            const item = this.findInTree(items, this.app.user.costCentre.id);
            return [item].filter(x => x);
        }
        return items;
    }
    
    getAssetGroups(clientId?: string, filter?: string): Promise<AssetGroupingItem[]> {
        return this.get(clientId, 'assetGroups', this.app.api.entities.listAssetGroups.bind(this.app.api.entities), filter);
    }
    getAssetGroupsAsTree(clientId?: string, filter?: string): Promise<AssetGroupingTreeItem[]> {
        return this.getAsTree(clientId, 'assetGroups', this.app.api.entities.listAssetGroups.bind(this.app.api.entities), filter);
    }

    getAssetCategories(clientId?: string, filter?: string): Promise<AssetGroupingItem[]> {
        return this.get(clientId, 'assetCategories', this.app.api.entities.listAssetCategories.bind(this.app.api.entities), filter);
    }
    getAssetCategoriesAsTree(clientId?: string, filter?: string): Promise<AssetGroupingTreeItem[]> {
        return this.getAsTree(clientId, 'assetCategories', this.app.api.entities.listAssetCategories.bind(this.app.api.entities), filter);
    }

    async getAssetTypes(clientId?: string, filter?: string): Promise<AssetGroupingItem[]> {
        const items = await this.get(clientId, 'assetTypes', this.app.api.entities.listAssetTypes.bind(this.app.api.entities), filter);
        if (this.app.features.page.admin.availableAssetTypes) {
            const shownItemIds = this.app.features.page.admin.availableAssetTypes.split(',');
            return items.filter(item => shownItemIds.includes(item.id));
        }
        return items;
    }
    async getAssetTypesAsTree(clientId?: string, filter?: string): Promise<AssetGroupingTreeItem[]> {
        const items = await this.getAssetTypes(clientId, filter);
        return AssetGroupingService.toTree(items);
    }

    getDeviceTypes(clientId?: string, filter?: string): Promise<AssetGroupingItem[]> {
        return this.get(clientId, 'deviceTypes', this.app.api.entities.listDeviceTypes.bind(this.app.api.entities), filter);
    }
    getDeviceTypesAsTree(clientId?: string, filter?: string): Promise<AssetGroupingTreeItem[]> {
        return this.getAsTree(clientId, 'deviceTypes', this.app.api.entities.listDeviceTypes.bind(this.app.api.entities), filter);
    }


    getAssetTagTypes(clientId?: string, filter?: string): Promise<AssetGroupingItem[]> {
        return this.get(clientId, 'assetTagTypes', this.app.api.entities.listAssetTagTypes.bind(this.app.api.entities), filter);
    }
    getAssetTagTypesAsTree(clientId?: string, filter?: string): Promise<AssetGroupingTreeItem[]> {
        return this.getAsTree(clientId, 'assetTagTypes', this.app.api.entities.listAssetTagTypes.bind(this.app.api.entities), filter);
    }

    getZoneGroups(clientId?: string, filter?: string): Promise<AssetGroupingItem[]> {
        return this.get(clientId, 'zoneGroups', this.app.api.entities.listZoneGroups.bind(this.app.api.entities), filter);
    }
    getZoneGroupsAsTree(clientId?: string, filter?: string): Promise<AssetGroupingTreeItem[]> {
        return this.getAsTree(clientId, 'zoneGroups', this.app.api.entities.listZoneGroups.bind(this.app.api.entities), filter);
    }



}

