import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KuiModule } from 'app/key-ui';
import { KeyGraphComponent } from '../graph/graph.component';
import { TranslateModule } from '@ngx-translate/core';
import { KeyPieGraphComponent } from './pie-graph.component';

const COMPONENTS = [
    KeyGraphComponent,
    KeyPieGraphComponent,
];

@NgModule({
    imports: [
        CommonModule,
        KuiModule,
        TranslateModule,
    ],
    declarations: COMPONENTS,
    exports: COMPONENTS,
})
export class KeyGraphModule { }
