<div class="row m-0"
    key-image-drop-target
    (dropHandler)="onDropHandler($event)">
    <div class="col-lg-9 col-sm-8 p-4">

        <div [class.hidden]="cropperReady"
            class="d-flex flex-stretch justify-content-center align-items-center p-4"
            style="min-height: 300px">
            <div *ngIf="!uploading" class="text-center p-5">
                <kui-icon class="muted"
                    name="image"
                    [size]="10"></kui-icon>
                <br />
                {{ 'IMAGE_EDITOR.HINTS.DROP_TARGET' | translate }}
            </div>
            <div *ngIf="uploading" class="text-center p-5">
                <kui-loader></kui-loader>
            </div>
        </div>

        <div [class.hidden]="!cropperReady"
            class="d-flex flex-stretch justify-content-center align-items-center">
            <image-cropper [maintainAspectRatio]="true"
                [aspectRatio]="aspectRatio"
                [roundCropper]="false"
                [cropper]="crop"
                format="png"
                output="base64"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded($event)"
                (loadImageFailed)="loadImageFailed()"
                style="max-height: 55vh"
                [imageFile]="blobImageUrl"></image-cropper>
        </div>

    </div>
    <div class="col-sm-4 col-lg-3 text-center p-4"
        [class.border-right]="!isMobile"
        [class.border-bottom]="isMobile">

        <button kui-button
            color="primary"
            [disabled]="uploading"
            (click)="uploadInput.click()">
            <kui-icon name='cloud-upload'></kui-icon> {{ 'IMAGE_EDITOR.BUTTONS.UPLOAD' | translate }}

        </button>

        <div *ngIf="cropperReady"
            class="pt-3">
            {{ 'IMAGE_EDITOR.HINTS.CROP' | translate }}
            <span *ngIf="previewVisible">
                {{ 'IMAGE_EDITOR.HINTS.PREVIEW' | translate }}
            </span>
            <br />
            <img *ngIf="previewVisible"
                class='border mt-3 w-100 mw-100'
                [src]="croppedImage" />
        </div>

    </div>
</div>

@if (hasExistingImage) {
    <div class="row m-0">
        <div class="p-4">
            <button kui-button
                color="danger"
                (click)="imageRemoved.next(true)">
                <kui-icon name='trash'></kui-icon> {{ 'IMAGE_EDITOR.BUTTONS.REMOVE' | translate }}
            </button>
        </div>
    </div>
}

<!-- HIDDEN The following items are used for background image processing and aren't visible -->
<div style='display: none'>

    <input #uploadInput
        type="file"
        (change)="fileChangeEvent($event)"
        accept=".png,.jpg,.jpeg" />

    <img #original
        crossOrigin="Anonymous"
        style="max-width: 500px"
        [src]="originalImageUrl"
        (load)="processOriginal()" />

    <canvas #destination
        style='border: solid 1px #444'></canvas>

</div>