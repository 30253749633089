<table>
    <thead>
        <tr>
            <td>
            </td>
            <td class="title">
                {{ 'REPORTING.BUILDER.COLUMNS.TITLE' | translate }}
            </td>
            <td>
                {{ 'REPORTING.BUILDER.COLUMNS.WIDTH' | translate }}
            </td>
            <td>
                {{ 'REPORTING.BUILDER.COLUMNS.ALIGN' | translate }}
            </td>
            <td>
            </td>
        </tr>
    </thead>
    <tbody
        [dragula]='"columns"'
        [dragulaModel]="columns"
        (dragulaModelChange)="sortColumns($event)"
    >
        <tr *ngFor="let column of columns">
            <td>
                <kui-icon
                    class="icon-light pl-1"
                    size="sm"
                    name="arrows-v"
                ></kui-icon>
            </td>
            <td class="title">
                <input
                    class="inline-editor"
                    type="text"
                    [placeholder]="column.id"
                    [(ngModel)]="column.title"
                />
            </td>
            <td class="width">
                <input
                    class="inline-editor"
                    type="number"
                    [(ngModel)]="column.width"
                />
            </td>
            <td class="align">
                <select
                    class="inline-editor"
                    [(ngModel)]="column.align"
                >
                    <option value="Left">{{ 'REPORTING.BUILDER.COLUMNS.ALIGN_VALUES.LEFT' | translate }}</option>
                    <option value="Center">{{ 'REPORTING.BUILDER.COLUMNS.ALIGN_VALUES.CENTER' | translate }}</option>
                    <option value="Right">{{ 'REPORTING.BUILDER.COLUMNS.ALIGN_VALUES.RIGHT' | translate }}</option>
                </select>
            </td>
            <td>
                <div class="d-flex">
                    <kui-icon *ngIf="column.id.startsWith('custom_')"
                        [title]="'REPORTING.BUILDER.COLUMNS.ACTION.EDIT' | translate"
                        kui-action
                        class="icon-light pr-1"
                        size="sm"
                        name="edit"
                        (click)="updateColumnExpression(column)"
                    ></kui-icon>
                    <kui-icon
                        [title]="'REPORTING.BUILDER.COLUMNS.ACTION.DELETE' | translate"
                        kui-action
                        class="icon-light pr-1 ml-auto"
                        size="sm"
                        name="trash-alt"
                        (click)="removeColumn(column)"
                    ></kui-icon>
                </div>
            </td>
        </tr>

    </tbody>

</table>
<div class="pt-2">
    <kui-dropdown
        [style]="'secondary'"
        [customContent]="dropdownContent"
        [customContentContext]="{ component: filterDropdown }"
    > {{ 'SHARED.ADD' | translate }}

    </kui-dropdown>
    <ng-template #dropdownContent
        let-component="component">
        <div style="min-width: 200px">
            <div
                class="p-2"
                *ngIf="!treeNodes"
            >
                <kui-loader size="small"></kui-loader>
            </div>
            <kui-tree-select
                #treeselect
                *ngIf="treeNodes"
                [nodes]="treeNodes"
                (selectedNodeChanged)="nodeSelected($event, component, treeselect)"
            ></kui-tree-select>
        </div>
    </ng-template>

    <button
        class="ml-2"
        kui-button
        color="secondary"
        (click)="resetColumns()"
    >{{ 'SHARED.RESET' | translate }}</button>
</div>
