import { Component, Input, HostBinding } from '@angular/core';

export interface FeedEntryInfoIcon {
    id: string;
    tooltip: string;
    notification?: number;
    name: string;
    color: 'success' | 'warning' | 'danger' | 'info';
}

@Component({
    selector: 'key-feed-entry-info',
    templateUrl: './feed-entry-info.component.html',
})
export class KeyFeedEntryInfoComponent {
    @HostBinding('class.feed-entry-info') feedEntryInfo = true;

    @Input() icons: FeedEntryInfoIcon[];
    @Input() indicators: Array<'success' | 'warning' | 'danger' | 'info'>;
}
