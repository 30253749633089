import { Component, OnInit } from '@angular/core';
import { KuiModalRefComponent } from 'app/key-ui/modal/modal-ref.component';
import { TranslateService } from '@ngx-translate/core';
import { IKuiModalAction } from 'app/key-ui';
import { ModalService } from '../modal';

export interface ImageEditorPreviewOptions {
    visible: boolean;
    width: number;
    height: number;
}

export interface ImageEditorOptions {
    paddingPercentage: number;
}

export interface ImageEditorModalOptions {
    title: string;
    originalUrl: string;
    options: ImageEditorOptions;
    preview: ImageEditorPreviewOptions;
    backgroundColor?: string;
    cropped?: number;
}


@Component({
    templateUrl: './image-editor-modal.component.html',
})
export class KeyImageEditorModalComponent extends KuiModalRefComponent<ImageEditorModalOptions> implements OnInit {

    aspectRatio: number;
    modalActions: IKuiModalAction[];

    originalImage: string;
    croppedImage: string;
    previewImage: string;

    constructor(private i18n: TranslateService) {
        super();
    }

    static open(modal: ModalService, title: string, url: string, options: ImageEditorOptions, preview: ImageEditorPreviewOptions, cropped?: number): Promise<{ original: string, preview: string }> {
        return new Promise((resolve, reject) => {
            try {
                modal.open(KeyImageEditorModalComponent, {
                    data: {
                        title: title,
                        originalUrl: url,
                        options: options,
                        preview: preview,
                        cropped: cropped,
                    },
                    actions: {
                        close: () => {
                            modal.close();
                            resolve(undefined);
                        },
                        apply: (result) => {
                            modal.close();
                            resolve(result);
                        },
                        delete: () => {
                            modal.close();
                            resolve(null);
                        }
                    },
                });
            } catch (err) {
                reject(err);
            }
        });

    }


    ngOnInit() {

        this.modalActions = [{
            text: this.i18n.instant('DIALOG.CANCEL'),
            style: 'secondary',
            keypress: 27,
            action: () => {
                this.actions.close();
            },
        }, {
            text: this.i18n.instant('DIALOG.OK'),
            style: 'primary',
            keypress: 13,
            action: () => {
                this.actions.apply({ original: this.originalImage, cropped: this.croppedImage, preview: this.previewImage });
            },
        }];
    }


    close() {
        this.actions.close();
    }

    onImageUpdated(event: { original: string, cropped: string; preview: string }) {
        this.originalImage = event.original;
        this.croppedImage = event.cropped;
        this.previewImage = event.preview;
    }

}
