<kui-select
    *ngIf="selectionValues"
    [options]="selectionValues"
    (change)="changeSelection($event)"
></kui-select>
<key-form-builder
    *ngIf="values"
    [form]="form"
    class="d-block mt-3"
    [values]="values"
    autoTranslatePrefix="ANALYTICS.SETTINGS"
    (onChange)="changeFormSetting($event)"
></key-form-builder>
