import { Component, OnInit } from '@angular/core';
import { KuiModalRefComponent } from 'app/key-ui/modal/modal-ref.component';
import { IKuiModalAction } from 'app/key-ui';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export interface IFrametModalOptions {
    title: string;
    url: string;
}

@Component({
    templateUrl: 'iframe.component.html',
})
export class IFrameModalComponent extends KuiModalRefComponent<IFrametModalOptions> implements OnInit {

    modalActions: IKuiModalAction[];
    url: SafeResourceUrl;

    constructor(
        private i18n: TranslateService,
        private sanitizer: DomSanitizer
    ) {
        super();
    }

    ngOnInit() {

        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);

        this.modalActions = [{
            text: this.i18n.instant('DIALOG.CLOSE'),
            style: 'primary',
            keypress: 27,
            action: () => {
                this.actions.close(false);
            },
        }];

    }

    detachModal() {
        this.actions.close(false);
    }
}
