import { Component, Input, ElementRef, Renderer2 } from '@angular/core';
import { AppService } from 'app/app.service';
import { get } from 'lodash';
import { BaseComponent } from 'app/shared/components/base/base.component';

@Component({
    selector: 'kui-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
})
export class KuiLoaderComponent extends BaseComponent {
    style: string;

    @Input() size: 'small' | 'medium' | 'large' = 'large';
    @Input() inverse: boolean;

    constructor(
        private app: AppService,
        private el: ElementRef,
        private render: Renderer2
    ) {
        super();
        this.on(this.app.theme$, theme => {
            this.style = get(theme, 'settings.loader.style', 'spinner');
            this.render.addClass(this.el.nativeElement, this.style);
        });
    }
}
