import {
  Component,
  ElementRef,
  Renderer2,
  OnInit
} from '@angular/core';

@Component({
  selector: 'kui-button-toolbar',
  templateUrl: './button-toolbar.component.html',
})
export class KuiButtonToolbarComponent implements OnInit {
  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.renderer.addClass(this.el.nativeElement, 'btn-toolbar');
    this.renderer.setAttribute(this.el.nativeElement, 'role', 'toolbar');
  }
}
