import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { KuiModalRefComponent } from 'app/key-ui/modal/modal-ref.component';
import { MatchMediaService } from 'app/services';
import { MediaService } from 'app/services/media/media.service';
import { ModalService } from '../../modal';
import { VideoComponent } from '../video/video.component';

export type PlaybackAction = 'play' | 'pause' | 'backward' | 'forward' | 'start' | 'end';


// would have loved to keep this on the media service, but a circular depenency would result
export function showMediaLiveViewModal(modal: ModalService, assetId: string, duration: number, cameraInput: string) {
    modal.open(MediaLiveViewModalComponent, {
        data: {
            assetId,
            cameraInput,
            duration
        },
        actions: {
            close: () => modal.close(),
        },
    });
}

@Component({
    selector: 'key-media-live-modal',
    templateUrl: 'live-modal.component.html',
    styles: [`
        .videos {
            height: 450px;
            position: relative;
        }
        .info {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
            z-index: 10;
        }
        .info .inner {
            background: var(--content-background-color) !important;
            padding-right: 10px;
            border-radius: 10px;
        }
    `],
    // styleUrls: ['viewer-modal.component.scss'],
})
export class MediaLiveViewModalComponent extends KuiModalRefComponent<{ assetId: string, duration: number, cameraInput: string }> implements OnInit {

    constructor(
        public matchMedia: MatchMediaService,
        public media: MediaService
    ) {
        super();
    }

    title = 'Live Stream';
    isIOS: boolean;

    video: any;

    @ViewChildren(VideoComponent) videoElements: QueryList<VideoComponent>;

    error: string;
    loading: boolean = false;
    ended: boolean = false;

    src: { src: string, type: string } = null;

    ngOnInit() {
        this.requestLiveStream();
    }

    async requestLiveStream() {
        try {
            this.video = null;
            this.loading = true;
            this.ended = false;

            const duration = 60; // Vision only supports 60 seconds at the moment, and it doesn't matter what you set this to, you're going to get 60 seconds. This may change in future.
            const record = true; // set this to true so that the media proxy creates a recording of the live video in the backend (which can be seen in the media tab).

            const result = await this.media.requestLiveStreamUrl(this.data.assetId, this.data.cameraInput, duration, record);
            if (result) {

                if (result.status === 'success') {
                    this.error = null;
                    const hls = result.endpoints.find(x => x.startsWith('https://'));
                    if (hls) {
                        this.src = { src: hls, type: 'application/x-mpegURL' };
                        this.video = {
                            autoplay: true,
                            controls: true,
                            sources: [this.src]
                        }
                        this.loading = false;
                        return;
                    } else 
                        this.setError('Could not find a compatible stream');    
                } else {
                    this.setError(result.status);

                }
            }
        } catch (err) {
            console.error(err);
            this.setError(err.message);
        }

    }

    onLiveStreamReady() {

        /** This is a HACK. The vision live stream only runs for 60 seconds and then ends, but I am unable to get any kind of event
         * from the video player telling me that the stream has ended. So I am going to set a timeout to end the stream after the 
         * passed in duration, so we can get the popup and enable the refresh button.
         */
        setTimeout(() => {
            if (this.video && !this.error) {
                this.ended = true;
            }
        }, this.data.duration * 1000);

    }

    onLiveStreamError(err: Error) {
        this.setError(err.message);
    }


    setError(error: string) {
        this.loading = false;
        this.video = null;
        this.error = error;
    }

    detachModal() {
        this.video = null;
        this.actions.close();
    }


}
