<kui-input-group [cleanAddons]="true"
    class="w-100 d-block">
    <kui-tag-input #tagInputEl
        [disabled]="field.readonly"
        [invalid]="error || (field.required && (dirty && touched) && selectedItems.length === 0)"
        [placeholder]="field.placeholder || ('FORMS.SEARCHSELECT.PLACEHOLDER' | translate)"
        [loading]="isLoading"
        [autoCompleteItems]="autoCompleteItems"
        [remoteSearch]="true"
        [selectedItems]="selectedItems"
        (itemAdded)="addTag($event)"
        (itemRemoved)="removeTag($event)"
        (searchTermChanged)="updateAutoCompletionList($event)"
        [multiple]="multi"></kui-tag-input>
    <span *ngIf="!field.readonly && !multi && !!selectedItems?.length"
        (click)="tagInputEl.removeItem(selectedItems[0])"
        kui-input-group-addon
        kui-action
        class="px-1">
        <kui-icon name="times"
            size="xs"
            class="muted"></kui-icon>
    </span>
    <span *ngIf="!field.readonly"
        kui-input-group-addon
        [class.border-danger]="error || (field.required && (dirty && touched) && selectedItems.length === 0)"
        class="p-0">
        <kui-dropdown [style]="'none'"
            [customContent]="customDropdown">
            <kui-icon [name]="multi ? 'plus' : 'chevron-down'"
                size="xs"
                class="py-2 px-3"></kui-icon>
        </kui-dropdown>
        <ng-template #customDropdown>
            <div style="min-width: 200px; max-width: 250px">
                <p *ngIf="!treeNodes?.length"
                    class="px-3 mb-0">
                    {{'SHARED.NO_RESULTS_FOUND' | translate}}
                </p>
                <p *ngIf="truncatedList"
                    class="trucated-message mb-0">
                    {{'FORMS.SEARCHSELECT.TRUNCATED_MESSAGE' | translate: truncatedList }}
                </p>
                <kui-tree-select *ngIf="!!treeNodes?.length"
                    [nodes]="treeNodes"
                    (selectedNodeChanged)="nodeSelected($event)"></kui-tree-select>
            </div>
        </ng-template>
    </span>
</kui-input-group>

<div *ngIf="field.required && (dirty && touched) && selectedItems.length === 0"
    class="invalid-feedback d-block">
    <div>{{ 'FORMS.SHARED.REQUIRED' | translate:{ field: field.title } }}</div>
</div>
<div *ngIf="error"
    class="invalid-feedback d-block">
    <div>{{ error }}</div>
</div>