import { AppService } from 'app/app.service';
import { MapComponent } from 'app/shared/components';

export abstract class MapService {
    public clientId: string;
    public map: MapComponent;

    constructor(protected app: AppService) {
        // Listen for changes to the client in use, and if it changes call reset
        // and update the internal client identifier
        this.app.client$.subscribe(client => {
            if (client) {
                this.clientId = client.id;
            }

            if (this.reset) {
                this.reset();
            }
        });
    }

    /**
     * Attach the map to the service
     * @param {MapComponent} map
     */
    attachMap(map: MapComponent) {
        this.detachMap();
        this.map = map;
    }


    /**
     * Detach the map from the service
     */
    detachMap() {
        if (this.reset) {
            this.reset();
        }

        if (this.map) {
            this.map = null;
        }
    }


    /**
     * Reset the service when the client changes or the map is removed
     */
    abstract reset();
}
