<div class="d-flex flex-stretch align-items-center">
    <div class="flex-1"
        kui-action
        (click)="!field.readonly && onEdit()">

        <span *ngIf="!value?.preview">{{ 'SHARED.NONE' | translate }}</span>
        <img *ngIf="value?.preview"
            [style.max-width]="field.width ? field.width + 'px' : '150px'"
            [src]="value.preview" />
    </div>
    <div *ngIf="!field.readonly">
        <kui-icon name="edit"
            kui-action
            class="muted ml-2"
            (click)="onEdit()"
            size="sm"></kui-icon>
        <kui-icon name="times"
            kui-action
            class="muted ml-2"
            (click)="onClear()"
            size="sm"></kui-icon>
    </div>
</div>


<div *ngIf="field.required && touched && !value?.original"
    class="invalid-feedback d-block">
    <div>{{ 'FORMS.SHARED.REQUIRED' | translate:{ field: field.title } }}</div>
</div>
<div *ngIf="error"
    class="invalid-feedback d-block">
    <div>{{ error }}</div>
</div>