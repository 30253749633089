<div
    class="modal fade"
    [ngClass]="{ show: showFade }"
    [attr.aria-hidden]="!displayed"
    [style.display]="show ? 'block' : 'none'"
>
    <div
        [ngClass]="modalClassnames"
        role="document"
    >
        <div class="modal-content w-100">
            <kui-modal-header
                *ngIf="title"
                [title]="title"
                (close)="close()"
            >
                <ng-content select="[header]"></ng-content>
            </kui-modal-header>
            <kui-modal-body
                [hasCloseButton]="!title"
                (close)="close()"
            >
                <ng-content></ng-content>
            </kui-modal-body>
            <kui-modal-footer
                *ngIf="actions"
                [actions]="actions"
                (buttonClicked)="handleButtonClick($event)"
            >
                <ng-content select="[footer]"></ng-content>
            </kui-modal-footer>

            <div
                class="modal-overlay d-flex flex-stretch justify-content-center align-items-center"
                *ngIf="loading"
            >
                <kui-loader></kui-loader>
            </div>

        </div>
    </div>
</div>
<div
    class="modal-backdrop fade"
    [ngClass]="{ show: showFade }"
    [style.display]="show ? 'block' : 'none'"
    (click)="close()"
>
</div>