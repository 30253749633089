import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Contact } from '@key-telematics/fleet-api-client';

@Component({
    selector: 'key-contacts',
    templateUrl: 'contacts.component.html',
    styleUrls: ['contacts.component.scss'],
})
export class ContactsComponent {

    @Input() compact = false;
    @Input() contacts: Contact[];

    @Input() showDelete = false;
    @Output() onDelete = new EventEmitter<Contact>();

    @Output() onClick = new EventEmitter<Contact>();

    constructor() { }


}
