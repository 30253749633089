import { FeedResult, FeedHistoryResult } from './../feed.model';

import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { AuthService, MeasurementUnitsService } from 'app/services';
import { LogFeedItem } from '../feed.model';
import { FeedService, FeedOriginType } from '../feed.service';
import { Observable } from 'rxjs';
import { LogMessage } from '@key-telematics/fleet-api-client';

@Injectable()
export class LogFeedService extends FeedService<LogFeedItem> {

    supportsWebsockets = false;

    constructor(app: AppService, public auth: AuthService, private units: MeasurementUnitsService) {
        super(app, auth);
    }

    getFeedSubscription(_clientId: string, _originType: FeedOriginType, _originId: string, _limit: number): Observable<FeedResult<LogFeedItem>> {
        throw new Error('not supported');
    }

    getFeed(_clientId: string, _originType: FeedOriginType, originId: string, _sequence: number, _direction: 'forward' | 'backward', limit: number): Promise<FeedResult<LogFeedItem>> {
        const levels = this.settings.levels || 'info,warn,debug,error';
        const entityType = this.settings.entityType;
        const start = this.settings.start || 'now-7d/d';
        const end = this.settings.end || 'now/d';
        return this.app.api.data.getLogHistoryForEntity(entityType, originId, start, end, limit - 1, levels)
            .then(result => ({
                count: result.items.length,
                sequence: [
                    result.items.length !== 0 ? Math.min(...result.items.map(item => this.dateToSequence(item.timestamp))) : 0,
                    result.items.length !== 0 ? Math.max(...result.items.map(item => this.dateToSequence(item.timestamp))) : 0,
                ],
                items: result.items.map(item => this.mapResponse(item)),
            }));
    }

    protected getFeedHistory(_clientId: string, _originType: FeedOriginType, _originId: string, _start: string, _end: string, _limit: number): Promise<FeedHistoryResult<LogFeedItem>> {
        return Promise.reject('Log history not available');
    }

    mapResponse(item: LogMessage): LogFeedItem {
        return {
            id: item.timestamp,
            date: item.timestamp,
            section: {
                title: this.units.format(item.timestamp, 'date', 'long').format,
                date: item.timestamp,
            },
            timestamp: item.timestamp,
            level: item.level,
            message: item.message
        };
    }
}
