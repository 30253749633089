import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'kui-tab',
    templateUrl: './tab.component.html',
})
export class KuiTabComponent {
    // prevent tooltip appearing over tab content
    @HostBinding('attr.title') _title = '';

    @Input() title: string;
    @Input() isActive = false;
}
