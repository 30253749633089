import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { AssetListItem } from './assets.model';
import { AssetResponse, AssetListItem as AssetListResponseItem, TaskResponse, IoTypeTextConfig, DeviceResponse } from '@key-telematics/fleet-api-client';
import { Subject } from 'rxjs';
import * as moment from 'moment-timezone';
import { nullAccessDenied } from '../api/api.service';
import { getDeviceConfiguration } from '../api/api.utils';

export interface AssetOutputState {
    device: DeviceResponse;
    input: string;
    type: string;
    states: IoTypeTextConfig;
}


/** The AssetListItemService is used to wrap and speed up loading assets from the backend with a cache  */
@Injectable()
export class AssetService {

    private assetCache: { [key: string]: AssetListItem; } = {};
    private promiseCache: { [key: string]: Promise<any>; } = {};

    private cachedSubject = new Subject<AssetListItem>();
    cached$ = this.cachedSubject.asObservable();

    constructor(
        private app: AppService
    ) {
        this.app.client$.subscribe(() => {
            this.clear();
        });
    }

    clear() {
        this.assetCache = {};
        this.promiseCache = {};
    }

    cacheAsset(asset: AssetListItem): AssetListItem {
        this.assetCache[asset.id] = asset;
        this.cachedSubject.next(asset);
        return asset;
    }

    getAsset(id: string): Promise<AssetListItem> {
        let asset = this.assetCache[id];
        if (asset) {
            return Promise.resolve(asset);
        }
        return this.app.api.entities.getAsset(id).then(item => {
            asset = this.toAssetListItem(item);
            this.cacheAsset(asset);
            return asset;
        });
    }

    toAssetListItem(item: AssetResponse | AssetListResponseItem): AssetListItem {
        return {
            id: item.id,
            name: item.name,
            owner: item.owner,
            sharedWith: item.sharedWith,
            assetType: item.assetType,
            fields: item.fields,
            tags: item.tags,
            color: item.color,
            state: item.state,
            geoLock: item.geoLock,
            costCentre: item.costCentre,
            groups: item.groups,
            devices: item.devices
        };

    }

    async removeGeoLock(id: string): Promise<AssetListItem> {
        // TODO: this route will likely change in the API once we have the output setting etc implemented
        const asset = await this.app.api.entities.updateAsset(id, { geoLock: null });
        return this.cacheAsset(this.toAssetListItem(asset));
    }

    async addGeoLock(id: string, lat: number, lon: number, distance: number): Promise<AssetListItem> {
        // TODO: this route will likely change in the API once we have the output setting etc implemented
        const asset = await this.app.api.entities.updateAsset(id, {
            geoLock: {
                user: { id: this.app.user.id, name: this.app.user.name },
                lat: lat,
                lon: lon,
                radiusKm: distance,
                start: moment().utc().toISOString(),
                end: moment().utc().add(1, 'year').toISOString(),
            },
        });
        return this.cacheAsset(this.toAssetListItem(asset));
    }

    getOutputStates(id: string): Promise<AssetOutputState[]> {

        const fetch = async (): Promise<AssetOutputState[]> => {

            const asset = await this.app.api.entities.getAsset(id, this.app.api.cacheFor(5));
            if (asset.owner.id === this.app.client.id) { // don't attempt to get details for shared assets, it will fail
                const deviceId = asset.devices.length > 0 && asset.devices[0].id;
                if (deviceId) {
                    const [device, ioTypes] = await Promise.all([
                        this.app.api.entities.getDevice(deviceId, this.app.api.cacheFor(5)).catch<DeviceResponse>(nullAccessDenied),
                        this.app.api.entities.listIoTypes(asset.owner.id, true, 0, 300, null, null, this.app.api.cacheFor(5)).catch(nullAccessDenied),
                    ]);
                    const results = [];
                    if (device && ioTypes) { // this could fail if the user doesn't have access to devices
                        let config = await getDeviceConfiguration(this.app.api, device);
                        Object.keys(config.parameters.io).forEach(type => {
                            Object.keys(config.parameters.io[type]).forEach(ioTypeId => {
                                const item = config.parameters.io[type][ioTypeId];
                                const ioType = ioTypes.items.find(x => x.id === ioTypeId);
                                if (ioType) {
                                    results.push({ device: device, input: item.input, type: type, states: ioType.text });
                                }
                            });
                        });
                    }
                    return results;
                }
            }
            return [];
        };


        let result = this.promiseCache[`getOutputStates/${id}`];
        if (!result) {
            result = fetch();
            this.promiseCache[`getOutputStates/${id}`] = result;
        }
        return result;

    }

    async createDeviceOutputTask(assetId: string, name: string, output: string, state: number): Promise<TaskResponse> {

        const asset = await this.app.api.entities.getAsset(assetId, this.app.api.cacheFor(5));
        const task = await this.app.api.tasks.createTask({
            ownerId: asset.owner.id,
            options: {
                retries: 3,
                delay: 1,
                timeout: 5,
            },
            type: 'DeviceOutputTask',
            data: {
                asset: {
                    id: asset.id,
                    name: asset.name,
                },
                output: {
                    name: name,
                    output: output,
                    state: state,
                    pulse: 0,
                    value: state === 0 ? 'off' : 'on',
                },
            },
        });
        return task;
    }

    async createDevicePhotoTask(assetId: string, name: string, input: string): Promise<TaskResponse> {

        const asset = await this.app.api.entities.getAsset(assetId, this.app.api.cacheFor(5));
        const task = await this.app.api.tasks.createTask({
            ownerId: asset.owner.id,
            options: {
                retries: 3,
                delay: 1,
                timeout: 5,
            },
            type: 'DevicePhotoTask',
            data: {
                asset: {
                    id: asset.id,
                    name: asset.name,
                },
                camera: {
                    name: name,
                    input: input,
                },
            },
        });
        return task;
    }

    async createDevicePollTask(assetId: string): Promise<TaskResponse> {

        const asset = await this.app.api.entities.getAsset(assetId, this.app.api.cacheFor(5));
        const task = await this.app.api.tasks.createTask({
            ownerId: asset.owner.id,
            options: {
                retries: 3,
                delay: 1,
                timeout: 5,
            },
            type: 'DevicePollTask',
            data: {
                asset: {
                    id: asset.id,
                    name: asset.name,
                },
            },
        });
        return task;
    }

    async createDeviceDialbackTask(assetId: string, number: string): Promise<TaskResponse> {

        const asset = await this.app.api.entities.getAsset(assetId, this.app.api.cacheFor(5));
        const task = await this.app.api.tasks.createTask({
            ownerId: asset.owner.id,
            options: {
                retries: 3,
                delay: 1,
                timeout: 5,
            },
            type: 'DeviceDialbackTask',
            data: {
                asset: {
                    id: asset.id,
                    name: asset.name,
                },
                dialback: {
                    number: number,
                },
            },
        });
        return task;
    }


    async createDeviceTextMessageTask(assetId: string, message: string): Promise<TaskResponse> {

        const asset = await this.app.api.entities.getAsset(assetId, this.app.api.cacheFor(5));
        const task = await this.app.api.tasks.createTask({
            ownerId: asset.owner.id,
            options: {
                retries: 3,
                delay: 1,
                timeout: 5,
            },
            type: 'DeviceTextMessageTask',
            data: {
                asset: {
                    id: asset.id,
                    name: asset.name,
                },
                message: {
                    message: message,
                },
            },
        });
        return task;
    }

    async createDeviceAddressTask(assetId: string, lat: number, lon: number, message: string): Promise<TaskResponse> {

        const asset = await this.app.api.entities.getAsset(assetId, this.app.api.cacheFor(5));
        const task = await this.app.api.tasks.createTask({
            ownerId: asset.owner.id,
            options: {
                retries: 3,
                delay: 1,
                timeout: 5,
            },
            type: 'DeviceAddressMessageTask',
            data: {
                asset: {
                    id: asset.id,
                    name: asset.name,
                },
                message: {
                    message,
                    lon,
                    lat,
                },
            },
        });
        return task;
    }

}


