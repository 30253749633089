import { Directive, OnInit, ComponentFactoryResolver, ViewContainerRef, ComponentFactory, ComponentRef, HostListener, HostBinding, Renderer2, ElementRef } from '@angular/core';

import { KuiActionComponent } from './action.component';
import { MatchMediaService } from 'app/services';

@Directive({
    selector: '[kui-action]',
})
export class KuiActionDirective implements OnInit {
    factory: ComponentFactory<KuiActionComponent>;
    ref: ComponentRef<KuiActionComponent>;

    @HostBinding('class.action') baseClass = true;
    /* adds convience .hover class to non touch screens for styling its hover state */
    @HostBinding('class.hover') hover: boolean;

    @HostListener('click', ['$event']) click(e) { this.doAnimation(e); }

    constructor(
        public el: ElementRef,
        public renderer: Renderer2,
        public componentFactoryResolver: ComponentFactoryResolver,
        public viewContainerRef: ViewContainerRef,
        public matchmedia: MatchMediaService
    ) {}

    ngOnInit() {
        this.hover = !this.matchmedia.deviceFeatures.touch;

        this.factory = this.componentFactoryResolver.resolveComponentFactory(KuiActionComponent);
        this.ref = this.viewContainerRef.createComponent(this.factory);

        this.renderer.appendChild(
            this.viewContainerRef.element.nativeElement,
            this.ref.injector.get(KuiActionComponent).el.nativeElement
        );
    }

    doAnimation(e: MouseEvent) {
        const rect = this.el.nativeElement.getBoundingClientRect();

        this.ref.instance.offsets = { x: e.clientX - rect.left, y: e.clientY - rect.top };
        this.renderer.addClass(this.ref.instance.el.nativeElement, 'action--animate');

        setTimeout(() => {
            this.renderer.removeClass(this.ref.instance.el.nativeElement, 'action--animate');
        }, 500);
    }
}
