import { ChangeDetectorRef, ElementRef, Input, OnChanges, ViewChild, Output, EventEmitter, AfterViewInit, HostBinding, SimpleChanges, Component } from '@angular/core';
import { Chart, InteractionMode } from 'chart.js';
import { AppService } from 'app/app.service';
import { BaseComponent } from 'app/shared/components';
import { takeUntil } from 'rxjs/operators';
import { Dataset, Legend, GraphPointSelection, LegendItem, DataLabelsFormatterContext, ChartConfigurationWithPlugins, DataPoint, ChartDataSetsWithPlugins, Label } from './graph.model';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import { THEME_GRAPH_PALETTES } from '../theme/theme-defaults';
import { get } from 'lodash';

@Component({
    selector: 'key-graph-base-component',
    template: '',
})
export abstract class KeyGraphBaseComponent extends BaseComponent implements AfterViewInit, OnChanges {
    private redrawChangesList = new Set<string>(['stacked', 'yAxes', 'showLabelsOnGraph', 'type', 'fill', 'averages']);
    protected axesLabelColor: string;
    protected axesLabelFont: string;
    protected axesLabelSize: number;
    protected colors: string[];
    protected shouldRedrawChart: boolean;
    legendMinimized: boolean;
    legendItems: LegendItem[];
    loaded = false;

    @Input() labels: Label[];
    @Input() datasets: Dataset[];
    @Input() legend: Legend;
    @Input() showLabelsOnGraph: boolean;
    @Input() tooltipMode: 'none' | 'single' | 'multiple' = 'single';
    @Input() stacked: boolean;

    @HostBinding('class') hostBaseClasses = 'position-relative d-flex flex-1';
    @HostBinding('class.flex-column') flexColumn: boolean;

    @Output() onPointSelection = new EventEmitter<GraphPointSelection>();
    @Output() onDatasetHidden = new EventEmitter<{ dataset: Dataset, hidden: boolean }>();

    @ViewChild('graph', { static: true }) chartContext: ElementRef;
    @ViewChild('legendEl') legendEl: ElementRef;

    chart: Chart;
    config: ChartConfigurationWithPlugins;

    tooltipModeMap: { [key: string]: { mode: InteractionMode, intersect: boolean } } = {
        none: { mode: null, intersect: false },
        single: { mode: 'index', intersect: true },
        multiple: { mode: 'index', intersect: true },
    };

    get baseConfig(): ChartConfigurationWithPlugins {
        return {
            options: {
                animation: {
                    duration: 0,
                },
                responsive: true,
                spanGaps: true,
                legend: {
                    display: false,
                },
                tooltips: {
                    mode: this.tooltipModeMap[this.tooltipMode].mode,
                    intersect: this.tooltipModeMap[this.tooltipMode].intersect,
                    titleFontColor: 'rgba(255, 255, 255, .8)',
                    titleFontStyle: getComputedStyle(this.chartContext.nativeElement).fontStyle,
                },
                maintainAspectRatio: false,
                onClick: this.handlePointsSelectionEvent.bind(this),
                hover: {
                    onHover: this.handleCursorOnHover.bind(this),
                },
                plugins: {
                    datalabels: {
                        display: this.showLabelsOnGraph ? (this.stacked || 'auto') : false,
                        anchor: this.stacked ? 'center' : 'end',
                        clamp: true,
                        font: {
                            size: 10,
                        },
                        formatter: (value: number, context: DataLabelsFormatterContext): string | number => {
                            const dataset = this.datasets[context.datasetIndex];
                            const dataValue = dataset.data[context.dataIndex].value;
                            return dataValue || value;
                        },
                    },
                },
            },
            plugins: [ChartDataLabels, {
                id: 'loadingCallback',
                afterRender: () => {
                    this.loaded = true;
                    this.changes.markForCheck();
                },
                afterUpdate: ({ legend }: any) => { // legend does exist on runtime ChartElement, but not on Chart type
                    if (this.legend && this.legend.show) {
                        this.legendItems = legend.legendItems.map(x => ({
                            text: x.text,
                            fillStyle: x.fillStyle,
                            strokeStyle: x.strokeStyle,
                            hidden: x.hidden,
                        }));
                        this.flexColumn = this.testLegendPositions('bottom', 'top');
                    }
                },
            }],
        };
    }

    constructor(public changes: ChangeDetectorRef, public app: AppService) {
        super();
        this.registerChartPlugins();

        this.app.theme$
            .pipe(takeUntil(this.destroyed))
            .subscribe(theme => {
                this.colors = THEME_GRAPH_PALETTES[get(theme.settings, 'graph.palette', 'default')] || THEME_GRAPH_PALETTES.default;
                this.axesLabelColor = theme.variables['--graph-label-font-color'] || '#333';
                this.axesLabelFont = theme.variables['--graph-label-font-family'] || 'Arial';
                try {
                    const rem = parseFloat(theme.variables['--graph-label-font-size'] || '1rem'); // believe it or not, but this works to get a float
                    this.axesLabelSize = parseFloat(window.getComputedStyle(document.documentElement).fontSize) * rem;
                } catch {
                    this.axesLabelSize = 10;
                }

                if (this.datasets) {
                    this.updateGraphConfig();
                }
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (Object.keys(changes).some(x => this.redrawChangesList.has(x))) {
            this.shouldRedrawChart = true;
        }
        this.updateGraphConfig();
    }

    ngAfterViewInit() {
        setTimeout(() => { // allow DOM to assign necessary heights to anchestor elements and then redraw
            this.shouldRedrawChart = true;
            this.updateGraphConfig();
        });
    }

    updateGraph() {
        if (this.chart && this.shouldRedrawChart) {
            this.chart.destroy();
            this.chart = null;
            this.shouldRedrawChart = false;
        }

        if (this.chart) {
            this.chart.config = this.config;
            this.chart.update(0);
            this.changes.markForCheck();
        } else {
            const ctx = this.chartContext.nativeElement.getContext('2d');
            this.chart = new Chart(ctx, this.config);
        }
    }

    getDataset(item: Dataset, index: number): ChartDataSetsWithPlugins {
        return {
            borderWidth: 1.2,
            data: item.data.map((val: DataPoint) => val.raw),
            hidden: item.hidden,
            label: (typeof item.label === 'string') ? item.label : item.label && item.label.name,
            datalabels: {
                color: '#ffffff',
                backgroundColor: item.color || this.colors && this.colors[index] || 'rgb(47, 166, 212)',
            },
        };
    }

    handleCursorOnHover(e: MouseEvent, elements: any[]) {
        e.target['style'].cursor = (elements && elements.length > 0) ? 'pointer' : 'default';
    }

    handlePointsSelectionEvent(event: Event) {
        const element = this.chart.getElementAtEvent(event)[0] as any;

        if (element) {
            this.onPointSelection.emit({
                pointIndex: element._index,
                datasetIndex: element._datasetIndex,
            });
        }
    }

    // copied and adapted from https://gist.github.com/tobyjsullivan/96d37ca0216adee20fa95fe1c3eb56ac
    abbreviateNumber(value: number): string {
        let newValue = value;
        const suffixes = ['', 'k', 'm', 'b', 't'];
        let suffixNum = 0;
        while (newValue >= 1000) {
            newValue /= 1000;
            suffixNum++;
        }
        let result = parseFloat(newValue.toPrecision(3)).toString();
        return result += suffixes[suffixNum];
    }


    testLegendPositions(position01: string, position02?: string): boolean {
        if (!this.legend) { return false; }
        if (!position02) { return this.legend.position === position01; }
        return this.legend.position === position01 || this.legend.position === position02;
    }

    resizeGraph() {
        if (this.chart) {
            this.chart.resize();
        }
    }

    registerChartPlugins() {
        const namedChartAnnotation = ChartAnnotation;
        namedChartAnnotation['id'] = 'annotation';
        Chart.pluginService.register(namedChartAnnotation);
    }

    // add graph specific config here
    abstract updateGraphConfig(): void;

    // handle legend clicks per chart type
    abstract clickLegend(item: LegendItem, index: number): void;
}
