<div class="asset-list">
    <key-selection
        type="asset"
        [id]="clientId"
        (onSelection)="selectItem($event)">
    </key-selection>
</div>

@if(asset()) {
    <div class="block">
        <div class="block__heading">Selected Asset</div>
        <div class="block__content">
            <div class="asset">
                <kui-icon [name]="asset().iconName"
                    class="mr-2"
                    [ngStyle]="{ color: asset().color }">
                </kui-icon>
                <span>{{asset().name}}</span>
            </div>
            @if(loading()) {
                <kui-loader size="small"></kui-loader>
            }
            @else {
                @if(warning()) {
                    <div class="asset__message">
                        <kui-icon name="exclamation-triangle" size="sm" color="danger"></kui-icon>
                        <span>{{ 'MEDIA.VIDEO_REQUEST.MODAL.NO_CAMERA' | translate }}</span>
                    </div>
                } @else {
                    <div class="asset__message">
                        <kui-icon name="check" size="sm" color="success"></kui-icon>
                        <span>{{ 'MEDIA.VIDEO_REQUEST.MODAL.HAS_CAMERA' | translate }}</span>
                    </div>
                }
            }
        </div>
    </div>
}