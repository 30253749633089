<!-- WIDGET -->
<div class="p-3 widget d-flex flex-column flex-1">

    <div *ngIf="widget"
        class="widget__header d-flex align-items-center mb-2">

        <h6 class="m-0 muted text-truncate">{{widget?.name | uppercase}}</h6>
        <div class="widget__header__actions ml-auto">
            <kui-icon kui-action
                *ngIf="showShare"
                (click)="onShare.emit({ cellset: cellset, settings: settings, id: widgetId, definitionId: widget?.dataSource?.options?.analytics?.definitionId })"
                name="share-alt"
                [title]="'DASHBOARDS.WIDGET.SHARE' | translate"></kui-icon>
            <!-- inherited widgets can't be deleted by the user -->
            <kui-icon kui-action
                *ngIf="!widget.inherited && showDelete"
                (click)="onDelete.emit({ id: widgetId, name: widget?.name })"
                name="trash-alt"
                class="ml-3"
                [title]="'DASHBOARDS.WIDGET.REMOVE' | translate"></kui-icon>
            <kui-icon kui-action
                *ngIf="showReportLink"
                (click)="onShowReport.emit(widgetId)"
                name="chart-pie"
                class="ml-3"
                [title]="'DASHBOARDS.WIDGET.OPEN_REPORT' | translate"></kui-icon>
            <kui-icon kui-action
                *ngIf="showSettings"
                (click)="doSettingsSelection()"
                name="cog"
                class="ml-3"
                [title]="'DASHBOARDS.WIDGET.OPEN_SETTINGS' | translate"></kui-icon>
        </div>
    </div>

    <div class="widget__body d-flex flex-1 flex-column position-relative">
        <div class="sample-data"
            *ngIf="!errorMessage && !loading && !widget?.dataSource?.id">
            <div class="py-3 d-inline-block">
                <kui-icon name="exclamation-triangle"
                    class="mr-2"
                    size="sm"></kui-icon>
                <span>{{'DASHBOARDS.WIDGET.SAMPLE_DATA' | translate | uppercase}}</span>
            </div>
        </div>

        <kui-alert-panel [icon]="size === SIZES.LARGE || size === SIZES.SMALL && !errorAction ? errorIcon : ''"
            [actionText]="errorAction?.text"
            [message]="size !== SIZES.SMALL && errorMessage"
            [title]="errorMessage || ''"
            [size]="size === SIZES.LARGE ? 5 : 4"
            (onAction)="errorAction?.action()"></kui-alert-panel>

        <kui-loader *ngIf="loading"
            class="d-flex flex-1 justify-content-center align-items-center"></kui-loader>

        <ng-container *ngIf="!errorMessage && !loading && cellset && settings">
            <key-analytics-chart [type]="widget.widgetType"
                [cellset]="cellset"
                [settings]="settings"
                [size]="size"
                (onLoad)="onChartLoaded.emit($event)"></key-analytics-chart>
        </ng-container>
    </div>
</div>