import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, signal, WritableSignal, type OnInit } from '@angular/core';
import { KuiTabsModule } from 'app/key-ui';
import { KuiTabComponent } from 'app/key-ui/tabs/tab.component';
import { KeyMediaModule } from 'app/shared/components/media/media.module';

export interface LiveCamera {
    assetId: string;
    cameraId: string;
    cameraName: string;
}

@Component({
    selector: 'key-video-request-livestream',
    standalone: true,
    imports: [
        CommonModule,
        KeyMediaModule,
        KuiTabsModule
    ],
    templateUrl: './livestream-viewer.component.html',
    styleUrl: './livestream-viewer.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoRequestLivestreamComponent implements OnInit {
    
    cameras = input.required<LiveCamera[]>();
    activeCamera: WritableSignal<string> = signal('');

    constructor() {}

    ngOnInit(): void {
        // Default to the first camera
        this.activeCamera.set(this.cameras()[0].cameraName);
    }

    handleTabChange(event: KuiTabComponent) {
        this.activeCamera.set(event.title);
    }

}
