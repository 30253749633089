
import { filter, map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from 'app/app.service';

// Conditional is for testing, where ga isn't bootstrapped
const gtag = window['gtag'] || (() => { });

@Injectable()
export class GoogleAnalyticsService {

    public pageViews: Observable<string>;

    constructor(private app: AppService, public router: Router) {

        /*
        * start google analytics
        */
        if (this.app.env.trackingId) {
            gtag('config', this.app.env.trackingId, {
                appName: 'fleet-ui',
                appId: this.app.env.storePrefix,
                appVersion: this.app.env.version,
            });
        }

        this.pageViews = this.router.events.pipe(
                filter(event => event instanceof NavigationEnd),
                map((event: NavigationEnd) => event.urlAfterRedirects),
                tap(urlAfterRedirects => {
                    /*
                    * excecute ga pageview events
                    */
                    gtag('event', 'page', { url: urlAfterRedirects });
                })
            );
    }

    /*
    * manually emit events from interactions
    */
    emitEvent(eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: number = null) {
        gtag('event', 'event', {
            eventCategory,
            eventAction,
            eventLabel,
            eventValue,
        });

    }

    /**
     * Emits an exception
     */
    emitException(description: string) {
        gtag('event', 'exception', {
            'description': description,
            'fatal': false,
        });
    }
}
