import { Directive, Renderer2, ElementRef } from '@angular/core';

@Directive({
    selector: '[kui-list-item-heading]',
})
export class KuiListItemHeadingDirective {
    constructor(private el: ElementRef, private renderer: Renderer2) {
        this.renderer.addClass(this.el.nativeElement, 'list-group-item-heading');
        this.renderer.addClass(this.el.nativeElement, 'w-100');
    }
}
