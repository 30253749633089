import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { CurrentClient } from 'app/shared/model';
import { ImageService } from '../images/image.service';

@Injectable()
export class ClientService {

    constructor(
        private app: AppService,
        private images: ImageService
    ) {

        // listen for changes to the logged in user, as we will automatically load the default client if one exists
        this.app.user$.subscribe(user => {
            if (user && user.defaultClientId) {
                const lastClient = this.app.appStore.get<CurrentClient>('client'); // when the user is set, see if we have an existing client stored for him
                const clientId = lastClient?.id || user.defaultClientId;
                this.loadClient(clientId).catch(err => {
                    this.app.logError(err); // if an error occurs, notify the AppService as it is a global error
                    if (user.defaultClientId !== clientId) {
                        this.loadClient(user.defaultClientId);
                    }
                });
            } else {
                this.loadClient(null); // reset the client
            }
        });

    }

    async loadClient(id: string): Promise<CurrentClient> {
        if (id) {
            const [result, logo] = await Promise.all([this.app.api.accounts.getClient(id), this.images.getForClient({ id: id })]);
            const client: CurrentClient = {
                id: result.id,
                owner: result.owner,
                name: result.name,
                mapSetId: result.meta ? result.meta.currentMapSetId : null,
                measurementUnits: result.measurementUnits,
                customFields: result.customFields || {},
                theme: result.theme.id !== 'inherited' ? result.theme : null,
                flags: result.meta.mergedFlags,
                logoPath: logo,
            };
            this.app.setClient(client);
            return client;
        } else {
            // place reset code here
            this.app.setClient(null);
            return Promise.resolve(null);
        }
    }


}
