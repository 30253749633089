<kui-side-panel width="300px"
    [absolute]="absolute"
    (onSwipe)="onHide.emit()"
    [align]="align">

    <kui-content-header *ngIf="title"
        [title]="title">
        <kui-content-header-actions>
            <kui-icon kui-action
                name="times"
                (click)="onHide.emit()"></kui-icon>
        </kui-content-header-actions>
    </kui-content-header>

    <kui-accordion class="flex-1">
        <ng-content></ng-content>
    </kui-accordion>
</kui-side-panel>