import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'checklistFormat',
    pure: false,
    standalone: true,
})
export class ChecklistFormatPipe implements PipeTransform {

    transform(value: string | string[]): string {
        return value ? typeof value === 'string' ? value : value.join(', ') : '';
    }
}
