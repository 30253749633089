<input
    kui-input
    type="{{ field.type }}"
    placeholder="{{ field.placeholder }}"
    #textField="ngModel"
    [disabled]="field.readonly"
    [minlength]="field.min || 0"
    [maxlength]="field.max || 1024"
    [ngModel]="value"
    (ngModelChange)="changeText($event)"
    [required]="field.required"
    autocomplete="new-password"
    [class.border-danger]="error || (textField.invalid && (textField.dirty || textField.touched))"
    [pattern]="pattern"
>
<div
    *ngIf="textField.invalid && (textField.dirty || textField.touched)"
    class="invalid-feedback d-block"
>
    <div *ngIf="textField.errors['required']">{{ 'FORMS.SHARED.REQUIRED' | translate:{ field: field.title } }} </div>
    <div *ngIf="textField.errors['minlength']">{{ 'FORMS.TEXT.MIN' | translate:{ field: field.title, min: field.min } }}</div>
    <div *ngIf="textField.errors['pattern']">{{ patternErrorMessage | translate:{ field: field.title } }}</div> 
</div>
<div *ngIf="error"
    class="invalid-feedback d-block">
    <div>{{ error }}</div>
</div>