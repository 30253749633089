import { Component, Input } from '@angular/core';

export interface LabelBadge {
    label: string,
    name: string,
    color?: string
}

@Component({
    selector: 'key-label-badges',
    template: `
        <span *ngFor="let label of labels" class="badge badge-secondary mt-1 mr-1">
            <kui-icon name="ribbon" [style.color]="label.color"
                class="mr-1"></kui-icon>
            {{ label.name }}
        </span>
    `,
})
export class LabelBadgesComponent {

    @Input() labels: LabelBadge[];

    constructor() { }

}
