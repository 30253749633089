import { Component, OnDestroy, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { KuiSnackbarService } from 'app/key-ui/snackbar/snackbar.service';
import { EMPTY, Observable } from 'rxjs';
import { MapOptionService } from '../../map-option.service';
import { MapOptionComponent } from '../map-option.component';
import { MapOptionMeasurementService } from './map-option-measurements.service';

@Component({
    styleUrls: ['./map-option-measurements.component.scss'],
    templateUrl: './map-option-measurements.component.html',
})
export class MapOptionMeasurementsComponent implements MapOptionComponent<any>, OnInit, OnDestroy {
    
    static ID = 'map-option-measurements-component';
    static ICON = 'ruler-triangle';
    static TITLE = 'LEAFLET.MAP_OPTIONS.MEASUREMENT';

    data: any;

    distance$: Observable<string> = EMPTY;

    constructor(
        private snackbar: KuiSnackbarService,
        private i18n: TranslateService,
        private measurementService: MapOptionMeasurementService,
        private mapOptionService: MapOptionService,
    ) { }

    ngOnInit(): void {
        this.distance$ = this.measurementService.distance$;
        this.measurementService.start();
    }

    ngOnDestroy(): void {
        this.measurementService.stop()
    }

    copyToClipboard(str: string) {
        // create non visible textarea to copy text from
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);

        // copy text
        const selected =
            document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
        el.select();
        document.execCommand('copy');

        // clean up after yourself
        document.body.removeChild(el);
        if (selected) {
            document.getSelection().removeAllRanges();
            document.getSelection().addRange(selected);
        }

        // inform user of action
        this.snackbar.message(this.i18n.instant('DIALOG.COPIED'));
    }

    undo() {
        this.measurementService.undo();
    }

    close() {
        this.measurementService.stop();
        this.mapOptionService.clear();
    }
}

