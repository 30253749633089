import { CurrentTheme, AvailableThemes, CssVariablesOverwrite, CurrentThemeSettings } from 'app/shared/model';
import { FormBuilderDefinition, FormBuilderField, FormBuilderAction } from '../form-builder';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, intersection, set } from 'lodash';
import { ThemeResponse, Dictionary } from '@key-telematics/fleet-api-client';
import * as Color from 'color';

export interface BaseTheme extends CurrentTheme {
    variableSettings: CssVariablesOverwrite[];
    getSettingsForm: (i18n: TranslateService, fields?: FormBuilderField[]) => FormBuilderDefinition;
}


export const THEME_DEFAULT_SETTINGS: { [K in AvailableThemes]: CurrentThemeSettings } = {
    default: {
        primaryColor_main: '#f33636',
        primaryColor_invert: '#ffffff',
        navigationColor_background: '#ffffff',
        navigationColor_foreground: '#676a6c',
        navigationColor_border: '#e6e6e6',
        navigationColor_accent: 'whitesmoke',
        navigationColor_accentInvert: '#676a6c',
        navigationActiveState: 'left-border',
        headerColor_background: '#ffffff',
        headerColor_foreground: '#676a6c',
        headerColor_border: 'rgba(0, 0, 0, .05)',
        headerColor_accent: 'whitesmoke',
        headerColor_accentInvert: '#676a6c',
        headerColorActive_background: 'whitesmoke',
        headerColorActive_foreground: '#676a6c',
        headerColorActive_border: '#e6e6e6',
        graphColors: ['#69aaf8', '#f33636', '#F5B400', '#5F39BF', '#F47C2A', '#B199F4', '#86E57E', '#857986', '#6b0392', '#f05b4f', '#dda458', '#eacf7d', '#86797d', '#b2c326', '#6188e2', '#a748ca', '#69aaf8', '#F5B400', '#5F39BF', '#F47C2A', '#B199F4', '#86E57E', '#857986', '#6b0392', '#f05b4f', '#dda458', '#eacf7d'],
        graphAxesLabelColor: 'rgba(103, 106, 108, .5)',
        textColor_default: 'rgb(103, 106, 108)',
        textColor_invert: '#ffffff',
    },
    nightrider: {
        primaryColor_main: '#0072C5',
        primaryColor_invert: '#ffffff',
        navigationColor_background: '#333333',
        navigationColor_foreground: '#ffffff',
        navigationColor_border: '#2b2b2b',
        navigationColor_accent: '#4A4A4A',
        navigationColor_accentInvert: '#ffffff',
        navigationActiveState: 'background',
        headerColor_background: '#333333',
        headerColor_foreground: 'rgba(255, 255, 255, 0.8)',
        headerColor_border: '#2b2b2b',
        headerColor_accent: '#4A4A4A',
        headerColor_accentInvert: '#ffffff',
        headerColorActive_background: '#404040',
        headerColorActive_foreground: 'rgba(255, 255, 255, 0.8)',
        headerColorActive_border: '#383838',
        graphColors: ['rgba(91, 189, 218, 0.8)', '#b32a2a', '#fdcdcd', '#cbccce', '#b0e1d8', '#eee9b4', '#d3c6d6', '#aaaaaa', '#88b7b8', '#e0bba9', '#ffda90', '#a8cca0', '#969abd', '#ba9db6'],
        graphAxesLabelColor: 'rgba(255, 255, 255, .4)',
        textColor_default: 'rgba(255, 255, 255, 0.8)',
        textColor_invert: '#1E1E1E',
    },
};

export const THEME_VARIABLES: CssVariablesOverwrite[] = [
    { key: 'navigationColor_background', type: 'color', variables: ['--color-bg-menu'] },
    { key: 'navigationColor_foreground', type: 'color', variables: ['--color-bg-menu-contrast'] },
    { key: 'navigationColor_border', type: 'border', relatedColor: 'navigationColor_background', variables: ['--color-bg-menu-border'] },
    { key: 'navigationColor_accent', type: 'accent', relatedColor: 'navigationColor_background', variables: ['--color-bg-menu-accent'] },
    { key: 'navigationColor_accentInvert', type: 'match', relatedColor: 'navigationColor_foreground', variables: ['--color-bg-menu-accent-contrast'] },

    { key: 'headerColor_background', type: 'color', variables: ['--color-bg-header'] },
    { key: 'headerColor_foreground', type: 'color', variables: ['--color-bg-header-contrast'] },
    { key: 'headerColor_border', type: 'border', relatedColor: 'headerColor_background', variables: ['--color-bg-header-border'] },
    { key: 'headerColor_accent', type: 'accent', relatedColor: 'headerColor_background', variables: ['--color-bg-header-accent'] },
    { key: 'headerColor_accentInvert', type: 'match', relatedColor: 'headerColor_foreground', variables: ['--color-bg-header-accent-contrast'] },

    { key: 'headerColorActive_background', type: 'color', variables: ['--color-bg-header-active'] },
    { key: 'headerColorActive_foreground', type: 'color', variables: ['--color-bg-header-active-contrast'] },
    { key: 'headerColorActive_border', type: 'border', relatedColor: 'headerColorActive_background', variables: ['--color-bg-header-active-border'] },
];


export function isLegacyTheme(theme: ThemeResponse): boolean {
    const themeDictionary = getThemeDictionary();
    const base = themeDictionary[theme.theme];
    if (base) {
        const legacyKeys = base.variableSettings.map(x => x.key);
        return (theme.theme === 'nightrider' || intersection(legacyKeys, Object.keys(theme.settings)).length > 0);
    }
    return false;
}

export function convertLegacyTheme(theme: BaseTheme): Dictionary {

    const result = {};

    if (theme.theme === 'nightrider') {
        set(result, 'main.background.color', '#161616');
        set(result, 'content.background.color', '#252526');
        set(result, 'header.background.color', '#333333');
        set(result, 'footer.background.color', '#4a4a4a');
        set(result, 'table.header.background.color', '#2e2e2e');

    }
    set(result, 'navigation.active.style', theme.settings.navigationActiveState || (theme.theme === 'nightrider' ? 'background' : 'left-border'));

    const defaultSettings = THEME_DEFAULT_SETTINGS[theme.theme];
    theme.variableSettings.forEach(item => {
        const value = getComputedColor(item, theme.settings, defaultSettings);
        switch (item.key) {
            case 'navigationColor_background':
                set(result, 'navigation.background.color', value);
                break;
            case 'navigationColor_foreground':
                set(result, 'navigation.font.color', value);
                break;
            case 'navigationColor_border':
                set(result, 'navigation.border.color', value);
                break;
            case 'navigationColor_accent': break;
            case 'navigationColor_accentInvert': break;
            case 'headerColor_background':
                set(result, 'header.background.color', value);
                if (theme.theme === 'nightrider') {
                    set(result, 'footer.background.color', value);
                }
                break;
            case 'headerColor_foreground':
                set(result, 'header.font.color', value);
                if (theme.theme === 'nightrider') {
                    set(result, 'footer.font.color', value);
                }
                break;
            case 'headerColor_border':
                set(result, 'header.border.color', value);
                break;
            case 'headerColor_accent': break;
            case 'headerColor_accentInvert': break;
            case 'headerColorActive_background':
                set(result, 'tab.active.background.color', value);
                break;
            case 'headerColorActive_foreground':
                set(result, 'tab.active.font.color', value);
                break;
            case 'headerColorActive_border':
                set(result, 'tab.active.border.color', value);
                break;
            case 'primaryColor_main':
                set(result, 'color.primary.color', value);
                break;
            case 'primaryColor_invert':
                set(result, 'color.primary.contrast', value);
                break;
            default:
                break;
        }
    });
    return result;
}


function getComputedColor(item: CssVariablesOverwrite, settings: CurrentThemeSettings, defaultSettings?: CurrentThemeSettings): string {
    const relatedColor = Color(settings[item.relatedColor]);
    const textOnDark = Color(settings.textColor_default).isDark() ? settings.textColor_invert : settings.textColor_default;
    const isDefault = settings[item.relatedColor] === defaultSettings[item.relatedColor];

    switch (item.type) {
        case 'contrast':
            return isDefault ? defaultSettings[item.key] : relatedColor.isDark() ? textOnDark : relatedColor.darken(.8).string();
        case 'match':
            return isDefault ? defaultSettings[item.key] : settings[item.relatedColor];
        case 'border':
        case 'accent':
            return isDefault ? defaultSettings[item.key] : relatedColor.isDark() ? relatedColor.lighten(.1).string() : relatedColor.darken(.1).string();
        default:
            return settings[item.key];
    }
}

const getDefaultThemeForm = (i18n: TranslateService, baseTheme: BaseTheme, fields?: FormBuilderField[]): FormBuilderDefinition => {
    const translate = (value: string): string => i18n ? i18n.instant('SHARED.THEME.' + value) : value;

    const resetAction: FormBuilderAction = {
        icon: 'undo',
        name: i18n.instant('ADMIN.EDITORS.CLIENT.CUSTOMIZE.RESET_COLOR'),
        click: (_action: string, field: FormBuilderField, values: any) => {
            if (field.type === 'swatches') {
                field.values.forEach(kv => {
                    values[kv.key] = baseTheme.settings[kv.key];
                });
            } else {
                values[field.id] = baseTheme.settings[field.id];
            }
            return null;
        },
    };

    return {
        groups: [
            {
                fields: [
                    { type: 'color', id: 'primaryColor_main', title: translate('PRIMARY_COLOR'), required: false, actions: [resetAction] },
                    {
                        type: 'swatches', id: 'navigationColor', title: translate('NAVIGATION_COLOR'), required: false, actions: [resetAction], values: [
                            { key: 'navigationColor_background', value: translate('BACKGROUND') },
                            { key: 'navigationColor_foreground', value: translate('FOREGROUND') },
                        ],
                    },
                    {
                        type: 'combo', id: 'navigationActiveState', title: translate('NAVIGATION_ACTIVE_STATE'), actions: [resetAction], values: [
                            { key: 'background', value: translate('BACKGROUND') },
                            { key: 'left-border', value: translate('LEFT_BORDER') },
                            { key: 'right-border', value: translate('RIGHT_BORDER') },
                        ],
                    },
                    {
                        type: 'swatches', id: 'headerColor', title: translate('HEADER_COLOR'), required: false, actions: [resetAction], values: [
                            { key: 'headerColor_background', value: translate('BACKGROUND') },
                            { key: 'headerColor_foreground', value: translate('FOREGROUND') },
                        ],
                    },
                    {
                        type: 'swatches', id: 'headerColorActive', title: translate('HEADER_ACTIVE_COLOR'), required: false, actions: [resetAction], values: [
                            { key: 'headerColorActive_background', value: translate('BACKGROUND') },
                            { key: 'headerColorActive_foreground', value: translate('FOREGROUND') },
                        ],
                    },
                    ...fields || [],
                ],
            },
        ],
    };
};

/**
* default configs for themes
*
* WARNING: When changing these settings make sure the theme's default variables inside its scss config file gets updated too.
* I know this is duplication, there is a future dude implementation of theming that will try to fix this
*/
export const getThemeDictionary = (): { [K in AvailableThemes]: BaseTheme } => {
    const value: { [K in AvailableThemes]: BaseTheme } = {
        default: {
            id: 'default',
            owner: null,
            entity: null,
            theme: 'default',
            name: 'Default Light',
            variableSettings: [
                ...THEME_VARIABLES,
                { key: 'primaryColor_main', type: 'color', variables: ['--color-primary', '--color-text-link'] },
                { key: 'primaryColor_invert', type: 'contrast', relatedColor: 'primaryColor_main', variables: ['--color-primary-contrast'] },
            ],
            settings: THEME_DEFAULT_SETTINGS.default,
            customStyles: '',
            logoPath: '',
            getSettingsForm: (i18n, fields) => getDefaultThemeForm(i18n, value.default, fields),
        },
        nightrider: {
            id: 'nightrider',
            owner: null,
            entity: null,
            theme: 'nightrider',
            name: 'Default Dark',
            variableSettings: [
                ...THEME_VARIABLES,
                { key: 'primaryColor_main', type: 'color', variables: ['--color-primary', '--color-text-link', '--color-bg-list-active'] },
                { key: 'primaryColor_invert', type: 'contrast', relatedColor: 'primaryColor_main', variables: ['--color-primary-contrast', '--color-bg-list-active-contrast'] },
            ],
            settings: THEME_DEFAULT_SETTINGS.nightrider,
            customStyles: '',
            logoPath: '',
            getSettingsForm: (i18n, fields) => getDefaultThemeForm(i18n, value.nightrider, fields),
        },
    };
    return cloneDeep(value);
};
