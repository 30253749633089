import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { KuiModalRefComponent } from 'app/key-ui/modal/modal-ref.component';
import { IKuiModalAction } from 'app/key-ui';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'key-admin-progress-modal',
    templateUrl: 'progress-modal.component.html',
})
export class AdminProgressModalComponent extends KuiModalRefComponent<any> implements OnInit {

    constructor(
        private ref: ChangeDetectorRef,
        private i18n: TranslateService
    ) {
        super();
    }

    modalActions: IKuiModalAction[];

    title: string = this.i18n.instant('ADMIN.MODALS.PROGRESS.TITLE');
    progress: number;
    text: string;
    errorMessage: string;

    ngOnInit() {

        this.data.progress.subscribe(progress => {
            this.progress = progress.progress / progress.total * 100;
            this.text = progress.text;
            this.errorMessage = progress.errorMessage;
            if (this.errorMessage) {
                this.title = this.i18n.instant('ADMIN.MODALS.PROGRESS.TITLE_ERROR');
                this.modalActions = [{
                    text: this.i18n.instant('DIALOG.CLOSE'),
                    style: 'primary',
                    keypress: 13,
                    action: () => {
                        this.close();
                        return true;
                    },
                }];
            }
            this.ref.markForCheck();
        });

        this.modalActions = [{
            text: this.i18n.instant('DIALOG.ABORT'),
            style: 'primary',
            keypress: 13,
            action: () => {
                this.actions.abort();
                return true;
            },
        }];
    }


    close() {
        this.actions.close();
    }
}
