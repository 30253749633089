import { Component, Input } from '@angular/core';

import { IKUIBreacrumbDropdown, IKuiBreadcrumbLink } from './breadcrumb.model';

@Component({
  selector: 'kui-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class KuiBreadcrumbComponent {
  @Input() breadcrumbs: Array<IKUIBreacrumbDropdown | IKuiBreadcrumbLink>;
}
