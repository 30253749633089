import * as moment from 'moment-timezone';
import { Component, HostBinding, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { LogFeedItem } from '../feed.model';
import { FeedEntryInfoIcon } from 'app/shared/components/feed/feed-entry-info/feed-entry-info.component';
import { FeedEntryAction } from 'app/shared/components/feed/feed-entry-actions/feed-entry-actions.component';

@Component({
    selector: 'key-log-feed-item',
    templateUrl: 'log-feed-item.component.html',
})
export class LogFeedItemComponent implements OnChanges {

    icons: FeedEntryInfoIcon[];
    message: string;
    timeAgo: string;
    changes: string[];

    @Input() data: LogFeedItem;
    @Input() actions: FeedEntryAction[];
    @Output() primaryActionClicked: EventEmitter<any> = new EventEmitter();

    @HostBinding('class.feed-entry') feedEntryClass = true;

    constructor(
    ) { }

    ngOnChanges(changes: SimpleChanges) {

        const levelToIcon = (level: string): string => {
            switch (level) {
                case 'info': return 'success';
                case 'warn': return 'warning';
                case 'debug': return 'info';
                case 'error': return 'danger';
                default: return level;
            }
        }


        if (changes.data && this.data) {
            const icon = levelToIcon(this.data.level);
            this.icons = [{ id: icon, color: icon as any,  name: 'info-circle', tooltip: icon }];
            this.timeAgo = moment.utc(this.data.date).fromNow();
            this.message = this.data.message;
        }
    }

}
