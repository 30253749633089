import {
    ElementRef,
    Renderer2,
    Directive,
    Input,
    OnInit
} from '@angular/core';

@Directive({
    selector: '[kui-radio]',
})
export class KuiRadioDirective implements OnInit {
    @Input('kui-radio') radioName: string;

    constructor(private el: ElementRef, private renderer: Renderer2) {
        this.renderer.addClass(this.el.nativeElement, 'form-check-input');
        this.renderer.setAttribute(this.el.nativeElement, 'type', 'radio');
    }

    ngOnInit() {
        if (this.radioName) {
            this.renderer.setAttribute(this.el.nativeElement, 'name', this.radioName);
        }
    }
}
