<kui-input-group [cleanAddons]="true"
    class="w-100 d-block">
    <kui-tag-input #tagInputEl
        [invalid]="error || (field.required && (dirty && touched) && selectedItems.length === 0)"
        [placeholder]="'FORMS.ASSETFILTER.PLACEHOLDER' | translate"
        [loading]="isLoading"
        [autoCompleteItems]="autoCompleteItems"
        [remoteSearch]="true"
        [selectedItems]="selectedItems"
        (itemAdded)="addTag($event)"
        (itemRemoved)="removeTag($event)"
        (searchTermChanged)="updateAutoCompletionList($event)"
        [multiple]="field.max !== 1"></kui-tag-input>
    <span *ngIf="!(field.max !== 1) && !!selectedItems?.length"
        (click)="tagInputEl.removeItem(selectedItems[0])"
        kui-input-group-addon
        kui-action
        class="px-1">
        <kui-icon name="times"
            size="xs"
            class="muted"></kui-icon>
    </span>
    <span kui-input-group-addon
        class="p-0"
        [class.border-danger]="error || (field.required && (dirty && touched) && selectedItems.length === 0)"
        [class.overlay]="overlay">
        <kui-dropdown [style]="'none'"
            [customContent]="customDropdown"
            classNames="assetfilter-popup"
            (onOpen)="overlay = true"
            (onClose)="overlay = false">
            <kui-icon [name]="field.max === 1 ? 'chevron-down' : 'plus'"
                size="xs"
                class="py-2 px-3"></kui-icon>
        </kui-dropdown>
        <ng-template #customDropdown>
            <div class="dropdown-content">
                @if(singleSelect) {
                    @if (truncatedList) {
                        <p class="trucated-message mb-0">
                            {{'FORMS.ASSETFILTER.TRUNCATED_MESSAGE' | translate: truncatedList }}
                        </p>
                    }
                    <div class="asset-list">
                        <kui-tree-select [nodes]="assetTreeNodes"
                            (selectedNodeChanged)="nodeSelected($event)"></kui-tree-select>
                    </div>
                } @else {
                    <kui-tabs>
                        <kui-tab [title]="'FORMS.ASSETFILTER.ASSETS' | translate" [isActive]="true">
                            @if (truncatedList) {
                                <p class="trucated-message mb-0">
                                    {{'FORMS.ASSETFILTER.TRUNCATED_MESSAGE' | translate: truncatedList }}
                                </p>
                            }
                            <div class="asset-list">
                                @if (assetTreeNodes?.length > 0) {
                                    <kui-tree-select [nodes]="assetTreeNodes"
                                        (selectedNodeChanged)="nodeSelected($event, false)"></kui-tree-select>
                                } @else {
                                    <p class="mx-4 mb-0">
                                        {{'FORMS.ASSETFILTER.NO_ASSETS' | translate: truncatedList }}
                                    </p>
                                }
                            </div>
                        </kui-tab>
                        <kui-tab [title]="'FORMS.ASSETFILTER.GROUPS' | translate">
                            <div class="group-tree">
                                <kui-tree-select [nodes]="treeNodes"
                                    (selectedNodeChanged)="nodeSelected($event)"></kui-tree-select>
                            </div>
                        </kui-tab>
                    </kui-tabs>
                }
            </div>
        </ng-template>
    </span>
</kui-input-group>

<div *ngIf="field.required && (dirty && touched) && selectedItems.length === 0"
    class="invalid-feedback d-block">
    <div>{{ 'FORMS.SHARED.REQUIRED' | translate:{ field: field.title } }}</div>
</div>
<div *ngIf="error"
    class="invalid-feedback d-block">
    <div>{{ error }}</div>
</div>