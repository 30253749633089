import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ColorPickerModule } from 'ngx-color-picker';

import { KuiColorPickerComponent } from './color-picker.component';
import { MatchMediaServiceModule } from 'app/services/match-media/match-media.module';

@NgModule({
  imports: [CommonModule, FormsModule, ColorPickerModule, MatchMediaServiceModule],
  exports: [KuiColorPickerComponent],
  declarations: [KuiColorPickerComponent],
})
export class KuiColorPickerModule { }
