import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, Subject, merge } from "rxjs";
import { FilterPanelItem, FilterPanelTreeItem } from "../../filter-panel.model";

export interface TreeNode {
    id: string;
    data: FilterPanelItem;
    checked: boolean;
    indeterminate: boolean;
    disabled: boolean;
    children: TreeNode[];
    parent: TreeNode;
}

@Injectable()
export class TreeFilterService {

    private selectedSubject: Subject<TreeNode> = new ReplaySubject();
    private deselectedSubject: Subject<TreeNode> = new ReplaySubject();

    /**
     * Represents when a user has selected a node
     */
    public nodeSelected$: Observable<TreeNode> = this.selectedSubject.asObservable();

    /**
     * Represents when a user has deselected a node
     */
    public nodeDeselected$: Observable<TreeNode> = this.deselectedSubject.asObservable();

    /**
     * Represents when a change in selection has occurred, both selection and deselection.
     */
    public nodeChanged$: Observable<TreeNode> = merge(this.nodeSelected$, this.nodeDeselected$);

    /**
     * Convert a collection of FilterPanelTreeItem into a tree structure beneath a root node.
     * 
     * @param data the collection to convert
     * @returns A single `TreeNode` representing the tree
     */
    toTreeNode(data: FilterPanelTreeItem[]): TreeNode {
        const root: TreeNode = {
            id: 'root',
            data: null,
            indeterminate: false,
            checked: false,
            disabled: false,
            parent: null,
            children: null,
        }
        const toTreeNodeRecursive = (item: FilterPanelTreeItem, parent: TreeNode) => {
            const node = {
                id: item.id,
                data: item,
                indeterminate: false,
                checked: false,
                disabled: false,
                parent,
                children: []
            }
            if (item.children) {
                const children = item.children.map(child => toTreeNodeRecursive(child, node));
                node.children = children;
            }
            return node;
        };

        const children = data.map(item => toTreeNodeRecursive(item, root));
        root.children = children;
        return root;
    }

    /**
     * Indicate to the service that a user has changed a the selected nodes state.
     * 
     * @param item The TreeNode changed
     */
    nodeChanged(item: TreeNode) {
        if (item.checked) {
            this.selectedSubject.next(item);
        } else {
            this.deselectedSubject.next(item);
        }
    }

    /**
     * From the given node, update any child nodes to reflect the newly selected node state.
     * 
     * Specifically, this will mark all child nodes as checked and disabled.
     * This is because when a user selects a node, the filter for said node will include all child filters by default.
     * For this reason, we disable the child nodes to prevent any user selection that doesn't make sense.
     * 
     * @param node The node to update
     */
    nodeSelected(node: TreeNode) {
        const checkedUpdate = (node: TreeNode) => {
            node.checked = true;
            node.disabled = true;

            if (node?.children) {
                node.children.forEach(child => checkedUpdate(child));
            }
        }
        checkedUpdate(node);
        node.disabled = false;
    }

    /**
     * From the given node, update any child nodes to reflect the newly deselected node state.
     * 
     * Specifically, this will mark all child nodes as unchecked and enabled.
     * 
     * @param node the node to update
     */
    nodeDeselected(node: TreeNode) {
        const checkedUpdate = (node: TreeNode) => {
            node.checked = false;
            node.disabled = false;

            if (node?.children) {
                node.children.forEach(child => checkedUpdate(child));
            }
        }
        checkedUpdate(node);
    }

    /**
     * From the given node, get all selected nodes as a collection of `FilterPanelItem`.
     * 
     * We return only the highest selected item in the hierarchy for a specific node.
     * 
     * If we take the following tree as an example:
     * 
     * ```text
     *  [ ] Parent 1
     *      [X] Child A
     *  [ ] Parent 2
     *  [X] Parent 3
     *      [X] Child B
     *      [X] Child C
     *      [X] Child D
     * ```
     * 
     * This method would return the following array:
     * ```text
     * [Child A, Parent 3]
     * ```
     * 
     * @param node the node to use as the starting point
     * @returns the selected nodes to be used in a filter
     */
    getFilterItems(node: TreeNode): FilterPanelItem[] {
        const selected: FilterPanelItem[] = [];
        const traverse = (node: TreeNode) => {
            if (node.checked && !node.disabled) {
                selected.push({
                    id: node.id,
                    name: node.data.name
                });
            }
            if (node?.children) {
                node.children.forEach(child => traverse(child));
            }
        }
        traverse(node);
        return selected;
    }

    /**
     * From the given node, update the checked state of each node to that of the passed in `FilterPanelItem` array.
     * 
     * 
     * @param node the node to use as the starting point 
     * @param filter the items to use as reference to select within the tree
     */
    updateTreeSelections(node: TreeNode, filter: FilterPanelItem[]) {
        // we have to deselect everything to get a blank slate for our selections
        this.nodeDeselected(node);
        const selectedIds = filter.map(filter => filter.id);
        const traverse = (node: TreeNode) => {
            if (selectedIds.includes(node.id)) {
                this.nodeSelected(node);
            }
            if (node?.children) {
                node.children.forEach(child => traverse(child));
            }
        }
        traverse(node);
    }

}
