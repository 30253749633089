import { Component, Output, EventEmitter, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilderDefinition, FormBuilderFieldChangeEvent } from 'app/shared/components/form-builder';
import { toTitleCase } from 'app/shared/utils/string.utils';
import { KeyValue } from '../../analytics.model';

export interface SortSettings {
    measures: KeyValue[];
    type: 'alphabetic' | 'numeric';
    measureKey?: string;
    direction?: 'asc' | 'desc';
    limit?: number;
}

@Component({
    selector: 'key-analytics-settings-sort',
    templateUrl: './sort.component.html',
})
export class AnalyticsSettingsSortComponent implements OnInit, OnChanges {
    form: FormBuilderDefinition;
    values: { [key: string]: any };
    prevMeasureLength: number;

    @Input() options: SortSettings;
    @Output() onChange: EventEmitter<{ [key: string]: any; }> = new EventEmitter();

    constructor(
        private i18n: TranslateService
    ) {
    }

    ngOnInit() {
        this.form = this.getUpdatedForm();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.options && this.options) {
            const { measures, ...opts } = this.options;

            // update form if measures has changed
            if (measures.length !== this.prevMeasureLength) {
                this.form = this.getUpdatedForm();
                this.prevMeasureLength = measures.length;
            }

            this.values = opts;
        }
    }

    changeFormSetting(change: FormBuilderFieldChangeEvent) {
        if (change.dirty) {
            const { id } = change.field;
            this.onChange.emit({ [id]: change.values[id] });
        }
    }

    getUpdatedForm(): FormBuilderDefinition {
        return {
            'groups': [{
                fields: [{
                    type: 'radio', id: 'type', title: 'TYPE', values: [
                        { key: 'alphabetic', value: this.i18n.instant('ANALYTICS.SETTINGS.ALPHABETIC') },
                        {
                            key: 'numeric', value: this.i18n.instant('ANALYTICS.SETTINGS.NUMERIC'), fields: [
                                {
                                    type: 'combo', id: 'measureKey', title: 'SORT_BY', values: this.options.measures.map(value => ({
                                        key: value.value, value: toTitleCase(value.value),
                                    })),
                                }, {
                                    type: 'number', id: 'limit', title: 'LIMIT', required: false,
                                },
                            ],
                        },
                    ],
                }, {
                    type: 'radio', id: 'direction', title: 'DIRECTION', values: [
                        { key: 'asc', value: this.i18n.instant('ANALYTICS.SETTINGS.ASC') },
                        { key: 'desc', value: this.i18n.instant('ANALYTICS.SETTINGS.DESC') },
                    ],
                }],
            }],
        };
    }
}
