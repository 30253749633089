import { Component, OnInit } from '@angular/core';
import { KuiModalRefComponent } from 'app/key-ui/modal/modal-ref.component';
import { IKuiModalAction } from 'app/key-ui';
import { TranslateService } from '@ngx-translate/core';

export interface AlertModalOptions {
    message: string;
    heading?: string;
}

@Component({
    templateUrl: 'alert.component.html',
})
export class AlertModalComponent extends KuiModalRefComponent<AlertModalOptions> implements OnInit {

    modalActions: IKuiModalAction[];

    constructor(
        private i18n: TranslateService
    ) {
        super();
    }

    ngOnInit() {

        this.modalActions = [{
            text: this.i18n.instant('DIALOG.CLOSE'),
            style: 'primary',
            keypress: 27,
            action: () => {
                this.actions.close(false);
            },
        }];

    }

    detachModal() {
        this.actions.close(false);
    }
}
