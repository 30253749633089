import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'latestDate',
    standalone: true,
})
export class LatestDatePipe implements PipeTransform {

    /**
     * Returns the date which is the most recent
     * 
     * @param date a chosen Date
     * @param dates the Dates to compare with
     * @returns the most recent Date out of all options
     */
    transform(date: Date, ...dates: Date[]): Date {
        const sorted = [date, ...dates].filter((date) => !!date).sort((a, b) => b.valueOf() - a.valueOf());
        return sorted[0];
    }
}
