import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[kui-nav-sub]',
})
export class KuiNavSubDirective {
    constructor(private el: ElementRef, private renderer: Renderer2) {
        this.renderer.addClass(this.el.nativeElement, 'sub-nav');
    }
}
