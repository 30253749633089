import {
    Component,
    Input,
    ElementRef,
    Renderer2,
    OnInit
} from '@angular/core';

@Component({
    selector: 'kui-button-group',
    templateUrl: './button-group.component.html',
})
export class KuiButtonGroupComponent implements OnInit {
    @Input() direction: 'vertical' | 'horizontal' = 'horizontal';
    @Input() size: 'lg' | 'sm';

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngOnInit() {
        this.renderer.addClass(this.el.nativeElement, 'btn-group');
        this.renderer.setAttribute(this.el.nativeElement, 'role', 'group');

        if (this.direction === 'vertical') {
            this.renderer.addClass(this.el.nativeElement, 'btn-group-vertical');
        }

        if (this.size) {
            this.renderer.addClass(this.el.nativeElement, `btn-group-${this.size}`);
        }
    }
}
