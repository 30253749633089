<div *ngIf="isSearchableType"
    class="mx-2">
    <kui-input-group [cleanAddons]="true">
        <input kui-input
            #searchInput
            type="text"
            [placeholder]="searchDescription"
            [(ngModel)]="searchValue">

        <button kui-button
            kui-input-group-addon
            color="secondary">
            <kui-icon *ngIf="!searching"
                name="search"></kui-icon>
            <kui-loader *ngIf="searching"
                size="small"></kui-loader>
        </button>
    </kui-input-group>
</div>

<ng-container *ngIf="!!searchNodes?.length; else showTree">
    <key-search-results-list [list]="searchNodes"
        [highlightValue]="searchValue"
        [maxHeight]="200"
        (onBlur)="blurSearchInput()"
        (onSelected)="onSelected({
            id: $event.id,
            name: $event.value,
            eventName: 'activate',
            node: { data: $event.meta }
        })"></key-search-results-list>
</ng-container>
<ng-template #showTree>
    <kui-tree-select [nodes]="nodes"
        [selected]="selectedNode"
        (selectedNodeChanged)="onSelected($event)"></kui-tree-select>
</ng-template>