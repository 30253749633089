import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class KeyTranslateLoader implements TranslateLoader {

    constructor(private http: HttpClient, public prefix: string = '/assets/i18n/', public suffix: string = '.json') { }

    static getLanguages(): { id: string, name: string }[] { 
        return [
            { id: 'en-us', name: 'English' },
            { id: 'fr', name: 'Français' },
            { id: 'es', name: 'Español' },
            { id: 'pt', name: 'Português' },
            { id: 'nl', name: 'Nederlands' },
            { id: 'de', name: 'Deutsche' },
            { id: 'it', name: 'Italiano' },
        ];
    }

    static clearLanguageEndpoint() {
        if (window && window.sessionStorage) {
            window.sessionStorage.removeItem('language-endpoint');
        }
    }


    static setLanguageEndpoint(lang: string, endpoint: string) {
        if (window && window.sessionStorage) {
            window.sessionStorage.setItem('language-endpoint', JSON.stringify({ lang, endpoint }));
        }
    }


    static getLanguageEndpoint(): { lang: string, endpoint: string } {
        try {
            if (window && window.sessionStorage) {
                const item = window.sessionStorage.getItem('language-endpoint') || null;
                if (item) {
                    return JSON.parse(item);
                }
            }
        } catch (err) {
            console.warn(err);
        }
        return null;
    }


    public getTranslation(lang: string): Observable<Object> {
        const languageEndpoint = KeyTranslateLoader.getLanguageEndpoint();
        if (!languageEndpoint || lang === 'en') {
            return this.http.get(`${this.prefix}${lang}${this.suffix}`);
        } else {
            console.log('LOADING LANGUAGE OVERRIDE:', languageEndpoint);
            return this.http.get(languageEndpoint.endpoint).pipe(catchError(err => {
                console.warn('FAILED TO LOAD LANGUAGE OVERRIDE');
                console.error(err);
                return of({});
            }));
        }
    }



}
