import { CommonModule } from '@angular/common';
import { Component, OnInit, computed, input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { KuiIconModule } from 'app/key-ui';
import { PipeModule } from 'app/shared/pipes/pipe.module';
import { VideoAsset } from '../asset-selection/asset-selection.component';
import { ClipSettings } from '../clip-builder/clip-builder.component';

@Component({
    selector: 'key-video-request-summary',
    templateUrl: 'request-summary.component.html',
    styleUrls: ['request-summary.component.scss'],
    imports: [CommonModule, KuiIconModule, TranslateModule, PipeModule],
    standalone: true,
})
export class VideoRequestSummaryComponent implements OnInit {

    asset = input.required<VideoAsset>();
    selectedCameras = input.required<string[]>();
    clipSettings = input.required<ClipSettings>();

    cameraList = computed(() => {
        return this.asset().cameras
            .filter(camera => this.selectedCameras().includes(camera.key))
            .map(camera => camera.value);
    })

    constructor(
        public i18n: TranslateService,
    ) { }

    ngOnInit() { }

}
