import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

export type OpenSidePanelType = 'widget-settings' | 'dashboard-settings' | 'dashboard-selection';

@Injectable()
export class SidePanelService {
    // widgets settings lives on the dashboard component itself and can't inform higher up components
    // of the change with an output cause there is a <router-outlet> in between. So using an observable
    // to communicate this change between components
    openSidePanelSubject = new Subject<OpenSidePanelType>();
    get openSidePanel$(): Observable<OpenSidePanelType> {
        return this.openSidePanelSubject.asObservable();
    }

    openSidePanel(panel: OpenSidePanelType) {
        this.openSidePanelSubject.next(panel);
    }
}
