<div class="kui-tag-input position-relative" [class.border-danger]="invalid">
    <p-autoComplete
        dataKey="id"
        field="name"
        inputStyleClass="kui-tag-input__textbox"

        [disabled]="disabled"
        [emptyMessage]="'SHARED.NO_RESULTS_FOUND' | translate"
        [suggestions]="suggestionItems"
        
        [forceSelection]="!freeText"
        [multiple]="multiple"
        [placeholder]="placeholder"

        (onSelect)="addItem($event)"
        (onUnselect)="removeItem($event)"
        (completeMethod)="doSearch($event)"
        (onClear)="clearItem()"

        (onBlur)="onKeyUp({ key: 'Enter' })"
        (onKeyUp)="onKeyUp($event)"

        [(ngModel)]="multiple ? selectedItems : selectedItems[0]"
    >
        <ng-template let-item pTemplate="item" class="kui-tag-input__item">
            <span [innerHTML]="highlightTerm(item.name)"></span>
        </ng-template>

        <ng-template let-value pTemplate="selectedItem">
            <kui-badge [style]="'primary'">
                <span [title]="value.name" class="text-truncate">{{ value.name ?? value.value.name }}</span>
                <kui-icon name="times" (click)="removeItem(value)"></kui-icon>
            </kui-badge>
        </ng-template>
        <ng-template let-value pTemplate="loadingicon"/>
    </p-autoComplete>

    <kui-loader *ngIf="loading" size="small"></kui-loader>
</div>
