import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KuiRadioDirective } from './radio.directive';

@NgModule({
  imports: [CommonModule],
  exports: [KuiRadioDirective],
  declarations: [KuiRadioDirective],
})
export class KuiRadioModule {}
