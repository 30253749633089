import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

/** Use the PollManagerService to create pollers so that you have the option to mock them in testing */
@Injectable()
export class PollService {

    create<TOptions, TResult>(intervalMilliseconds: number, processCallback: (options: TOptions) => Promise<TResult>): Poll<TOptions, TResult> {
        return new Poll(intervalMilliseconds, processCallback);
    }

}

/** Use the mock version in testing to prevent use of setTimeout() */
@Injectable()
export class MockPollService extends PollService {

    create<TOptions, TResult>(intervalMilliseconds: number, processCallback: (options: TOptions) => Promise<TResult>): Poll<TOptions, TResult> {
        return new MockPoll(intervalMilliseconds, processCallback);
    }

}

export enum PollState {
    stopped,
    running,
    paused,
}


/** The Poll class implements a timed poll callback with extra care taken to prevent overlapping results when options are changed. */
export class Poll<TOptions, TResult> {

    private stopped = true;
    private paused: boolean;
    private timeoutHandle: any;
    private runId = 0;

    private valueSubject = new BehaviorSubject<TResult | Error>(null);
    public values$ = this.valueSubject.asObservable();

    protected options: TOptions;

    get state(): PollState {
        if (this.timeoutHandle === 0) {
            return PollState.stopped;
        } else { // we're running
            if (this.paused) {
                return PollState.paused;
            } else {
                return PollState.running;
            }
        }
    }

    constructor(public intervalMilliseconds: number, private processCallback: (options: TOptions) => Promise<TResult>) {
    }

    start(options: TOptions, delayStart?: boolean) {
        this.options = options;
        clearTimeout(this.timeoutHandle);
        this.stopped = false;
        this.paused = false;
        this.runId++;
        const run = () => {
            const curentRunId = this.runId;
            if (this.paused) {
                this.timeoutHandle = setTimeout(() => run(), Math.min(1e3, this.intervalMilliseconds));
                return;
            }
            return Promise.resolve().then(() => { // wrap the callback in a promise to catch any errors that may occur
                if (this.stopped || this.runId !== curentRunId) { return; } // we've been stopped or re-run since this chain was started, just let this chain die
                return this.processCallback(options).then((data) => {
                    if (this.stopped || this.runId !== curentRunId) { return; } // we've been stopped or re-run since this chain was started, just let this chain die
                    if (this.paused) { return run(); } // if we've paused this, don't process or emit the data when it finally comes back
                    this.valueSubject.next(data);
                    this.timeoutHandle = setTimeout(() => run(), this.intervalMilliseconds);
                });
            }).catch(err => {
                if (this.stopped || this.runId !== curentRunId) { return; } // we've been stopped or re-run since this chain was started, just let this chain die
                if (this.paused) { return run(); } // if we've paused this, don't process or emit the data when it finally comes back
                this.valueSubject.next(err);
                this.timeoutHandle = setTimeout(() => run(), this.intervalMilliseconds * 3);
            });
        };
        // delayStart === true will only start polling after the first interval instead of immediately 
        this.timeoutHandle = setTimeout(() => run(), delayStart ? this.intervalMilliseconds : 0);
    }

    updateInterval(intervalMilliseconds: number) {
        if (this.intervalMilliseconds !== intervalMilliseconds) {
            const reduced = this.intervalMilliseconds > intervalMilliseconds;
            this.intervalMilliseconds = intervalMilliseconds;
            if (reduced && this.state === PollState.running) {
                this.start(this.options);
            }
        }
    }

    stop() {
        this.stopped = true;
        clearTimeout(this.timeoutHandle);
        this.timeoutHandle = 0;
        this.valueSubject.next(null); // indicates that we've stopped
    }

    pause() {
        this.paused = true;
    }

    resume() {
        this.paused = false;
    }

    /** execute the callback immediate, don't wait for the timeout */
    fire(options?: TOptions): Promise<TResult> {
        options = options || this.options;
        const prevState = this.state;
        if (prevState === PollState.running) {
            this.pause();
        }
        return Promise.resolve().then(() => { // wrap the callback in a promise to catch any errors that may occur
            return this.processCallback(options).then((data) => {
                if (prevState === PollState.running) {
                    this.resume();
                }
                this.valueSubject.next(data);
                return data;
            });
        }).catch(err => {
            if (prevState === PollState.running) {
                this.resume();
            }
            this.valueSubject.next(err);
            throw err;
        });
    }

}


export class MockPoll<TOptions, TResult> extends Poll<TOptions, TResult> {

    start(options: TOptions) {
        this.options = options;
        // NO-OP method, in testing we don't want any timers running. use the `fire` method instead
    }


}
