import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, HostBinding } from '@angular/core';
import { FormBuilderField } from 'app/shared/components/form-builder';

@Component({
    selector: 'key-form-builder-color-field',
    templateUrl: './color.component.html',
})
export class KeyFormBuilderColorFieldComponent implements OnInit {

    @Input() field: FormBuilderField;
    @Input() values: { [key: string]: any };
    @Input() error: string; // set this error value externally to have the default error highligh and display kick in

    @Output() onChange: EventEmitter<{ value: string, dirty: boolean }> = new EventEmitter();

    @HostBinding('class') classes = 'd-flex align-items-center';

    get value(): any {
        return this.field.getValue ? this.field.getValue(this.field, this.values) : this.values[this.field.id];
    }
    set value(val: any) {
        if (this.field.setValue) {
            const promise = this.field.setValue(this.field, this.values, val);
            if (promise && promise.then) {
                promise.then(() => this.ref.markForCheck());
            }
        } else {
            this.values[this.field.id] = val;
        }
    }

    constructor(private ref: ChangeDetectorRef) { }

    validate(): boolean {
        return !this.field.required || !!this.value;
    }

    ngOnInit() {
        this.value = this.value || this.field.value;
    }

    changeColor(color: string) {
        this.value = color;
        this.onChange.emit({ value: color, dirty: true });
    }
}
