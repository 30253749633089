import {
    Component,
    Input,
    OnInit,
    ElementRef,
    Renderer2,
    AfterViewInit,
    ViewChild,
    ChangeDetectionStrategy,
} from '@angular/core';

import { IKuiToolTipDimensions } from './tooltip.model';

@Component({
    selector: 'kui-tooltip',
    templateUrl: './tooltip.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KuiTooltipComponent implements OnInit, AfterViewInit {
    classNames: string[] = ['tooltip', 'show'];

    @Input() title: string;
    @Input() dimensions: IKuiToolTipDimensions;

    @ViewChild('tooltip') tooltipElement;

    constructor(public el: ElementRef, private renderer: Renderer2) { }

    ngOnInit() {
        this.classNames.push(`tooltip-${this.dimensions.placement}`);
        this.classNames.push(`tooltip-align-${this.dimensions.align}`);
    }

    ngAfterViewInit() {
        if (this.tooltipElement) {
            const hostDimensions = this.dimensions;
            const tooltip = this.tooltipElement.nativeElement;
            const tooltipDimensions = this._getDimensions(tooltip);

            /*
            * set the top and left position of tooltip according to its placement
            */
            this._setTopLeftPosition(tooltip, hostDimensions, tooltipDimensions);
        }    
    }

    private _setTopLeftPosition(el: HTMLElement, host, tooltip) {
        if (host.placement === 'top' || host.placement === 'bottom') {
            if (host.align === 'center') {
                this.renderer.setStyle(el, 'left', '50%');
                this.renderer.setStyle(el, 'margin-left', `-${tooltip.width / 2}px`);
            }

            if (host.placement === 'top') {
                this.renderer.setStyle(el, 'bottom', `${host.height}px`);
            }

            if (host.placement === 'bottom') {
                this.renderer.setStyle(el, 'top', `${host.height}px`);
            }
        }

        if (host.placement === 'left' || host.placement === 'right') {
            if (host.align === 'center') {
                this.renderer.setStyle(el, 'top', '50%');
                this.renderer.setStyle(el, 'margin-top', `-${tooltip.height / 2}px`);
            }

            if (host.placement === 'left') {
                this.renderer.setStyle(el, 'right', `${host.width + 5}px`);
            }

            if (host.placement === 'right') {
                this.renderer.setStyle(el, 'left', `${host.width + 5}px`);
            }
        }
    }

    private _getDimensions(el: HTMLElement) {
        const height = el.offsetHeight;
        const width = el.offsetWidth;

        return { height, width };
    }
}
