import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation, WritableSignal, computed, input, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { TripItem } from 'app/services/trips/trips.service';
import { MapTripService } from 'app/shared/components';
import { PipeModule } from 'app/shared/pipes/pipe.module';
import { Slider, SliderChangeEvent, SliderModule } from 'primeng/slider';
import { ReplaySubject, Subject, debounceTime, filter, takeUntil } from 'rxjs';

@Component({
    selector: 'key-playback-slider',
    templateUrl: 'playback-slider.component.html',
    styleUrls: ['playback-slider.component.scss'],
    imports: [SliderModule, CommonModule, FormsModule, PipeModule],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
})
export class PlaybackSliderComponent implements OnInit, OnDestroy, AfterViewInit {

    length = input.required<number>();
    trip = input.required<TripItem>();

    handlePosition: WritableSignal<number> = signal<number>(0);
    sliderValue: WritableSignal<number> = signal(0);
    currentDate: WritableSignal<string> = signal("");

    startDate = computed(() => this.trip().telemetry[0].date);
    endDate = computed(() => this.trip().telemetry[this.trip().telemetry.length - 1].date);

    changed$ = new Subject<number>();
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    @ViewChild('slider', { static: true }) slider: Slider;

    constructor(
        public tripService: MapTripService
    ) {
        toObservable(this.trip).pipe(
            takeUntil(this.destroyed$)
        ).subscribe(_ => {
            // we've changed the trip, let's select the starting point.
            this.tripService.selectWaypointIndex(0);
        });
    }

    ngOnInit() {
        this.currentDate.set(this.startDate());

        this.changed$.pipe(
            debounceTime(100),
            takeUntil(this.destroyed$)
        ).subscribe(res => {
            // slider changed, updated selected waypoint.
            this.tripService.selectWaypointTime(this.trip().telemetry[res].date);
            this.currentDate.set(this.trip().telemetry[res].date);
        });
        this.tripService.selectedTelemetry$.pipe(
            takeUntil(this.destroyed$),
            filter((telemetry) => !!telemetry),
        ).subscribe(selected => {
            // user has clicked a marker on the map, update our state to reflect this waypoint.
            const telemetryIndex = this.trip().telemetry.findIndex(b => b.date === selected.date);
            this.sliderValue.set(telemetryIndex);
            this.currentDate.set(this.trip().telemetry[telemetryIndex].date);
            setTimeout(() => { // arrrgh
                this.handlePosition.set(this.slider.handleValue)
            });
        });
    }

    ngAfterViewInit(): void {
        // select the first item in the trip
        this.tripService.selectWaypointIndex(0);
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    handleChange($event: SliderChangeEvent) {
        this.changed$.next($event.value);
    }

    handleSlideEnd() {
        this.handlePosition.set(this.slider.handleValue)
    }

}
