import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KuiButtonGroupComponent } from './button-group.component';
import { KuiButtonToolbarComponent } from './button-toolbar.component';

const KUI_BUTTON_GROUP_MODULES = [
  KuiButtonGroupComponent,
  KuiButtonToolbarComponent,
];

@NgModule({
  imports: [CommonModule],
  exports: KUI_BUTTON_GROUP_MODULES,
  declarations: KUI_BUTTON_GROUP_MODULES,
})
export class KuiButtonGroupModule {}
