import { Injectable } from '@angular/core';
import { AnalyticsChartData } from '../chart.component';
import { AnalyticsChartService } from '../chart.service';
import { AnalyticsService } from '../../services/analytics.service';
import { AnalyticsCellValue } from '../../analytics.model';
import { AnalyticsOutputSettings } from '@key-telematics/fleet-api-client';
import { DataPoint, Label } from 'app/shared/components/graph/graph.model';
import * as moment from 'moment-timezone';

export type AnalyticsChartStatDataType = 'average' | 'total' | 'first' | 'last';
export type AnalyticsChartStatTrendType = 'none' | 'percentage' | 'actual';

export interface AnalyticsChartStatData extends AnalyticsChartData {
    data: AnalyticsChartStatDataType;
    key?: string;
    color?: string;
    sparkline?: boolean;
    trend?: AnalyticsChartStatTrendType;
    labels: Label[];
    targetValue?: number;
}

@Injectable()
export class AnalyticsChartStatService extends AnalyticsChartService<AnalyticsChartStatData> {
    constructor(public analytics: AnalyticsService) {
        super(analytics);
    }

    getData(cellset: AnalyticsCellValue[][], settings: AnalyticsOutputSettings): AnalyticsChartStatData {
        const { datasets, labels } = this.getGraphDataFromCellset(cellset, settings);
        const { key, ...stat } = settings.stat;

        return {
            ...stat,
            key: key || datasets[0] && datasets[0].uniquename,
            datasets,
            labels,
        };
    }

    getAverageFromData(data: DataPoint[]): number {
        return this.getTotalFromData(data) / data.length;
    }

    getTotalFromData(data: DataPoint[]): number {
        return data.map(x => x.raw).reduce((total, value) => total + value, 0);
    }

    getTrendValue(data: DataPoint[], isPercentage?: boolean): number {
        const parse = value => parseFloat(value.toFixed(2));
        const first = parse(data[0].raw);
        const last = parse(data[data.length - 1].raw);
        if (first === 0) { // prevent returning Infinity when dividing last by 0
            return parse(last * 100);
        }
        const diff = last - first;
        return parse(isPercentage ? (diff / first) * 100 : diff);
    }

    convertRawToValue(raw: number, reference?: string): string {
        if (reference) {
            if (reference.includes(':')) { 
                // this is a time value, the raw value is stored as decimal time...
                const duration = moment.duration(raw, 'hours');
                const formatNumber = (number: number) => number.toLocaleString(undefined, { minimumIntegerDigits: 2 });

                const hoursValue = Math.floor(duration.asHours());
                const minutesValue = Math.floor(duration.asMinutes()) - hoursValue * 60;
                const secondsValue = Math.floor(duration.asSeconds()) - hoursValue * 60 * 60 - minutesValue * 60;
                const hours = formatNumber(hoursValue);
                const minutes = formatNumber(minutesValue);
                const seconds = formatNumber(secondsValue);

                return `${hours}:${minutes}:${seconds}`;
            }
        }

        return Math.round(raw).toString();
    }
}
