<ngx-bootstrap-multiselect 
    [ngClass]="{
    'select-dropdown': true,
    'single': !multiselect
  }"
    [options]="pluginSpecificOptions"
    [texts]="text"
    [settings]="settings"
    [ngModel]="selected"
    (ngModelChange)="onChange($event)">
</ngx-bootstrap-multiselect >