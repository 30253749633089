@if(isMobile()) {
    <!-- MOBILE -->
    @if(trip()) {
        <button kui-button color="secondary" class="mobile--helper" (click)="trip.set(null)">
            <kui-icon name="chevron-left"></kui-icon>
            <p class="p-2 m-0">{{ 'MEDIA.VIDEO_REQUEST.MODAL.CHANGE_TRIP' | translate }}</p>
        </button>

        <key-event-selector 
            [asset]="asset()" 
            [trip]="trip()" 
            (settingsChanged)="handleSettingsChange($event)">
        </key-event-selector>
    } @else {
        <key-trip-list [asset]="asset()" [initialDate]="date()" (tripSelected)="handleTripSelection($event)"></key-trip-list>
    }
} @else {
    <!-- DESKTOP -->
    <key-trip-list [asset]="asset()" (tripSelected)="handleTripSelection($event)"></key-trip-list>

    @if(trip()) {
        <key-event-selector 
            [asset]="asset()" 
            [trip]="trip()" 
            (settingsChanged)="handleSettingsChange($event)">
        </key-event-selector>
    } @else {
        <div class="guidance">
            {{ 'MEDIA.VIDEO_REQUEST.MODAL.SELECT_TRIP' | translate }}
        </div>
    }
}