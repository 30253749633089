import { Component, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
    selector: 'kui-nav',
    templateUrl: './nav.component.html',
})
export class KuiNavComponent {
    @Input() orient = 'vertical';
    @ViewChild('nav', { static: true }) el: ElementRef<HTMLDivElement>;
}
