import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'app/app.service';
import { LocalStore, Poll, PollService } from 'app/services';
import { FormBuilderDefinition, FormBuilderField } from 'app/shared/components/form-builder';
import { DateRange } from 'app/shared/utils/dates';
import { CompletedReportResponse, QueuedReportResponse, QueueReportRequest, ReportDefinitionLayoutOptions, ReportDefinitionStyleLimits, ReportTemplateResponse, ReportTemplateUpdateRequest, ScheduledReportListItem, ScheduledReportResponse } from '@key-telematics/fleet-api-client';
import * as moment from 'moment-timezone';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { cloneDeep, set } from 'lodash';

export interface ReportDefinition {
    id: string;
    def: {
        id: string,
        style: string
    };
    type: 'dataset' | 'analytics';
    name: string;
    styleId: string;
    styleName: string;
    description: string;
    tags: string[];
    schedulable: boolean;
    limits: ReportDefinitionStyleLimits;
    parameters: FormBuilderDefinition;
    layout: ReportDefinitionLayoutOptions;
    userVisible: boolean;
    template?: ReportTemplateResponse;
}

export interface ReportColumnDefinition {
    id: string;
    title: string;
    width: number;
    def: boolean;
    req: boolean;
    format: string;
    footer: string;
    footerFormat: string;
    align: string;
}

export interface LegacyReportSampleData {
    header: { [key: string]: string };
    parameters: { [key: string]: string };
    table: { [key: string]: string }[];
    columns: ReportColumnDefinition[];
}

export interface CachedReportParameters {
    id: string;
    params: any;
    layout: any;
}

export type QueueMode = 'user' | 'company';

export interface QueuePollOptions {
    clientId: string;
    userId?: string;
    range: DateRange;
}
export interface QueuePollResult {
    reset: boolean;
    options: QueuePollOptions;
    items: QueuedReportResponse[];
}


@Injectable()
export class ReportingService implements OnDestroy {

    private reportDefinitions: ReportDefinition[];
    reportDefinitions$ = new BehaviorSubject<ReportDefinition[]>(null);
    private reportDefinitionLoader: Promise<ReportDefinition[]>;

    queueOptions: QueuePollOptions;
    queueSequence: number = null;
    queued: Poll<QueuePollOptions, QueuePollResult>;
    queuedRunningCount = 0;

    private recentReportsSubject = new BehaviorSubject<string[]>([]);
    recentReports$ = this.recentReportsSubject.asObservable();

    private favouriteReportsSubject = new BehaviorSubject<string[]>([]);
    favouriteReports$ = this.favouriteReportsSubject.asObservable();

    private mode = new BehaviorSubject<QueueMode>('user');
    private range = new Subject<DateRange>();

    store: LocalStore;

    translationOutput = null; // {}; // enable this if you need to export the list of translations

    constructor(
        public poll: PollService,
        private http: HttpClient, // TODO: remove, need for prototyping only
        private app: AppService,
        private router: Router,
        private i18n: TranslateService
    ) {

        this.store = this.app.getStore('reporting-service');
        this.recentReportsSubject.next(this.store.get('recent-reports') || []);
        this.favouriteReportsSubject.next(this.store.get('favourite-reports') || []);

        this.queued = this.poll.create<QueuePollOptions, QueuePollResult>(5e3,
            (options) => {
                const initial = this.queueSequence === null;
                return Promise.all([
                    initial ? this.app.api.reports.listCompletedReports(options.range.start, options.range.end, options.clientId, options.userId).then(response => response.items) : [],
                    this.app.api.reports.listQueuedReports(options.clientId, options.userId, null).then(response => response.items),
                ]).then(([history, queued]) => {
                    this.queueSequence = queued.reduce((p, c) => Math.max(p, new Date(c.updateDate).getTime()), 0);
                    this.queuedRunningCount = queued.filter(x => ['queued', 'running', 'retry'].includes(x.status)).length;
                    this.adjustPollingInterval();
                    return {
                        reset: initial,
                        options: options,
                        items: history.concat(queued),
                    };
                });
            }
        );

        combineLatest([this.app.client$, this.app.user$, this.mode, this.range])
            .subscribe(([client, user, mode, range]) => {
                this.queued.stop();
                this.queueSequence = null;
                if (client && user && mode && range) {
                    this.queueOptions = {
                        range: range,
                        userId: mode === 'user' ? user.id : undefined,
                        clientId: client.id,
                    };
                    this.queued.start(this.queueOptions);
                }
            });

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                this.adjustPollingInterval(); // make sure to adjust when we're navigating between pages
            });

    }

    ngOnDestroy() {
        this.queued.stop();
    }

    adjustPollingInterval() {
        // adjust the polling update interval based on whether the user is on the queued screen or not and
        // whether he's waiting for a report to complete
        if (this.router.routerState.snapshot.url.includes('reporting/queued') || this.router.routerState.snapshot.url.includes('mapsearch')) {
            const interval = this.queuedRunningCount ? 3e3 : 15e3;
            this.queued.resume();
            this.queued.updateInterval(interval);
        } else {
            this.queued.pause();
        }
    }

    setMode(mode: QueueMode) {
        this.mode.next(mode);
    }

    setDateRange(range: DateRange) {
        this.range.next(range);
    }

    clearRecent() {
        this.store.set('recent-reports', []);
        this.recentReportsSubject.next([]);
    }

    pushRecentReport(id: string) {
        const recent = this.recentReportsSubject.value;
        if (recent.indexOf(id) === -1) {
            recent.push(id);
            while (recent.length > 5) {
                recent.splice(0, 1);
            }
            this.store.set('recent-reports', recent);
            this.recentReportsSubject.next(recent);
        }
    }

    clearFavourites() {
        this.store.set('favourite-reports', []);
        this.favouriteReportsSubject.next([]);
    }

    toggleReportFavourite(id: string) {
        const favourites = this.favouriteReportsSubject.value;
        const idx = favourites.indexOf(id);
        if (idx === -1) {
            favourites.push(id);
        } else {
            favourites.splice(idx, 1);
        }
        this.store.set('favourite-reports', favourites);
        this.favouriteReportsSubject.next(favourites);
    }

    getCachedReportParameters(id: string): CachedReportParameters {
        try {
            const cache = this.store.get<{ items: CachedReportParameters[] }>(`report-params`) || { items: [] };
            const cacheId = `${this.app.client && this.app.client.id}-${this.app.user && this.app.user.id}-${id}`;
            return cache.items.find(x => x.id === cacheId);
        } catch (err) {
            console.error(err);
            return null; // if we have a cache error, just return null
        }
    }

    setCachedReportParameters(item: CachedReportParameters): void {
        try {
            const cache = this.store.get<{ items: CachedReportParameters[] }>(`report-params`) || { items: [] };
            const cacheId = `${this.app.client && this.app.client.id}-${this.app.user && this.app.user.id}-${item.id}`;
            const idx = cache.items.findIndex(x => x.id === cacheId);
            if (idx !== -1) { // if it's already in the cache, remove it
                cache.items.splice(idx, 1);
            }
            cache.items.push({ ...item, id: cacheId });
            while (cache.items.length > 5) {
                cache.items.splice(0, 1);
            }
            this.store.set(`report-params`, cache);
        } catch (err) {
            console.error(err); // cache errors are not fatal
        }
    }

    getReportDefinitions(ownerId: string, allowedReports: string[], includeAnalyticsReports: boolean): Promise<ReportDefinition[]> {

        return Promise.all([
            this.app.api.entities.listReportDefinitions(ownerId).then(result => result.items),
            this.app.api.entities.listReportTemplates(ownerId).then(result => result.items).catch(err => {
                console.error(err);
                return [] as ReportTemplateResponse[];
            }),
        ]).then(async ([definitions, templates]) => {

            let results: ReportDefinition[] = [];
            definitions.forEach(item => {

                if (item.source === 'analytics' && (!includeAnalyticsReports)) {
                    return; // don't include analytics reports if the feature isn't enabled for the client
                }

                item.styles.forEach(style => {
                    try {
                        const params = style.parameters && JSON.parse(style.parameters);

                        // This NEEDS to be removd once trip limits/trip violations are ready
                        if (!this.app.flags?.tripLimitsEnabled()) {
                            for (let group of params.groups) {
                                for (let field of group.fields) {
                                    field.values = field.values?.filter((x: any) => x.key !== 'tripviolationevent')
                                }
                            }
                        }
                

                        results.push(this.translateReportDefinition({
                            id: item.id + '-' + style.id,
                            def: { id: item.id, style: style.id },
                            name: item.name,
                            styleId: style.id,
                            styleName: style.name,
                            description: item.description,
                            type: item.source,
                            tags: item.tags || ['other'],
                            schedulable: style.schedulable,
                            limits: style.limits,
                            parameters: params,
                            layout: style.layout,
                            userVisible: !allowedReports || allowedReports.indexOf(item.id) !== -1,
                        }));
                    } catch (err) {
                        console.error(err);
                        console.log(item, style);
                    }
                });
            });

            // do this after translation
            results.forEach(item => {
                if (!['analytics', 'default'].includes(item.def.style)) {
                    item.name = `${item.name} - ${item.styleName}`;
                }
            });

            // add the templated definitions
            results = results.concat(templates.map(template => this.createDefinitionFromTemplate(results, cloneDeep(template)))).filter(x => x);
            return results;
        });

    }

    loadReportDefinitions(): Promise<ReportDefinition[]> {
        if (!this.reportDefinitionLoader) {
            const allowedReports = this.app.features.page.reporting.reports.split(',');
            const analyticsFeatures = this.app.features.page.reporting.show.analytics;
            this.reportDefinitionLoader = this.getReportDefinitions(this.app.client.id, allowedReports, analyticsFeatures).then(results => {
                // filter reports out based on the client features, but allow all if user is not a 'client' user
                this.reportDefinitions = results.filter(x => x.userVisible || this.app.user.owner.type !== 'client');
                this.reportDefinitions$.next(this.reportDefinitions);
                return this.reportDefinitions;
            });
        }
        return this.reportDefinitionLoader;
    }

    private createDefinitionFromTemplate(defs: ReportDefinition[], template: ReportTemplateResponse): ReportDefinition {
        const def = cloneDeep(defs.find(x => x.id === template.config.definitionId + '-' + template.config.styleId));
        if (def) {

            // The template may have zone tags, swap them out for geofences now
            if (template.tags?.includes('zones')) {
                template.tags = [
                    ...template.tags.filter(tag => tag !== 'zones'),
                    'geofences',
                ];
            };

            return {
                ...def,
                id: template.id + '-template',
                name: template.name,
                description: template.description,
                template: template,
                userVisible: true,
                tags: (template.tags || def.tags).concat('custom'),
            };
        }
        return null;
    }

    translateReportDefinition(item: ReportDefinition, force: boolean = false): ReportDefinition {

        const translate = (prefix: string, field: string, value: string): string => {

            if (this.translationOutput) {
                set(this.translationOutput, `REPORTING.ITEMS.${prefix}.${field.toUpperCase().replace(/[^a-zA-Z\d]/g, '_')}`, value);
            }

            let result = this.i18n.instant(`REPORTING.ITEMS.${prefix}.${field.toUpperCase().replace(/[^a-zA-Z\d]/g, '_')}`) as string;
            // fall back to the original english if there are no translations for this string
            if (result.startsWith('REPORTING.') && !force) {
                result = value;
            }
            return result;
        };

        const reportPrefix = `${item.type.toUpperCase()}.${item.name.toUpperCase().replace(/[^a-zA-Z\d]/g, '_')}.${item.styleId.toUpperCase()}`;
        item.name = translate(reportPrefix, 'NAME', item.name);
        item.description = translate(reportPrefix, 'DESC', item.description);
        item.styleName = translate(reportPrefix, 'STYLE', item.styleName);

        if (item.layout) {
            item.layout.columns.forEach(column => {
                column.title = translate(`COLUMNS`, column.title, column.title);
            });
            item.layout.grouping.forEach(group => {
                group.name = translate(`GROUP_BY`, group.name, group.name);
            });
        }

        if (item.template) {
            item.template.config?.parameters?.layout?.columns?.forEach(column => {
                column.title = translate(`COLUMNS`, column.title, column.title);
            });
        }

        const translateField = (field: FormBuilderField) => {
            field.title = translate(`FIELDS`, field.title, field.title);
            if (field.values) {
                field.values.forEach(value => {
                    value.value = translate(`FIELDS.VALUES`, value.value.toString(), value.value.toString());
                    if (value.fields) {
                        value.fields.forEach(x => translateField(x));
                    }
                });
            }
        };

        if (item.parameters && item.parameters.groups) {
            item.parameters.groups.forEach(group => {
                group.name = translate(`FIELD_GROUP`, group.name, group.name);
                group.fields.forEach(x => translateField(x));
            });
        }

        // The template may have zone tags, swap them out for the geofence tag
        if (item.tags?.includes('zones')) {
            item.tags = [
                ...item.tags.filter(tag => tag !== 'zones'),
                'geofences',
            ];
        }

        return item;

    }


    getReportDefinition(id: string): Promise<ReportDefinition> {
        return this.loadReportDefinitions().then(items => {
            return items.find(x => x.id === id);
        });
    }

    getReportSampleDataRows(_id: string): Promise<LegacyReportSampleData> {

        return this.http.get('/assets/docs/report-sample-data.json').toPromise().then(result => {
            return result as any;
        });

    }

    getCompletedReport(id: string, allowCached?: boolean): Promise<CompletedReportResponse> {
        if (allowCached === false) {
            return this.app.api.reports.getCompletedReport(id);
        }
        return this.queued.fire(this.queueOptions).then(result => {
            const item = result && result.items.find(x => x.id === id);
            if (item) {
                if (item.source === 'analytics' && item.status === 'completed') {
                    // if this is an analytics report we need to go fetch the details fresh from the api
                    return this.app.api.reports.getCompletedReport(id);
                } else {
                    return item;
                }
            } else {
                return this.app.api.reports.getCompletedReport(id);
            }
        });
    }

    loadScheduledReports(): Promise<ScheduledReportListItem[]> {
        return this.app.api.entities.listScheduledReports(this.app.client.id, 0, 100, 'name', 'state=active').then(result => result.items);

    }

    getScheduledReport(id: string): Promise<ScheduledReportResponse> {
        return this.app.api.entities.getScheduledReport(id);
    }

    queueReport(report: QueueReportRequest): Promise<QueuedReportResponse> {
        // the reporting service expects the report parameter `dateStart` and `dateEnd` to be in the user's
        // local time, and all of our date components work on UTC, so do a conversion before submitting it
        if (report.config && report.config.parameters) {
            Object.keys(report.config.parameters)
                .filter(key => ['dateStart', 'dateEnd'].includes(key))
                .forEach(key => {
                    const val = report.config.parameters[key];
                    if (typeof val === 'string' && val.endsWith('Z') && moment(val).isValid()) {
                        report.config.parameters[key] = moment.utc(val).tz(moment.defaultZone.name).format('YYYY/MM/DD HH:mm:ss');
                    }
                });
        }
        this.queuedRunningCount++; // artificially increase our running count temporarily for interval calculations
        return this.app.api.reports.queueReport(report);
    }

    cancelReport(id: string): Promise<QueuedReportResponse> {
        return this.app.api.reports.cancelReport(id).then(report => {
            return report;
        });
    }

    async saveTemplate(id: string, template: ReportTemplateUpdateRequest): Promise<ReportTemplateResponse> {
        // Convert "geofences" tags into "zones" tags to maintain any existing templates
        if (template.tags?.includes('geofences')) {
            template.tags = [
                ...template.tags.filter(tag => tag !== 'geofences'),
                'zones',
            ]
        };

        return Promise.resolve().then(() => {
            if (id) {
                return this.app.api.entities.updateReportTemplate(id, template);
            } else {
                return this.app.api.entities.createReportTemplate({
                    ownerId: this.app.client.id,
                    ...template,
                });
            }
        }).then(response => {
            const idx = this.reportDefinitions.findIndex(x => x.id.startsWith(response.id));
            if (idx !== -1) {
                this.reportDefinitions.splice(idx, 1);
            }
            const def = this.createDefinitionFromTemplate(this.reportDefinitions, response);
            if (def) {
                this.reportDefinitions.push(def);
            }
            this.reportDefinitions$.next(this.reportDefinitions);
            return response;
        });
    }

    deleteTemplate(id: string): Promise<void> {
        return this.app.api.entities.deleteReportTemplate(id).then(() => {
            const idx = this.reportDefinitions.findIndex(x => x.id.startsWith(id));
            if (idx !== -1) {
                this.reportDefinitions.splice(idx, 1);
                this.reportDefinitions$.next(this.reportDefinitions);
            }
        });
    }


}
