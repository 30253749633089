import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { KuiNavComponent } from './nav.component';
import { KuiNavSectionComponent } from './nav-section.component';
import { KuiNavTitleComponent } from './nav-title.component';

import { KuiNavSubDirective } from './nav-sub.directive';
import { KuiNavLinkDirective } from './nav-link.directive';
import { KuiNavLinkTextDirective } from './nav-link-text.directive';

const DECLARATIONS = [
    KuiNavComponent,
    KuiNavSectionComponent,
    KuiNavTitleComponent,
    KuiNavLinkDirective,
    KuiNavSubDirective,
    KuiNavLinkTextDirective,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports: DECLARATIONS,
  declarations: DECLARATIONS,
})
export class KuiNavModule {  }
