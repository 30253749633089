<kui-datetime
    [disabled]="field.readonly"
    [format]="format"
    [date]="value"
    [min]="field.min"
    [max]="field.max"
    [type]="datetimeType"
    [disableTime]="disableTime"
    (onChange)="dateChanged($event)"
></kui-datetime>
<div
    *ngIf="field.required && touched && !value"
    class="invalid-feedback d-block"
>
    <div>{{ 'FORMS.SHARED.REQUIRED' | translate:{ field: field.title } }}</div>
</div>
<div *ngIf="error"
    class="invalid-feedback d-block">
    <div>{{ error }}</div>
</div>