import { Pipe, PipeTransform } from '@angular/core';
import { MapOption, MapOptionSearchComponent } from '../components';


@Pipe({
    name: 'showSearchInput'
})
export class ShowSearchInputPipe implements PipeTransform {

    transform(mapOption: MapOption<any>): boolean {
        // We should only show the search box when we're on the home page, or showing search results.
        if (!mapOption) {
            return true;
        }

        return mapOption.id === MapOptionSearchComponent.ID;
    }
}
