<span *ngFor="let swatch of field.values"
    class="swatch mr-1 mb-1"
    [class.swatch--small]="field.options?.size === 'small'"
    [title]="swatch.value">
    <kui-color-picker [color]="getValue(swatch.key)"
        [size]="field.options?.size || 'large'"
        [position]="field.options?.position || 'left'"
        (onChange)="setValue(swatch.key, $event)"></kui-color-picker>
    <span *ngIf="swatch.value && field.options?.size !== 'small'"
        class="swatch__name">{{swatch.value}}</span>
</span>