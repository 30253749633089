import { Injectable, Injector, Inject, ComponentFactoryResolver, EmbeddedViewRef, ApplicationRef, ComponentRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';

export interface DynamicModalConfig<TOptions> {
    data?: TOptions;
    actions?: { [key: string]: (result?: any) => void };
}

@Injectable()
export class KuiModalService {
    componentRef: ComponentRef<any>;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector,
        @Inject(DOCUMENT) private document: HTMLDocument
    ) { }

    open<TOptions>(component: any, config: DynamicModalConfig<TOptions>) {

        this.close();

        this.componentRef = this.componentFactoryResolver
            .resolveComponentFactory(component)
            .create(this.injector);

        // add inputs and outputs(actions)
        if (config) {
            this.componentRef.instance.data = config.data;
            this.componentRef.instance.actions = config.actions;
        }

        this.appRef.attachView(this.componentRef.hostView);

        const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;

        this.document.body.appendChild(domElem);

        // open modal component inside of ref component
        setTimeout(() => this.componentRef.instance.openModal());

    }

    close() {
        if (this.componentRef) {
            this.componentRef.instance.closeModal();
            this.appRef.detachView(this.componentRef.hostView);
            this.componentRef.destroy();
        }
    }
}
