// https://videojs.com/guides/angular/

import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation, input, output } from '@angular/core';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';


export interface VideoPlayerOptions {
    controls: boolean;
    maxPlaylistRetries?: number;
    fluid?: boolean;
    aspectRatio?: string;
    autoplay?: boolean;
    html5?: {
        vhs: {
            playlistExclusionDuration: number,
            maxPlaylistRetries: number
        }
    }
    [key: string]: any;
}

export interface VideoPlayerSource {
    src: string;
    type: string;
}


/** Chrome cannot play HLS videos on desktop, so we need this video player to deal with that */
@Component({
    selector: 'key-videojs-player',
    template: `
        <video #target class="video-js w-100 h-100" controls muted playsinline preload="none"></video>
    `,
    styleUrl: './vjs-player.component.scss',
    encapsulation: ViewEncapsulation.None,
    standalone: true
})
export class VjsPlayerComponent implements OnInit, OnDestroy {

    @ViewChild('target', { static: true }) target: ElementRef;

    // See options: https://videojs.com/guides/options
    options = input.required<VideoPlayerOptions>();
    source = input.required<VideoPlayerSource>();

    onReady = output();
    onError = output<any>();
    onEnded = output();

    private player: Player;

    constructor() {}

    ngOnInit() {
        this.player = videojs(this.target.nativeElement, this.options(), () => {
            this.onReady.emit();
        });
        this.player.src(this.source());
        this.player.on('error', (err: any) => this.onError.emit(err));
    }

    ngOnDestroy() {
        if (this.player) {
            this.player.dispose();
        }
    }

}