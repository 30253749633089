import { Component, Input, OnInit, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { FormBuilderField, BaseFormBuilderFieldComponent } from 'app/shared/components/form-builder';
import { FormBuilderAction } from '../form-builder.model';
import { KuiDropdownComponent } from 'app/key-ui/dropdown/dropdown.component';
import { KuiTreeSelectNode, KuiTreeSelectComponent } from 'app/key-ui/tree-select/tree-select.component';

interface TagItem {
    id?: string;
    name: string;
}

@Component({
    selector: 'key-form-builder-tags-field',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss'],
})
export class KeyFormBuilderTagsFieldComponent implements BaseFormBuilderFieldComponent, OnInit {

    @Input() field: FormBuilderField;
    @Input() values: { [key: string]: any };
    @Input() error: string; // set this error value externally to have the default error highligh and display kick in

    @Output() onChange: EventEmitter<{ value: TagItem, dirty: boolean }> = new EventEmitter();

    selectedItems: TagItem[] = [];
    autoCompleteItems: TagItem[] = [];

    touched = false;
    dirty = false;
    overlay: boolean;

    get value(): any[] {
        return (this.field.getValue ? this.field.getValue(this.field, this.values) : this.values[this.field.id]) || this.field.value || [];
    }

    set value(val: any[]) {
        if (this.field.setValue) {
            const promise = this.field.setValue(this.field, this.values, val);
            if (promise && promise.then) {
                promise.then(() => this.ref.markForCheck());
            }
        } else {
            this.values[this.field.id] = val;
        }
    }

    constructor(private ref: ChangeDetectorRef) { }

    validate(): boolean {
        this.touched = true;
        this.ref.markForCheck();
        return !this.field.required || this.selectedItems.length > 0;
    }

    ngOnInit() {
        this.selectedItems = this.value.map(x => ({
            id: x.id || x,
            name: x.name || x,
        }));
        this.autoCompleteItems = this.field.values ? this.field.values.map(x => ({ id: x.key, name: x.value.toString() })) : null;
    }

    updateValues() {
        this.dirty = true;
        this.touched = true;
        this.value = this.selectedItems.map(x => x.id || x.name);
        this.onChange.emit({ value: this.values[this.field.id], dirty: this.dirty });
        this.ref.markForCheck();
    }

    addTag(value) {
        this.selectedItems = [...this.selectedItems, value];
        this.updateValues();
    }

    removeTag(value) {
        const idx = this.selectedItems.findIndex(x => x.id === value.id);
        this.selectedItems.splice(idx, 1);
        this.updateValues();
    }

    onActionClick(action: FormBuilderAction, id?: string) {
        action.click(id || action.id, this.field, this.values);
    }

    onDropdownClick(dropdown: KuiDropdownComponent, tree: KuiTreeSelectComponent, event: KuiTreeSelectNode) {
        this.dirty = true;

        const { id, name } = event.node.data;
        if (event.node.data && !this.selectedItems.find(x => x.id === id)) {
            this.addTag({
                id: id,
                name: name,
                filter: { name, id },
            });
        }
        if (event.eventName === 'activate') {
            tree.clearSelection();
        }
        dropdown.toggle();
    }
}
