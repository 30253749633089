import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationEnd, NavigationExtras } from '@angular/router';

@Injectable()
export class RouteReuseNavigationGuard  {

    currentUrl: string;
    lastUrl: { [key: string]: string } = {};
    redirects: { [key: string]: number } = {};

    constructor(private router: Router) {
        this.router.events
            .subscribe(event => {
                if (event instanceof NavigationEnd) {
                    this.currentUrl = event.urlAfterRedirects || event.url;
                    this.lastUrl[this.getKey(this.currentUrl)] = this.currentUrl;
                }
            });
    }

    private getKey(url: string) {
        return url.split('/')[1];
    }

    public canActivate(route: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): boolean {

        if (!(route.data && route.data.reuse)) {
            console.warn('A RouteReuseNavigationGuard has been used with a route that isn\'t enabled for route reuse!');
            return true;
        }

        const key = this.getKey(routerStateSnapshot.url);
        const url = this.lastUrl[key];
        const redirects = this.redirects[key] || 0;

        if (!url || url === routerStateSnapshot.url || key === this.getKey(this.currentUrl) || routerStateSnapshot.url.indexOf('redirecting=1') > -1) {
            this.redirects[key] = 0;
            return true;
        } else {
            if (redirects < 5) { // 5 is an arbitrary number
                // console.log(`SAFE REDIRECTING ${url} to ${routerStateSnapshot.url} from ${this.currentUrl}`);
                this.redirects[key] = redirects + 1;
                // first, navigate to the previous known url when this route was saved
                this.tryNavigate([url, routerStateSnapshot.url, key], { queryParams: { redirecting: 1 }, queryParamsHandling: 'merge', skipLocationChange: true }).then(() => {
                    // then navigate to the actual url we are trying to reach
                    return this.router.navigateByUrl(routerStateSnapshot.url);
                }).catch(err => {
                    // TODO: should redirect to an error page, right now navigation will just not do anything
                    console.warn(`RouteReuseNavigationGuard: Navigation error`);
                    console.error(err);
                });
            } else {
                // TODO: should redirect to an error page, right now navigation will just not do anything
                console.warn(`RouteReuseNavigationGuard: Too many redirects`);
            }
            return false;
        }

    }


    async tryNavigate(routes: any[], extras?: NavigationExtras): Promise<boolean> {
        // the state of the destination route isn't known before hand, so try go through a number of possible routes 
        // if the original route fails with a "Cannot reattach ActivatedRouteSnapshot with a different number of children" error.
        let error = null;
        for (const url of routes) {
            try {
                return await this.router.navigate([url], extras);
            } catch (err) {
                error = err;
                if (!err.message.includes('Cannot reattach ActivatedRouteSnapshot with a different number of children')) {
                    throw err;
                }
            }
        }
        throw error;

    }

}
