import { Component, Input, HostBinding, OnChanges, Output, EventEmitter } from '@angular/core';


@Component({
    selector: 'kui-alert-panel',
    templateUrl: './alert-panel.component.html',
})
export class KuiAlertPanelComponent implements OnChanges {

    @Input() message: string;
    @Input() icon: string; // NOTE: you can explicitely hide icons by assinging it an empty string
    @Input() size = 5;
    @Input() actionText: string;

    @Output() onAction = new EventEmitter();

    @HostBinding('class') classes = 'hidden';

    ngOnChanges() {
        if (this.message || this.icon || this.actionText) {
            this.classes = 'h-100 d-flex flex-stretch justify-content-center align-items-center p-4';
        } else {
            this.classes = 'hidden';
        }
    }

}
