import { Injectable } from '@angular/core';
import { SearchResult } from 'app/shared/components/search-results-list/search-results-list.component';
import { Observable, ReplaySubject, from } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { MapToolsService } from '../../../map-tools.service';
import { MapCoordinates, MapMarker } from '../../../map.component';
import { MapOptionService } from '../../map-option.service';
import { MapOptionSearchComponent } from './map-option-search.component';


@Injectable()
export class MapOptionSearchService {

    private lastSelectedSearchItem: MapMarker;
    private _lastSelectedSubject = new ReplaySubject<MapMarker>(1);
    private _blurInputSubject = new ReplaySubject<boolean>(1);
    private _searchTermSubject = new ReplaySubject<string>(1);
    private _markerAddSubject = new ReplaySubject<MapMarker>(1);
    private _markerRemoveSubject = new ReplaySubject<MapMarker>(1);
   
    lastSelectedSearchItem$ = this._lastSelectedSubject.asObservable();
    searching$ = this.mapTools.loading$.pipe(map(loading => loading === 'search'));
    searchTerm$ = this._searchTermSubject.asObservable().pipe(debounceTime(500));
    markerAdd$ = this._markerAddSubject.asObservable();
    markerRemove$ = this._markerRemoveSubject.asObservable();
    blurInput$ = this._blurInputSubject.asObservable();
    searchResults$: Observable<SearchResult<MapCoordinates>[]>;

    constructor(
        private mapTools: MapToolsService,
        private mapOptionService: MapOptionService,
    ) {
        this.searchResults$ = this._searchTermSubject.asObservable().pipe(
            debounceTime(500),
            filter(term => !!term),
            tap(_ => {
                this.removeMarker(this.lastSelectedSearchItem);
                this.lastSelectedSearchItem = null;
                this._lastSelectedSubject.next(null);
            }),
            switchMap(term => {
                return from(this.mapTools.doSearch(term, (marker) => this.updateSearchMarker(marker)));
            }),            
        );
    }

    showResults() {
        const current = this.mapOptionService.getCurrent();
        if (current?.id !== MapOptionSearchComponent.ID) {
            this.mapOptionService.load({
                component: MapOptionSearchComponent,
                id: MapOptionSearchComponent.ID,
                title: MapOptionSearchComponent.TITLE,
                icon: MapOptionSearchComponent.ICON,
                data: undefined,
            });
        }
    }

    setSearchTerm(term: string) {
        if (term) {
            this._searchTermSubject.next(term);
        } else {
            this.clear();
        }
    }

    clear(redirect: boolean = true) {
        this._searchTermSubject.next(null);
        this.removeMarker(this.lastSelectedSearchItem);
        this.lastSelectedSearchItem = null;
        this._lastSelectedSubject.next(null);
        if (redirect) {
            this.mapOptionService.clear();
        }
    }

    hideResults() {
        this.mapOptionService.clear();
    }

    addMarker(marker: MapMarker) {
        this._markerAddSubject.next(marker);
    }

    removeMarker(marker: MapMarker) {
        this._markerRemoveSubject.next(marker);
    }

    blurInput() {
        this._blurInputSubject.next(true);
    }

    getLastSearchItem(): MapMarker {
        return this.lastSelectedSearchItem;
    }

    private updateSearchMarker(marker: MapMarker) {
        this.removeMarker(this.lastSelectedSearchItem);
        this.lastSelectedSearchItem = marker;
        this._lastSelectedSubject.next(marker);
        this.addMarker(marker);
    }

}
