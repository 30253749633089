import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class LayoutGridColumnService {
    private layoutGridResizedSubject = new Subject<number | string>();
    // an observable for elements to subscribe to that are deeper down the DOM tree
    // and would like to know when someone resized ANY of the columns
    layoutGridResized$: Observable<number | string> = this.layoutGridResizedSubject.asObservable();

    emitGridLayoutResize(size: number | string) {
        // NOTE: even though we are emitting the size, it would not really be beneficial to know unless you know for sure what column has been resized.
        // use the LayoutGridColumn.onResized output where possible instead.
        this.layoutGridResizedSubject.next(size);
    }
}
