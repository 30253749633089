import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MapZone } from "../../../map.component";
import { MapOptionService } from "../../map-option.service";
import { ZoneType } from "../../model";
import { ZoneEditorService } from "../../services";
import { MapOptionComponent } from "../map-option.component";

export interface ZoneRemoverConfig {
    supportedTypes: ZoneType[];
}

@Component({
    styleUrls: ['./map-option-zone-remover.component.scss'],
    templateUrl: './map-option-zone-remover.component.html',
})
export class MapOptionZoneRemoverComponent implements MapOptionComponent<ZoneRemoverConfig>, OnInit, OnDestroy {

    static ID = 'map-option-zone-remover-component';

    private destroyed: Subject<void> = new Subject();
    compact: boolean = false;
    data: ZoneRemoverConfig;

    public zones$: Observable<MapZone[]>;

    constructor(
        private editorService: ZoneEditorService,
        private mapOptionService: MapOptionService
    ) { }

    ngOnInit(): void {
        this.mapOptionService.isCompact$.pipe(
            takeUntil(this.destroyed)
        ).subscribe(res => {
            this.compact = res;
        });

        if (this.data.supportedTypes.includes('route')) {
            this.editorService.fireEditEvent({
                action: 'zonedeletepolyline',
            });
        } else {
            this.editorService.fireEditEvent({
                action: 'zonedeletepolygon'
            });
        }
    }

    ngOnDestroy(): void {
        this.destroyed.next();
        this.editorService.cancelMapEditing();
    }

    save() {
        this.editorService.completeMapEdits();
        this.mapOptionService.back();
    }

    cancel() {
        this.mapOptionService.back();
    }


}