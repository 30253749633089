import { Component, Input, HostBinding, Output, EventEmitter } from '@angular/core';
import { AnalyticsChartGraphData } from './graph.service';

@Component({
    selector: 'key-analytics-chart-graph',
    templateUrl: './graph.component.html',
})
export class AnalyticsChartGraphComponent {
    @Input() data: AnalyticsChartGraphData;

    @Output() onMeasureSelection = new EventEmitter<string>();

    @HostBinding('class') hostClassnames = 'd-flex flex-column flex-1';

    constructor(
    ) {
    }
}
