import { Component, OnInit, ViewChild } from '@angular/core';
import { KuiModalRefComponent } from 'app/key-ui/modal/modal-ref.component';
import { IKuiModalAction } from 'app/key-ui';
import { TranslateService } from '@ngx-translate/core';
import { CompletedReportResponse, QueueReportRequest } from '@key-telematics/fleet-api-client';
import { ReportDefinition, CachedReportParameters } from '../services/reporting.service';
import { ModalService } from '../../modal';
import { ReportParameterFormComponent } from '../parameter-form/report-parameter-form.component';


export interface ReportParameterModalOptions {
    title: string;
    clientId: string;
    reportDefinition: ReportDefinition;
    sourceReport?: CompletedReportResponse;
    parameters?: CachedReportParameters;
    showHiddenFields?: boolean;
    isSheduledReport?: boolean; 

}

@Component({
    templateUrl: 'report-parameter-modal.component.html',
    styleUrls: ['report-parameter-modal.component.scss'],
})
export class ReportParameterModalComponent extends KuiModalRefComponent<ReportParameterModalOptions> implements OnInit {

    modalActions: IKuiModalAction[];

    @ViewChild(ReportParameterFormComponent, { static: true }) parameters: ReportParameterFormComponent;

    constructor(
        private i18n: TranslateService
    ) {
        super();
    }

    static open(modal: ModalService, options: ReportParameterModalOptions): Promise<QueueReportRequest> {
        return new Promise<QueueReportRequest>((resolve, reject) => {
            try {
                modal.open<ReportParameterModalOptions>(ReportParameterModalComponent, {
                    data: options,
                    actions: {
                        close: () => {
                            modal.close();
                            resolve(null);
                        },
                        apply: (result: QueueReportRequest) => {
                            modal.close();
                            resolve(result);
                        },
                    },

                });
            } catch (err) {
                reject(err);
            }
        });

    }


    ngOnInit() {

        this.modalActions = [{
            text: this.i18n.instant('DIALOG.CANCEL'),
            style: 'secondary',
            keypress: 27,
            action: () => {
                this.actions.close(false);
            },
        }, {
            text: this.i18n.instant('DIALOG.OK'),
            style: 'primary',
            action: () => {
                if (this.parameters.validate()) {
                    this.actions.apply(this.parameters.getValues());
                }
                return true; // don't close the modal automatically
            },
        }];

    }

    detachModal() {
        this.actions.close(false);
    }



}
