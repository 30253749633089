import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ErrorLoggerService } from 'app/services';

@Injectable()
export class ErrorLoggerHandler extends ErrorHandler {
  constructor(public injector: Injector) {
    super();
  }
  /**
   * Handles errors tracked by Angular
   */
  handleError(error: any): void {
    // It's injected because it's a cyclic dependency
    const logger = this.injector.get(ErrorLoggerService);

    // Get real error if bubbled from promise
    const logError = error.rejection ? error.rejection : error;

    // TokenExpiredError is already handled and logs the user out, but the api-client calls Promise.reject()
    // before firing the custom error handlers which causes this to still happen...
    // so we return early to not get token expired errors ending up in our logs
    if (logError.name && logError.name === 'TokenExpiredError') { return null; }

    if (logError instanceof Error) {
      logger.trackException(logError);
    } else {
      logger.trackMessage(logError);
    }

    logger.unhandledError$.next(logError);
  }
}
