import { NgModule } from '@angular/core';
import { NavPageLinkDirective } from './page-nav-link.directive';

@NgModule({
    imports: [],
    exports: [NavPageLinkDirective],
    declarations: [NavPageLinkDirective],
    providers: [],
})
export class NavPageLinkModule { }
