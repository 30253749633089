<div kui-action
    class="d-flex flex-1"
    (click)="primaryActionClicked.emit()">
    <key-feed-entry-info [icons]="icons">
        <span id="info-title">
            <span [title]="data | getKey: 'date' | date: 'yyyy-MM-dd HH:mm:ss'">{{ data | getKey: 'date' | units: { unit: 'time', variant: 'short'} }}</span>
        </span>
        <span id="info-small-text"
            class="small muted">{{timeAgo}}</span>
    </key-feed-entry-info>

    <div class="feed-entry-details">
        <div class="d-flex flex-wrap">
            <div class='entry-text flex-1 mr-3 mb-1'>
                <div class="entry-title"
                    [innerHTML]="message"></div>
            </div>
        </div>
    </div>
</div>
