<div *ngFor="let node of nodes">
    <label class="form-check-label" [ngClass]="{'disabled': node.disabled}">
        <input
            [checked]="node.checked"
            [indeterminate]="node.indeterminate"
            [disabled]="node.disabled"
            (change)="nodeChanged(node)"
            kui-checkbox>
        {{ node?.data?.name || node.id }}
    </label>
    <div class="children">
        <key-tree-view
            [nodes]="node.children">
        </key-tree-view>
    </div>
</div>