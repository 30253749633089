<kui-modal size='md'
    class="details-modal"
    [title]="title"
    (onClose)="detachModal()"
    >
    <div class="">
        <key-admin-form-editor [editable]="true"
            [editing]="true"
            [entity]="data.entity"
            [handler]="data.editor"
            [showHeader]="false"
            (onEditing)="onEditing($event)"
            (onSaved)="onSaved($event)"
            (onCanceled)="onCanceled()"
            ></key-admin-form-editor>
    </div>
</kui-modal>