import { Component, OnInit } from '@angular/core';
import { KuiModalRefComponent } from 'app/key-ui/modal/modal-ref.component';
import { IKuiModalAction, IKuiTableCol } from 'app/key-ui';
import { TranslateService } from '@ngx-translate/core';
import { EditorHandler } from '../entities/entity.handler';
import { debounce } from 'lodash';

@Component({
    templateUrl: 'select.component.html',
})
export class AdminSelectModalComponent extends KuiModalRefComponent<{ ownerId: string, handler: EditorHandler }> implements OnInit {

    constructor(private i18n: TranslateService) {
        super();
    }

    modalActions: IKuiModalAction[];

    rows: any[] = [];
    cols: IKuiTableCol[] = [];

    selected: any[];
    searchTerm: string;

    performSearch = debounce(() => this.load(), 300);

    async ngOnInit() {

        this.modalActions = [{
            text: this.i18n.instant('DIALOG.CANCEL'),
            style: 'secondary',
            keypress: 27,
            action: () => {
                this.actions.close();
            },
        }, {
            text: this.i18n.instant('DIALOG.OK'),
            style: 'primary',
            keypress: 13,
            action: () => {
                this.actions.apply(this.selected);
            },
        }];

        this.cols = await this.data.handler.getGridColumns(this.data.ownerId);
        this.load();
        
    }

    async load() {
        this.rows = (await this.data.handler.listEntities(this.data.ownerId, 0, 100, null, this.searchTerm && `name=*${this.searchTerm}*`)).items;
    }

    detachModal() {
        this.actions.close();
    }

    searchChanged(value: string) {
        this.searchTerm = value;
        this.performSearch();
    }

    rowsSelected(items: any[]) { 
        this.selected = items;
    }

}
