import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilderField } from 'app/shared/components/form-builder';

@Component({
    selector: 'key-form-builder-toggle-field',
    templateUrl: './toggle.component.html',
})
export class KeyFormBuilderToggleFieldComponent implements OnInit {
    dirty = false;

    @Input() field: FormBuilderField;
    @Input() values: { [key: string]: any };
    @Input() error: string; // set this error value externally to have the default error highligh and display kick in

    @Output() onChange: EventEmitter<{ value: boolean, dirty: boolean }> = new EventEmitter();

    validate(): boolean {
        return true;
    }

    ngOnInit() {
        this.values[this.field.id] = this.values[this.field.id] !== undefined ? this.values[this.field.id] : this.field.value;
        this.toggle(this.values[this.field.id]);
        this.dirty = true;
    }

    toggle(state: boolean) {
        this.values[this.field.id] = state;
        this.onChange.emit({ value: state, dirty: this.dirty });
    }
}
