import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'kui-table-cell-html',
  templateUrl: './table-cell-html.component.html',
  styleUrls: ['./table.component.scss'],
})
export class KuiTableCellHtmlComponent implements OnChanges {
  @Input() html: string;
  @Input() path: any;
  @Input() action: any;
  @Input() icon: { name: string, color?: string };

  @Output() actionClicked: EventEmitter<any> = new EventEmitter();

  innerHtml: SafeHtml;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.html && this.html) {
      this.innerHtml = this.sanitizer.bypassSecurityTrustHtml(this.html);
    }
  }

  goToPath(path) {
    const pathname = (typeof path === 'string') ? path : path.pathname;

    if (path.relative) {
      if (Array.isArray(pathname)) {
        this.router.navigate(pathname, { relativeTo: this.route });
      } else {
        this.router.navigate([pathname], { relativeTo: this.route });
      }
    } else {
      if (Array.isArray(pathname)) {
        this.router.navigate(pathname);
      } else {
        this.router.navigate([pathname]);
      }
    }
  }
}
