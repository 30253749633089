import { Component, OnInit, ViewChild } from '@angular/core';
import { KuiModalRefComponent } from 'app/key-ui/modal/modal-ref.component';
import { IKuiModalAction } from 'app/key-ui';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../modal';
import { ReportParameterFormComponent } from '../parameter-form/report-parameter-form.component';
import { KuiTreeSelectNode } from 'app/key-ui/tree-select/tree-select.component';
import { ReportingService } from '../services/reporting.service';
import { ReportListItem } from '../list-item/report-item.component';
import { take } from 'rxjs/operators';
import { AppService } from 'app/app.service';
import { TreeNode } from 'primeng/api';


export interface ReportSelectModalOptions {
    clientId: string;
    schedulableOnly?: boolean;
}

@Component({
    templateUrl: 'report-select-modal.component.html',
    styleUrls: ['report-select-modal.component.scss'],
})
export class ReportSelectModalComponent extends KuiModalRefComponent<ReportSelectModalOptions> implements OnInit {

    treeNodes: KuiTreeSelectNode[];
    selectedNode: string;
    reports: ReportListItem[] = [];

    modalActions: IKuiModalAction[];

    selectedCategory: any;

    @ViewChild(ReportParameterFormComponent) parameters: ReportParameterFormComponent;

    constructor(
        private app: AppService,
        private i18n: TranslateService,
        private reportService: ReportingService
    ) {
        super();
    }

    static open(modal: ModalService, options: ReportSelectModalOptions): Promise<ReportListItem> {
        return new Promise<ReportListItem>((resolve, reject) => {
            try {
                modal.open<ReportSelectModalOptions>(ReportSelectModalComponent, {
                    data: options,
                    actions: {
                        close: () => {
                            modal.close();
                            resolve(null);
                        },
                        apply: (result: ReportListItem) => {
                            modal.close();
                            resolve(result);
                        },
                    },

                });
            } catch (err) {
                reject(err);
            }
        });

    }


    ngOnInit() {

        this.modalActions = [{
            text: this.i18n.instant('DIALOG.CANCEL'),
            style: 'secondary',
            keypress: 27,
            action: () => {
                this.actions.close(false);
            },
        }];

        this.app.api.accounts.getClient(this.data.clientId).then(client => {
            const features = this.app.getFeaturesForClient({
                flags: client.meta.mergedFlags,
            });
            const allowedReports = features.page.reporting.reports.split(',');
            const analyticsFeatures = features.page.reporting.show.analytics;

            return this.reportService.getReportDefinitions(client.id, allowedReports, analyticsFeatures).then(async definitions => {

                const favourites = await this.reportService.favouriteReports$.pipe(take(1)).toPromise();

                this.reports = definitions.filter(x => (this.data.schedulableOnly ? x.schedulable : true) && (x.userVisible || this.app.user.owner.type !== 'client')).map(x => ({
                    id: x.id,
                    name: x.name,
                    description: x.description,
                    type: x.type,
                    tags: x.tags,
                    starred: favourites && favourites.indexOf(x.id) > -1,
                    muted: !x.userVisible,
                    template: !!x.template,
                }));


                // first, build a set of unique categories from all of the tags in the report
                const categories = Array.from(new Set(this.reports.map(x => x.tags).reduce((p, c) => p.concat(c), [])).values()).map(id => ({
                    id: id,
                    name: this.getCategoryName(id),
                    items: [],
                }));
                categories.sort((a, b) => a.name.localeCompare(b.name));

                // then go through all of the reports and add them to the categories
                this.reports.map(report => {
                    categories.filter(x => report.tags.indexOf(x.id) > -1).forEach(category => {
                        category.items.push(report);
                    });
                });

                // and finally sort the reports in each category
                categories.forEach(category => {
                    category.items.sort((a, b) => a.name.localeCompare(b.name));
                });

                this.treeNodes = categories.map(cat => ({
                    id: cat.id,
                    name: cat.name,
                    icon: 'folder',
                    items: cat.items,
                }));

            });
        });


    }

    detachModal() {
        this.actions.close(false);
    }

    getCategoryName(id: string): string {
        const result = this.i18n.instant(`REPORTING.CATEGORIES.${id.toUpperCase()}`) as string;
        return result.startsWith('REPORTING.') ? id : result;
    }

    nodeSelected(event: { eventName: string, node: TreeNode }) {
        if (event.eventName === 'activate') {
            this.selectedCategory = event.node.data;
        }
    }

    selectReport(report: ReportListItem) {
        this.actions.apply(report);
    }



}
