import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { MapZonesService } from '../../../map-zones.service';
import { MapZone } from '../../../map.component';

@Injectable()
export class MapOptionZonesService {

    private _zoneEditSubject = new Subject<string>();
    private _filterTermSubject = new BehaviorSubject<string>('');
    private visibleZones$: Observable<MapZone[]> = this.zoneService.zones$.pipe(
        map(zones => zones.filter(zone => zone.type !== 'route'))
    );

    zoneEdit$ = this._zoneEditSubject.asObservable();

    filtered$ = combineLatest([this._filterTermSubject, this.visibleZones$]).pipe(
        map(([term, zones]) => zones.filter(zone => zone.name.includes(term)))
    );

    constructor(
        private zoneService: MapZonesService,
    ) { }

    setFilterTerm(term: string) {
        this._filterTermSubject.next(term);
    }

    editZone(zoneId: string) {
        this._zoneEditSubject.next(zoneId);
    }

}
