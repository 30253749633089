import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { IKuiDropdownMenuItem } from '../dropdown';
import { BaseComponent } from 'app/shared/components/base/base.component';
import { KuiTabBarDragEvent } from '../tab-bar/tab-bar.component';
import { MatchMediaService } from 'app/services';

export interface ContentHeaderTitle {
    value: string;
    iconName?: string;
    iconPosition?: 'prefix' | 'sufix';
    click?: () => void;
}

export interface ContentHeaderTabItem {
    id: string;
    title: string;
    click: () => void;
    close?: () => void;
    drag?: (e: KuiTabBarDragEvent) => void;
}

export interface ContentHeaderAction {
    id: string;
    name?: string;
    icon: string;
    show?: 'icon' | 'text' | 'both' | string;
    click?: () => void;
    menu?: IKuiDropdownMenuItem[];
    template?: TemplateRef<any>;
    templateContext?: { [key: string]: any };
    active?: boolean;
}

@Component({
    selector: 'kui-content-header',
    templateUrl: './content-header.component.html',
    styleUrls: ['./content-header.component.scss'],
})
export class KuiContentHeaderComponent extends BaseComponent implements OnChanges {
    isDesktop: boolean;

    closeableTabs = false;
    draggableTabs = false;

    _title: ContentHeaderTitle;
    @Input()
    get title(): string | ContentHeaderTitle {
        return this._title;
    }
    set title(title: string | ContentHeaderTitle) {
        this._title = typeof title === 'string' ? { value: title } : title;
    }

    @Input() icon: 'menu' | 'back' | 'close';
    @Input() custom: boolean;
    @Input() sub: boolean;
    @Input() actions: ContentHeaderAction[] = [];
    @Input() tabs: ContentHeaderTabItem[] = [];
    @Input() activeTab: ContentHeaderTabItem;

    @Output() action: EventEmitter<string> = new EventEmitter;

    constructor(
        private matchMedia: MatchMediaService
    ) {
        super();

        this.on(this.matchMedia.isDesktop, desktop => {
            this.isDesktop = desktop;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.tabs) {
            this.closeableTabs = (this.tabs || []).filter(x => !!x.close).length > 0;
            this.draggableTabs = (this.tabs || []).filter(x => !!x.drag).length > 0;
        }
    }

    setActiveTab(id: string) {
        if (!this.tabs || this.tabs.length === 0 || (this.activeTab && this.activeTab.id === id)) { return; }
        const active = (this.tabs || []).find(tab => tab.id === id);
        if (active) {
            this.activeTab = active;
            active.click();
        }
    }

    selectTab(id: string) {
        this.setActiveTab(id);
    }

    dragTab(event: KuiTabBarDragEvent) {
        const dragged = (this.tabs || []).find(tab => tab.id === event.id);
        if (dragged) {
            dragged.drag(event);
        }
    }

    async closeTab(id: string, e?: Event) {

        if (e) { e.stopPropagation(); }

        const tab = this.tabs.find(x => x.id === id);
        if (tab && tab.close) {
            const activeId = this.activeTab && this.activeTab.id;
            tab.close();
            if (activeId === id) {
                this.setActiveTab(this.tabs[0] && this.tabs[0].id);
            }
        }
    }
}
