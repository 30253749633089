import { Component, Input, OnInit, Output, EventEmitter, ViewChild, HostBinding } from '@angular/core';
import { FormBuilderField, BaseFormBuilderFieldComponent } from 'app/shared/components/form-builder';
import { NgModel } from '@angular/forms';

@Component({
    selector: 'key-form-builder-minmax-field',
    templateUrl: './minmax.component.html',
})
export class KeyFormBuilderMinMaxFieldComponent implements BaseFormBuilderFieldComponent, OnInit {

    @Input() field: FormBuilderField;
    @Input() values: { [key: string]: any };
    @Input() error: string; // set this error value externally to have the default error highligh and display kick in

    @Output() onChange: EventEmitter<{ value: number[], dirty: boolean }> = new EventEmitter();
    @HostBinding('class') classnames = 'd-flex flex-wrap';
    @ViewChild('minField', { static: true }) minField: NgModel;
    @ViewChild('maxField', { static: true }) maxField: NgModel;

    ids: string[] = ['MIN', 'MAX'];
    minBiggerThanMax: boolean;

    validate(): boolean {
        this.minField.control.markAsTouched();
        this.maxField.control.markAsTouched();
        return !this.minField.invalid || !this.maxField.invalid;
    }

    ngOnInit() {
        this.values[this.field.id] = this.values[this.field.id] || this.field.value || [ null, null ];
    }

    changeNumber(change: { value: number, index: number }) {
        this.values[this.field.id][change.index] = change.value;
        const [min, max] = this.values[this.field.id];
        this.minBiggerThanMax = max && min > max;
        this.onChange.emit({ value: this.values[this.field.id], dirty: this.minField.dirty || this.maxField.dirty });
    }
}
