import { KuiSelectModule } from './../select/select.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { KuiMenuComponent } from './menu.component';
import { KuiMenuBrandComponent } from './menu-brand.component';
import { KuiMenuActionsDirective } from './menu-actions.directive';

import { KuiIconModule } from 'app/key-ui/icon/icon.module';
import { KuiDropdownModule } from 'app/key-ui/dropdown/dropdown.module';
import { KuiActionModule } from 'app/key-ui/action/action.module';

const KUI_MENU_MODULES = [
  KuiMenuComponent,
  KuiMenuBrandComponent,
  KuiMenuActionsDirective,
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        KuiSelectModule,
        KuiIconModule,
        KuiDropdownModule,
        KuiActionModule,
    ],
    exports: KUI_MENU_MODULES,
    declarations: KUI_MENU_MODULES
})
export class KuiMenuModule {}
