import { Injectable } from '@angular/core';
import { AssetFilterService } from 'app/services';
import { MapSearchService } from 'app/shared/components/mapsearch';
import { ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { MapToolsService } from '../../../map-tools.service';
import { MapCoordinates } from '../../../map.component';
import { MapOptionService } from '../../map-option.service';


export interface CoordinateDetails {
    title: string,
    text: string
}

@Injectable()
export class PointInformationService {

    private _pointRequestSubject = new ReplaySubject<boolean>(1);
    private _coordinatesSubject = new ReplaySubject<MapCoordinates>(1);
    private _openGoogleMapsSubject = new ReplaySubject<MapCoordinates>(1);
    
    coordinates$ = this._coordinatesSubject.asObservable();
    pointRequest$ = this._pointRequestSubject.asObservable();
    openGoogleMaps$ = this._openGoogleMapsSubject.asObservable();
    loading$ = this.mapTools.loading$.pipe(map(loading => loading === 'coordinates'));

    constructor(
        private mapTools: MapToolsService,
        private mapSearchService: MapSearchService,
        private mapOptionService: MapOptionService,
        private filterService: AssetFilterService
    ) { }

    start() {
        this._pointRequestSubject.next(true);
    }

    stop() {
        this._pointRequestSubject.next(false);
    }

    searchClosestAsset(coordinates: MapCoordinates) {
        const { lat, lon, address } = coordinates;
        this.filterService.setPoint({ lat, lon, address });
        // if we're fullscreen, exit as the user can't see the results
        this.mapOptionService.setFullscreen(false);
    }

    setCoordinates(coordinates: MapCoordinates) {
        this._coordinatesSubject.next(coordinates);
    }

    openInGoogleMaps(coordinates: MapCoordinates) {
        this._openGoogleMapsSubject.next(coordinates);
    }

    mapSearch(coordinates: MapCoordinates) {
        const { lat, lon, address } = coordinates;
        this.mapSearchService.openPointSearch(lat, lon, address);
        // if we're fullscreen, exit as the user can't see the results
        this.mapOptionService.setFullscreen(false);
    }



}
