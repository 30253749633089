<kui-modal size='md'
    class="details-modal"
    [title]="'ADMIN.MODALS.SELECT.TITLE' | translate"
    (onClose)="detachModal()"
    [actions]="modalActions">

    <div class="p-4 d-flex flex-column"
        style="min-height: 300px">
        <div>
            <kui-input-group>
                <input kui-input
                    type="text"
                    #searchBar
                    [placeholder]="'SHARED.SEARCH_ELLIPSES' | translate"
                    (input)="searchChanged($event.target.value)">
            </kui-input-group>

        </div>
        <div class="flex-1 d-flex overflow-auto border mt-3">
            <kui-table #table
                class="h-100"
                [selectionMode]="'multiple'"
                selectionColumn="id"
                [nowrap]="true"
                [rows]="rows"
                [cols]="cols"
                [resize]="false"
                [selection]="true"
                (onRowsSelect)="rowsSelected($event)"
                >
            </kui-table>
        </div>
    </div>
</kui-modal>