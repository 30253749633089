import {
    ElementRef,
    Renderer2,
    Directive,
    Input,
    OnInit,
    OnChanges,
} from '@angular/core';

@Directive({
    selector: '[kui-input]',
})
export class KuiInputDirective implements OnInit, OnChanges {
    uniqueId = Math.floor(Math.random() * 1e8).toString();

    @Input('kui-input') inputName: string;
    @Input() type: string;
    @Input() state: string;

    constructor(private el: ElementRef, private renderer: Renderer2) {
        this.renderer.addClass(this.el.nativeElement, 'form-control');
    }

    ngOnInit() {
        this.type = this.type || 'text';
        // fixes an issue where input types could not be set with [type]='field'
        this.renderer.setAttribute(this.el.nativeElement, 'type', this.type);

        if (this.inputName) {
            ['name', 'id'].forEach(attr => {
                this.renderer.setAttribute(this.el.nativeElement, attr, this.inputName);
            });
        } else {
            ['name', 'id'].forEach(attr => {
                this.renderer.setAttribute(this.el.nativeElement, attr, this.uniqueId);
            });
        }
    }

    ngOnChanges(update) {
        this.changeState(update.currentValue || this.state);
    }

    changeState(state: string) {
        if (state === 'success') {
            this.renderer.removeClass(this.el.nativeElement, 'is-invalid');
            this.renderer.addClass(this.el.nativeElement, 'is-valid');
        }

        if (state === 'danger') {
            this.renderer.removeClass(this.el.nativeElement, 'is-valid');
            this.renderer.addClass(this.el.nativeElement, 'is-invalid');
        }
    }
}
