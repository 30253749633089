import { FeedResult, FeedHistoryResult } from './../feed.model';

import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { AuthService, MeasurementUnitsService } from 'app/services';
import { AuditFeedItem } from '../feed.model';
import { FeedService, FeedOriginType } from '../feed.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuditEventResponse, FeedsGetRecentAuditFeedGQL } from 'app/shared/graphql';

@Injectable()
export class AuditFeedService extends FeedService<AuditFeedItem> {

    constructor(app: AppService, public auth: AuthService, private units: MeasurementUnitsService) {
        super(app, auth);
    }

    getFeedSubscription(clientId: string, _originType: FeedOriginType, originId: string, limit: number): Observable<FeedResult<AuditFeedItem>> {
        return new FeedsGetRecentAuditFeedGQL(this.app.api.gql).subscribe({
            company: clientId,
            entity: originId,
            limit: limit,
        }).pipe(map(result => {
            const items = result.data.getRecentAuditFeed;
            return {
                count: items.length,
                sequence: [
                    !!items.length ? Math.min(...items.map(item => this.dateToSequence(item.date))) : 0,
                    !!items.length ? Math.max(...items.map(item => this.dateToSequence(item.date))) : 0,
                ],
                items: items.map(item => this.mapResponse(item as any)),
            };
        }));
    }


    getFeed(clientId: string, _originType: FeedOriginType, originId: string, sequence: number, direction: 'forward' | 'backward', limit: number): Promise<FeedResult<AuditFeedItem>> {
        return this.app.api.data.getAuditFeedForEntity(clientId, originId, sequence, direction, limit)
            .then(result => ({
                count: result.count,
                sequence: [
                    result.sequence,
                    result.count !== 0 ? Math.max(...result.items.map(item => this.dateToSequence(item.date))) : 0,
                ],
                items: result.items.map(item => this.mapResponse(item)),
            }));
    }

    protected getFeedHistory(_clientId: string, _originType: FeedOriginType, _originId: string, _start: string, _end: string, _limit: number): Promise<FeedHistoryResult<AuditFeedItem>> {
        return Promise.reject('Audit history not available');
    }

    mapResponse(item: AuditEventResponse): AuditFeedItem {
        return {
            id: item.id,
            date: item.date,
            section: {
                title: this.units.format(item.date, 'date', 'long').format,
                date: item.date,
            },
            owner: item.owner,
            class: item.eventClass,
            type: item.eventType,
            user: item.user,
            entity: item.entity,
            changes: item.changes as any,
        };
    }
}
