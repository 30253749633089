@if(name()) {
    <div class="p-3 heading">
        <div> {{name()}} </div>
    </div>
}

<div class="content">
    @if(loading()) {
        <kui-alert 
            type="none"
            class="inner key-alert-wrapper">
            <kui-loader
                class="mr-4"
                size="medium"></kui-loader>
            <span>{{ 'MEDIA.LIVESTREAM.REQUESTING' | translate }}</span>
        </kui-alert>
    } @else {
        @if(error()) {
            @if(watchLimitReached()) {
                <div class="limit-warning">
                    <div class="limit-warning--heading">
                        <kui-icon name="lock" color="danger"
                            size="lg"></kui-icon>
                        <h2>
                            {{'MEDIA.LIVESTREAM.DAILY_STREAMS_REACHED' | translate}}
                        </h2>
                    </div>
                    <p>
                        {{'MEDIA.LIVESTREAM.DAILY_STREAMS_DESC' | translate}}
                    </p>
                    <p>
                        {{'MEDIA.LIVESTREAM.TRY_LATER' | translate}}
                    </p>
                </div>
            } @else {   
                <kui-alert type="none"
                    class="key-alert-wrapper fig">
                    <kui-icon name="ban"
                        size="lg"></kui-icon>
                    <span>{{ error() }}</span>
                </kui-alert>
            }
        } @else {
            @if(videoSource()) {
                <key-videojs-player
                    class="w-100 h-100"
                    [options]="videoOptions()"
                    [source]="videoSource()"
                    (onError)="onLiveStreamError($event)">
                </key-videojs-player>

                @if(streamEnd()) {
                    <div class="player-overlay">
                        <h2 class="player-overlay--title">
                            {{'MEDIA.LIVESTREAM.STREAM_END' | translate}}
                        </h2>
                        <div class="player-overlay--actions d-flex justify-content-center align-items-center flex-column">
                            <p>{{'MEDIA.LIVESTREAM.REQUEST_NEW' | translate}}</p>
                            <div class="buttons d-flex justify-content-between align-items-center">
                                <button kui-button (click)="requestStream()">{{'MEDIA.LIVESTREAM.REQUEST_LIVE_VIDEO' | translate}}</button>

                                @switch (videoRequestState()) {
                                    @case (VideoRequestState.AWAITING_USER) {
                                        <button kui-button color="secondary" (click)="requestVideo()">{{'MEDIA.LIVESTREAM.REQUEST_VIDEO' | translate}}</button>
                                    }
                                    @case(VideoRequestState.REQUEST_SENT) {
                                        <kui-loader class="mr-4" size="small"></kui-loader>
                                    }
                                    @case (VideoRequestState.SUCCESS) {
                                        <ng-content select="[savedVideoActions]"></ng-content>
                                    }
                                    @case (VideoRequestState.FAILED) {
                                        <p>{{'MEDIA.LIVESTREAM.UNABLE_TO_SAVE' | translate}}</p>
                                    }
                                }

                            </div>
                            
                        </div>
                    </div>
                }
            } @else {
                <div class="request">
                    <button kui-button (click)="requestStream()">{{'MEDIA.LIVESTREAM.REQUEST_LIVE_VIDEO' | translate}}</button>
                </div>
            }
        }
    }
</div>
