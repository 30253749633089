import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

interface TimeValues {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

/*
 * Formats seconds to 'hr min sec'
 */
@Pipe({ name: 'formatSeconds' })
export class FormatSecondsPipe implements PipeTransform {

    constructor(private i18n: TranslateService) { }

    transform(seconds: number = 0): string {
        return this.getText(this.format(seconds));
    }

    format(value: number): TimeValues {
        value = Number(value);
        const days = Math.floor(value / (3600 * 24));
        const hours = Math.floor(value % (3600 * 24) / 3600);
        const minutes = Math.floor(value % 3600 / 60);
        const seconds = Math.floor(value % 60);
        return { days, hours, minutes, seconds };
    }

    getText(values: TimeValues): string {

        let result = '';

        if (values.days > 0) {
            result = values.days === 1 ? this.i18n.instant('SHARED.DISPLAY_TIME.DAY', { d: values.days }) : this.i18n.instant('SHARED.DISPLAY_TIME.DAYS', { d: values.days });
            result += ' ';
        }


        if (values.hours === 0) {
            if (values.minutes === 0) {
                if (values.seconds === 1) {
                    result += this.i18n.instant('SHARED.DISPLAY_TIME.SEC', { s: values.seconds });
                } else {
                    result += this.i18n.instant('SHARED.DISPLAY_TIME.SECS', { s: values.seconds });
                }
            } else {
                if (values.minutes === 1) {
                    result += this.i18n.instant('SHARED.DISPLAY_TIME.MIN', { m: values.minutes });
                } else {
                    result += this.i18n.instant('SHARED.DISPLAY_TIME.MINS', { m: values.minutes });
                }
            }
        } else {
            if (values.hours === 1) {
                if (values.minutes === 0) {
                    result += this.i18n.instant('SHARED.DISPLAY_TIME.HR', { h: values.hours });
                } else if (values.minutes === 1) {
                    result += this.i18n.instant('SHARED.DISPLAY_TIME.HR_MIN', { h: values.hours, m: values.minutes });
                } else {
                    result += this.i18n.instant('SHARED.DISPLAY_TIME.HR_MINS', { h: values.hours, m: values.minutes });
                }
            }

            if (values.hours > 1) {
                if (values.minutes === 0) {
                    result += this.i18n.instant('SHARED.DISPLAY_TIME.HRS', { h: values.hours });
                } else if (values.minutes === 1) {
                    result += this.i18n.instant('SHARED.DISPLAY_TIME.HRS_MIN', { h: values.hours, m: values.minutes });
                } else {
                    result += this.i18n.instant('SHARED.DISPLAY_TIME.HRS_MINS', { h: values.hours, m: values.minutes });
                }
            }
        }

        return result;

    }
}
