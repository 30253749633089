<ol class="breadcrumb" *ngIf="breadcrumbs && breadcrumbs.length">
	<li *ngFor="let breadcrumb of breadcrumbs" class="breadcrumb-item" [class.active]="breadcrumb.active">
        <kui-dropdown *ngIf="breadcrumb.dropdown" [menu]="breadcrumb.dropdown" [style]="'none'">
            <a *ngIf="!breadcrumb.active">
                {{ breadcrumb.name }}
                <kui-icon name="arrow-drop-down"></kui-icon>
            </a>
                <span *ngIf="breadcrumb.active">
                {{ breadcrumb.name }}
                <kui-icon name="arrow-drop-down"></kui-icon>
            </span>
        </kui-dropdown>

        <a *ngIf="!breadcrumb.active && breadcrumb.link" [routerLink]="breadcrumb.link">{{ breadcrumb.name }}</a>
        <span *ngIf="breadcrumb.active && breadcrumb.link">{{ breadcrumb.name }}</span>
    </li>
</ol>