import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, model, output, signal, type OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { KuiActionModule, KuiIconModule } from 'app/key-ui';
import { VideoType } from '../request.component';

@Component({
    selector: 'key-video-type',
    standalone: true,
    imports: [
        CommonModule,
        KuiActionModule,
        KuiIconModule,
        FormsModule
    ],
    templateUrl: './video-type.component.html',
    styleUrl: './video-type.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoTypeComponent implements OnInit {


    options = signal<{ name: string; value: VideoType; }[]>([
        {
            name: this.i18n.instant('MEDIA.VIDEO_REQUEST.MODAL.VIDEO'),
            value: VideoType.CLIP
        },
        {
            name: this.i18n.instant('MEDIA.VIDEO_REQUEST.MODAL.LIVESTREAM'),
            value: VideoType.STREAM
        }
    ]);

    type = model<VideoType>();
    videoTypeSelected = output<VideoType>();
    
    constructor(
        public i18n: TranslateService,
    ) { }

    ngOnInit(): void { }

    isValid(): boolean {
        return this.type() !== undefined;
    }

    handleModelChange(event: VideoType) {
        this.type.set(event);
        this.videoTypeSelected.emit(event);
    }

}
