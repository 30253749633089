import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { SelectionService } from './selection.service';
import { SelectionItem, EntityResult, EntityListOptions } from '../selection.model';
import {  IdName } from '@key-telematics/fleet-api-client';
import { ClientService } from 'app/services';
import { escapeRqlValue } from 'app/shared/utils/rql';

@Injectable()
export class ClientSelectionService extends SelectionService<SelectionItem>  {

    constructor(public app: AppService, public clientService: ClientService) {
        super(app);
    }

    private items: SelectionItem[] = [];

    getEntityList(id: string, { offset, limit, sort, filter }: EntityListOptions): Promise<EntityResult<SelectionItem>> {
        return this.app.api.accounts.listClients(id, offset, limit, sort, filter)
            .then(res => ({
                items: res.items.map(x => this.mapClient(x)),
                total: res.count,
            })).then(result => {
                this.items = result.items;
                return result;
            });
    }

    getEntity(id: string): Promise<SelectionItem> {
        const result = this.items.find(x => x.id === id);
        if (result) {
            return Promise.resolve(result);
        } else {
            return this.app.api.accounts.getClient(id).then(client => {
                if (client.id !== this.app.client.id) {
                    return this.clientService.loadClient(client.owner.id).then(() => client);
                }
                return client;
            }).then(client => {
                return this.mapClient(client);
            });
        }    
    }

    private mapClient(item: { owner: IdName, id: string, name?: string }): SelectionItem {
        return {
            ownerId: item.owner.id,
            id: item.id,
            name: item.name,
            logoPath: `${this.app.env.apiEndpoint}/accounts/clients/${item.id}/logo`,
        };
    }

    getDefaultEntityOptions(): EntityListOptions {
        return {
            offset: 0,
            limit: 10,
            sort: 'name:asc',
            filter: 'state=active',
        };
    }

    getFilterRqlString(value?: string): string {
        return value ? `state=active,name=*${escapeRqlValue(value)}*` : 'state=active';
    }
}
