import { Subject ,  Subscription, Observable, combineLatest, ObservableInput } from 'rxjs';
import { OnDestroy, Component } from '@angular/core';

const ComponentCounts = {}; // for debugging, keep a count of how many instances of each component type is active

/** Implement this interface to get notifications when the Route Reuse Strategy is about to reattach the component
 *  to the router.
 *  */
export interface OnAttach {
    ngOnAttach(): void;
}

/** Implement this interface to get notifications when the Route Reuse Strategy is about to detach the component
 *  from the router.
 *  */
export interface OnDetach {
    ngOnDetach(): void;
}

@Component({
    selector: 'key-base-component',
    template: '',
})
export abstract class BaseComponent implements OnDestroy {

    private _destroyed: Subject<void>;
    private _subscriptions: Subscription[] = [];
    private destroyTriggered = false;

    /** A utility observable to determine when the class is destroyed */
    protected get destroyed() : Observable<void> {
        // prevent descendants from sending a value or completing the subject
        return this._destroyed.asObservable();
    }

    constructor() {
        this._countRefs(1);
        // set up an observable that can be used with any .takeUntil in this class to manage unsubscriptions
        this._destroyed = new Subject<void>();
        const originalDestroy = this.ngOnDestroy.bind(this);
        this.ngOnDestroy = () => {
            this.unsubscribeAll();
            this._countRefs(-1);
            originalDestroy();
            this.triggerDestroyed();
            this._destroyed.complete();
        };
    }
    
    ngOnDestroy() {
        // no-op, can be overridden in descendants
    }

    triggerDestroyed() {
        if (!this.destroyTriggered) {
            this._destroyed.next();
            this.destroyTriggered = true;
        }
    }
    
    private _countRefs(value: number) {
        if (ComponentCounts) {
            ComponentCounts[this.constructor.name] = (ComponentCounts[this.constructor.name] || 0) + value;
            // Enable the logging below to output component reference counts while debugging.
            // console.log(ComponentCounts);
        }
    }
    
    on<T>(subject: Observable<T>, next?: (value: T) => void, error?: (error: any) => void, complete?: () => void): void {
        this._subscriptions.push(subject.subscribe(next, error, complete));
    }
    
    onAll<T>(subjects: ObservableInput<T>[], next?: (value: T[]) => void, error?: (error: any) => void, complete?: () => void): void {
        this._subscriptions.push(combineLatest(subjects).subscribe(next, error, complete));
    }
    
    unsubscribeAll() {
        this._subscriptions.forEach(sub => sub.unsubscribe());
        this._subscriptions = [];
    }

}

