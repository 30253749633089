/*
* The purpose of this service is to streamline the repetive actions that header toolbars will need i.e. either
*   - toggle sidebar
*   - navigate back to previous page
*   - navigate by closing current view
*/

import { Injectable } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { filter, pairwise, map } from 'rxjs/operators';

@Injectable()
export class MobileNavigationActionsService {

    private sidebarToggled: BehaviorSubject<boolean> = new BehaviorSubject(false);
    get sidebarToggle$(): Observable<boolean> {
        return this.sidebarToggled.asObservable();
    }

    previousRoute: string;

    constructor(public router: Router) {

        this.previousRoute = this.router.url;

        this.router.events.pipe(
            pairwise(),
            filter(events => events[0] instanceof NavigationEnd && events[1] instanceof NavigationStart),
            map(events => events[0])
        ).subscribe((route: NavigationEnd) => this.previousRoute = route.url);
    }

    toggleSidebar(state?: boolean): boolean {
        const next = state === undefined ? !this.sidebarToggled.getValue() : state;
        this.sidebarToggled.next(next);
        return next;
    }

    navigateToPrevRoute() {
        this.router.navigate([this.previousRoute]);
    }

    closeWindow(redirectRoute?: any[]) {
        this.router.navigate(redirectRoute || [this.previousRoute]);
    }
}
