import { Component, OnInit } from '@angular/core';

import { MapOptionComponent } from '../map-option.component';
import { MapOptionLayerService } from './map-option-layers.service';

export interface MapOptionLayer {
    title: string;
    id: string;
    visible: boolean;
    action: (layer: MapOptionLayer) => void;
    meta?: { [key: string]: any }; // allow maps to store library specific information if needed
}

export interface MapOptionLayerConfig {
    baseLayers : MapOptionLayer[];
    overlays: MapOptionLayer[];
    supportsMarkerClustering: boolean;
    showLabelsToggle: boolean;
}

@Component({
    styleUrls: ['./map-option-layers.component.scss'],
    templateUrl: './map-option-layers.component.html',
})
export class MapOptionLayerComponent implements MapOptionComponent<MapOptionLayerConfig>, OnInit {
    
    static ID = 'map-option-layers-component';
    static ICON = 'layer-group';
    static TITLE = 'LEAFLET.MAP_OPTIONS.LAYERS';

    data: MapOptionLayerConfig;

    clustering = true;
    labels = true;

    constructor(private layerService: MapOptionLayerService) {}

    ngOnInit(): void {
        this.clustering = this.layerService.markerClustering;
        this.labels = this.layerService.labels;
    }

    clickLayer(event: MouseEvent, layer: MapOptionLayer) {
        layer.visible = (event.target as HTMLInputElement).checked;
        layer.action(layer);
    }

    toggleClustering() {
        this.layerService.toggleMarkerClustering();
    }

    toggleLabels() {
        this.layerService.toggleLabels();
    }

}

