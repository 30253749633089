<div class="btn-group"
    [ngClass]="{'dropup': dropUp, 'no-dropdown': hideDropdown, 'form-input': isFormControl, 'disabled': disabled}">
    <div *ngIf="style === 'none'"
        [class.form-input]="isFormControl"
        data-toggle="dropdown"
        (click)="toggle()">
        <ng-content select="div, span, a, p, strong, i, b, ul, li, kui-icon"></ng-content>
    </div>
    <button *ngIf="style !== 'none'"
        kui-button
        class="dropdown-toggle d-flex align-items-center"
        data-toggle="dropdown"
        [class.form-control]="isFormControl"
        [color]="style"
        [size]="buttonSize"
        [outlined]="outlined"
        (click)="toggle()">
        <ng-content></ng-content>

        <ng-content select="kui-dropdown-button"></ng-content>

        <kui-icon class='ml-2'
            *ngIf="!dropUp"
            name="caret-down"></kui-icon>
        <kui-icon class='ml-2'
            *ngIf="dropUp"
            name="caret-up"></kui-icon>
    </button>
</div>