export const removeSpaces = (str: string): string => {
    return str ? str.replace(/\s/g, '') : '';
};

export const toTitleCase = (str: string): string => {
    return str ? str.substring(0, 1).toUpperCase() + str.substring(1) : '';
};

export const toCamelCase = (str: string): string => {
    return str.split(' ')
        .map((word, i) => i === 0 ? word.substring(0, 1).toLocaleLowerCase() + word.substring(1) : toTitleCase(word))
        .reduce((res, word) => res + word, '');
};
