<ng-container *ngIf="{
    zones: zones$ | async
} as data">
    <div class="p-3">
        <div
            class="heading"
            [ngClass]="{'compact': compact}">
            <kui-icon
                color="default"
                class="mr-3"
                [size]="compact ? 'sm' : 'lg'"
                name="edit">
            </kui-icon>
            <h5 class="m-0">
                {{'LEAFLET.ZONES.SELECT_ITEM' | translate}}
            </h5>
        </div>

        <kui-input-group [cleanAddons]="true">
            <input kui-input
                #searchInput
                type="text"
                [placeholder]="'LEAFLET.ZONES.FILTER_ZONES' | translate"
                [ngModel]="term"
                (ngModelChange)="updateSearchTerm($event)">
            <kui-icon
                *ngIf="term"
                kui-input-group-addon
                kui-action
                name="times"
                class="pl-2"
                [title]="'DIALOG.CLEAR' | translate"
                (click)="clearSearchTerm()"></kui-icon>
        </kui-input-group>
    </div>
    <ng-container *ngIf="data.zones?.length > 0 else noZones">
        <div class="zone-list">
            <div
                *ngFor="let zone of data.zones"
                kui-action
                class="zone"
                (click)="selectZone(zone.id)">
                <kui-icon
                    class="zone__icon"
                    size="sm"
                    color="default"
                    [name]="zone.type !== 'route' ? 'draw-polygon' : 'scribble'">
                </kui-icon>
                <span class="zone__title mx-2">
                    {{zone.name}}
                </span>
                <kui-icon
                    class="zone__indicator"
                    name="chevron-right">
                </kui-icon>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-template #noZones>
    <p class="d-flex px-4 py-2 align-items-center">
        <ng-container *ngIf="term">
            {{'LEAFLET.NO_RESULTS' | translate}}
        </ng-container>
        <ng-container *ngIf="!term">
            {{'LEAFLET.ZONES.NO_VISIBLE_ITEMS' | translate}}
        </ng-container>
    </p>
</ng-template>