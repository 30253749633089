<div class="dropdown-menu"
    [style.transform]="translate">
    <ng-container *ngIf="customContentLocation === 'TOP'">
        <ng-container *ngTemplateOutlet="customContent; context: customContentContext"></ng-container>
    </ng-container>

    <div *ngFor="let item of menu; let i = index;">
        <h6 *ngIf="item.type === 'heading'"
            class="dropdown-header"
            [innerHtml]="item.text"></h6>

        <a *ngIf="item.type === 'link'"
            class="dropdown-item"
            [routerLink]="item.link">
            <span [ngStyle]="{ 'padding-left': (item.indent) + 'rem' }"></span>
            <kui-icon *ngIf="item.icon"
                class="mr-2"
                [name]="item.icon"></kui-icon> <span [innerHtml]="item.text"></span>
        </a>

        <button *ngIf="item.type === 'action'"
            class="dropdown-item"
            (click)="onAction.emit({item: item, event: $event})">
            <span [ngStyle]="{ 'padding-left': (item.indent) + 'rem' }"></span>
            <kui-icon *ngIf="item.icon"
                class="mr-2"
                [name]="item.icon"></kui-icon> <span [innerHtml]="item.text"></span>
        </button>

        <button *ngIf="item.type === 'radio'"
            class="dropdown-item d-flex"
            (click)="onAction.emit({item: item, event: $event})">
            <span [ngStyle]="{ 'padding-left': (item.indent) + 'rem' }"></span>
            <span class='pr-3'
                [innerHtml]="item.text"></span>
            <kui-icon *ngIf="item.checked"
                class='pl-2 ml-auto'
                [name]="item.icon || 'check'"></kui-icon>
        </button>

        <div *ngIf="item.type === 'checkbox' || item.type === 'toggle'"
            class="dropdown-item"
            (click)="dispatchToggleAction(item, $event)">
            <div class="position-relative d-flex align-items-center">
                <label class="mb-0 pointer pr-4">
                    <span [ngStyle]="{ 'padding-left': (item.indent) + 'rem' }"></span>
                    <kui-icon *ngIf="item.icon"
                        class="mr-2"
                        [name]="item.icon"></kui-icon> <span [innerHtml]="item.text"></span>
                </label>

                <ng-container *ngIf="item.type === 'checkbox'">
                    <input kui-checkbox
                        [id]="'dropdownCheckbox' + i"
                        [checked]="item.checked"
                        class="right pointer">
                </ng-container>

                <ng-container *ngIf="item.type === 'toggle'">
                    <kui-toggle [checked]="item.checked"
                        size="sm"
                        class="pl-2 ml-auto"></kui-toggle>
                </ng-container>
            </div>
        </div>

        <div *ngIf="item.type === 'divider'"
            class="dropdown-divider"></div>
    </div>

    <ng-container *ngIf="customContentLocation === 'BOTTOM'">
        <ng-container *ngTemplateOutlet="customContent; context: customContentContext"></ng-container>
    </ng-container>
</div>