import { Component, Input, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'kui-alert',
  templateUrl: './alert.component.html',
})
export class KuiAlertComponent implements OnInit {
  @Input() title: string;
  @Input() type = 'success';
  @HostBinding('attr.colorType') colorType: string;

  ngOnInit() {
    // make sure the colorType attr is present for styling purposes
      this.colorType = this.type;
  }
}
