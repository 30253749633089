import { Directive, ElementRef, Renderer2, Input, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[kui-nav-link]',
})
export class KuiNavLinkDirective implements OnInit {
  $this;
  @Input('sub') sub: HTMLElement;
  @Input() open: boolean;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    renderer.addClass(el.nativeElement, 'main-nav-link');
    renderer.addClass(el.nativeElement, 'nav-link');
  }

  @HostListener('click') onClick() {
    if (this.sub) {
      this.toggleSub();

      if (this.open) {
        this.renderer.addClass(this.el.nativeElement, 'open');
      } else {
          this.renderer.removeClass(this.el.nativeElement, 'open');
      }
    }
  }

  ngOnInit() {
    if (this.sub) {
      if (this.open) {
        this.renderer.addClass(this.el.nativeElement, 'open');
      } else {
        this.sub.classList.add('sr-only');
      }
    }
  }

  toggleSub() {
    if (this.open) {
      this.sub.classList.add('sr-only');
      this.open = false;
    } else {
      this.sub.classList.remove('sr-only');
      this.open = true;
    }
  }

}
