import { Component, Input, HostBinding, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'key-listing',
    templateUrl: './listing.component.html',
    styleUrls: ['./listing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyListingComponent implements OnInit {
    _active: boolean;

    @Input() loading: boolean;
    @Input() disabled: boolean;

    @Input()
    set active(active) {
        this._active = active;
        this.activeClass = this.active;
    }
    get active() {
        return this._active;
    }

    @Input() hover: boolean;
    @Input() color: string; // show a color bar if set

    @HostBinding('class') hostClasses = 'listing border-width';
    @HostBinding('class.listing--hover') hoverClass;
    @HostBinding('class.listing--active') activeClass;

    ngOnInit() {
        this.hoverClass = this.hover && !this.disabled;
    }
}
