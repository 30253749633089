<div class="info-container border-radius d-flex">

  <div class="info">
    <div class="info-header">
      <div>
        <ng-content select="[id=info-title]"></ng-content>
      </div>

    </div>
    
    <ng-content select="[id=info-small-text]"></ng-content>

    <div class="feed-icons" *ngIf="icons && !!icons.length">
      <div class="feed-icon" *ngFor="let item of icons">
        <kui-icon [name]="item.name" class="text-{{item.color}}" [fill]="true" [title]="item.tooltip"></kui-icon>
      </div>
    </div>
    
  </div>

  <ng-container *ngIf="indicators && !!indicators.length; else noIndicators">
    <ng-container *ngFor="let item of indicators">
      <div class="indicator bg-{{item}}"></div>
    </ng-container>
  </ng-container>
  <ng-template #noIndicators>
    <div class="indicator"></div>
  </ng-template>
</div>
