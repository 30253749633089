import { Component, Inject, ElementRef, Input, OnChanges, HostBinding, SimpleChanges } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LayoutGridColumnComponent } from '../layout-grid';
import { LayoutGridColumnService } from '../layout-grid/layout-grid-column.service';
import { TopNavSettings } from 'app/key-ui/top-nav';
import { MatchMediaService, MobileNavigationActionsService, NavigationService } from 'app/services';
import { ContentHeaderTabItem, ContentHeaderTitle } from 'app/key-ui/content-header/content-header.component';
import { AppService } from 'app/app.service';

@Component({
    selector: 'key-layout-view',
    templateUrl: './layout-view.component.html',
})
export class LayoutViewComponent extends LayoutGridColumnComponent implements OnChanges {
    isDesktop: boolean;
    title: string | ContentHeaderTitle;
    mobileActionIcon: string;
    mobileActionClick: () => void;

    @Input() show: boolean; // TODO: consider moving show and hidden to LayoutGridColumnComponent instead... Although it will just be a bigger refactor with no real gain
    @Input() page: string;
    @Input() header: TopNavSettings;
    @Input() scroll = true;

    @HostBinding('class.hidden') hidden = false;

    constructor(
        private app: AppService,
        public el: ElementRef,
        @Inject(DOCUMENT) public document: HTMLDocument,
        public layoutGridService: LayoutGridColumnService,
        public media: MatchMediaService,
        private mobileActions: MobileNavigationActionsService,
        public navigation: NavigationService
    ) {
        super(el, document, layoutGridService);

        this.on(this.media.isDesktop, isDesktop => {
            this.isDesktop = isDesktop;
            this.update();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.show || changes.header) {
            this.hidden = !this.show;
            this.update();
        }
    }

    update() {
        if (this.header) {

            const pageName = (this.navigation.PAGES && this.navigation.PAGES[this.page]?.name) || '';

            const { navigationAction, title } = this.header;
            this.mobileActionIcon = navigationAction && navigationAction.icon || 'menu';
            this.mobileActionClick = navigationAction && navigationAction.click || (() => this.mobileActions.toggleSidebar());
            this.title = title || (!this.isDesktop && this.page && (typeof pageName === 'string' ? pageName : pageName(this.app))); // always add page tile to mobile
        }
    }

    getActiveTab(tabs: ContentHeaderTabItem[], active: string): ContentHeaderTabItem {
        if (!tabs) { return null; }
        return tabs.find(x => x.id === active) || tabs[0];
    }
}
