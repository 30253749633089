import { Component, Input, ViewChild, Output, EventEmitter, ChangeDetectorRef, OnInit } from '@angular/core';
import { FormBuilderField, BaseFormBuilderFieldComponent } from 'app/shared/components/form-builder';
import { MeasurementUnitsService, MatchMediaService } from 'app/services';
import * as moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
import { IKuiDropdownMenuItem } from 'app/key-ui';
import { KuiDropdownComponent } from 'app/key-ui/dropdown/dropdown.component';
import { DeviceFeaturesBrowser } from 'app/services/match-media/match-media.service';

@Component({
    selector: 'key-form-builder-daytime-field',
    templateUrl: './daytime.component.html',
    styleUrls: ['./daytime.component.scss'],
})
export class KeyFormBuilderDayTimeFieldComponent implements BaseFormBuilderFieldComponent, OnInit {

    @Input() field: FormBuilderField;
    @Input() values: { [key: string]: any };
    @Input() error: string; // set this error value externally to have the default error highlight and display kick in

    @Output() onChange: EventEmitter<{ value: string, dirty: boolean }> = new EventEmitter();

    @ViewChild(KuiDropdownComponent, { static: true }) dayPresetElement: KuiDropdownComponent;
    
    invalidTimeError: boolean;
    showTimeInput: boolean;
    touched = false;
    dayValue: string;
    timeValue: string;
    timeAsISOValue: string;
    dayPresetDropdownValues: IKuiDropdownMenuItem[];

    constructor(
        private ref: ChangeDetectorRef,
        protected units: MeasurementUnitsService,
        private i18n: TranslateService,
        private media: MatchMediaService
    ) { }

    validate(): boolean {
        this.touched = true;
        this.ref.markForCheck();
        return !this.field.required || !!(this.dayValue && this.timeValue);
    }

    ngOnInit() {
        // only allow input field on Chrome and Firefox cause Safari is being a dick and I didn't test Edge so not taking any chances
        this.showTimeInput = [
            DeviceFeaturesBrowser.CHROME, 
            DeviceFeaturesBrowser.FIREFOX,
        ].includes(this.media.deviceFeatures.browser);
        
        // set a default
        this.values[this.field.id] = this.values[this.field.id] || this.field.value || `0 ${moment().format('HH:mm')}`;
        this.convertToFieldValues(this.values[this.field.id]);

        const days = [
            { key: '0', value: 'TODAY' },
            { key: '-1', value: 'YESTERDAY' },
            { key: '-2', value: '2_DAYS_AGO' },
            { key: '-3', value: '3_DAYS_AGO' },
        ];
        this.dayPresetDropdownValues = days.map(x => ({
            type: 'action',
            id: x.key,
            text: this.i18n.instant(`FORMS.DAYTIME.OPTIONS.${x.value}`),
            closeDropdownOnClicked: true,
            action: () => {
                this.dayValue = x.key;
                this.emitChange();
            },
        })) as IKuiDropdownMenuItem[];
    }

    convertToFieldValues(value: string) {
        const [day, time] = value.split(' ');
        if (day && time) { // value is 'day time'
            this.dayValue = day;
            if (this.isValidTime(time)) {
                this.timeValue = time;
            } else {
                // remove seconds to make it a valid time value to work with
                const [HH, mm] = time.split(':');
                this.timeValue = HH + ':' + mm;
            }
        } else { // in case the consumer sends down iso values... NOTE: this would be considered wrong, but we are being the nice guy over here 
            this.dayValue = '0';
            this.timeValue = moment(value).format('HH:mm');
        }

        this.timeAsISOValue = this.getTimeAsISO(this.timeValue);
    }

    emitChange() {
        if (this.isValidTime(this.timeValue)) {
            this.invalidTimeError = false;
            const value = this.dayValue + ' ' + this.timeValue + ':00'; // add seconds on final value
            this.values[this.field.id] = value;
            this.onChange.emit({ value, dirty: true });
        } else {
            this.invalidTimeError = true;
        }
    }
    
    changeTime(time: string) {
        this.timeValue = this.isValidTime(time) ? time : moment(time).format('HH:mm');
        this.timeAsISOValue = this.getTimeAsISO(this.timeValue);
        this.emitChange();
    }

    getDayPresetText(key: string): string {
        const preset = this.dayPresetDropdownValues.find(x => x.id === key);
        return preset ? preset.text as string : key;
    }
    
    // datetime component requires an ISO string
    getTimeAsISO(time: string): string {
        const [HH, mm] = time.split(':').map(x => parseFloat(x));
        const date = moment().hours(HH).minutes(mm);
        if (moment.isMoment(date)) {
            return date.toISOString();
        }
        return null;
    }

    openPresets() {
        setTimeout(() => { // allows onOutsideClick check of dropdown to run before calling the toggle
            this.dayPresetElement.toggle(true);
        });
    }
    
    private isValidTime(time: string): boolean {
        return !!(time || '').match(/^\d{2}:\d{2}$/g);
    }
}