import { Component, Input, OnChanges, HostBinding, Injector, Output, EventEmitter } from '@angular/core';
import { Dataset, Average } from 'app/shared/components/graph/graph.model';
import { AnalyticsChartService } from './chart.service';
import { AnalyticsChartGraphService } from './graph/graph.service';
import { AnalyticsSettings, AnalyticsCellValue } from '../analytics.model';
import { AnalyticsChartPieService } from './pie/pie.service';
import { BaseComponent } from '../../base/base.component';
import { AppService } from 'app/app.service';
import { AnalyticsChartStatService } from './stat/stat.service';
import { THEME_GRAPH_PALETTES } from '../../theme/theme-defaults';
import { get } from 'lodash';

export interface AnalyticsChartData {
    datasets: Dataset[];
    averages?: Average[];
}

export type AnalyticsChartType = 'chart' | 'grid' | 'text' | 'stat' | 'pie';

@Component({
    selector: 'key-analytics-chart',
    templateUrl: './chart.component.html',
    providers: [
        AnalyticsChartGraphService,
        AnalyticsChartPieService,
        AnalyticsChartStatService,
    ],
})
export class AnalyticsChartComponent extends BaseComponent implements OnChanges {
    data: AnalyticsChartData;
    chartService: AnalyticsChartService<AnalyticsChartData>;
    colors: string[];

    @Input() type: AnalyticsChartType;
    @Input() cellset: AnalyticsCellValue[][];
    @Input() settings: AnalyticsSettings;
    @Input() size: string;

    @Output() onMeasureSelection = new EventEmitter<string>();
    @Output() onLoad = new EventEmitter<AnalyticsChartData>();

    @HostBinding('class') hostClassnames = 'd-flex flex-column flex-1';

    constructor(
        public injector: Injector,
        private app: AppService
    ) {
        super();
        this.on(this.app.theme$, theme => {
            if (theme) {
                this.colors = THEME_GRAPH_PALETTES[get(theme.settings, 'graph.palette', 'default')] || THEME_GRAPH_PALETTES.default;
            }
        });
    }

    ngOnChanges() {
        if (this.cellset && this.settings) {
            this.chartService = this.injectChartService();
            this.data = this.chartService && this.chartService.getData(this.cellset, this.settings, this.colors);
            
            this.onLoad.emit(this.data);
        }
    }

    injectChartService(): AnalyticsChartService<AnalyticsChartData> {
        switch (this.type) {
            case 'chart': return this.injector.get(AnalyticsChartGraphService);
            case 'pie': return this.injector.get(AnalyticsChartPieService);
            case 'stat': return this.injector.get(AnalyticsChartStatService);
            default:
                console.warn('No/unsupported type specified on AnalyticsChartComponent. To make sure the correct chart service is used please add the correct type to your <key-analytics-chart type="CHART_TYPE_HERE" /> tag.');
                return null;
        }
    }
}
