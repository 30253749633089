import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KuiModule } from 'app/key-ui';

import { PipeModule } from 'app/shared/pipes/pipe.module';
import { GetKeyPipe } from 'app/shared/pipes/getkey.pipe';
import { FormModalComponent } from './form/form-modal.component';
import { KeyFormBuilderModule } from '../form-builder/form-builder.module';
import { ConfirmModalComponent } from './confirm/confirm.component';
import { ErrorModalComponent } from './error/error.component';
import { ModalService } from './modal.service';
import { AlertModalComponent } from './alert/alert.component';
import { IFrameModalComponent } from './iframe/iframe.component';
import { JsonModalComponent } from './json/json.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { MarkdownModule } from 'ngx-markdown';

const COMPONENTS = [
    FormModalComponent,
    ConfirmModalComponent,
    ErrorModalComponent,
    AlertModalComponent,
    JsonModalComponent,
    IFrameModalComponent,
];

@NgModule({
    imports: [
        CommonModule,
        KuiModule,
        TranslateModule.forChild(),
        PipeModule.forRoot(),
        MarkdownModule.forRoot(),
        KeyFormBuilderModule,
        NgxJsonViewerModule,
    ],
    exports: COMPONENTS,
    declarations: COMPONENTS,
    providers: [GetKeyPipe, ModalService]
})
export class KeyModalModule { }
