import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AssetListItem, ZoneListItem } from '@key-telematics/fleet-api-client';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'app/app.service';
import { KuiModule } from 'app/key-ui';
import { KuiTreeSelectNode } from 'app/key-ui/tree-select/tree-select.component';
import { SearchResult } from 'app/shared/components/search-results-list/search-results-list.component';
import { KeySearchResultsListModule } from 'app/shared/components/search-results-list/search-results-list.module';
import { escapeRqlValue } from 'app/shared/utils/rql';
import { debounce } from 'lodash';

type EntityType = 'asset' | 'linked' | 'zone' | 'zones';

@Component({
    selector: 'key-form-builder-eventfilter-searchable-tree',
    templateUrl: './searchable-tree.component.html',
    standalone: true,
    imports: [
        KuiModule,
        KeySearchResultsListModule,
        CommonModule,
        FormsModule
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyFormBuilderEventFilterSearchableTreeComponent implements OnChanges {

    @Input() nodes: KuiTreeSelectNode[];
    @Input() ownerId: string;
    @Input() entityType: EntityType;
    @Input() selectedNode: string;

    @Output() onChange = new EventEmitter<KuiTreeSelectNode>();

    searching: boolean;
    searchDescription: string;
    isSearchableType: boolean;
    staticSearchNodes: SearchResult<KuiTreeSelectNode>[] = [];
    searchNodes: SearchResult<KuiTreeSelectNode>[];
    searchListPromise: (filter: string) => Promise<SearchResult<KuiTreeSelectNode>[]>;

    debouncedSearchValue = debounce(this.updateSearch, 500, { trailing: true, leading: false });

    _searchValue: string;
    get searchValue(): string {
        return this._searchValue;
    }
    set searchValue(value: string) {
        this.debouncedSearchValue(value);
    }

    @ViewChild('searchInput', { static: true }) searchInput: ElementRef<HTMLInputElement>;
    @ViewChild('searchResults', { static: true }) searchResults: ElementRef<HTMLDivElement>;

    constructor(
        public app: AppService,
        private i18n: TranslateService,
        private ref: ChangeDetectorRef
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.entityType) {
            this.searchListPromise = this.getSearchListPormise(this.entityType);
            this.searchDescription = this.getEntitySearchDescription(this.entityType);
            this.isSearchableType = ['asset', 'linked', 'zone', 'zones'].includes(this.entityType);
        }

        if (changes.nodes && this.searchListPromise(null)) {
            this.staticSearchNodes = [];
            const uniqueNodes = new Map<String, SearchResult<KuiTreeSelectNode>>();
            const traverseTree = (nodes: KuiTreeSelectNode[]) => {
                (nodes || []).forEach(node => {
                    if (node.data) {
                        const res = {
                            id: node.id,
                            value: node.data.text,
                            meta: node,
                        };
                        uniqueNodes.set(node.data.text, res);
                    }
                    traverseTree(node.children);
                });
            };
            traverseTree(this.nodes);
            this.staticSearchNodes = [...uniqueNodes.values()];
        }
    }

    onSelected(event: KuiTreeSelectNode) {
        if (event.eventName === 'activate') {
            this.searchValue = '';
            this.onChange.emit(event);
        }
    }

    getSearchListPormise(type: EntityType): (filter: string) => Promise<SearchResult<KuiTreeSelectNode>[]> {
        const limit = 10;
        const getItems = (res, filter) => !filter ? [] : [
            ...this.staticSearchNodes,
            ...res.items.map(this.itemToSearchNode.bind(this)),
        ];

        return (filter) => {
            switch (type) {
                case 'asset':
                case 'linked':
                    return this.app.api.entities.listAssets(this.ownerId, 0, limit, 'name:asc', 'state=active' + (filter || '')).then(x => getItems(x, filter));
                case 'zone':
                case 'zones':
                    return this.app.api.entities.listZones(this.ownerId, 0, limit, 'name:asc', 'state=active' + (filter || '')).then(x => getItems(x, filter));
                default:
                    return null; // when this returns null there will be no search, just a tree
            }
        };
    }

    itemToSearchNode(node: any): SearchResult<KuiTreeSelectNode> {
        const typeName = this.getEntityTypeName(this.entityType, node);
        return {
            id: node.id,
            value: `${typeName} "${node.name}"`,
            meta: {
                id: node.id,
                name: node.name,
                data: this.getEntityData(this.entityType, node, typeName),
            },
        };
    }

    getEntityData(type: EntityType, entity: any, typeName: string): { [key: string]: any } {
        switch (type) {
            case 'asset':
            case 'linked':
                return {
                    actorId: entity.id,
                    actorName: entity.name,
                    actorSelectionType: 'specific',
                    actorType: 'asset',
                    actorTypeId: entity.assetType.id,
                    actorTypeName: typeName,
                    id: entity.id,
                    name: entity.name,
                    text: `${typeName} "${entity.name}"`,
                };
            case 'zone':
            case 'zones':
                return {
                    targetId: entity.id,
                    targetName: entity.name,
                    targetSelectionType: 'specific',
                    targetType: 'zone',
                    targetTypeId: entity.zoneType,
                    targetTypeName: entity.zoneType,
                    text: `${typeName} "${entity.name}"`,
                };
            default:
                return null;
        }
    }

    getEntityTypeName(type: EntityType, entity: any): string {
        const name = {
            asset: (entity as AssetListItem).assetType && entity.assetType.name,
            zone: (entity as ZoneListItem).zoneType,
        };
        if (type === 'linked') { type = 'asset'; }
        if (type === 'zones') { type = 'zone'; }
        return this.i18n.instant(`SHARED.${type.toUpperCase()}_TYPES.${(name[type] || '').toUpperCase().replace(/ /g, '_')}`);
    }

    getEntitySearchDescription(type: EntityType): string {
        switch (type) {
            case 'asset':
            case 'linked':
                return this.i18n.instant('FORMS.ASSETFILTER.PLACEHOLDER');
            case 'zone':
            case 'zones':
                return this.i18n.instant('FORMS.ZONEFILTER.PLACEHOLDER');
            default:
                return this.i18n.instant('SHARED.SEARCH_ELLIPSES');
        }
    }

    async updateSearch(searchTerm: string) {
        this._searchValue = searchTerm;

        const filter = searchTerm ? escapeRqlValue(searchTerm) : '';

        this.searching = true;
        this.ref.markForCheck();

        try {
            const list = await this.searchListPromise(!!searchTerm.length ? `&name=*${filter}*` : '');
            this.searchNodes = (list || []).filter(x => x.meta.data.text.toLowerCase().includes(searchTerm.toLowerCase()));
        } catch (err) {
            console.error(err);
            this.searchNodes = [];
        } finally {
            this.searching = false;
            this.ref.markForCheck();
        }
    }

    blurSearchInput() {
        if (this.searchInput) {
            this.searchInput.nativeElement.blur();
        }
    }
}
