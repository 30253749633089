import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticPageService } from './static-page.service';
import { StaticPageComponent } from './static-page.component';

const COMPONENTS = [
    StaticPageComponent,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: COMPONENTS,
  declarations: COMPONENTS,
  providers: [StaticPageService],
})
export class StaticPageModule { }
