import { combineLatest ,  Subject } from 'rxjs';

import { environment } from 'environments/environment';
import { GoogleAnalyticsService } from 'app/services/google-analytics/google-analytics.service';
import { Injectable } from '@angular/core';
import * as Raven from 'raven-js';

import { AppService } from 'app/app.service';

@Injectable()
export class ErrorLoggerService {

    unhandledError$ = new Subject<Error | string>();

    constructor(
        private app: AppService,
        public analytics: GoogleAnalyticsService
    ) {
        // The errormonster is hungry
        if (environment.production) {
            Raven.config(environment.sentryDsn, {
                release: environment.version,
                environment: environment.production ? 'production' : 'development',
            }).install();
        }

        combineLatest(
            this.app.user$,
            this.app.client$
        ).subscribe(([user, client]) => {

            if (user && client) {

                Raven.setTagsContext({
                    client: client.id,
                });

                Raven.setUserContext(
                    <any>{
                        id: user.id,
                        username: user.emailAddress,
                        clientId: client.id,
                        client: client.name,
                        type: user.owner && user.owner.type,
                    }
                );

            } else {
                Raven.setTagsContext({});
                return Raven.setUserContext();
            }

        }, error => {
            console.error(error); // should never happen, but lets have some output if it does!
        });
    }

    /**
       * Track an individual message
       */
    trackMessage(title: string, extra?: any) {

        console.log(title, extra);

        try {
            // Dispatch to analytics from aggregate overview
            this.analytics.emitException(title);
            // Send to sentry
            return Raven.captureMessage(title, { extra });
        } catch (_err) {
            // don't let errors in error logging bubble up!
        }
    }

    /**
       * Track an exception
       */
    trackException(error: Error, exclude: string[] = [], extra?: any) {
        if (error && !exclude.includes(error.name)) {
            console.error('TRACKEXCEPTION', error);
            try {
                // Dispatch to analytics from aggregate overview
                this.analytics.emitException(error.message);
                // Send to sentry
                return Raven.captureException(error, { extra });
            } catch (_err) {
                // don't let errors in error logging bubble up!
            }
        }

    }
}
