import { Component, Output, EventEmitter, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilderDefinition, FormBuilderFieldChangeEvent } from 'app/shared/components/form-builder';
import { IKuiSelectOption } from 'app/key-ui';

export interface AverageValuesSelected {
    values: { key: string, value: string }[];
    selected?: string;
}

export interface AveragesSettings {
    selection?: AverageValuesSelected;
    measure: string;
    color: string;
    lineStyle: 'solid' | 'dashed';
}

@Component({
    selector: 'key-analytics-settings-averages',
    templateUrl: './averages.component.html',
})
export class AnalyticsSettingsAveragesComponent implements OnInit, OnChanges {
    selectionDirty: boolean;
    selectionValues: IKuiSelectOption[];
    form: FormBuilderDefinition;
    values: { [key: string]: any };

    @Input() options: AveragesSettings;
    @Output() onChange: EventEmitter<{ [key: string]: any; }> = new EventEmitter();

    constructor(
        private i18n: TranslateService
    ) {
    }

    ngOnInit() {
        this.form = {
            'groups': [{
                fields: [{
                    type: 'color', id: 'color', title: 'COLOR',
                }, {
                    type: 'radio', id: 'lineStyle', title: 'LINE_STYLE', values: [
                        { key: 'dashed', value: this.i18n.instant('ANALYTICS.SETTINGS.DASHED') },
                        { key: 'solid', value: this.i18n.instant('ANALYTICS.SETTINGS.SOLID') },
                    ],
                }],
            }],
        };
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.options && this.options) {
            this.selectionDirty = false;
            this.selectionValues = this.options.selection && this.options.selection.values.map(item => ({
                id: item.key,
                value: item.value,
                selected: this.options.selection.selected === item.key,
            }));
            setTimeout(() => {
                this.selectionDirty = true;
            });
            const { color, lineStyle } = this.options;
            this.values = { color, lineStyle };
        }
    }

    changeSelection(selected: IKuiSelectOption[]) {
        if (this.selectionDirty) {
            this.onChange.emit({
                selection: {
                    ...this.options.selection,
                    selected: selected[0].id,
                },
            });
        }
    }

    changeFormSetting(change: FormBuilderFieldChangeEvent) {
        if (change.dirty) {
            const { id } = change.field;
            this.onChange.emit({
                [id]: this.values[id],
            });
        }
    }
}
