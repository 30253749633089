import { Component, Output, EventEmitter, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableSettings } from './table/table.component';
import { FilterSettings } from './filters/filters.component';
import { SeriesSettings, SeriesValuesSelected } from './series/series.component';
import { YAxisSettings, YAxisMinMax } from './y-axis/y-axis.component';
import { SortSettings } from './sort/sort.component';
import { PresetSettings } from './presets/preset.component';
import { AveragesSettings, AverageValuesSelected } from 'app/shared/components/analytics/settings/averages/averages.component';
import { toTitleCase, removeSpaces } from 'app/shared/utils/string.utils';
import { AnalyticsSettings, AvailableSettings, AnalyticsCustomSettings, AnalyticsCellSetFilters } from '../analytics.model';
import { ChartLabel, AnalyticsOutputFilters } from '@key-telematics/fleet-api-client';
import { FormBuilderFieldChangeEvent } from '../../form-builder';
import { XAxisSettings } from './x-axis/x-axis.component';
import { ChartSettings } from './chart/chart.component';
import { PieSettings } from './pie/pie.component';
import { BaseComponent } from '../../base/base.component';
import { AppService } from 'app/app.service';
import { StatSettings } from './stat/stat.component';
import { THEME_GRAPH_PALETTES } from '../../theme/theme-defaults';
import { get } from 'lodash';

export interface SettingsDatesMinMax {
    min?: string;
    max?: string;
}

export interface SettingsMessage {
    text: string;
    icon?: string;
    buttons?: {
        text: string;
        color: string;
        action: () => any;
    }[];
}

@Component({
    selector: 'key-analytics-settings',
    templateUrl: './settings.component.html',
})
export class AnalyticsSettingsComponent extends BaseComponent implements OnInit, OnChanges {
    seriesSelection: SeriesValuesSelected;
    averagesSelection: AverageValuesSelected;
    themeColors: string[];

    get overriddenSeriesValues(): string[] {
        if (!this.settings) { return []; }

        const { series } = this.settings.graph;
        return Object.keys(series || {})
            .filter(key => key !== 'default' && series[key] && this.settings.table.measures.find(({ value }) => removeSpaces(value.toLowerCase()) === key))
            .map(key => {
                const value = this.settings.measures.find(measure => removeSpaces(measure && measure.value.toLowerCase()) === key);
                return value && value.value || key;
            });
    }

    tableSettings: TableSettings;
    legendSettings: ChartLabel;
    yAxisSettings: YAxisSettings;
    xAxisSettings: XAxisSettings;
    sortSettings: SortSettings;
    seriesSettings: SeriesSettings;
    filterSettings: FilterSettings;
    averagesSettings: AveragesSettings;
    chartSettings: ChartSettings;
    pieSettings: PieSettings;
    statSettings: StatSettings;

    presetOptions: PresetSettings[];

    @Input() customTabs: AnalyticsCustomSettings[];
    @Input() show: AvailableSettings = {};
    @Input() settings: AnalyticsSettings;
    @Input() activeTab: string;
    @Input() align: 'right' | 'left' = 'right';
    @Input() title: string;
    @Input() absolute: boolean;
    @Input() datesMinMax: SettingsDatesMinMax;
    @Input() allowCustomDates: boolean;
    @Input() yAxesLimits: { left: YAxisMinMax, right: YAxisMinMax };
    @Input() exportOptions: string[];
    @Input() selectedSeries: string;
    @Input() message: SettingsMessage;
    @Input() pieSegments: number;
    @Input() statSeries: string[];
    @Input() singleMeasureSelection: boolean;
    @Input() filters: AnalyticsCellSetFilters;
    @Input() disableFilters = false;

    @Output() onSeriesClear = new EventEmitter<string[]>();
    @Output() onSeriesSelectionChange = new EventEmitter<string>();
    @Output() onSettingsChange = new EventEmitter<{ [key: string]: any; }>();
    @Output() onTabChange = new EventEmitter<string>();
    @Output() onExport = new EventEmitter<string>();
    @Output() onHide = new EventEmitter<any>();

    constructor(
        private i18n: TranslateService,
        public app: AppService
    ) {
        super();

        this.on(this.app.theme$, theme => {
            if (theme) {
                this.themeColors = THEME_GRAPH_PALETTES[get(theme.settings, 'graph.palette', 'default')] || THEME_GRAPH_PALETTES.default;
            }
        });
    }

    get hideFilters(): boolean {
        return !this.filterSettings || this.disableFilters;
    }

    ngOnInit() {
        // TODO: consider adding presets from outside settings components
        if (this.show.presets) {
            this.presetOptions = [
                {
                    title: 'Bar Top', iconType: 'svg', iconName: 'bar-asc',
                    values: {
                        sortBy: 'acknowledged',
                        direction: 'asc',
                    },
                    formDefinition: {
                        'groups': [{
                            fields: [{
                                type: 'combo', id: 'sortBy', title: this.i18n.instant('ANALYTICS.SETTINGS.SORT_BY'), values: ['acknowledged', 'handled', 'unhandled'].map(value => ({
                                    key: value, value: toTitleCase(value),
                                })),
                            }],
                        }],
                    },
                }, {
                    title: 'Bar Bottom', iconType: 'svg', iconName: 'bar-desc',
                    values: {
                        sortBy: 'acknowledged',
                        direction: 'desc',
                    },
                    formDefinition: {
                        'groups': [{
                            fields: [{
                                type: 'combo', id: 'sortBy', title: this.i18n.instant('ANALYTICS.SETTINGS.SORT_BY'), values: ['acknowledged', 'handled', 'unhandled'].map(value => ({
                                    key: value, value: toTitleCase(value),
                                })),
                            }],
                        }],
                    },
                },
            ];
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        const { series, sort, legend, yAxes, tableLayout, levels, flipped, measures, daterange, stacked, xAxis, dataLabels, pie, stat, filters } = this.show;

        if ((changes.settings || changes.show) && this.settings) {

            this.tableSettings = (tableLayout || levels || flipped || measures || daterange) ? this.getTableSettings() : null;
            this.sortSettings = sort ? this.getSortSettings() : null;
            this.filterSettings = filters ? this.getFilterSettings() : null;

            if (this.settings.pie) {
                this.pieSettings = pie ? this.getPieSettings() : null;
                this.legendSettings = legend ? this.getLegendSettings() : null;
            }

            if (this.settings.stat) {
                this.statSettings = stat ? this.getStatSettings() : null;
            }

            if (this.settings.graph) {
                this.seriesSettings = series ? this.getSeriesSettings() : null;
                this.legendSettings = legend ? this.getLegendSettings() : null;
                this.yAxisSettings = yAxes ? this.getYAxisSettings() : null;
                this.chartSettings = (stacked || dataLabels) ? this.getChartSettings() : null;
                this.xAxisSettings = xAxis ? this.getXAxisSettings() : null;
            }

        }

        if (changes.pieSegments) {
            this.pieSettings = pie ? this.getPieSettings() : null;
        }

        if (changes.statSeries) {
            this.statSettings = stat ? this.getStatSettings() : null;
        }

        if (changes.selectedSeries && this.selectedSeries && this.seriesSelection) {
            this.updateSeriesSettings({
                selection: {
                    ...this.seriesSelection,
                    selected: this.selectedSeries,
                },
            });
        }
    }

    updateCustomSettings(update: FormBuilderFieldChangeEvent) {
        // go through the keys in reverse assigning the actual value to the deepest nested item and then add that result to the subsequent ones to build an object from dot seperated keys
        const buildObjectFromKeys = k => k.reverse().reduce((res, key, i) => ({
            [key]: !!i ? res : get(update.values, id),
        }), {});
        const { id } = update.field;
        const keys = id.split('.');
        const result = keys[1] ? buildObjectFromKeys(keys) : { [id]: update.values[id] };
        this.onSettingsChange.emit(result);
    }

    getSortSettings(): SortSettings {
        const { sort, table } = this.settings;
        return {
            measures: table.measures,
            measureKey: sort.measureKey,
            direction: sort.direction,
            type: sort.type,
            limit: sort.limit,
        };
    }

    updateSortSettings(update: { [key: string]: any; }) {
        this.onSettingsChange.emit({
            sort: update,
        });
    }

    getPieSettings(): PieSettings {
        const { fill, dataLabels, data, series } = this.settings.pie;
        return {
            fill,
            dataLabels,
            data,
            segments: this.pieSegments,
            color: series && series.default && series.default.color || this.themeColors,
            measures: this.settings.table.measures,
        };
    }

    updatePieSettings(update: { [key: string]: any; }) {
        this.onSettingsChange.emit({
            pie: update.color ? {
                series: {
                    default: update,
                },
            } : update,
        });
    }

    getStatSettings(): StatSettings {
        const { key, ...stat } = this.settings.stat;
        // remove duplicate values
        const uniqueSeries = [...new Set(this.statSeries)] || [];
        return {
            ...stat,
            key: uniqueSeries.find(x => x === key) || uniqueSeries[0], // the key should change with the level
            series: uniqueSeries.map(id => {
                // we display the last item on the uniquename which is the actual series item
                const arr = decodeURIComponent(id).split('|');
                return { key: id, value: arr[arr.length - 1] };
            }),
        };
    }

    updateStatSettings(update: { [key: string]: any; }) {
        this.onSettingsChange.emit({
            stat: update,
        });
    }

    getFilterSettings(): FilterSettings {
        const { row, group } = this.settings.filters;
        // TODO: for now range will only support hours on time dim... Future Dude: This might change to support different things, stay sharp!
        const hasRange = (dimension: 'row' | 'group'): boolean => {
            const { dim, levels } = this.settings[dimension];
            return dim === 'time' && !!levels.headings.find(x => x === 'Hour');
        };
        const getLevel = (dimension: 'row' | 'group'): string => {
            const { dim, levels, level } = this.settings[dimension];
            if (dim !== 'time') { return levels.headings[level].toLowerCase(); }
            // always return 'hour' for time dimension
            return levels.headings[levels.headings.length - 1].toLowerCase();
        };

        return {
            row: {
                name: toTitleCase(this.settings.row.dim),
                ...row,
                hasRange: hasRange('row'),
                range: { ...row.range, level: getLevel('row') },
            },
            group: {
                name: toTitleCase(this.settings.group.dim),
                ...group,
                hasRange: hasRange('group'),
                range: { ...group.range, level: getLevel('group') },
            },
        };
    }

    updateFilterSettings(update: AnalyticsOutputFilters) {
        this.onSettingsChange.emit({
            filters: update,
        });
    }

    getLegendSettings(): ChartLabel {
        return this.settings.graph && this.settings.graph.label;
    }

    updateLegendSettings(update: { [key: string]: any; }) {
        this.onSettingsChange.emit({
            graph: {
                label: update,
            },
            pie: {
                label: update,
            },
        });
    }

    getYAxisSettings(): YAxisSettings {
        const { yAxis } = this.settings.graph;
        return {
            left: yAxis.left,
            right: yAxis.right,
        };
    }

    updateYAxisSettings(update: { [key: string]: any; }) {
        this.onSettingsChange.emit({
            graph: { yAxis: update },
        });
    }

    getXAxisSettings(): XAxisSettings {
        return this.settings.graph && this.settings.graph.xAxis;
    }

    updateXAxisSettings(update: { [key: string]: any; }) {
        this.onSettingsChange.emit({
            graph: {
                xAxis: update,
            },
        });
    }

    getChartSettings(): ChartSettings {
        const { dataLabels, stacked } = this.settings.graph;
        return {
            stacked,
            dataLabels,
        };
    }

    updateChartSettings(update: { [key: string]: any; }) {
        this.onSettingsChange.emit({
            graph: update,
        });
    }

    getTableSettings(): TableSettings {
        const { row, group, flipped, measures, table, dateRange, timeOptions, calculatedMeasures } = this.settings;
        return {
            row: {
                dim: row.dim,
                level: row.level,
                levels: row.levels,
            },
            group: {
                dim: group.dim,
                level: group.level,
                levels: group.levels,
            },
            flipped,
            timeOptions,
            layout: table.layout,
            dateRange,
            dateMinMax: this.datesMinMax,
            measures: measures.map(x => ({
                ...x,
                checked: !!table.measures.find(y => x.value === y.value),
            })),
            exportOptions: this.exportOptions,
            calculatedMeasures: calculatedMeasures.measures,
        };
    }

    updateTableSettings(update: { [key: string]: any; }) {
        const { measures, calcMeasureUpdate, layout, ...args } = update;

        if (measures) {
            this.onSettingsChange.emit({
                calcMeasureUpdate: calcMeasureUpdate,
                table: {
                    measures: (measures || this.tableSettings.measures)
                        .filter(x => x.checked)
                        .map(({ checked, ...keyValue }) => keyValue),
                },
            });
        } else if (layout) {
            this.onSettingsChange.emit({ table: { layout } });
        } else {
            this.onSettingsChange.emit({ ...args });
        }

    }

    removeMesureFromTable(measure: string) {
        this.onSettingsChange.emit({
            table: {
                measures: this.settings.table.measures
                    .filter(({ value }) => value !== measure),
            },
        });
    }

    getSeriesSettings(): SeriesSettings {
        const selected = this.settings.table.measures.find(({ value }) => removeSpaces(value && value.toLowerCase()) === (this.seriesSelection && this.seriesSelection.selected));
        const { series, seriesColors } = this.settings.graph;
        const description = this.settings.measures.find(({ value }) => removeSpaces(value && value.toLowerCase()) === this.seriesSelection?.selected);

        this.seriesSelection = {
            values: [{ key: 'default', value: 'Default' }, ...this.settings.table.measures.map(({ value }) => {
                const id = removeSpaces(value && value.toLowerCase());
                return {
                    key: id,
                    value: toTitleCase(value),
                    color: seriesColors && seriesColors[id],
                };
            })],
            selected: removeSpaces(selected && selected.value.toLowerCase() || 'default'),
            selectedMeasureDesc: description,
        };

        const { color, ...currentSeries } = series && series[this.seriesSelection && this.seriesSelection.selected] || series.default;
        return {
            selection: this.seriesSelection,
            ...currentSeries,
            color: color || seriesColors[this.seriesSelection.selected],
        };
    }

    updateSeriesSettings(option: { [key: string]: any; }) {
        if (option && option.selection) {
            this.seriesSelection = option.selection;
            this.seriesSettings = this.getSeriesSettings();
            this.onSeriesSelectionChange.emit(option.selection.selected);
        } else {
            this.onSettingsChange.emit({
                graph: {
                    series: {
                        [this.seriesSelection.selected]: {
                            ...this.settings.graph.series.default,
                            ...this.settings.graph.series[this.seriesSelection.selected],
                            ...option,
                        },
                    },
                },
            });
        }
    }

    // TODO: PRESETS
    updatePresetOptions(options) {
        console.group('PRESET CHANGE');
        console.log('change', options);
        console.log('series', this.presetOptions);
        console.groupEnd();
    }

    clearOverriddenSeriesValues(values: string[]) {
        this.onSeriesClear.emit(values.map(x => x.toLowerCase()));
    }

    handleTabChange(tab: string) {
        this.onTabChange.emit(tab);
        this.onSeriesSelectionChange.emit(tab !== 'series' ? 'default' : this.selectedSeries);
    }
}
