import * as Bluebird from 'bluebird';
import * as moment from 'moment-timezone';
import { JwtHelperService } from '@auth0/angular-jwt';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AppService } from 'app/app.service';
import { LocalStore } from 'app/services/storage/storage.service';
import { CurrentUser } from 'app/shared/model';

export interface UserInfo {
    id: string;
    name: string;
    companyId: string;
    companyName: string;
    companyType: string;
}

export interface Tokens {
    idToken: string;
    accessToken: string;
    refreshToken: string;
    otpToken?: string;
}

export interface TokenDetails {
    header: {
        alg: string;
        typ: string;
    };
    payload: {
        sub: string;
        username: string;
        token_use: string;
        iat: number;
        exp: number;
        iss: string
    };
    signature: string;
}

@Injectable()
export class AuthService {

    refreshIntervalSeconds = 60;
    tokens: Tokens;
    private store: LocalStore;

    currentUser: CurrentUser;
    users: UserInfo[] = []; // keep track of our user accounts so we can switch to them later

    constructor(
        private app: AppService,
        private router: Router) {

        this.store = this.app.getStore('auth');
        this.app.api.onTokenExpired.subscribe((err) => {
            this.logout(err?.name || 'TokenExpiredError');
        });

        this.users = this.store.get('accounts') || [];

        setInterval(() => this.refreshTokens(), this.refreshIntervalSeconds * 1000);
    }

    isAuthenticated(redirectUrl?: string): Promise<boolean> {
        return Promise.resolve().then(() => {
            if (this.tokens) {
                return this.getTokenTimeRemaining(this.tokens.accessToken) > 0;
            }
            const tokens = this.store.get<Tokens>('tokens');
            if (tokens && this.getTokenTimeRemaining(tokens.accessToken) > 0) {
                const user = this.store.get<CurrentUser>('user');
                if (user) {
                    this.tokens = tokens;
                    this.app.api.setAccessToken(this.tokens.accessToken);
                    return this.app.setUser(user).then(() => {
                        return true;
                    });
                }
                return false;
            }
            return false;
        }).catch(err => {
            this.app.logError(err);
            return false;
        }).then(result => {
            if (!result) {
                this.router.navigate(['/login', { next: redirectUrl }]);
            }
            return result;
        });
    }

    signInAndGetMatchingUsers(username: string, password: string): Bluebird<UserInfo[]> {
        return Bluebird.resolve(this.app.api.auth.signIn({
            username,
            password,
        }).then(result => {
            this.app.api.setAccessToken(result.accessToken);
            this.users = result.users.map(x => ({
                id: x.id,
                name: x.name,
                companyId: x.owner.id,
                companyName: x.owner.name,
                companyType: x.owner.type,
            }));
            this.store.set('accounts', this.users);
            return this.users;
        }));
    }

    signInWithToken(token: string): Bluebird<UserInfo[]> {
        return Bluebird.resolve(this.app.api.auth.signIn({
            token: token,
        }).then(result => {
            this.app.api.setAccessToken(result.accessToken);
            return result.users.map(x => ({
                id: x.id,
                name: x.name,
                companyId: x.owner.id,
                companyName: x.owner.name,
                companyType: x.owner.type,
            }));
        }));
    }

    signInWithOpenIdConnect(domain: string, idToken: string): Bluebird<UserInfo[]> {
        return Bluebird.resolve(this.app.api.auth.signIn({
            domain: domain,
            token: idToken,
        }).then(result => {
            this.app.api.setAccessToken(result.accessToken);
            return result.users.map(x => ({
                id: x.id,
                name: x.name,
                companyId: x.owner.id,
                companyName: x.owner.name,
                companyType: x.owner.type,
            }));
        }));
    }

    selectAuthenticatedUser(id: string, resetToken?: string): Bluebird<CurrentUser> {

        const otpToken = this.getOtpTokenForUser(id);
        if (otpToken) {
            this.app.api.auth.setHeader('x-otp-token', otpToken);
        }

        if (resetToken) {
            this.app.api.auth.setHeader('x-reset-token', resetToken);
        }

        return Bluebird.resolve(this.app.api.auth.selectUser(id).then(result => {

            this.tokens = {
                idToken: result.idToken,
                accessToken: result.accessToken,
                refreshToken: result.refreshToken,
            };
            this.app.api.setAccessToken(result.accessToken);
            const user: CurrentUser = {
                id: result.user.id,
                owner: result.user.owner,
                name: result.user.name,
                emailAddress: result.user.username,
                defaultClientId: result.user.defaultClient.id,
                costCentre: result.user.costCentre,
                timeZoneId: result.user.timeZoneId,
                language: result.user.language,
                otp: result.user.otp,
                passwordExpiryDate: result.user.passwordExpiresOn,
            };
            // we can't set the user here, as this will kick of all kinds of loading and the user may 
            // still need OTP before being able to load anything.
            //this.app.setUser(user);

            this.currentUser = user;

            this.store.set('tokens', this.tokens);
            this.store.set('user', user);

            return user;
        }));
    }

    /** Once a user is finished authenticating, call this method to update any tokens and kick off loading the user in the app */
    loadCurrentUser(newTokens?: Tokens, saveOTPToken?: boolean) {
        if (!this.currentUser) throw new Error('No user has been selected');
        this.tokens = newTokens || this.tokens;
        this.store.set('tokens', this.tokens);
        if (saveOTPToken) {
            this.updateOtpToken(this.currentUser.id, this.tokens.otpToken);
        } else {
            if (saveOTPToken === false) {
                this.clearOtpToken(this.currentUser.id); // make sure there aren't any tokens lying around if a user doesn't want to store them
            }
        }
        this.app.api.setAccessToken(this.tokens.accessToken);
        this.app.setUser(this.currentUser);
    }

    async logout(reason?: string) {
        this.users = [];
        let prevRoute = this.router.routerState.snapshot.url || '/status';
        if (prevRoute.startsWith('/login')) { // prevent an infinite loop
            prevRoute = '/status';
        }
        // never fail a logout
        try {
            // this.clearOtpToken(this.app.user?.id); // if a user logs out on purpose, clear his OTP tokens by default
            await this.app.api.auth.signOut({}); // will invalidate the token used on the server
        } catch { }
        try {
            this.app.setUser(null);
            this.tokens = null;
            this.store.set('tokens', null);
        } catch { }
        this.router.navigate(['/login', reason ? { reason: reason, next: prevRoute } : { next: prevRoute }]);
    }

    getTokenDetails(token: string): TokenDetails {
        const helper = new JwtHelperService();
        const body = {
            header: null,
            payload: helper.decodeToken(token),
            signature: null,
        };
        if (!body || !token) {
            return null;
        }
        return body;
    }

    getTokenTimeRemaining(token: string): number {
        const details = this.getTokenDetails(token);
        const expiry = moment.unix(details.payload.exp);
        return expiry.diff(moment().utc(), 'seconds');
    }


    refreshTokens(): Promise<void> {
        if (this.tokens && this.tokens.accessToken) {
            if (this.getTokenTimeRemaining(this.tokens.accessToken) < this.refreshIntervalSeconds * 3) {
                return this.app.api.auth.refreshTokens({
                    refreshToken: this.tokens.refreshToken,
                }).then(response => {
                    this.tokens = response;
                    this.app.api.setAccessToken(response.accessToken, true);
                    this.store.set('tokens', this.tokens);
                }).catch(() => {
                    // no need to do anything with the error, if we can't refresh the tokens the user will
                    // eventually be logged out.

                    // The error is being handled correctly, as the api emits onTokenExpired which is handled above
                    // within the constructor. Muting this error report here as it's not currently implemented on the
                    // API.
                    // this.app.logError(err);
                });
            }
        }
        return Promise.resolve();
    }


    updateOtpToken(id: string, otpToken: string) {
        if (otpToken !== undefined) {
            const tokens = this.store.get('otp-tokens') || {};
            tokens[id] = otpToken;
            this.store.set('otp-tokens', tokens);
        }
    }

    getOtpTokenForUser(id: string): string {
        const tokens = this.store.get('otp-tokens') || {};
        return tokens[id];

    }

    clearOtpToken(userId: string) {
        this.updateOtpToken(userId, null);
    }

}
