import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../../base/base.component';
import { FilterPanelConfig, FilterPanelItem } from '../../filter-panel.model';
import { FilterPanelService } from '../../filter-panel.service';

@Component({
    selector: 'key-radio-filter',
    styleUrls: ['radio-filter.component.scss'],
    templateUrl: 'radio-filter.component.html',
})
export class KeyRadioFilterComponent extends BaseComponent implements OnInit {

    @Input() config: FilterPanelConfig;

    itemCache = {};

    selectedId: string;
    items: FilterPanelItem[] = [];
    truncatedList: { count: number, limit: number };

    constructor(
        private filters: FilterPanelService,
        public ref: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        // fires after data is loaded so we can update our items if necessary
        this.on(this.filters.total$, () => {
            this.getItems().then(items => {
                this.items = items;
                this.ref.markForCheck();
            });
        });
        this.on(this.filters.filters$, values => {
            this.selectedId = values.filters[this.config.id] && values.filters[this.config.id][0]?.id;
            this.ref.markForCheck();
        });
    }

    async getItems(filter: string = ''): Promise<FilterPanelItem[]> {
        if (this.config?.data) {
            this.truncatedList = null;
            return this.config.data.filter(x => x.name.toLowerCase().includes(filter.toLowerCase()));
        } else {
            const result = await this.config.getData(filter);
            this.truncatedList = result.count > result.limit && { limit: result.limit, count: result.count };
            return result.items;
        }
    }

    itemSelected(item: FilterPanelItem) {
        this.selectedId = item.id;
        this.filters.updateFilterValues(this.config.id, [item]);
        this.ref.markForCheck();
    }

}
