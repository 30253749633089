import { Injectable } from '@angular/core';
import { AnalyticsChartData } from './chart.component';
import { Average } from 'app/shared/components/graph/graph.model';
import { AnalyticsCellValue, GraphData, AnalyticsSettings } from '../analytics.model';
import { AnalyticsService } from '../services/analytics.service';


export interface AnalyticsChartGraphData extends GraphData {
    averages: Average[];
}

@Injectable()
export abstract class AnalyticsChartService<T extends AnalyticsChartData> {

    constructor(
        public analytics: AnalyticsService
    ) {
    }

    getGraphDataFromCellset(cellset: AnalyticsCellValue[][], settings: AnalyticsSettings): AnalyticsChartGraphData {
        if (!cellset) { return null; }

        const graph: AnalyticsChartGraphData = {
            ...this.analytics.convertCellsetToGraph(cellset, settings),
            averages: this.analytics.getGraphAverages(settings.table.measures, settings.graph.series, cellset, settings.graph.seriesColors),
        };

        if (graph.datasets) {
            return graph;
        }
    }

    abstract getData(cellset: AnalyticsCellValue[][], settings: AnalyticsSettings, colors?: string[]): T;
}
