import { Component, OnInit, Inject, ChangeDetectionStrategy, OnDestroy, HostBinding } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from 'environments/environment';
import { AppService } from 'app/app.service';
import { CurrentTheme } from 'app/shared/model';
import * as WebFont from 'webfontloader';
import { BaseComponent } from '../base/base.component';
import { EntityService } from 'app/services/entity/entity.service';
import * as cssVars from 'css-vars-ponyfill';
import { Dictionary } from '@key-telematics/fleet-api-client';
import { blobToDataURL } from 'app/shared/utils/dataUrl';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'key-theme',
    templateUrl: './theme.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class ThemeComponent extends BaseComponent implements OnInit, OnDestroy {

    isLoaded = false;
    hasNativeCssVariableSupport = true;
    loader: HTMLElement;

    @HostBinding('class') classes = '';

    constructor(
        @Inject(DOCUMENT) public document: HTMLDocument,
        public app: AppService,
        public http: HttpClient,
        public entities: EntityService
    ) {
        super();
        this.loader = <HTMLElement>this.document.querySelector('#loader');
    }

    get baseURI(): string {
        // IE doesn't have baseURI property
        if (!this.document.baseURI) {
            const baseEL = this.document.getElementsByTagName('base');
            return baseEL[0].href;
        }

        return this.document.baseURI;
    }

    ngOnInit() {

        this.hasNativeCssVariableSupport = typeof window !== 'undefined' && window['CSS'] && window['CSS'].supports && window['CSS'].supports('(--a: 0)');

        this.app.theme$.subscribe(theme => {
            this.loadTheme(theme);
        });

        this.on(this.entities.entityUpdated$, (entity) => {
            if (entity && entity._type === 'theme') {
                this.app.setTheme(entity);
            }
        });

    }

    ngOnDestroy() {
        /**
        * only applicable to dev environment
        * remove stylesheet for hot module replacement so that it can reload after recompiling
        */
        if (environment.hmr && !environment.production) {
            const themeStylesheet = <HTMLLinkElement>this.document.querySelector('#theme-stylesheet');
            themeStylesheet.href = '';
        }
    }

    async loadTheme(theme: CurrentTheme) {

        try {

            if (!this.isLoaded || !this.hasNativeCssVariableSupport) { this.showLoader(); }

            if (theme.variables) {

                // temporary icon pack switcher. we'll add more options eventually
                const [iconPack, iconWeight] = (theme.variables['--main-icon-pack'] || 'fontawesome:900').split(':');
                if (iconPack === 'fontawesome') {
                    theme.variables['--icon-weight'] = iconWeight;
                }

                await this.injectSettingsVariables(theme.variables);

                const classes = [];
                if (theme.variables['--main-gutter-width'] && theme.variables['--main-gutter-width'] !== '0' && theme.variables['--main-gutter-width'] !== '0px' && theme.variables['--main-gutter-width'] !== '0rem') {
                    classes.push('app-has-gutter');
                }

                this.classes = classes.join(' ');

            }

            let externalCss = '/**/';
            const externalCssUrl = theme.settings?.cssSrc || '';
            if (externalCssUrl) {
                externalCss = await this.downloadStyle(externalCssUrl).catch(err => {
                    console.error(err);
                    return '/**/';
                });
            }
            await this.injectStyle('external-css', externalCss);
            await this.injectStyle('custom-css', theme.customStyles || '/**/');


            (theme.customTags || []).forEach((customTag, index) => {
                this.appendHTML(`custom-tag-${index}`, customTag.location, customTag.tag, customTag.attributes, customTag.contents);
            });


        } catch (err) {
            console.error(err);
        }
        this.isLoaded = true;
        this.hideLoader();

    }

    changeThemeStylesheet(cssFile: string, stylesheet: HTMLLinkElement): Promise<void> {
        /**
        * I think I mentioned enough about me doing things to the DOM now.
        * This litterally only happens when you change the stylesheet, so should not affect performance.
        */

        return new Promise((resolve, reject) => {
            try {
                const newStyle = this.document.createElement('link');
                newStyle.setAttribute('type', 'text/css');
                newStyle.setAttribute('rel', 'stylesheet');
                newStyle.setAttribute('id', 'theme-stylesheet');

                stylesheet.parentNode.replaceChild(newStyle, stylesheet);

                if (!cssFile.includes('default')) {
                    newStyle.setAttribute('href', cssFile);
                    newStyle.onload = () => {
                        resolve();
                    };
                    newStyle.onerror = (err) => {
                        reject(err);
                    };
                } else {
                    resolve();
                }
            } catch (err) {
                reject(err);
            }
        });
    }

    hideLoader() {
        this.loader.classList.add('fade');
        setTimeout(() => this.loader.classList.add('hide'), 1000);
    }

    showLoader() {
        this.loader.classList.remove('fade');
        this.loader.classList.remove('hide');
    }


    async injectSettingsVariables(variables: Dictionary): Promise<void> {

        const fonts = {};
        Object.keys(variables).filter(x => x.includes('-font-family')).forEach(key => {
            const font = variables[key];
            fonts[font] = `${font}:300,400,400i,500,700`;
        });
        if (Object.values(fonts).length > 0) {
            await this.loadFonts(fonts);
        }

        return new Promise<void>((resolve, reject) => {
            try {
                cssVars.default({
                    variables: variables,
                    onComplete() {
                        resolve();
                    },
                    onError(message) {
                        reject(new Error(message));
                    },
                });
                if (this.hasNativeCssVariableSupport) {
                    resolve(); // the stupid component doesn't fire any events if it's native support, so we have to do so ourselves.
                }
            } catch (err) {
                reject(err);
            }

        });

    }


    loadFonts(fonts: any): Promise<void> {

        return new Promise<void>((resolve) => {
            WebFont.load({
                google: {
                    families: Object.values(fonts),
                },
                active: () => resolve(),
                inactive: () => resolve(), // was unable to load the fonts, we don't really care

            });
        });


    }


    async downloadStyle(url: string): Promise<string> {
        const res = await this.http.get(url, {
            responseType: 'blob',

        }).toPromise();
        return await res.text();
    }

    async injectStyle(id: string, css: string) {

        const blob = new Blob([css], {
            type: 'text/css;charset=utf-8;',
        });

        const dataUrl = await blobToDataURL(blob);

        const head = this.document.getElementsByTagName('head')[0];
        const themeLink = this.document.getElementById(id) as HTMLLinkElement;
        if (themeLink) {
            themeLink.href = dataUrl;
        } else {

            return new Promise<void>((resolve, reject) => {

                const style = this.document.createElement('link');
                style.id = id;
                style.rel = 'stylesheet';
                style.onerror = (_event, _source, _lineno, _colno, error) => {
                    console.log(css);
                    console.error(error);
                    reject();
                };
                style.onload = () => {
                    resolve();
                };

                style.href = `${dataUrl}`;
                head.appendChild(style);

            });
        }

    }

    appendHTML(id: string, owner: string, tag: string, attributes: string, contents: string) {

        const parent = this.document.getElementsByTagName(owner)[0];
        const script = this.document.createElement(tag);
        script.id = id;
        const strings = (attributes || '').split(/\r|\n/g).filter(x => x);
        strings.forEach(str => {
            const [key, value] = str.split('=');
            script[key] = value?.replace(/'|"/g, '') || true;
        });
        // tslint:disable-next-line: tsr-detect-html-injection
        script.innerHTML = contents || '';
        if (this.app?.user?.owner?.type !== 'client') { console.log('[INJECTING]', script); }
        parent.appendChild(script);
    }


}
