import { Injectable } from '@angular/core';
import { IdName, ZoneCreateRequest, ZoneListItem, ZoneResponse, ZoneUpdateRequest } from '@key-telematics/fleet-api-client';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'app/app.service';
import { AssetGroupingService, MeasurementUnitsService } from 'app/services';
import { EntityService } from 'app/services/entity/entity.service';
import { LayerClickEvent, MapComponent, MapZone, Point, PolygonEditEvent, ZoneCreationEvent } from 'app/shared/components';
import { MapService } from 'app/shared/components/map/map.service';
import * as Bluebird from 'bluebird';
import { CacheService } from 'ionic-cache';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { IdNameComboValueHandlers } from '../form-builder';
import { ModalService } from '../modal';
import { SpatialUtils } from './map.utils';

export interface MapViewport {
    left: number; top: number; right: number; bottom: number;
}

const ZONE_COLORS = {
    'location': 'blue',
    'keepin': 'green',
    'nogo': 'red',
    'route': 'green',
};

export interface ZoneProperties {
    name?: string;
    group?: IdName;
    costCentre?: IdName;
    zoneType?: 'location' | 'keepin' | 'nogo' | 'route';
    speed?: number;
    radius?: number;
}

@Injectable()
export class MapZonesService extends MapService {

    private zonesSubject: Subject<MapZone[]> = new ReplaySubject(1);
    private zoneClickedSubject: Subject<LayerClickEvent> = new Subject();
    private zoneDoubleClickedSubject: Subject<LayerClickEvent> = new Subject();
    private zoneCreatedSubject: Subject<ZoneCreationEvent> = new Subject();
    private polygonEditSubject: Subject<PolygonEditEvent> = new Subject();

    viewport: { left: number, top: number, right: number, bottom: number } = null;
    zones: MapZone[] = [];
    zones$: Observable<MapZone[]> = this.zonesSubject.asObservable();
    zoneClicked$: Observable<LayerClickEvent> = this.zoneClickedSubject.asObservable();
    zoneDoubleClicked$: Observable<LayerClickEvent> = this.zoneDoubleClickedSubject.asObservable();
    zoneCreated$: Observable<ZoneCreationEvent> = this.zoneCreatedSubject.asObservable();
    polygonEdit$: Observable<PolygonEditEvent> = this.polygonEditSubject.asObservable();

    private loadPointsStart: Date;

    private subscriptions: Subscription[] = [];

    constructor(
        protected app: AppService,
        protected i18n: TranslateService,
        protected modal: ModalService,
        private grouping: AssetGroupingService,
        private entities: EntityService,
        private units: MeasurementUnitsService,
        private cache: CacheService
    ) {
        super(app);
    }

    attachMap(map: MapComponent) {
        this.detachMap();
        this.map = map;
        this.subscriptions.push(
            this.map.onMapMoved
                .pipe(debounceTime(1000))
                .subscribe(() => {
                    if (this.map.getZoom() >= 9) {
                        this.loadViewport(this.map.getBounds());
                    } else {
                        this.reset();
                    }
                })
        );

        this.subscriptions.push(
            this.map.onLayerDblClick.subscribe(event => {
                this.zoneDoubleClickedSubject.next(event);
            })
        )

        this.subscriptions.push(
            this.map.onLayerClick.subscribe(event => {
                this.zoneClickedSubject.next(event);
            })
        )

        this.subscriptions.push(
            this.map.onZonesDeleted.subscribe(event => {
                this.deleteMultipleZones(event.zones)
                    .then(_ => {
                        // Once we delete a zone, we must update the zones in memory
                        const removedZoneIds = event.zones.map(zone => zone.id);
                        this.zones = this.zones.filter(zone => !removedZoneIds.includes(zone.id));
                        this.zonesSubject.next(this.zones);
                    })
            })
        );

        this.subscriptions.push(
            this.map.onZoneCreated.subscribe(event => {
                this.zoneCreatedSubject.next(event);
            })
        );

        this.subscriptions.push(
            this.map.onPolygonEdited.subscribe(event => {
                this.polygonEditSubject.next(event);
            })
        );

        this.subscriptions.push(
            this.entities.entityUpdated$.subscribe(x => {
                if (x && x._type === 'zone') {
                    this.cache.removeItem(`zone.${x.id}`);
                }
            })
        );

    }

    attachMapAndZone(map: MapComponent, zone: ZoneResponse) {
        this.detachMap();
        this.map = map;

        const item = this.zoneToMapZone(zone);
        this.map.addZone(item);

        this.zones = [item];

        this.subscriptions.push(
            this.map.onPolygonEdited.subscribe(async event => {
                this.polygonEditSubject.next(event);
            })
        );

        this.subscriptions.push(
            this.map.onZonesEdited.subscribe(async event => {
                try {
                    await Bluebird.map(event.zones, async zone => {
                        await this.app.api.entities.updateZone(zone.id, zone);
                        await this.cache.removeItem(`zone.${zone.id}`);
                        await Bluebird.delay(1000); // prevent rate limiting issues
                    }, { concurrency: 1 });
                } catch (err) {
                    this.modal.error('ERRORS.UNABLE_TO_UPDATE_ZONE', err);
                }
            })
        );

        this.zonesSubject.next([item]);

        this.subscriptions.push(
            this.entities.entityUpdated$.subscribe(x => {
                if (x && x._type === 'zone') {
                    this.map.addZone({
                        id: x.id,
                        name: x.name,
                        color: ZONE_COLORS[x.zoneType],
                        type: x.zoneType,
                        center: x.center,
                        radius: x.radius,
                        points: x.points,
                        modifiedDate: x.entity.modifiedDate,
                        interactive: true
                    });
                    this.app.api.entities.updateZone(x.id,  { radius: x.radius });
                }
            })
        );
    }


    detachMap() {
        this.reset();

        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
        this.subscriptions = [];
        this.map = null;
    }

    reset() {
        this.viewport = null;
        if (this.map) {
            this.zones.forEach(zone => this.map.removeZone(zone.id));
        }
        this.zones = [];
        if (this.zonesSubject) {
            this.zonesSubject.next(this.zones);
        }
    }


    async deleteMultipleZones(zones: { id: string }[]): Promise<void> {
        try {
            await Bluebird.map(zones, async zone => {
                await this.app.api.entities.updateZone(zone.id, { state: 'deleted' });
                await this.cache.removeItem(`zone.${zone.id}`);
                await Bluebird.delay(1000); // prevent rate limiting issues
            }, { concurrency: 1 });
        } catch (err) {
            this.modal.error('ERRORS.UNABLE_TO_UPDATE_ZONE', err);
        }
    }

    async updateZone(id: string, update: ZoneUpdateRequest): Promise<ZoneResponse> {
        if (update) {
            update.speed = update.speed && this.units.toBackend('speed', update.speed, 0) as number;
            update.radius = update.radius && this.units.toBackend('distance', update.radius) as number;
            const result = await this.app.api.entities.updateZone(id, update);
            this.cache.removeItem(`zone.${result.id}`);
            const idx = this.zones.findIndex(x => x.id === result.id);
            if (idx > -1) {
                this.zones[idx] = this.zoneToMapZone(result);
                this.map.updateZone(this.zones[idx]);
                this.zonesSubject.next(this.zones);
            }
            return result;
        }
        return null;
    }

    async createZone(request: ZoneCreateRequest): Promise<ZoneResponse> {
        if (request) {
            request.speed = request.speed && this.units.toBackend('speed', request.speed, 0) as number;
            request.radius = request.radius && this.units.toBackend('distance', request.radius) as number;
            const result = await this.app.api.entities.createZone(request);
            const mapZone = this.zoneToMapZone(result);
            this.zones.push(mapZone);
            this.map.addZone(mapZone);
            return result;
        }
        return null;
    }


    loadViewport(viewport: MapViewport): Promise<MapZone[]> {

        const viewportToString = (v: MapViewport) => v ? `${v.left},${v.top},${v.right},${v.bottom}` : '0,0,0,0';
        if (!this.clientId || viewportToString(this.viewport) === viewportToString(viewport)) {
            return Promise.resolve(this.zones);
        }

        this.viewport = viewport;
        return this.app.api.entities.listZones(this.clientId, 0, 1000, 'name:asc', null, viewportToString(viewport))
            .then(result => {
                this.zones = this.mergeZones(this.zones, result.items);
                this.zonesSubject.next(this.zones);
                setTimeout(() => this.loadPoints());
                return this.zones;
            })
            .catch(() => {
                // swallow errors
                return this.zones;
            });
    }

    loadPoints(): Bluebird<void> {
        const start = new Date();
        this.loadPointsStart = start;
        return Bluebird.map(this.zones.filter(x => !x.points), zone => {
            if (start !== this.loadPointsStart) {
                return; // if this method was called again, abort any pending tasks
            }
            const key = `zone.${zone.id}`;
            this.cache.getOrSetItem(key, () => this.app.api.entities.getZone(zone.id), 'zones', 60 * 60).then(z => {
                zone.points = z.points;
                if (start === this.loadPointsStart) {
                    if (this.map) {
                        this.map.addZone(zone);
                    }
                }
            });
        }, { concurrency: 1 }).then(() => {
            return;
        }).catch(err => {
            // if an error occurs, we need to try again in the background after a little while. it doesn't matter that we just
            // try all of them again as only zones without points will be loaded
            setTimeout(() => this.loadPoints(), 5000);
            console.error(err); // TODO: log this with an error service
        });
    }

    mergeZones(a: MapZone[], b: ZoneListItem[]): MapZone[] {
        // remove items in our existing array that aren't in the new one, and remove items from the new one
        // that are already in our existing one
        for (let i = a.length - 1; i >= 0; i--) {
            const zone = a[i];
            const idx = b.findIndex(x => x.id === zone.id);
            if (idx === -1) {
                if (this.map) {
                    this.map.removeZone(zone.id);
                }
                a.splice(i, 1);
            } else {
                b.splice(idx, 1);
            }
        }
        // we can now concat the two arrays
        return a.concat(b.map(x => {
            return this.zoneToMapZone({
                ...x,
                entity: { creationDate: x.modifiedDate, modifiedDate: x.modifiedDate },
            });
        }));
    }

    private zoneToMapZone(x: Pick<ZoneResponse, 'id' | 'name' | 'zoneType' | 'center' | 'radius' | 'points' | 'entity'>): MapZone {
        return {
            id: x.id,
            name: x.name,
            color: ZONE_COLORS[x.zoneType],
            type: x.zoneType,
            center: x.center,
            radius: x.radius,
            points: x.points,
            modifiedDate: x.entity.modifiedDate,
            interactive: true
        };
    }

    async newZoneModal(zoneType: string, points: Point[]): Promise<ZoneResponse> {
        try {
            const data: ZoneCreateRequest = {
                ownerId: this.app.client.id,
                name: '',
                zoneType: zoneType as any,
                costCentre: null,
                group: null,
                speed: null,
                radius: null,
                points: points,
            };
            const update = await this.showZonePropertiesModal(data) as ZoneCreateRequest;
            if (update) {
                update.speed = update.speed && this.units.toBackend('speed', update.speed, 0) as number;
                update.radius = update.radius && this.units.toBackend('distance', update.radius) as number;
                const result = await this.app.api.entities.createZone(update);
                const mapZone = this.zoneToMapZone(result);
                this.zones.push(mapZone);
                this.map.addZone(mapZone);
                return result;
            }
            return null;
        } catch (err) {
            this.modal.error('ERRORS.UNABLE_TO_CREATE_ZONE', err);
        }
    }


    private async showZonePropertiesModal(properties: ZoneProperties): Promise<ZoneProperties> {
        const [zoneGroups, costCentres] = await Promise.all([
            this.grouping.getZoneGroupsAsTree(),
            this.grouping.getCostCentresAsTree(),
        ]);
        const zoneTypes = ['location', 'keepin', 'nogo'].map(key => ({ key, value: this.i18n.instant(`SHARED.ZONE_TYPES.${key.toUpperCase()}`) }));
        const translate = key => this.i18n.instant(`ADMIN.EDITORS.ZONE.DETAILS.FIELDS.${key}`);
        const form = {
            groups: [{
                name: properties.zoneType === 'route' ? this.i18n.instant('ADMIN.EDITORS.ROUTE.DETAILS.TITLE') : this.i18n.instant('ADMIN.EDITORS.ZONE.DETAILS.TITLE'),
                fields: [
                    { id: 'name', title: translate('NAME'), type: 'text', required: true, min: 1, max: 255, },
                    properties.zoneType === 'route' ? null : { id: 'zoneType', title: translate('TYPE'), type: 'combo', required: true, values: zoneTypes },
                    { id: 'costCentre', title: translate('COST_CENTRE'), type: 'combo', required: true, values: AssetGroupingService.treeToComboValues(costCentres), ...IdNameComboValueHandlers },
                    { id: 'group', title: translate('GROUP'), type: 'combo', required: true, values: AssetGroupingService.treeToComboValues(zoneGroups), ...IdNameComboValueHandlers },
                    properties.zoneType === 'route' ? null : { id: 'speed', title: translate('SPEED'), type: 'number', required: false, min: 0, max: 1000, unit: this.units.format(0, 'speed').unit, },
                    properties.zoneType === 'route' ? null : { id: 'radius', title: translate('RADIUS'), type: 'number', required: false, min: 0, max: 1000, unit: this.units.format(0, 'distance').unit, },
                ].filter(x => x),
            }],
        };
        return this.modal.form(form, properties);

    }


    findZones(lat: number, lon: number): MapZone[] {
        const result = this.zones.filter(x => SpatialUtils.pointInPolygon({ x: lon, y: lat }, x.points || []));
        return result;
    }


}
