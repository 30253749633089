import { ChangeDetectorRef, Component, Input } from '@angular/core';


@Component({
    selector: 'key-filter-section',
    templateUrl: 'filter-section.component.html',
})
export class KeyFilterSectionComponent {

    @Input() title: string;
    @Input() expanded = true;
    @Input() actions = [];

    constructor(
        public ref: ChangeDetectorRef
    ) {
    }

    onCardClick() {
        this.expanded = !this.expanded;
        this.ref.markForCheck();
    }

}
