import { Router, NavigationStart } from '@angular/router';
import { Component, Input, Inject, Output, ElementRef, Renderer2, ChangeDetectionStrategy, OnChanges, OnInit, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { filter } from 'rxjs/operators';
import { AppService } from 'app/app.service';
import { SubscriptionLike } from 'rxjs';
import { get } from 'lodash';
import { MatchMediaService, LocalStore } from 'app/services';

export const MenuComponentLocalStore = new LocalStore('menu-component');

interface MenuComponentState {
    isToggledCollapse?: boolean;
}

@Component({
    selector: 'kui-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    providers: [{ provide: LocalStore, useValue: MenuComponentLocalStore }],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KuiMenuComponent implements OnChanges, OnInit {
    body: HTMLElement = this.document.body;

    theme: SubscriptionLike;
    routerEvents: SubscriptionLike;
    state: MenuComponentState;
    @Input() canToggleCollapse = true;

    @Input() orientation = 'vertical';

    // only relevant on mobile
    @Input() isToggledMobile = false;
    @Output() toggledMobile: EventEmitter<any> = new EventEmitter();

    // only relevant on tablet and desktop
    @Input() isToggledCollapse = false;
    @Output() toggledCollapse: EventEmitter<any> = new EventEmitter();

    constructor(
        public el: ElementRef,
        private renderer: Renderer2,
        private router: Router,
        @Inject(DOCUMENT) private document: HTMLDocument,
        private app: AppService,
        private ref: ChangeDetectorRef,
        private media: MatchMediaService,
        private store: LocalStore
    ) {
        this.renderer.addClass(this.el.nativeElement, 'app-menu');

        this.state = this.store.watchState<MenuComponentState>('state', {
            isToggledCollapse: null,
        });
        this.toggleCollapse(this.state.isToggledCollapse);
    }

    ngOnChanges(state) {
        if (state.isToggledMobile) {
            this.toggleMobile(state.isToggledMobile.currentValue);
        }

        // only toggle if the user hasn't toggled it manually before i.e. state.isToggle will be null
        if (state.isToggledCollapse && this.state.isToggledCollapse === null) {
            this.toggleCollapse(state.isToggledCollapse.currentValue);
        }
    }

    ngOnInit() {
        this.theme = this.app.theme$.subscribe(theme => {
            ['background', 'left-border', 'right-border'].forEach(x => {
                this.renderer.removeClass(this.el.nativeElement, x);
            });
            this.renderer.addClass(this.el.nativeElement, get(theme.settings, 'navigation.active.style', 'left-border'));

            if (get(theme.settings, 'navigation.size', 'auto') === 'auto') {
                // load menu as collapsed on tablets and smaller desktop screens
                const { alias } = this.media.current;
                setTimeout(() => { // setTimeout prevents ExpressionChangedAfterItHasBeenCheckedError
                    // only toggle if the user hasn't toggled it manually before i.e. state.isToggle will be null
                    this.toggleCollapse(this.state.isToggledCollapse === null ? (alias === 'md' || alias === 'lg') : this.state.isToggledCollapse);
                });
                this.canToggleCollapse = true;
            }
            if (get(theme.settings, 'navigation.size', 'auto') === 'compact') {
                this.body.classList.add('menu-collapsed');
                this.canToggleCollapse = false;
            }
            if (get(theme.settings, 'navigation.size', 'auto') === 'expanded') {
                this.body.classList.remove('menu-collapsed');
                this.canToggleCollapse = false;
            }

            this.ref.markForCheck();
        });

        this.routerEvents = this.router.events.pipe(
            filter(event => event instanceof NavigationStart)
        ).subscribe(() => this.toggleMobile(false));
    }

    OnDestroy() {
        [this.theme, this.routerEvents].forEach(x => x.unsubscribe());
    }

    toggleMobile(state: boolean) {
        this.isToggledMobile = state;

        if (this.isToggledMobile) {
            this.body.classList.add('menu-mobile-toggled');
        } else {
            this.body.classList.remove('menu-mobile-toggled');
        }

        this.toggledMobile.emit(this.isToggledMobile);
    }

    toggleCollapse(state: boolean) {
        this.state.isToggledCollapse = state;
        this.isToggledCollapse = state;

        if (this.isToggledCollapse) {
            this.body.classList.add('menu-collapsed');
        } else {
            this.body.classList.remove('menu-collapsed');
        }

        this.toggledCollapse.emit(this.isToggledCollapse);

        // manually trigger window resize in case some views needs to respond to resize changes
        if (typeof Event === 'function') {
            // modern browsers
            window.dispatchEvent(new Event('resize'));
        } else {
            // for IE and other old browsers
            // causes deprecation warning on modern browsers
            const evt = window.document.createEvent('UIEvents') as any;
            evt.initUIEvent('resize', true, false, window, 0);
            window.dispatchEvent(evt);
        }
    }

}
