<kui-card>
    <kui-card-body>
        <h5>{{ 'LEAFLET.COORDINATES.TITLE' | translate }}</h5>
        <p *ngIf="coordinatesError; else noError">{{coordinatesError}}</p>
        <ng-template #noError>
            <div *ngIf="loading$ | async"
                class="p-3">
                <kui-loader size="small"></kui-loader>
            </div>
            <p *ngIf="!(loading$ | async) && !coordinatesDetails">{{ 'LEAFLET.COORDINATES.DESC' | translate }}</p>
            <div class="list mb-3"
                *ngIf="!(loading$ | async) && coordinatesDetails">
                <p *ngIf="!coordinatesDetails.length">{{ 'LEAFLET.COORDINATES.NO_ITEMS' | translate }}</p>
                <ng-container *ngIf="!!coordinatesDetails.length">
                    <div class="flex-1">
                        <table>
                            <tr *ngFor="let coord of coordinatesDetails">
                                <td>{{ coord.title }}</td>
                                <td>{{ coord.text }}</td>
                            </tr>
                        </table>
                    </div>
                    <kui-icon *ngIf="data.routing"
                        kui-action
                        class="px-4"
                        size="xxl"
                        color="primary"
                        name="directions"
                        [title]="'LEAFLET.ROUTING.GET_ROUTE' | translate"
                        (click)="openRouting(coordinates)"></kui-icon>
                </ng-container>
            </div>
        </ng-template>
        <button kui-button
            *ngIf="data.googlemaps && !!coordinatesDetails?.length"
            class="mr-1 mt-1"
            (click)="openInGoogleMaps(coordinates)">
            <svg class="mr-2 map-toolbar-cards__button-svg"
                viewBox="0 0 19 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.67118 11.7785L16.5175 19.6249H0.824812L8.67118 11.7785ZM17.3423 8.93225V18.8L9.49607 10.9537L11.0637 9.38603C11.0339 9.34964 11.0039 9.31316 10.9739 9.27645C10.7614 9.01756 10.5424 8.75035 10.3315 8.46852L0 18.8V2.67135C0 2.45663 0.174124 2.2825 0.388842 2.2825H9.83374C10.722 0.910513 12.2651 0 14.0177 0C16.7649 0 19 2.23507 19 4.98224C19.0001 6.7171 18.1697 7.90594 17.3423 8.93225ZM7.64923 6.25577H4.76728V7.35363H6.46497C6.23291 8.06964 5.55959 8.58883 4.76728 8.58883C3.78351 8.58883 2.9832 7.78851 2.9832 6.80474C2.9832 5.82097 3.78359 5.02065 4.76728 5.02065C5.16468 5.02065 5.54054 5.14835 5.85433 5.3899L6.52407 4.51998C6.01663 4.12927 5.40911 3.9228 4.76728 3.9228C3.17816 3.9228 1.88526 5.2157 1.88526 6.80482C1.88526 8.39394 3.17809 9.68684 4.76728 9.68684C6.3564 9.68684 7.6493 8.39394 7.6493 6.80482V6.25577H7.64923ZM17.8335 4.98224C17.8335 2.87487 16.1252 1.16653 14.0178 1.16653C11.9104 1.16653 10.2021 2.87494 10.2021 4.98224C10.2021 8.15006 13.7842 9.05777 13.7842 12.8318H14.0178H14.2515C14.2513 9.05785 17.8335 8.15014 17.8335 4.98224ZM14.0177 3.23711C13.1965 3.23711 12.5308 3.90281 12.5308 4.72397C12.5308 5.5452 13.1966 6.2109 14.0177 6.2109C14.839 6.2109 15.5047 5.54512 15.5047 4.72397C15.5047 3.90281 14.839 3.23711 14.0177 3.23711Z" />
            </svg>
            <span>{{ 'LEAFLET.GOOGLE_MAPS' | translate }}</span>
        </button>
        <button kui-button
            *ngIf="coordinates && data.findclosest"
            color="secondary"
            class="mr-1 mt-1"
            (click)="searchClosestAsset(coordinates)">{{ 'LEAFLET.COORDINATES.FIND_CLOSEST' | translate }}</button>
        <button kui-button
            *ngIf="coordinates && data.mapsearch"
            color="secondary"
            class="mr-1 mt-1"
            (click)="mapSearch(coordinates)">
            <kui-icon [name]="'search-location'"></kui-icon>
        </button>
    </kui-card-body>
</kui-card>