<div class="iconography">

    <div [innerHTML]="telemetry() | directionalIcon: asset()?.color"></div>
    
    @if(roadSpeed()) {
        <div class="rounded-circle road-sign-prohibitory">
            {{ roadSpeed() }}
        </div>
    }

</div>

<div class="telemetry">

    <strong>{{ 'SHARED.DATETIME.TIME' | translate }}</strong>
    <p>
        <span class="date">
            {{ telemetry().date | units: 'date' }}
        </span>
        
        <span>{{ telemetry().date | units: 'time' }}</span>
    </p>


    <strong>{{ 'HISTORY.MAP.INFO.IGNITION' | translate }}</strong>
    <p>
        {{ (telemetry().active ? 'HISTORY.MAP.INFO.ACTIVE': 'HISTORY.MAP.INFO.INACTIVE') | translate }}
    </p>


    <strong>{{ 'HISTORY.MAP.INFO.SPEED' | translate }}</strong>
    <p>
        {{ telemetry()?.location?.speed | units:
        'speed' }}
    </p>

    <strong>{{ 'HISTORY.TELEMETRY_COLUMNS.LOCATION' | translate }}</strong>
    <p>{{ telemetry()?.location?.address }}</p>

    @if(telemetry()?.events?.length > 0) {
        <strong>{{ 'ALERTS.ALERTS' | translate }}</strong>
        <div>
            @for(event of telemetry().events; track event) {
                @if(event.alerts && event.alerts[0]) {
                    <div class="alert-entry">
                        @switch (event.alerts[0].type) {
                            @case ('low') {
                                <kui-icon 
                                    [fill]="true"
                                    name="info-circle"
                                    size="sm"
                                    class="text-success">
                                </kui-icon>
                            }
                            @case ('medium') {
                                <kui-icon 
                                    [fill]="true"
                                    name="exclamation-circle"
                                    size="sm"
                                    class="text-warning">
                                </kui-icon>
                            }
                            @case ('high') {
                                <kui-icon 
                                    [fill]="true"
                                    name="exclamation-triangle"
                                    size="sm"
                                    class="text-danger">
                                </kui-icon>
                            }
                        }
                    <span>{{ event.alerts[0].name }}</span>
                </div>
            } @else {
                <div class="alert-entry">
                    <kui-icon name="info-circle" class="text-success" size="sm"></kui-icon>
                    <p>{{event | eventDisplay}}</p>
                </div>
            }
        }
        </div>
    }

</div>