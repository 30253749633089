import { Component, Input, ChangeDetectorRef, OnChanges, SimpleChanges, ViewChild, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';
import { SearchResult } from 'app/shared/components/search-results-list/search-results-list.component';
import { debounce } from 'lodash';
import { BaseComponent } from 'app/shared/components/base/base.component';
import { RouteMarker } from './map-toolbar-routing.component';
import { MapToolsService } from 'app/shared/components/map/map-tools.service';
import { MapCoordinates } from 'app/shared/components/map/map.component';

export interface RoutingInputText {
    searchTerm: string;
    address: string;
}

@Component({
    selector: 'key-map-toolbar-routing-input',
    templateUrl: './map-toolbar-routing-input.component.html',
    styleUrls: ['./map-toolbar-routing-input.component.scss'],
})
export class MapToolbarRoutingInputComponent extends BaseComponent implements OnChanges {
    searching: boolean;
    showResults: boolean;
    results: SearchResult<MapCoordinates>[];

    // value alternates between text.address and text.searchTerm as the input focus and blur
    value: string;

    debouncedUpdateSearchTerm = debounce(this.updateSearchTerm, 500, { trailing: true, leading: false });

    @Input() placeholder: string;
    @Input() text: RoutingInputText;
    // replace the marker with a consistent id which is specific to this input. This way we have control over the marker on the map and don't get extra markers everywhere
    @Input() id: string;

    @Output() onSelected = new EventEmitter<RouteMarker>();

    @ViewChild('input') input: ElementRef;

    @HostListener('keydown.shift.tab', ['$event'])
    @HostListener('keydown.tab', ['$event']) closeOnTabKey(e) {
        if (document.activeElement === e.target) {
            this.blur(false);
        }
    }

    // @HostListener('document:touchend', ['$event']) // TODO: Hello Future Dude!! if we ever need to support mobile, this needs to be uncommented
    @HostListener('document:mouseup', ['$event']) closeOnOutsideClick(e) {
        if (!this.el.nativeElement.contains(e.target)) {
            this.blur(false);
        }
    }

    constructor(
        private ref: ChangeDetectorRef,
        private mapTools: MapToolsService,
        private el: ElementRef
    ) {
        super();
        this.on(this.mapTools.loading$, loading => {
            this.searching = loading === 'search';
            this.ref.markForCheck();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.text) {
            this.value = this.text.address;
        }
    }

    updateSearchTerm(value: string) {
        if (!value) {
            this.clearSearch();
            return;
        }

        this.text.searchTerm = value;
        this.showResults = true;
        this.doSearch(value);
        this.ref.markForCheck();
    }

    clearSearch() {
        this.text = { searchTerm: null, address: null };
        this.value = null;
        this.showResults = false;
        this.ref.markForCheck();
    }

    async doSearch(term: string) {
        const action = marker => {
            this.showResults = false;
            this.value = this.text.address = marker.name;
            this.blur(false);
            this.onSelected.emit({
                input: this.text,
                marker: { ...marker, id: this.id },
            });
            this.ref.markForCheck();
        };

        this.results = null;
        this.text.searchTerm = term;

        this.results = await this.mapTools.doSearch(term, action);
        this.ref.markForCheck();
    }

    focus() {
        this.value = this.text.searchTerm;
        this.updateSearchTerm(this.value);
        this.ref.markForCheck();
    }

    blur(keepResultsOpen: boolean) {
        this.value = this.text.address;
        if (this.input) {
            this.input.nativeElement.blur();
        }
        if (!keepResultsOpen) {
            this.showResults = false;
        }
        this.ref.markForCheck();
    }
}
