import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { MapComponent } from '../../../../map/map.component';
import { MapTripService } from '../../../../map/map-trip.service';
import { MapZonesService } from '../../../../map/map-zones.service';
import { AppService } from 'app/app.service';
import { TelemetryResponse, EventResponse } from '@key-telematics/fleet-api-client';
import { AssetListItem } from 'app/services';
import { MAP_COLORS, MAP_COLORS_ENUM } from 'app/shared/components/map/map.markers';

@Component({
    selector: 'key-trip-preview-map',
    templateUrl: './map.component.html',
    providers: [MapZonesService, MapTripService],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class TripPreviewMapComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {
    @ViewChild('map', { static: true }) map: MapComponent;

    public isPlaying: boolean;

    @Input() asset: AssetListItem;
    @Input() telemetry: TelemetryResponse[];
    @Input() events: EventResponse[];
    @Input() isMobile: boolean;

    @Output() mobileAction: EventEmitter<any> = new EventEmitter();
    @Output() onViewSelection = new EventEmitter<string>();

    constructor(
        public app: AppService,
        public tripService: MapTripService,
        public zonesService: MapZonesService,
        public router: Router
    ) {

    }

    ngOnInit() {
        this.map.disableMarkerClustering();
    }

    ngAfterViewInit() {
        this.tripService.attachMap(this.map);
        this.zonesService.attachMap(this.map);
    }

    ngOnDestroy() {
        this.tripService.detachMap();
        if (this) { // there is either a bug with the destroy or HMR does weird things, but this is undefined in hot module reloads
            this.zonesService.detachMap();
        }
    }

    resize() {
        this.map.invalidate();
    }

    ngOnChanges() {
        this.tripService.reset();
        if (this.telemetry && this.events) {
            setTimeout(() => {
                this.tripService.setAsset({
                    id: this.asset.id,
                    color: ((typeof this.asset.color === 'number')
                        ? MAP_COLORS[this.asset.color || 0]
                        : MAP_COLORS[MAP_COLORS_ENUM[this.asset.color] || 0]),
                });
                this.tripService.setTripsTelemetry([{ id: this.asset.id, telemetry: this.telemetry, events: this.events.map(x => ({ id: x.id, owner: x.owner, date: x.eventDate, type: x.eventType, class: x.eventClass, details: x.details, alerts: x.alerts })) }]);
                this.map.invalidate(); // make sure the map redraws itself once the DOM has settled
            });
        }

    }


}
