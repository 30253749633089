import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilderField, BaseFormBuilderFieldComponent } from 'app/shared/components/form-builder';
import { NgModel } from '@angular/forms';
import { get, set } from 'lodash';

@Component({
    selector: 'key-form-builder-number-field',
    templateUrl: './number.component.html',
})
export class KeyFormBuilderNumberFieldComponent implements BaseFormBuilderFieldComponent, OnInit {

    @Input() field: FormBuilderField;
    @Input() values: { [key: string]: any };
    @Input() error: string; // set this error value externally to have the default error highligh and display kick in

    @Output() onChange: EventEmitter<{ value: number, dirty: boolean }> = new EventEmitter();
    @ViewChild('numberField', { static: true }) numberField: NgModel;

    step: number;

    get value(): any {
        return this.field.getValue ? this.field.getValue(this.field, this.values) : get(this.values, this.field.id, undefined);
    }
    set value(val: any) {
        if (this.field.setValue) {
            const promise = this.field.setValue(this.field, this.values, val);
            if (promise && promise.then) {
                promise.then(() => this.ref.markForCheck());
            }
        } else {
            set(this.values, this.field.id, val);
        }
    }

    constructor(private ref: ChangeDetectorRef) { }

    validate(): boolean {
        this.numberField.control.markAsTouched();
        return !this.numberField.invalid;
    }

    ngOnInit() {
        if (this.field.value) {
            this.value = this.value === 0 ? 0 : (this.value || this.field.value); // a number is a number no matter how small... even zero!!
        }
        this.step = this.field?.options?.step || 1;
    }

    changeNumber(value: number) {
        this.value = value;
        this.onChange.emit({ value, dirty: this.numberField.dirty });
    }
}
