<kui-card-header [active]="expanded"
    (click)="onCardClick()">
    <kui-card-title>
        {{ title }}
    </kui-card-title>
    <ul class="content-card-actions">
        <!-- ACTIONS -->
        <ng-container *ngIf="expanded">
            <li *ngFor="let action of actions"
                class="mr-1">
                <ng-container *ngIf="action.menu || action.template; else icon;">
                    <kui-dropdown [style]="'none'"
                        [menu]="action.menu"
                        [customContent]="action.template"
                        [customContentContext]="action.templateContext">
                        <kui-icon kui-action
                            size="sm"
                            [name]="action.icon"
                            [class.active]="action.active"
                            [title]="action.name"></kui-icon>

                    </kui-dropdown>
                </ng-container>
                <ng-template #icon>
                    <kui-icon kui-action
                        [class.active]="action.active"
                        [name]="action.icon"
                        [title]="action.name"
                        (click)="action.click()">
                    </kui-icon>
                </ng-template>
            </li>
        </ng-container>
        <li>
            <kui-icon name="chevron-up"></kui-icon>
        </li>
    </ul>
</kui-card-header>
<kui-card-body>
    <kui-card-text class="d-flex flex-column w-100 filter-content">

            <ng-content></ng-content>


    </kui-card-text>
</kui-card-body>