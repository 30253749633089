import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { IKuiModalAction } from 'app/key-ui';

export interface NotificationDetails {
    type: 'error' | 'warn' | 'info';
    block: boolean;
    title: string;
    message: string;
    action?: IKuiModalAction;
}

@Injectable()
export class NotificationService {

    onNotify = new Subject<NotificationDetails>();

    constructor(private i18n: TranslateService) {
    }

    info(titleId: string, messageId: string, parameters?: { [key: string]: string }) {
        this.onNotify.next({
            type: 'info',
            block: false,
            title: this.i18n.instant(titleId || 'ERROR.INFO', parameters),
            message: this.i18n.instant(messageId, parameters),
        });
    }

    warn(titleId: string, messageId: string, parameters?: { [key: string]: string }) {
        this.onNotify.next({
            type: 'warn',
            block: false,
            title: this.i18n.instant(titleId || 'ERROR.WARNING', parameters),
            message: this.i18n.instant(messageId, parameters),
        });
    }

    error(error: Error | string, block?: boolean, messageLookup?: { [key: string]: string }, parameters?: { [key: string]: string }, action?: IKuiModalAction) {
        const message = this.translateError(error, messageLookup, parameters);
        this.onNotify.next({
            type: 'error',
            block: block,
            title: error instanceof Error ? error.name : this.i18n.instant('ERROR.ERROR'),
            message: message,
            action,
        });
    }


    /** Translates globally known errors and adds utility for translating known errors on the fly. */
    translateError(error: Error | HttpErrorResponse | string, messageLookup?: { [key: string]: string }, parameters?: { [key: string]: string }): string {
        // not sure if this is the best place for this function, but since nearly everything will have to have the NotificationService
        // injected, it made sense at the time.
        messageLookup = {
            // add known errors to this lookup
            '0': 'ERROR.OFFLINE',
            ...messageLookup,
        };
        let message = 'ERROR.UNEXPECTED_ERROR';
        try {
            if (error) {
                if (error instanceof Error || error instanceof HttpErrorResponse) {
                    message = messageLookup[error.name] || messageLookup[error['status']] || messageLookup[error['statusText']] || messageLookup['*'] || error.message;
                } else {
                    if (typeof error === 'string') {
                        message = error || message;
                    } else {
                        message = JSON.stringify(error);
                    }
                }
            }
            return this.i18n.instant(message, parameters);
        } catch (err) {
            console.error(error);
            console.warn('Error translating error message');
            console.error(err);
            return this.i18n.instant('ERROR.UNEXPECTED_ERROR');
        }
    }
}
