import { Component, Output, EventEmitter, Input, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilderDefinition, FormBuilderKeyValue, FormBuilderField } from 'app/shared/components/form-builder';
import { AnalyticsOutputFilter } from '@key-telematics/fleet-api-client';
import { AnalyticsCellSetFilter } from '../../analytics.model';
import { TranslateService } from '@ngx-translate/core';
import { FilterSettingsItem } from './filters.component';
import { set } from 'lodash';

@Component({
    selector: 'key-analytics-settings-filter-section',
    templateUrl: './filter-section.component.html',
})
export class AnalyticsSettingsFilterSectionComponent implements OnChanges {
    form: FormBuilderDefinition;
    flattenedFilters: string[];
    _values: AnalyticsOutputFilter;

    @Input() filters: { [key: string]: AnalyticsCellSetFilter };
    @Input() options: FilterSettingsItem;
    @Input() values: AnalyticsOutputFilter;
    @Output() onChange = new EventEmitter<AnalyticsOutputFilter>();

    constructor(private i18n: TranslateService) {
    }

    translateSettings(key): string {
        return this.i18n.instant(`ANALYTICS.SETTINGS.${key}`);
    }

    translateDimensions(key): string {
        return this.i18n.instant(`ANALYTICS.DIMENSIONS.${key}`);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.filters && this.filters) {
            this.flattenedFilters = this.getFlattenedFilters(this.filters);
            this.form = this.getForm();

            const performantMode = this.getMostPerformantMode(this.values.items, this.flattenedFilters); // give getExpandedItems the opisite of this
            this._values = {
                ...this.values,
                items: this.getExpandedItems(this.values.items, this.flattenedFilters, this.values.mode !== 'range' ? this.values.mode : performantMode === 'include' ? 'exclude' : 'include'),
            };
        }
    }

    changeFormSetting(event) {
        const items = this.getRolledUpItems(event.values.items);
        const mode = this._values.mode !== 'range' ? this.getMostPerformantMode(event.values.items, this.flattenedFilters) : this._values.mode;
        const itemsToExclude = this.flipItems(event.values.items, this.flattenedFilters);

        this.onChange.emit({
            ...event.values,
            mode,
            items: mode === 'include' ? items : itemsToExclude,
        });
    }

    getForm(): FormBuilderDefinition {
        const { name, hasRange } = this.options;
        const checklist = {
            type: 'checklist',
            id: 'items',
            title: this.translateSettings('FILTERS'),
            values: this.convertFiltersToChecklist(this.filters),
        };

        const timePicker = (id: string, title: string): FormBuilderField => ({
            id, title: this.translateSettings(title), type: 'time', min: '00:00', max: '23:00',
            options: { disableTime: true },
            setValue: (field: FormBuilderField, values: any, value: any) => {
                set(values, field.id, value.split(':')[0]);
            },
        });

        return {
            groups: [{
                name: this.translateDimensions(`${name.toUpperCase()}`),
                fields: [
                    hasRange ? {
                        type: 'combo', id: 'mode', title: this.translateSettings('MODE'), values: [
                            { key: 'include', value: this.translateSettings('LIST'), fields: [checklist] },
                            {
                                key: 'range', value: this.translateSettings('RANGE'), fields: [
                                    timePicker('range.start', 'START'),
                                    timePicker('range.end', 'END'),
                                ],
                            },
                        ],
                    } : checklist,
                ],
            }],
        };

    }

    getFlattenedFilters(filters: { [key: string]: AnalyticsCellSetFilter }): string[] {
        const result = [];

        const addChildrenToResult = (key: string, filter: AnalyticsCellSetFilter) => {
            result.push(key); // the item itself should also be checked
            const children = Object.keys(filter.children);
            if (!!children.length) {
                children.forEach(child => {
                    addChildrenToResult(`${key}.${child}`, filter.children[child]);
                });
            }
        };

        const items = Object.keys(filters);
        items.forEach(item => {
            addChildrenToResult(item, filters[item]);
        });

        return result;
    }

    // flipItems should follow a rolled up structure
    flipItems(items: string[], flattenedFilters: string[]): string[] {
        // return a filtered list which removes the excluded items
        let selected = [];
        const difference = flattenedFilters.filter(f => items.indexOf(f) === -1);
        const dictionary = flattenedFilters.reduce((result, filter) => {
            const parent = filter.substring(0, filter.lastIndexOf('.'));
            const ancestors = flattenedFilters.filter(x => parent.includes(x));
            const siblings = flattenedFilters.filter(x => x.includes(parent) && x.split('.').length === filter.split('.').length);
            const children = flattenedFilters.filter(x => x.includes(filter) && x.split('.').length === filter.split('.').length + 1);
            const descendants = flattenedFilters.filter(x => x.includes(filter) && x.split('.').length > filter.split('.').length + 1);

            result[filter] = { parent, ancestors, siblings, children, descendants };
            return result;
        }, {});

        difference.forEach(x => {
            const relation = dictionary[x];
            selected.push(x);
            
            if (items.length === 1 && relation.ancestors.includes(items[0])) {
                selected = [];
                return selected;
            }

            if (!!relation.children.length && relation.siblings.includes(x) && !items.includes(x)) {
                relation.children.forEach(child => {
                    selected.push(child);
                });

                if (!!relation.descendants.length) {
                    relation.descendants.forEach(descendant => {
                        selected.push(descendant);
                    });
                }
            }

            if (items.includes(relation.parent) && difference.filter(d => d.includes(relation.parent)).length === relation.siblings.length) {
                selected = [...selected.filter(s => s !== x)];
            }

        });
        return [...new Set(selected)];
    }

    // receives an 'include' items list and decide on whether to keep it included or change mode to exclude
    // NOTE: make sure to flip the items in the receiving method if this returns exclude
    getMostPerformantMode(items: string[], filters: string[]): 'include' | 'exclude' {
        // count the amounts in and out of the items[] for comparison
        const count: { in: number, out: number } = filters.reduce((res, cur) => {
            if (items.find(y => cur.includes(y))) {
                ++res.in;
            } else {
                ++res.out;
            }
            return res;
        }, { in: 0, out: 0 });

        // the side that has the least amount of work to do wins
        return count.in <= count.out ? 'include' : 'exclude';
    }

    getExpandedItems(items: string[], filters: string[], mode: 'include' | 'exclude'): string[] {
        const result = [];
        const itemsToExclude = this.flipItems(items, filters);
        const keys = mode === 'include' ? items :  itemsToExclude.filter(x => x.split('.').length === 1);
        
        keys.forEach(key => {
            result.push(...filters.filter(x => x.includes(key)));
        });
        return [...new Set(result)]; // remove all duplicates
    }

    getRolledUpItems(items: string[]): string[] {
        const result = [];
        items.forEach(item => {
            const parent = item.substring(0, item.lastIndexOf('.'));
            if (!items.find(x => x === parent)) {
                result.push(item);
            }
        });
        return result;
    }

    convertFiltersToChecklist(filters: { [key: string]: AnalyticsCellSetFilter }): FormBuilderKeyValue[] {
        const result = [];
        // recursively go through the keys of each level and add it to a flat FormBuilderKeyValue[] list
        const recurseConvert = (filter: { [key: string]: AnalyticsCellSetFilter }, parentKey?: string) => {
            Object.keys(filter).filter(x => x !== 'undefined').forEach(x => {
                const item = filter[x];
                const key = parentKey ? `${parentKey}.${x}` : x;
                const index = key.split('.').length - 1;
                const children = Object.keys(item.children);

                result.push({ key, value: item.name, indent: index });

                if (!!children.length) {
                    recurseConvert(item.children, key);
                }
            });
        };
        recurseConvert(filters);
        return result;
    }
}
