import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'app/app.service';
import { EventFilterService } from '../events/event-filter.service';
import { AssetGroupingService } from '../assets/asset-grouping.service';

@Injectable()
export class GeoFilterService extends EventFilterService {

    constructor(
        app: AppService,
        i18n: TranslateService,
        grouping: AssetGroupingService
    ) {
        super(app, i18n, grouping);
    }

    ACTION_EVENTS = {
        geocoding: ['speed'],
    };

    ACTION_TARGETS = {
        zoneevent: 'zones',
        assetstatechangeevent: 'state',
    };

    EVENT_CONDITIONS = {
        speedlimit: {
            mode: { type: 'dropdown', values: ['greater', 'less', 'equals'] },
            speed: { type: 'speed' },
        },
        routenumber: {
            mode: { type: 'dropdown', values: ['equals', 'starts', 'contains', 'ends', 'not_equals', 'not_starts', 'not_contains', 'not_ends'] },
            value: { type: 'value' },
        },
        roadname: {
            mode: { type: 'dropdown', values: ['equals', 'starts', 'contains', 'ends', 'not_equals', 'not_starts', 'not_contains', 'not_ends'] },
            value: { type: 'value' },
        },
        carriageway: {
            mode: { type: 'dropdown', values: ['single', 'dual'] },
        },
        boundary: {
            boundary: { type: 'dropdown', values: ['suburb', 'town', 'state', 'country'] },
            mode: { type: 'dropdown', values: ['equals', 'starts', 'contains', 'ends', 'not_equals', 'not_starts', 'not_contains', 'not_ends'] },
            value: { type: 'value' },
        },
        time: {
            mode: { type: 'dropdown', values: ['inside', 'outside'] },
            time1: { type: 'time' },
            time2: { type: 'time' },
            dow: { type: 'checklist', values: ['0', '1', '2', '3', '4', '5', '6'] },
        },
        zone: {
            mode: { type: 'dropdown', values: ['inside', 'outside'] },
            zone: { type: 'dropdown', values: this.getZoneSelectionTree },
        },
        // linked: {
        //     mode: { type: 'dropdown', values: ['in', 'out'] },
        //     asset: { type: 'dropdown', values: this.getAssetSelectionTree },
        // },
        digital: {
            input: { type: 'dropdown', values: () => this.getIoTargetList(['digital_input']), linkedTo: 'state' },
            state: { type: 'dropdown', values: this.getIoValues },
        },
        state: {
            profile: { type: 'dropdown', values: this.getStateTargetList, linkedTo: 'state' },
            state: { type: 'dropdown', values: this.getStateValues },
        },
    };

    getConditionPrefix(index: number): string {
        return index === 0 ? this.i18n.instant('SHARED.ALERTS.FILTER_WHEN') : this.i18n.instant('SHARED.ALERTS.FILTER_AND');
    }


}
