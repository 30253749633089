import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output, Signal, ViewChild, WritableSignal, computed, effect, input, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { KuiIconModule } from 'app/key-ui';
import { LocalStore, MatchMediaService } from 'app/services';
import { TripItem } from 'app/services/trips/trips.service';
import { KeyFormBuilderModule, MapTelemetry, MapTripService } from 'app/shared/components';
import { KeyFormBuilderComponent } from 'app/shared/components/form-builder';
import { PipeModule } from 'app/shared/pipes/pipe.module';
import * as moment from 'moment';
import { ReplaySubject } from 'rxjs';
import { VideoAsset } from '../asset-selection/asset-selection.component';
import { PlaybackSliderComponent } from '../playback-slider/playback-slider.component';
import { TelemetryInformationComponent } from '../telemetry-information/telemetry-information.component';
import { TripMapComponent } from '../trip-map/trip-map.component';
import { Validate } from '../validate.model';
import { ClipSettings } from '../clip-builder/clip-builder.component';

export const VideoRequestLocalStore = new LocalStore('video-request-modal-component');

@Component({
    selector: 'key-event-selector',
    templateUrl: 'event-selector.component.html',
    styleUrls: ['event-selector.component.scss'],
    imports: [
        TripMapComponent,
        TelemetryInformationComponent,
        PlaybackSliderComponent,
        CommonModule,
        KeyFormBuilderModule,
        PipeModule,
        TranslateModule,
        KuiIconModule
    ],
    providers: [
        MapTripService,
        { provide: LocalStore, useValue: VideoRequestLocalStore }
    ],
    standalone: true,
})
export class EventSelectorComponent implements OnInit, OnDestroy, Validate {

    asset = input.required<VideoAsset>();
    trip = input.required<TripItem>();

    telemetry: Signal<MapTelemetry> = signal(undefined);
    showSettings: WritableSignal<Boolean> = signal(false);
    invalidSettings: WritableSignal<Boolean> = signal(false);
    isMobile: Signal<Boolean> = signal(false);

    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    
    @ViewChild(KeyFormBuilderComponent, { static: false }) formBuilder: KeyFormBuilderComponent;
    @Output() settingsChanged = new EventEmitter<ClipSettings>();

    start: string;
    end: string;

    form = computed(() => {
        return {
            groups: [{
                fields: [
                    {
                        id: 'duration',
                        type: 'number',
                        title: this.i18n.instant('MEDIA.VIDEO_REQUEST.MODAL.FIELDS.DURATION'),
                        required: true,
                        min: this.asset()?.duration?.min ?? 0,
                        max: this.asset()?.duration?.max ?? 60,
                        unit: this.i18n.instant('MEDIA.VIDEO_REQUEST.MODAL.FIELDS.SECONDS'),
                    },
                    {
                        id: 'offset',
                        type: 'number',
                        title: this.i18n.instant('MEDIA.VIDEO_REQUEST.MODAL.FIELDS.OFFSET'),
                        required: true,
                        min: -600,
                        max: 600,
                        unit: this.i18n.instant('MEDIA.VIDEO_REQUEST.MODAL.FIELDS.SECONDS')
                    }
                ]
            }]
        };
    });

    formValues = {
        duration: 30,
        offset: 0,
    };

    constructor(
        public i18n: TranslateService,
        public media: MatchMediaService,
        private tripService: MapTripService,
        private store: LocalStore,
    ) {
        this.isMobile = toSignal(this.media.isMobile);
        this.telemetry = toSignal(this.tripService.selectedTelemetry$);
        effect(() => {
            // when telemetry changes, recalculate the clip settings
            if (this.telemetry()) {
                this.calculateClipStart(this.telemetry().date);
            }
        });
    }

    ngOnInit() {
        this.formValues = this.store.watchState<{ duration: number, offset: number }>('clipSettings', {
            duration: 30,
            offset: 0
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    handleSettingsChange() {
        this.invalidSettings.set(false);
        this.calculateClipStart(this.telemetry().date);
    }

    isValid(): boolean {
        if (this.formBuilder) {
            this.invalidSettings.set(!this.formBuilder.validate());
            return !!this.start && !!this.end && this.formBuilder.validate();
        }
        return false;
    }

    private calculateClipStart(eventDate: string) {
        const date = moment(eventDate);
        const { duration, offset } = this.formValues;
        let start = date.add(offset, 'seconds');
        this.start = start.toISOString();
        this.end = start.add(duration, 'seconds').toISOString();
        this.settingsChanged.emit({
            duration,
            offset,
            eventDate,
            startDate: this.start,
            endDate: this.end
        });
    }
}
