import { HttpClient } from '@angular/common/http';

export function blobToDataURL(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const a = new FileReader();
        a.onload = (e) => {
            const result = e.target['result'];
            if (typeof result === 'string') {
                resolve(result);
            } else {
                reject(new Error('Was expecting a string, not an ArrayBuffer'));
            }
        };
        a.onerror = (e) => {
            reject(e);
        };
        a.readAsDataURL(blob);
    });
}


export function urlToDataUrl(http: HttpClient, url: string, accessToken?: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        http.get(url, {
            headers: { 'x-access-token': accessToken },
            responseType: 'blob',
        }).subscribe(async res => {
            resolve(await blobToDataURL(res));
        }, err => {
            reject(err);
        });
    });
}
