import { Injectable } from '@angular/core';

import { FeaturesGuard } from 'app/guards/features.guard';
import { Observable, of } from 'rxjs';
import { combineLatest, map, skipWhile, catchError } from 'rxjs/operators';

@Injectable()
export class AlertsGuards extends FeaturesGuard  {
    canActivate(): Observable<boolean> {
        return this.app.client$
            .pipe(
                skipWhile(client => !client),
                map(() => this.app.features.page.alerts.enabled),
                combineLatest(this.matchesUserType('system')),
                map(([hasFeature, isSystemUser]) => {
                    const canNavigate = hasFeature || isSystemUser;

                    if (!canNavigate && !this.hasRouted()) {
                        this.navigation.navigateToDefault();
                    }

                    return canNavigate;
                }),
                catchError((err) => {
                    console.error(err);
                    if (!this.hasRouted()) {
                        this.navigation.navigateToDefault();
                    }
                    return of(false);
                })
            );
    }
}
