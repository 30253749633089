import { Component, QueryList, ChangeDetectorRef, AfterViewInit, ContentChildren, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { OptionsSidebarCardComponent } from './options-sidebar-card.component';

@Component({
    selector: 'key-options-sidebar',
    templateUrl: './options-sidebar.component.html',
    styleUrls: ['./options-sidebar.component.scss'],
})
export class OptionsSidebarComponent implements AfterViewInit, OnChanges {

    @Input() title: string;
    @Input() align: string;
    @Input() activeCard: string;
    @Input() absolute: boolean;
    @Output() onHide = new EventEmitter();
    @Output() onSelected = new EventEmitter<string>();

    @ContentChildren(OptionsSidebarCardComponent) cards: QueryList<OptionsSidebarCardComponent>;

    constructor(private ref: ChangeDetectorRef) {
    }

    ngAfterViewInit() {
        this.cards.forEach((card, index) => {
            card.onClick.subscribe(() => {
                this.onSelected.emit(card.id);
                this.setActiveCard(card);
            });
            if ((this.activeCard && card.id === this.activeCard) || (!this.activeCard && index === 0)) {
                setTimeout(() => { // prevent ExpressionChangedAfterItHasBeenCheckedError
                    this.setActiveCard(card);
                });
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.cards && changes.activeCard) {
            this.cards.forEach(card => {
                if (this.activeCard && card.id === this.activeCard) {
                    this.setActiveCard(card);
                }
            });
        }
    }

    setActiveCard(card: OptionsSidebarCardComponent) {
        this.cards.forEach(c => {
            c.active = c === card;
            c.ngOnChanges();
        });
        this.ref.markForCheck();
    }

}
