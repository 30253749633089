import { KuiIconModule } from './../icon/icon.module';
import { KuiDropdownModule } from './../dropdown/dropdown.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { KuiBreadcrumbComponent } from './breadcrumb.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    KuiDropdownModule,
    KuiIconModule,
  ],
  exports: [KuiBreadcrumbComponent],
  declarations: [
    KuiBreadcrumbComponent,
  ],
})
export class KuiBreadcrumbModule {}
