import { Directive, Input, ElementRef, Renderer2, OnChanges, AfterContentInit, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { KuiActionDirective } from 'app/key-ui/action/action.directive';
import { MatchMediaService } from 'app/services';

@Directive({
    selector: '[kui-button]',
})
export class KuiButtonDirective extends KuiActionDirective implements AfterContentInit, OnChanges {
    private _color = 'primary';

    @Input() color: string;
    @Input() outlined: boolean;
    @Input() size: 'sm' | 'md' | 'lg';

    constructor(
        public el: ElementRef,
        public renderer: Renderer2,
        public componentFactoryResolver: ComponentFactoryResolver,
        public viewContainerRef: ViewContainerRef,
        public matchmedia: MatchMediaService) {
        super(el, renderer, componentFactoryResolver, viewContainerRef, matchmedia);
    }

    ngAfterContentInit() {
        this._color = this.color || this._color;
        this.renderer.addClass(this.el.nativeElement, 'btn');
        this._updatecolor(this._color);

        if (this.size) {
            this.renderer.addClass(this.el.nativeElement, `btn-${this.size}`);
        }
    }

    ngOnChanges(inputChanges) {
        if (inputChanges.color) {
            this._updatecolor(inputChanges.color.currentValue);
        }
    }

    _updatecolor(newcolor: string) {
        this.renderer.removeClass(this.el.nativeElement, this.outlined ? `btn-outline-${this._color}` : `btn-${this._color}`);
        this.renderer.addClass(this.el.nativeElement, this.outlined ? `btn-outline-${newcolor}` : `btn-${newcolor}`);
        this._color = newcolor;
    }
}
