import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { escapeRegExp } from 'lodash';
import { AutoComplete } from 'primeng/autocomplete';

export interface KuiAutoCompleteItem {
    id: string;
    name: string;
}

@Component({
    selector: 'kui-tag-input',
    templateUrl: './tag-input.component.html',
})
export class KuiTagInputComponent {
    @Input() remoteSearch = false;
    @Input() loading = false;
    @Input() invalid = false;
    @Input() multiple = true;
    @Input() freeText = false;
    @Input() placeholder = '';
    @Input() disabled: boolean = undefined;

    @ViewChild(AutoComplete, { static: true }) autoComplete: AutoComplete;

    // Please don't abuse me. 😢
    @Input()
    set selectedItems(items: KuiAutoCompleteItem[]) {
        if (this.multiple) {
            this._selectedItems = [...items];
        } else if (items?.length > 0) {
            this._selectedItems = [items[items.length - 1]];
        } else {
            this._selectedItems = [];
        }
    }
    get selectedItems(): KuiAutoCompleteItem[] {
        return this._selectedItems || [];
    }

    @Input() autoCompleteItems: KuiAutoCompleteItem[] = [];
    @Output() searchTermChanged = new EventEmitter<string>();

    @Output() itemAdded = new EventEmitter<KuiAutoCompleteItem>();
    @Output() itemRemoved = new EventEmitter<KuiAutoCompleteItem>();

    private _suggestedItems: KuiAutoCompleteItem[] = [];
    private _searchTerm = '';
    private _selectedItems: KuiAutoCompleteItem[] = [];

    constructor(private el: ElementRef, private changeDetector: ChangeDetectorRef) { }

    /**
     * Suggestion items could be provided as external items
     * Here it is wrapper so that when `remoteSearch` is enabled, the list can be populated externally.
     */
    get suggestionItems() {
        return this.remoteSearch ? this.autoCompleteItems : this._suggestedItems;
    }

    doSearch({ query }: { query: string }) {

        this._searchTerm = query;

        if (this.remoteSearch) {
            return this.searchTermChanged.emit(query);
        }

        if (this.autoCompleteItems && this.autoCompleteItems.length > 0) {
            // Allow only text searches
            const searchExpression = new RegExp(escapeRegExp(query), 'i');
            this._suggestedItems = this.autoCompleteItems
                .filter(item => !this.selectedItems.find(x => x?.id === item.id))
                .filter(item => searchExpression.test(item.name))
                .slice(0, 4);
        }
    }

    highlightTerm(value: string) {
        return value.replace(
            new RegExp(escapeRegExp(this._searchTerm), 'ig'),
            (term) => `<span class="text-primary">${term}</span>`
        );
    }

    addItem(item: any) {
        // this changes depending on if you select something from the dropdown or the add button
        let tag = item;
        if (item?.originalEvent) {
            tag = item.value
        }
        // When the user fully types out a value and clicks on the suggestion in the dropdown this "addItem" method gets called twice. 
        // Once due to a "change" event, and once due to a click "event".
        // Do some nasty checking to ensure that we don't emit the same item twice, unless it's a single select then we should emit...
        if (this.multiple) {
            if (!this.selectedItems.includes(tag)) {
                this.itemAdded.emit(tag);
            }
        } else {
            this.itemAdded.emit(tag);
        }
    }

    removeItem(item: KuiAutoCompleteItem) {
        if (item) {
            this.itemRemoved.emit(item);
            this.selectedItems = this.selectedItems.filter(x => x?.id !== item?.id);
            this.changeDetector.markForCheck();
        }
    }

    clearItem() {
        // Only intended for single selects, hooks onto when input box is cleared
        if (!this.multiple && this.selectedItems.length === 1) {
            this.removeItem(this.selectedItems[0]);
        }
    }

    onKeyUp(event) {
        if (event.key === 'Enter' && this.freeText) {
            // HACK ALERT: only reliable way I could find to get the input text
            const input = this.el.nativeElement.querySelector('.kui-tag-input__textbox') as HTMLInputElement;
            if (input) {
                const value = input.value;
                if (value) {
                    input.value = '';
                    this.addItem({ id: value, name: value });
                    this._selectedItems.push({ id: value, name: value });
                    this.changeDetector.markForCheck();
                }
            }
        }
    }


}