import { Component, Input, OnInit, ChangeDetectorRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormBuilderField, BaseFormBuilderFieldComponent } from 'app/shared/components/form-builder';
import { from as fromPromise } from 'rxjs';
import { KuiTreeSelectNode, KuiTreeSelectComponent } from 'app/key-ui/tree-select/tree-select.component';
import { TreeNode } from '@ali-hm/angular-tree-component';
import { KuiDropdownComponent } from 'app/key-ui/dropdown/dropdown.component';
import { escapeRqlValue } from 'app/shared/utils/rql';

interface TagItem {
    id: string;
    name: string;
    filter: any;
}

// TODO: This field currently only supports getting data from a custom promise set in the options and when searching it does an api call.
// Future dude: we may consider getting a preloaded list from field.values and being able to filter on that instead
@Component({
    selector: 'key-form-builder-search-select-field',
    templateUrl: './searchselect.component.html',
    styleUrls: ['./searchselect.component.scss'],
})
export class KeyFormBuilderSearchSelectFieldComponent implements OnInit, BaseFormBuilderFieldComponent {

    @Input() field: FormBuilderField;
    @Input() values: { [key: string]: any };
    @Input() error: string; // set this error value externally to have the default error highligh and display kick in

    touched = false;
    dirty = false;
    isLoading = false;
    multi = false;
    ownerId: string;
    truncatedList: { count: number, limit: number };

    selectedItems: TagItem[] = [];
    autoCompleteItems: TagItem[] = [];
    promisesCache: { [key: string]: Promise<any[]> } = {};

    @Output() onChange: EventEmitter<{ value: TagItem[], dirty: boolean }> = new EventEmitter();

    treeNodes: KuiTreeSelectNode[];

    @ViewChild(KuiDropdownComponent) filterDropdown: KuiDropdownComponent;
    @ViewChild(KuiTreeSelectComponent) tree: KuiTreeSelectComponent;

    constructor(
        private ref: ChangeDetectorRef
    ) {
    }

    validate(): boolean {
        this.dirty = true;
        this.touched = true;
        this.ref.markForCheck();
        return !this.field.required || this.selectedItems.length > 0;
    }

    async ngOnInit() {
        if (!this.field.options || this.field.options && !this.field.options.searchListPromise) {
            console.error('ERROR KeyFormBuilderSearchSelectFieldComponent: No searchItemPromise and searchListPromise found in field.options');
        }

        this.values[this.field.id] = this.field.getValue ? this.field.getValue(this.field, this.values) : this.values[this.field.id] || this.field.value;
        const id = this.values[this.field.id] && this.values[this.field.id].id;
        this.multi = this.field.max !== 1;

        try {
            this.isLoading = true;
            const item = await this.field.options.searchItemPromise(id);
            this.selectedItems = [{
                id: item.id,
                name: item.name,
                filter: item['filter'],
            }];
        } catch (error) {
            this.selectedItems = [];
        } finally {
            this.isLoading = false;
            this.ref.markForCheck();
        }

        this.updateTreeNodes();
    }

    updateValues() {
        this.dirty = true;
        this.touched = true;

        if (this.field.setValue) {
            this.field.setValue(this.field, this.values, this.multi ? this.selectedItems : this.selectedItems[0]);
        } else {
            this.values[this.field.id] = this.multi ? this.selectedItems : this.selectedItems[0];
        }

        this.updateTreeNodes();
        this.onChange.emit({ value: this.values[this.field.id], dirty: this.dirty });
    }

    addTag(value: TagItem) {
        if (this.multi) {
            this.selectedItems = [...this.selectedItems, value];
        } else {
            this.selectedItems = [value];
        }
        this.updateValues();
    }

    removeTag(value: TagItem) {
        const idx = this.selectedItems.findIndex(x => x.id === value.id);
        this.selectedItems.splice(idx, 1);
        this.updateValues();
    }

    updateAutoCompletionList(searchTerm: string) {
        const filter = searchTerm ? escapeRqlValue(searchTerm) : '';

        this.isLoading = true;
        this.dirty = true;

        return fromPromise(
            this.getItems(10, filter).then(items => {
                this.autoCompleteItems = items.map(x => ({
                    id: x.id,
                    name: x.name,
                    filter: x['filter'],
                })).filter(x => !this.selectedItems.find(y => y.id === x.id) && x.name.toLowerCase().includes(searchTerm.toLowerCase()));

                this.isLoading = false;
                this.ref.markForCheck();

                return this.autoCompleteItems;
            }).catch(err => {
                console.error(err);
                return [];
            })
        );
    }

    nodeSelected(event: { eventName: string, node: TreeNode }) {
        this.dirty = true;

        const { name, id, filter } = event.node.data;
        if (name && !this.selectedItems.find(x => x.id === id)) {
            this.addTag({
                id,
                name,
                filter: filter,
            });
        }
        if (event.eventName === 'activate' && this.tree) {
            this.tree.clearSelection();
        }
        this.filterDropdown.toggle();
    }

    async updateTreeNodes() {
        const items = await this.getItems(100);
        if (items) {
            this.treeNodes = items;
        }
    }

    async getItems(limit: number, filter: string = ''): Promise<KuiTreeSelectNode[]> {
        if (!this.field?.options?.searchListPromise) {
            return [];
        }
        const id = `${filter}/${limit}`;
        this.promisesCache[id] = this.promisesCache[id] || this.field.options && this.field.options.searchListPromise(limit, filter).then(res => {
            const { items, count } = res || {} as any;
            this.truncatedList = count > limit && { limit, count };
            return items || [];
        });
        return this.promisesCache[id];
    }
}
