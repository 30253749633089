<input kui-input
    type="number"
    [min]="field.min"
    [max]="field.max"
    key-number-validator
    #minField="ngModel"
    [required]="field.required"
    [ngModel]="values[field.id][0]"
    (ngModelChange)="changeNumber({ value: $event, index: 0 })"
    class="flex-1 mr-1"
    [class.border-danger]="minBiggerThanMax || minField.invalid && (minField.dirty || minField.touched)">
<input kui-input
    type="number"
    [min]="field.min"
    [max]="field.max"
    key-number-validator
    #maxField="ngModel"
    [required]="field.required"
    [ngModel]="values[field.id][1]"
    (ngModelChange)="changeNumber({ value: $event, index: 1 })"
    class="flex-1 ml-1"
    [class.border-danger]="error || minBiggerThanMax || maxField.invalid && (maxField.dirty || maxField.touched)">
<ng-container *ngFor="let f of [minField, maxField]; let i = index">
    <div *ngIf="f.invalid && (f.dirty || f.touched)"
        class="invalid-feedback d-block w-100">
        <div *ngIf="f.errors['required']">{{ 'FORMS.SHARED.REQUIRED' | translate:{ field: 'FORMS.MIN_MAX.' + ids[i] | translate } }}</div>
        <div *ngIf="f.errors['min']">{{ 'FORMS.NUMBER.MIN' | translate:{ field: 'FORMS.MIN_MAX.' + ids[i] | translate, min: field.min } }}</div>
        <div *ngIf="f.errors['max']">{{ 'FORMS.NUMBER.MAX' | translate:{ field: 'FORMS.MIN_MAX.' + ids[i] | translate, max: field.max } }}</div>
    </div>
</ng-container>
<div class="invalid-feedback d-block w-100"
    *ngIf="minBiggerThanMax">{{ 'FORMS.MIN_MAX.MIN_LARGER_THAN_MAX' | translate }}</div>
<div *ngIf="error"
    class="invalid-feedback d-block">
    <div>{{ error }}</div>
</div>