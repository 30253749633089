<kui-modal #modal
    [loading]="loading"
    [title]="'ANALYTICS.CREATE_WIDGET.SHARE_TO_DASHBOARD' | translate"
    [actions]="modalActions"
    (onClose)="detachModal()">

    <kui-alert type="danger"
        class="mb-0 key-alert-wrapper"
        *ngIf="errorMessage">
        <kui-icon name="ban"
            size="sm"></kui-icon>
        <span>{{errorMessage}}</span>
    </kui-alert>

    <div class="p-4"
        *ngIf="success; else createView">
        {{ 'ANALYTICS.CREATE_WIDGET.SUCCESSFULL_MESSAGE' | translate: {
            dashboards: getDashboardsString(formValues.shareType === 'template' ? formValues.templates : formValues.dashboards)
        } }}
    </div>

    <ng-template #createView>
        <div class="p-4 border-bottom">
            <div class="d-flex"
                style="min-height: 200px">
                <key-analytics-chart *ngIf="!noResult"
                    [cellset]="cellset"
                    [settings]="settings"
                    [type]="widgetType"></key-analytics-chart>

                <kui-alert-panel *ngIf="noResult"
                    [message]="'ANALYTICS.ERRORS.NO_RESULTS' | translate"></kui-alert-panel>
            </div>
        </div>
        <div class="p-4">
            <key-form-builder [form]="form"
                [values]="formValues"
                (onChange)="onFormChanges($event)"
                autoTranslatePrefix="ANALYTICS.CREATE_WIDGET"></key-form-builder>
        </div>
    </ng-template>
</kui-modal>