import { Component, Input, ViewChild, HostBinding } from '@angular/core';
import { KuiModalComponent } from 'app/key-ui/modal/modal.component';
import { BaseComponent } from 'app/shared/components/base/base.component';

@Component({
    selector: 'kui-modal-ref',
    template: ``,
})
export class KuiModalRefComponent<TData> extends BaseComponent {
    @Input() data: TData;
    @Input() actions: { [key: string]: (...args: any[]) => void };

    @ViewChild(KuiModalComponent) modal: KuiModalComponent;

    @HostBinding('class.modal-ref') refClassname = true;

    openModal() {
        if (this.modal) {
            this.modal.open();
        } else {
            console.warn('no child KuiModalComponent found inside KuiModalRefComponent... Please make sure to add one if you wish to have all the pretty modal styling and functionality');
        }
    }

    closeModal() {
        if (this.modal) {
            this.modal.close();
        } else {
            console.warn('no child KuiModalComponent found inside KuiModalRefComponent... Please make sure to add one if you wish to have all the pretty modal styling and functionality');
        }
    }
}
