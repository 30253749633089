<key-listing [hover]="true"
    [active]="selected"
    [class.muted]="item.muted">
    <key-listing-avatar [ngSwitch]="item.type"
        class="icon-light p-1"
        (click)="onSelect.emit(item)">
        <kui-icon *ngSwitchCase="'dataset'"
            name="file-alt"
            [size]="'lg'"></kui-icon>
        <kui-icon *ngSwitchCase="'analytics'"
            name="chart-line"
            [size]="'lg'"></kui-icon>
        <kui-icon class="icon-templated"
            name="user"
            size="sm"
            *ngIf="item.template"></kui-icon>

    </key-listing-avatar>

    <div class="d-flex flex-row">
    
        <div class='flex-1'
            (click)="onSelect.emit(item)">
            <key-listing-title>{{item.name}}</key-listing-title>
            <key-listing-description>
                <div class="pt-1 muted">{{item.description}}</div>
            </key-listing-description>
        </div>
    
        <div class='actions d-flex flex-column'>
            <div [class.icon-light]="!starred"
                [class.text-primary]="starred"
                (click)="onStarred.emit(item)">
                <kui-icon name="star"
                    [size]="'sm'"
                    class="ml-3"></kui-icon>
            </div>
        </div>

    </div>
</key-listing>