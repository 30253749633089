import { KuiNavModule } from './../nav/nav.module';
import { KuiTabComponent } from './tab.component';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KuiTabsComponent } from './tabs.component';

@NgModule({
  imports: [CommonModule, FormsModule, KuiNavModule],
  exports: [KuiTabsComponent, KuiTabComponent],
  declarations: [KuiTabsComponent, KuiTabComponent],
})
export class KuiTabsModule {}
