import { Component, OnDestroy, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { ErrorLoggerService, MeasurementUnitsService, NotificationService } from 'app/services';
import { Observable, Subject } from 'rxjs';
import { MapCoordinates } from '../../../map.component';
import { MapOptionComponent } from '../map-option.component';
import { CoordinateDetails, PointInformationService } from './point-information.service';
import { MapOptionService } from '../../map-option.service';
import { MapOptionRoutingComponent } from '../map-option-routing';
import { takeUntil } from 'rxjs/operators';

export interface PointInformationConfig {
    hideCrosshair: boolean;
    routing: boolean;
    googlemaps: boolean;
    findclosest: boolean;
    mapsearch: boolean;
}

@Component({
    styleUrls: ['./map-option-point-information.component.scss'],
    templateUrl: './map-option-point-information.component.html',
})
export class MapOptionPointInformationComponent implements MapOptionComponent<PointInformationConfig>, OnInit, OnDestroy {

    static ID = 'map-option-point-information-component';
    static ICON = 'map-pin';
    static TITLE = 'LEAFLET.MAP_OPTIONS.POINT_INFO';

    private destroyed: Subject<void> = new Subject();

    data: PointInformationConfig;
    loading$: Observable<boolean>;
    coordinates: MapCoordinates;
    coordinatesDetails: CoordinateDetails[];
    coordinatesError: string;

    constructor(
        private pointService: PointInformationService,
        private i18n: TranslateService,
        private units: MeasurementUnitsService,
        private error: ErrorLoggerService,
        private notify: NotificationService,
        private mapOptionService: MapOptionService) { }

    ngOnInit(): void {
        this.loading$ = this.pointService.loading$;
        this.pointService.coordinates$.pipe(
            takeUntil(this.destroyed)
        ).subscribe(coords => {
            this.coordinates = coords;
            this.updateCoordinatesDetails(coords);
        });

        // sometimes we want to open the panel without prompting the user to choose a point.
        if (!this.data?.hideCrosshair) {
            this.pointService.start();
        }
    }

    ngOnDestroy(): void {
        this.destroyed.next();
        this.pointService.stop();
    }

    openRouting(coordinates: MapCoordinates) {
        const start = {
            address: coordinates.address,
            lat: coordinates.lat,
            lon: coordinates.lon
        }
        this.mapOptionService.load({
            component: MapOptionRoutingComponent,
            data: {
                routeInputs: start && [start]
            },
            id: MapOptionRoutingComponent.ID,
            title: MapOptionRoutingComponent.TITLE,
            icon: MapOptionRoutingComponent.ICON
        });
    }

    searchClosestAsset(coordinates: MapCoordinates) {
        this.pointService.searchClosestAsset(coordinates);
    }

    mapSearch(coordinates: MapCoordinates) {
        this.pointService.mapSearch(coordinates);
    }

    openInGoogleMaps(coordinates: MapCoordinates) {
        this.pointService.openInGoogleMaps(coordinates);
    }

    updateCoordinatesDetails(coordinates: MapCoordinates) {
        this.coordinatesError = null;

        if (!coordinates) {
            this.coordinatesDetails = null;
            return;
        }

        if (coordinates.error) {
            this.error.trackException(coordinates.error);
            this.coordinatesError = this.notify.translateError(coordinates.error);
            return;
        }

        const { lat, lon, address, speed } = coordinates;
        this.coordinatesDetails = lat && lon && address ? [
            { title: this.i18n.instant('LEAFLET.COORDINATES.TEXT'), text: address },
            { title: this.i18n.instant('LEAFLET.COORDINATES.LAT'), text: (Math.round(lat * 1e6) / 1e6).toString() },
            { title: this.i18n.instant('LEAFLET.COORDINATES.LON'), text: (Math.round(lon * 1e6) / 1e6).toString() },
            speed ? { title: this.i18n.instant('LEAFLET.COORDINATES.SPEED'), text: this.units.format(speed, 'speed').format } : null,
        ].filter(x => x) : [];

        if (coordinates.action === 'find_closest_asset') {
            this.searchClosestAsset(coordinates);
        }
    }

}

