import { MeasurementUnitsService } from 'app/services/measurement-units/measurement-units.service';
import { TranslateService } from '@ngx-translate/core';
import { MapMarker } from '../../map/map.component';
import * as fleetShared from '@key-telematics/fleet-ui-shared';

/* NOTE - The translation dictionary has been moved to the NPM module "@key-telematics/fleet-ui-shared" so that it may be re-used in reporting and notifications */

export function getAndTranslateEventText(textSection: 'title' | 'description', event: { class: string, type: string, details: { [key: string]: any; }; }, i18n: TranslateService, units: MeasurementUnitsService): string {
    return fleetShared.getAndTranslateEventText(textSection, event, i18n, {
        format(value, type) {
            return units.format(value, type).format;
        },
    });
}

export function getEventInfoText(event: { class: string, type: string, details: { [key: string]: any; }; }, i18n: TranslateService, units: MeasurementUnitsService): fleetShared.IEventInfoValue[] {
    return fleetShared.getEventInfoText(event, i18n, {
        format(value, type) {
            return units.format(value, type).format;
        },
    });
}

export function getEventLinkedMarkers(event: { id: string; class: string, type: string, details: { [key: string]: any; }; }): MapMarker[] {
    const links = [];
    switch (event.class) {
        case 'fuelingevent':
            const fuelSite = event.details?.fuelSite;
            if (fuelSite && fuelSite.longitude && fuelSite.latitude) {
                links.push({
                    id: event.id + '-fuel-link',
                    name: fuelSite.name,
                    lon: fuelSite.longitude,
                    lat: fuelSite.latitude,
                    getIcon: () => {
                        return {
                            size: 30,
                            html: `
                                    <div class="map-font-icon">
                                        <i class="icon icon__fill icon-gas-pump text-dark"></i>
                                        <i class="icon icon__fill icon-gas-pump map-font-icon__background map-font-icon__shadows"></i>
                                    </div>
                                `,
                        };
                    },
                });
            }
            break;
    }
    return links;
}
