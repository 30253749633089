import { Component, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { KuiModalComponent } from 'app/key-ui/modal/modal.component';
import { IKuiModalAction } from 'app/key-ui';
import { map, first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

export interface FeedCommentResult {
    action: 'cancel' | 'comment' | 'acknowledge';
    comment?: string;
}

@Component({
    selector: 'key-event-comment-modal',
    templateUrl: 'comment.component.html',
    styleUrls: ['comment.component.scss'],
})
export class EventFeedCommentModalComponent {

    @ViewChild(KuiModalComponent, { static: true }) modal: KuiModalComponent;

    trigger: Subject<any>;

    actions: IKuiModalAction[];

    title: string;
    action: string;
    comment: string;

    @ViewChild('commentTextArea', { static: true }) commentTextArea: ElementRef;

    constructor(private i18n: TranslateService) {
    }

    open(action: 'comment' | 'acknowledge'): Promise<FeedCommentResult> {
        this.comment = '';
        this.action = action;
        this.title = action === 'comment' ? this.i18n.instant('ALERTS.BUTTONS.COMMENT') : this.i18n.instant('ALERTS.BUTTONS.ACKNOWLEDGE');
        this.actions = [
            { text: this.i18n.instant('DIALOG.CANCEL'), action: () => { this.trigger.next('cancel'); }, keypress: 27, style: 'secondary' },
            { text: this.title, action: () => { this.trigger.next(this.action); }, keypress: 'Enter' },
        ];
        this.trigger = new Subject<any>();
        this.modal.open();
        return this.trigger.pipe(
            first(),
            map(value => ({
                action: value,
                comment: this.comment,
            }))
        ).toPromise();
    }

    opened() {
        setTimeout(() => this.commentTextArea.nativeElement.focus());
    }

    cancel() {
        if (this.trigger) {
            this.trigger.next('cancel');
        }
    }

}
