<div class="loading"
    *ngIf="!loaded">
    <kui-loader class="centered d-flex flex-stretch justify-content-center align-items-center"></kui-loader>
</div>
<div class="error"
    *ngIf="status">
    <div class="m-4 centered d-flex flex-stretch justify-content-center align-items-center">
        <div class="m-4">
            <div>
                <h4 class="text-center">{{ 'MEDIA.STATUS.TITLE.' + status.status.toUpperCase() | translate }}</h4>
            </div>
            <div class="text-center mb-2 muted-less">{{ 'MEDIA.STATUS.DESCRIPTION.' + status.status.toUpperCase() | translate: { user: changedByUser } }}</div>
            <kui-progress *ngIf="status.progress > 0"
                [value]="status.progress"
                type="primary"></kui-progress>
            <div *ngIf="requesterName"
                class="small text-center mt-2 muted-less">{{ 'MEDIA.STATUS.QUEUED_BY' | translate: { requester: requesterName, date: requesterDate, type: requesterType } }}</div>
        </div>
    </div>
</div>
<div class="error"
    *ngIf="error">
    <div class="centered d-flex flex-stretch justify-content-center align-items-center">
        <kui-alert type="none"
            class="key-alert-wrapper">
            <kui-icon name="ban"
                size="lg"></kui-icon>
            <span>{{ error }}</span>
        </kui-alert>
    </div>
</div>
<video class="w-100"
    [class.hidden]="!loaded || error || status"
    autoplay
    playsinline>
    <source [src]="video.url"
        [type]="video.type" />
</video>
<a kui-button
    class="download"
    *ngIf="loaded && !error && !status && download"
    target="_blank"
    [download]="video.filename"
    [href]="video.url">
    <kui-icon name="download"></kui-icon>
</a>