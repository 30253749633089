import * as Color from 'color';

/** Our own color class that we can extend with more functions as we need  */
export class ThemeColor {

    private color: Color;

    constructor(value: string | ThemeColor) {
        this.color = typeof value === 'string' ? Color(value) : value.color;
    }

    hex(): string {
        if (this.color.alpha() === 1) {
            return this.color.hex().toLowerCase();
        } else {
            return this.color.rgb().string();
        }
    }

    negate(): ThemeColor {
        this.color = this.color.negate();
        return this;
    }

    lighten(ratio: number): ThemeColor {
        this.color = this.color.lighten(ratio);
        return this;
    }

    darken(ratio: number): ThemeColor {
        this.color = this.color.darken(ratio);
        return this;
    }

    saturate(ratio: number): ThemeColor {
        this.color = this.color.saturate(ratio);
        return this;
    }

    desaturate(ratio: number): ThemeColor {
        this.color = this.color.desaturate(ratio);
        return this;
    }

    whiten(ratio: number): ThemeColor {
        this.color = this.color.whiten(ratio);
        return this;
    }

    blacken(ratio: number): ThemeColor {
        this.color = this.color.blacken(ratio);
        return this;
    }

    grayscale(): ThemeColor {
        this.color = this.color.grayscale();
        return this;
    }

    fade(ratio: number): ThemeColor {
        this.color = this.color.fade(ratio);
        return this;
    }

    opaquer(ratio: number): ThemeColor {
        this.color = this.color.opaquer(ratio);
        return this;
    }

    rotate(degrees: number): ThemeColor {
        this.color = this.color.rotate(degrees);
        return this;
    }

    inverse(light: string, dark: string): ThemeColor {
        return this.color.isDark() ? new ThemeColor(light) : new ThemeColor(dark);
    }

}
