import { Injectable } from '@angular/core';
import { isEqual, cloneDeep, uniqWith } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { FilterPanelItem, FilterPanelOperator } from './filter-panel.model';

export interface FilterPanelFilter {
    date?: { start: string, end: string };
    page?: number;
    limit?: number;
    filters?: {
        [key: string]: FilterPanelItem[]
    };
    operators?: {
        [key: string]: FilterPanelOperator
    }
}



@Injectable()
export class FilterPanelService {

    filters: FilterPanelFilter = {
        filters: {},
    };

    private filtersSubject: BehaviorSubject<FilterPanelFilter> = new BehaviorSubject(this.filters);
    get filters$(): Observable<FilterPanelFilter> {
        return this.filtersSubject.asObservable();
    }

    private totalSubject: BehaviorSubject<number> = new BehaviorSubject(0);
    get total$(): Observable<number> {
        return this.totalSubject.asObservable();
    }

    constructor() {
    }

    private emit(filters: FilterPanelFilter, forceEmit = false) {
        if (forceEmit || !isEqual(filters, this.filters)) {
            this.filters = filters;
            this.filtersSubject.next(cloneDeep(this.filters));
        }
    }

    update(values: FilterPanelFilter, forceEmit = false) {
        this.emit({
            ...this.filters,
            ...values,
            page: values.page || 1, // reset the page to 1 if anything other than page is set
        }, forceEmit);
    }

    updateFilterValues(key: string, values: FilterPanelItem[]) {
        this.emit({
            ...this.filters,
            page: 1,
            filters: {
                ...this.filters.filters,
                [key]: uniqWith([...values], isEqual),
            },
        });
    }

    /** this won't replace existing filters, just add to them */
    addFilterValues(key: string, values: FilterPanelItem[]) {
        this.emit({
            ...this.filters,
            page: 1,
            filters: {
                ...this.filters.filters,
                [key]: uniqWith([...this.filters.filters[key] || [], ...values], isEqual),
            },
        });
    }

    resetFilters() {
        this.emit({
            ...this.filters,
            filters: {},
        });
    }

    updateTotal(total: number) {
        this.totalSubject.next(total);
    }

    updateOperator(key: string, operator: FilterPanelOperator): void {
        this.emit({
            ...this.filters,
            operators: {
                ...this.filters.operators,
                [key]: operator
            }
        });
    }

}
