import { Component, Output, EventEmitter, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilderDefinition, FormBuilderFieldChangeEvent } from 'app/shared/components/form-builder';

export interface ChartSettings {
    stacked: boolean;
    dataLabels: boolean;
}

export interface AvailableChartSettings {
    stacked?: boolean;
    dataLabels?: boolean;
}

export interface ChartValues {
    stacked: boolean;
    dataLabels: boolean;
}

@Component({
    selector: 'key-analytics-settings-chart',
    templateUrl: './chart.component.html',
})
export class AnalyticsSettingsChartComponent implements OnInit, OnChanges {
    form: FormBuilderDefinition;
    formLeftAxis: FormBuilderDefinition;
    formRightAxis: FormBuilderDefinition;

    values: ChartValues;

    @Input() options: ChartSettings;
    @Input() show: AvailableChartSettings = {};
    @Output() onChange: EventEmitter<{ [key: string]: any; }> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
        this.form = this.getForm();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.options && this.options) {
            this.values = this.options;
        }
        this.updateForm();
    }

    changeFormSetting(change: FormBuilderFieldChangeEvent) {
        if (change.dirty) {
            const { id } = change.field;
            this.onChange.emit({ [id]: change.values[id] });
        }
    }

    getForm(): FormBuilderDefinition {
        const { stacked, dataLabels } = this.show;

        return {
            groups: [{
                fields: [
                    { type: 'toggle', id: 'stacked', title: 'STACKED', visible: stacked },
                    { type: 'toggle', id: 'dataLabels', title: 'DATA_LABELS', visible: dataLabels },
                ],
            }],
        };
    }

    updateForm() {
        if (this.form) {
            const fields = this.form.groups[0].fields;

            const stackedIndex = fields.findIndex(x => x.id === 'stacked');
            fields[stackedIndex].visible = this.show.stacked;

            const dataLabelsIndex = fields.findIndex(x => x.id === 'dataLabels');
            fields[dataLabelsIndex].visible = this.show.dataLabels;
        }
    }
}
