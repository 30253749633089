<div [ngClass]="{
    'table-bordered': borders,
    'table-selectable': selection,
    'table-nowrap': nowrap,
    'table-static-height': height
  }"
    #container>

    <p-table class="table"
        #dataTable
        [columns]="_cols"
        [value]="rows"
        [resizableColumns]="resize"
        [reorderableColumns]="true"
        [customSort]="true"
        (sortFunction)="sortByColumn($event)"
        [selectionMode]="selectionMode"
        [metaKeySelection]="true"
        [scrollable]="true"
        [scrollHeight]="scrollHeight"
        columnResizeMode="expand"
        (onColReorder)="reorderCol($event)"
        (onRowSelect)="selectRow($event, false)"
        (onRowUnselect)="unselectRow($event)"
        (onColResize)="resizeCol($event)"
        (onSort)="updateSorting($event)"
        [dataKey]="selectionColumn"
        [(selection)]="_activeRows"
        [sortOrder]="sortOrder === 'desc' ? -1 : 1"
        [sortField]="sortField"
        [paginator]="!!pageSize"
        [rows]="pageSize">

        <ng-template pTemplate="header"
            let-columns>
            <tr>
                <th *ngIf="checkBoxSelection">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <ng-container *ngFor="let col of columns">
                    <th pResizableColumn
                        pReorderableColumn
                        [pResizableColumnDisabled]="!resize"
                        [pSortableColumn]="sort && col.sortable ? col.field : null"
                        [style.width.px]="col.width">
                        <div class="d-flex align-items-center">
                            <span [class]="'flex-1 text-' + (col.align || 'left')">{{col.display}}</span>
                            <p-sortIcon *ngIf="sort && col.sortable"
                                [field]="col.field"
                                class="pl-2 ml-auto muted">
                            </p-sortIcon>
                        </div>
                    </th>
                </ng-container>
            </tr>
            <tr class="ui-table-filter-row"
                *ngIf="showFilters">
                <th *ngIf="checkBoxSelection">
                </th>
                <th *ngFor="let col of columns"
                    [ngSwitch]="col.filter && col.filter.type">
                    <input *ngSwitchCase="'text'"
                        kui-input
                        type="text"
                        class="w-100"
                        [value]="col.filter.value || null"
                        (input)="filterColumn(col, $event.target.value)">

                    <select *ngSwitchCase="'combo'"
                        kui-input
                        class="w-100"
                        (change)="filterColumn(col, $event.target.value)">
                        <option *ngFor="let option of col.filter.values"
                            [value]="option.id">{{option.value}}</option>
                    </select>

                    <kui-dropdown *ngSwitchCase="'checklist'"
                        [style]="'secondary'"
                        [outlined]="true"
                        [menu]="col.toggleMenu"
                        [customContent]="filterOperator"
                        [customContentLocation]="'TOP'">
                        <ng-container>
                            <span class="flex-1 text-truncate text-left">
                                {{ getCheckListText(col) || ('SHARED.ANY' | translate) }}
                            </span>
                        </ng-container>
                    </kui-dropdown>
                    <ng-template #filterOperator>
                        <div *ngIf="col.filter.operator.visible"
                            class="ui-table-filter-column-operator px-4 pt-3 pb-2">
                            <div class="d-flex justify-content-between align-items-center">
                                <span>{{ 'SHARED.FILTER' | translate }}</span>
                                <div class="d-flex">
                                    <label class="form-check-label ml-2">
                                        <input
                                            kui-radio="filterOperator"
                                            value="OR"
                                            (change)="updateFilterOperator(col, 'OR')"
                                            [checked]="col.filter.operator.value === 'OR'">
                                        OR
                                    </label>
                                    <label class="form-check-label ml-2">
                                        <input
                                            kui-radio="filterOperator"
                                            value="AND"
                                            (change)="updateFilterOperator(col, 'AND')"
                                            [checked]="col.filter.operator.value === 'AND'">
                                        AND
                                    </label>

                                </div>
                            </div>
                            <hr class="w-100 mt-2 mb-0" />
                        </div>
                    </ng-template>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body"
            let-row
            let-columns="columns"
            let-rowIndex="rowIndex">

            <tr class="ui-table-section-header"
                *ngIf="sortGrouping && sortField && rowGroupMetadata && rowGroupMetadata[row[sortField]] && rowGroupMetadata[row[sortField]].index === rowIndex">
                <td [attr.colspan]="columns.length">
                    <span>{{row[sortField]}}</span>
                </td>
            </tr>

            <tr [pSelectableRow]="row"
                [pSelectableRowIndex]="rowIndex"
                [class]="'select-row-' + rowIndex + ' ' + (row._class || '')">

                <td *ngIf="checkBoxSelection">
                    <p-tableCheckbox [value]="row"></p-tableCheckbox>
                </td>

                <td *ngFor="let col of columns"
                    [class]="'ui-resizable-column text-' + (col.align || 'left')"
                    [style.width.px]="col.width">
                    <kui-table-cell-html *ngIf="row[col.field] || col.format"
                        [html]="getCellValue(col, row)"
                        [path]="row[col.field]?.path"
                        [action]="row[col.field]?.action"
                        [icon]="row[col.field]?.icon"
                        (actionClicked)="row[col.field]?.action({column: col, row: row})"></kui-table-cell-html>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="sorticon">
            <i class="ui-sortable-column-icon"></i>
        </ng-template>
    </p-table>
</div>