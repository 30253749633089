<div [ngSwitch]="type" class="mr-3">
  <div *ngSwitchCase="'image'">
    <img [src]="url">
  </div>
  <div *ngSwitchCase="'icon'" class="icon color-bg-secondary color-text-inverse d-flex align-items-center justify-content-center">
    <kui-icon [name]="icon" size="lg"></kui-icon>
  </div>
  <div *ngSwitchDefault>
    <ng-content></ng-content>
  </div>
</div>

