import { Pipe, PipeTransform } from '@angular/core';

/*
 * Converts decimal degrees to degrees and formats it
 *
 * Usage:
 *   value | dec2deg
 *
 * @see https://en.wikipedia.org/wiki/Geographic_coordinate_conversion#Coordinate_format_conversion
 */
@Pipe({name: 'dec2deg'})
export class DecimalDegreesToDegreesPipe implements PipeTransform {
  transform(value: number): string {
      // This is a happy place :)
      const decimalDegrees = value * Math.sign(value);

      const degrees = parseInt(decimalDegrees.toString(), 10);
      const minutes = parseInt(((decimalDegrees - degrees) * 60).toString(), 10);
      const seconds = parseInt(((decimalDegrees - (minutes / 60) - degrees) * 3600).toString(), 10);

      return `${degrees}° ${minutes}' ${seconds}"`;
  }

  format(value: number, line: 'lat' | 'lon'): string {
    let symbol: string;

    if (value) {
      if (value < 0 && line === 'lat') {
        symbol = ' S';
      } else if (value > 0 && line === 'lat') {
        symbol = ' N';
      } else if (value < 0 && line === 'lon') {
        symbol = ' W';
      } else {
        symbol = ' E';
      }
      return this.transform(value) + symbol;
    }
  }
}
