import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormBuilderField, FormBuilderKeyValue, BaseFormBuilderFieldComponent } from 'app/shared/components/form-builder';
import { get, set, debounce } from 'lodash';
import * as uuid from 'uuid';
import { IKuiDropdownMenuItem } from 'app/key-ui';
import { TranslateService } from '@ngx-translate/core';

interface RelationItem {
    ancestors: string[];
    children: string[];
    descendants: string[];
}

@Component({
    selector: 'key-form-builder-checklist-field',
    templateUrl: './checklist.component.html',
    styleUrls: ['./checklist.component.scss'],
})
export class KeyFormBuilderChecklistFieldComponent implements BaseFormBuilderFieldComponent, OnInit {

    @Input() field: FormBuilderField;
    @Input() values: { [key: string]: any };
    /** set this error value externally to have the default error highligh and display kick in */
    @Input() error: string;

    @Output() onChange: EventEmitter<{ value: { [key: string]: any }, dirty: boolean }> = new EventEmitter();

    originalFieldValues: FormBuilderKeyValue[];
    items: FormBuilderKeyValue[];
    relationDictionary: { [key: string]: RelationItem }; // a list to quickly check an item's ancestors and descendants
    uniqueId = uuid.v4(); // prevents different checklists from ticking the same keys
    multiSelectDropdownMenu: IKuiDropdownMenuItem[];

    hasSearch = false;
    searching = false;
    searchTerm: string;
    debouncedDoSearch = debounce(this.doSearch, 200, { trailing: true, leading: false });

    cascade = false;

    touched = false;
    get checkedCount(): number {
        return get(this.values, this.field.id, []).length;
    }

    get value(): string[] {
        const value = this.field.getValue ? this.field.getValue(this.field, this.values) : get(this.values, this.field.id, this.field.value || []);
        return Array.isArray(value) ? value : [value];
    }
    set value(val: string[]) {
        if (this.field.setValue) {
            const promise = this.field.setValue(this.field, this.values, val);
            if (promise && promise.then) {
                promise.then(() => {
                    // update items in case field.values was changed
                    this.items = this.field.values || [];
                    this.relationDictionary = this.getRelationDictionary(this.items);
                    this.ref.markForCheck();
                });
            }
        } else {
            set(this.values, this.field.id, val);
        }
    }

    constructor(
        private ref: ChangeDetectorRef,
        private i18n: TranslateService
    ) { }

    validate(): boolean {
        this.touched = true;
        this.ref.markForCheck();
        return !this.field.required || this.checkedCount > 0;
    }

    ngOnInit() {
        this.originalFieldValues = [...(this.field.values || [])];
        this.items = [...this.originalFieldValues];
        this.relationDictionary = this.getRelationDictionary(this.items);

        this.hasSearch = !!(this.field.options && this.field.options.checkListPromise);
        if (this.hasSearch) {
            this.field.description = this.i18n.instant('FORMS.CHECKLIST.DESCRIPTION');
        }

        this.cascade = !!(this.field.options && this.field.options.cascade);

        this.multiSelectDropdownMenu = [{
            type: 'radio',
            closeDropdownOnClicked: true,
            text: this.i18n.instant('FORMS.CHECKLIST.SELECT_ALL'),
            action: () => this.multiSelect(true),
        }, {
            type: 'radio',
            closeDropdownOnClicked: true,
            text: this.i18n.instant('FORMS.CHECKLIST.DESELECT_ALL'),
            action: () => this.multiSelect(false),
        }];
    }

    async doSearch(term: string) {
        this.searchTerm = term;
        this.searching = true;
        this.ref.markForCheck();

        try {
            if (term) {
                const values = await this.field.options.checkListPromise(term);
                // internally we use this.items now, but update the field.values so that setValue and getValue can have access to new results too
                values.forEach(value => {
                    if (!this.field.values.find(x => x.key === value.key)) {
                        this.field.values.push(value);
                    }
                });
                this.items = values;
            } else {
                this.field.values = [...this.originalFieldValues];
                this.items = [...this.originalFieldValues];
            }
            this.relationDictionary = this.getRelationDictionary(this.items);
        } catch (error) {
            this.items = []; // empty array will show no items found message
        } finally {
            this.searching = false;
            this.ref.markForCheck();
        }
    }

    checkItem(event: { srcElement: { checked?: boolean } }, id: string) {
        const checkTree = (keys: string[], vals: Set<string>) => {
            keys.forEach(x => {
                const relation = this.relationDictionary[x];

                if (relation.children.length) {
                    const all = relation.children.every(y => vals.has(y));
                    const some = relation.children.some(y => vals.has(y));
                    const deep = relation.descendants.some(y => vals.has(y));

                    const item = this.items.find(y => y.key === x);
                    if (item) {
                        item.readonly = (some || deep) ? 'indeterminate' : null;
                    }

                    if (all) {
                        vals.add(x);
                    } else {
                        vals.delete(x);
                    }

                    checkTree(relation.children, vals);
                }

            });
        };

        this.touched = true;
        const values = new Set<string>(this.value);
        const related = this.relationDictionary[id];
        const nested = related ? [...related.children, ...related.descendants] : [];
        if (event.srcElement['checked']) {
            values.add(id);
            nested.forEach(x => values.add(x)); // add all nested items too
        } else {
            values.delete(id);
            nested.forEach(x => values.delete(x)); // remove all nested items too
        }
        if (this.cascade) {
            checkTree([(related && related.ancestors[0]) || id], values);
        }
        this.value = [...values].filter(x => x);
        this.onChange.emit({ value: this.values, dirty: true });
    }

    multiSelect(checked: boolean) {
        this.items.forEach(item => {
            this.checkItem({ srcElement: { checked } }, item.key);
        });
    }

    isChecked(id: string): boolean {
        return (this.value || []).indexOf(id) !== -1;
    }

    getRelationDictionary(items: FormBuilderKeyValue[]): { [key: string]: RelationItem } {
        return items
            .map(x => ({ ...x, indent: x.indent || 0 }))
            .reduce((result, item, index, array) => {
                // first split items in two from the current item's index... Top half will have ancestors and bottom half descendants
                const top = array.slice(0, index);

                // find the closest anscestor for each indent level by moving from the bottom up
                const ancestorIndexes = new Array(item.indent).fill('').map((_, i) => i);
                const ancestors = ancestorIndexes.map(z => [...top].reverse().find(y => y.indent === z));

                const bottom = array.slice(index + 1, array.length);
                // bottomEnd is where the last descendant stop and a new sibling start
                const bottomEnd = bottom.findIndex(y => y.indent <= item.indent);

                result[item.key] = {
                    ancestors: ancestors.map(z => z.key),
                    children: (bottomEnd === -1 ? bottom : bottom.slice(0, bottomEnd)).filter(y => y.indent === item.indent + 1).map(y => y.key),
                    descendants: (bottomEnd === -1 ? bottom : bottom.slice(0, bottomEnd)).filter(y => y.indent !== item.indent + 1).map(y => y.key),
                };

                return result;
            }, {});
    }
}
