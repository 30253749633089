import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ContentHeaderAction, ContentHeaderTabItem, ContentHeaderTitle } from '../content-header/content-header.component';

export interface TopNavMobileNavigationAction {
    icon: 'menu' | 'back' | 'close';
    click: () => void;
}

export interface TopNavSettings {
    title?: string | ContentHeaderTitle;
    tabs?: ContentHeaderTabItem[];
    activeTab?: string;
    actions?: ContentHeaderAction[];
    navigationAction?: TopNavMobileNavigationAction;
}

export interface TopNavPageSettings extends TopNavSettings {
    page: string;
}

@Injectable()
export class TopNavService {

    private activePage: string;
    private settings: { [key: string]: TopNavSettings } = {};

    private settingsSubject = new BehaviorSubject<TopNavPageSettings>(null);
    settings$ = this.settingsSubject.asObservable();

    constructor() {
    }

    reset(page?: string) {
        this.update(page || this.activePage, {
            title: null,
            navigationAction: null,
            actions: null,
            activeTab: null,
            tabs: null,
        });
    }

    getSettings(page: string): TopNavSettings {
        return this.settings[page];
    }

    update(page: string, settings: TopNavSettings) {
        this.settings[page] = {
            ...this.settings[page],
            ...settings,
        };
        if (page === this.activePage) {
            this.activate(page); // emits
        }
    }

    activate(page: string) {
        this.activePage = page;
        this.settingsSubject.next({ page: page, ...this.settings[page] });
    }

}

