import { ChartOptions, ChartConfiguration, ChartDataSets } from 'chart.js';

export interface DatasetLabel {
    name: string;
    prefix?: string;
    suffix?: string;
}

export interface Label {
    value: string;
    tooltipTitle?: string;
    hideTooltipTitle?: boolean; // explicitly hide tooltip title
}

export interface DataPoint {
    raw: number;
    value: string;
}

export interface GraphPointSelection {
    datasetIndex: number;
    pointIndex: number;
}

export type DatasetType = 'line' | 'bar';
export interface Dataset {
    label: string | DatasetLabel;
    id?: string;
    type?: DatasetType;
    data: DataPoint[];
    fill?: boolean;
    color?: string;
    colors?: string[];
    curvedLines?: boolean;
    steppedLines?: boolean;
    yAxis?: 'left' | 'right';
    hidden?: boolean;
    uniquename?: string;
    borderDash?: number[];
}

export type LegendPlacement = 'onChart' | 'asLegend';
export type LegendPosition = 'left' | 'right' | 'bottom' | 'top';
export interface Legend {
    show?: boolean;
    placement?: LegendPlacement;
    position?: LegendPosition;
    overlay?: boolean;
}

export interface YAxis {
    hidden?: boolean;
    min?: number;
    max?: number;
    labelFn?: (value: number) => string | number;
}

export interface YAxes {
    left?: YAxis;
    right?: YAxis;
}

export interface XAxis {
    hidden?: boolean;
    autoLabels: boolean;
    format?: (value: string) => string;
}

export interface LegendItem {
    text: string;
    hidden: boolean;
    fillStyle: string;
    strokeStyle: string;
}

export enum AXIS_ID {
    Y_LEFT = 'left',
    Y_RIGHT = 'right',
    X = 'x-axis-0',
}

export interface Average {
    axis: AXIS_ID;
    value: number | string;
    color?: string;
    label?: string;
    borderWidth?: number;
    borderDash?: number[];
    mode?: 'horizontal' | 'vertical';
}

export interface ChartDataSetsWithPlugins extends ChartDataSets {
    datalabels?: {
        color: string;
        backgroundColor: string;
    };
}

export interface Annotation {
    type: string;
    mode: string;
    scaleID: string;
    value: number | string;
    borderDash?: number[];
    borderColor?: string;
    borderWidth?: number;
    id?: string;
    label: {
        enabled: boolean;
        content?: string;
        fontStyle?: string;
        backgroundColor?: string;
        fontSize?: number;
        cornerRadius?: number;
        position?: string;
        yPadding?: number;
        xPadding?: number;
    };
}

export interface DataLabelsFormatterContext {
    active: boolean;
    chart: Chart;
    dataIndex: number;
    dataset: any;
    datasetIndex: number;
}

export interface ChartOptionsWithPlugins extends ChartOptions {
    annotation?: {
        events?: string[];
        annotations: Annotation[];
    };
}

export interface ChartConfigurationWithPlugins extends ChartConfiguration {
    options?: ChartOptionsWithPlugins;
}
