<div class="tree-select">
    <tree-root #tree
        [(state)]="state"
        [nodes]="nodes"
        [options]="options"
        (activate)="selectedNodeChanged.emit($event)"
        (deactivate)="selectedNodeChanged.emit($event)">
        <ng-template #treeNodeTemplate
            let-node
            let-index="index">
            <kui-icon *ngIf="node.data.icon" [size]="1" [name]="node.data.icon" [fixedWidth]="true" class="mr-2"></kui-icon>        
            <span>{{ node.data.name }}</span>
        </ng-template>

        <ng-template #loadingTemplate>
            <kui-loader size="small"></kui-loader>
        </ng-template>
    </tree-root>
</div>