import * as moment from 'moment-timezone';
import { Component, HostBinding, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationFeedItem } from '../feed.model';
import { FeedEntryInfoIcon } from 'app/shared/components/feed/feed-entry-info/feed-entry-info.component';
import { FeedEntryAction } from 'app/shared/components/feed/feed-entry-actions/feed-entry-actions.component';
import { Router } from '@angular/router';
import { parseUserNotification } from './notification-feed.utils';

@Component({
    selector: 'key-notification-feed-item',
    templateUrl: 'notification-feed-item.component.html',
})
export class NotificationFeedItemComponent implements OnChanges {

    icons: FeedEntryInfoIcon[];
    icon: string;
    title: string;
    description: string;
    url: string;

    timeAgo: string;

    @Input() data: NotificationFeedItem;
    @Input() actions: FeedEntryAction[];
    @Output() primaryActionClicked: EventEmitter<any> = new EventEmitter();

    @HostBinding('class.feed-entry') feedEntryClass = true;

    constructor(
        public i18n: TranslateService,
        private router: Router
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data && this.data) {
            this.icons = [];
            this.timeAgo = moment.utc(this.data.date).fromNow();

            const details = parseUserNotification(this.i18n, this.data.type, this.data.data);
            this.title = details.title;
            this.description = details.description;
            this.icon = details.icon;
            this.url = details.url;
        }
    }

    onClicked() {
        if (this.url) {
            this.router.navigateByUrl(this.url);
        }
    }
    

}
