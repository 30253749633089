import { Injectable, OnDestroy } from '@angular/core';
import { AppService } from 'app/app.service';
import { AuthService, MeasurementUnitsService } from 'app/services';
import { EventFeedItem, FeedHistoryResult, FeedResult } from 'app/shared/components/feed/feed.model';
import { getTimeBuckets } from 'app/shared/utils/time-buckets';
import { EventResponse } from '@key-telematics/fleet-api-client';
import { FeedOriginType, FeedService } from '../feed.service';
import * as Bluebird from 'bluebird';
import { FeedsGetRecentEventFeedGQL } from 'app/shared/graphql';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { MediaService } from 'app/services/media/media.service';

@Injectable()
export class EventFeedService extends FeedService<EventFeedItem> implements OnDestroy {

    private subs: Subscription[];
    private parameters: any;

    constructor(
        app: AppService,
        public auth: AuthService,
        protected units: MeasurementUnitsService,
        private media: MediaService
    ) {
        super(app, auth);
        this.subs = [
            this.media.eventUpdated$.subscribe(event => {
                if (this.parameters && this.parameters.start) {
                    if (event.origin.id === this.parameters.originId && event.eventDate >= this.parameters.start && event.eventDate <= this.parameters.end) {
                        this.addItems(0, [this.mapEventResponse(event)]);
                    }
                }
            }),
        ];
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.subs.forEach(sub => sub.unsubscribe());
    }


    getFeedSubscription(clientId: string, originType: FeedOriginType, originId: string, limit: number): Observable<FeedResult<EventFeedItem>> {
        this.parameters = { clientId, originType, originId, limit, };
        return new FeedsGetRecentEventFeedGQL(this.app.api.gql).subscribe({
            client: clientId,
            asset: originType === 'asset' ? originId : undefined,
            limit: limit,
        }).pipe(map(result => {
            const items = result.data.getRecentEventFeed;
            return {
                count: items.length,
                sequence: [
                    !!items.length ? Math.min(...items.map(item => this.dateToSequence(item.modifiedDate))) : 0,
                    !!items.length ? Math.max(...items.map(item => this.dateToSequence(item.modifiedDate))) : 0,
                ],
                items: items.map(item => this.mapEventResponse(item as any)),
            };
        }));
    }

    getFeed(clientId: string, originType: FeedOriginType, originId: string, sequence: number, direction: 'forward' | 'backward', limit: number): Promise<FeedResult<EventFeedItem>> {
        this.parameters = { clientId, originType, originId, sequence, direction, limit, };
        return this.app.api.data.getEventFeed(clientId, sequence, direction, limit, originType === 'asset' ? originId : null)
            .then(result => ({
                count: result.count,
                sequence: [
                    result.sequence,
                    result.count !== 0 ? Math.max(...result.items.map(item => this.dateToSequence(item.modifiedDate))) : 0,
                ],
                items: result.items.map(item => this.mapEventResponse(item)),
            }));
    }

    protected getFeedHistory(_clientId: string, _originType: FeedOriginType, originId: string, start: string, end: string, limit: number): PromiseLike<FeedHistoryResult<EventFeedItem>> {
        return Bluebird.map(getTimeBuckets(start, end, 24 * 60 ** 2),
            (dates) => this.getSingleFeedHistory(_originType, originId, dates[0], dates[1], limit),
            { concurrency: 1, }
        ).then(events =>
            events.reduce((all, current) => ({
                count: (all.count || 0) + current.count,
                start: all.start ? all.start : current.start,
                end: current.end,
                limit: current.limit,
                items: (all.items || []).concat(current.items),
            } as FeedHistoryResult<EventFeedItem>), {
                count: 0,
                start: '',
                items: [],
            }) as FeedHistoryResult<EventFeedItem>
        );
    }

    protected getSingleFeedHistory(_originType: FeedOriginType, originId: string, start: string, end: string, limit: number): Promise<FeedHistoryResult<EventFeedItem>> {
        this.parameters = { originId, start, end, limit, };
        return this.app.api.data.getEventHistory(originId, start, end, limit, this.app.api.cacheFor(5)).then(result => {
            return {
                count: result.count,
                start: result.start,
                end: result.end,
                limit: result.limit,
                items: result.items.map(item => this.mapEventResponse(item)),
            };
        });
    }

    mapEventResponse(item: EventResponse): EventFeedItem {
        return {
            id: item.id,
            owner: item.owner,
            origin: item.origin,
            alerts: item.alerts,
            media: item.media,
            class: item.eventClass,
            type: item.eventType,
            details: item.details,
            handledBy: item.handledBy,
            comments: item.comments,
            section: {
                title: this.units.format(item.eventDate, 'date', 'long').format,
                date: item.eventDate,
            },
            date: item.eventDate,
        };
    }

    comment(id: string, values: any): Promise<EventFeedItem> {
        const comment = values.comment;
        delete values.comment;
        return this.app.api.data.commentAlert(this.app.client.id, id, {
            comment: comment,
            values: values,
        }).then(item => {
            this.addItems(0, [this.mapEventResponse(item)]);
            return this.mapEventResponse(item);
        });
    }

    acknowledge(id: string, values: any): Promise<EventFeedItem> {
        const comment = values.comment;
        delete values.comment;
        return this.app.api.data.acknowledgeAlert(this.app.client.id, id, {
            comment: comment,
            values: values,
        }).then(item => {
            this.addItems(0, [this.mapEventResponse(item)]);
            return this.mapEventResponse(item);
        });
    }
}
