import { NgModule } from '@angular/core';

import { KuiInputGroupBtnDirective } from './input-group-btn.directive';
import { KuiInputGroupAddonDirective } from './input-group-addon.directive';
import { KuiInputGroupComponent } from './input-group.component';

@NgModule({
  exports: [
      KuiInputGroupComponent,
      KuiInputGroupAddonDirective,
      KuiInputGroupBtnDirective,
  ],
  declarations: [
      KuiInputGroupComponent,
      KuiInputGroupAddonDirective,
      KuiInputGroupBtnDirective,
  ],
})
export class KuiInputGroupModule {}
