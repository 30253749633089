<div kui-action
    class="d-flex p-3"
    [class.text-muted]="disabled">

    <div *ngIf="color" class="key-listing-color-bar" [ngStyle]="{ 'background-color': color }">
    </div>

    <ng-content select="key-listing-avatar"></ng-content>

    <div class="flex-1">
        <div class="d-flex">
            <ng-content select="key-listing-title"></ng-content>
            <small class="ml-auto listing__stat pl-2">
                <kui-loader size="small"
                    *ngIf="loading"></kui-loader>
                <ng-container *ngIf="!loading">
                    <ng-content select="key-listing-stat"></ng-content>
                </ng-container>
            </small>
        </div>
        <ng-content select="key-listing-description"></ng-content>
        <ng-content></ng-content>
    </div>

</div>