import { Component, OnInit, ElementRef, Input, Renderer2 } from '@angular/core';

@Component({
    selector: 'key-layout-grid-columns',
    templateUrl: './layout-grid-columns.component.html',
})
export class LayoutGridColumnsComponent implements OnInit {
    @Input() direction: 'horizontal' | 'vertical' = 'horizontal';

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngOnInit() {
        const directionClasses: string[] = this.getDirectionClasses(this.direction);

        directionClasses.forEach(className => {
            this.renderer.addClass(this.el.nativeElement, className);
        });
    }

    getDirectionClasses(direction): string[] {
        const sharedClasses = ['d-flex', 'flex-stretch', 'h-100'];

        if (direction === 'horizontal') {
            return [...sharedClasses, 'flex-row'];
        }

        if (direction === 'vertical') {
            return [...sharedClasses, 'flex-column'];
        }
    }
}
