import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KuiTooltipComponent } from './tooltip.component';
import { KuiTooltipDirective } from './tooltip.directive';
import { MatchMediaServiceModule } from 'app/services/match-media/match-media.module';

const KUI_TOOLTIP_MODULES = [
  KuiTooltipComponent,
  KuiTooltipDirective,
];

@NgModule({
    imports: [CommonModule, MatchMediaServiceModule],
    exports: KUI_TOOLTIP_MODULES,
    declarations: KUI_TOOLTIP_MODULES
})
export class KuiTooltipModule {}
