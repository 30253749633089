<input kui-input="{{ field.id }}"
    type="number"
    placeholder="{{ field.placeholder }}"
    key-number-validator
    [min]="field.min"
    [max]="field.max"
    [step]="step"
    [ngModel]="value"
    (ngModelChange)="changeNumber($event)"
    [required]="field.required"
    [disabled]="field.readonly"
    #numberField="ngModel"
    [class.border-danger]="error || (numberField.invalid && (numberField.dirty || numberField.touched))">

<div *ngIf="numberField.invalid && (numberField.dirty || numberField.touched)"
    class="invalid-feedback d-block">
    <div *ngIf="numberField.errors['required']">{{ 'FORMS.SHARED.REQUIRED' | translate:{ field: field.title } }}</div>
    <div *ngIf="numberField.errors['min']">{{ 'FORMS.NUMBER.MIN' | translate:{ field: field.title, min: field.min } }}</div>
    <div *ngIf="numberField.errors['max']">{{ 'FORMS.NUMBER.MAX' | translate:{ field: field.title, max: field.max } }}</div>
</div>
<div *ngIf="error"
    class="invalid-feedback d-block">
    <div>{{ error }}</div>
</div>