import { Component, Output, EventEmitter, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilderDefinition, FormBuilderFieldChangeEvent, FormBuilderField } from 'app/shared/components/form-builder';
import { IKuiSelectOption } from 'app/key-ui';
import { ChartSeriesItem, MeasureDescription, TargetLine } from '@key-telematics/fleet-api-client';
import { toTitleCase, removeSpaces } from 'app/shared/utils/string.utils';
import { ListFieldValue } from 'app/shared/components/form-builder/list/list.component';
import { ModalService } from 'app/shared/components/modal';
import * as uuid from 'uuid';

export interface SeriesValuesSelected {
    values: { key: string, value: string, color?: string }[];
    selected?: string;
    selectedMeasureDesc?: MeasureDescription;
}

export interface SeriesSettings extends ChartSeriesItem {
    selection?: SeriesValuesSelected;
}

export interface TargetLineItem extends TargetLine {
    label?: string;
    axis?: 'left' | 'right';
    selectedMeasureFormat?: string;
}

interface TargetLineListFieldValue extends ListFieldValue<any> {
    value: number;
    showLabel?: boolean;
    type?: string;
}

@Component({
    selector: 'key-analytics-settings-series',
    templateUrl: './series.component.html',
    styleUrls: ['./series.component.scss'],
})
export class AnalyticsSettingsSeriesComponent implements OnInit, OnChanges {
    selectionDirty: boolean;
    selectionValues: IKuiSelectOption[];
    selectedSeriesColor: string;
    form: FormBuilderDefinition;
    values: { [key: string]: any };
    lineLimit = 3;
    selectedMeasureFormat: string;
    @Input() options: SeriesSettings;
    @Input() overriddenValues: string[];
    @Output() onChange = new EventEmitter<{ [key: string]: any; }>();
    @Output() onClear = new EventEmitter<string[]>();
    @Output() onRemove = new EventEmitter<string>();

    constructor(
        public i18n: TranslateService,
        public modal: ModalService
    ) {
    }

    ngOnInit() {
        this.updateForm('default');
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.options && this.options) {
            // extract selection only and assign what's left to this.values
            const { selection, ...values } = this.options;

            if (selection && !selection.selected) {
                this.options.selection.selected = 'default';
            }

            this.selectionDirty = false;
            this.selectionValues = selection && selection.values.map(({ key, value }) => ({
                id: key,
                value: toTitleCase(value),
                selected: selection.selected === key,
            }));

            // we only need to update the form when selection has changed... So we compare prev and current selected values
            const prev = changes.options.previousValue && changes.options.previousValue.selection;
            const cur = changes.options.currentValue.selection;
            if (prev && prev.selected !== cur.selected) {
                this.selectedMeasureFormat = cur.selectedMeasureDesc?.format;
                this.updateForm(selection.selected);
            }

            this.selectedSeriesColor = this.getSeriesColor(selection.selected);
            setTimeout(() => {
                this.selectionDirty = true;
            });
            
            this.values = values;
        }
    }

    changeSelection(selectedOption: IKuiSelectOption[] | string) {
        if (this.selectionDirty) {
            // type string means we are trying to trigger change outside of select component
            const selected = typeof selectedOption === 'string' ? removeSpaces(selectedOption.toLowerCase()) : selectedOption[0].id as string;

            this.selectedSeriesColor = this.getSeriesColor(selected);
            this.onChange.emit({
                selection: {
                    ...this.options.selection,
                    selected,
                },
            });
            this.updateForm(selected);
        }
    }

    changeFormSetting(change: FormBuilderFieldChangeEvent) {
        if (change.dirty) {
            const { id } = change.field;
            if (id === 'yAxis' && change.values.targetLines && change.values.targetLines.length !== 0) {
                change.values.targetLines.map(line => line.axis = change.values.yAxis);
            }
            this.onChange.emit({
                [id]: change.values[id],
            });
        }
    }

    removeSeries(value: string) {
        const measure = this.getCurrentOverriddenText(value);
        this.onRemove.emit(measure);
        this.resetSeries(value);
    }

    resetSeries(value: string) {
        this.onClear.emit([value]);
        this.changeSelection('default');
    }

    updateForm(name: string) {
        // TODO: need to add support for horizontal bar charts that works nice with other chart types
        const fields: FormBuilderField[] = [
            {
                type: 'combo', id: 'type', title: 'TYPE', values: [
                    { key: 'bar', value: this.i18n.instant('ANALYTICS.SETTINGS.BAR') },
                    {
                        key: 'line', value: this.i18n.instant('ANALYTICS.SETTINGS.LINE'), fields: [
                            { type: 'toggle', id: 'fill', title: 'AREA_FILL' },
                        ],
                    },
                ],
            },
            {
                type: 'radio', id: 'yAxis', title: 'Y_AXIS', values: [
                    { key: 'left', value: this.i18n.instant('ANALYTICS.SETTINGS.LEFT') },
                    { key: 'right', value: this.i18n.instant('ANALYTICS.SETTINGS.RIGHT') },
                ],
            },
        ];

        if (name !== 'default') {
            fields.push(
                { type: 'toggle', id: 'visible', title: 'VISIBLE' },
                { type: 'toggle', id: 'average', title: 'SHOW_AVERAGE' },
                // TODO: color should probaly be a color pallette with some sort of ability to
                // change swatches seperately by clicking on a dataset of the chart or something like that
                { type: 'color', id: 'color', title: 'COLOR' }
            );
            if (this.selectedMeasureFormat) {
                fields.push(
                    {
                        id: 'targetLines', title: 'TARGET_LINES', type: 'list', required: false,
                        actions: [
                            { id: 'add', name: this.i18n.instant('SHARED.ADD'), click: this.showAddTargetLineModal.bind(this) },
                            { id: 'edit', click: this.showAddTargetLineModal.bind(this), },
                        ],
                        getText: (field: FormBuilderField, values: any) => values[field.id] && values[field.id].map(x => x.name).join(', '),
                    }
                );
            }
        }

        this.form = { 'groups': [{ fields }] };
    }

    getSeriesColor(selected: string): string {
        const value = this.options.selection.values.find(x => x.key === selected);
        return this.options.color || value && value.color;
    }

    getCurrentOverriddenText(selected: string): string {
        return this.overriddenValues.find(x => removeSpaces(x.toLowerCase()) === selected);
    }

    showReset(selected: string): boolean {
        return !!(this.overriddenValues || []).find(x => removeSpaces(x.toLowerCase()) === selected);
    }

    getFields(targetLineItem: TargetLineListFieldValue): FormBuilderField[] {
        const timeValue = { hours: null, minutes: null, seconds: null };

        const fields: FormBuilderField[] = [
            {
                id: 'name', type: 'text', title: this.i18n.instant('ANALYTICS.SETTINGS.NAME'), placeholder: 'Enter line name', required: true,
            },
        ];

        if (this.selectedMeasureFormat === 'duration') {
            timeValue.hours = targetLineItem?.value > 0 ? Math.floor(targetLineItem.value / 60 / 60) : 0;
            timeValue.minutes = targetLineItem?.value > 0 ? Math.floor((targetLineItem.value - (timeValue.hours * 60 * 60)) / 60) : 0;
            timeValue.seconds = targetLineItem?.value > 0 ? targetLineItem.value - (timeValue.hours * 60 * 60) - (timeValue.minutes * 60) : 0;

            fields.push({ id: 'hours', type: 'number', title: this.i18n.instant('ANALYTICS.SETTINGS.HOURS'), decimals: 0, min: 0, max: null, value: timeValue.hours, required: true });
            fields.push({ id: 'minutes', type: 'number', title: this.i18n.instant('ANALYTICS.SETTINGS.MINUTES'), decimals: 0, min: 0, max: 60, value: timeValue.minutes, required: true });
            fields.push({ id: 'seconds', type: 'number', title: this.i18n.instant('ANALYTICS.SETTINGS.SECONDS'), decimals: 0, min: 0, max: 60, value: timeValue.seconds, required: true });
        } else {
            fields.push(
                {
                    id: 'value', type: 'number', title: this.i18n.instant('ANALYTICS.SETTINGS.VALUE'),
                    decimals: this.selectedMeasureFormat === 'number' ? 2 : 0,
                    min: this.selectedMeasureFormat === 'percent' ? 0 : null,
                    max: this.selectedMeasureFormat === 'percent' ? 100 : null,
                }
            );
        }

        fields.push({ type: 'toggle', id: 'showLabel', title: this.i18n.instant('ANALYTICS.SETTINGS.LABEL') });
        fields.push({
            type: 'combo', id: 'type', title: this.i18n.instant('ANALYTICS.SETTINGS.TYPE'), values: ['dashed', 'solid'].map((type) => ({
                key: type, value: this.i18n.instant('ANALYTICS.SETTINGS.' + type.toUpperCase()),
            })),
        });
        fields.push({ type: 'color', id: 'color', title: this.i18n.instant('ANALYTICS.SETTINGS.COLOR'), value: this.options.color || '#fff' });

        return fields;
    }

    async showAddTargetLineModal(_action: string, _field: FormBuilderField, values: SeriesSettings, item: TargetLineListFieldValue): Promise<TargetLineListFieldValue> {
        values.targetLines = values.targetLines || [];

        if (this.options.targetLines?.length !== this.lineLimit || item) {
            return this.modal.form({
                groups: [{
                    name: this.i18n.instant('ANALYTICS.SETTINGS.TARGET_LINE'),
                    fields: this.getFields(item),
                }],
            }, (item) || {
                name: '',
                value: 0,
                showLabel: false,
                type: '',
                color: '',
                axis: '',
            }).then(result => {
                if (result) {
                    this.updateOrAddItem(values.targetLines, item, result);
                }
                return result;
            });
        } else {
            this.modal.alert(this.i18n.instant('ANALYTICS.SETTINGS.LIMIT_REACHED.DESCRIPTION', { max: this.lineLimit }), this.i18n.instant('ANALYTICS.SETTINGS.LIMIT_REACHED.TITLE'));
        }

    }

    lineItemToListItem(item: any): TargetLineItem {
        const value = this.selectedMeasureFormat === 'duration' ? (item.hours * 3600) + (item.minutes * 60) + item.seconds : item.value;

        return {
            id: item.id || uuid.v4(),
            name: item.name,
            value: value,
            showLabel: item.showLabel,
            label: item.showLabel ? item.name : null,
            color: item.color,
            axis: this.options.yAxis === 'left' ? 'left' : 'right',
            type: item.type,
            borderWidth: 3,
            borderDash: item.type === 'solid' ? [] : null,
            selectedMeasureFormat: this.selectedMeasureFormat,
        };
    }

    updateOrAddItem(items: ListFieldValue<TargetLine>[], existingItem: ListFieldValue<TargetLine>, item: ListFieldValue<TargetLine>) {
        if (existingItem) {
            const idx = items.findIndex(x => x.id === existingItem.id);
            items[idx] = this.lineItemToListItem(item);
        } else {
            items.push(this.lineItemToListItem(item));
        }
    }
}
