import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { KuiModule } from 'app/key-ui';

import { LayoutGridColumnsComponent } from './layout-grid-columns.component';
import { LayoutGridColumnComponent } from './layout-grid-column.component';
import { LayoutGridColumnResizerComponent } from './layout-grid-column-resizer.component';
import { LayoutGridColumnInnerDirective } from './layout-grid-column-inner.directive';
import { LayoutGridColumnService } from './layout-grid-column.service';

const COMPONENTS = [
    LayoutGridColumnsComponent,
    LayoutGridColumnComponent,
    LayoutGridColumnResizerComponent,
    LayoutGridColumnInnerDirective,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        KuiModule,
    ],
    exports: COMPONENTS,
    declarations: COMPONENTS,
    providers: [ LayoutGridColumnService ],
})
export class KeyLayoutGridModule { }
