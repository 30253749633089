import { EventEmitter, Injectable, NgZone } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { InMemoryCache } from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { AccountsClient, AuthClient, BaseClient, DataClient, EntitiesClient, HealthClient, MapClient, MediaClient, ReportsClient, RequestCache, RequestOptions, SearchClient, StatsClient, TasksClient } from '@key-telematics/fleet-api-client';
import { Throttle } from '@key-telematics/fleet-api-client/lib/throttle';
import { Apollo } from 'apollo-angular';
import * as moment from 'moment';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import * as TinyCache from 'tinycache';

export interface EnvironmentVariables {
    apiEndpoint: string;
}

export class TinyRequestCache implements RequestCache {

    private cache = new TinyCache();

    async get<T>(key: string): Promise<T> {
        return this.cache.get(key);
    }

    async put<T>(key: string, timeout: number, value: T): Promise<void> {
        this.cache.put(key, value, timeout * 1000);
    }

}



/** The ApiService wraps and configures the Fleet API clients. */
@Injectable()
export class ApiService {


    endpoint: string;
    accessToken: string;

    private requestCache = new TinyRequestCache();

    throttle: Throttle = new Throttle([
        {
            requestsPerSecond: 5,
            concurrency: 5,
        },
    ])
    modifyThrottle: Throttle = new Throttle([
        {
            requestsPerSecond: 1,
            concurrency: 5,
            methods: ['POST', 'PUT', 'DELETE'],
        },
        {
            requestsPerSecond: 5,
            concurrency: 5,
        },
    ])

    auth = new AuthClient({ throttle: this.modifyThrottle });
    accounts = new AccountsClient({ throttle: this.modifyThrottle });
    entities = new EntitiesClient({ throttle: this.modifyThrottle });
    tasks = new TasksClient({ throttle: this.throttle });
    reports = new ReportsClient({ throttle: this.throttle });
    data = new DataClient({ throttle: this.throttle });
    search = new SearchClient({ throttle: this.throttle });
    map = new MapClient({ throttle: this.throttle });
    health = new HealthClient({ throttle: this.throttle });
    stats = new StatsClient({ throttle: this.throttle });
    media = new MediaClient({ throttle: this.throttle });

    gql: Apollo;
    gqlEnabled = true;

    websocketConnected: boolean;

    private websocketClient: SubscriptionClient;

    onTokenExpired: EventEmitter<Error> = new EventEmitter();
    onTokenRefreshed: EventEmitter<string> = new EventEmitter();

    constructor(private zone: NgZone, public http: HttpClient) {
        this.clients().forEach(service => {
            // Enable below if you want to log all api requests
            // service.logger = {
            //     log: (line: string) => {
            //         console.log(line);
            //     },
            // };
            service._addErrorHandler(this.apiErrorHandler.bind(this));
        });
    }

    private clients(): BaseClient[] {
        return [this.auth, this.accounts, this.entities, this.data, this.reports, this.search, this.tasks, this.media, this.map, this.health, this.stats];
    }

    private apiErrorHandler(err: any, req: any) {
        if (err && err.name) {
            console.warn(req && req.req ? req.req.url : null, err);
        }
        if (err && err.name && ['TokenExpiredError', 'OTPRequiredError'].includes(err.name)) {
            this.onTokenExpired.emit(err);
        }
    }

    get isStagingEndpoint(): boolean {
        return this.endpoint && (
            this.endpoint.includes('127.0.0.1') ||
            this.endpoint.includes('localhost') ||
            this.endpoint.includes('staging')
        );
    }

    setEndpoint(endpoint: string) {
        this.endpoint = endpoint;
        this.clients().forEach(client => {
            client.url = endpoint;
        });
    }

    setAccessToken(token: string, refresh: boolean = false) {
        this.accessToken = token;
        this.clients().forEach(client => {
            client.setAccessToken(token);
        });
        this.onTokenRefreshed.emit(token);

        if (this.gqlEnabled && this.endpoint && !refresh) { // do not recreate the websocket on token refreshes!
            this.gql = this.createApolloClient();
        }
    }

    createApolloClient(): Apollo {

        if (this.websocketClient) {
            this.websocketClient.unsubscribeAll();
            this.websocketClient.close(true, true);
            this.websocketClient = null;
            this.websocketConnected = false;
        }

        const debug = (...args) => {
            if (this.endpoint.includes('staging') || this.endpoint.includes('localhost')) {
                console.log(`[${new Date().toISOString()}] WEBSOCKET`, ...args);
            }
        };

        let connectTime;

        this.websocketClient = new SubscriptionClient(this.endpoint.replace('http://', 'ws://').replace('https://', 'wss://') + '/gql', {
            connectionParams: () => ({
                'x-access-token': this.accessToken,
            }),
            reconnect: true,
            timeout: 60000,
            inactivityTimeout: 0, // disable
            lazy: false,
            reconnectionAttempts: 10000,
        });
        this.websocketClient.on('connected', (...args) => {
            debug('Connected', ...args);
            this.websocketConnected = true;
            connectTime = moment();
        });
        this.websocketClient.on('reconnected', (...args) => {
            debug('Reconnected', ...args);
            this.websocketConnected = true;
            connectTime = moment();
        });
        this.websocketClient.on('disconnected', (...args) => {
            const time = moment().diff(connectTime, 'seconds');
            debug('Disconnected', time, ...args);
            this.websocketConnected = false;
        });
        this.websocketClient.on('error', (...args) => {
            debug('Error', ...args);
        });


        const ws = new WebSocketLink(this.websocketClient);

        // if we ever want to do HTTP graphql, here's the needed link
        // gqlHeaders: HttpHeaders = new HttpHeaders();
        // this.gqlHeaders = this.gqlHeaders.set('x-access-token', token);
        // const http = new HttpLink(this.http).create({
        //     headers: this.gqlHeaders,
        //     uri: this.endpoint + '/gql',
        // });

        return new Apollo(this.zone, {
            cache: new InMemoryCache(),
            link: ws,
        });

    }

    cacheFor(minutes: number): RequestOptions {
        return {
            cache: this.requestCache,
            cacheTimeout: minutes * 60,
        };
    }


}

/** This will return null if the error is an Access Denied error */
export function nullAccessDenied(error: Error) {
    if (error.name === 'ForbiddenError') {
        return Promise.resolve(null);
    }
    throw error;
}
