<ng-content></ng-content>
<div class="footer-actions">
    <button *ngFor="let action of actions"
        kui-button
        [tabIndex]="(action.keypress || '').toString() === '27' ? -1 : undefined"
        [color]="action.style || 'primary'"
        (click)="!action.disabled && buttonClicked.emit(action)"
        [class.disabled]="action.disabled">
        <kui-icon *ngIf="action.icon" [name]="action.icon" size="sm" [class.d-sm-none]="action.hideIconOnDesktop" [class.mr-2]="!action.hideTextOnMobile"></kui-icon>
        <span *ngIf="action.text" [class.d-none]="action.hideTextOnMobile" class="d-sm-inline-block">{{action.text}} </span>
    </button>
</div>