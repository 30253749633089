import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class SimpleReuseStrategy implements RouteReuseStrategy {

    handles: { [key: string]: DetachedRouteHandle } = {};

    getKey(route: ActivatedRouteSnapshot): string {
        return route.data && route.data.reuse; 
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return !!(route.data && route.data.reuse);
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        if (route.data && route.data.reuse) {
            try {
                const key = this.getKey(route);
                if (!handle && this.handles[key]) {
                    // the router is clearing a previously stored route because it was successfully reattached
                    const componentRef = this.handles[key]['componentRef'];
                    if (componentRef && componentRef.instance && componentRef.instance.ngOnAttach) {
                        try {
                            componentRef.instance.ngOnAttach();
                        } catch (err) { 
                            console.error('ngOnAttach', err);
                        }
                    }
                    delete this.handles[key];
                } else {
                    // the router is storing a route because it's about to be detached
                    this.handles[key] = handle;
                    const componentRef = handle['componentRef'];
                    if (componentRef && componentRef.instance && componentRef.instance.ngOnDetach) {
                        try {
                            componentRef.instance.ngOnDetach();
                        } catch (err) { 
                            console.error('ngOnDetach', err);
                        }
                    }
                }
            } catch (err) {
                console.error(err);
            }
        }
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return !!(route.data && route.data.reuse && route.routeConfig && this.handles[this.getKey(route)]);
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        if (route.data && route.data.reuse) {
            return this.handles[this.getKey(route)];
        }
        return null;
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return curr.routeConfig === future.routeConfig;
    }

}


