import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { KuiModule } from 'app/key-ui';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { ExternalTabsComponent } from './external.component';
import { ExternalTabComponent } from './external-tab.component';

@NgModule({
    imports: [
        RouterModule.forChild([

            { path: '', redirectTo: 'first', pathMatch: 'full' },
            { path: ':tab', component: ExternalTabsComponent, data: { reuse: 'ExternalTabsComponent' } },
            { path: '**', component: ExternalTabsComponent, data: { reuse: 'ExternalTabsComponent' } },

        ]),
        CommonModule,
        KuiModule,
        SharedModule,
    ],
    declarations: [
        ExternalTabsComponent,
        ExternalTabComponent,
    ],
    exports: [
        ExternalTabsComponent,
        ExternalTabComponent,
    ],
    providers: [
    ],
})
export class ExternalPageModule { }
