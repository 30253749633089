import { Injectable } from '@angular/core';
import { IKuiTableCol } from '.';
import { get } from 'lodash';

@Injectable()
export class KuiTableService {

    constructor() { }

    // adapted the code from https://github.com/primefaces/primeng/blob/master/src/app/components/table/table.ts to work with objects as row items
    exportCSV(cols: IKuiTableCol[], data: any[], filename = 'download') {
        let csv = '\ufeff';

        // headers
        cols.forEach((column, i) => {
            if (column.field) {
                csv += '"' + (column.display || column.field) + '"';

                if (i < (cols.length - 1)) {
                    csv += ',';
                }
            }
        });

        // body
        const getValue = (obj: any, column: IKuiTableCol): any => {
            let field = column.format ? column.format(column, obj, true) : get(obj, column.field);

            // if field is not a primitive try get some common key values or just return undefined if all else fails
            if (field && typeof field === 'object') {
                if (field.value != null) {
                    field = field.value;
                } else if (field.name != null) {
                    field = field.name;
                } else if (field.text != null) {
                    field = field.text;
                }
            }
            return field;
        };

        data.forEach(record => {
            csv += '\n';

            cols.forEach((column, i) => {
                if (column.field) {
                    let cellData = getValue(record, column);

                    if (cellData !== null && cellData !== undefined) {
                        cellData = String(cellData).replace(/"/g, '""');
                    } else {
                        cellData = '';
                    }

                    csv += '"' + cellData + '"';

                    if (i < (cols.length - 1)) {
                        csv += ',';
                    }
                }
            });
        });

        const blob = new Blob([csv], {
            type: 'text/csv;charset=utf-8;',
        });

        const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link);
        if (link.download !== undefined) {
            link.setAttribute('href', URL.createObjectURL(blob));
            link.setAttribute('download', filename + '.csv');
            link.click();
        } else {
            csv = 'data:text/csv;charset=utf-8,' + csv;
            window.open(encodeURI(csv));
        }
        document.body.removeChild(link);
    }
}
