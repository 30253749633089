import { Component, Input } from '@angular/core';

@Component({
    selector: 'kui-input-group',
    templateUrl: './input-group.component.html',
    styleUrls: ['./input-group.component.scss'],
})
export class KuiInputGroupComponent {
    // TODO: make this true by defaults once all the kinks got ironed out
    @Input() cleanAddons: boolean; // if true addons will look like it is inside the inputs... i.e PRETTY
}
