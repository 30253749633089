import { Pipe, PipeTransform } from '@angular/core';
import { removeSpaces } from 'app/shared/utils/string.utils';

// a pipe that wraps the removeSpaces util fn for templates use
@Pipe({name: 'removeSpaces'})
export class RemoveSpacesPipe implements PipeTransform {
  transform(str: string): string {
    return removeSpaces(str);
  }
}
