import { Component, Input, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilderField, BaseFormBuilderFieldComponent } from 'app/shared/components/form-builder';
import { NgModel } from '@angular/forms';

@Component({
    selector: 'key-form-builder-memo-field',
    templateUrl: './memo.component.html',
})
export class KeyFormBuilderMemoFieldComponent implements BaseFormBuilderFieldComponent, OnInit {

    @Input() field: FormBuilderField;
    @Input() values: { [key: string]: any };
    @Input() error: string; // set this error value externally to have the default error highligh and display kick in

    rows = 3;

    @Output() onChange: EventEmitter<{ value: string, dirty: boolean }> = new EventEmitter();
    @ViewChild('textareaField', { static: true }) textArea: NgModel;

    validate(): boolean {
        this.textArea.control.markAsTouched();
        return !this.textArea.invalid;
    }

    ngOnInit() {
        if (this.field.value) {
            this.values[this.field.id] = this.values[this.field.id] || this.field.value;
        }
        this.rows = parseFloat(this.field?.options?.size || '3');
    }

    changeValue(value: string) {
        this.values[this.field.id] = value;
        this.onChange.emit({ value, dirty: this.textArea.dirty });
    }
}
