import { NgModule } from '@angular/core';
import { KuiLoaderComponent } from './loader.component';
import { CommonModule } from '@angular/common';

/** This module is here only because it is required to import it into other shared components that have modules declared */
@NgModule({
  imports: [
      CommonModule,
  ],
  exports: [KuiLoaderComponent],
  declarations: [
    KuiLoaderComponent,
  ],
})
export class KuiLoaderModule { }
