import { Component, EventEmitter, HostBinding, Input, OnChanges, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ErrorLoggerService } from 'app/services';
import { FeedEntryAction } from 'app/shared/components/feed/feed-entry-actions/feed-entry-actions.component';
import { FeedEntryInfoIcon } from 'app/shared/components/feed/feed-entry-info/feed-entry-info.component';
import { EventFeedItem } from 'app/shared/components/feed/feed.model';
import { GetKeyPipe } from 'app/shared/pipes/getkey.pipe';
import * as moment from 'moment-timezone';
import { getAndTranslateEventText } from './event-translation-dictionary';
import { MeasurementUnitsService } from 'app/services/measurement-units/measurement-units.service';
import { MediaService } from 'app/services/media/media.service';
import { ModalService } from '../../modal';
import { showMediaViewerModal } from '../../media/viewer-modal/viewer-modal.component';
import { AppService } from 'app/app.service';

export interface Alert {
    id: string;
    name?: string;
    type?: string;
    err?: any;
}

@Component({
    selector: 'key-event-feed-item',
    templateUrl: 'event-feed-item.component.html',
    styleUrls: ['./event-feed-item.component.scss'],
})
export class EventFeedItemComponent implements OnChanges {
    icons: FeedEntryInfoIcon[] = [];
    indicators: string[];
    title: string;
    description: string;
    handledBy: string;
    handledDate: moment.Moment;
    baseTranslationString = 'SHARED.FEED_ENTRY.EVENT';
    thumbnailUrls: string[];
    values: { title: string, value: string }[] = [];

    layout = 'default';
    classNames: string[] = [];

    @Input() data: EventFeedItem;
    @Input() showSubtext = true;
    @Input() titleType: 'event' | 'alert' = 'event';
    @Input() actions: FeedEntryAction[];

    @Output() primaryActionClicked: EventEmitter<any> = new EventEmitter();

    @HostBinding('class.muted') muted = false;

    constructor(
        private app: AppService,
        private i18n: TranslateService,
        public getKey: GetKeyPipe,
        private media: MediaService,
        private error: ErrorLoggerService,
        private units: MeasurementUnitsService,
        private modal: ModalService
    ) {
        if (this.app?.features?.page?.overview?.layout === 'basic') {
            this.layout = 'basic';
            this.classNames.push('layout-right');
        }
     }

    ngOnChanges() {
        if (this.data) {
            this.icons = [];
            if (this.data.alerts && this.data.alerts.length > 0) {
                const priorities = this.getAlertPriorityIcons(this.data.alerts);
                this.icons = priorities;
                this.indicators = priorities.map(x => x.color);
            }
            if (this.data.handledBy) {
                this.handledBy = this.data.handledBy.user.name;
                this.handledDate = moment.utc(this.data.handledBy.date);
            }
            this.muted = !!this.data.handledBy && this.showSubtext;

            const mediaHost = this.media.getMediaHost();
            this.thumbnailUrls = null;
            if (this.data.class === 'camerainputevent' && this.data.details && this.data.details.attachments) {
                switch (this.data.type) {
                    case 'photo':
                        const ownerId = this.data.owner.id;
                        const assetId = this.data.details.asset && this.data.details.asset.id;
                        const date = moment(this.data.date).format('YYYYMMDD');
                        this.thumbnailUrls = this.data.details.attachments.map(attachment => `${mediaHost}/media/image?filename=${ownerId}/${assetId}/${date}/${attachment.filename}`);
                        break;
                    case 'video':
                        this.thumbnailUrls = [this.media.getLegacyThumbnailsUrl(this.data)];
                        break;
                    default:
                        break;
                }
            }

            if (this.data.details && this.data.details.asset && this.data.media && this.data.media.length > 0) {
                const media = this.data.media.find(x =>
                    x.mediaType.includes('video') &&
                    !x.filename.endsWith('.jpg') // Fixed a bug that was in the backend that would confuse pictures and videos. Added here to fix old images.
                );
                if (media) {
                    this.thumbnailUrls = [this.media.getThumbnailsUrl(this.data.details.asset.id, media)];
                }
            }

            switch (this.titleType) {
                case 'alert':
                    this.title = this.data.alerts.filter(x => x.name).map(x => x.name).join(' / ');
                    break;
                default:
                    this.title = this.getTranslatedText(this.data, 'title');
                    break;
            }
            this.description = this.getTranslatedText(this.data, 'description');


            const customFields = this.app.client?.customFields?.event || [];
            const rawValues = {};
            this.data.comments.forEach(item => {
                Object.keys(item.values || {}).map(key => {
                    rawValues[key] = item.values[key];
                });
            });
            this.values = Object.keys(rawValues).map(key => ({
                title: customFields.find(x => x.id === key)?.title || key,
                value: rawValues[key],
            }));

        }
    }

    getDisplayDate(date: string): string {
        return date ? moment.utc(date).fromNow() : '';
    }

    getTranslatedText(event: EventFeedItem, textSection: 'title' | 'description' = 'title'): string {
        return getAndTranslateEventText(textSection, event, this.i18n, this.units);
    }

    getAlertPriorityIcons(alerts: Array<Alert>): FeedEntryInfoIcon[] {
        const type = {
            low: { name: 'info-circle', color: 'success' },
            medium: { name: 'exclamation-circle', color: 'warning' },
            high: { name: 'exclamation-triangle', color: 'danger' },
        };

        return alerts.filter(x => !x.err).reduce((all: FeedEntryInfoIcon[], cur: Alert) => {

            if (cur.err) {
                this.error.trackException(new Error(cur.err.message || cur.err));
                return all;
            }
            
            if (cur.type) { // when an alert is deleted, it may still appear in this list, but the type & name will be null

                // check if alert is already in the array and update it else add it to the list
                const index = all.findIndex(x => x.name === type[cur.type].name);

                if (index >= 0) {
                    const item = all[index];
                    item.tooltip = `${item.tooltip}, ${cur.name}`;
                    item.notification++;
                } else {
                    all.push({
                        id: cur.type,
                        name: type[cur.type].name,
                        notification: 1,
                        tooltip: cur.name,
                        color: type[cur.type].color,
                    });
                }

            }
            return all;
        }, []);
    }

    onThumbnailClick(event: Event) {
        event.stopPropagation(); // don't select items if we've clicked on a thumbnail
        showMediaViewerModal(this.modal, this.data);
    }
}

