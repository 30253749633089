import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { DashboardResponse, DashboardListItem, DashboardWidget, DashboardTemplateResponse, DashboardTemplateListItem } from '@key-telematics/fleet-api-client';
import * as Queue from 'promise-queue';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'app/shared/components/modal/modal.service';
import { cloneDeep } from 'lodash';
import { NotificationService } from '../notification/notification.service';
import { FormBuilderDefinition } from 'app/shared/components/form-builder';


@Injectable()
export class DashboardsService {
    clientId: string;
    dashboardCache = new Map<string, DashboardResponse>();
    dashboardTemplateCache = new Map<string, DashboardTemplateResponse>();
    widgetsQueue: Queue = new Queue(1, Infinity);


    constructor(
        public app: AppService,
        private router: Router,
        private i18n: TranslateService,
        public modal: ModalService,
        private notify: NotificationService
    ) {
        this.app.client$.subscribe(client => {
            if (client && this.clientId !== client.id) {
                this.clientId = client.id;
            }
        });
    }

    openDashboard(id: string, useCache: boolean = true) {
        if (!useCache) {
            this.dashboardCache.delete(id);
        }
        this.router.navigate(['dashboards', id]);
    }

    getDashboard(id: string, bypassCache?: boolean): Promise<DashboardResponse> {
        if (!bypassCache && this.dashboardCache.has(id)) {
            return Promise.resolve(this.dashboardCache.get(id));
        }
        return this.app.api.entities.getDashboard(id).then(dashboard => {
            this.dashboardCache.set(id, dashboard);
            return dashboard;
        });
    }

    getDashboardTemplate(id: string, bypassCache?: boolean): Promise<DashboardTemplateResponse> {
        if (!bypassCache && this.dashboardTemplateCache.has(id)) {
            return Promise.resolve(this.dashboardTemplateCache.get(id));
        }
        return this.app.api.entities.getDashboardTemplate(id).then(dashboard => {
            this.dashboardTemplateCache.set(id, dashboard);
            return dashboard;
        });
    }

    getDashboards(id?: string): Promise<DashboardListItem[]> {
        return this.app.api.entities.listDashboards(id || this.clientId).then(result => result.items.sort((a, b) => a.name > b.name ? 1 : -1));
    }

    getDashboardTemplates(id?: string): Promise<DashboardTemplateListItem[]> {
        return this.app.api.entities.listDashboardTemplates(id || this.clientId, true).then(res => {
            return res.items
                .filter(x => x.state !== 'deleted')
                .sort((a, b) => a.name > b.name ? 1 : -1);
        });
    }

    async cloneDashboard(id: string): Promise<void> {
        try {
            const translate = (key, options?) => this.i18n.instant(`DASHBOARDS.FORM_MODALS.${key}`, options);

            const currentDashboard = await this.getDashboard(id); // using cache here

            // make sure that all widgets has parameters set before allowing cloning
            const anyWidgetsUsesFakeData = Object.values(currentDashboard.widgets).some(x => {
                const analytics = x.dataSource && x.dataSource.options && x.dataSource.options.analytics || {} as any;
                return !(analytics.parameters || !analytics.parameterDefinition);
            });
            if (anyWidgetsUsesFakeData) {
                await this.modal.error(this.i18n.instant('DASHBOARDS.ERRORS.MISSING_WIDGET_PARAMS'));
                return null;
            }

            const form: FormBuilderDefinition = {
                groups: [{
                    name: translate('CLONE_DASHBOARD'),
                    fields: [
                        { id: 'name', type: 'text', title: translate('NAME'), required: true, max: 250, min: 1 },
                        { id: 'description', type: 'memo', title: translate('DESCRIPTION'), required: true, max: 1024, min: 1 },
                    ],
                }],
            };

            const values = {
                name: currentDashboard.name,
                description: currentDashboard.description,
            };

            const result = await this.modal.form(form, values);
            if (result) {
                const { name, description } = values;

                const dashboard = await this.app.api.entities.createDashboard({
                    ownerId: this.clientId,
                    name,
                    description,
                    level: currentDashboard.level, // Cant change a level of a dashboard by cloning it
                    source: {
                        id: currentDashboard.id,
                        name: currentDashboard.name,
                    },
                    widgets: Object.entries(currentDashboard.widgets).reduce((widgets, [key, widget]) => {
                        // set inherited to false on each widget
                        widgets[key] = {
                            ...cloneDeep(widget),
                            inherited: false,
                        };
                        return widgets;
                    }, {}),
                });

                await this.openDashboard(dashboard.id, false);
            }
        } catch (err) {
            this.modal.error(this.notify.translateError(err.message));
        }
    }

    async updateWidget(dashboardId: string, widgetId: string, update: DashboardWidget, template?: boolean): Promise<DashboardResponse | DashboardTemplateResponse> {
        if (!dashboardId || !widgetId) {
            throw new Error(`Invalid widget or dashboard id`);
        }

        // remove the id off the dataSource if there is a dataSource in the update
        if (update && update.dataSource) {
            delete update.dataSource.id; // strip dataSource.id from any update as a safety precaution
        }

        const payload = {
            widgets: {
                [widgetId]: update,
            },
        };
        const dashboard = await this.widgetsQueue.add(() => {
            return template ? this.app.api.entities.updateDashboardTemplate(dashboardId, payload) : this.app.api.entities.updateDashboard(dashboardId, payload);
        });

        if (template) {
            this.dashboardTemplateCache.set(dashboard.id, dashboard);
        } else {
            this.dashboardCache.set(dashboard.id, dashboard);
        }

        return dashboard;
    }

    // helper method for any consumer, but yeah this basically just updates the widget data with null
    async deleteWidget(dashboardId: string, widgetId: string, template?: boolean): Promise<DashboardResponse | DashboardTemplateResponse> {
        return await this.updateWidget(dashboardId, widgetId, null, template);
    }

}
