<div kui-action
    class="d-flex flex-1"
    [ngClass]="classNames"
    (click)="primaryActionClicked.emit()">
    <key-feed-entry-info [icons]="icons">
        <span id="info-title">
            <span kui-tooltip="{{data?.dateStart | date: 'yyyy-MM-dd HH:mm:ss'}}"
                [showOnTouchscreen]="true"
                align="left">{{ data?.dateStart | units: { unit: 'time', variant: 'short' } }}</span>
            <ng-container *ngIf="active">
                - <span kui-tooltip="{{data?.dateEnd | date: 'yyyy-MM-dd HH:mm:ss'}}"
                    [showOnTouchscreen]="true">{{ data?.dateEnd | units: { unit: 'time', variant: 'short' } }}</span>
            </ng-container>
        </span>
        <span id="info-small-text"
            class="small muted">
            {{tripTime | formatSeconds}}
        </span>
        <span id="info-small-text"
            class="small muted"
            *ngIf="distance">
            {{ distance }}
        </span>
    </key-feed-entry-info>

    <div class="feed-entry-details">
        <ng-container *ngIf="data?.locationStart && data?.locationEnd; else noLocation">

            <!-- <div id="entry-title"
                [innerHTML]="title"></div> -->

            <div id="entry-title"
                *ngIf="active"
                class="d-flex entry-title">
                <div class="accent d-flex flex-row">
                    <kui-icon name="flag"
                        class="pr-2 icon-trip icon-trip-start"></kui-icon>
                    <span>{{ titleStart }}</span>
                </div>
                <div class="px-2">
                    <kui-icon name="arrow-right-long"
                        class="icon-trip-arrow"></kui-icon>
                </div>
                <div class="accent d-flex flex-row">
                    <kui-icon name="flag-checkered"
                        class="pr-2 icon-trip icon-trip-end"></kui-icon>
                    <span>{{ titleEnd }}</span>
                </div>
            </div>

            <div id="entry-title"
                *ngIf="!active"
                class="d-flex entry-title">
                <div class="accent d-flex flex-row">
                    <kui-icon name="power-off"
                        class="pr-2 icon-trip icon-trip-parked"></kui-icon>
                    <span>{{ titleStart }}</span>
                </div>
            </div>

            <div id="entry-description">
                <div *ngFor="let linkedAsset of linkedAssets">
                    <kui-icon [name]="linkedAsset.icon"
                        class="mr-1"></kui-icon>
                    {{ linkedAsset.name }}
                </div>
                <div class="muted-less"
                    [innerHTML]="description"></div>
                <div *ngIf="labelsEnabled && labels && labels.length"
                    class="mt-1">
                    <key-label-badges [labels]="labels"></key-label-badges>
                </div>
            </div>
        </ng-container>
        <ng-template #noLocation>
            <div id="entry-title"
                class="accent">{{ 'SHARED.FEED_ENTRY.TRIP.UNKNOWN.TITLE' | translate }}</div>
            <div id="entry-description">
                <div *ngFor="let linkedAsset of linkedAssets">
                    <kui-icon [name]="linkedAsset.icon"
                        class="mr-1"></kui-icon>
                    {{ linkedAsset.name }}
                </div>
                <div class="muted-less">{{ 'SHARED.FEED_ENTRY.TRIP.UNKNOWN.DESCRIPTION' | translate }}</div>
            </div>
        </ng-template>
    </div>
</div>

<key-feed-entry-actions *ngIf="actions && actions.length"
    [actions]="actions"></key-feed-entry-actions>