import { Component, ElementRef } from '@angular/core';

@Component({
    selector: 'kui-action',
    templateUrl: './action.component.html',
})
export class KuiActionComponent {
    offsets: { x: number, y: number };

    get translate(): string {
        return this.offsets ? `translate(-50%, -50%) translate(${this.offsets.x}px, ${this.offsets.y}px)` : `translate(0, 0)`;
    }

    constructor(public el: ElementRef) { }
}
