import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'app/services';

@Injectable()
export class AuthenticatedGuard  {

    constructor(private auth: AuthService) { }

    /** checks if user is authenticated or redirects to login route. */
    canActivate(_router: ActivatedRouteSnapshot, snapshot: RouterStateSnapshot): Promise<boolean> {
        return this.auth.isAuthenticated(snapshot.url);
    }

}
