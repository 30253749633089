import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'app/app.service';
import { CurrentUser } from 'app/shared/model';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { NavigationService } from 'app/services';

@Injectable()
export abstract class FeaturesGuard  {

    constructor(protected app: AppService, protected router: Router, protected navigation: NavigationService) { }

    public hasRouted(): boolean {
        return this.router.navigated;
    }

    public matchesUserType(type: string | string[]): Observable<boolean> {
        return this.app.user$
            .pipe(
                skipWhile(user => !user),
                map((user: CurrentUser) => {
                    return typeof type === 'string' ? type === user.owner.type.toLowerCase() : type.includes(user.owner.type.toLowerCase());
                })
            );
    }

    abstract canActivate(): Observable<boolean>;

}
