import { Component, Output, EventEmitter, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilderDefinition, FormBuilderFieldChangeEvent, FormBuilderKeyValue } from 'app/shared/components/form-builder';
import { TranslateService } from '@ngx-translate/core';
import { SelectedMeasureValues } from '@key-telematics/fleet-api-client';

export interface PieSettings {
    fill: boolean;
    dataLabels?: boolean;
    color: string[];
    data: 'average' | 'total';
    measures: SelectedMeasureValues[];
    segments: number;
}

@Component({
    selector: 'key-analytics-settings-pie',
    templateUrl: './pie.component.html',
})
export class AnalyticsSettingsPieComponent implements OnInit, OnChanges {
    form: FormBuilderDefinition;
    formLeftAxis: FormBuilderDefinition;
    formRightAxis: FormBuilderDefinition;

    values: { [key: string]: any; };

    @Input() options: PieSettings;
    @Output() onChange: EventEmitter<{ [key: string]: any; }> = new EventEmitter();

    constructor(private i18n: TranslateService) {
    }

    ngOnInit() {
        this.form = this.getForm();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.options && this.options) {
            const { color, fill, dataLabels, data } = this.options;
            this.values = {
                color,
                fill,
                dataLabels,
                data,
                // convert color string[] into value items and add the index in key so that it is always in correct order
                ...color.reduce((res, value, index) => {
                    res[`color__${index}`] = value;
                    return res;
                }, {}),
            };

            // update swatches to display the amount of segments that are visible
            const { previousValue } = changes.options;
            if (this.form && this.options.segments !== (previousValue && previousValue.segments)) {
                this.form = this.getForm();
            }
        }
    }

    changeFormSetting(change: FormBuilderFieldChangeEvent) {
        if (change.dirty) {
            const { id } = change.field;

            if (id === 'color') {
                // convert all color value items back into a single array
                const color = Object.keys(change.values)
                    .filter(x => x.split('__')[1])
                    .map(x => change.values[x]);

                this.onChange.emit({ [id]: color });
            } else {
                this.onChange.emit({ [id]: change.values[id] });
            }
        }
    }

    getForm(): FormBuilderDefinition {
        return {
            groups: [{
                fields: [
                    { type: 'toggle', id: 'fill', title: 'FILL' },
                    { type: 'toggle', id: 'dataLabels', title: 'DATA_LABELS' },
                    {
                        type: 'radio', id: 'data', title: 'DATA', values: [
                            { key: 'average', value: this.i18n.instant('ANALYTICS.SETTINGS.AVERAGE') },
                            { key: 'total', value: this.i18n.instant('ANALYTICS.SETTINGS.TOTAL') },
                        ],
                    },
                    {
                        type: 'swatches', id: 'color', title: 'COLORS', options: { size: 'small' },
                        values: this.getColorValues(this.values.color, this.options.segments),
                    },
                ],
            }],
        };
    }

    getColorValues(colors: string[], count: number): FormBuilderKeyValue[] {
        return colors.slice(0, count || colors.length).map((color, index) => ({ key: `color__${index}`, value: color }));
    }
}
