import { Component, Input, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MeasurementUnitsService } from 'app/services';
import { EventFeedItem } from '../../feed.model';
import { getAndTranslateEventText, getEventInfoText } from '../event-translation-dictionary';
import { IEventInfoValue } from '@key-telematics/fleet-ui-shared';


@Component({
    selector: 'key-event-feed-item-description',
    template: `
    <span>{{description}}</span>
    <div class="pt-2" *ngIf="values.length > 0">
        <div *ngFor="let value of values">
            <strong>{{value.title}}:</strong>&nbsp;<span>{{value.value}}</span>
        <div>
    </div>
    `,
})
export class EventFeedItemDescriptionComponent implements OnChanges {

    @Input() item: EventFeedItem;

    description: string;
    values: IEventInfoValue[] = [];

    constructor(
        private i18n: TranslateService,
        private units: MeasurementUnitsService
    ) {
    }

    ngOnChanges() {
        this.description = getAndTranslateEventText('description', this.item, this.i18n, this.units);
        this.values = getEventInfoText(this.item, this.i18n, this.units);
    }



}
