<kui-input-group [cleanAddons]="true"
    class="group">
    <kui-icon kui-input-group-addon
        name="map-marker-alt"></kui-icon>
    <input kui-input
        #input
        type="text"
        [placeholder]="placeholder"
        [ngModel]="value"
        (ngModelChange)="debouncedUpdateSearchTerm($event)"
        (focus)="focus()"
        (blur)="blur(!searching && results?.length)">
</kui-input-group>

<kui-dropdown-ref *ngIf="showResults"
    [customContent]="resultsDropdown"></kui-dropdown-ref>

<ng-template #resultsDropdown>
    <kui-loader *ngIf="searching"
        size="small"></kui-loader>
    <div *ngIf="!searching && !results?.length"
        class="px-2">{{'LEAFLET.NO_RESULTS' | translate}}</div>
    <key-search-results-list *ngIf="!searching && results?.length"
        [list]="results"
        [highlightValue]="text?.searchTerm"></key-search-results-list>
</ng-template>