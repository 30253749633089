import { NgModule } from '@angular/core';
import { KeySearchResultsListComponent } from './search-results-list.component';
import { CommonModule } from '@angular/common';
import { KuiModule } from 'app/key-ui';

@NgModule({
    imports: [
        CommonModule,
        KuiModule,
    ],
    exports: [KeySearchResultsListComponent],
    declarations: [KeySearchResultsListComponent],
})
export class KeySearchResultsListModule { }
