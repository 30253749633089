import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KuiDropdownModule } from './../dropdown';
import { KuiIconModule } from './../icon';
import { KuiOptionPanelComponent } from './option-panel.component';
import { KuiActionModule } from 'app/key-ui/action/action.module';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { SlideMenuModule } from 'primeng/slidemenu';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        TranslateModule,
        KuiDropdownModule,
        KuiIconModule,
        CommonModule,
        KuiActionModule,
        TieredMenuModule,
        SlideMenuModule,
    ],
    exports: [KuiOptionPanelComponent],
    declarations: [KuiOptionPanelComponent],
})
export class KuiOptionPanelModule { }
