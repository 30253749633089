import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilderField, BaseFormBuilderFieldComponent } from 'app/shared/components/form-builder';
import { IKuiSelectOption } from 'app/key-ui';
import { KuiSelectComponent } from 'app/key-ui/select/select.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'key-form-builder-checkbox-field',
    templateUrl: './checkbox.component.html',
})
export class KeyFormBuilderCheckboxFieldComponent implements BaseFormBuilderFieldComponent, OnInit {

    @Input() field: FormBuilderField;
    @Input() values: { [key: string]: any };
    @Input() error: string; // set this error value externally to have the default error highligh and display kick in

    @Output() onChange: EventEmitter<{ value: string, dirty: boolean }> = new EventEmitter();

    @ViewChild(KuiSelectComponent, { static: true }) selectComponent: KuiSelectComponent;

    comboValues: IKuiSelectOption[];

    constructor(private i18n: TranslateService) { }

    validate(): boolean {
        return true;
    }

    ngOnInit() {

        if (this.field.value !== undefined || this.field.value !== null) {
            this.values[this.field.id] = !!(this.values[this.field.id] || this.field.value); // cast value to boolean
        }

        this.comboValues = [
            {
                id: 'false',
                value: this.i18n.instant('FORMS.CHECKBOX.NO'),
                selected: this.values[this.field.id] !== true,
            }, {
                id: 'true',
                value: this.i18n.instant('FORMS.CHECKBOX.YES'),
                selected: this.values[this.field.id] === true,
            },
        ];
        setTimeout(() => { // avoid ExpressionChangedAfterItHasBeenCheckedError
            this.comboChanged(this.comboValues.filter(x => x.selected), false);
        });
    }

    comboChanged(selected: IKuiSelectOption[], dirty: boolean) {
        if (selected && selected.length === 1) {
            this.values[this.field.id] = selected[0].id === 'true';
            this.onChange.emit({ value: this.values[this.field.id], dirty});
        }
    }

}
