import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output, signal } from '@angular/core';
import { input } from '@angular/core';

@Directive({
    selector: '[key-intersection-observer]',
    standalone: true,
})
export class KeyIntersectionObserverDirective implements OnInit, OnDestroy {

    threshold = input(0);
    element = signal(null);
    observer = signal<IntersectionObserver>(null);

    @Output() intersecting = new EventEmitter<boolean>()

    constructor(element: ElementRef) {
        this.element.set(element.nativeElement);
    }

    ngOnInit(): void {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    this.intersecting.emit(entry.isIntersecting);
                })
            },
            {
                threshold: this.threshold(),
            }
        );
        observer.observe(this.element());
        this.observer.set(observer);
    }

    ngOnDestroy(): void {
        this.observer()?.disconnect();
    }

}
