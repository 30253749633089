<div class="alert">
    <div class="text-center">
        <kui-icon *ngIf="icon !== ''"
            class="mb-3 muted-more d-block"
            [name]="icon || 'exclamation'"
            [size]="size"></kui-icon>
        <div *ngIf="message"
            class="mb-3">{{message}}</div>

        <button *ngIf="actionText"
            kui-button
            color="primary"
            (click)="onAction.emit()">
            {{ actionText | translate }}
        </button>
        <ng-content></ng-content>
    </div>
</div>