<kui-alert type="danger" class="key-alert-wrapper" *ngIf="errorMessage">
    <kui-icon name="ban" size="sm"></kui-icon>
    <span>{{errorMessage}}</span>
</kui-alert>
<div class="contacts p-3" *ngIf="asset">
    <h4>{{ asset.name }}</h4>
    <div *ngIf="!(contacts && contacts.length)" class="no-contacts pt-3 pb-3">
        {{ 'SHARED.CONTACTS.NO_CONTACTS' | translate }}
    </div>
    <key-contacts [contacts]="contacts"></key-contacts>
</div>
