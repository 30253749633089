<div kui-action
    class="d-flex flex-1"
    (click)="primaryActionClicked.emit()">
    <key-feed-entry-info [icons]="icons">
        <span id="info-title">
            <span [title]="data | getKey: 'date' | date: 'yyyy-MM-dd HH:mm:ss'">{{ data | getKey: 'date' | units: { unit: 'time', variant: 'short'} }}</span>
        </span>
        <span id="info-small-text"
            class="small muted">{{timeAgo}}</span>
    </key-feed-entry-info>

    <div class="feed-entry-details">
        <div class="d-flex flex-wrap">
            <div class='entry-text flex-1 mr-3 mb-1'>
                <div class="entry-title"
                    [innerHTML]="title"></div>
                <div class="entry-description muted-less pl-2"
                    *ngIf="changes && changes.length">

                    <div *ngFor="let change of changes"
                        class="d-flex pt-1">

                        <kui-icon name="edit"
                            class="muted-more pt-1 pr-2"
                            size="xs"></kui-icon>
                        <div class="flex-1 change-description">
                            {{ change }}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<key-feed-entry-actions *ngIf="actions && actions.length"
    [actions]="actions"></key-feed-entry-actions>