import { Component, OnInit, ViewChild } from '@angular/core';
import { KuiModalRefComponent } from 'app/key-ui/modal/modal-ref.component';
import { IKuiModalAction } from 'app/key-ui';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilderDefinition, KeyFormBuilderComponent } from '../../form-builder';

@Component({
    templateUrl: 'form-modal.component.html',
    styleUrls: ['form-modal.component.scss'],
})
export class FormModalComponent extends KuiModalRefComponent<{ form: FormBuilderDefinition, values: any }> implements OnInit {

    constructor(private i18n: TranslateService) {
        super();
    }

    @ViewChild(KeyFormBuilderComponent, { static: true }) formBuilder;

    title: string;
    modalActions: IKuiModalAction[];

    ngOnInit() {

        this.modalActions = [{
            text: this.i18n.instant('DIALOG.CANCEL'),
            style: 'secondary',
            keypress: 27,
            action: () => {
                this.actions.close();
            },
        }, {
            text: this.i18n.instant('DIALOG.OK'),
            style: 'primary',
            // keypress: 13, -- do not capture enter as it messes with the controls on the page
            action: () => {
                if (this.formBuilder.validate()) {
                    this.actions.apply();
                    return false;
                }
                return true;
            },
        }];

        this.title = this.data.form.groups[0].name;
        this.data.form.groups[0].name = null;

    }


    detachModal() {
        this.actions.close();
    }
}
