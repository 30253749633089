<div *ngIf="form">
    <div *ngFor="let group of form.groups"
        [class.hidden]="group.visible === false">
        <h4 *ngIf="group.name">{{ group.name }}</h4>
        <div *ngIf="group.description"
            class="mb-4 muted">{{ group.description }} </div>
        <form class="mb-4" autocomplete="off" (keydown.enter)="formKeyEnterPress($event)" (keyup.enter)="formKeyEnterPress($event)">

            <div class="invalid-feedback d-block">
                <div *ngFor="let error of getNonFieldErrors()">{{ error }}</div>
            </div>

            <key-form-builder-field *ngFor="let field of group.fields"
                [inline]="inline"
                [field]="field"
                [values]="values"
                [error]="errors && errors[field.id]"
                (onChange)="onChangeHandler($event)"></key-form-builder-field>
        </form>
    </div>
</div>