import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, AfterViewInit, SimpleChanges } from '@angular/core';
import { AppService } from 'app/app.service';
import * as Color from 'color';
import { ChartConfigurationWithPlugins, LegendItem } from './graph.model';
import { KeyGraphBaseComponent } from './base.component';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { merge } from 'lodash';

@Component({
    selector: 'key-pie-graph',
    templateUrl: './pie-graph.component.html',
    styleUrls: ['./base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyPieGraphComponent extends KeyGraphBaseComponent implements OnChanges, AfterViewInit {

    @Input() fill = true;

    get chartSpecificConfig(): ChartConfigurationWithPlugins {
        return merge(this.baseConfig, {
            type: this.fill ? 'pie' : 'doughnut',
            options: {
                scales: {
                    xAxes: [{
                        display: false,
                    }],
                },
                tooltips: {
                    callbacks: {
                        title: (tooltips: {index: number}[]) => {
                            const index = tooltips[0].index;
                            const label = this.labels[index];
                            if (label.hideTooltipTitle) { return null; }
                            return label.tooltipTitle || label.value;
                        },
                        label: tooltipItem => {
                            const dataset = this.datasets[tooltipItem.datasetIndex];
                            const label = this.chart.data.labels[tooltipItem.index];
                            const dataValue = dataset.data[tooltipItem.index].value;
                            return `${label}: ${dataValue}`;
                        },
                    },
                },
                plugins: {
                    datalabels: {
                        anchor: 'center',
                    },
                },
            },
            plugins: [ChartDataLabels],
        });
    }

    constructor(public changes: ChangeDetectorRef, public app: AppService) {
        super(changes, app);
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }

    updateGraphConfig() {
        const fadeColor = color => Color(color).fade(.1).string();
        const darkenColor = color => Color(color).darken(.2).string();

        this.config = {
            ...this.chartSpecificConfig,
            data: {
                labels: this.labels.map(x => x.value),
                datasets: this.datasets && this.datasets.map((x, i) => ({
                    ...this.getDataset(x, i),
                    backgroundColor: (x.colors || this.colors).map(fadeColor),
                    borderColor: x.colors || this.colors,
                    datalabels: {
                        color: '#ffffff',
                        backgroundColor: (x.colors || this.colors).map(darkenColor),
                    },
                })),
            },
        };

        this.updateGraph();
    }

    clickLegend(_item: LegendItem, index: number) {
        // pie charts should only ever have 1 dataset. More than that makes no sense.
        // so we get the meta information of the specific data item that is linked to the legend
        // item clicked, and toggle visibility on it.
        // NOTE: we are not going to emit the change, will only try and figure that out if it becomes a requirement
        const _meta = this.chart.data.datasets[0]['_meta'];
        const dataItem = _meta[Object.keys(_meta)[0]].data[index];

        dataItem.hidden = !dataItem.hidden;
        this.chart.update(0);
    }
}
