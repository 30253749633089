<key-layout-view page="external"
    [show]="true"
    [scroll]="false"
    [header]="!(media.isDesktop | async) && mobileHeader && !hidden">

    <div class="flex-1" *ngFor="let tab of tabs" [class.hidden]="active !== tab.id">
        <iframe *ngIf="tab.activated"  width="100%" height="100%" frameBorder="0" [src]="tab.url"  >
        </iframe>
    </div>

</key-layout-view>