import { NgModule } from '@angular/core';
import { MatchMediaService } from './match-media.service';
import {  } from 'ngx-device-detector';

@NgModule({
    providers: [
        MatchMediaService,
    ],
})
export class MatchMediaServiceModule { }
