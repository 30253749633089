import { Component, OnDestroy, OnInit } from "@angular/core";
import { SearchResult } from "app/shared/components/search-results-list/search-results-list.component";
import { Observable, ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MapCoordinates } from "../../../map.component";
import { MapOptionComponent } from "../map-option.component";
import { MapOptionSearchService } from "./map-option-search.service";


@Component({
    styleUrls: ['./map-option-search.component.scss'],
    templateUrl: './map-option-search.component.html',
})
export class MapOptionSearchComponent implements MapOptionComponent<any>, OnInit, OnDestroy {

    static ID = 'map-option-search-component';
    static ICON = 'search';
    static TITLE = 'LEAFLET.MAP_OPTIONS.SEARCH_RESULTS';

    data: any;

    searching: boolean = true;
    results: SearchResult<MapCoordinates>[];
    term$: Observable<string>;
    destroy$ = new ReplaySubject<void>(1);

    constructor(private searchService: MapOptionSearchService) { }

    ngOnInit(): void {
        this.term$ = this.searchService.searchTerm$;

        this.searchService.searching$.pipe(
            takeUntil(this.destroy$)
        ).subscribe(s => this.searching = s);

        this.searchService.searchResults$.pipe(
            takeUntil(this.destroy$)
        ).subscribe(res => this.results = res);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    hideSearchList() {
        this.searchService.hideResults();
    }
}
