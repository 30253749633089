import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { KuiAccordionModule } from './accordion';
import { KuiActionModule } from './action';
import { KuiAlertModule } from './alert';
import { KuiBreadcrumbModule } from './breadcrumb';
import { KuiButtonModule } from './button';
import { KuiButtonGroupModule } from './button-group';
import { KuiCardModule } from './card';
import { KuiCheckboxModule } from './checkbox';
import { KuiColorPickerModule } from './color-picker';
import { KuiHeaderModule } from './header';
import { KuiIconModule } from './icon';
import { KuiInputModule } from './input';
import { KuiInputGroupModule } from './input-group';
import { KuiListModule } from './list';
import { KuiModalModule } from './modal';
import { KuiDropdownModule } from './dropdown';
import { KuiNavModule } from './nav';
import { KuiOptionPanelModule } from './option-panel';
import { KuiPaginateModule } from './paginate';
import { KuiProgressModule } from './progress/progress.module';
import { KuiRadioModule } from './radio';
import { KuiSelectModule } from './select';
import { KuiSidePanelModule } from './side-panel';
import { KuiMenuModule } from './menu';
import { KuiTableModule } from './table';
import { KuiBadgeModule } from './badge';
import { KuiTooltipModule } from './tooltip';
import { KuiTreeSelectModule } from './tree-select/tree-select.module';
import { KuiUserModule } from './user';
import { KuiTabsModule } from './tabs';
import { KuiDateTimeModule } from './datetime';
import { KuiToggleModule } from './toggle';
import { KuiTabBarModule } from './tab-bar';
import { KuiContentHeaderModule } from './content-header';
import { KuiContentHeaderActionsModule } from './content-header-actions';
import { KuiTagInputModule } from 'app/key-ui/tag-input';
import { KuiLoaderModule } from 'app/key-ui/loader/loader.module';
import { KuiSnackbarModule } from './snackbar/snackbar.module';
import { KuiTopNavModule } from './top-nav/top-nav.module';
import { KuiDateRangeComponent } from './daterange';

const KUI_MODULES = [
    KuiAccordionModule,
    KuiActionModule,
    KuiAlertModule,
    KuiBreadcrumbModule,
    KuiButtonModule,
    KuiButtonGroupModule,
    KuiCardModule,
    KuiCheckboxModule,
    KuiColorPickerModule,
    KuiDropdownModule,
    KuiHeaderModule,
    KuiIconModule,
    KuiInputModule,
    KuiInputGroupModule,
    KuiListModule,
    KuiModalModule,
    KuiNavModule,
    KuiOptionPanelModule,
    KuiPaginateModule,
    KuiProgressModule,
    KuiRadioModule,
    KuiSelectModule,
    KuiSidePanelModule,
    KuiMenuModule,
    KuiTableModule,
    KuiBadgeModule,
    KuiTooltipModule,
    KuiUserModule,
    KuiTabBarModule,
    KuiToggleModule,
    KuiTreeSelectModule,
    KuiDateTimeModule,
    KuiTabsModule,
    KuiContentHeaderModule,
    KuiContentHeaderActionsModule,
    KuiTagInputModule,
    KuiLoaderModule,
    KuiSnackbarModule,
    KuiTopNavModule,
    KuiDateRangeComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        ...KUI_MODULES,
    ],
    exports: KUI_MODULES,
})
export class KuiModule { }
