<div class="chart-container">
    <div class="loading" *ngIf="!loaded">
        <kui-loader class="mr-1" size="small"></kui-loader> {{ 'SHARED.LOADING' | translate }}
    </div>

    <!-- CHART -->
    <canvas #graph class="d-block" [class.invisible]="!loaded"></canvas>
</div>

<!-- LEGEND -->
<div
    *ngIf="legend?.show"
    #legendEl
    [ngStyle]="{
        order: testLegendPositions('bottom') || testLegendPositions('right') ? 3 : 1
    }"
>
    <kui-card
        id="legend"
        [ngClass]="{
            'mt-3': testLegendPositions('bottom') && !legend?.overlay,
            'mb-3': testLegendPositions('top') && !legend?.overlay,
            'position__overlay': legend?.overlay,
            'position__top': testLegendPositions('top'),
            'position__bottom': testLegendPositions('bottom'),
            'position__right': testLegendPositions('right'),
            'position__left': testLegendPositions('left'),
            'hide': legendMinimized
        }"
    >
        <kui-card-body class="d-flex flex-column">
            <kui-icon
                kui-action
                name="chevron-down"
                class="legend__toggle px-2 py-1"
                (click)="legendMinimized = !legendMinimized"
            ></kui-icon>
            <div *ngIf="legendItems?.length && (!legendMinimized || !legend?.overlay)" class="legend p-2 flex-1 overflow-auto">
                <ul>
                    <li *ngFor="let item of legendItems; let i = index" (click)="clickLegend(item, i)" [class.legend-hidden]="item.hidden">
                        <span class="legend__color" [style.background-color]="item.fillStyle" [style.border-color]="item.strokeStyle"></span>
                        <span class="legend__text">{{item.text}}</span>
                    </li>
                </ul>
            </div>
        </kui-card-body>
    </kui-card>
</div>

