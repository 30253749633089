import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { MapZonesService } from '../../map-zones.service';
import { MapZone } from '../../map.component';
import { MapOptionLayer } from '../components';
import { ALL_ZONES, ZoneType } from '../model';

@Injectable()
export class ZoneSelectorService {

    private layers: MapOptionLayer[] = [];
    private _nameFilterSubject = new BehaviorSubject<string>('');
    private _typeFilterSubject = new BehaviorSubject<ZoneType[]>(ALL_ZONES);

    private allZones$ = this.zoneService.zones$;

    filteredZones$: Observable<MapZone[]> = combineLatest([
        this._nameFilterSubject,
        this._typeFilterSubject,
        this.allZones$
    ]).pipe(
        map(([name, types, zones]) => this.filterZones(name, types, zones))
    );

    constructor(
        private zoneService: MapZonesService,
    ) { }

    private filterZones(searchTerm: string, supportedTypes: ZoneType[], zones: MapZone[]): MapZone[] {
        const isLayerVisible = (zone: MapZone) => this.layers ? this.layers?.find(layer => zone.type === layer.id)?.visible : true;

        return zones.filter(
            zone => 
                zone.name.toLowerCase().includes(searchTerm.toLowerCase()) 
                && supportedTypes.includes(zone.type)
                && isLayerVisible(zone)
        );
    }

    setLayerOverlays(layers: MapOptionLayer[]) {
        this.layers = layers;
    }

    setNameFilter(term: string) {
        this._nameFilterSubject.next(term);
    }

    setTypeFilter(types: ZoneType[]) {
        this._typeFilterSubject.next(types);
    }

}
