import { Component, Output, EventEmitter, Input, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilderDefinition } from 'app/shared/components/form-builder';
import { AnalyticsOutputFilters, AnalyticsOutputRangeFilter, AnalyticsOutputFilter } from '@key-telematics/fleet-api-client';
import { pick } from 'lodash';
import { AnalyticsCellSetFilters } from '../../analytics.model';

export interface FilterSettingsItem {
    name: string;
    mode: 'include' | 'exclude' | 'range';
    items?: string[];
    range?: AnalyticsOutputRangeFilter;
    hasRange?: boolean;
}

export interface FilterSettings {
    row: FilterSettingsItem;
    group: FilterSettingsItem;
}

export interface FilterValues {
    group?: AnalyticsOutputFilter;
    row?: AnalyticsOutputFilter;
}

@Component({
    selector: 'key-analytics-settings-filters',
    templateUrl: './filters.component.html',
})
export class AnalyticsSettingsFiltersComponent implements OnChanges {
    form: FormBuilderDefinition;
    values: AnalyticsOutputFilters;

    @Input() filters: AnalyticsCellSetFilters;
    @Input() options: FilterSettings;
    @Output() onChange = new EventEmitter<FilterValues>();

    ngOnChanges(changes: SimpleChanges) {
        if (changes.options && this.options) {
            const { row, group } = this.options;
            this.values = {
                row: pick(row, ['mode', 'items', 'range']),
                group: pick(group, ['mode', 'items', 'range']),
            };
        }
    }
}
