import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TelemetryResponse } from '@key-telematics/fleet-api-client';
import { MapTripService } from 'app/shared/components';

@Pipe({
    name: 'directionalIcon',
    standalone: true,
})
export class DirectionalIconPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer, private tripService: MapTripService) {}

    transform(telemetry: TelemetryResponse, colour: string): SafeHtml {
        const iconColour = colour ?? 'blue';
        return this.sanitizer.bypassSecurityTrustHtml(this.tripService.getMarkerIcon(telemetry, iconColour, 35).html);
    }

}
