import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { ZoneUpdateRequest } from '@key-telematics/fleet-api-client';
import { KeyFormBuilderComponent } from 'app/shared/components/form-builder';
import { EMPTY, Observable, Subject, from } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ZoneProperties } from '../../../map-zones.service';
import { Point, ZoneCreationEvent } from '../../../map.component';
import { MapOptionService } from '../../map-option.service';
import { ZoneEditorAction } from '../../model';
import { ZoneEditorService, ZoneForm } from '../../services';
import { MapOptionComponent } from '../map-option.component';

export interface ZoneEditorConfig {
    editorAction: ZoneEditorAction;
    zoneId?: string;
}

@Component({
    styleUrls: ['./map-option-zone-editor.component.scss'],
    templateUrl: './map-option-zone-editor.component.html',
})
export class MapOptionZoneEditorComponent implements MapOptionComponent<ZoneEditorConfig>, OnInit, OnDestroy {

    static ID = 'map-option-zone-editor-component';
    static ICON = 'draw-polygon';

    @ViewChild(KeyFormBuilderComponent) formBuilder: KeyFormBuilderComponent;

    private destroyed: Subject<void> = new Subject();

    data: ZoneEditorConfig;
    form$: Observable<ZoneForm> = EMPTY;
    zoneCreated$: Observable<ZoneCreationEvent> = this.editorService.zoneCreated$;
    compact: boolean = false;
    latestPolygon: Point[] = [];

    constructor(
        private editorService: ZoneEditorService,
        private mapOptionService: MapOptionService,
    ) { }

    ngOnInit(): void {
        this.mapOptionService.isCompact$.pipe(
            takeUntil(this.destroyed)
        ).subscribe(res => {
            this.compact = res;
        });
        this.editorService.fireEditEvent({
            action: this.data.editorAction,
            zoneId: this.data.zoneId
        });
        this.editorService.polygonEdit$.pipe(
            takeUntil(this.destroyed)
        ).subscribe(res => {
            this.latestPolygon = res.points;
        })

        this.form$ = from(this.editorService.getForm(this.data.editorAction, this.data.zoneId));
    }

    ngOnDestroy(): void {
        this.destroyed.next();
        this.editorService.cancelMapEditing();
    }

    save(zoneId: string, properties: ZoneProperties) {
        if (this.formBuilder.validate()) {
            this.editorService.completeMapEdits();
            const request: ZoneUpdateRequest = properties;
            if (this.latestPolygon.length > 0) {
                // user has modified the polygon, update the request with the latest points.
                request.points = this.latestPolygon;
                this.latestPolygon = [];
            }
            this.editorService.updateZone(zoneId, request);
            this.mapOptionService.back();
        }
    }

    saveMapEdits(zoneId: string) {
        if (this.latestPolygon.length > 0) {
            this.editorService.completeMapEdits();
            const request: ZoneUpdateRequest = {
                points: this.latestPolygon
            };
            this.latestPolygon = [];
            this.editorService.updateZone(zoneId, request);
            this.mapOptionService.back();
        }
    }

    createNew(properties: ZoneProperties, points: Point[]) {
        if (this.formBuilder.validate()) {
            this.editorService.completeMapEdits();
            let newZonePoints = points;
            if (this.latestPolygon.length > 0) {
                // user has modified the polygon since creating it, use the edited points instead.
                newZonePoints = this.latestPolygon;
            }
            this.editorService.createNewZone(properties.name, newZonePoints, properties);
            this.mapOptionService.back();
        }

    }

    removeLastPoint() {
        this.editorService.removeLastPoint();
    }

    finishDrawing() {
        this.editorService.autoCompleteGeometry();
    }

    cancel() {
        this.mapOptionService.back();
    }
}