import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { ReplaySubject } from 'rxjs';
import { MapSetLayer, IdNameType } from '@key-telematics/fleet-api-client';

export interface CurrentMapSet {
    id: string;
    owner: IdNameType;
    name: string;
    layers: Array<MapSetLayer>;
    overlays: Array<MapSetLayer>;
    staticMapURL: string;

}

@Injectable()
export class MapSetService {

    private mapSetSubject: ReplaySubject<CurrentMapSet> = new ReplaySubject(1);
    mapSet$ = this.mapSetSubject.asObservable();

    constructor(private app: AppService) {
        // listen for changes to the client, as we will automatically load the map set if it changes
        this.app.client$.subscribe(client => {
            try {
                if (client) {
                    this.loadMapSet(client.mapSetId).catch(err => {
                        this.app.logError(err); // if an error occurs, notify the AppService as it is a global error
                    });
                } else {
                    this.loadMapSet(null); // reset the mapset
                }
            } catch (err) {
                this.app.logError(err); // if an error occurs, notify the AppService as it is a global error
            }
        });

    }

    loadMapSet(id: string): Promise<CurrentMapSet> {
        if (id) {
            return this.app.api.entities.getMapSet(id).then(result => {
                const layers = result.layers.map(layer => {
                   return {
                       ...layer,
                       servers: layer.servers.map(server => {
                           // Not all map layers are formatted nicely, but we can only assume
                           if (layer.serverType === 'osm' && !/{/.test(server)) {
                                return `http://${server}/${layer.layerName}/{z}/{x}/{y}.${layer.imageType}`;
                           }

                           return server;
                       }),
                   };
                });

                const mapset: CurrentMapSet = {
                    id: result.id,
                    owner: result.owner,
                    name: result.name,
                    layers: layers,
                    overlays: result.overlays,
                    staticMapURL: result.staticMapURL,
                };
                this.mapSetSubject.next(mapset);
                return mapset;
            });
        } else {
            // place reset code here
            this.mapSetSubject.next(null);
            return Promise.resolve(null);
        }
    }


}
