import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { KuiModule } from 'app/key-ui';

import { LayoutViewComponent } from './layout-view.component';
import { KeyLayoutGridModule } from '../layout-grid/layout-grid.module';

const COMPONENTS = [
    LayoutViewComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        KuiModule,
        KeyLayoutGridModule,
    ],
    exports: COMPONENTS,
    declarations: COMPONENTS,
    providers: [],
})
export class KeyLayoutViewModule { }
