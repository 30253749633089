import { Pipe, PipeTransform } from '@angular/core';
import { CustomFieldValues } from '@key-telematics/fleet-api-client';


@Pipe({
    name: 'isValidCustomField'
})
export class ValidCustomFieldPipe implements PipeTransform {
    transform(customFields: CustomFieldValues, field: string): boolean {
        if (customFields && field) {
            return !!customFields[field];
        }
        return false;
    }
}
