import { Injectable } from '@angular/core';
import { FeaturesGuard } from 'app/guards/features.guard';
import { Observable, of } from 'rxjs';
import { map, skipWhile, combineLatest, catchError } from 'rxjs/operators';


@Injectable()
export class AdminGuard extends FeaturesGuard  {

    /** The Admin is a little different to other features. The feature setting applies only to 'client' users. Any other user
     *  will have admin enabled by default, regardless of the setting. */
    canActivate(): Observable<boolean> {
        return this.app.client$
            .pipe(
                skipWhile(client => !client),
                map(() => this.app.features.page.admin.enabled),
                combineLatest(this.matchesUserType('client')),
                map(([hasFeature, isClientUser]) => {
                    const canNavigate = hasFeature || !isClientUser;
                    if (!canNavigate && !this.hasRouted()) {
                        this.navigation.navigateToDefault();
                    }
                    return canNavigate;
                }),
                catchError((err) => {
                    console.error(err);
                    if (!this.hasRouted()) {
                        this.navigation.navigateToDefault();
                    }
                    return of(false);
                })
            );
    }
}
