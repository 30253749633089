<textarea
    kui-input="{{ field.id }}"
    placeholder="{{ field.placeholder }}"
    id="{{ field.id }}"
    #textareaField="ngModel"
    [minlength]="field.min || 0"
    [maxlength]="field.max || 1024"
    [ngModel]="values[field.id]"
    (ngModelChange)="changeValue($event)"
    [required]="field.required"
    [rows]="rows"
    [class.border-danger]="error || (textareaField.invalid && (textareaField.dirty || textareaField.touched))"
>
</textarea>
<div
    *ngIf="textareaField.invalid && (textareaField.dirty || textareaField.touched)"
    class="invalid-feedback d-block"
>
    <div *ngIf="textareaField.errors['required']">{{ 'FORMS.SHARED.REQUIRED' | translate:{ field: field.title } }}</div>
    <div *ngIf="textareaField.errors['minlength']">{{ 'FORMS.TEXT.MIN' | translate:{ field: field.title, min: field.min } }}</div>
</div>
<div *ngIf="error"
    class="invalid-feedback d-block">
    <div>{{ error }}</div>
</div>