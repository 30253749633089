import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormBuilderField, BaseFormBuilderFieldComponent } from 'app/shared/components/form-builder';
import { KuiModalService } from 'app/key-ui/modal/modal.service';
import { KeyImageEditorModalComponent } from 'app/shared/components/image-editor';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'key-form-builder-image-field',
    templateUrl: './image.component.html',
})
export class KeyFormBuilderImageFieldComponent implements BaseFormBuilderFieldComponent, OnInit {

    @Input() field: FormBuilderField;
    @Input() values: { [key: string]: any };
    @Input() error: string; // set this error value externally to have the default error highligh and display kick in

    @Output() onChange: EventEmitter<{ value: any, dirty?: boolean }> = new EventEmitter();

    dirty = false;
    touched = false;

    get value(): { original: string, cropped: string, preview: string } {
        return this.field.getValue ? this.field.getValue(this.field, this.values) : (this.values[this.field.id] || this.field.value);
    }
    set value(val: { original: string, cropped: string, preview: string }) {
        if (this.field.setValue) {
            const promise = this.field.setValue(this.field, this.values, val);
            if (promise && promise.then) {
                promise.then(() => this.ref.markForCheck());
            }
        } else {
            this.values[this.field.id] = val;
        }
    }

    constructor(
        public modal: KuiModalService,
        public i18l: TranslateService,
        private ref: ChangeDetectorRef
    ) { }

    validate(): boolean {
        this.touched = true;
        return !!(this.value && this.value.original) || !this.field.required;
    }

    ngOnInit() {
        if (this.field.value) {
            this.value = this.value || this.field.value;
        }
    }

    onEdit() {
        this.modal.open(KeyImageEditorModalComponent, {
            data: {
                title: this.i18l.instant('IMAGE_EDITOR.TITLE'),
                originalUrl: this.value && (this.value.cropped || this.value.original),
                preview: {
                    visible: true,
                    width: 240,
                    height: 120,
                },
                backgroundColor: this.field.options && this.field.options.background,
            },
            actions: {
                close: () => {
                    this.touched = true;
                    this.modal.close();
                },
                apply: (result) => {
                    this.touched = true;
                    this.dirty = true;
                    this.value = result;
                    this.onChange.emit({ value: this.value, dirty: true });
                    this.modal.close();
                },
                delete: () => {
                    this.onClear();
                    this.modal.close();
                }
            },
        });

    }

    onClear() {
        this.touched = true;
        this.dirty = true;
        this.value = { original: null, cropped: null, preview: null };
        this.onChange.emit({ value: this.value, dirty: true });
    }

}
