<kui-modal size='lg'
    [title]="data.title"
    (onClose)="close()"
    [actions]="modalActions">
    <div>
        <key-image-editor
            [cropWidth]="data.cropped"
            [originalImageUrl]="data.originalUrl"
            [previewVisible]="data.preview.visible"
            [previewWidth]="data.preview.width"
            [previewHeight]="data.preview.height"
            [paddingPercentage]="!data.options || data.options.paddingPercentage === undefined ? 1 : data.options.paddingPercentage"
            [backgroundColor]="data.backgroundColor || 'white'"
            (imageUpdated)="onImageUpdated($event)"
            (imageRemoved)="this.actions.delete()"
            ></key-image-editor>
    </div>
</kui-modal>