

/** These setting variables will be turned into CSS variables based on the object tree, i.e.
 * --main-text-font: 'Ubuntu',
 * --main-text-size: '1rem',
 * --main-text-color: '#333',
 * --main-background-color: '#232',
 * --main-background-image: 'url(12323)',
 * --content-text-font: 'Ubuntu',
 * --content-text-size: '1rem',
 * --content-text-color: '#333',
 * ...
 *
 * SASS like functions are supported which will be resolved in javascript before rendering the CSS variable.
 *
 */

export const THEME_OFF_WHITE = '#f7f7f7';
export const THEME_OFF_BLACK = '#676a6c';
export const THEME_DEFAULTS = {

    main: {
        font: {
            family: 'Ubuntu',
            size: '1rem',
            weight: '400',
            color: `var(--main-background-color).inverse("${THEME_OFF_WHITE}", "${THEME_OFF_BLACK}")`,
        },
        background: {
            color: THEME_OFF_WHITE,
        },
        border: {
            color: 'var(--main-background-color).darken(0.1)',
        },
        gutter: {
            width: '0',
        },
        shadow: 'none',
        icon: {
            pack: 'fontawesome:900',
        },
    },

    content: {
        font: {
            family: 'var(--main-font-family)',
            size: '1rem',
            color: 'var(--main-font-color)',
        },
        background: {
            color: 'var(--main-background-color).lighten(0.1)',
        },
        border: {
            width: '1px',
            color: 'var(--content-background-color).darken(0.1)',
        },
        shadow: 'none',
    },

    sidebar: {
        font: {
            family: 'var(--content-font-family)',
            size: '1rem',
            color: 'var(--content-font-color)',
        },
        background: {
            color: 'var(--content-background-color)',
        },
        border: {
            width: '1px',
            color: 'var(--sidebar-background-color).darken(0.05)',
        },
        shadow: 'none',
    },

    loader: {
        style: 'spinner',
        font: {
            color: 'var(--main-font-color)',
        },
        overlay: {
            background: {
                color: 'var(--main-background-color).fade(0.6)',
            },
        },
    },

    color: {
        primary: { color: '#f33636', contrast: `var(--color-primary-color).inverse("${THEME_OFF_WHITE}", "${THEME_OFF_BLACK}")`, },
        secondary: { color: 'var(--content-background-color).darken(0.05)', contrast: `var(--color-secondary-color).inverse("${THEME_OFF_WHITE}", "${THEME_OFF_BLACK}")`, },
        success: { color: '#5cb85c', contrast: `var(--color-success-color).inverse("${THEME_OFF_WHITE}", "${THEME_OFF_BLACK}")`, },
        info: { color: '#5bc0de', contrast: `var(--color-info-color).inverse("${THEME_OFF_WHITE}", "${THEME_OFF_BLACK}")`, },
        notify: { color: '#f9f6e8', contrast: `var(--color-notify-color).inverse("${THEME_OFF_WHITE}", "${THEME_OFF_BLACK}")`, },
        warning: { color: '#f0ad4e', contrast: `var(--color-warning-color).inverse("${THEME_OFF_WHITE}", "${THEME_OFF_BLACK}")`, },
        danger: { color: '#d9534f', contrast: `var(--color-danger-color).inverse("${THEME_OFF_WHITE}", "${THEME_OFF_BLACK}")`, },
        light: { color: '#F5F5F5', contrast: `var(--color-light-color).inverse("${THEME_OFF_WHITE}", "${THEME_OFF_BLACK}")`, },
        dark: { color: '#55595c', contrast: `var(--color-dark-color).inverse("${THEME_OFF_WHITE}", "${THEME_OFF_BLACK}")`, },
    },

    header: {
        font: {
            family: 'var(--main-font-family)',
            size: '1rem',
            color: 'var(--main-font-color)',
            weight: '400',
            transform: 'unset',
        },
        background: {
            color: 'var(--content-background-color)',
        },
        border: {
            width: '1px',
            color: 'var(--header-background-color).darken(0.05)',
        },
    },

    subheader: {
        font: {
            family: 'var(--content-font-family)',
            color: 'var(--content-font-color)',
            size: '1rem',
            weight: '400',
            transform: 'unset',
        },
        background: {
            color: 'var(--content-background-color).darken(0.025)',
        },
        border: {
            color: 'var(--subheader-background-color).darken(0.05)',
        },
    },

    footer: {
        font: {
            family: 'var(--main-font-family)',
            size: '1rem',
            color: 'var(--main-font-color)',
        },
        background: {
            color: 'var(--content-background-color).darken(0.025)',
        },
        border: {
            width: '1px',
            color: 'var(--footer-background-color).darken(0.1)',
        },
    },

    navigation: {
        size: 'auto',
        position: 'left',
        font: {
            family: 'var(--main-font-family)',
            size: '1rem',
            color: 'var(--main-font-color)',
            weight: '400',
            transform: 'unset',
        },
        background: {
            color: 'var(--content-background-color)',
        },
        border: {
            width: '1px',
            color: 'var(--navigation-background-color).darken(0.1)',
        },
        separator: {
            width: '0px',
            color: 'var(--main-border-color)',
        },
        active: {
            style: 'left-border',
            font: {
                color: 'var(--color-primary-contrast)',
            },
            background: {
                color: 'var(--color-primary-color)',
            },
        },
        subnav: {
            font: {
                color: 'var(--main-font-color)',
            },
            background: {
                color: 'var(--navigation-background-color).darken(0.05)',
            },
        },
        client: {
            font: {
                family: 'var(--main-font-family)',
                size: '1rem',
                color: 'var(--footer-font-color)',
            },
            background: {
                color: 'var(--footer-background-color)',
            },
            border: {
                width: '1px',
                color: 'var(--footer-border-color)',
            },
        },
        logo: {
            background: {
                color: '#ffffff',
            },
            border: {
                width: '1px',
                color: 'var(--navigation-logo-background-color).darken(0.1)',
            },
        },
    },

    topnavigation: {
        style: 'hidden',
        font: {
            family: 'var(--header-font-family)',
            size: '1rem',
            color: 'var(--header-font-color)',
            weight: '400',
        },
        background: {
            color: 'var(--header-background-color)',
        },
        border: {
            width: '1px',
            color: 'var(--topnavigation-background-color).darken(0.1)',
        },
        subnav: {
            font: {
                color: 'var(--header-font-color)',
            },
            background: {
                color: 'var(--topnavigation-background-color).darken(0.05)',
            },
        },
        active: {
            font: {
                color: 'var(--color-primary)',
            },
            background: {
                color: 'transparent',
            },
            tab: {
                font: {
                    color: 'var(--topnavigation-font-color)',
                },
                background: {
                    color: 'var(--topnavigation-background-color).darken(0.05)',
                },
            },
            indicator: {
                style: 'var(--tab-active-indicator-style)',
                color: 'var(--tab-active-indicator-color)',
            },
        },
        logo: {
            style: 'right',
        },

    },

    accordion: {
        font: {
            family: 'var(--main-font-family)',
            color: 'var(--main-font-color)',
            size: '0.9rem',
            weight: '500',
            transform: 'uppercase',
        },
        background: {
            color: 'var(--content-background-color)',
        },
        border: {
            width: '1px',
            color: 'var(--accordion-background-color).darken(0.05)',
        },
        active: {
            font: {
                color: 'var(--subheader-font-color)',
            },
            background: {
                color: 'var(--subheader-background-color)',
            },
            border: {
                width: '1px',
                color: 'var(--accordion-active-background-color).darken(0.1)',
            },
        },
    },

    table: {
        font: {
            family: 'var(--main-font-family)',
            size: '1rem',
            color: 'var(--main-font-color)',
            weight: 'var(--main-font-weight)',
        },
        background: {
            color: 'var(--content-background-color)',
        },
        border: {
            width: '1px',
            color: 'var(--table-background-color).darken(0.05)',
        },
        header: {
            font: {
                family: 'var(--subheader-font-family)',
                size: '1rem',
                color: 'var(--subheader-font-color)',
                weight: '500',
                transform: 'unset',
            },
            background: {
                color: 'var(--subheader-background-color)',
            },
            border: {
                width: '1px',
                color: 'var(--table-header-background-color).darken(0.1)',
            },
        },
        active: {
            font: {
                color: 'var(--color-primary-contrast)',
            },
            background: {
                color: 'var(--color-primary-color)',
            },
            border: {
                width: '1px',
                color: 'var(--table-active-background-color).darken(0.1)',
            },
        },

    },

    tree: {
        background: {
            color: 'var(--content-background-color)',
        },
        border: {
            width: '1px',
            color: 'var(--tree-background-color).darken(0.1)',
        },
        active: {
            font: {
                color: 'var(--color-primary-contrast)',
            },
            background: {
                color: 'var(--color-primary-color)',
            },
        },

    },

    list: {
        font: {
            family: 'var(--main-font-family)',
            size: '1rem',
            color: 'var(--main-font-color)',
            weight: 'var(--main-font-weight)',
        },
        background: {
            color: 'var(--content-background-color)',
        },
        border: {
            width: '1px',
            color: 'var(--list-background-color).darken(0.05)',
        },
        icon: {
            color: 'var(--list-background-color).darken(0.1)',
        },
        active: {
            font: {
                color: 'var(--main-font-color)',
            },
            icon: {
                color: 'var(--color-primary-color)',
            },
            background: {
                color: 'var(--list-background-color).darken(0.025)',
            },
        },
    },

    feed: {
        font: {
            family: 'var(--main-font-family)',
            size: '1rem',
            color: 'var(--main-font-color)',
            weight: 'var(--main-font-weight)',
        },
        background: {
            color: 'var(--content-background-color)',
        },
        border: {
            width: '1px',
            color: 'var(--feed-background-color).darken(0.05)',
        },
        header: {
            font: {
                color: 'var(--content-font-color)',
            },
            background: {
                color: 'var(--feed-background-color)',
            },
            border: {
                color: 'var(--feed-background-color).darken(0.05)',
            },
        },
        active: {
            font: {
                color: 'var(--main-font-color)',
            },
            background: {
                color: 'var(--feed-background-color).darken(0.025)',
            },
        },

    },

    tab: {
        font: {
            family: 'var(--main-font-family)',
            size: '1rem',
            color: 'var(--main-font-color)',
            weight: 'var(--main-font-weight)',
            transform: 'unset',
        },
        background: {
            color: 'var(--header-background-color)',
        },
        border: {
            width: '1px',
            color: 'var(--tab-background-color).darken(0.05)',
        },
        active: {
            font: {
                color: 'var(--main-font-color)',
            },
            background: {
                color: 'var(--tab-background-color).darken(0.05)',
            },
            indicator: {
                style: 'none',
                color: 'var(--color-primary-color)',
            },
        },

    },

    input: {
        font: {
            family: 'var(--main-font-family)',
            size: '1rem',
            color: 'var(--main-font-color)',
            weight: 'var(--main-font-weight)',
        },
        background: {
            color: 'var(--content-background-color).lighten(0.1)',
        },
        border: {
            width: '1px',
            color: 'var(--content-background-color).darken(0.1)',
        },
    },

    button: {
        font: {
            family: 'var(--main-font-family)',
            size: '1rem',
            color: 'var(--main-font-color)',
            weight: 'var(--main-font-weight)',
            transform: 'unset',
        },
        border: {
            radius: '2px',
            shadow: 'none',
        },
        hover: {
            border: {
                shadow: 'none',
            },
        },
        active: {
            border: {
                shadow: 'none',
            },
        },
    },

    snackbar: {
        font: {
            family: 'var(--main-font-family)',
            size: '1rem',
            color: 'var(--main-font-color)',
            weight: 'var(--main-font-weight)',
        },
        background: {
            color: 'var(--content-background-color).lighten(0.2)',
        },
        border: {
            width: '1px',
            color: 'var(--snackbar-background-color).darken(0.1)',
        },
    },

    map: {
        control: {
            font: {
                family: 'var(--main-font-family)',
                size: '1rem',
                color: '#676a6c',
                weight: 'var(--main-font-weight)',
            },
            background: {
                color: '#fff',
            },
            secondary: {
                background: {
                    color: 'var(--map-control-background-color).darken(0.05)',
                },
            },
            border: {
                width: '1px',
                color: 'var(--map-control-background-color).darken(0.1)',
            },
        },
    },

    graph: {
        palette: 'default',
        label: {
            font: {
                family: 'var(--main-font-family)',
                size: '0.8rem',
                color: 'var(--main-font-color).lighten(0.5)',
                weight: 'var(--main-font-weight)',
            },
        },
    },

    modal: {

        font: {
            family: 'var(--content-font-family)',
            size: '1rem',
            color: 'var(--content-font-color)',
        },

        background: {
            color: 'var(--content-background-color)',
        },

        border: {
            width: '1px',
            color: 'var(--content-border-color)',
        },

        header: {
            font: {
                family: 'var(--header-font-family)',
                size: '1rem',
                color: 'var(--header-font-color)',
                weight: 'var(--header-font-weight)',
                transform: 'var(--header-font-transform)',
            },
            background: {
                color: 'var(--header-background-color)',
            },
            border: {
                width: '1px',
                color: 'var(--header-border-color)',
            },
        },

        footer: {
            font: {
                family: 'var(--footer-font-family)',
                size: '1rem',
                color: 'var(--footer-font-color)',
            },
            background: {
                color: 'var(--footer-background-color)',
            },
            border: {
                width: '1px',
                color: 'var(--footer-border-color)',
            },
        },

    },

};

export const THEME_GRAPH_PALETTES = {
    default: ['#69aaf8', '#f33636', '#F5B400', '#5F39BF', '#F47C2A', '#B199F4', '#86E57E', '#857986', '#6b0392', '#f05b4f', '#dda458', '#eacf7d', '#86797d', '#b2c326', '#6188e2', '#a748ca', '#69aaf8', '#F5B400', '#5F39BF', '#F47C2A', '#B199F4', '#86E57E', '#857986', '#6b0392', '#f05b4f', '#dda458', '#eacf7d'],
    nightrider: ['rgba(91, 189, 218, 0.8)', '#b32a2a', '#fdcdcd', '#cbccce', '#b0e1d8', '#eee9b4', '#d3c6d6', '#aaaaaa', '#88b7b8', '#e0bba9', '#ffda90', '#a8cca0', '#969abd', '#ba9db6'],
};
