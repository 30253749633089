<div class="p-3">
    <div class="heading">
        <kui-icon
            color="default"
            class="mr-3"
            size="sm"
            name="edit">
        </kui-icon>
        <h5 class="m-0">
            {{'LEAFLET.DRAW.DRAG_HANDLES' | translate}}
        </h5>
    </div>

    <div class="d-flex">
        <button
            class="mr-1 mt-1"
            kui-button
            (click)="saveMapEdits()">
            {{'LEAFLET.DRAW.SAVE' | translate}}
        </button>
        <button
            class="mr-1 mt-1"
            kui-button
            color="secondary"
            (click)="cancel()">
            {{'LEAFLET.DRAW.CANCEL' |translate}}
        </button>
    </div>
</div>