import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KuiTopNavComponent } from './top-nav.component';
import { KuiContentHeaderModule } from '../content-header';
import { KuiContentHeaderActionsModule } from '../content-header-actions';
import { KuiDropdownModule } from '../dropdown';
import { KuiIconModule } from '../icon';
import { KuiTabsModule } from '../tabs';
import { KuiTabBarModule } from '../tab-bar';
import { KuiNavModule } from '../nav';
import { TranslateModule } from '@ngx-translate/core';
import { NavPageLinkModule } from 'app/shared/directives/page-nav-link.module';

const COMPONENTS = [
  KuiTopNavComponent,
];

@NgModule({
  imports: [
    CommonModule,
    KuiContentHeaderModule,
    KuiContentHeaderActionsModule,
    KuiDropdownModule,
    KuiIconModule,
    KuiTabsModule,
    KuiTabBarModule,
    KuiNavModule,
    TranslateModule,
    NavPageLinkModule,
  ],
  exports: COMPONENTS,
  declarations: COMPONENTS,
})
export class KuiTopNavModule {}
