import { Component, Output, EventEmitter, Input, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilderDefinition, FormBuilderFieldChangeEvent } from 'app/shared/components/form-builder';

export interface YAxisMinMax {
    max?: string;
    min?: string;
}

export interface YAxis extends YAxisMinMax {
    show?: boolean;
}

export interface YAxisSettings {
    left?: YAxis;
    right?: YAxis;
}

export interface AvailableYAxesSettings {
    yAxes?: boolean;
}

export interface YAxisValues {
    'left.min.max'?: number[];
    'right.min.max'?: number[];
}

@Component({
    selector: 'key-analytics-settings-y-axis',
    templateUrl: './y-axis.component.html',
})
export class AnalyticsSettingsYAxisComponent implements OnChanges {
    form: FormBuilderDefinition;
    values: YAxisValues;

    @Input() options: YAxisSettings;
    @Input() show: AvailableYAxesSettings = {};
    @Input() limits: { left?: YAxisMinMax, right?: YAxisMinMax };
    @Output() onChange: EventEmitter<{ [key: string]: any; }> = new EventEmitter();

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.form) {
            this.form = this.getForm();
        }

        if (changes.options && this.options) {
            const { left, right } = this.options;

            this.values = {};

            if (left) {
                const min = parseInt(left.min, 0);
                const max = parseInt(left.max, 0);
                this.values['left.min.max'] = [
                    min == null ? null : min,
                    max == null ? null : max,
                ];
            }

            if (right) {
                const min = parseInt(right.min, 0);
                const max = parseInt(right.max, 0);
                this.values['right.min.max'] = [
                    min == null ? null : min,
                    max == null ? null : max,
                ];
            }
        }

        if (changes.limits && this.limits) {
            this.updateFormLimits(this.limits);
        }
    }

    changeFormSetting(change: FormBuilderFieldChangeEvent) {
        if (change.dirty) {
            const { id } = change.field;
            const [key, nestedKey, maxKey] = id.split('.');

            if (nestedKey) {
                this.onChange.emit({
                    [key]: maxKey
                        ? {
                            // maxmin field value is a number[], so we have to descructure it here to return { max: string, min: string }
                            [nestedKey]: (change.values[id][0] === 0 || change.values[id][0]) && change.values[id][0].toString(),
                            [maxKey]: (change.values[id][1] === 0 || change.values[id][1]) && change.values[id][1].toString(),
                        } : {
                            [nestedKey]: change.values[id],
                        },
                });
            } else {
                this.onChange.emit({ [id]: change.values[id] });
            }

        }
    }

    getForm(): FormBuilderDefinition {
        const form = { groups: [] };
        const { yAxes } = this.show;

        if (yAxes) {
            ['left', 'right'].forEach(side => form.groups.push({
                name: `${side.toUpperCase()}_Y_AXIS`,
                visible: true,
                fields: [
                    { type: 'minmax', id: `${side}.min.max`, title: 'MIN_MAX', required: false },
                ],
            }));
        }

        return form;
    }

    updateFormLimits(limits: { left?: YAxisMinMax, right?: YAxisMinMax }) {
        if (this.form) {
            this.form.groups.filter(group => group.fields.find(x => x.type === 'minmax')).forEach(group => {
                const field = group.fields[0];
                const side = field.id.split('.')[0];
                field.min = limits[side] && limits[side].min;
                field.max = limits[side] && limits[side].max;
            });
        }
    }
}
