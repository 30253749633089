

/* this is copied from the function in fleet-api/src/lib/rql.ts */
export function escapeRqlValue(value: string) {
    const result = encodeURIComponent(value.replace(/[%\/\\^$+?.()|[\]{}]/g, '\\$&'))
        .replace(/!/g, '%21')
        .replace(/~/g, '%7E')
        .replace(/\</g, '%3C')
        .replace(/\>/g, '%3E')
        .replace(/\(/g, '%28')
        .replace(/\)/g, '%29')
        .replace(/'/g, '%27')
        .replace(/\*/g, '%2A');
    return result; /*?*/
}
