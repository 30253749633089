import { Component, Input, ViewChild, HostBinding, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { MatchMediaService, MobileNavigationActionsService, NavigationService } from 'app/services';
import { BaseComponent } from 'app/shared/components/base/base.component';
import { TopNavService, TopNavMobileNavigationAction } from './top-nav.service';
import { KuiDropdownComponent } from '../dropdown/dropdown.component';
import { AppService } from 'app/app.service';
import { get } from 'lodash';
import { CurrentUser } from 'app/shared/model';
import { NavigationGroup } from 'app/services/navigation/navigation.service';
import { ContentHeaderAction, ContentHeaderTabItem, ContentHeaderTitle } from '../content-header/content-header.component';

@Component({
    selector: 'kui-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['top-nav.component.scss'],
    encapsulation: ViewEncapsulation.None, // NOTE: styling items on dropdown which lives outside this component, but are directly related to it... Make sure that all top-nav.component.scss styles are non-generic and has good specificity
})
export class KuiTopNavComponent extends BaseComponent {

    @Input() logo: string;
    @Input() avatar: string;
    @Input() clientname: string;

    @Input() user: CurrentUser;
    @Input() navigationGroups: NavigationGroup[];
    @Input() notification: boolean;

    @Output() onProfileAction = new EventEmitter<string>();

    alwaysShow: string[] = ['dashboards', 'user', 'demo', 'external']; // always show on mobile and desktop regardless the theme setting
    page: string;
    pageTitle: string;
    pageIcon: string;
    logoStyle = 'right';
    pageIsExternal = false;
    sectionTitle: string | ContentHeaderTitle;

    closeableTabs = false;
    draggableTabs = false;

    tabs: ContentHeaderTabItem[] = [];
    activeTab: ContentHeaderTabItem;
    actions: ContentHeaderAction[] = [];
    mobileNavigationAction: TopNavMobileNavigationAction;

    isMobile = false;
    mobileTabsOpen: boolean;
    @ViewChild('mobileTabs') mobileTabs: KuiDropdownComponent;

    style: 'hidden' | 'header' | 'navigation' = 'hidden';
    @HostBinding('class.hidden') hidden = true;

    constructor(
        private app: AppService,
        private matchMedia: MatchMediaService,
        public mobileActions: MobileNavigationActionsService,
        public service: TopNavService,
        public navigation: NavigationService
    ) {
        super();

        this.on(this.matchMedia.isMobileOrTablet, isMobile => {
            this.isMobile = isMobile;
            this.setHidden();
        });

        this.on(this.service.settings$, settings => {
            if (settings) {
                this.tabs = settings.tabs || [];
                this.activeTab = this.tabs.find(x => x.id === settings.activeTab);
                this.actions = settings.actions || [];
                this.mobileNavigationAction = settings.navigationAction || {
                    icon: 'menu',
                    click: () => this.mobileActions.toggleSidebar(),
                };

                const nav = this.navigation.getNavigationItems().find(x => x.id === settings.page);
                if (nav) {
                    this.page = nav.id;
                    this.pageIcon = nav.icon;
                    this.pageTitle = nav.name;
                    this.pageIsExternal = nav.external;
                    this.sectionTitle = settings.title || nav.name;
                }
                this.setHidden();
            }
        });

        this.on(this.app.theme$, theme => {
            this.style = get(theme.settings, 'topnavigation.style', 'hidden');
            this.logoStyle = get(theme.settings, 'topnavigation.logo.style', 'none');
            this.setHidden();
        });

    }

    setHidden() {
        const show = this.alwaysShow.find(x => x === this.page);
        this.hidden = !this.isMobile && show ? false : (this.pageIsExternal || this.isMobile || this.style === 'hidden');
    }
}
