import { Component, OnInit, AfterViewInit } from '@angular/core';
import { KuiModalRefComponent } from 'app/key-ui/modal/modal-ref.component';
import { IKuiModalAction } from 'app/key-ui';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../modal/modal.service';


export interface SelectionModalOptions {
    id: string;
    type: string;
}

export interface SelectionResult {
    id: string;
    name: string;
}

@Component({
    templateUrl: 'selection-modal.component.html',
    styleUrls: ['selection-modal.component.scss'],
})
export class SelectionModalComponent extends KuiModalRefComponent<SelectionModalOptions> implements OnInit, AfterViewInit {

    typeName: string;
    modalActions: IKuiModalAction[];

    constructor(
        private i18n: TranslateService
    ) {
        super();
    }

    static open(modal: ModalService, options: SelectionModalOptions): Promise<SelectionResult> {
        return new Promise<SelectionResult>((resolve, reject) => {
            try {
                modal.open<SelectionModalOptions>(SelectionModalComponent, {
                    data: options,
                    actions: {
                        close: () => {
                            modal.close();
                            resolve(null);
                        },
                        apply: (result: SelectionResult) => {
                            modal.close();
                            resolve(result);
                        },
                    },

                });
            } catch (err) {
                reject(err);
            }
        });

    }

    ngOnInit() {

        this.modalActions = [{
            text: this.i18n.instant('DIALOG.CANCEL'),
            style: 'secondary',
            keypress: 27,
            action: () => {
                this.actions.close(false);
            },
        }];

    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.data) {
                this.typeName = this.i18n.instant('ADMIN.ENTITIES.' + this.data.type.toUpperCase());
            }
        });
    }

    detachModal() {
        this.actions.close(false);
    }

    selectItem(item) {
        this.actions.apply(item);
    }


}
