 <ng-container *ngIf="hasNativePicker; else useFallback">
  <label class="form-control color-picker-label m-0 {{size}}" [class.transparent]="!backgroundColor" [for]="uniqueId" [style.background]="backgroundColor" [style.border-color]="borderColor"></label>
  <input
    class="visibility-hidden"
    type="color"
    [id]="uniqueId"
    [ngModel]="backgroundColor"
    (ngModelChange)="debounceChange($event)">
</ng-container>

<ng-template #useFallback>
   <label
    class="form-control color-picker-label {{size}}"
    [(colorPicker)]="backgroundColor"
    [style.background]="backgroundColor"
    [style.border-color]="borderColor"
    [class.transparent]="!backgroundColor"
    [cpPosition]="position"
    (colorPickerChange)="debounceChange($event)"></label>
</ng-template>
