import { Component, OnInit } from '@angular/core';
import { KuiModalRefComponent } from 'app/key-ui/modal/modal-ref.component';
import { IKuiModalAction } from 'app/key-ui';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: 'confirm-modal.component.html',
    styleUrls: ['confirm-modal.component.scss'],
})
export class AdminBulkConfirmModalComponent extends KuiModalRefComponent<{ changes: { name: string, value: string }[], count: number }> implements OnInit {

    constructor(private i18n: TranslateService) {
        super();
    }

    modalActions: IKuiModalAction[];

    ngOnInit() {

        this.modalActions = [{
            text: this.i18n.instant('DIALOG.CANCEL'),
            style: 'secondary',
            keypress: 27,
            action: () => {
                this.actions.close();
            },
        }, {
            text: this.i18n.instant('DIALOG.APPLY'),
            style: 'primary',
            keypress: 13,
            action: () => {
                this.actions.apply();
            },
        }];
    }


    detachModal() {
        this.actions.close();
    }
}
