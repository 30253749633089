import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppService } from 'app/app.service';
import { EventFeedItem } from '../../../feed.model';

export interface ExternalEventLink {
    title: string;
    url: string;
    type?: string;
}

@Component({
    selector: 'key-event-details-iframe',
    template: `
        <kui-alert type="danger" class="key-alert-wrapper" *ngIf="errorMessage">
            <kui-icon name="ban" size="sm"></kui-icon>
            <span>{{errorMessage}}</span>
        </kui-alert>
        <iframe width="100%" height="100%" frameBorder="0" [src]="url"  ></iframe>
    `,
})
export class EventDetailsIFrameComponent implements OnChanges {

    @Input() event: EventFeedItem;
    @Input() link: ExternalEventLink;

    url: SafeResourceUrl;
    errorMessage: string;

    constructor(
        public app: AppService,
        private sanitizer: DomSanitizer
    ) { }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.event || changes.link) {
            try {

                const bucket = {
                    asset: this.event.details?.asset?.id,
                    lon: this.event.details?.tel?.location?.lon,
                    lat: this.event.details?.tel?.location?.lat,
                };

                let url = this.link.url
                    .replace('{token}', this.app.api.accessToken)
                    .replace('{language}', this.app.user.language || 'en-us')
                    .replace('{user}', this.app.user.id)
                    .replace('{client}', this.app.client.id);

                for (const key in bucket) {
                    if (bucket[key]) {
                        url = url.replace('{' + key + '}', bucket[key]);
                    }
                }

                this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);

            } catch (err) {
                this.errorMessage = err?.message;
            }

        }
    }

}
