<div class="snackbar__item"
    [style.animation]="duration | snackbarAnimation: pause"
    (mouseleave)="pause = false"
    (mouseenter)="pause = true"
    (animationend)="onClosed.emit()"
    (click)="onClosed.emit()">
    <div class="snackbar__item-content p-3">
        <kui-icon *ngIf="icon"
            size="xl"
            class="snackbar__item-content__icon mr-3 mt-1 muted-more"
            [name]="icon"></kui-icon>
        <div *ngIf="title || description"
            class="snackbar__item-content__text">
            <h4 *ngIf="title"
                class="mb-1">{{title}}</h4>
            <div *ngIf="description">{{description}}</div>
        </div>
        <button *ngIf="actionText"
            class="snackbar__item-content__action ml-3"
            kui-button
            (click)="onActionClicked.emit()">{{actionText}}</button>
    </div>
</div>