import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { KuiModule } from 'app/key-ui';
import { PipeModule } from 'app/shared/pipes/pipe.module';
import { KeyFormBuilderModule } from '../form-builder/form-builder.module';
import { KeyGraphModule } from '../graph/graph.module';
import { KeyLayoutGridModule } from '../layout-grid/layout-grid.module';
import { KeyOptionsSidebarModule } from '../options-sidebar/options-sidebar.module';
import { AnalyticsDataTableComponent } from './data-table/data-table.component';
import { AnalyticsPivotTableComponent } from './pivot-table/pivot-table.component';
import { AnalyticsService } from './services/analytics.service';
import { AnalyticsSettingsAveragesComponent } from './settings/averages/averages.component';
import { AnalyticsSettingsFiltersComponent } from './settings/filters/filters.component';
import { AnalyticsSettingsLegendComponent } from './settings/legend/legend.component';
import { AnalyticsSettingsPresetsModalComponent } from './settings/presets/modal/modal.component';
import { AnalyticsSettingsPresetComponent } from './settings/presets/preset.component';
import { AnalyticsSettingsSeriesComponent } from './settings/series/series.component';
import { AnalyticsSettingsComponent } from './settings/settings.component';
import { AnalyticsSettingsSortComponent } from './settings/sort/sort.component';
import { AnalyticsSettingsTableComponent } from './settings/table/table.component';
import { AnalyticsSettingsYAxisComponent } from './settings/y-axis/y-axis.component';
import { AnalyticsSettingsXAxisComponent } from './settings/x-axis/x-axis.component';
import { AnalyticsTablesComponent } from './tables/tables.component';
import { AnalyticsChartComponent } from './chart/chart.component';
import { AnalyticsChartGraphComponent } from './chart/graph/graph.component';
import { AnalyticsCreateWidgetModalComponent } from './modals/create-widget/create-widget.component';
import { AnalyticsSettingsChartComponent } from './settings/chart/chart.component';
import { AnalyticsChartPieComponent } from './chart/pie/pie.component';
import { AnalyticsSettingsPieComponent } from './settings/pie/pie.component';
import { AnalyticsChartStatComponent } from './chart/stat/stat.component';
import { AnalyticsSettingsStatComponent } from './settings/stat/stat.component';
import { AnalyticsSettingsFilterSectionComponent } from './settings/filters/filter-section.component';
import { AnalyticsComponent } from './analytics.component';
import { AnalyticsSettingsInfoComponent } from './settings/info/info.component';

const COMPONENTS = [
    AnalyticsTablesComponent,
    AnalyticsPivotTableComponent,
    AnalyticsDataTableComponent,
    AnalyticsSettingsComponent,
    AnalyticsSettingsTableComponent,
    AnalyticsSettingsFiltersComponent,
    AnalyticsSettingsSeriesComponent,
    AnalyticsSettingsLegendComponent,
    AnalyticsSettingsYAxisComponent,
    AnalyticsSettingsXAxisComponent,
    AnalyticsSettingsSortComponent,
    AnalyticsSettingsPresetComponent,
    AnalyticsSettingsAveragesComponent,
    AnalyticsSettingsPresetsModalComponent,
    AnalyticsSettingsChartComponent,
    AnalyticsSettingsPieComponent,
    AnalyticsSettingsStatComponent,
    AnalyticsSettingsInfoComponent,
    AnalyticsChartComponent,
    AnalyticsChartGraphComponent,
    AnalyticsChartPieComponent,
    AnalyticsChartStatComponent,
    AnalyticsCreateWidgetModalComponent,
    AnalyticsSettingsFilterSectionComponent,
    AnalyticsComponent,
];

@NgModule({
    imports: [
        CommonModule,
        KuiModule,
        FormsModule,
        TranslateModule,
        KeyLayoutGridModule,
        PipeModule.forRoot(),
        KeyOptionsSidebarModule,
        KeyFormBuilderModule,
        KeyGraphModule,
    ],
    declarations: COMPONENTS,
    exports: COMPONENTS,
    providers: [AnalyticsService]
})
export class KeyAnalyticsModule { }
