<div [ngClass]="{
		'loader': true,
		'small': size === 'small',
		'inverse': inverse
	}">
    <ng-container *ngIf="style === 'spinner'; else innerDiv">
        <svg class="inner"
            viewBox="25 25 50 50">
            <circle class="path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke-width="2"
                stroke-miterlimit="10" />
        </svg>
    </ng-container>
    <ng-template #innerDiv>
        <div class="inner"></div>
    </ng-template>
</div>