<div [ngClass]="{
    'd-flex': true,
    'non-resizable-container': !resizable,
    'resizable-container': resizable,
    'resizable-container__right': resizable && direction === 'horizontal',
    'resizable-container__bottom': resizable && direction === 'vertical'
  }">

    <div key-layout-grid-column-inner [class.overflow-hidden]="!scroll">
        <kui-content-header [title]="title"
            [icon]="mobileActionIcon"
            [tabs]="header?.tabs"
            [activeTab]="getActiveTab(header?.tabs, header?.activeTab)"
            [actions]="header?.actions"
            (action)="mobileActionClick()"
            *ngIf="!!header">
        </kui-content-header>

        <ng-content></ng-content>
    </div>

    <key-layout-grid-column-resizer (isSelected)="setDragging($event)"
        (isDoubleClicked)="handleDoubleClick()"
        [ngClass]="{
        'show': resizable,
        'vertical': direction === 'vertical',
        'horizontal': direction === 'horizontal'
      }"></key-layout-grid-column-resizer>
</div>