import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AppService } from 'app/app.service';
import { AssetResponse, Contact } from '@key-telematics/fleet-api-client';
import { AssetGroupingService } from 'app/services';

@Component({
    selector: 'key-event-details-contacts',
    templateUrl: 'contacts.component.html',
    styleUrls: ['contacts.component.scss'],
})
export class EventDetailsContactsComponent implements OnChanges {

    @Input() assetId;

    asset: AssetResponse;
    contacts: Contact[];
    errorMessage: string;

    constructor(
        public app: AppService,
        private assetGrouping: AssetGroupingService
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.assetId) {
            Promise.all([
                this.app.api.entities.getAsset(this.assetId),
                this.assetGrouping.getAssetGroups(),
            ]).then(([asset, groups]) => {
                this.asset = asset;
                this.contacts = asset.contacts || [];
                // add all of the contacts from the asset groups that the asset belongs to
                groups.filter(x => !!asset.groups.find(g => g.id === x.id)).forEach(group => {
                    (group.contacts || []).forEach(contact => this.contacts.push(contact));
                });
            }).catch(err => {
                this.errorMessage = err && err.message;
            });
        }
    }

}
