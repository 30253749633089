import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilderField, BaseFormBuilderFieldComponent } from 'app/shared/components/form-builder';
import { NgModel } from '@angular/forms';
import { get, set } from 'lodash';

@Component({
    selector: 'key-form-builder-text-field',
    templateUrl: './text.component.html',
})
export class KeyFormBuilderTextFieldComponent implements BaseFormBuilderFieldComponent, OnInit {

    @Input() field: FormBuilderField;
    @Input() values: { [key: string]: any };
    @Input() error: string; // set this error value externally to have the default error highligh and display kick in
    @Input() pattern?: string;
    @Input() patternErrorMessage?: string;

    @Output() onChange: EventEmitter<{ value: string, dirty?: boolean }> = new EventEmitter();
    @ViewChild('textField', { static: true }) textField: NgModel;

    get value(): any {
        return this.field.getValue ? this.field.getValue(this.field, this.values) : get(this.values, this.field.id, undefined);
    }
    set value(val: any) {
        if (this.field.setValue) {
            const promise = this.field.setValue(this.field, this.values, val);
            if (promise && promise.then) {
                promise.then(() => this.ref.markForCheck());
            }
        } else {
            set(this.values, this.field.id, val);
        }
    }

    constructor(private ref: ChangeDetectorRef) { }

    validate(): boolean {
        this.textField.control.markAsTouched();
        return !this.textField.invalid;
    }

    ngOnInit() {
        if (this.field.value) {
            this.value = this.value || this.field.value;
        }
    }

    changeText(value: string) {
        const changed = value !== this.value;
        this.value = value;
        this.onChange.emit({ value, dirty: this.textField.dirty || changed });
    }
}
