import { Component, OnInit, Output, Input, EventEmitter, HostBinding, ChangeDetectionStrategy, Renderer2, ElementRef, SimpleChanges, OnChanges, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { ngIfAnimation, slideInOut } from '../utilities';

@Component({
    selector: 'kui-side-panel',
    templateUrl: './side-panel.component.html',
    animations: [
        ngIfAnimation,
        slideInOut,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KuiSidePanelComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

    @Input() absolute: boolean;
    @Input() align: 'right' | 'left' = 'right';
    @Input() width: string;

    @Input() tabs: string[];

    @Input() undo: boolean;
    @Input() redo: boolean;
    @Input() save: boolean;
    @Input() cancel: boolean;

    @Output() tabSelection = new EventEmitter<string>();

    @Output() onRedo = new EventEmitter<Event>();
    @Output() onUndo = new EventEmitter<Event>();
    @Output() onCancel = new EventEmitter<Event>();
    @Output() onSave = new EventEmitter<Event>();
    @Output() onSwipe = new EventEmitter<any>();

    @HostBinding('@ngIfAnimation') ngIfAnimation;
    @HostBinding('class.position-absolute') positionAbsolute;

    @HostListener('panright', ['$event']) swipeRight(e) {
        if (this.align === 'right') {
            this.onSwipe.emit(e);
        }
    }
    @HostListener('panleft', ['$event']) swipeLeft(e) {
        if (this.align === 'left') {
            this.onSwipe.emit(e);
        }
    }

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngOnInit() {
        this.renderer.addClass(this.el.nativeElement, this.align);
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.renderer.addClass(this.el.nativeElement, 'shadow');
        });
    }

    ngOnDestroy() {
        this.renderer.removeClass(this.el.nativeElement, 'shadow');
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.absolute) {
            this.positionAbsolute = this.absolute;
        }

        if (changes.width) {
            this.renderer.setStyle(this.el.nativeElement, 'width', this.width);
            this.renderer.setStyle(this.el.nativeElement, 'flex-basis', this.width);
        }
    }
}
