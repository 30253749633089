import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, AfterViewInit, SimpleChanges } from '@angular/core';
import { AppService } from 'app/app.service';
import { YAxes, YAxis, Average, XAxis, ChartConfigurationWithPlugins, Annotation, LegendItem, AXIS_ID } from './graph.model';
import { KeyGraphBaseComponent } from './base.component';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { merge } from 'lodash';
import * as Color from 'color';

@Component({
    selector: 'key-graph',
    templateUrl: './graph.component.html',
    styleUrls: ['./base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyGraphComponent extends KeyGraphBaseComponent implements OnChanges, AfterViewInit {

    @Input() yAxes: YAxes;
    @Input() xAxis: XAxis;
    @Input() averages: Average[];

    get chartSpecificConfig(): ChartConfigurationWithPlugins {
        return merge(this.baseConfig, {
            type: this.datasets && this.datasets.every(x => x.type === 'line') ? 'line' : 'bar',
            options: {
                annotation: {
                    annotations: this.getAnnotations(this.averages),
                },
                elements: {
                    point: {
                        radius: 0,
                        hitRadius: 10,
                    },
                },
                tooltips: {
                    callbacks: {
                        title: (tooltips: {index: number}[]) => {
                            const index = tooltips[0].index;
                            const label = this.labels[index];
                            if (label.hideTooltipTitle) { return null; }
                            return label.tooltipTitle || label.value;
                        },
                        label: tooltipItem => {
                            if (isNaN(+tooltipItem.yLabel)) { return null; }

                            const dataset = this.datasets[tooltipItem.datasetIndex];
                            const label = dataset.label;
                            const dataValue = dataset.data[tooltipItem.index].value;

                            const getValue = (value: string, prefix: string, suffix: string): string => {
                                if (prefix) {
                                    value = prefix + ' ' + value;
                                }
                                if (suffix) {
                                    value += ' ' + suffix;
                                }
                                return value;
                            };

                            if (!label || !dataValue) { return tooltipItem.yLabel; }
                            return (typeof label === 'string') ? `${label}: ${dataValue}` : `${label.name}: ${getValue(dataValue, label.prefix, label.suffix)}`;
                        },
                    },
                },
                scales: {
                    xAxes: [{
                        // TODO: add time support as well
                        type: 'category',
                        display: !(this.xAxis && this.xAxis.hidden),
                        ticks: {
                            fontFamily: this.axesLabelFont,
                            fontColor: this.axesLabelColor,
                            fontSize: this.axesLabelSize,
                            minRotation: 0,
                            maxRotation: this.xAxis && this.xAxis.autoLabels ? 90 : 0,
                            autoSkip: false,
                            callback: x => this.xAxis && this.xAxis.format ? this.xAxis.format(x) : x,
                        },
                        stacked: this.stacked,
                        gridLines: {
                            display: false,
                            drawBorder: false,
                        },
                    }],
                    yAxes: this.getYAxes(this.yAxes),
                },
            },
            plugins: [ChartDataLabels, {
                id: 'loadingCallback',
                // expecting chart to be of type Chart, but am being forced to get keys only available on runtime.
                // the joys of working with chartjs
                beforeDraw: (chart: any) => {
                    // check how many times the longest label fits into the chart width and reduce the amount of labels to that
                    const { longestLabelWidth, ticks } = chart.scales[AXIS_ID.X];
                    // if autoLabels is true the labels will rotate up to 90deg. so instead of (width + padding) we take a guesstimate of the text height plus a little padding (I went with 20)
                    const maxLabels = Math.ceil(chart.chart.width / (this.xAxis && this.xAxis.autoLabels ? 20 : longestLabelWidth + 20));
                    const max = Math.ceil(ticks.length / maxLabels);

                    if (ticks.length > maxLabels) {
                        chart.scales[AXIS_ID.X]._ticks = ticks.map((tick, index) => ({ label: index % max === 0 ? tick : '', major: false }));
                    } else {
                        chart.scales[AXIS_ID.X]._ticks = ticks.map(tick => ({ label: tick, major: false }));
                    }
                },
            }],
        });
    }

    constructor(public changes: ChangeDetectorRef, public app: AppService) {
        super(changes, app);
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }

    updateGraphConfig() {
        const fadeColor = color => Color(color).fade(.4).string();

        this.config = {
            ...this.chartSpecificConfig,
            data: {
                labels: this.labels.map(x => x.value),
                datasets: this.datasets && this.datasets.map((x, i) => ({
                    ...this.getDataset(x, i),
                    backgroundColor: x.fill ? fadeColor(x.color || this.colors && this.colors[i] || 'rgba(47, 166, 212, 0.6)') : 'rgba(0, 0, 0, 0)',
                    borderColor: x.color || this.colors && this.colors[i] || 'rgb(47, 166, 212)',
                    type: x.type || 'bar',
                    fill: x.fill ? 'start' : false,
                    lineTension: x.curvedLines ? .3 : 0,
                    steppedLine: x.steppedLines,
                    yAxisID: x.yAxis || 'left',
                    borderDash: x.borderDash || [],
                })),
            },
        };

        this.updateGraph();
    }

    getYAxes(yAxes: YAxes = { left: {} }): Chart.ChartYAxe[] {
        return Object.entries(yAxes)
            .map(([position, options]: [string, YAxis]) => ({
                display: !options.hidden,
                id: position,
                position,
                type: 'linear',
                stacked: this.stacked,
                ticks: {
                    min: options.min,
                    max: options.max,
                    fontFamily: this.axesLabelFont,
                    fontColor: this.axesLabelColor,
                    fontSize: this.axesLabelSize,
                    callback: value => {
                        return options.labelFn ? options.labelFn(value) : this.abbreviateNumber(value);
                    },
                },
            }));
    }

    getAnnotations(items: Average[] = []): Annotation[] {
        return items.map(item => {
            const value = item['selectedMeasureFormat'] === 'duration' ? (Number(item.value) / 3600) : item.value;

            return {
                type: 'line',
                mode: item.mode || 'horizontal',
                scaleID: item.axis,
                value: value,
                borderDash: item.borderDash || [5, 10],
                borderColor: item.color || 'rgba(0, 0, 0, .4)',
                borderWidth: item.borderWidth || 1,
                label: {
                    enabled: !!item.label,
                    content: item.label,
                    fontStyle: 'normal',
                    backgroundColor: 'rgba(0, 0, 0, .8)',
                    fontSize: 12,
                    cornerRadius: 3,
                    position: 'right',
                    yPadding: 3,
                },
            };
        });
    }

    clickLegend(item: LegendItem, index: number) {
        const hidden = !item.hidden;
        this.chart.data.datasets[index].hidden = hidden;
        this.datasets[index].hidden = hidden;
        this.onDatasetHidden.emit({
            dataset: this.datasets[index],
            hidden: hidden,
        });
        this.chart.update(0);
    }
}
