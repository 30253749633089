import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KuiButtonDirective } from './button.directive';
import { MatchMediaServiceModule } from 'app/services/match-media/match-media.module';

@NgModule({
    imports: [CommonModule, MatchMediaServiceModule],
    exports: [KuiButtonDirective],
    declarations: [KuiButtonDirective],
})
export class KuiButtonModule { }
