import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppService } from 'app/app.service';
import { Location } from '@angular/common';
import * as moment from 'moment-timezone';
import { userDateToUrlDate } from 'app/shared/utils/dates';
import { TranslateService } from '@ngx-translate/core';
import { uniq } from 'lodash';
import { PAGE_ICONS } from 'app/app.features';
import { getTripId } from '../trips/trips.service';
import { MatchMediaService } from '../match-media/match-media.service';


interface PageDefinition {
    name: string | ((app: AppService) => string);
    icon?: string | ((app: AppService) => string);
    group?: string | ((app: AppService) => string);
    route?: string;
    external?: boolean; // set to true to have a blank page with no headers
    visible?: (app: AppService, isMobile?: boolean) => boolean;
    children?: { [key: string]: PageDefinition };
    getChildren?: (app: AppService) => { [key: string]: PageDefinition };
    order?: number | ((app: AppService) => number);
}

export interface NavigationGroup {
    id: string;
    name: string;
    items: NavigationItem[];
}

export interface NavigationItem {
    id: string;
    external: boolean;
    icon?: string;
    name: string;
    group: string;
    order: number;
    children?: NavigationItem[];
}

function getExternalApplicationChildren(app: AppService): { [key: string]: PageDefinition }  {
    return app.features.page.external.tabs.split('\n').reduce((res, tab) => {
        const info = tab.split(':')[0];
        const [name, icon, section] = info.split('|');
        if (!section) { // don't include items which have a seperate section
            res[name.replace(' ', '').toLowerCase()] = { name, icon, group: (section || '').toLowerCase() };
        }
        return res;
    }, {});
}

@Injectable()
export class NavigationService {

    get PAGES(): { [key: string]: PageDefinition } {
        return {
            status: {
                name: (app: AppService): string => {
                    return app.features.page.overview.name || this.i18n.instant(`MENU.STATUS`);
                },
                icon: (app: AppService): string => {
                    return app.features.page.overview.icon || PAGE_ICONS.overview;
                },
                group: (app: AppService): string => {
                    return app.features.page.overview.group || 'status';
                },
                order: (app: AppService): number => {
                    return app.features.page.overview.order || 0;
                },
                visible: (app: AppService): boolean => {
                    return app.features.page.overview.enabled;
                },
            },
            geofences: {
                route: "status?geofence=true",
                name: this.i18n.instant(`MENU.GEOFENCES`),
                icon: 'draw-polygon',
                visible: (app: AppService, isMobile: boolean): boolean => {
                    return app.features.page.geofence.enabled && !isMobile;
                },
                group: (app: AppService): string => {
                    return app.features.page.geofence.group || 'status';
                },
                order: (app: AppService): number => {
                    return app.features.page.geofence.order || 0;
                },
            },
            alerts: {
                name: this.i18n.instant(`MENU.ALERTS`),
                icon: PAGE_ICONS.alerts,
                visible: (app: AppService): boolean => {
                    return app.features.page.alerts.enabled;
                },
                group: (app: AppService): string => {
                    return app.features.page.alerts.group || 'status';
                },
                order: (app: AppService): number => {
                    return app.features.page.alerts.order || 0;
                },
            },
            videos: {
                name: this.i18n.instant(`MENU.VIDEOS`),
                icon: PAGE_ICONS.videos,
                visible: (app: AppService): boolean => {
                    return app.features.page.videos.enabled;
                },
                group: (app: AppService): string => {
                    return app.features.page.videos.group || 'status';
                },
                order: (app: AppService): number => {
                    return app.features.page.videos.order || 0;
                },
            },
            dashboards: {
                name: this.i18n.instant(`MENU.DASHBOARDS`),
                icon: PAGE_ICONS.dashboards,
                visible: (app: AppService): boolean => {
                    return app.features.page.dashboards.enabled;
                },
                group: (app: AppService): string => {
                    return app.features.page.dashboards.group || 'status';
                },
                order: (app: AppService): number => {
                    return app.features.page.dashboards.order || 0;
                },
            },
            external: {
                name: this.i18n.instant(`MENU.EXTERNAL`),
                icon: PAGE_ICONS.external,
                group: 'status',
                visible: (app: AppService): boolean => {
                    return app.features.page.external.enabled && Object.keys(getExternalApplicationChildren(app)).length > 0;
                },
                getChildren: (app: AppService) => {
                    return getExternalApplicationChildren(app);
                },
            },
            history: {
                name: this.i18n.instant(`MENU.HISTORY`),
                icon: PAGE_ICONS.replay,
                visible: (app: AppService): boolean => {
                    return app.features.page.replay.enabled;
                },
                group: (app: AppService): string => {
                    return app.features.page.replay.group || 'history';
                },
                order: (app: AppService): number => {
                    return app.features.page.replay.order || 0;
                },
            },
            mapsearch: {
                name: this.i18n.instant(`MENU.MAPSEARCH`),
                icon: PAGE_ICONS.mapsearch,
                visible: (app: AppService): boolean => {
                    return app.features.page.mapsearch.enabled;
                },
                group: (app: AppService): string => {
                    return app.features.page.mapsearch.group || 'history';
                },
                order: (app: AppService): number => {
                    return app.features.page.mapsearch.order || 0;
                },
            },
            reporting: {
                name: this.i18n.instant(`MENU.REPORTING`),
                icon: PAGE_ICONS.reporting,
                visible: (app: AppService): boolean => {
                    return app.features.page.reporting.enabled;
                },
                group: (app: AppService): string => {
                    return app.features.page.reporting.group || 'history';
                },
                order: (app: AppService): number => {
                    return app.features.page.reporting.order || 0;
                },
            },
            admin: {
                name: this.i18n.instant(`MENU.ADMIN`),
                icon: PAGE_ICONS.admin,
                visible: (app: AppService): boolean => {
                    return app.features.page.admin.enabled || (app.user && app.user.owner.type !== 'client');
                },
                group: (app: AppService): string => {
                    return app.features.page.admin.group || 'admin';
                },
                order: (app: AppService): number => {
                    return app.features.page.admin.order || 0;
                },
            },
            demo: {
                name: 'Demo',
                icon: PAGE_ICONS.demo,
                group: 'admin',
                visible: (app: AppService): boolean => {
                    return app.user && app.user.owner.type === 'system';
                },
                order: 1000,
                children: {
                    kui: {
                        name: 'Kui',
                    },
                    'form-builder': {
                        name: 'Form Builder',
                    },
                    maps: {
                        name: 'Maps',
                    },
                    themes: {
                        name: 'Themes',
                    },
                    scratchpad: {
                        name: 'Scratchpad',
                    },
                },
            },
            system: {
                name: this.i18n.instant(`MENU.SYSTEM`),
                icon: PAGE_ICONS.system,
                group: 'system',
                visible: (app: AppService): boolean => {
                    return app.user && app.user.owner.type === 'system';
                },
            },
            user: {
                name: this.i18n.instant(`MENU.USER`),
                children: {
                    profile: {
                        name: this.i18n.instant(`MENU.USER_PROFILE`),
                    },
                    notifications: {
                        name: this.i18n.instant(`MENU.USER_NOTIFICATIONS`),
                    },
                },
            },
            about: {
                name: this.i18n.instant(`MENU.ABOUT`),
            },
            login: {
                external: true,
                name: this.i18n.instant(`MENU.LOGIN`),
            },
            'client-select': {
                external: true,
                name: this.i18n.instant(`MENU.CLIENT_SELECT`),
            },
            'user-select': {
                external: true,
                name: this.i18n.instant(`MENU.USER_SELECT`),
            },
            welcome: {
                external: true,
                name: this.i18n.instant(`MENU.WELCOME`),
            },
            otp: {
                external: true,
                name: this.i18n.instant(`MENU.OTP`),
            },
            shared: {
                name: 'Shared',
                external: true,
            },
        };
    }
    clientPages: { [key: string]: PageDefinition }; // all computed pages for the current client

    urlHistory: string[] = [];
    get previousUrl(): string {
        return this.urlHistory[this.urlHistory.length - 2] || '';
    }

    private activeRoot = new BehaviorSubject<string>('status');
    activeRoot$ = this.activeRoot.asObservable();
    activePage: PageDefinition;

    private itemsSubject = new Subject<NavigationItem[]>();
    items$ = this.itemsSubject.asObservable();

    private isMobile = false;

    constructor(
        private app: AppService,
        private i18n: TranslateService,
        private matchMedia: MatchMediaService,
        private router: Router,
        private location: Location

    ) {
        this.router.events
            .subscribe(event => {
                if (event instanceof NavigationEnd) {
                    if (event instanceof NavigationEnd && event.urlAfterRedirects.indexOf('redirecting=') === -1) {
                        this.urlHistory.push(event.urlAfterRedirects);
                    }
                    this.setPath(event.urlAfterRedirects || event.url);
                }
            });

        this.clientPages = { ...this.PAGES };
        
        this.setPath(this.location.path());

        this.matchMedia.isMobile.subscribe(res => this.isMobile = res);

    }

    private setPath(url: string) {

        const root = Object.keys(this.clientPages).find(x => url.indexOf(x) === 1);
        if (root) {
            this.activePage = this.clientPages[root];
            const children = this.activePage.children || this.activePage.getChildren && this.activePage.getChildren(this.app) || [];
            const child = Object.keys(children).find(x => url.includes(x));
            this.activeRoot.next(child ? root + '/' + child : root); // TODO: this currently only supports a single child root... If we ever have to support multiple nested children, slap yourself in the face first and then amend this
        }
    }

    getNavigationGroups(): NavigationGroup[] {
        const items = this.getNavigationItems();
        const groups = uniq(items.map(item => item.group).filter(x => x));
        return groups.map(key => {
            let name: string = this.i18n.instant(`MENU.GROUP.${key.toUpperCase()}`);
            if (name.startsWith('MENU.')) { // there is no translation
                name = key;
            }
            
            const b = {
                id: key,
                name: name,
                items: items.filter(x => x.group === key).sort((a, b) => a.order - b.order),
            };
            return b;
        });
    }

    getNavigationItems(group?: string): NavigationItem[] {
        const getNavigationItem = (key: string, item: PageDefinition): NavigationItem => {
            const children = item.getChildren ? item.getChildren(this.app) : item.children;
            return {
                id: item.route ?? key,
                external: item.external,
                icon: typeof item.icon === 'string' ? item.icon : item.icon && item.icon(this.app),
                group: typeof item.group === 'string' ? item.group.toLowerCase() : item.group && item.group(this.app).toLowerCase(),
                name: typeof item.name === 'string' ? item.name : item.name(this.app),
                children: children && Object.keys(children).map(id => getNavigationItem(id, children[id])),
                order: typeof item.order === 'number' ? item.order : item.order && item.order(this.app),
            };
        };

        const result: NavigationItem[] = [];
        this.clientPages = {
            ...this.PAGES,
            ...this.app.features.page.external.tabs.split('\n').reduce((res, tab) => {
                if (tab) {
                    const info = tab.split(':')[0];
                    const [name, icon, section, order] = info.split('|');
                    if (section) { // only load items that have a section defined into the main navigation
                        const id = 'app-' + name.replace(/[^a-z0-9]/ig, '-').toLowerCase();
                        res[id] = {
                            name,
                            icon,
                            group: (section || '').toLowerCase(),
                            order: Number(order)
                        };
                    }
                }
                return res;
            }, {}),
        };


        Object.keys(this.clientPages).forEach(key => {
            const item = this.clientPages[key];
            if (!item.visible || item.visible(this.app, this.isMobile) && (!group || item.group === group)) {
                result.push(getNavigationItem(key, item));
            }
        });
        return result;
    }


    navigateToPage(page: string, child?: string): Promise<boolean> {
        const path = child ? `${page}/${child}` : page;
        const url = '/' + path;
        return this.router.navigateByUrl(url)
            .catch(err => { // should the reuse strategy cause errors for some reason, clear the reuse cache and try again.
                console.error(`navigateToPage('${page}${child ? ', ' + child : ''}')[1]`, err);
                return this.router.navigateByUrl(url)
                    .catch(err2 => { // should that fail, try and navigate to the root of the page
                        console.error(`navigateToPage('${page}${child ? ', ' + child : ''}')[2]`, err2);
                        if (url !== '/' + page) {
                            return this.router.navigateByUrl('/' + page);
                        } else {
                            throw err2;
                        }
                    });
            });
    }


    navigateToTrip(assetId: string, trip: { dateStart: string }) {
        if (trip) {
            this.router.navigate(['/history', userDateToUrlDate(moment.utc(trip.dateStart).tz(moment.defaultZone.name)), assetId, getTripId(trip), 'details']);
        } else {
            this.router.navigate(['/history', 'today', assetId]);
        }
    }

    navigateToDefault() {
        const items = this.getNavigationItems();
        // there should always be some page enabled, but to be safe we redirect to welcome if a user for some reason disabled all pages
        this.router.navigate([`/${items.length > 0 ? items[0].id : 'profile'}`]);
    }

}

