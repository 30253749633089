<span class="feed-entry flex-column"
    (click)="selected.emit(data)">
    <div kui-action
        class="d-flex flex-1"
        [ngClass]="classNames"
        >
        <key-feed-entry-info>
            <span id="info-title">
                <span [title]="data | getKey: 'date' | date: 'yyyy-MM-dd HH:mm:ss'">{{ data | getKey: 'date' | units: { unit: 'time', variant: 'short'} }}</span>
            </span>
            <span id="info-small-text"
                class="small muted">{{ getDisplayDate(data.date) }}</span>
        </key-feed-entry-info>

        <div class="feed-entry-details">
            <div class="d-flex flex-wrap">
                <div class='entry-text flex-1 mr-3 mb-2'>

                    <div class="entry-title accent">
                        <div class="filter-link"
                            [title]=" 'VIDEOS.LIST.CLICK_TO_FILTER' | translate"
                            (click)="showFilterByValue && filterBy($event, 'asset')">{{title}}</div>
                    </div>
                    <div class="entry-description muted-less mb-2">
                        {{description}}
                    </div>

                    <div class="filters">
                        <div class="small"
                            *ngFor="let item of info">
                            <kui-icon name='star'
                                [fixedWidth]="true"
                                class="mr-1"></kui-icon>
                            <span class="filter-link"
                                [title]=" 'VIDEOS.LIST.CLICK_TO_FILTER' | translate"
                                (click)="showFilterByValue && filterBy($event, item.type)">{{item.title}}</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    </div>

    <div class="media-thumbnail mb-2"
        *ngIf="thumbnailUrls">
        <key-video-thumbnail *ngFor="let url of thumbnailUrls"
            [url]="url">
        </key-video-thumbnail>
    </div>

</span>