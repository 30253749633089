import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, Inject, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { SortEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { IKuiTableCol } from './table.model';
import { KuiTableService } from './table.service';

export interface SortingEvent {
    field: string;
    order: string;
}

export interface TableFilterEvent {
    col: IKuiTableCol;
    filter: string | string[];
    operator?: 'OR' | 'AND';
}

@Component({
    selector: 'kui-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KuiTableComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    _cols: IKuiTableCol[];
    _activeRows: { [key: string]: number | string }[];

    viewMounted: boolean;

    scrollHeight: string;

    windowResize: ISubscription;
    rowGroupMetadata: any; // see https://www.primefaces.org/primeng-6.1.6/#/table/rowgroup

    @Input() rows: any[] = [];
    @Input() activeRows: { [key: string]: number | string }[];
    @Input() cols: IKuiTableCol[];
    @Input() sort: boolean;
    @Input() resize: boolean;
    @Input() selectionMode: 'single' | 'multiple' = 'single';
    @Input() selection: boolean;
    @Input() selectionColumn: string;
    @Input() borders = true;
    @Input() nowrap: boolean;
    /** the height of the parent container */
    @Input() height: number;
    @Input() sortField: string;
    @Input() sortOrder: 'asc' | 'desc';
    @Input() sortGrouping = false;
    @Input() pageSize: number;

    @Input() showFilters = false;
    @Input() checkBoxSelection = false;

    @Output() onRowSelect: EventEmitter<any> = new EventEmitter();
    @Output() onRowsSelect: EventEmitter<any[]> = new EventEmitter();
    @Output() onCollResize: EventEmitter<IKuiTableCol> = new EventEmitter();
    @Output() onCollReoreder: EventEmitter<IKuiTableCol[]> = new EventEmitter();
    @Output() onSort: EventEmitter<SortingEvent> = new EventEmitter();

    @Output() onFilter = new EventEmitter<TableFilterEvent>();

    @ViewChild('dataTable', { static: true }) table: Table;
    @ViewChild('container', { static: true }) container: ElementRef;
    @ViewChild('scrollbarDetector', { static: true }) scrollbarDetector: ElementRef;

    @HostBinding('class.nowrap') nowrapClassBinding;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private tableService: KuiTableService) {

    }

    ngOnInit() {}

    ngAfterViewInit() {
        this._activeRows = this.activeRows;
        this.viewMounted = true;
    }

    ngOnChanges(changes) {
        if (changes.cols) {
            this._cols = [];

            this.cols.forEach(col => {
                const column = {
                    ...col,
                    width: col.width || 150,
                    sortable: col.sortable !== undefined ? col.sortable : true,
                };


                if (col.filter && col.filter.values) {
                    if (!col.filter.operator) {
                        // OR is the default value to keep existing behaviour
                        col.filter.operator = {
                            value: 'OR',
                            visible: false
                        }
                    }
                    column['toggleMenu'] = col.filter.values.map(item => ({
                        type: 'toggle',
                        text: item.value,
                        indent: item?.indent || 0,
                        checked: !!item.selected,
                        closeDropdownOnClicked: false,
                        action: (e: any) => {
                            item.selected = e.target.checked;
                            this.onFilter.emit({ 
                                col: col, 
                                filter: col.filter.values.filter(x => x.selected).map(x => x.id),
                                operator: col.filter.operator.value
                            });
                        },
                    }));
                }

                this._cols.push(column);
            });
        }

        if (changes.activeRows && this.activeRows && this.activeRows.length > 0) {
            this._activeRows = this.activeRows;
            this.selectRow({ data: this.activeRows[0], originalEvent: null, type: null });
        }

        if (changes.rows) {
            this.updateRowGroupMetaData();
        }

    }

    ngOnDestroy() {
        if (this.windowResize) {
            this.windowResize.unsubscribe();
        }
    }

    /**
    * Convenience method that selects the table rows and columns for you.
    * Can inject the table.service in your parent component and export your own cols and rows.
    */
    public exportCSV(filename: string) {
        this.tableService.exportCSV(this._cols, this.rows, filename);
    }

    isTypeOf(value, type): boolean {
        return typeof value === type;
    }

    sortByColumn(event: SortEvent) {
        if (this.sort) {
            const col = this.cols.find(x => x.field === event.field);
            if (col && col.compare) {
                return event.data.sort((a, b) => col.compare(col, a, b) * event.order);
            } else {
                return event.data.sort((data1, data2) => {
                    const value1 = data1[event.field] && data1[event.field]['value'] || data1[event.field] || null;
                    const value2 = data2[event.field] && data2[event.field]['value'] || data2[event.field] || null;
                    let result = null;

                    if (value1 === null && value2 !== null) {
                        result = -1;
                    } else if (value1 !== null && value2 === null) {
                        result = 1;
                    } else if (value1 === null && value2 === null) {
                        result = 0;
                    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
                        result = value1.localeCompare(value2);
                    } else {
                        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
                    }

                    return (event.order * result);
                });
            }
        }
    }

    selectRow(item: { data: any, originalEvent: MouseEvent | KeyboardEvent, type: string }, scrollIntoView: boolean = true) {
        if (this.selection) {
            this.onRowSelect.emit(item.data);
            setTimeout(() => {
                if (item.originalEvent instanceof KeyboardEvent) {
                    // if we're using the keyboard to move up and down, _activeRows never gets updated due to the keyboard hack.
                    this._activeRows = [item.data].filter(x => x);
                } else {
                    // in order to support both single selection and checkbox selection we need to convert the
                    // _activeRows back to an array each time that the user single selects, or the table component
                    // will throw an error the next time you try and do a checkbox select
                    if (!Array.isArray(this._activeRows)) {
                        this._activeRows = [item.data].filter(x => x);
                    }
                }
                this.onRowsSelect.emit(this._activeRows);
            });

            // scroll row into view
            if (scrollIntoView) {
                const row = (this.document as HTMLDocument).querySelector('.select-row-' + this.rows.indexOf(item.data)) as HTMLTableRowElement;
                const scrollBody = this.container.nativeElement.querySelector('.ui-table-scrollable-body');
                if (row && scrollBody) {
                    const scrollBottom = scrollBody.scrollTop + scrollBody.clientHeight;
                    if (row.offsetTop < scrollBody.scrollTop || row.offsetTop > scrollBottom) {
                        scrollBody.scrollTop = row.offsetTop;
                    }
                }
            }
        }
    }

    unselectRow(_item: { data: any, originalEvent: MouseEvent, type: string }) {
        if (this.selection) {
            this.onRowSelect.emit(null);
            setTimeout(() => {
                // in order to support both single selection and checkbox selection we need to convert the
                // _activeRows back to an array each time that the user single selects, or the table component
                // will throw an error the next time you try and do a checkbox select
                if (!Array.isArray(this._activeRows)) {
                    this._activeRows = [];
                }
                this.onRowsSelect.emit(this._activeRows);
            });
        }
    }


    resizeCol(selection: { delta: number, element: HTMLTableHeaderCellElement }) {
        const index = selection.element.cellIndex;
        const width = this._cols[index].width;

        this.onCollResize.emit({
            ...this.cols[index],
            width: typeof width === 'number'
                ? width + selection.delta
                : parseFloat(width) + selection.delta,
        });
    }

    reorderCol(event: { dragIndex: number, dropIndex: number, columns: IKuiTableCol[] }) {
        this.onCollReoreder.emit(event.columns);
    }

    getScrollbarWidth(): number {
        const widthNoScroll = this.scrollbarDetector.nativeElement.offsetWidth;
        const widthWithScroll = this.scrollbarDetector.nativeElement.querySelector('div').offsetWidth;

        return widthNoScroll - widthWithScroll;
    }

    updateSorting(event: { field: string; order: number }) {
        const sortOrder = (event.order > 0) ? 'asc' : 'desc';

        // the p-table sends out garbage before it's instantiated
        if (!event.field) {
            return;
        }

        this.onSort.emit({
            field: event.field,
            order: sortOrder,
        });
        this.updateRowGroupMetaData();
    }

    getCellValue(col: IKuiTableCol, row: any): string {
        if (col.format) {
            return col.format(col, row);
        } else {
            return this.isTypeOf(row[col.field], 'object') ? row[col.field].display || row[col.field].field : row[col.field];
        }
    }


    filterColumn(col: IKuiTableCol, filter: any) {
        this.onFilter.emit({ col: col, filter: filter });
    }

    getCheckListText(col: IKuiTableCol): string {
        return col.filter.values.filter(x => x.selected).map(x => x.value).join(', ');
    }

    updateFilterOperator(col: IKuiTableCol, operator: 'AND' | 'OR') {
        col.filter.operator.value = operator;
        this.onFilter.emit({ 
            col: col, 
            filter: col.filter.values.filter(x => x.selected).map(x => x.id),
            operator: col.filter.operator.value
        });
    }

    // see https://www.primefaces.org/primeng-6.1.6/#/table/rowgroup
    updateRowGroupMetaData() {
        this.rowGroupMetadata = {};
        if (this.rows && this.sortGrouping && this.sortField) {
            for (let i = 0; i < this.rows.length; i++) {
                const row = this.rows[i];
                const group = row[this.sortField] || '';
                const metadata = this.rowGroupMetadata[group] = this.rowGroupMetadata[group] || { index: i, size: 0 };
                metadata.size++;
            }
        }
    }


}
