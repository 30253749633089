import { environment } from 'environments/environment';
import 'proxy-polyfill/proxy.min';

export class LocalStore {
    prefix = environment.storePrefix;
    isLocalStorageAvailable = null;

    volatileStore = {};

    constructor(protected localPrefix) {
        this.prefix += `-${this.localPrefix}`;

        // Check if storage is available at all
        try {
            if ('localStorage' in window) {
                localStorage.setItem('storage.test', 'test');
                localStorage.removeItem('storage.test');
            } else {
                throw new Error();
            }

            this.isLocalStorageAvailable = true;
        } catch (e) {
            this.isLocalStorageAvailable = false;
        }
    }

    get<T>(key: string): T {
        if (!this.isLocalStorageAvailable) {
            return JSON.parse(this.volatileStore[`${this.prefix}-${key}`] || null) as T;
        }

        const value = localStorage.getItem(`${this.prefix}-${key}`);
        return JSON.parse(value || 'null') as T;
    }

    set<T>(key: string, value: T): T {
        // additional try is done here since it can change and Safari <=10 only errors out on writes
        if (!this.isLocalStorageAvailable) {
            this.volatileStore[`${this.prefix}-${key}`] = JSON.stringify(value);
        } else {
            localStorage.setItem(`${this.prefix}-${key}`, JSON.stringify(value));
        }

        return value;
    }

    /**
     * Pass in an object to get a proxied object back that will automatically have any
     * changes to it's propertied persisted to local storage. Note that change detection
     * only works on shallow properties.
     */
    watchState<T extends object>(key: string, defaults: T): T {
        const storeState = this.get<any>(key);
        const state = storeState ? { ...(defaults as any), ...storeState } : defaults;

        return new Proxy(state, {
            set: (_target: any, prop: string, value: any): boolean => {
                state[prop] = value;
                this.set(key, state);
                return true;
            },
        });
    }

}

/** Useful for tests or where results should not persist over sessions or pages */
export class MemoryStore extends LocalStore {

    store = {};

    get<T>(key: string): T {
        const value = this.store[`${this.prefix}-${key}`];
        return JSON.parse(value || 'null') as T;
    }

    set<T>(key: string, value: T): T {
        this.store[`${this.prefix}-${key}`] = JSON.stringify(value);
        return value;
    }

    reset() {
        this.store = {};
    }
}
