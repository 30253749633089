@if(orderedParts()) {
    <div class="text">
        {{ index() | conditionPrefix }}
        @for (part of orderedParts(); track part.key) {
            @switch (part.type) {
                @case ('text') {
                    <span>{{part.text}}</span>
                }
                @case ('dropdown') {
                    <kui-dropdown
                        #inlineDropdown
                        [style]="'none'"
                        [customContent]="inlineDropdownContent"
                        [customContentContext]="{ component: inlineDropdown }">
                        <a [class.unset]="!part.value">{{part.text}}</a>
                    </kui-dropdown>
                    <ng-template #inlineDropdownContent
                        let-component="component">
                        <div style="min-width: 200px">
                            <key-form-builder-eventfilter-searchable-tree [entityType]="part.key"
                                [ownerId]="ownerId()"
                                [nodes]="part.values"
                                (onChange)="onConditionOptionSelected(component, $event, part)"></key-form-builder-eventfilter-searchable-tree>
                        </div>
                    </ng-template>
                }
                @case ('checklist') {
                    <kui-dropdown
                        #inlineChecklist
                        [style]="'none'"
                        [menu]="part.values">
                        <a [class.unset]="!part.value">{{part.text | checklistFormat}}</a>
                    </kui-dropdown>
                }
                @case ('placeholder') {
                    <span>
                        <span>{{part.text}}</span>
                        <kui-loader size="small"></kui-loader>
                    </span>
                }
                @default {
                    <kui-dropdown
                        #editorDropdown
                        [style]="'none'"
                        [customContent]="editorDropdownContent"
                        [customContentContext]="{ component: editorDropdown }">
                        <a [class.unset]="!part.value">{{part.text}}</a>
                    </kui-dropdown>
                    <ng-template #editorDropdownContent let-component="component">
                        <div class="pl-2 d-flex justify-content-center align-items-center">
                            <div class="flex-1 nowrap">
                                <input [class.hidden]="part.type !== 'value'"
                                    class="d-inline-block"
                                    kui-input
                                    #valueInput
                                    [value]="part.value || ''"
                                    (change)="onConditionEditorUpdated(component, valueInput.value, part)"
                                    style="width: 100px"
                                    type="text" />
                                <input
                                    [class.hidden]="part.type !== 'speed' && part.type !== 'distance' && part.type !== 'duration'"
                                    class="d-inline-block"
                                    kui-input
                                    #numberInput
                                    [value]="part.value"
                                    (change)="onConditionEditorUpdated(component, numberInput.value || timeInput.value, part)"
                                    style="width: 70px"
                                    type="number" />
                                <input [class.hidden]="part.type !== 'time'"
                                    class="d-inline-block"
                                    kui-input
                                    #timeInput
                                    placeholder="00:00"
                                    [value]="part.value || ''"
                                    (change)="onConditionEditorUpdated(component, numberInput.value || timeInput.value, part)"
                                    style="width: 100px"
                                    type="time" />
            
                                {{part.unit}}
                            </div>
                            <kui-icon kui-action
                                class="mx-2"
                                name="check-circle"
                                size="sm"
                                color="primary"
                                (click)="component.toggle(false)"></kui-icon>
                        </div>
                    </ng-template>
                }
            }
        }
    </div>
    <div>
        <kui-icon kui-action
            class="muted pl-2"
            name="times"
            size="sm"
            (click)="removeCondition()"></kui-icon>
    </div>
} @else {
    <kui-loader size="small"></kui-loader>   
}

