import { Component, Input, OnInit, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormBuilderField, BaseFormBuilderFieldComponent } from 'app/shared/components/form-builder';
import { KuiDateTimeComponent } from 'app/key-ui/datetime/datetime.component';
import { MeasurementUnitsService } from 'app/services';
import * as moment from 'moment-timezone';
import { get, set } from 'lodash';

@Component({
    selector: 'key-form-builder-datetime-field',
    templateUrl: './datetime.component.html',
})
export class KeyFormBuilderDateTimeFieldComponent implements BaseFormBuilderFieldComponent, OnInit {
    @Input() field: FormBuilderField;
    @Input() values: { [key: string]: any };
    @Input() error: string; // set this error value externally to have the default error highligh and display kick in

    @Output() onChange: EventEmitter<{ value: string, dirty: boolean }> = new EventEmitter();

    @ViewChild(KuiDateTimeComponent, { static: true }) dateTimeComponent: KuiDateTimeComponent;

    format: string;
    datetimeType: string;
    disableTime: boolean;
    touched = false;

    get value(): any {
        let val = this.field.getValue ? this.field.getValue(this.field, this.values) : get(this.values, this.field.id, undefined);
        if (this.field.type === 'time') {
            // the kui-datetime component expects an iso datetime, but our input is always going to be HH:mm or HH:mm:ss when specifying time
            val = val || '00:00:00';
            if (val.length === 5) { // HH:mm
                val += ':00';
            }
            val = moment(val, 'HH:mm:ss').toISOString();
        }
        return val;
    }

    set value(value: any) {
        if (this.field.type === 'time') {
            // the kui-datetime component returns an iso datetime, but we're gonna want a HH:mm:ss returned
            value = moment(value).format('HH:mm:ss');
        }
        if (this.field.setValue) {
            const promise = this.field.setValue(this.field, this.values, value);
            if (promise && promise.then) {
                promise.then(() => this.ref.markForCheck());
            }
        } else {
            set(this.values, this.field.id, value);
        }
    }

    constructor(private ref: ChangeDetectorRef, protected units: MeasurementUnitsService) { }

    validate(): boolean {
        this.touched = true;
        this.ref.markForCheck();
        return !this.field.required || !!this.value;
    }

    ngOnInit() {
        const dateFormat = this.units.getUnitList('date').units[this.units.getUnitConfig('date')].unit;
        const timeFormat = this.units.getUnitList('time').units[this.units.getUnitConfig('time')].unit;

        switch (this.field.type) {
            case 'datetime':
                this.format = `${dateFormat} ${timeFormat}`;
                this.datetimeType = 'datetime-local';
                break;
            case 'date':
                this.format = dateFormat;
                this.datetimeType = 'date';
                break;
            case 'time':
                this.format = (this.field.options && this.field.options.format) || timeFormat;
                this.datetimeType = 'time';
                this.disableTime = this.field.options && this.field.options.disableTime;
                break;
            default:
                break;
        }

        if (this.field.value) {
            this.values[this.field.id] = this.values[this.field.id] || this.field.value || new Date().toISOString();
        }

    }

    dateChanged(value) {
        this.value = value;
        this.onChange.emit({ value: this.values[this.field.id], dirty: true });
    }
}
