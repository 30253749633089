<!-- kui-select won't allow any html (including extra spaces) so went back to plain old <select> -->
<!-- <kui-select [options]="comboValues"
    (change)="comboChanged($event)"></kui-select> -->

<select kui-input
    [disabled]="field.readonly"
    [required]="field.required"
    (change)="comboChanged($event.target.value)"
    [class.border-danger]="error || (field.required && touched && !value)">
    <option *ngIf="!field.required || !value"
        value=""
        [disabled]="field.required"
        selected></option>
    <option *ngFor="let option of comboValues"
        [value]="option.key"
        [selected]="option.key === value">
        {{ '&nbsp;'.repeat(option.indent * 3) }}{{ option.value }}
    </option>
</select>


<div *ngIf="field.required && touched && !value"
    class="invalid-feedback d-block">
    <div>{{ 'FORMS.SHARED.REQUIRED' | translate:{ field: field.title } }}</div>
</div>
<div *ngIf="error"
    class="invalid-feedback d-block">
    <div>{{ error }}</div>
</div>