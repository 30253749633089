import { Component, OnDestroy, OnInit } from '@angular/core';

import { MapMarker, MapRoute } from '../../../map.component';
import { MapOptionService } from '../../map-option.service';
import { MapOptionComponent } from '../map-option.component';
import { RouteInput } from './components/routing/map-toolbar-routing.component';
import { MapOptionRoutingService } from './map-option-routing.service';


export interface MapOptionRoutingConfig {
    routeInputs: RouteInput[];
}

@Component({
    styleUrls: ['./map-option-routing.component.scss'],
    templateUrl: './map-option-routing.component.html',
})
export class MapOptionRoutingComponent implements MapOptionComponent<MapOptionRoutingConfig>, OnInit, OnDestroy {

    static ID = 'map-option-routing-component';
    static ICON = 'search';
    static TITLE = 'LEAFLET.MAP_OPTIONS.SEARCH_RESULTS';

    data: MapOptionRoutingConfig;

    constructor(
        private mapOptionService: MapOptionService,
        private mapOptionRoutingService: MapOptionRoutingService
    ) { }

    ngOnInit(): void {
        this.mapOptionRoutingService.setRouteInput(this.data.routeInputs);
    }

    ngOnDestroy(): void {
        // Have to clean up in case the user exits at any point in the process.

    }

    onClose() {
        this.mapOptionService.clear();
    }

    addMarker(marker: MapMarker) {
        this.mapOptionRoutingService.addMarker(marker);
    }

    clearMultipleMarkers(markers: MapMarker[]) {
        this.mapOptionRoutingService.clearMultipleMarkers(markers);
    }

    updateRoutes(routes: MapRoute[]) {
        this.mapOptionRoutingService.updateRoutes(routes);
    }

    clearRoutes(routes: MapRoute[]) {
        this.mapOptionRoutingService.clearRoutes(routes);
    }

}

