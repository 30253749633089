import { Component, HostListener, Output, EventEmitter, ElementRef, ViewChild, Input } from '@angular/core';
import { escapeRegExp } from 'lodash';

export interface SearchResult<T> {
    id: string;
    value: string;
    meta?: T;
    active?: boolean;
    icon?: string;
    action?: (result: SearchResult<T>) => void;
}

@Component({
    selector: 'key-search-results-list',
    styleUrls: ['search-results-list.component.scss'],
    templateUrl: 'search-results-list.component.html',
})
export class KeySearchResultsListComponent<T> {
    activeIndex: number;

    @Input() list: SearchResult<T>[];
    @Input() highlightValue: string;
    @Input() maxHeight: number;

    @Output() onSelected = new EventEmitter<SearchResult<T>>();
    @Output() onBlur = new EventEmitter<void>();

    @ViewChild('results', { static: true }) results: ElementRef<HTMLDivElement>;

    @HostListener('document:keydown', ['$event']) keyboardInputs(event: KeyboardEvent) {
        if (!this.list || !this.list.length) { return; }

        switch (event.key) {
            case 'ArrowUp':
                this.highlightSearchResults('up');
                break;
            case 'ArrowDown':
                this.highlightSearchResults('down');
                break;
            case 'Enter':
                this.selectResult(this.list[this.activeIndex]);
                break;
            default: break;
        }
    }

    selectResult(item: SearchResult<T>) {
        if (item) {
            if (item.action) {
                item.action(item);
            }
            this.onSelected.emit(item);
        }
    }

    highlightTerm(value: string) {
        return value.replace(
            new RegExp(escapeRegExp(this.highlightValue), 'ig'),
            (term) => `<span class="text-primary">${term}</span>`
        );
    }

    highlightSearchResults(direction: 'up' | 'down') {
        const indexes = this.list.length - 1;
        const cur = this.activeIndex;
        let next = null;

        this.onBlur.emit();

        if (direction === 'up') {
            next = !cur ? indexes : cur - 1;
        }

        if (direction === 'down') {
            next = (cur === undefined || cur === null || cur === indexes) ? 0 : cur + 1;
        }

        this.list = this.list.map((x, i) => {
            x.active = i === next;
            return x;
        });

        this.activeIndex = next;

        // scroll container when active item goes out of view
        setTimeout(() => {
            const results: HTMLElement = this.results.nativeElement;
            const active: HTMLElement = this.results.nativeElement.querySelector('.active');
            if (active) {
                const tooHigh = results.scrollTop < (active.offsetTop + active.clientHeight);
                const tooLow = (results.scrollTop + results.clientHeight) > (active.offsetTop + active.clientHeight);
                if (!(tooHigh && tooLow)) {
                    active.scrollIntoView(tooLow);
                }
            }
        });
    }
}
