<div class="trip">
    <key-trip-map [trip]="trip()" [asset]="asset()"></key-trip-map>

    <div class="trip__playback">
        <key-playback-slider 
            [trip]="trip()"
            [length]="trip().telemetry.length - 1">
        </key-playback-slider>
    </div>

    <div class="trip__information">

        @if(telemetry()) {
            <div class="block">
                <div class="block__heading">{{ 'MEDIA.VIDEO_REQUEST.MODAL.SELECTED_EVENT' | translate }}</div>
                <div class="block__content">
                    <key-telemetry-information [telemetry]="telemetry()" [asset]="asset()"></key-telemetry-information>
                </div>
            </div>
            <div class="block">

                <div class="block__heading d-flex justify-content-between align-items-center">
                    <span>{{ 'MEDIA.VIDEO_REQUEST.MODAL.CLIP_SETTINGS' | translate }}</span>

                    @if(showSettings()) {
                        <div>
                            <span>
                                {{start | units: { unit: 'time', variant: 'long'} }}
                            </span>
                            <kui-icon name="arrow-right" class="px-2"></kui-icon>
                            <span>
                                {{end | units: { unit: 'time', variant: 'long'} }}
                            </span>
                        </div>
                    }
                    <kui-icon 
                        name="cog" 
                        size="sm" 
                        class="pointer"
                        [notification]="invalidSettings()"
                        (click)="showSettings.set(!showSettings())">
                    </kui-icon> 
                </div>
                
                <div class="block__content">
                    <key-form-builder #formBuilder
                        [hidden]="!showSettings()"
                        [inline]="true"
                        [form]="form()"
                        [values]="formValues"
                        (onChange)="handleSettingsChange()">
                    </key-form-builder>
                    @if(!showSettings()) {
                        <div class="list">
                            <strong>{{ 'MEDIA.VIDEO_REQUEST.MODAL.CLIP_START' | translate }}</strong>
                            <p>
                                {{start | units: { unit: 'time', variant: 'long'} }}
                            </p>
                        
                            <strong>{{ 'MEDIA.VIDEO_REQUEST.MODAL.CLIP_END' | translate }}</strong>
                            <p>
                                {{end | units: { unit: 'time', variant: 'long'} }}
                            </p>  
                        </div>

                        @if(invalidSettings()) {
                            <div class="warning mx-2 my-3">
                                <kui-icon name="exclamation-triangle" size="md" color="danger"></kui-icon>
                                <span>{{ 'MEDIA.VIDEO_REQUEST.MODAL.INVALID_SETTINGS' | translate }}</span>
                            </div>
                        }
                    }
                </div>
            </div>
        }
    </div>
</div>