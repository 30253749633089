import { Pipe, PipeTransform } from '@angular/core';
import { EventFilterService } from 'app/services';

@Pipe({
    name: 'conditionPrefix',
    standalone: true,
})
export class KeyFormBuilderEventFilterConditionPrefixPipe implements PipeTransform {

    constructor(private filterService: EventFilterService) {}

    /**
     * Returns the prefix that should be used for a given index
     * 
     * @param conditionIndex the index of the specific condition
     * @returns the correct prefix
     */
    transform(conditionIndex: number): string {
        return this.filterService.getConditionPrefix(conditionIndex);
    }
}
