<div class="user-profile dropdown">
  <div class="user-profile-button">

    <div kui-action class="profile-info" >
      <div class="hidden-on-collapsed top-menu-hide text-truncate" (click)="companyClicked.emit($event)">{{clientname}}</div>

      <div kui-action class="hidden-on-expanded top-menu-show profile-image-container" (click)="userClicked.emit($event)">
        <img *ngIf="avatar" src="{{avatar}}" class="profile-image d-inline-block" alt="{{ fullname }}" (error)="avatar = null">
        <div *ngIf="!avatar" class="default-pic profile-image-text d-inline-block bg-primary color-text-inverse text-center">
          <strong>{{ fullname[0] }}</strong>
        </div>
      </div>


    </div>
  </div>
</div>
