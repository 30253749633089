import { Injectable } from '@angular/core';

import { FeaturesGuard } from 'app/guards/features.guard';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SystemGuards extends FeaturesGuard  {
    canActivate(): Observable<boolean> {
        return this.matchesUserType('system')
            .pipe(
                map(isSystemUser => {
                    if (!isSystemUser && !this.hasRouted()) {
                        this.router.navigate(['/login']);
                    }
                    return isSystemUser;
                })
            );
    }
}
