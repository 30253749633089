import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StaticPageService } from './static-page.service';
import { BaseComponent } from '../base/base.component';

@Component({
    template: '',
})
export class StaticPageComponent extends BaseComponent implements OnDestroy {

    constructor(
        private route: ActivatedRoute,
        private service: StaticPageService
    ) {
        super();
        this.on(this.route.params, () => {
            this.service.emitShapshot(this.route.snapshot);
        });
    }

    ngOnDestroy() {
        this.service.emitShapshot(null);
    }

}
