import {
    Directive,
    Input,
    ElementRef,
    OnInit,
    ComponentFactoryResolver,
    ViewContainerRef,
    ComponentFactory,
    ComponentRef,
    HostListener,
    HostBinding,
    Renderer2,
} from '@angular/core';

import { KuiTooltipComponent } from './tooltip.component';
import { IKuiToolTipDimensions } from './tooltip.model';
import { MatchMediaService } from 'app/services';

@Directive({
    selector: '[kui-tooltip]',
})
export class KuiTooltipDirective implements OnInit {
    factory: ComponentFactory<KuiTooltipComponent>;
    ref: ComponentRef<KuiTooltipComponent>;

    notTouch: boolean;

    @Input('kui-tooltip') title: string;
    @Input() placement: 'top' | 'bottom' | 'left' | 'right' = 'top';
    @Input() align: 'center' | 'left' | 'right' = 'center';
    @Input() showOnTouchscreen: boolean;

    @HostBinding('style.position') position;
    @HostBinding('class.overflow-visible') visible = true;

    @HostListener('mouseenter') onMouseEnter() { this.open(); }
    @HostListener('mouseleave') onMouseLeave() { this.close(); }
    @HostListener('document:touchstart') closeOnOutsideTouch() { this.close(); }


    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef,
        private matchmedia: MatchMediaService
    ) {
        this.notTouch = !this.matchmedia.deviceFeatures.touch;
    }

    ngOnInit() {
        this.renderer.setAttribute(this.el.nativeElement, 'title', this.title);

        /*
        * create kui-tooltip component factory
        */
        this.factory = this.componentFactoryResolver.resolveComponentFactory(KuiTooltipComponent);
    }

    open() {
        if (this.notTouch || this.showOnTouchscreen) {
            /*
            * insert component from factory
            */
            this.ref = this.viewContainerRef.createComponent(this.factory);
            this.ref.instance.dimensions = this._getDimensions(this.placement, this.align);

            this.renderer.appendChild(
                this.viewContainerRef.element.nativeElement,
                this.ref.injector.get(KuiTooltipComponent).el.nativeElement
            );
        }
    }

    close() {
        if (this.ref) {
            this.ref.destroy();
        }
    }

    private _getDimensions(placement, align): IKuiToolTipDimensions {
        const el = this.el.nativeElement;
        const dimensions = el.getBoundingClientRect();

        const position = window.getComputedStyle(el, null).getPropertyValue('position');

        if (position === 'static') {
            this.position = 'relative';
        }

        return {
            width: dimensions.width,
            height: dimensions.height,
            placement,
            align,
        };
    }
}

