import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import * as TinyCache from 'tinycache';
import { ApiService } from 'app/services/api/api.service';


@Injectable()
export class EntityService {

    constructor(
        private app: AppService
    ) {

    }

    private DEFAULT_CACHE_TTL = 60; // seconds
    private cache = new TinyCache();

    protected entityUpdatedSubject = new Subject<any>();
    entityUpdated$ = this.entityUpdatedSubject.asObservable();

    // caches promises, thus preventing race conditions when multiple things ask for the same item at the same time.
    getCached<T>(key: string, missFunction: (api: ApiService) => Promise<T>, ttlSeconds?: number, bypassCache?: boolean): Promise<T> {
        let result = (ttlSeconds === 0 || bypassCache) ? null : this.cache.get(key);
        if (!result) {
            result = missFunction(this.app.api);
            this.cache.put(key, result, (ttlSeconds || this.DEFAULT_CACHE_TTL) * 1000);
        }
        return result;
    }

    applyUpdate<T>(updateFunction: (api: ApiService) => Promise<T>, additionalProperties?: Object): Promise<T> {
        return updateFunction(this.app.api).then(updated => {
            this.entityUpdatedSubject.next({
                ...(updated as Object), // a typescript 2.x bug requires this cast : https://stackoverflow.com/questions/51189388/typescript-spread-types-may-only-be-created-from-object-types
                ...additionalProperties,
            });
            if (additionalProperties) {
                const key = additionalProperties['_cacheKey'];
                if (key) {
                    this.cache.del(key);
                }
            }
            return updated;
        });
    }

}
