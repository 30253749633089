<div *ngIf="report">
    <table class="w-100">
        <tr>
            <td>Report: </td>
            <td><a (click)="navigateToReport()">{{ report.id }}</a></td>
        </tr>
        <tr>
            <td>Base: </td>
            <td><a (click)="navigateToBaseReport()">{{ baseReportId }}</a></td>
        </tr>
        <tr>
            <td>Queued: </td>
            <td>{{ report.queueDate | timeAgo }}</td>
        </tr>
        <tr>
            <td>Updated: </td>
            <td>{{ report.updateDate | timeAgo }}</td>
        </tr>
        <tr>
            <td>User: </td>
            <td>{{ report.user.name }}</td>
        </tr>
        <tr>
            <td>TriggeredBy: </td>
            <td>{{ report.triggeredBy }}</td>
        </tr>
    </table>
    <div class="pt-3">
    <a  (click)="rerunReport()">Run Again</a>
    </div>
</div>