import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'kui-user',
    templateUrl: './user.component.html',
})
export class KuiUserComponent {
    @Input() avatar: string;
    @Input() clientname: string;
    @Input() fullname: string;
    @Input() username: string;
    
    @Output() companyClicked = new EventEmitter<MouseEvent>();
    @Output() userClicked = new EventEmitter<MouseEvent>();
}
