import { ModuleWithProviders, NgModule } from '@angular/core';
import { TimeAgoPipe } from 'app/shared/pipes/time-ago.pipe';

import { GetKeyPipe } from './getkey.pipe';
import { DecimalDegreesToDegreesPipe } from './dec2deg.pipe';
import { LocalizeTimePipe } from './localizeTime.pipe';
import { UnitsPipe } from './units.pipe';
import { FormatSecondsPipe } from 'app/shared/pipes/format-seconds.pipe';
import { ObjectToArrayPipe } from 'app/shared/pipes/object2array.pipe';
import { RemoveSpacesPipe } from 'app/shared/pipes/removeSpaces.pipe';
import { MapGetPipe } from './map-get.pipe';
import { ValidCustomFieldPipe } from './valid-custom-field.pipe';

const PIPES = [
    GetKeyPipe,
    DecimalDegreesToDegreesPipe,
    LocalizeTimePipe,
    UnitsPipe,
    FormatSecondsPipe,
    TimeAgoPipe,
    ObjectToArrayPipe,
    RemoveSpacesPipe,
    MapGetPipe,
    ValidCustomFieldPipe
];

@NgModule({
    exports: PIPES,
    declarations: PIPES,
})
export class PipeModule {
    static forRoot(): ModuleWithProviders<PipeModule> {
        return {
            ngModule: PipeModule,
            providers: [],
        };
    }
}
