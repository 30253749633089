import { Directive, Renderer2, ElementRef } from '@angular/core';

@Directive({
    selector: '[kui-list-item-text]',
})
export class KuiListItemTextDirective {
    constructor(private el: ElementRef, private renderer: Renderer2) {
        this.renderer.addClass(this.el.nativeElement, 'list-group-item-text');
    }
}
