import { Directive, HostBinding } from '@angular/core';

@Directive({
    selector: '[key-layout-grid-column-inner]',
})
export class LayoutGridColumnInnerDirective {
    @HostBinding('class.d-flex') displayFlex = true;
    @HostBinding('class.flex-column') columnFlexDirection = true;
    @HostBinding('class.flex-stretch') flexStretch = true;
}
