import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KuiUserComponent } from './user.component';
import { KuiDropdownModule } from './../dropdown';
import { KuiIconModule } from './../icon';
import { KuiActionModule } from 'app/key-ui/action/action.module';

@NgModule({
  imports: [CommonModule, KuiDropdownModule, KuiIconModule, KuiActionModule],
  exports: [KuiUserComponent],
  declarations: [KuiUserComponent],
})
export class KuiUserModule {}
