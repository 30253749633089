import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};

export type AlertAction = {
  __typename?: 'AlertAction';
  type: Scalars['String'];
  id: Scalars['String'];
  options: Scalars['JSONObject'];
};

export type AlertActionInput = {
  type: Scalars['String'];
  id: Scalars['String'];
  options: Scalars['JSONObject'];
};

export type AlertCreateRequestInput = {
  /** A unique name for this alert */
  name?: Maybe<Scalars['String']>;
  /** The current state of this alert */
  state?: Maybe<Scalars['String']>;
  /** The cost centre that this alert applies to */
  costCentre?: Maybe<IdNameInput>;
  /** The priority of this alert */
  priority?: Maybe<Scalars['String']>;
  /** The filter that this alert matches on */
  filter?: Maybe<EventFilterInput>;
  /** A list of users and roles that will be notified if this alert triggers. */
  notify?: Maybe<Array<Maybe<AlertNotifyInput>>>;
  /** A list of actions to be performed once this alert triggers. */
  actions?: Maybe<Array<Maybe<AlertActionInput>>>;
  /** The client that owns this Alert */
  ownerId: Scalars['String'];
};

export type AlertListItem = {
  __typename?: 'AlertListItem';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A unique name for this alert */
  name: Scalars['String'];
  /** The current state of this alert */
  state: Scalars['String'];
  /** The cost centre that this alert applies to */
  costCentre: IdName;
  /** The priority of this alert */
  priority: Scalars['String'];
  /** The filter that this alert matches on */
  filter?: Maybe<EventFilter>;
  /** The date the entity was last modified */
  modifiedDate: Scalars['String'];
};

/** A list of alert items */
export type AlertListResponse = {
  __typename?: 'AlertListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<AlertListItem>>;
};

export type AlertNotify = {
  __typename?: 'AlertNotify';
  type: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type AlertNotifyInput = {
  type: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type AlertResponse = {
  __typename?: 'AlertResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A unique name for this alert */
  name?: Maybe<Scalars['String']>;
  /** The current state of this alert */
  state?: Maybe<Scalars['String']>;
  /** The cost centre that this alert applies to */
  costCentre?: Maybe<IdName>;
  /** The priority of this alert */
  priority?: Maybe<Scalars['String']>;
  /** The filter that this alert matches on */
  filter?: Maybe<EventFilter>;
  /** A list of users and roles that will be notified if this alert triggers. */
  notify?: Maybe<Array<Maybe<AlertNotify>>>;
  /** A list of actions to be performed once this alert triggers. */
  actions?: Maybe<Array<Maybe<AlertAction>>>;
  /** entity specific metadata */
  entity?: Maybe<EntityInfo>;
};

export type AlertUpdateRequestInput = {
  /** A unique name for this alert */
  name?: Maybe<Scalars['String']>;
  /** The current state of this alert */
  state?: Maybe<Scalars['String']>;
  /** The cost centre that this alert applies to */
  costCentre?: Maybe<IdNameInput>;
  /** The priority of this alert */
  priority?: Maybe<Scalars['String']>;
  /** The filter that this alert matches on */
  filter?: Maybe<EventFilterInput>;
  /** A list of users and roles that will be notified if this alert triggers. */
  notify?: Maybe<Array<Maybe<AlertNotifyInput>>>;
  /** A list of actions to be performed once this alert triggers. */
  actions?: Maybe<Array<Maybe<AlertActionInput>>>;
};

export type AnalyticsDimension = {
  __typename?: 'AnalyticsDimension';
  dim: Scalars['String'];
  level: Scalars['Float'];
  filter: Array<Maybe<Scalars['String']>>;
  levels: AnalyticsLevels;
};

export type AnalyticsDimensionInput = {
  dim: Scalars['String'];
  level: Scalars['Float'];
  filter: Array<Maybe<Scalars['String']>>;
  levels: AnalyticsLevelsInput;
};

export type AnalyticsLevels = {
  __typename?: 'AnalyticsLevels';
  name: Scalars['String'];
  headings: Array<Maybe<Scalars['String']>>;
};

export type AnalyticsLevelsInput = {
  name: Scalars['String'];
  headings: Array<Maybe<Scalars['String']>>;
};

export type AnalyticsOutputFilter = {
  __typename?: 'AnalyticsOutputFilter';
  mode: Scalars['String'];
  items?: Maybe<Array<Maybe<Scalars['String']>>>;
  range?: Maybe<AnalyticsOutputRangeFilter>;
};

export type AnalyticsOutputFilterInput = {
  mode: Scalars['String'];
  items?: Maybe<Array<Maybe<Scalars['String']>>>;
  range?: Maybe<AnalyticsOutputRangeFilterInput>;
};

export type AnalyticsOutputFilters = {
  __typename?: 'AnalyticsOutputFilters';
  group: AnalyticsOutputFilter;
  row: AnalyticsOutputFilter;
};

export type AnalyticsOutputFiltersInput = {
  group: AnalyticsOutputFilterInput;
  row: AnalyticsOutputFilterInput;
};

export type AnalyticsOutputRangeFilter = {
  __typename?: 'AnalyticsOutputRangeFilter';
  level: Scalars['String'];
  start: Scalars['String'];
  end: Scalars['String'];
};

export type AnalyticsOutputRangeFilterInput = {
  level: Scalars['String'];
  start: Scalars['String'];
  end: Scalars['String'];
};

export type AnalyticsOutputRequestInput = {
  rowLevel: Scalars['Float'];
  groupLevel: Scalars['Float'];
  time: Scalars['String'];
  flipped: Scalars['Boolean'];
  measures: Array<Maybe<Scalars['String']>>;
  sort: AnalyticsSortOptionsInput;
  row?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  filters?: Maybe<AnalyticsOutputFiltersInput>;
};

export type AnalyticsOutputSettings = {
  __typename?: 'AnalyticsOutputSettings';
  name: Scalars['String'];
  description: Scalars['String'];
  dateRange: Scalars['String'];
  row: AnalyticsDimension;
  group: AnalyticsDimension;
  flipped: Scalars['Boolean'];
  measures: Array<Maybe<MeasureDescription>>;
  table: AnalyticsTable;
  sort: AnalyticsSortOptions;
  graph?: Maybe<ChartOptions>;
  pie?: Maybe<PieChartOptions>;
  stat?: Maybe<StatChartOptions>;
  calculatedMeasures?: Maybe<CalculatedMeasureSettings>;
  filters?: Maybe<AnalyticsOutputFilters>;
  timeOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  shiftsEnabled?: Maybe<Scalars['Boolean']>;
  updates?: Maybe<AnalyticsUpdateSettings>;
};

export type AnalyticsOutputSettingsInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  dateRange: Scalars['String'];
  row: AnalyticsDimensionInput;
  group: AnalyticsDimensionInput;
  flipped: Scalars['Boolean'];
  measures: Array<Maybe<MeasureDescriptionInput>>;
  table: AnalyticsTableInput;
  sort: AnalyticsSortOptionsInput;
  graph?: Maybe<ChartOptionsInput>;
  pie?: Maybe<PieChartOptionsInput>;
  stat?: Maybe<StatChartOptionsInput>;
  calculatedMeasures?: Maybe<CalculatedMeasureSettingsInput>;
  filters?: Maybe<AnalyticsOutputFiltersInput>;
  timeOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  shiftsEnabled?: Maybe<Scalars['Boolean']>;
  updates?: Maybe<AnalyticsUpdateSettingsInput>;
};

export type AnalyticsSettingsRequestInput = {
  row: Scalars['String'];
  group: Scalars['String'];
};

export type AnalyticsSortOptions = {
  __typename?: 'AnalyticsSortOptions';
  type: Scalars['String'];
  direction: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  measureKey?: Maybe<Scalars['String']>;
};

export type AnalyticsSortOptionsInput = {
  type: Scalars['String'];
  direction: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  measureKey?: Maybe<Scalars['String']>;
};

export type AnalyticsTable = {
  __typename?: 'AnalyticsTable';
  measures: Array<Maybe<SelectedMeasureValues>>;
  layout: Scalars['String'];
};

export type AnalyticsTableInput = {
  measures: Array<Maybe<SelectedMeasureValuesInput>>;
  layout: Scalars['String'];
};

export type AnalyticsUpdateFrequencyOffset = {
  __typename?: 'AnalyticsUpdateFrequencyOffset';
  frequency: Scalars['Float'];
  offset: Scalars['Float'];
};

export type AnalyticsUpdateFrequencyOffsetInput = {
  frequency: Scalars['Float'];
  offset: Scalars['Float'];
};

export type AnalyticsUpdateSettings = {
  __typename?: 'AnalyticsUpdateSettings';
  daily: AnalyticsUpdateSettings_Daily;
  hourly?: Maybe<AnalyticsUpdateFrequencyOffset>;
  shifts?: Maybe<AnalyticsUpdateFrequencyOffset>;
};

export type AnalyticsUpdateSettings_Daily = {
  __typename?: 'AnalyticsUpdateSettings_daily';
  time: Scalars['String'];
};

export type AnalyticsUpdateSettings_DailyInput = {
  time: Scalars['String'];
};

export type AnalyticsUpdateSettingsInput = {
  daily: AnalyticsUpdateSettings_DailyInput;
  hourly?: Maybe<AnalyticsUpdateFrequencyOffsetInput>;
  shifts?: Maybe<AnalyticsUpdateFrequencyOffsetInput>;
};

export type AssetAnalogParameter = {
  __typename?: 'AssetAnalogParameter';
  id: Scalars['String'];
  name: Scalars['String'];
  ioType: Scalars['String'];
  minRange: Scalars['Float'];
  maxRange: Scalars['Float'];
  rangeDebounce: Scalars['Float'];
  decValue: Scalars['Float'];
  incValue: Scalars['Float'];
};

export type AssetAnalogParameterInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  ioType: Scalars['String'];
  minRange: Scalars['Float'];
  maxRange: Scalars['Float'];
  rangeDebounce: Scalars['Float'];
  decValue: Scalars['Float'];
  incValue: Scalars['Float'];
};

export type AssetCategoryCreateRequestInput = {
  /** The index number of this asset category (0 - 4) */
  index?: Maybe<Scalars['Float']>;
  /** A unique name for this entity */
  name: Scalars['String'];
  /** The parent of this entity */
  parent?: Maybe<IdNameInput>;
  /** A list of contacts applicable to this asset category */
  contacts?: Maybe<Array<Maybe<ContactInput>>>;
  /** The client that owns this entity */
  ownerId: Scalars['String'];
};

/** A list of asset category items */
export type AssetCategoryListResponse = {
  __typename?: 'AssetCategoryListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<AssetCategoryResponse>>;
};

export type AssetCategoryResponse = {
  __typename?: 'AssetCategoryResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The index number of this asset category (0 - 4) */
  index?: Maybe<Scalars['Float']>;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The parent of this entity */
  parent?: Maybe<IdName>;
  /** A list of contacts applicable to this asset category */
  contacts?: Maybe<Array<Maybe<Contact>>>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type AssetCategoryUpdateRequestInput = {
  /** The index number of this asset category (0 - 4) */
  index?: Maybe<Scalars['Float']>;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The parent of this entity */
  parent?: Maybe<IdNameInput>;
  /** A list of contacts applicable to this asset category */
  contacts?: Maybe<Array<Maybe<ContactInput>>>;
};

/** Describes a counter value (odometer, engine hours, etc.) at a specific date. */
export type AssetCounterValueInput = {
  /** The value of the counter at a specific point in time (must me km for odometer, and hours for hour couters). */
  value: Scalars['Float'];
  /** The date and time at which this counter was recorded. */
  date: Scalars['String'];
};

/** A collection of counter values */
export type AssetCounterValuesInput = {
  /** The odometer of the asset, expressed as kilometers. */
  odometer?: Maybe<AssetCounterValueInput>;
  /** The engine hours of the asset, expressed as hours. */
  hours?: Maybe<AssetCounterValueInput>;
};

export type AssetCreateRequestInput = {
  /** A client unique name for this asset. This can be any value that is relevant for the client. */
  name: Scalars['String'];
  /** The type of asset */
  assetType: IdNameInput;
  /** The color of the icon for this asset */
  color?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The cost centre to which this asset belongs */
  costCentre: IdNameInput;
  /** The trip rating profile to use for this assets trip rating */
  ratingProfile?: Maybe<IdNameInput>;
  /** One or more asset state profiles to use for this asset */
  assetStateProfiles?: Maybe<Array<Maybe<IdNameInput>>>;
  /** The overspeed profile to use for this asset */
  overspeedProfile?: Maybe<IdNameInput>;
  /** One of more asset groups that this asset belongs to */
  groups: Array<Maybe<IdNameInput>>;
  /** Up to 5 different categories that this asset belongs to */
  categories?: Maybe<Array<Maybe<IdNameInput>>>;
  /** One or more clients to which this asset has been shared. */
  sharedWith?: Maybe<Array<Maybe<IdNameInput>>>;
  assetTag?: Maybe<IdNameInput>;
  /** The current state of the asset object */
  state?: Maybe<Scalars['String']>;
  /** A number of custom field values for this asset. */
  fields?: Maybe<Scalars['JSONObject']>;
  parameters?: Maybe<AssetParametersInput>;
  /** Details about an active geo-lock on this asset (if any) */
  geoLock?: Maybe<AssetGeoLockInput>;
  /** One or more contacts that are relevant to this asset */
  contacts?: Maybe<Array<Maybe<ContactInput>>>;
  /** For static assets this field can be set with the known location of the asset */
  location?: Maybe<AssetLocationInput>;
  /** A list of zones and routes that are relevant to this asset */
  zones?: Maybe<Array<Maybe<AssetZoneTargetInput>>>;
  ownerId: Scalars['String'];
};

export type AssetCurfewParameter = {
  __typename?: 'AssetCurfewParameter';
  id: Scalars['String'];
  from: Scalars['String'];
  to: Scalars['String'];
  days: Array<Maybe<Scalars['String']>>;
  timeZoneId: Scalars['String'];
};

export type AssetCurfewParameterInput = {
  id: Scalars['String'];
  from: Scalars['String'];
  to: Scalars['String'];
  days: Array<Maybe<Scalars['String']>>;
  timeZoneId: Scalars['String'];
};

/** A user can geo lock an asset to a specific location, and be notified should the asset move from that location. */
export type AssetGeoLock = {
  __typename?: 'AssetGeoLock';
  /** The user that initiated the geo-lock */
  user: IdName;
  /** The latitude of the location (in milliarcseconds format) */
  lat: Scalars['Float'];
  /** The longitude of the location (in milliarcseconds format) */
  lon: Scalars['Float'];
  /** The radius of the geo-lock in kilometers */
  radiusKm: Scalars['Float'];
  /** The date and time (YYYY/MM/DD HH:mm:ss) when the geolock was initiated. */
  start: Scalars['String'];
  /** The date and time (YYYY/MM/DD HH:mm:ss) when the geolock was ended. */
  end: Scalars['String'];
};

/** A user can geo lock an asset to a specific location, and be notified should the asset move from that location. */
export type AssetGeoLockInput = {
  /** The user that initiated the geo-lock */
  user: IdNameInput;
  /** The latitude of the location (in milliarcseconds format) */
  lat: Scalars['Float'];
  /** The longitude of the location (in milliarcseconds format) */
  lon: Scalars['Float'];
  /** The radius of the geo-lock in kilometers */
  radiusKm: Scalars['Float'];
  /** The date and time (YYYY/MM/DD HH:mm:ss) when the geolock was initiated. */
  start: Scalars['String'];
  /** The date and time (YYYY/MM/DD HH:mm:ss) when the geolock was ended. */
  end: Scalars['String'];
};

export type AssetGroupCreateRequestInput = {
  /** A unique name for this entity */
  name: Scalars['String'];
  /** The parent of this entity */
  parent?: Maybe<IdNameInput>;
  /** A list of contacts applicable to this asset group */
  contacts?: Maybe<Array<Maybe<ContactInput>>>;
  /** The client that owns this entity */
  ownerId: Scalars['String'];
};

/** A list of asset group items */
export type AssetGroupListResponse = {
  __typename?: 'AssetGroupListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<AssetGroupResponse>>;
};

export type AssetGroupResponse = {
  __typename?: 'AssetGroupResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The parent of this entity */
  parent?: Maybe<IdName>;
  /** A list of contacts applicable to this asset group */
  contacts?: Maybe<Array<Maybe<Contact>>>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type AssetGroupUpdateRequestInput = {
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The parent of this entity */
  parent?: Maybe<IdNameInput>;
  /** A list of contacts applicable to this asset group */
  contacts?: Maybe<Array<Maybe<ContactInput>>>;
};

export type AssetListItem = {
  __typename?: 'AssetListItem';
  /** The unique ID for this asset */
  id: Scalars['String'];
  /** A client unique name for this asset. This can be any value that is relevant for the client. */
  name: Scalars['String'];
  /** The client that owns this asset */
  owner: IdName;
  /** One or more clients to which this asset has been shared. */
  sharedWith: Array<Maybe<IdName>>;
  /** The type of asset */
  assetType: IdName;
  /** One of more asset groups that this asset belongs to */
  groups: Array<Maybe<IdName>>;
  /** Up to 5 different categories that this asset belongs to */
  categories: Array<Maybe<IdName>>;
  /** The cost centre to which this asset belongs */
  costCentre: IdName;
  /** One or more devices that provide telemetry data for this asset. */
  devices: Array<Maybe<IdName>>;
  assetTag: Scalars['String'];
  tags: Array<Maybe<Scalars['String']>>;
  /** A number of custom field values for this asset. */
  fields: Scalars['JSONObject'];
  /** The color of the icon for this asset */
  color: Scalars['String'];
  /** The current state of the asset object */
  state: Scalars['String'];
  /** Details about an active geo-lock on this asset (if any) */
  geoLock?: Maybe<AssetGeoLock>;
};

/** A list of asset items */
export type AssetListResponse = {
  __typename?: 'AssetListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  /** The list of asset items in this response */
  items: Array<Maybe<AssetListItem>>;
};

/** Fixed assets can have their location statically assigned */
export type AssetLocation = {
  __typename?: 'AssetLocation';
  /** An address for this location */
  address: Scalars['String'];
  /** The latitude of the location (in milliarcseconds format) */
  lat: Scalars['Float'];
  /** The longitude of the location (in milliarcseconds format) */
  lon: Scalars['Float'];
};

/** Fixed assets can have their location statically assigned */
export type AssetLocationInput = {
  /** An address for this location */
  address: Scalars['String'];
  /** The latitude of the location (in milliarcseconds format) */
  lat: Scalars['Float'];
  /** The longitude of the location (in milliarcseconds format) */
  lon: Scalars['Float'];
};

export type AssetParameters = {
  __typename?: 'AssetParameters';
  overspeed?: Maybe<Scalars['Float']>;
  overspeedTolerance?: Maybe<Scalars['Float']>;
  excessiveIdle?: Maybe<Scalars['Float']>;
  borders?: Maybe<Array<Maybe<Scalars['String']>>>;
  analogs?: Maybe<Array<Maybe<AssetAnalogParameter>>>;
  temperatures?: Maybe<Array<Maybe<AssetTemperatureParameter>>>;
  curfews?: Maybe<Array<Maybe<AssetCurfewParameter>>>;
};

export type AssetParametersInput = {
  overspeed?: Maybe<Scalars['Float']>;
  overspeedTolerance?: Maybe<Scalars['Float']>;
  excessiveIdle?: Maybe<Scalars['Float']>;
  borders?: Maybe<Array<Maybe<Scalars['String']>>>;
  analogs?: Maybe<Array<Maybe<AssetAnalogParameterInput>>>;
  temperatures?: Maybe<Array<Maybe<AssetTemperatureParameterInput>>>;
  curfews?: Maybe<Array<Maybe<AssetCurfewParameterInput>>>;
};

export type AssetRatingPenalty = {
  __typename?: 'AssetRatingPenalty';
  /** The unique ID of this penalty item */
  id: Scalars['String'];
  /** A descriptive name for this penalty item */
  name: Scalars['String'];
  /** A penalty to apply when this penalty is matched */
  penalty: Scalars['Float'];
  /** The maximum penalty that can be applied for this penalty */
  max: Scalars['Float'];
  /** The monetary cost incurred each time this incident occurs */
  cost: Scalars['Float'];
  /** The filter that this penalty matches on */
  filter: EventFilter;
};

export type AssetRatingPenaltyInput = {
  /** The unique ID of this penalty item */
  id: Scalars['String'];
  /** A descriptive name for this penalty item */
  name: Scalars['String'];
  /** A penalty to apply when this penalty is matched */
  penalty: Scalars['Float'];
  /** The maximum penalty that can be applied for this penalty */
  max: Scalars['Float'];
  /** The monetary cost incurred each time this incident occurs */
  cost: Scalars['Float'];
  /** The filter that this penalty matches on */
  filter: EventFilterInput;
};

export type AssetRatingProfileCreateRequestInput = {
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']>;
  /** The penalty items for this profile */
  items?: Maybe<Array<Maybe<AssetRatingPenaltyInput>>>;
  /** The client that owns this Asset Rating Profile */
  ownerId: Scalars['String'];
};

export type AssetRatingProfileListItem = {
  __typename?: 'AssetRatingProfileListItem';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']>;
  /** The penalty items for this profile */
  items?: Maybe<Array<Maybe<AssetRatingPenalty>>>;
  /** The date the entity was last modified */
  modifiedDate: Scalars['String'];
};

/** A list of asset rating profile items */
export type AssetRatingProfileListResponse = {
  __typename?: 'AssetRatingProfileListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<AssetRatingProfileListItem>>;
};

export type AssetRatingProfileResponse = {
  __typename?: 'AssetRatingProfileResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']>;
  /** The penalty items for this profile */
  items?: Maybe<Array<Maybe<AssetRatingPenalty>>>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type AssetRatingProfileUpdateRequestInput = {
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']>;
  /** The penalty items for this profile */
  items?: Maybe<Array<Maybe<AssetRatingPenaltyInput>>>;
};

export type AssetResponse = {
  __typename?: 'AssetResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A client unique name for this asset. This can be any value that is relevant for the client. */
  name?: Maybe<Scalars['String']>;
  /** The type of asset */
  assetType?: Maybe<IdName>;
  /** The color of the icon for this asset */
  color?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The cost centre to which this asset belongs */
  costCentre?: Maybe<IdName>;
  /** The trip rating profile to use for this assets trip rating */
  ratingProfile?: Maybe<IdName>;
  /** One or more asset state profiles to use for this asset */
  assetStateProfiles?: Maybe<Array<Maybe<IdName>>>;
  /** The overspeed profile to use for this asset */
  overspeedProfile?: Maybe<IdName>;
  /** One of more asset groups that this asset belongs to */
  groups?: Maybe<Array<Maybe<IdName>>>;
  /** Up to 5 different categories that this asset belongs to */
  categories?: Maybe<Array<Maybe<IdName>>>;
  /** One or more clients to which this asset has been shared. */
  sharedWith?: Maybe<Array<Maybe<IdName>>>;
  assetTag?: Maybe<IdName>;
  /** The current state of the asset object */
  state?: Maybe<Scalars['String']>;
  /** A number of custom field values for this asset. */
  fields?: Maybe<Scalars['JSONObject']>;
  parameters?: Maybe<AssetParameters>;
  /** Details about an active geo-lock on this asset (if any) */
  geoLock?: Maybe<AssetGeoLock>;
  /** One or more contacts that are relevant to this asset */
  contacts?: Maybe<Array<Maybe<Contact>>>;
  /** For static assets this field can be set with the known location of the asset */
  location?: Maybe<AssetLocation>;
  /** A list of zones and routes that are relevant to this asset */
  zones?: Maybe<Array<Maybe<AssetZoneTarget>>>;
  /** One or more devices that provide telemetry data for this asset.
   * Can only be modified using the `updateDevice` operation.
   */
  devices?: Maybe<Array<Maybe<IdName>>>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type AssetStateItem = {
  __typename?: 'AssetStateItem';
  /** The unique ID of this state item */
  id: Scalars['String'];
  /** A descriptive name for this state item */
  name: Scalars['String'];
  /** A short name for the state */
  state: Scalars['String'];
  /** A color for the state, defined as a CSS color code (i.e. #3359e6) */
  color: Scalars['String'];
  /** The filter that this state matches on */
  filter: EventFilter;
};

export type AssetStateItemInput = {
  /** The unique ID of this state item */
  id: Scalars['String'];
  /** A descriptive name for this state item */
  name: Scalars['String'];
  /** A short name for the state */
  state: Scalars['String'];
  /** A color for the state, defined as a CSS color code (i.e. #3359e6) */
  color: Scalars['String'];
  /** The filter that this state matches on */
  filter: EventFilterInput;
};

export type AssetStateProfileCreateRequestInput = {
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']>;
  /** The state items for this profile */
  items?: Maybe<Array<Maybe<AssetStateItemInput>>>;
  /** The client that owns this Asset State Profile */
  ownerId: Scalars['String'];
};

export type AssetStateProfileListItem = {
  __typename?: 'AssetStateProfileListItem';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A unique name for this entity */
  name: Scalars['String'];
  /** The current state of this entity */
  state: Scalars['String'];
  /** The state items for this profile */
  items: Array<Maybe<AssetStateItem>>;
  /** The date the entity was last modified */
  modifiedDate: Scalars['String'];
};

/** A list of asset state profile items */
export type AssetStateProfileListResponse = {
  __typename?: 'AssetStateProfileListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<AssetStateProfileListItem>>;
};

export type AssetStateProfileResponse = {
  __typename?: 'AssetStateProfileResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']>;
  /** The state items for this profile */
  items?: Maybe<Array<Maybe<AssetStateItem>>>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type AssetStateProfileUpdateRequestInput = {
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']>;
  /** The state items for this profile */
  items?: Maybe<Array<Maybe<AssetStateItemInput>>>;
};

export type AssetTagCreateRequestInput = {
  /** A friendly name to identify this tag */
  name?: Maybe<Scalars['String']>;
  /** The serial number of the Asset Tag that is used to uniquely identify it. */
  serialNumber: Scalars['String'];
  /** The type of asset tag */
  assetTagType: IdNameInput;
  /** The asset tag operation mode. Options are 'movable' for movable assets,
   * 'fixed' for fixed assets and 'stock' for temporary stock control uses.
   */
  mode: Scalars['String'];
  /** The current state of the object */
  state?: Maybe<Scalars['String']>;
  /** The asset to which this tag is currently assigned */
  asset?: Maybe<IdNameInput>;
  /** A number of custom field values for this assettag. */
  fields?: Maybe<Scalars['JSONObject']>;
  ownerId: Scalars['String'];
};

export type AssetTagListItem = {
  __typename?: 'AssetTagListItem';
  /** The unique UUID for this Asset Tag */
  id: Scalars['String'];
  /** The client that owns this Asset Tag */
  owner: IdName;
  /** The serial number of the Asset Tag that is used to uniquely identify it. */
  name: Scalars['String'];
  /** The serial number of the Asset Tag that is used to uniquely identify it. */
  serialNumber: Scalars['String'];
  /** The type of asset tag */
  assetTagType: IdName;
  /** The asset tag operation mode. Options are 'movable' for movable assets,
   * 'fixed' for fixed assets and 'stock' for temporary stock control uses.
   */
  mode: Scalars['String'];
  /** The current state of the object */
  state: Scalars['String'];
  /** The asset to which this tag is currently assigned */
  asset: IdName;
  /** A number of custom field values for this assettag. */
  fields: Scalars['JSONObject'];
};

/** A list of Asset Tag items */
export type AssetTagListResponse = {
  __typename?: 'AssetTagListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  /** The list of Asset Tag items in this response */
  items: Array<Maybe<AssetTagListItem>>;
};

export type AssetTagResponse = {
  __typename?: 'AssetTagResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A friendly name to identify this tag */
  name?: Maybe<Scalars['String']>;
  /** The serial number of the Asset Tag that is used to uniquely identify it. */
  serialNumber?: Maybe<Scalars['String']>;
  /** The type of asset tag */
  assetTagType?: Maybe<IdName>;
  /** The asset tag operation mode. Options are 'movable' for movable assets,
   * 'fixed' for fixed assets and 'stock' for temporary stock control uses.
   */
  mode?: Maybe<Scalars['String']>;
  /** The current state of the object */
  state?: Maybe<Scalars['String']>;
  /** The asset to which this tag is currently assigned */
  asset?: Maybe<AssetResponse>;
  /** A number of custom field values for this assettag. */
  fields?: Maybe<Scalars['JSONObject']>;
  /** entity specific metadata */
  entity: EntityInfo;
};

/** A list of asset tag type items */
export type AssetTagTypeListResponse = {
  __typename?: 'AssetTagTypeListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<AssetTagTypeResponse>>;
};

export type AssetTagTypeResponse = {
  __typename?: 'AssetTagTypeResponse';
  /** The unique ID of this asset tag type */
  id: Scalars['String'];
  /** The client that owns this entity */
  owner: IdNameType;
  /** A unique name for this entity */
  name: Scalars['String'];
  /** The parent of this entity */
  parent: IdName;
  /** A unique tag for this entity */
  tag: Scalars['String'];
};

export type AssetTagUpdateRequestInput = {
  /** A friendly name to identify this tag */
  name?: Maybe<Scalars['String']>;
  /** The serial number of the Asset Tag that is used to uniquely identify it. */
  serialNumber?: Maybe<Scalars['String']>;
  /** The type of asset tag */
  assetTagType?: Maybe<IdNameInput>;
  /** The asset tag operation mode. Options are 'movable' for movable assets,
   * 'fixed' for fixed assets and 'stock' for temporary stock control uses.
   */
  mode?: Maybe<Scalars['String']>;
  /** The current state of the object */
  state?: Maybe<Scalars['String']>;
  /** The asset to which this tag is currently assigned */
  asset?: Maybe<IdNameInput>;
  /** A number of custom field values for this assettag. */
  fields?: Maybe<Scalars['JSONObject']>;
};

export type AssetTemperatureParameter = {
  __typename?: 'AssetTemperatureParameter';
  id: Scalars['String'];
  name: Scalars['String'];
  ioType: Scalars['String'];
  minRange: Scalars['Float'];
  maxRange: Scalars['Float'];
  rangeDebounce: Scalars['Float'];
};

export type AssetTemperatureParameterInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  ioType: Scalars['String'];
  minRange: Scalars['Float'];
  maxRange: Scalars['Float'];
  rangeDebounce: Scalars['Float'];
};

/** A list of asset type items */
export type AssetTypeListResponse = {
  __typename?: 'AssetTypeListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<AssetTypeResponse>>;
};

export type AssetTypeResponse = {
  __typename?: 'AssetTypeResponse';
  /** The unique ID of this asset type */
  id: Scalars['String'];
  /** The client that owns this entity */
  owner: IdNameType;
  /** A unique name for this entity */
  name: Scalars['String'];
  /** The parent of this entity */
  parent: IdName;
  /** A unique tag for this entity */
  tag: Scalars['String'];
};

export type AssetUpdateRequestInput = {
  /** A client unique name for this asset. This can be any value that is relevant for the client. */
  name?: Maybe<Scalars['String']>;
  /** The type of asset */
  assetType?: Maybe<IdNameInput>;
  /** The color of the icon for this asset */
  color?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The cost centre to which this asset belongs */
  costCentre?: Maybe<IdNameInput>;
  /** The trip rating profile to use for this assets trip rating */
  ratingProfile?: Maybe<IdNameInput>;
  /** One or more asset state profiles to use for this asset */
  assetStateProfiles?: Maybe<Array<Maybe<IdNameInput>>>;
  /** The overspeed profile to use for this asset */
  overspeedProfile?: Maybe<IdNameInput>;
  /** One of more asset groups that this asset belongs to */
  groups?: Maybe<Array<Maybe<IdNameInput>>>;
  /** Up to 5 different categories that this asset belongs to */
  categories?: Maybe<Array<Maybe<IdNameInput>>>;
  /** One or more clients to which this asset has been shared. */
  sharedWith?: Maybe<Array<Maybe<IdNameInput>>>;
  assetTag?: Maybe<IdNameInput>;
  /** The current state of the asset object */
  state?: Maybe<Scalars['String']>;
  /** A number of custom field values for this asset. */
  fields?: Maybe<Scalars['JSONObject']>;
  parameters?: Maybe<AssetParametersInput>;
  /** Details about an active geo-lock on this asset (if any) */
  geoLock?: Maybe<AssetGeoLockInput>;
  /** One or more contacts that are relevant to this asset */
  contacts?: Maybe<Array<Maybe<ContactInput>>>;
  /** For static assets this field can be set with the known location of the asset */
  location?: Maybe<AssetLocationInput>;
  /** A list of zones and routes that are relevant to this asset */
  zones?: Maybe<Array<Maybe<AssetZoneTargetInput>>>;
  /** Populate this field to supply new odometer and/or engine hours values for the asset. */
  counterValues?: Maybe<AssetCounterValuesInput>;
};

export type AssetZoneTarget = {
  __typename?: 'AssetZoneTarget';
  zoneType?: Maybe<Scalars['String']>;
  targetSelectionType?: Maybe<Scalars['String']>;
  targetId?: Maybe<Scalars['String']>;
  targetName?: Maybe<Scalars['String']>;
};

export type AssetZoneTargetInput = {
  zoneType?: Maybe<Scalars['String']>;
  targetSelectionType?: Maybe<Scalars['String']>;
  targetId?: Maybe<Scalars['String']>;
  targetName?: Maybe<Scalars['String']>;
};

export type AuditEventChange = {
  __typename?: 'AuditEventChange';
  action: Scalars['String'];
  field: Scalars['String'];
  name: Scalars['String'];
  oldValue?: Maybe<AuditEventKeyValue>;
  newValue?: Maybe<AuditEventKeyValue>;
};

/** A list of audit event items */
export type AuditEventFeedResponse = {
  __typename?: 'AuditEventFeedResponse';
  /** sequence for the feed */
  sequence: Scalars['Float'];
  /** number of items in the feed */
  count: Scalars['Float'];
  items: Array<Maybe<AuditEventResponse>>;
};

export type AuditEventKeyValue = {
  __typename?: 'AuditEventKeyValue';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type AuditEventResponse = {
  __typename?: 'AuditEventResponse';
  /** The unique ID for the event */
  id: Scalars['String'];
  /** The owner company of the event */
  owner: IdNameType;
  /** The user that triggered the event */
  entity: IdNameType;
  /** The user that triggered the event */
  user: IdName;
  /** The UTC date when the event was recorded in the system */
  date: Scalars['String'];
  /** The class of the event */
  eventClass: Scalars['String'];
  /** The type of event in the given class */
  eventType: Scalars['String'];
  /** A list of changes that were applied */
  changes: Array<Maybe<AuditEventChange>>;
};

export type AuthEndpoint = {
  __typename?: 'AuthEndpoint';
  /** The ID for the endpoint. */
  id: Scalars['String'];
  /** Type type of endpoint */
  type: Scalars['String'];
  /** A friendly name for the endpoint. */
  name: Scalars['String'];
  /** The endpoint url, i.e. https://accounts.google.com */
  endpoint: Scalars['String'];
  /** The endpoint client id */
  clientId: Scalars['String'];
};

export type AuthInfo = {
  __typename?: 'AuthInfo';
  /** The types of authentication that are available */
  availableAuth: Array<Maybe<Scalars['String']>>;
  /** 3rd party endpoints which can handle authentication */
  endpoints: Array<Maybe<AuthEndpoint>>;
};

export type AuthRefreshTokenRequestInput = {
  /** A valid user refresh token */
  refreshToken: Scalars['String'];
};

export type AuthRequestInput = {
  /** Optionally pass the domain for which this login is restricted to. Required for
   * OpenId Connect `id_token` based authentication.
   */
  domain?: Maybe<Scalars['String']>;
  /** The username to authenticate against, usually an email address. Required for password validation. */
  username?: Maybe<Scalars['String']>;
  /** The password for the specified username. Required if you passed a username. */
  password?: Maybe<Scalars['String']>;
  /** Optional - if multiple matching users are expected, you can pre-select the user you want by passing in the user id */
  userId?: Maybe<Scalars['String']>;
  /** Optional token based authentication. This could be a password reset token or
   * an OpenId Connect `id_token`. Username and password are ignored if a token is passed.
   */
  token?: Maybe<Scalars['String']>;
};

export type AuthResetPasswordRequestInput = {
  /** The full URL where the user will be redirected for password reset. Include a
   * {token} template variable so the API can insert the reset token.
   * i.e. https://example.com/reset?token={token}
   */
  resetUrl: Scalars['String'];
  /** The username to reset, usually an email address. */
  username: Scalars['String'];
};

export type AuthSetPasswordRequestInput = {
  /** The reset token that was sent to the user. */
  token: Scalars['String'];
  /** The user's new password */
  newPassword: Scalars['String'];
};

export type AuthUserResponse = {
  __typename?: 'AuthUserResponse';
  /** The unique user identifier */
  id: Scalars['String'];
  /** The user's username (usually his email address) */
  username: Scalars['String'];
  /** The user's full name */
  name: Scalars['String'];
  /** The entity that owns this user */
  owner: IdNameType;
  /** The client that has been configured as the default for this user */
  defaultClient: IdName;
  /** The cost centre to which this user belongs */
  costCentre: IdName;
  /** The user's timezone, either as a region/city value (i.e. America/New_York) or a specific timezone (i.e. GMT+2) */
  timeZoneId: Scalars['String'];
  /** The user's chosen language */
  language: Scalars['String'];
};

export type CalculatedMeasure = {
  __typename?: 'CalculatedMeasure';
  id: Scalars['String'];
  name: Scalars['String'];
  expression: Scalars['String'];
  average: Scalars['Boolean'];
  total: Scalars['Boolean'];
  format: Scalars['String'];
};

export type CalculatedMeasureFormat = {
  __typename?: 'CalculatedMeasureFormat';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type CalculatedMeasureFormatInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type CalculatedMeasureInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  expression: Scalars['String'];
  average: Scalars['Boolean'];
  total: Scalars['Boolean'];
  format: Scalars['String'];
};

export type CalculatedMeasureSettings = {
  __typename?: 'CalculatedMeasureSettings';
  formatOptions: Array<Maybe<CalculatedMeasureFormat>>;
  measures: Array<Maybe<CalculatedMeasure>>;
};

export type CalculatedMeasureSettingsInput = {
  formatOptions: Array<Maybe<CalculatedMeasureFormatInput>>;
  measures: Array<Maybe<CalculatedMeasureInput>>;
};

export type CellProperties = {
  __typename?: 'CellProperties';
  raw?: Maybe<Scalars['JSONObject']>;
  format?: Maybe<ColumnFormatting>;
  doNotShowOnChart?: Maybe<Scalars['Boolean']>;
  type: Scalars['String'];
  seriesKey?: Maybe<Scalars['String']>;
  uniquename?: Maybe<Scalars['String']>;
  dimension?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
};

export type CellSet = {
  __typename?: 'CellSet';
  rowLevels: Scalars['Float'];
  filters: CellSetFilters;
  average?: Maybe<CellSetAverage>;
  cellset: Array<Maybe<Array<Maybe<CellValue>>>>;
  status: Scalars['String'];
};

export type CellSetAverage = {
  __typename?: 'CellSetAverage';
  value: Array<Maybe<Scalars['String']>>;
  raw: Array<Maybe<Scalars['Float']>>;
};

export type CellSetFilters = {
  __typename?: 'CellSetFilters';
  row: Scalars['JSONObject'];
  group: Scalars['JSONObject'];
};

export type CellSetResponse = {
  __typename?: 'CellSetResponse';
  cellset: CellSet;
};

export type CellValue = {
  __typename?: 'CellValue';
  value: Scalars['JSONObject'];
  type: Scalars['String'];
  properties: CellProperties;
};

export type ChartAxisMaxMin = {
  __typename?: 'ChartAxisMaxMin';
  max?: Maybe<Scalars['String']>;
  min?: Maybe<Scalars['String']>;
};

export type ChartAxisMaxMinInput = {
  max?: Maybe<Scalars['String']>;
  min?: Maybe<Scalars['String']>;
};

export type ChartLabel = {
  __typename?: 'ChartLabel';
  visible: Scalars['Boolean'];
  placement: Scalars['String'];
  position: Scalars['String'];
  overlay: Scalars['Boolean'];
};

export type ChartLabelInput = {
  visible: Scalars['Boolean'];
  placement: Scalars['String'];
  position: Scalars['String'];
  overlay: Scalars['Boolean'];
};

export type ChartOptions = {
  __typename?: 'ChartOptions';
  yAxis?: Maybe<ChartYAxis>;
  xAxis?: Maybe<ChartXAxis>;
  stacked: Scalars['Boolean'];
  label: ChartLabel;
  series: Scalars['JSONObject'];
  dataLabels?: Maybe<Scalars['Boolean']>;
};

export type ChartOptionsInput = {
  yAxis?: Maybe<ChartYAxisInput>;
  xAxis?: Maybe<ChartXAxisInput>;
  stacked: Scalars['Boolean'];
  label: ChartLabelInput;
  series: Scalars['JSONObject'];
  dataLabels?: Maybe<Scalars['Boolean']>;
};

export type ChartXAxis = {
  __typename?: 'ChartXAxis';
  labels: ChartXAxisLabels;
};

export type ChartXAxisInput = {
  labels: ChartXAxisLabelsInput;
};

export type ChartXAxisLabels = {
  __typename?: 'ChartXAxisLabels';
  layout: Scalars['String'];
};

export type ChartXAxisLabelsInput = {
  layout: Scalars['String'];
};

export type ChartYAxis = {
  __typename?: 'ChartYAxis';
  left?: Maybe<ChartAxisMaxMin>;
  right?: Maybe<ChartAxisMaxMin>;
};

export type ChartYAxisInput = {
  left?: Maybe<ChartAxisMaxMinInput>;
  right?: Maybe<ChartAxisMaxMinInput>;
};

/** The ClientCreateRequest is used to create a new entity. */
export type ClientCreateRequestInput = {
  /** The display name of the company */
  name?: Maybe<Scalars['String']>;
  /** A list of custom ID's for this company. Can be queried using the
   * getClientByTag, getVendorByTag and getDistributorByTag methods.
   */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The company website (if available) */
  website?: Maybe<Scalars['String']>;
  /** The state of this company */
  state?: Maybe<Scalars['String']>;
  /** Entity specific metadata */
  entity?: Maybe<EntityInfoInput>;
  /** Soft limits that apply to this company. This includes overrides only, see `getLookups` for defaults. */
  limits?: Maybe<SoftLimitsInput>;
  /** A set of user defined flags */
  flags?: Maybe<Scalars['JSONObject']>;
  /** The theme that this company uses */
  theme?: Maybe<ThemeInfoInput>;
  /** Address information for this company */
  address?: Maybe<CompanyAddressInput>;
  /** The default timezone for this company */
  timeZoneId?: Maybe<Scalars['String']>;
  /** A set of custom fields for this company */
  customFields?: Maybe<Scalars['JSONObject']>;
  /** A list of custom domains to use for this company */
  domains?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The default language to user for this client. */
  language?: Maybe<Scalars['String']>;
  /** Support contact information that will be displayed to user of this company */
  support?: Maybe<CompanySupportDetailsInput>;
  /** Customized messages that are displayed to users of this company. */
  messages?: Maybe<CompanyMessagesInput>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: Maybe<Scalars['JSONObject']>;
  /** The map set that has been defined for this client. It may be empty in which case the default will be used. */
  mapSet?: Maybe<IdNameInput>;
  /** [DEPRECATED] use the `flags` property instead. */
  features?: Maybe<Scalars['JSONObject']>;
  /** A user friendly pin that uniquely identifies this client. */
  pin?: Maybe<Scalars['String']>;
  /** The group that this client belongs to */
  group?: Maybe<Scalars['String']>;
  /** UUID's of the device types that this client is allowed to use. An empty list means all device types. */
  availableDeviceTypes?: Maybe<Array<Maybe<IdNameInput>>>;
  /** Dynamically resolved meta information for the client */
  meta?: Maybe<ClientMetaInput>;
  /** A shift pattern for the client */
  shiftPattern?: Maybe<ShiftPatternInput>;
  /** Measurement unit configuration for this client. */
  measurementUnits?: Maybe<ClientMeasurementUnitsInput>;
  ownerId: Scalars['String'];
};

export type ClientDetailsUpdateRequestInput = {
  /** The theme that this company uses */
  theme?: Maybe<ThemeInfoInput>;
  /** Address information for this company */
  address?: Maybe<CompanyAddressInput>;
  /** The default timezone for this company */
  timeZoneId?: Maybe<Scalars['String']>;
  /** A set of custom fields for this company */
  customFields?: Maybe<Scalars['JSONObject']>;
  /** A list of custom domains to use for this company */
  domains?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The default language to user for this client. */
  language?: Maybe<Scalars['String']>;
  /** Support contact information that will be displayed to user of this company */
  support?: Maybe<CompanySupportDetailsInput>;
  /** Customized messages that are displayed to users of this company. */
  messages?: Maybe<CompanyMessagesInput>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: Maybe<Scalars['JSONObject']>;
  /** Measurement unit configuration for this client. */
  measurementUnits?: Maybe<ClientMeasurementUnitsInput>;
};

/** Summary client information returned in lists. */
export type ClientListItem = {
  __typename?: 'ClientListItem';
  /** The client's unique UUID */
  id: Scalars['String'];
  /** The client's display name */
  name: Scalars['String'];
  /** The client website (if available) */
  website: Scalars['String'];
  /** The vendor that owns this client. */
  owner: IdName;
  /** The group to which this client belongs. */
  group: Scalars['String'];
  /** The state of this client. */
  state: Scalars['String'];
  /** A dictionary of child entity names and their counts, present if the `counts` parameter is supplied. */
  counts?: Maybe<Scalars['JSONObject']>;
  /** The date this client was created. */
  creationDate: Scalars['String'];
  /** The date that this client was last modified. */
  modifiedDate: Scalars['String'];
};

/** A paginated list of client items. */
export type ClientListResponse = {
  __typename?: 'ClientListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<ClientListItem>>;
};

export type ClientMeasurementUnits = {
  __typename?: 'ClientMeasurementUnits';
  distanceUnit?: Maybe<Scalars['String']>;
  altitudeUnit?: Maybe<Scalars['String']>;
  speedUnit?: Maybe<Scalars['String']>;
  areaUnit?: Maybe<Scalars['String']>;
  volumeUnit?: Maybe<Scalars['String']>;
  weightUnit?: Maybe<Scalars['String']>;
  timeUnit?: Maybe<Scalars['String']>;
  dateUnit?: Maybe<Scalars['String']>;
  temperatureUnit?: Maybe<Scalars['String']>;
};

export type ClientMeasurementUnitsInput = {
  distanceUnit?: Maybe<Scalars['String']>;
  altitudeUnit?: Maybe<Scalars['String']>;
  speedUnit?: Maybe<Scalars['String']>;
  areaUnit?: Maybe<Scalars['String']>;
  volumeUnit?: Maybe<Scalars['String']>;
  weightUnit?: Maybe<Scalars['String']>;
  timeUnit?: Maybe<Scalars['String']>;
  dateUnit?: Maybe<Scalars['String']>;
  temperatureUnit?: Maybe<Scalars['String']>;
};

/** The meta structure contains information that has been resolved dynamically for this client. */
export type ClientMeta = {
  __typename?: 'ClientMeta';
  /** The map set that should be used by this client, it may have been passed down from the parent vendor. */
  currentMapSetId: Scalars['String'];
  /** A set of user defined flags that have been merged with the client group tree that this client belongs to. */
  mergedFlags: Scalars['JSONObject'];
};

/** The meta structure contains information that has been resolved dynamically for this client. */
export type ClientMetaInput = {
  /** The map set that should be used by this client, it may have been passed down from the parent vendor. */
  currentMapSetId: Scalars['String'];
  /** A set of user defined flags that have been merged with the client group tree that this client belongs to. */
  mergedFlags: Scalars['JSONObject'];
};

export type ClientResponse = {
  __typename?: 'ClientResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The display name of the company */
  name?: Maybe<Scalars['String']>;
  /** A list of custom ID's for this company. Can be queried using the
   * getClientByTag, getVendorByTag and getDistributorByTag methods.
   */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The company website (if available) */
  website?: Maybe<Scalars['String']>;
  /** The state of this company */
  state?: Maybe<Scalars['String']>;
  /** Entity specific metadata. */
  entity: EntityInfo;
  /** Soft limits that apply to this company. This includes overrides only, see `getLookups` for defaults. */
  limits?: Maybe<SoftLimits>;
  /** A set of user defined flags */
  flags?: Maybe<Scalars['JSONObject']>;
  /** The theme that this company uses */
  theme?: Maybe<ThemeInfo>;
  /** Address information for this company */
  address?: Maybe<CompanyAddress>;
  /** The default timezone for this company */
  timeZoneId?: Maybe<Scalars['String']>;
  /** A set of custom fields for this company */
  customFields?: Maybe<Scalars['JSONObject']>;
  /** A list of custom domains to use for this company */
  domains?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The default language to user for this client. */
  language?: Maybe<Scalars['String']>;
  /** Support contact information that will be displayed to user of this company */
  support?: Maybe<CompanySupportDetails>;
  /** Customized messages that are displayed to users of this company. */
  messages?: Maybe<CompanyMessages>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: Maybe<Scalars['JSONObject']>;
  /** The map set that has been defined for this client. It may be empty in which case the default will be used. */
  mapSet?: Maybe<IdName>;
  /** [DEPRECATED] use the `flags` property instead. */
  features?: Maybe<Scalars['JSONObject']>;
  /** A user friendly pin that uniquely identifies this client. */
  pin?: Maybe<Scalars['String']>;
  /** The group that this client belongs to */
  group?: Maybe<Scalars['String']>;
  /** UUID's of the device types that this client is allowed to use. An empty list means all device types. */
  availableDeviceTypes?: Maybe<Array<Maybe<IdName>>>;
  /** Dynamically resolved meta information for the client */
  meta?: Maybe<ClientMeta>;
  /** A shift pattern for the client */
  shiftPattern?: Maybe<ShiftPattern>;
  /** Measurement unit configuration for this client. */
  measurementUnits?: Maybe<ClientMeasurementUnits>;
};

/** The ClientUpdateRequest is used to update an existing entity. All fields are optional and can be
 * individually supplied to the update method.
 */
export type ClientUpdateRequestInput = {
  /** The display name of the company */
  name?: Maybe<Scalars['String']>;
  /** A list of custom ID's for this company. Can be queried using the
   * getClientByTag, getVendorByTag and getDistributorByTag methods.
   */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The company website (if available) */
  website?: Maybe<Scalars['String']>;
  /** The state of this company */
  state?: Maybe<Scalars['String']>;
  /** Entity specific metadata */
  entity?: Maybe<EntityInfoInput>;
  /** Soft limits that apply to this company. This includes overrides only, see `getLookups` for defaults. */
  limits?: Maybe<SoftLimitsInput>;
  /** A set of user defined flags */
  flags?: Maybe<Scalars['JSONObject']>;
  /** The theme that this company uses */
  theme?: Maybe<ThemeInfoInput>;
  /** Address information for this company */
  address?: Maybe<CompanyAddressInput>;
  /** The default timezone for this company */
  timeZoneId?: Maybe<Scalars['String']>;
  /** A set of custom fields for this company */
  customFields?: Maybe<Scalars['JSONObject']>;
  /** A list of custom domains to use for this company */
  domains?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The default language to user for this client. */
  language?: Maybe<Scalars['String']>;
  /** Support contact information that will be displayed to user of this company */
  support?: Maybe<CompanySupportDetailsInput>;
  /** Customized messages that are displayed to users of this company. */
  messages?: Maybe<CompanyMessagesInput>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: Maybe<Scalars['JSONObject']>;
  /** The map set that has been defined for this client. It may be empty in which case the default will be used. */
  mapSet?: Maybe<IdNameInput>;
  /** [DEPRECATED] use the `flags` property instead. */
  features?: Maybe<Scalars['JSONObject']>;
  /** A user friendly pin that uniquely identifies this client. */
  pin?: Maybe<Scalars['String']>;
  /** The group that this client belongs to */
  group?: Maybe<Scalars['String']>;
  /** UUID's of the device types that this client is allowed to use. An empty list means all device types. */
  availableDeviceTypes?: Maybe<Array<Maybe<IdNameInput>>>;
  /** Dynamically resolved meta information for the client */
  meta?: Maybe<ClientMetaInput>;
  /** A shift pattern for the client */
  shiftPattern?: Maybe<ShiftPatternInput>;
  /** Measurement unit configuration for this client. */
  measurementUnits?: Maybe<ClientMeasurementUnitsInput>;
};

export type ColumnFormatting = {
  __typename?: 'ColumnFormatting';
  align: Scalars['String'];
  width: Scalars['Float'];
};

export type CompanyAddress = {
  __typename?: 'CompanyAddress';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type CompanyAddressInput = {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type CompanyGroupCreateRequestInput = {
  /** A unique name for this entity */
  name: Scalars['String'];
  /** The parent of this entity */
  parent?: Maybe<IdNameInput>;
  /** A set of user defined flags */
  flags?: Maybe<Scalars['JSONObject']>;
  /** A set of user defined flags */
  email?: Maybe<EmailConfigInput>;
  /** The client that owns this entity */
  ownerId: Scalars['String'];
};

/** A list of company group items */
export type CompanyGroupListResponse = {
  __typename?: 'CompanyGroupListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<CompanyGroupResponse>>;
};

export type CompanyGroupResponse = {
  __typename?: 'CompanyGroupResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The parent of this entity */
  parent?: Maybe<IdName>;
  /** A set of user defined flags */
  flags?: Maybe<Scalars['JSONObject']>;
  /** A set of user defined flags */
  email?: Maybe<EmailConfig>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type CompanyGroupUpdateRequestInput = {
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The parent of this entity */
  parent?: Maybe<IdNameInput>;
  /** A set of user defined flags */
  flags?: Maybe<Scalars['JSONObject']>;
  /** A set of user defined flags */
  email?: Maybe<EmailConfigInput>;
};

export type CompanyMessages = {
  __typename?: 'CompanyMessages';
  /** A custom message that will be displayed on the login screen */
  login?: Maybe<Scalars['String']>;
  /** A custom message that will be displayed if a user of this company attempts to log in, but the company is suspended. */
  suspended?: Maybe<Scalars['String']>;
};

export type CompanyMessagesInput = {
  /** A custom message that will be displayed on the login screen */
  login?: Maybe<Scalars['String']>;
  /** A custom message that will be displayed if a user of this company attempts to log in, but the company is suspended. */
  suspended?: Maybe<Scalars['String']>;
};

export type CompanyResponse = {
  __typename?: 'CompanyResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The display name of the company */
  name?: Maybe<Scalars['String']>;
  /** A list of custom ID's for this company. Can be queried using the
   * getClientByTag, getVendorByTag and getDistributorByTag methods.
   */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The company website (if available) */
  website?: Maybe<Scalars['String']>;
  /** The state of this company */
  state?: Maybe<Scalars['String']>;
  /** Entity specific metadata */
  entity?: Maybe<EntityInfo>;
  /** Soft limits that apply to this company. This includes overrides only, see `getLookups` for defaults. */
  limits?: Maybe<SoftLimits>;
  /** A set of user defined flags */
  flags?: Maybe<Scalars['JSONObject']>;
  /** The theme that this company uses */
  theme?: Maybe<ThemeInfo>;
  /** Address information for this company */
  address?: Maybe<CompanyAddress>;
  /** The default timezone for this company */
  timeZoneId?: Maybe<Scalars['String']>;
  /** A set of custom fields for this company */
  customFields?: Maybe<Scalars['JSONObject']>;
  /** A list of custom domains to use for this company */
  domains?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The default language to user for this client. */
  language?: Maybe<Scalars['String']>;
  /** Support contact information that will be displayed to user of this company */
  support?: Maybe<CompanySupportDetails>;
  /** Customized messages that are displayed to users of this company. */
  messages?: Maybe<CompanyMessages>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: Maybe<Scalars['JSONObject']>;
  /** The type of company */
  type: Scalars['String'];
};

export type CompanySupportDetails = {
  __typename?: 'CompanySupportDetails';
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type CompanySupportDetailsInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type CompletedReportDataRequestInput = {
  outputOptions?: Maybe<AnalyticsOutputRequestInput>;
};

/** A list of map set items */
export type CompletedReportListResponse = {
  __typename?: 'CompletedReportListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<CompletedReportResponse>>;
};

export type CompletedReportRequestInput = {
  outputOptions?: Maybe<AnalyticsSettingsRequestInput>;
};

export type CompletedReportResponse = {
  __typename?: 'CompletedReportResponse';
  /** The unique ID of this report definition */
  id: Scalars['String'];
  /** The entity that owns this report */
  owner: IdNameType;
  /** The user that queued this report definition */
  user: IdName;
  /** The client for which the report was run */
  client: IdName;
  /** The base report name */
  name: Scalars['String'];
  /** The report title as set by the user */
  title: Scalars['String'];
  /** The reporting subsystem that generates this report */
  source: Scalars['String'];
  /** The status of this report */
  status: Scalars['String'];
  /** The ISO date/time that this report was queued */
  queueDate: Scalars['String'];
  /** The ISO date/time that this report's state last changed */
  updateDate: Scalars['String'];
  /** The output format for this report */
  outputFormat: Scalars['String'];
  /** The progress percentage of this report */
  progress: Scalars['Float'];
  /** report queue order, will change sometimes while queued */
  order: Scalars['Float'];
  /** The report priority: 0 = urgent, 1 = high, 2 = normal, 3 = low, 4+ = none (sorted as an integer) */
  priority: Scalars['Float'];
  /** The report configuration */
  config: ReportConfig;
  /** What triggered the report */
  triggeredBy: Scalars['String'];
  outputOptions?: Maybe<ReportOutputOptions>;
};

export type CompletedReportUpdateRequestInput = {
  outputOptions: ReportOutputOptionsInput;
};

/** Contacts can be assigned to assets and will be displayed along side any asset related information. */
export type Contact = {
  __typename?: 'Contact';
  /** A unique id for this contact */
  id: Scalars['String'];
  /** The type of contact */
  type: Scalars['String'];
  /** The name of the contact person or company */
  name: Scalars['String'];
  /** The contact person's email address */
  emailAddress?: Maybe<Scalars['String']>;
  /** The mobile phone number of the contact */
  phoneMobile?: Maybe<Scalars['String']>;
  /** An optional office number for the contact */
  phoneOffice?: Maybe<Scalars['String']>;
  /** Any notes regarding this contact */
  notes?: Maybe<Scalars['String']>;
};

/** Contacts can be assigned to assets and will be displayed along side any asset related information. */
export type ContactInput = {
  /** A unique id for this contact */
  id: Scalars['String'];
  /** The type of contact */
  type: Scalars['String'];
  /** The name of the contact person or company */
  name: Scalars['String'];
  /** The contact person's email address */
  emailAddress?: Maybe<Scalars['String']>;
  /** The mobile phone number of the contact */
  phoneMobile?: Maybe<Scalars['String']>;
  /** An optional office number for the contact */
  phoneOffice?: Maybe<Scalars['String']>;
  /** Any notes regarding this contact */
  notes?: Maybe<Scalars['String']>;
};

export type CostCentreCreateRequestInput = {
  /** A unique name for this entity */
  name: Scalars['String'];
  /** The parent of this entity */
  parent?: Maybe<IdNameInput>;
  /** A list of contacts applicable to this cost centre */
  contacts?: Maybe<Array<Maybe<ContactInput>>>;
  /** The client that owns this entity */
  ownerId: Scalars['String'];
};

/** A list of cost centre items */
export type CostCentreListResponse = {
  __typename?: 'CostCentreListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<CostCentreResponse>>;
};

export type CostCentreResponse = {
  __typename?: 'CostCentreResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The parent of this entity */
  parent?: Maybe<IdName>;
  /** A list of contacts applicable to this cost centre */
  contacts?: Maybe<Array<Maybe<Contact>>>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type CostCentreUpdateRequestInput = {
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The parent of this entity */
  parent?: Maybe<IdNameInput>;
  /** A list of contacts applicable to this cost centre */
  contacts?: Maybe<Array<Maybe<ContactInput>>>;
};

export type DashboardCreateRequestInput = {
  /** The user to whom this dashboard belongs */
  user?: Maybe<IdNameInput>;
  /** The name of the dashboard. */
  name?: Maybe<Scalars['String']>;
  /** A short description of the dashboard. */
  description: Scalars['String'];
  /** Whether or not other users can see the dashboard */
  public?: Maybe<Scalars['Boolean']>;
  /** A number of custom field values for this dashboard. */
  options?: Maybe<Scalars['JSONObject']>;
  /** A dictionary of the widgets in this dashboard */
  widgets?: Maybe<Scalars['JSONObject']>;
  /** The template dashboard from which this dashboard was constructed. This dashboard will track
   * all of the widgets on the parent dashboard.
   */
  parent?: Maybe<IdNameInput>;
  /** The source dashboard if this dashboard was cloned from another. */
  source?: Maybe<IdNameInput>;
  ownerId: Scalars['String'];
  /** The cost centre the dashboard belongs to */
  costCentre?: Maybe<IdNameInput>;
};

export type DashboardListItem = {
  __typename?: 'DashboardListItem';
  /** The unique UUID for this Dashboard */
  id: Scalars['String'];
  /** The client that owns this Dashboard */
  owner: IdNameType;
  /** The template dashboard from which this dashboard was constructed. This dashboard will track
   * all of the widgets on the parent dashboard.
   */
  parent?: Maybe<IdName>;
  /** The source dashboard if this dashboard was cloned from another. */
  source?: Maybe<IdName>;
  /** The cost centre the dashboard belongs to */
  costCentre?: Maybe<IdName>;
  /** The user to whom this dashboard belongs */
  user: IdName;
  /** The name of the dashboard. */
  name: Scalars['String'];
  /** Whethere or not other users can see the dashboard */
  public: Scalars['Boolean'];
  /** A short description of the dashboard. */
  description: Scalars['String'];
  /** The date the entity was last modified */
  modifiedDate: Scalars['String'];
};

/** A list of Dashboard items */
export type DashboardListResponse = {
  __typename?: 'DashboardListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  /** The list of Dashboard items in this response */
  items: Array<Maybe<DashboardListItem>>;
};

export type DashboardResponse = {
  __typename?: 'DashboardResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The user to whom this dashboard belongs */
  user?: Maybe<IdName>;
  /** The name of the dashboard. */
  name?: Maybe<Scalars['String']>;
  /** A short description of the dashboard. */
  description?: Maybe<Scalars['String']>;
  /** Whether or not other users can see the dashboard */
  public?: Maybe<Scalars['Boolean']>;
  /** A number of custom field values for this dashboard. */
  options?: Maybe<Scalars['JSONObject']>;
  /** A dictionary of the widgets in this dashboard */
  widgets?: Maybe<Scalars['JSONObject']>;
  /** The template dashboard from which this dashboard was constructed. This dashboard will track
   * all of the widgets on the parent dashboard.
   */
  parent?: Maybe<IdName>;
  /** The source dashboard if this dashboard was cloned from another. */
  source?: Maybe<IdName>;
  /** The cost centre the dashboard belongs to */
  costCentre?: Maybe<IdName>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type DashboardTemplateCreateRequestInput = {
  /** The id of the dashboard to turn into a template */
  dashboardId: Scalars['String'];
  /** The ownerId of the template */
  ownerId: Scalars['String'];
  /** The id of the user who created the template */
  user: IdNameInput;
  /** The name of the dashboard template. */
  name: Scalars['String'];
  /** A short description of the dashboard. */
  description: Scalars['String'];
  /** Determines if the dashboard can be added by downstream users */
  public: Scalars['Boolean'];
};

export type DashboardTemplateListItem = {
  __typename?: 'DashboardTemplateListItem';
  /** The unique UUID for this Dashboard */
  id: Scalars['String'];
  /** The client that owns this Dashboard */
  owner: IdNameType;
  /** The state of the entity */
  state: Scalars['String'];
  /** The user to whom this dashboard belongs */
  user: IdName;
  /** The name of the dashboard. */
  name: Scalars['String'];
  /** A short description of the dashboard. */
  description: Scalars['String'];
  /** Determines if the dashboard can be added by downstream users */
  public?: Maybe<Scalars['Boolean']>;
  /** The date the entity was last modified */
  modifiedDate: Scalars['String'];
};

/** A list of Dashboard items */
export type DashboardTemplateListResponse = {
  __typename?: 'DashboardTemplateListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  /** The list of Dashboard items in this response */
  items: Array<Maybe<DashboardTemplateListItem>>;
};

export type DashboardTemplateResponse = {
  __typename?: 'DashboardTemplateResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The user to whom this dashboard belongs */
  user?: Maybe<IdName>;
  /** The name of the dashboard. */
  name?: Maybe<Scalars['String']>;
  /** A short description of the dashboard. */
  description?: Maybe<Scalars['String']>;
  /** Determines if the template can be added by downstream users */
  public?: Maybe<Scalars['Boolean']>;
  /** A number of custom field values for this dashboard. */
  options?: Maybe<Scalars['JSONObject']>;
  /** A dictionary of the widgets in this dashboard */
  widgets?: Maybe<Scalars['JSONObject']>;
  /** The state of dashboard template */
  state?: Maybe<Scalars['String']>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type DashboardTemplateUpdateRequestInput = {
  /** The user to whom this dashboard belongs */
  user?: Maybe<IdNameInput>;
  /** The name of the dashboard. */
  name?: Maybe<Scalars['String']>;
  /** A short description of the dashboard. */
  description?: Maybe<Scalars['String']>;
  /** Determines if the template can be added by downstream users */
  public?: Maybe<Scalars['Boolean']>;
  /** A number of custom field values for this dashboard. */
  options?: Maybe<Scalars['JSONObject']>;
  /** A dictionary of the widgets in this dashboard */
  widgets?: Maybe<Scalars['JSONObject']>;
  /** The state of dashboard template */
  state?: Maybe<Scalars['String']>;
};

export type DashboardUpdateRequestInput = {
  /** The user to whom this dashboard belongs */
  user?: Maybe<IdNameInput>;
  /** The name of the dashboard. */
  name?: Maybe<Scalars['String']>;
  /** A short description of the dashboard. */
  description?: Maybe<Scalars['String']>;
  /** Whether or not other users can see the dashboard */
  public?: Maybe<Scalars['Boolean']>;
  /** A number of custom field values for this dashboard. */
  options?: Maybe<Scalars['JSONObject']>;
  /** A dictionary of the widgets in this dashboard */
  widgets?: Maybe<Scalars['JSONObject']>;
  /** The template dashboard from which this dashboard was constructed. This dashboard will track
   * all of the widgets on the parent dashboard.
   */
  parent?: Maybe<IdNameInput>;
  /** The source dashboard if this dashboard was cloned from another. */
  source?: Maybe<IdNameInput>;
};

export type DeviceCreateRequestInput = {
  /** The serial or IMEI of the device that is used to uniquely identify it. The value used will depend on the device type. */
  name?: Maybe<Scalars['String']>;
  /** The current state of the device object */
  state?: Maybe<Scalars['String']>;
  /** The type of device */
  deviceType: IdNameInput;
  /** The device provider, if the device requires one to function. */
  provider?: Maybe<IdNameInput>;
  /** The asset to which this device has been assigned. */
  asset?: Maybe<IdNameInput>;
  /** The sim card which has been assigned to this device. */
  simcard?: Maybe<IdNameInput>;
  /** A number of device specific parameters */
  parameters?: Maybe<DeviceParametersInput>;
  /** Values for the DeviceType's setting definition form */
  settings?: Maybe<Scalars['JSONObject']>;
  /** Accessory settings for this device */
  accessories?: Maybe<Scalars['JSONObject']>;
  /** A number of custom field values for this device. */
  fields?: Maybe<Scalars['JSONObject']>;
  ownerId: Scalars['String'];
};

export type DeviceIoParameters = {
  __typename?: 'DeviceIOParameters';
  /** Digital input configuration */
  digital_input?: Maybe<Scalars['JSONObject']>;
  /** Digital output configuration */
  digital_output?: Maybe<Scalars['JSONObject']>;
  /** Analog input configuration */
  analog_input?: Maybe<Scalars['JSONObject']>;
  /** Temperature input configuration */
  temperature_input?: Maybe<Scalars['JSONObject']>;
  /** CAN bus configuration */
  can_input?: Maybe<Scalars['JSONObject']>;
  /** Counter input configuration */
  counter_input?: Maybe<Scalars['JSONObject']>;
  /** Camera configuration */
  camera_input?: Maybe<Scalars['JSONObject']>;
  /** Value configuration */
  value_input?: Maybe<Scalars['JSONObject']>;
};

export type DeviceIoParametersInput = {
  /** Digital input configuration */
  digital_input?: Maybe<Scalars['JSONObject']>;
  /** Digital output configuration */
  digital_output?: Maybe<Scalars['JSONObject']>;
  /** Analog input configuration */
  analog_input?: Maybe<Scalars['JSONObject']>;
  /** Temperature input configuration */
  temperature_input?: Maybe<Scalars['JSONObject']>;
  /** CAN bus configuration */
  can_input?: Maybe<Scalars['JSONObject']>;
  /** Counter input configuration */
  counter_input?: Maybe<Scalars['JSONObject']>;
  /** Camera configuration */
  camera_input?: Maybe<Scalars['JSONObject']>;
  /** Value configuration */
  value_input?: Maybe<Scalars['JSONObject']>;
};

export type DeviceListItem = {
  __typename?: 'DeviceListItem';
  /** The unique UUID for this device */
  id: Scalars['String'];
  /** The serial or IMEI of the device that is used to uniquely identify it. The value used will depend on the device type. */
  name: Scalars['String'];
  /** The client that owns this device */
  owner: IdName;
  /** The type of device */
  deviceType?: Maybe<IdName>;
  /** The device provider, if the device requires one to function. */
  provider?: Maybe<IdName>;
  /** The asset to which this device has been assigned. */
  asset?: Maybe<AssetResponse>;
  /** The sim card which has been assigned to this device. */
  simcard?: Maybe<IdName>;
  /** A number of custom field values for this device. */
  fields: Scalars['JSONObject'];
  /** The current state of the device object */
  state: Scalars['String'];
};

/** A list of device items */
export type DeviceListResponse = {
  __typename?: 'DeviceListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  /** The list of deviceitems in this response */
  items: Array<Maybe<DeviceListItem>>;
};

export type DeviceParameters = {
  __typename?: 'DeviceParameters';
  /** The input that is used to determine when the device becomes active (ignition
   * on for vehicles). Determines when trips are started and ended.
   */
  active_input?: Maybe<Scalars['String']>;
  /** The input that is used to start counting engine hours. This is usually the same as 'active_input'. */
  hours_input?: Maybe<Scalars['String']>;
  /** The input that is used to determine when the device is idling. Defaults to
   * "speed" where idling is calulated based on device speed.
   */
  idling_input?: Maybe<Scalars['String']>;
  /** Mappings between IoTypes and the device's telemetry inputs and outputs */
  io?: Maybe<DeviceIoParameters>;
};

export type DeviceParametersInput = {
  /** The input that is used to determine when the device becomes active (ignition
   * on for vehicles). Determines when trips are started and ended.
   */
  active_input?: Maybe<Scalars['String']>;
  /** The input that is used to start counting engine hours. This is usually the same as 'active_input'. */
  hours_input?: Maybe<Scalars['String']>;
  /** The input that is used to determine when the device is idling. Defaults to
   * "speed" where idling is calulated based on device speed.
   */
  idling_input?: Maybe<Scalars['String']>;
  /** Mappings between IoTypes and the device's telemetry inputs and outputs */
  io?: Maybe<DeviceIoParametersInput>;
};

export type DeviceProviderCreateRequestInput = {
  /** The name of the device provider. */
  name?: Maybe<Scalars['String']>;
  /** The current state of the device provider object */
  state?: Maybe<Scalars['String']>;
  /** The type of device provider */
  deviceProviderType?: Maybe<IdNameInput>;
  ownerId: Scalars['String'];
  /** A number of device provider specific parameters */
  parameters?: Maybe<Scalars['JSONObject']>;
};

export type DeviceProviderListItem = {
  __typename?: 'DeviceProviderListItem';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The name of the device provider. */
  name?: Maybe<Scalars['String']>;
  /** The current state of the device provider object */
  state?: Maybe<Scalars['String']>;
  /** The type of device provider */
  deviceProviderType?: Maybe<IdName>;
  /** The date the entity was last modified */
  modifiedDate: Scalars['String'];
};

/** A list of device provider items */
export type DeviceProviderListResponse = {
  __typename?: 'DeviceProviderListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  /** The list of device provider items in this response */
  items: Array<Maybe<DeviceProviderListItem>>;
};

export type DeviceProviderResponse = {
  __typename?: 'DeviceProviderResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The name of the device provider. */
  name?: Maybe<Scalars['String']>;
  /** The current state of the device provider object */
  state?: Maybe<Scalars['String']>;
  /** The type of device provider */
  deviceProviderType?: Maybe<IdName>;
  /** A number of device provider specific parameters */
  parameters?: Maybe<Scalars['JSONObject']>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type DeviceProviderTypeListItem = {
  __typename?: 'DeviceProviderTypeListItem';
  /** The unique ID of this device provider type */
  id: Scalars['String'];
  /** The company that owns this entity */
  owner: IdNameType;
  /** A unique name for this entity */
  name: Scalars['String'];
  /** A unique tag for this entity */
  tag: Scalars['String'];
  /** The company types that this provider type can be instantiated in. Valid values are 'client', 'vendor' and 'distributor' */
  companyTypes: Array<Maybe<Scalars['String']>>;
  /** The current state of the object */
  state?: Maybe<Scalars['String']>;
};

/** A list of device provider type items */
export type DeviceProviderTypeListResponse = {
  __typename?: 'DeviceProviderTypeListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<DeviceProviderTypeListItem>>;
};

export type DeviceProviderTypeResponse = {
  __typename?: 'DeviceProviderTypeResponse';
  /** The unique ID of this device provider type */
  id: Scalars['String'];
  /** The company that owns this entity */
  owner: IdNameType;
  /** A unique name for this entity */
  name: Scalars['String'];
  /** A unique tag for this entity */
  tag: Scalars['String'];
  /** The company types that this provider type can be instantiated in. Valid values are 'client', 'vendor' and 'distributor' */
  companyTypes: Array<Maybe<Scalars['String']>>;
  /** The current state of the object */
  state?: Maybe<Scalars['String']>;
  /** A JSON string that describes the parameter input fields for this device provider type */
  parameters: Scalars['String'];
};

export type DeviceProviderUpdateRequestInput = {
  /** The name of the device provider. */
  name?: Maybe<Scalars['String']>;
  /** The current state of the device provider object */
  state?: Maybe<Scalars['String']>;
  /** The type of device provider */
  deviceProviderType?: Maybe<IdNameInput>;
  /** A number of device provider specific parameters */
  parameters?: Maybe<Scalars['JSONObject']>;
};

export type DeviceResponse = {
  __typename?: 'DeviceResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The serial or IMEI of the device that is used to uniquely identify it. The value used will depend on the device type. */
  name?: Maybe<Scalars['String']>;
  /** The current state of the device object */
  state?: Maybe<Scalars['String']>;
  /** The type of device */
  deviceType?: Maybe<IdName>;
  /** The device provider, if the device requires one to function. */
  provider?: Maybe<IdName>;
  /** The asset to which this device has been assigned. */
  asset?: Maybe<AssetResponse>;
  /** The sim card which has been assigned to this device. */
  simcard?: Maybe<IdName>;
  /** A number of device specific parameters */
  parameters?: Maybe<DeviceParameters>;
  /** Values for the DeviceType's setting definition form */
  settings?: Maybe<Scalars['JSONObject']>;
  /** Accessory settings for this device */
  accessories?: Maybe<Scalars['JSONObject']>;
  /** A number of custom field values for this device. */
  fields?: Maybe<Scalars['JSONObject']>;
  /** A number of features that have been enabled on this device */
  features?: Maybe<Scalars['JSONObject']>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type DeviceTypeCreateRequestInput = {
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** A short name for this device type (usually just a model number) */
  shortName?: Maybe<Scalars['String']>;
  /** The parent of this entity */
  parent?: Maybe<IdNameInput>;
  /** A unique tag for this entity */
  tag?: Maybe<Scalars['String']>;
  /** The current state of the device type object */
  state?: Maybe<Scalars['String']>;
  /** An optional link to a device provider that supplies the data for this device type. If this is set, the user will be
   * required to select the device provider on device configuration.
   */
  deviceProviderType?: Maybe<IdNameInput>;
  /** Defines the types of IOs that are available on this device type */
  ioCapabilities?: Maybe<DeviceTypeIoCapabilitiesInput>;
  /** A form definition for custom settings in this device type */
  settingsDefinition?: Maybe<Scalars['String']>;
  /** A list of available accessories for this device type */
  accessories?: Maybe<Scalars['JSONObject']>;
  /** A map of features for this device type */
  features?: Maybe<Scalars['JSONObject']>;
  ownerId: Scalars['String'];
};

export type DeviceTypeIoCapabilities = {
  __typename?: 'DeviceTypeIOCapabilities';
  digital_input?: Maybe<Scalars['JSONObject']>;
  digital_output?: Maybe<Scalars['JSONObject']>;
  analog_input?: Maybe<Scalars['JSONObject']>;
  temperature_input?: Maybe<Scalars['JSONObject']>;
  can_input?: Maybe<Scalars['JSONObject']>;
  counter_input?: Maybe<Scalars['JSONObject']>;
  camera_input?: Maybe<Scalars['JSONObject']>;
  value_input?: Maybe<Scalars['JSONObject']>;
  cached_fields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DeviceTypeIoCapabilitiesInput = {
  digital_input?: Maybe<Scalars['JSONObject']>;
  digital_output?: Maybe<Scalars['JSONObject']>;
  analog_input?: Maybe<Scalars['JSONObject']>;
  temperature_input?: Maybe<Scalars['JSONObject']>;
  can_input?: Maybe<Scalars['JSONObject']>;
  counter_input?: Maybe<Scalars['JSONObject']>;
  camera_input?: Maybe<Scalars['JSONObject']>;
  value_input?: Maybe<Scalars['JSONObject']>;
  cached_fields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DeviceTypeListItem = {
  __typename?: 'DeviceTypeListItem';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** A short name for this device type (usually just a model number) */
  shortName?: Maybe<Scalars['String']>;
  /** The parent of this entity */
  parent?: Maybe<IdName>;
  /** A unique tag for this entity */
  tag?: Maybe<Scalars['String']>;
  /** The current state of the device type object */
  state?: Maybe<Scalars['String']>;
  /** An optional link to a device provider that supplies the data for this device type. If this is set, the user will be
   * required to select the device provider on device configuration.
   */
  deviceProviderType?: Maybe<IdName>;
  /** The date the entity was last modified */
  modifiedDate: Scalars['String'];
};

/** A list of device type items */
export type DeviceTypeListResponse = {
  __typename?: 'DeviceTypeListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<DeviceTypeListItem>>;
};

export type DeviceTypeResponse = {
  __typename?: 'DeviceTypeResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** A short name for this device type (usually just a model number) */
  shortName?: Maybe<Scalars['String']>;
  /** The parent of this entity */
  parent?: Maybe<IdName>;
  /** A unique tag for this entity */
  tag?: Maybe<Scalars['String']>;
  /** The current state of the device type object */
  state?: Maybe<Scalars['String']>;
  /** An optional link to a device provider that supplies the data for this device type. If this is set, the user will be
   * required to select the device provider on device configuration.
   */
  deviceProviderType?: Maybe<IdName>;
  /** Defines the types of IOs that are available on this device type */
  ioCapabilities?: Maybe<DeviceTypeIoCapabilities>;
  /** A form definition for custom settings in this device type */
  settingsDefinition?: Maybe<Scalars['String']>;
  /** A list of available accessories for this device type */
  accessories?: Maybe<Scalars['JSONObject']>;
  /** A map of features for this device type */
  features?: Maybe<Scalars['JSONObject']>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type DeviceTypeUpdateRequestInput = {
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** A short name for this device type (usually just a model number) */
  shortName?: Maybe<Scalars['String']>;
  /** The parent of this entity */
  parent?: Maybe<IdNameInput>;
  /** A unique tag for this entity */
  tag?: Maybe<Scalars['String']>;
  /** The current state of the device type object */
  state?: Maybe<Scalars['String']>;
  /** An optional link to a device provider that supplies the data for this device type. If this is set, the user will be
   * required to select the device provider on device configuration.
   */
  deviceProviderType?: Maybe<IdNameInput>;
  /** Defines the types of IOs that are available on this device type */
  ioCapabilities?: Maybe<DeviceTypeIoCapabilitiesInput>;
  /** A form definition for custom settings in this device type */
  settingsDefinition?: Maybe<Scalars['String']>;
  /** A list of available accessories for this device type */
  accessories?: Maybe<Scalars['JSONObject']>;
  /** A map of features for this device type */
  features?: Maybe<Scalars['JSONObject']>;
};

export type DeviceUpdateRequestInput = {
  /** The serial or IMEI of the device that is used to uniquely identify it. The value used will depend on the device type. */
  name?: Maybe<Scalars['String']>;
  /** The current state of the device object */
  state?: Maybe<Scalars['String']>;
  /** The type of device */
  deviceType?: Maybe<IdNameInput>;
  /** The device provider, if the device requires one to function. */
  provider?: Maybe<IdNameInput>;
  /** The asset to which this device has been assigned. */
  asset?: Maybe<IdNameInput>;
  /** The sim card which has been assigned to this device. */
  simcard?: Maybe<IdNameInput>;
  /** A number of device specific parameters */
  parameters?: Maybe<DeviceParametersInput>;
  /** Values for the DeviceType's setting definition form */
  settings?: Maybe<Scalars['JSONObject']>;
  /** Accessory settings for this device */
  accessories?: Maybe<Scalars['JSONObject']>;
  /** A number of custom field values for this device. */
  fields?: Maybe<Scalars['JSONObject']>;
};

export type DistributorCreateRequestInput = {
  /** The display name of the company */
  name?: Maybe<Scalars['String']>;
  /** A list of custom ID's for this company. Can be queried using the
   * getClientByTag, getVendorByTag and getDistributorByTag methods.
   */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The company website (if available) */
  website?: Maybe<Scalars['String']>;
  /** The state of this company */
  state?: Maybe<Scalars['String']>;
  /** Entity specific metadata */
  entity?: Maybe<EntityInfoInput>;
  /** Soft limits that apply to this company. This includes overrides only, see `getLookups` for defaults. */
  limits?: Maybe<SoftLimitsInput>;
  /** A set of user defined flags */
  flags?: Maybe<Scalars['JSONObject']>;
  /** The theme that this company uses */
  theme?: Maybe<ThemeInfoInput>;
  /** Address information for this company */
  address?: Maybe<CompanyAddressInput>;
  /** The default timezone for this company */
  timeZoneId?: Maybe<Scalars['String']>;
  /** A set of custom fields for this company */
  customFields?: Maybe<Scalars['JSONObject']>;
  /** A list of custom domains to use for this company */
  domains?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The default language to user for this client. */
  language?: Maybe<Scalars['String']>;
  /** Support contact information that will be displayed to user of this company */
  support?: Maybe<CompanySupportDetailsInput>;
  /** Customized messages that are displayed to users of this company. */
  messages?: Maybe<CompanyMessagesInput>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: Maybe<Scalars['JSONObject']>;
  /** [DEPRECATED] Use the listCompanyGroups operation instead */
  vendorGroups?: Maybe<Array<Maybe<IdNameInput>>>;
  /** A set of features that are enabled for this distributor. */
  features?: Maybe<Scalars['JSONObject']>;
  /** A list of maps sets that are available to this distributor */
  availableMapSets?: Maybe<Array<Maybe<IdNameInput>>>;
  ownerId: Scalars['String'];
};

export type DistributorDetailsUpdateRequestInput = {
  /** The theme that this company uses */
  theme?: Maybe<ThemeInfoInput>;
  /** Address information for this company */
  address?: Maybe<CompanyAddressInput>;
  /** The default timezone for this company */
  timeZoneId?: Maybe<Scalars['String']>;
  /** A set of custom fields for this company */
  customFields?: Maybe<Scalars['JSONObject']>;
  /** A list of custom domains to use for this company */
  domains?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The default language to user for this client. */
  language?: Maybe<Scalars['String']>;
  /** Support contact information that will be displayed to user of this company */
  support?: Maybe<CompanySupportDetailsInput>;
  /** Customized messages that are displayed to users of this company. */
  messages?: Maybe<CompanyMessagesInput>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: Maybe<Scalars['JSONObject']>;
};

/** Summary distributor information returned in lists. */
export type DistributorListItem = {
  __typename?: 'DistributorListItem';
  /** The distributor's unique ID */
  id: Scalars['String'];
  /** The distributor's display name */
  name: Scalars['String'];
  /** The distributor website (if available) */
  website: Scalars['String'];
  /** The distributor that owns this distributor */
  owner: IdName;
  /** The state of this distributor */
  state: Scalars['String'];
  /** A dictionary of child entity names and their counts, present if the `counts` parameter is supplied. */
  counts?: Maybe<Scalars['JSONObject']>;
  /** The date this distributor was created */
  creationDate: Scalars['String'];
  /** The date that this distributor was last modified */
  modifiedDate: Scalars['String'];
};

/** A list of distributor items */
export type DistributorListResponse = {
  __typename?: 'DistributorListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<DistributorListItem>>;
};

export type DistributorResponse = {
  __typename?: 'DistributorResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The display name of the company */
  name?: Maybe<Scalars['String']>;
  /** A list of custom ID's for this company. Can be queried using the
   * getClientByTag, getVendorByTag and getDistributorByTag methods.
   */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The company website (if available) */
  website?: Maybe<Scalars['String']>;
  /** The state of this company */
  state?: Maybe<Scalars['String']>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** Soft limits that apply to this company. This includes overrides only, see `getLookups` for defaults. */
  limits?: Maybe<SoftLimits>;
  /** A set of user defined flags */
  flags?: Maybe<Scalars['JSONObject']>;
  /** The theme that this company uses */
  theme?: Maybe<ThemeInfo>;
  /** Address information for this company */
  address?: Maybe<CompanyAddress>;
  /** The default timezone for this company */
  timeZoneId?: Maybe<Scalars['String']>;
  /** A set of custom fields for this company */
  customFields?: Maybe<Scalars['JSONObject']>;
  /** A list of custom domains to use for this company */
  domains?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The default language to user for this client. */
  language?: Maybe<Scalars['String']>;
  /** Support contact information that will be displayed to user of this company */
  support?: Maybe<CompanySupportDetails>;
  /** Customized messages that are displayed to users of this company. */
  messages?: Maybe<CompanyMessages>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: Maybe<Scalars['JSONObject']>;
  /** [DEPRECATED] Use the listCompanyGroups operation instead */
  vendorGroups?: Maybe<Array<Maybe<IdName>>>;
  /** A set of features that are enabled for this distributor. */
  features?: Maybe<Scalars['JSONObject']>;
  /** A list of maps sets that are available to this distributor */
  availableMapSets?: Maybe<Array<Maybe<IdName>>>;
};

export type DistributorUpdateRequestInput = {
  /** The display name of the company */
  name?: Maybe<Scalars['String']>;
  /** A list of custom ID's for this company. Can be queried using the
   * getClientByTag, getVendorByTag and getDistributorByTag methods.
   */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The company website (if available) */
  website?: Maybe<Scalars['String']>;
  /** The state of this company */
  state?: Maybe<Scalars['String']>;
  /** Entity specific metadata */
  entity?: Maybe<EntityInfoInput>;
  /** Soft limits that apply to this company. This includes overrides only, see `getLookups` for defaults. */
  limits?: Maybe<SoftLimitsInput>;
  /** A set of user defined flags */
  flags?: Maybe<Scalars['JSONObject']>;
  /** The theme that this company uses */
  theme?: Maybe<ThemeInfoInput>;
  /** Address information for this company */
  address?: Maybe<CompanyAddressInput>;
  /** The default timezone for this company */
  timeZoneId?: Maybe<Scalars['String']>;
  /** A set of custom fields for this company */
  customFields?: Maybe<Scalars['JSONObject']>;
  /** A list of custom domains to use for this company */
  domains?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The default language to user for this client. */
  language?: Maybe<Scalars['String']>;
  /** Support contact information that will be displayed to user of this company */
  support?: Maybe<CompanySupportDetailsInput>;
  /** Customized messages that are displayed to users of this company. */
  messages?: Maybe<CompanyMessagesInput>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: Maybe<Scalars['JSONObject']>;
  /** [DEPRECATED] Use the listCompanyGroups operation instead */
  vendorGroups?: Maybe<Array<Maybe<IdNameInput>>>;
  /** A set of features that are enabled for this distributor. */
  features?: Maybe<Scalars['JSONObject']>;
  /** A list of maps sets that are available to this distributor */
  availableMapSets?: Maybe<Array<Maybe<IdNameInput>>>;
};

export type DomainInfo = {
  __typename?: 'DomainInfo';
  /** The domain name */
  domain: Scalars['String'];
  /** The company that owns the domain name. Will be `undefined` if no domain was matched. */
  owner?: Maybe<IdNameType>;
  /** Authentication information for this domain */
  auth: AuthInfo;
  /** An optional messge to display to the user at logon time */
  message?: Maybe<Scalars['String']>;
};

export type DomainThemeResponse = {
  __typename?: 'DomainThemeResponse';
  /** The domain details */
  domain: DomainInfo;
  /** The theme for this domain */
  theme: ThemeResponse;
};

export type EmailConfig = {
  __typename?: 'EmailConfig';
  /** The default email template to use for all of configured email types (can be
   * overridden individually). If an empty GUID is passed the system default will be used.
   */
  defaultTemplate?: Maybe<IdName>;
  /** A number of email template customizations */
  templates?: Maybe<Scalars['JSONObject']>;
};

export type EmailConfigInput = {
  /** The default email template to use for all of configured email types (can be
   * overridden individually). If an empty GUID is passed the system default will be used.
   */
  defaultTemplate?: Maybe<IdNameInput>;
  /** A number of email template customizations */
  templates?: Maybe<Scalars['JSONObject']>;
};

export type EmailProviderCreateRequestInput = {
  /** The name of the email provider */
  name?: Maybe<Scalars['String']>;
  /** The type of the email provider */
  type?: Maybe<IdNameInput>;
  /** The state of the email provider */
  state?: Maybe<Scalars['String']>;
  /** A dictionary of entity specific parameters */
  parameters?: Maybe<Scalars['JSONObject']>;
  /** The client that owns this email provider */
  ownerId: Scalars['String'];
};

export type EmailProviderListItem = {
  __typename?: 'EmailProviderListItem';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The name of the email provider */
  name?: Maybe<Scalars['String']>;
  /** The type of the email provider */
  type?: Maybe<IdName>;
  /** The state of the email provider */
  state?: Maybe<Scalars['String']>;
  /** The date the entity was last modified */
  modifiedDate: Scalars['String'];
};

/** A list of email provider items */
export type EmailProviderListResponse = {
  __typename?: 'EmailProviderListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<EmailProviderListItem>>;
};

export type EmailProviderResponse = {
  __typename?: 'EmailProviderResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The name of the email provider */
  name?: Maybe<Scalars['String']>;
  /** The type of the email provider */
  type?: Maybe<IdName>;
  /** The state of the email provider */
  state?: Maybe<Scalars['String']>;
  /** A dictionary of entity specific parameters */
  parameters?: Maybe<Scalars['JSONObject']>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type EmailProviderTypeListItem = {
  __typename?: 'EmailProviderTypeListItem';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The name of the email provider type */
  name: Scalars['String'];
  /** The tag to apply to the email provider type */
  tag: Scalars['String'];
  /** The parameters used by the email type */
  parameterDefinition?: Maybe<Scalars['String']>;
  /** The state of the email provider type */
  state?: Maybe<Scalars['String']>;
  /** The date the entity was last modified */
  modifiedDate: Scalars['String'];
};

/** A list of email provider type items */
export type EmailProviderTypeListResponse = {
  __typename?: 'EmailProviderTypeListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<EmailProviderTypeListItem>>;
};

export type EmailProviderTypeResponse = {
  __typename?: 'EmailProviderTypeResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The name of the email provider type */
  name: Scalars['String'];
  /** The tag to apply to the email provider type */
  tag: Scalars['String'];
  /** The parameters used by the email type */
  parameterDefinition?: Maybe<Scalars['String']>;
  /** The state of the email provider type */
  state?: Maybe<Scalars['String']>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type EmailProviderUpdateRequestInput = {
  /** The name of the email provider */
  name?: Maybe<Scalars['String']>;
  /** The type of the email provider */
  type?: Maybe<IdNameInput>;
  /** The state of the email provider */
  state?: Maybe<Scalars['String']>;
  /** A dictionary of entity specific parameters */
  parameters?: Maybe<Scalars['JSONObject']>;
};

export type EmailServerConfiguration = {
  __typename?: 'EmailServerConfiguration';
  smtpEnabled?: Maybe<Scalars['Boolean']>;
  smtpServer?: Maybe<Scalars['String']>;
  smtpPort?: Maybe<Scalars['Float']>;
  fromAddress?: Maybe<Scalars['String']>;
  fromName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  enableSsl?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
};

export type EmailServerConfigurationInput = {
  smtpEnabled?: Maybe<Scalars['Boolean']>;
  smtpServer?: Maybe<Scalars['String']>;
  smtpPort?: Maybe<Scalars['Float']>;
  fromAddress?: Maybe<Scalars['String']>;
  fromName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  enableSsl?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
};

export type EntityInfo = {
  __typename?: 'EntityInfo';
  /** The ISO date this company was created */
  creationDate: Scalars['String'];
  /** The ISO date that this company was last modified */
  modifiedDate: Scalars['String'];
};

export type EntityInfoInput = {
  /** The ISO date this company was created */
  creationDate: Scalars['String'];
  /** The ISO date that this company was last modified */
  modifiedDate: Scalars['String'];
};

/** A list of search results */
export type EntitySearchResponse = {
  __typename?: 'EntitySearchResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<EntitySearchResult>>;
};

/** An entity search result item */
export type EntitySearchResult = {
  __typename?: 'EntitySearchResult';
  /** The UUID of the entity */
  id: Scalars['String'];
  /** The name value of the entity */
  name: Scalars['String'];
  /** The type of the entitity */
  type: Scalars['String'];
  /** The company that owns the entity */
  owner: IdNameType;
  /** The state of the entity */
  state: Scalars['String'];
  /** Any custom field values assigned to the entity */
  fields: Scalars['String'];
  /** Any custom tags assigned to the entity */
  tags: Scalars['String'];
};

export type ErrorBase = {
  __typename?: 'ErrorBase';
  message: Scalars['String'];
};

export type ErrorBaseInput = {
  message: Scalars['String'];
};

export type EventActionFilter = {
  __typename?: 'EventActionFilter';
  eventClass: Scalars['String'];
  eventType?: Maybe<Scalars['String']>;
  targetType?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};

export type EventActionFilterInput = {
  eventClass: Scalars['String'];
  eventType?: Maybe<Scalars['String']>;
  targetType?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};

export type EventActorFilter = {
  __typename?: 'EventActorFilter';
  actorType: Scalars['String'];
  actorTypeId: Scalars['String'];
  actorTypeName: Scalars['String'];
  actorSelectionType: Scalars['String'];
  actorId: Scalars['String'];
  actorName?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};

export type EventActorFilterInput = {
  actorType: Scalars['String'];
  actorTypeId: Scalars['String'];
  actorTypeName: Scalars['String'];
  actorSelectionType: Scalars['String'];
  actorId: Scalars['String'];
  actorName?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};

export type EventComment = {
  __typename?: 'EventComment';
  /** The UTC date that the comment was created */
  date: Scalars['String'];
  /** The user that created the comment */
  user: IdName;
  /** The comment text */
  comment: Scalars['String'];
  /** Values populated from custom field controls */
  values?: Maybe<Scalars['JSONObject']>;
};

export type EventCommentRequestInput = {
  /** The comment text */
  comment?: Maybe<Scalars['String']>;
  values?: Maybe<Scalars['JSONObject']>;
};

/** A list of trip items */
export type EventFeedResponse = {
  __typename?: 'EventFeedResponse';
  /** sequence for the feed */
  sequence: Scalars['Float'];
  /** number of items in the feed */
  count: Scalars['Float'];
  items: Array<Maybe<EventResponse>>;
};

export type EventFilter = {
  __typename?: 'EventFilter';
  actor?: Maybe<EventActorFilter>;
  action?: Maybe<EventActionFilter>;
  target?: Maybe<EventTargetFilter>;
  conditions?: Maybe<Array<Maybe<EventFilterCondition>>>;
};

export type EventFilterCondition = {
  __typename?: 'EventFilterCondition';
  type: Scalars['String'];
  values: Scalars['JSONObject'];
  text: Scalars['JSONObject'];
};

export type EventFilterConditionInput = {
  type: Scalars['String'];
  values: Scalars['JSONObject'];
  text: Scalars['JSONObject'];
};

export type EventFilterInput = {
  actor?: Maybe<EventActorFilterInput>;
  action?: Maybe<EventActionFilterInput>;
  target?: Maybe<EventTargetFilterInput>;
  conditions?: Maybe<Array<Maybe<EventFilterConditionInput>>>;
};

export type EventHandled = {
  __typename?: 'EventHandled';
  /** The UTC date that the event was handled */
  date: Scalars['String'];
  /** The user that handled the event */
  user: IdName;
};

/** A list of event items */
export type EventListResponse = {
  __typename?: 'EventListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  /** ISO start date of the request */
  start: Scalars['String'];
  /** ISO end date of the request */
  end: Scalars['String'];
  /** Event items */
  items: Array<Maybe<EventResponse>>;
};

export type EventMedia = {
  __typename?: 'EventMedia';
  input: Scalars['String'];
  date: Scalars['String'];
  user?: Maybe<IdName>;
  mediaType: Scalars['String'];
  filename: Scalars['String'];
  error?: Maybe<Scalars['String']>;
};

export type EventResponse = {
  __typename?: 'EventResponse';
  /** The unique ID for the event */
  id: Scalars['String'];
  /** The owner company of the event */
  owner: IdNameType;
  /** The originator of the event */
  origin: IdNameType;
  /** Assets and devices that are linked to this event */
  linked: Array<Maybe<IdNameType>>;
  /** Any alerts this event may have triggered */
  alerts: Array<Maybe<IdNameType>>;
  /** Users that were notified of this event */
  notify: Array<Maybe<IdName>>;
  /** Media that's linked to this event */
  media: Array<Maybe<EventMedia>>;
  /** The UTC date of the event as recorded by the device that triggered it */
  eventDate: Scalars['String'];
  /** The UTC date when the evetn was created in the system */
  creationDate: Scalars['String'];
  /** The UTC date when this event was last modified */
  modifiedDate: Scalars['String'];
  /** The class of the event */
  eventClass: Scalars['String'];
  /** The type of event in the given class */
  eventType: Scalars['String'];
  /** Populated if the event/alert has been handled by a user */
  handledBy?: Maybe<EventHandled>;
  /** List of comments that users have left on this event */
  comments: Array<Maybe<EventComment>>;
  /** An event class specific bag of details relating to this event */
  details: Scalars['JSONObject'];
};

export type EventTargetFilter = {
  __typename?: 'EventTargetFilter';
  targetType?: Maybe<Scalars['String']>;
  targetTypeId?: Maybe<Scalars['String']>;
  targetTypeName?: Maybe<Scalars['String']>;
  targetSelectionType: Scalars['String'];
  targetId?: Maybe<Scalars['String']>;
  targetName?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type EventTargetFilterInput = {
  targetType?: Maybe<Scalars['String']>;
  targetTypeId?: Maybe<Scalars['String']>;
  targetTypeName?: Maybe<Scalars['String']>;
  targetSelectionType: Scalars['String'];
  targetId?: Maybe<Scalars['String']>;
  targetName?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type ExportTaskCreateRequestInput = {
  /** The client to export data from. In the case of a Firehose export task, this will be the Vendor and not a client. */
  client?: Maybe<IdNameInput>;
  /** The name of the export task */
  name?: Maybe<Scalars['String']>;
  /** The state of the export task */
  state?: Maybe<Scalars['String']>;
  /** The delivery method of the export tasks */
  deliveryMethod?: Maybe<Scalars['String']>;
  /** The filters used to get the assets to be included in the export task */
  assets?: Maybe<Array<Maybe<EventActorFilterInput>>>;
  /** The document types to include in the export task */
  documentTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The document layout version */
  documentVersion?: Maybe<Scalars['String']>;
  /** A set of delivery method specific options */
  settings?: Maybe<Scalars['JSONObject']>;
  /** The client or vendor that owns this entity */
  ownerId: Scalars['String'];
};

export type ExportTaskListItem = {
  __typename?: 'ExportTaskListItem';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The client to export data from. In the case of a Firehose export task, this will be the Vendor and not a client. */
  client?: Maybe<IdName>;
  /** The name of the export task */
  name?: Maybe<Scalars['String']>;
  /** The state of the export task */
  state?: Maybe<Scalars['String']>;
  /** The delivery method of the export tasks */
  deliveryMethod?: Maybe<Scalars['String']>;
  /** The filters used to get the assets to be included in the export task */
  assets?: Maybe<Array<Maybe<EventActorFilter>>>;
  /** The document types to include in the export task */
  documentTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The document layout version */
  documentVersion?: Maybe<Scalars['String']>;
  /** A set of delivery method specific options */
  settings?: Maybe<Scalars['JSONObject']>;
  /** Export task username */
  username?: Maybe<Scalars['String']>;
  /** The date the entity was last modified */
  modifiedDate: Scalars['String'];
};

/** A list of I/O type items */
export type ExportTaskListResponse = {
  __typename?: 'ExportTaskListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<ExportTaskListItem>>;
};

export type ExportTaskResponse = {
  __typename?: 'ExportTaskResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The client to export data from. In the case of a Firehose export task, this will be the Vendor and not a client. */
  client?: Maybe<IdName>;
  /** The name of the export task */
  name?: Maybe<Scalars['String']>;
  /** The state of the export task */
  state?: Maybe<Scalars['String']>;
  /** The delivery method of the export tasks */
  deliveryMethod?: Maybe<Scalars['String']>;
  /** The filters used to get the assets to be included in the export task */
  assets?: Maybe<Array<Maybe<EventActorFilter>>>;
  /** The document types to include in the export task */
  documentTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The document layout version */
  documentVersion?: Maybe<Scalars['String']>;
  /** A set of delivery method specific options */
  settings?: Maybe<Scalars['JSONObject']>;
  /** The connection endpoint for the export task */
  endpoint?: Maybe<Scalars['String']>;
  /** Export task username */
  username?: Maybe<Scalars['String']>;
  /** Export task password */
  password?: Maybe<Scalars['String']>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type ExportTaskUpdateRequestInput = {
  /** The client to export data from. In the case of a Firehose export task, this will be the Vendor and not a client. */
  client?: Maybe<IdNameInput>;
  /** The name of the export task */
  name?: Maybe<Scalars['String']>;
  /** The state of the export task */
  state?: Maybe<Scalars['String']>;
  /** The delivery method of the export tasks */
  deliveryMethod?: Maybe<Scalars['String']>;
  /** The filters used to get the assets to be included in the export task */
  assets?: Maybe<Array<Maybe<EventActorFilterInput>>>;
  /** The document types to include in the export task */
  documentTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The document layout version */
  documentVersion?: Maybe<Scalars['String']>;
  /** A set of delivery method specific options */
  settings?: Maybe<Scalars['JSONObject']>;
};

/** A list of search results */
export type ForwardGeocodeResponse = {
  __typename?: 'ForwardGeocodeResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<ForwardGeocodeResult>>;
};

/** A forward geocode search result item */
export type ForwardGeocodeResult = {
  __typename?: 'ForwardGeocodeResult';
  type: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  lon: Scalars['Float'];
  lat: Scalars['Float'];
  bounds?: Maybe<GeocoderBounds>;
  address?: Maybe<GeocoderAddress>;
};

export type GeocoderAddress = {
  __typename?: 'GeocoderAddress';
  country?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  road?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
};

export type GeocoderBounds = {
  __typename?: 'GeocoderBounds';
  top: Scalars['Float'];
  left: Scalars['Float'];
  bottom: Scalars['Float'];
  right: Scalars['Float'];
};

/** A GeoJSON structure */
export type GeoJson = {
  __typename?: 'GeoJson';
  /** The type of GeoJSON geometry */
  type: Scalars['String'];
  /** The coordinates of the geometry */
  coordinates: Array<Maybe<Array<Maybe<Scalars['Float']>>>>;
};

export type GetAssetAvatar = {
  __typename?: 'getAssetAvatar';
  /** default field */
  empty?: Maybe<Scalars['String']>;
};

export type GetClientLogo = {
  __typename?: 'getClientLogo';
  /** default field */
  empty?: Maybe<Scalars['String']>;
};

export type GetCompanyLogo = {
  __typename?: 'getCompanyLogo';
  /** default field */
  empty?: Maybe<Scalars['String']>;
};

export type GetCompletedReportData = {
  __typename?: 'getCompletedReportData';
  /** default field */
  empty?: Maybe<Scalars['String']>;
};

export type GetDistributorLogo = {
  __typename?: 'getDistributorLogo';
  /** default field */
  empty?: Maybe<Scalars['String']>;
};

export type GetUserAvatar = {
  __typename?: 'getUserAvatar';
  /** default field */
  empty?: Maybe<Scalars['String']>;
};

export type GetVendorLogo = {
  __typename?: 'getVendorLogo';
  /** default field */
  empty?: Maybe<Scalars['String']>;
};

/** Health issue date information */
export type HealthIssueDates = {
  __typename?: 'HealthIssueDates';
  /** The date that the issue was first experienced */
  created: Scalars['String'];
  /** The date that the issue was last experienced */
  updated: Scalars['String'];
};

/** A list of health issue items */
export type HealthIssueListResponse = {
  __typename?: 'HealthIssueListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  /** The list of issue items in this response */
  items: Array<Maybe<HealthIssueResponse>>;
};

/** Detail to describe the health issue's resolution */
export type HealthIssueResolutionDetail = {
  __typename?: 'HealthIssueResolutionDetail';
  /** A message to describe what was done to fix the issue */
  message?: Maybe<Scalars['String']>;
  /** The timestamp when the issue was resolved */
  timestamp: Scalars['String'];
  /** The user who resolved the issue */
  user: IdNameType;
};

/** Health issue */
export type HealthIssueResponse = {
  __typename?: 'HealthIssueResponse';
  /** A unique GUID for the issue */
  id: Scalars['String'];
  /** The company that owns this issue */
  owner: IdNameType;
  /** The target entity to which the issue relates */
  target: IdNameType;
  /** A name given to the issue */
  topic: Scalars['String'];
  /** The source entity from which the issue originates */
  source: IdNameType;
  /** Date information */
  date: HealthIssueDates;
  /** A number of times the issue has been experienced since it was first experienced */
  count: Scalars['Float'];
  /** The flag indicating the issue has been corrected or not */
  resolved?: Maybe<HealthIssueResolutionDetail>;
};

/** The model to send when resolving a health issue */
export type HealthIssueUpdateRequestInput = {
  resolved?: Maybe<HealthIssueUpdateRequestInput_ResolvedInput>;
};

export type HealthIssueUpdateRequestInput_ResolvedInput = {
  message?: Maybe<Scalars['String']>;
};

export type IdName = {
  __typename?: 'IdName';
  /** The unique ID of the object */
  id: Scalars['String'];
  /** The name of the object (can be undefined if an error occurs) */
  name?: Maybe<Scalars['String']>;
  /** Will be populated if an error occurs retrieving the name for the object. */
  err?: Maybe<ErrorBase>;
};

export type IdNameInput = {
  /** The unique ID of the object */
  id: Scalars['String'];
  /** The name of the object (can be undefined if an error occurs) */
  name?: Maybe<Scalars['String']>;
  /** Will be populated if an error occurs retrieving the name for the object. */
  err?: Maybe<ErrorBaseInput>;
};

export type IdNameType = {
  __typename?: 'IdNameType';
  /** The unique ID of the object */
  id: Scalars['String'];
  /** The name of the object (can be undefined if an error occurs) */
  name?: Maybe<Scalars['String']>;
  /** The type of the object (can be undefined if an error occurs) */
  type?: Maybe<Scalars['String']>;
  /** Will be populated if an error occurs retrieving the name for the object. */
  err?: Maybe<ErrorBase>;
};

export type IdNameTypeInput = {
  /** The unique ID of the object */
  id: Scalars['String'];
  /** The name of the object (can be undefined if an error occurs) */
  name?: Maybe<Scalars['String']>;
  /** The type of the object (can be undefined if an error occurs) */
  type?: Maybe<Scalars['String']>;
  /** Will be populated if an error occurs retrieving the name for the object. */
  err?: Maybe<ErrorBaseInput>;
};

export type IoTypeCreateRequestInput = {
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']>;
  /** The type of the IO type */
  type?: Maybe<Scalars['String']>;
  /** Optional units that this I/O type is measured in */
  unit?: Maybe<Scalars['String']>;
  /** The type of smoothing to apply to this input */
  smoothingType?: Maybe<Scalars['String']>;
  /** Digital types can have their value substituted with the following text */
  text?: Maybe<IoTypeTextConfigInput>;
  /** An optional rate conversion to do on this value */
  rate?: Maybe<IoTypeRateConfigInput>;
  /** A dictionary of lookup values. Only relevant for "value_input" types. */
  lookups?: Maybe<Array<Maybe<IdNameInput>>>;
  /** The client or vendor that owns this entity */
  ownerId: Scalars['String'];
};

export type IoTypeListItem = {
  __typename?: 'IoTypeListItem';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']>;
  /** The type of the IO type */
  type?: Maybe<Scalars['String']>;
  /** Optional units that this I/O type is measured in */
  unit?: Maybe<Scalars['String']>;
  /** The type of smoothing to apply to this input */
  smoothingType?: Maybe<Scalars['String']>;
  /** Digital types can have their value substituted with the following text */
  text?: Maybe<IoTypeTextConfig>;
  /** An optional rate conversion to do on this value */
  rate?: Maybe<IoTypeRateConfig>;
  /** A dictionary of lookup values. Only relevant for "value_input" types. */
  lookups?: Maybe<Array<Maybe<IdName>>>;
  /** The date the entity was last modified */
  modifiedDate: Scalars['String'];
};

/** A list of I/O type items */
export type IoTypeListResponse = {
  __typename?: 'IoTypeListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<IoTypeListItem>>;
};

export type IoTypeRateConfig = {
  __typename?: 'IoTypeRateConfig';
  /** The type of rate conversion to do */
  type?: Maybe<Scalars['String']>;
  /** A multiplier for the value */
  multiplier?: Maybe<Scalars['Float']>;
  /** The unit once the rate conversion is done */
  unit?: Maybe<Scalars['String']>;
};

export type IoTypeRateConfigInput = {
  /** The type of rate conversion to do */
  type?: Maybe<Scalars['String']>;
  /** A multiplier for the value */
  multiplier?: Maybe<Scalars['Float']>;
  /** The unit once the rate conversion is done */
  unit?: Maybe<Scalars['String']>;
};

export type IoTypeResponse = {
  __typename?: 'IoTypeResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']>;
  /** The type of the IO type */
  type?: Maybe<Scalars['String']>;
  /** Optional units that this I/O type is measured in */
  unit?: Maybe<Scalars['String']>;
  /** The type of smoothing to apply to this input */
  smoothingType?: Maybe<Scalars['String']>;
  /** Digital types can have their value substituted with the following text */
  text?: Maybe<IoTypeTextConfig>;
  /** An optional rate conversion to do on this value */
  rate?: Maybe<IoTypeRateConfig>;
  /** A dictionary of lookup values. Only relevant for "value_input" types. */
  lookups?: Maybe<Array<Maybe<IdName>>>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type IoTypeTextConfig = {
  __typename?: 'IoTypeTextConfig';
  /** Text that can be substituted for the value when the io type is active */
  active?: Maybe<Scalars['String']>;
  /** Text that can be substituted for the value when the io type is inactive */
  inactive?: Maybe<Scalars['String']>;
};

export type IoTypeTextConfigInput = {
  /** Text that can be substituted for the value when the io type is active */
  active?: Maybe<Scalars['String']>;
  /** Text that can be substituted for the value when the io type is inactive */
  inactive?: Maybe<Scalars['String']>;
};

export type IoTypeUpdateRequestInput = {
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']>;
  /** The type of the IO type */
  type?: Maybe<Scalars['String']>;
  /** Optional units that this I/O type is measured in */
  unit?: Maybe<Scalars['String']>;
  /** The type of smoothing to apply to this input */
  smoothingType?: Maybe<Scalars['String']>;
  /** Digital types can have their value substituted with the following text */
  text?: Maybe<IoTypeTextConfigInput>;
  /** An optional rate conversion to do on this value */
  rate?: Maybe<IoTypeRateConfigInput>;
  /** A dictionary of lookup values. Only relevant for "value_input" types. */
  lookups?: Maybe<Array<Maybe<IdNameInput>>>;
};

/** A complete route result */
export type MapRoute = {
  __typename?: 'MapRoute';
  /** The strategy that was used to calculate the route */
  strategy: Scalars['String'];
  /** One or more segments in the route */
  segments: Array<Maybe<MapRouteSegment>>;
};

/** A list of routing results */
export type MapRouteResponse = {
  __typename?: 'MapRouteResponse';
  /** A list of possible routes for the given coordinates */
  routes: Array<Maybe<MapRoute>>;
};

/** A route segment */
export type MapRouteSegment = {
  __typename?: 'MapRouteSegment';
  /** The total distance of the segment, in kilometers */
  distance: Scalars['Float'];
  /** The total travel time of the segment, in seconds */
  time: Scalars['Float'];
  /** The GeoJSON structure that describes the geometry of the section */
  geom: GeoJson;
};

export type MapsetCreateRequestInput = {
  /** A unique name for this map set */
  name?: Maybe<Scalars['String']>;
  /** One or more layers available in this map set */
  layers?: Maybe<Array<Maybe<MapSetLayerInput>>>;
  /** The state of this map set */
  state?: Maybe<Scalars['String']>;
  /** One or more overlays for this map set */
  overlays?: Maybe<Array<Maybe<MapSetLayerInput>>>;
  /** The url to use for static maps when this map set is selected */
  staticMapURL?: Maybe<Scalars['String']>;
  /** The client that owns this map set */
  ownerId: Scalars['String'];
};

export type MapSetLayer = {
  __typename?: 'MapSetLayer';
  /** The name of the map layer */
  name: Scalars['String'];
  /** The projection of the map */
  projection: Scalars['String'];
  /** The minimum zoom level supported by this map layer */
  minZoom: Scalars['Float'];
  /** The maximum zoom level supported by this map layer */
  maxZoom: Scalars['Float'];
  /** The map server type */
  serverType: Scalars['String'];
  /** The order of the layer amongst it's peers */
  order: Scalars['Float'];
  /** The image type of the map tiles */
  imageType: Scalars['String'];
  /** The name of the layer to present to the map server */
  layerName: Scalars['String'];
  /** A list of map server urls */
  servers: Array<Maybe<Scalars['String']>>;
  /** An optional list of layers to overlay on this layer */
  overlays: Array<Maybe<MapSetLayer>>;
  /** The source attribution text to overlay on this map layer */
  attribution?: Maybe<Scalars['String']>;
};

export type MapSetLayerInput = {
  /** The name of the map layer */
  name: Scalars['String'];
  /** The projection of the map */
  projection: Scalars['String'];
  /** The minimum zoom level supported by this map layer */
  minZoom: Scalars['Float'];
  /** The maximum zoom level supported by this map layer */
  maxZoom: Scalars['Float'];
  /** The map server type */
  serverType: Scalars['String'];
  /** The order of the layer amongst it's peers */
  order: Scalars['Float'];
  /** The image type of the map tiles */
  imageType: Scalars['String'];
  /** The name of the layer to present to the map server */
  layerName: Scalars['String'];
  /** A list of map server urls */
  servers: Array<Maybe<Scalars['String']>>;
  /** An optional list of layers to overlay on this layer */
  overlays: Array<Maybe<MapSetLayerInput>>;
  /** The source attribution text to overlay on this map layer */
  attribution?: Maybe<Scalars['String']>;
};

export type MapSetListItem = {
  __typename?: 'MapSetListItem';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A unique name for this map set */
  name?: Maybe<Scalars['String']>;
  /** One or more layers available in this map set */
  layers?: Maybe<Array<Maybe<MapSetLayer>>>;
  /** The state of this map set */
  state?: Maybe<Scalars['String']>;
  /** One or more overlays for this map set */
  overlays?: Maybe<Array<Maybe<MapSetLayer>>>;
  /** The url to use for static maps when this map set is selected */
  staticMapURL?: Maybe<Scalars['String']>;
  /** The date the entity was last modified */
  modifiedDate: Scalars['String'];
};

/** A list of map set items */
export type MapSetListResponse = {
  __typename?: 'MapSetListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<MapSetListItem>>;
};

export type MapSetResponse = {
  __typename?: 'MapSetResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A unique name for this map set */
  name?: Maybe<Scalars['String']>;
  /** One or more layers available in this map set */
  layers?: Maybe<Array<Maybe<MapSetLayer>>>;
  /** The state of this map set */
  state?: Maybe<Scalars['String']>;
  /** One or more overlays for this map set */
  overlays?: Maybe<Array<Maybe<MapSetLayer>>>;
  /** The url to use for static maps when this map set is selected */
  staticMapURL?: Maybe<Scalars['String']>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type MapsetUpdateRequestInput = {
  /** A unique name for this map set */
  name?: Maybe<Scalars['String']>;
  /** One or more layers available in this map set */
  layers?: Maybe<Array<Maybe<MapSetLayerInput>>>;
  /** The state of this map set */
  state?: Maybe<Scalars['String']>;
  /** One or more overlays for this map set */
  overlays?: Maybe<Array<Maybe<MapSetLayerInput>>>;
  /** The url to use for static maps when this map set is selected */
  staticMapURL?: Maybe<Scalars['String']>;
};

export type MeasureDescription = {
  __typename?: 'MeasureDescription';
  /** The key of the measure */
  key: Scalars['String'];
  /** The name of the measure */
  value: Scalars['String'];
  /** The formatting type of  the measure. Durations are hh:mm:ss. Numbers can be
   * postive or negative values to 2 decimal places,
   * integers can be postive or negative; and percent is an integer between 0 and 100
   */
  format?: Maybe<Scalars['String']>;
  /** Whether or not the measure is generic. This is useful to indicate that the measure is specific to a client
   * and should not be used in dashboard templates
   */
  generic?: Maybe<Scalars['Boolean']>;
  /** The date YYYY/MM/DD HH:mm:ss (UTC) from which the measure is valid */
  validFrom?: Maybe<Scalars['String']>;
  /** The date YYYY/MM/DD HH:mm:ss (UTC) to which the measure is valid */
  validTo?: Maybe<Scalars['String']>;
};

export type MeasureDescriptionInput = {
  /** The key of the measure */
  key: Scalars['String'];
  /** The name of the measure */
  value: Scalars['String'];
  /** The formatting type of  the measure. Durations are hh:mm:ss. Numbers can be
   * postive or negative values to 2 decimal places,
   * integers can be postive or negative; and percent is an integer between 0 and 100
   */
  format?: Maybe<Scalars['String']>;
  /** Whether or not the measure is generic. This is useful to indicate that the measure is specific to a client
   * and should not be used in dashboard templates
   */
  generic?: Maybe<Scalars['Boolean']>;
  /** The date YYYY/MM/DD HH:mm:ss (UTC) from which the measure is valid */
  validFrom?: Maybe<Scalars['String']>;
  /** The date YYYY/MM/DD HH:mm:ss (UTC) to which the measure is valid */
  validTo?: Maybe<Scalars['String']>;
};

/** A list of media items */
export type MediaInfoListResponse = {
  __typename?: 'MediaInfoListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  /** A list of media items */
  items: Array<Maybe<MediaInfoResponse>>;
};

/** Information about a media item */
export type MediaInfoResponse = {
  __typename?: 'MediaInfoResponse';
  /** The internal ID of the media item */
  id: Scalars['String'];
  /** The company that owns this media item */
  owner: IdName;
  /** The asset that generated this media item */
  asset: IdName;
  /** The entity that requested this media item */
  requester: IdNameType;
  /** The MIME type of this media */
  mimeType: Scalars['String'];
  /** The filename of the media item */
  filename: Scalars['String'];
  /** The camera input from which this media was recorded */
  input: Scalars['String'];
  /** The ISO date at which this media was started */
  date: Scalars['String'];
  /** The duration of the media item */
  duration: Scalars['Float'];
  /** The status of the media item */
  status: Scalars['String'];
  /** The progress of media retrieval (not all devices support this property) */
  progress: Scalars['Float'];
  /** Information about when this media item was created and last updated */
  entity: EntityInfo;
  /** Device specific information about this media item */
  data?: Maybe<Scalars['JSONObject']>;
};

/** Setting for creating a new video event */
export type MediaVideoEventCreateRequestInput = {
  /** The duration in seconds of the video to retrieve */
  duration: Scalars['Float'];
  /** The number of seconds from the given date to begin recording (can be negative) */
  offset: Scalars['Float'];
  /** The camera inputs from which to retrieve videos */
  inputs: Array<Maybe<Scalars['String']>>;
  /** the UUID of the asset for which to create this event */
  assetId: Scalars['String'];
  /** The date on which to create this event. This should correspond to an actual telemetry point for the asset. */
  date: Scalars['String'];
};

/** Setting for retreiving a video on an existing event */
export type MediaVideoEventUpdateRequestInput = {
  /** The duration in seconds of the video to retrieve */
  duration: Scalars['Float'];
  /** The number of seconds from the given date to begin recording (can be negative) */
  offset: Scalars['Float'];
  /** The camera inputs from which to retrieve videos */
  inputs: Array<Maybe<Scalars['String']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Creates a new client entity. */
  createClient: ClientResponse;
  /** Updates an existing client entity by patching the existing object with the
   * properties supplied in the `ClientUpdateRequest`.
   */
  updateClient: ClientResponse;
  /** Updates an existing client account details. Unlike updateClient, this route is available to client users. */
  updateClientDetails: ClientResponse;
  /** Updates the specified client's logo. */
  updateClientLogo: Scalars['JSONObject'];
  /** Permanently deletes a custom client logo. The logo will revert to the client's parent logo. */
  deleteClientLogo: Scalars['JSONObject'];
  /** Creates a new company group */
  createCompanyGroup: CompanyGroupResponse;
  /** Updates an existing company group */
  updateCompanyGroup: CompanyGroupResponse;
  /** Delete a company group */
  deleteCompanyGroup: CompanyGroupResponse;
  /** Creates a new distributor account */
  createDistributor: DistributorResponse;
  /** Updates an existing distributor account */
  updateDistributor: DistributorResponse;
  /** Updates an existing distributor account details. Unlike `updateDistributor`, this route is available to distributor users. */
  updateDistributorDetails: DistributorResponse;
  /** Updates the specified distributor's logo. */
  updateDistributorLogo: Scalars['JSONObject'];
  /** Permanently deletes a custom distributor logo. The logo will revert to the Key Telematics logo. */
  deleteDistributorLogo: Scalars['JSONObject'];
  /** Creates a new user */
  createUser: UserCreateResponse;
  /** Updates an existing user */
  updateUser: UserResponse;
  /** Optionally suspends the user and sends them a password reset email. */
  resetUser: UserResetResponse;
  /** Updates the specified user's avatar. */
  updateUserAvatar: Scalars['JSONObject'];
  /** Permanently deletes a user avatar. */
  deleteUserAvatar: Scalars['JSONObject'];
  /** Creates a new API key */
  createApiKey: UserApiKeyCreateResponse;
  /** Creates a new API key */
  deleteApiKey: SuccessResponse;
  /** Creates a new user role entity */
  createUserRole: UserRoleResponse;
  /** Updates an existing user role entity */
  updateUserRole: UserRoleResponse;
  /** Permanently deletes a user role */
  deleteUserRole: UserRoleResponse;
  /** Creates a new vendor account */
  createVendor: VendorResponse;
  /** Updates an existing vendor account */
  updateVendor: VendorResponse;
  /** Updates an existing vendor account details. Unlike updateVendor, this route is available to vendor users. */
  updateVendorDetails: VendorResponse;
  /** Updates the specified vendor's logo. */
  updateVendorLogo: Scalars['JSONObject'];
  /** Permanently deletes a custom vendor logo. The logo will revert to the vendor's parent logo. */
  deleteVendorLogo: Scalars['JSONObject'];
  /** Authenticate using a username and password */
  signIn: UserSessionResponse;
  /** Invalidate an active access token */
  signOut: Scalars['JSONObject'];
  /** Refreshes a user's tokens by providing a valid refresh token */
  refreshTokens: TokenResponse;
  /** Sends a password reset email to the user. */
  resetPassword: SuccessResponse;
  /** Sends a password reset email to the user. */
  setPassword: SuccessResponse;
  /** Changes the currently authenticated user's password. */
  changePassword: SuccessResponse;
  /** Retrieve an alert feed for the specified asset. */
  commentAlert: EventResponse;
  /** Retrieve an alert feed for the specified asset. */
  acknowledgeAlert: EventResponse;
  /** Creates a new Alert */
  createAlert: AlertResponse;
  /** Updates an existing Alert */
  updateAlert: AlertResponse;
  /** Creates a new asset category */
  createAssetCategory: AssetCategoryResponse;
  /** Updates an existing asset category */
  updateAssetCategory: AssetCategoryResponse;
  /** Delete an asset category */
  deleteAssetCategory: AssetCategoryResponse;
  /** Creates a new Asset Group */
  createAssetGroup: AssetGroupResponse;
  /** Updates an existing Asset Group */
  updateAssetGroup: AssetGroupResponse;
  /** Delete an asset group */
  deleteAssetGroup: AssetGroupResponse;
  /** Creates a new Asset Rating Profile entity */
  createAssetRatingProfile: AssetRatingProfileResponse;
  /** Updates an existing Asset Rating Profile entity */
  updateAssetRatingProfile: AssetRatingProfileResponse;
  /** Creates a new asset entity */
  createAsset: AssetResponse;
  /** Updates an existing asset */
  updateAsset: AssetResponse;
  /** Updates the specified asset's avatar. */
  updateAssetAvatar: Scalars['JSONObject'];
  /** Permanently deletes a asset avatar. */
  deleteAssetAvatar: Scalars['JSONObject'];
  /** Creates a new Asset State Profile entity */
  createAssetStateProfile: AssetStateProfileResponse;
  /** Updates an existing Asset State Profile entity */
  updateAssetStateProfile: AssetStateProfileResponse;
  /** Creates a new Asset Tag */
  createAssetTag: AssetTagResponse;
  /** Updates an existing Asset Tag */
  updateAssetTag: AssetTagResponse;
  /** Creates a new cost centre */
  createCostCentre: CostCentreResponse;
  /** Updates an existing cost centre */
  updateCostCentre: CostCentreResponse;
  /** Delete a cost centre */
  deleteCostCentre: CostCentreResponse;
  /** Creates a new dashboard entity */
  createDashboard: DashboardResponse;
  /** Updates an existing dashboard entity */
  updateDashboard: DashboardResponse;
  /** Permanently deletes a dashboard */
  deleteDashboard: DashboardResponse;
  /** Creates a new dashboard template */
  createDashboardTemplate: DashboardTemplateResponse;
  /** Updates an existing dashboard template entity */
  updateDashboardTemplate: DashboardTemplateResponse;
  /** Creates a new device entity */
  createDevice: DeviceResponse;
  /** Updates an existing device */
  updateDevice: DeviceResponse;
  /** Creates a new device type entity */
  createDeviceType: DeviceTypeResponse;
  /** Updates an existing device type */
  updateDeviceType: DeviceTypeResponse;
  /** Creates a new device provider entity */
  createDeviceProvider: DeviceProviderResponse;
  /** Updates an existing device provider */
  updateDeviceProvider: DeviceProviderResponse;
  /** Creates a new export task entity */
  createExportTask: ExportTaskResponse;
  /** Updates an existing export task entity */
  updateExportTask: ExportTaskResponse;
  /** Creates a new I/O type entity */
  createIoType: IoTypeResponse;
  /** Updates an existing I/O type entity */
  updateIoType: IoTypeResponse;
  /** Creates a new map set entity */
  createMapSet: MapSetResponse;
  /** Updates an existing map set entity */
  updateMapSet: MapSetResponse;
  /** Creates a new Overspeed Profile entity */
  createOverspeedProfile: OverspeedProfileResponse;
  /** Updates an existing Overspeed Profile entity */
  updateOverspeedProfile: OverspeedProfileResponse;
  /** Creates a new report template */
  createReportTemplate: ReportTemplateResponse;
  /** Updates an existing report template */
  updateReportTemplate: ReportTemplateResponse;
  /** Deletes a report template */
  deleteReportTemplate: ReportTemplateResponse;
  /** Creates a new reminder entity */
  createReminder: ReminderResponse;
  /** Updates an existing reminder entity */
  updateReminder: ReminderResponse;
  /** Deletes an existing reminder entity */
  deleteReminder: ReminderResponse;
  /** Creates a new scheduled report entity */
  createScheduledReport: ScheduledReportResponse;
  /** Updates an existing scheduled report entity */
  updateScheduledReport: ScheduledReportResponse;
  /** Creates a new SIM card */
  createSimCard: SimCardResponse;
  /** Updates an existing SIM card */
  updateSimCard: SimCardResponse;
  /** Creates a new sms gateway provider entity */
  createSmsGatewayProvider: SmsGatewayProviderResponse;
  /** Updates an existing sms gateway provider entity */
  updateSmsGatewayProvider: SmsGatewayProviderResponse;
  /** Creates a new email provider entity */
  createEmailProvider: EmailProviderResponse;
  /** Updates an existing email provider entity */
  updateEmailProvider: EmailProviderResponse;
  /** Creates a new Theme entity */
  createTheme: ThemeResponse;
  /** Updates an existing Theme entity */
  updateTheme: ThemeResponse;
  /** Creates a new Zone Group */
  createZoneGroup: ZoneGroupResponse;
  /** Updates an existing Zone Group */
  updateZoneGroup: ZoneGroupResponse;
  /** Delete a Zone Group */
  deleteZoneGroup: ZoneGroupResponse;
  /** Creates a new zone */
  createZone: ZoneResponse;
  /** Updates an existing zone */
  updateZone: ZoneResponse;
  /** Creates a new video event for a specified date time. */
  createVideoEvent: EventResponse;
  /** Request a video from a camera enabled device for a particular event. */
  updateVideoEvent: EventResponse;
  /** Sends dummy notifications to the requesting user */
  testNotification: SuccessResponse;
  /** Update a report by it's ID. */
  updateCompletedReport: CompletedReportResponse;
  /** Retrieve a queued report by it's ID. */
  getCompletedReportWithOptions: CompletedReportResponse;
  /** Retrieve the binary data for a queued report by it's ID. */
  getCompletedReportDataWithOptions: Scalars['JSONObject'];
  /** Queue a new report */
  queueReport: QueuedReportResponse;
  /** Cancel a queued or running report */
  cancelReport: QueuedReportResponse;
  /** Retrieve data for a stats report. */
  getOutputForEntity: CellSetResponse;
  /** Update a health issue for the specified id */
  updateIssue: HealthIssueResponse;
  /** Creates a new task */
  createTask: TaskResponse;
  /** Creates a new task */
  cancelTask: TaskResponse;
};

export type MutationCreateClientArgs = {
  request: ClientCreateRequestInput;
};

export type MutationUpdateClientArgs = {
  id: Scalars['String'];
  request: ClientUpdateRequestInput;
};

export type MutationUpdateClientDetailsArgs = {
  id: Scalars['String'];
  request: ClientDetailsUpdateRequestInput;
};

export type MutationUpdateClientLogoArgs = {
  id: Scalars['String'];
  size: Scalars['String'];
};

export type MutationDeleteClientLogoArgs = {
  id: Scalars['String'];
  size: Scalars['String'];
};

export type MutationCreateCompanyGroupArgs = {
  request: CompanyGroupCreateRequestInput;
};

export type MutationUpdateCompanyGroupArgs = {
  id: Scalars['String'];
  request: CompanyGroupUpdateRequestInput;
};

export type MutationDeleteCompanyGroupArgs = {
  id: Scalars['String'];
};

export type MutationCreateDistributorArgs = {
  request: DistributorCreateRequestInput;
};

export type MutationUpdateDistributorArgs = {
  id: Scalars['String'];
  request: DistributorUpdateRequestInput;
};

export type MutationUpdateDistributorDetailsArgs = {
  id: Scalars['String'];
  request: DistributorDetailsUpdateRequestInput;
};

export type MutationUpdateDistributorLogoArgs = {
  id: Scalars['String'];
  size: Scalars['String'];
};

export type MutationDeleteDistributorLogoArgs = {
  id: Scalars['String'];
  size: Scalars['String'];
};

export type MutationCreateUserArgs = {
  request: UserCreateRequestInput;
};

export type MutationUpdateUserArgs = {
  id: Scalars['String'];
  request: UserUpdateRequestInput;
};

export type MutationResetUserArgs = {
  id: Scalars['String'];
  request: UserResetRequestInput;
};

export type MutationUpdateUserAvatarArgs = {
  id: Scalars['String'];
};

export type MutationDeleteUserAvatarArgs = {
  id: Scalars['String'];
};

export type MutationCreateApiKeyArgs = {
  id: Scalars['String'];
  request: UserApiKeyCreateRequestInput;
};

export type MutationDeleteApiKeyArgs = {
  id: Scalars['String'];
  keyid: Scalars['String'];
};

export type MutationCreateUserRoleArgs = {
  request: UserRoleCreateRequestInput;
};

export type MutationUpdateUserRoleArgs = {
  id: Scalars['String'];
  request: UserRoleUpdateRequestInput;
};

export type MutationDeleteUserRoleArgs = {
  id: Scalars['String'];
};

export type MutationCreateVendorArgs = {
  request: VendorCreateRequestInput;
};

export type MutationUpdateVendorArgs = {
  id: Scalars['String'];
  request: VendorUpdateRequestInput;
};

export type MutationUpdateVendorDetailsArgs = {
  id: Scalars['String'];
  request: VendorDetailsUpdateRequestInput;
};

export type MutationUpdateVendorLogoArgs = {
  id: Scalars['String'];
  size: Scalars['String'];
};

export type MutationDeleteVendorLogoArgs = {
  id: Scalars['String'];
  size: Scalars['String'];
};

export type MutationSignInArgs = {
  request: AuthRequestInput;
};

export type MutationSignOutArgs = {
  request: SignOutRequestInput;
};

export type MutationRefreshTokensArgs = {
  request: AuthRefreshTokenRequestInput;
};

export type MutationResetPasswordArgs = {
  request: AuthResetPasswordRequestInput;
};

export type MutationSetPasswordArgs = {
  request: AuthSetPasswordRequestInput;
};

export type MutationChangePasswordArgs = {
  request: UpdateUserPasswordRequestInput;
};

export type MutationCommentAlertArgs = {
  client: Scalars['String'];
  alert: Scalars['String'];
  request: EventCommentRequestInput;
};

export type MutationAcknowledgeAlertArgs = {
  client: Scalars['String'];
  alert: Scalars['String'];
  request: EventCommentRequestInput;
};

export type MutationCreateAlertArgs = {
  request: AlertCreateRequestInput;
};

export type MutationUpdateAlertArgs = {
  id: Scalars['String'];
  request: AlertUpdateRequestInput;
};

export type MutationCreateAssetCategoryArgs = {
  request: AssetCategoryCreateRequestInput;
};

export type MutationUpdateAssetCategoryArgs = {
  id: Scalars['String'];
  request: AssetCategoryUpdateRequestInput;
};

export type MutationDeleteAssetCategoryArgs = {
  id: Scalars['String'];
};

export type MutationCreateAssetGroupArgs = {
  request: AssetGroupCreateRequestInput;
};

export type MutationUpdateAssetGroupArgs = {
  id: Scalars['String'];
  request: AssetGroupUpdateRequestInput;
};

export type MutationDeleteAssetGroupArgs = {
  id: Scalars['String'];
};

export type MutationCreateAssetRatingProfileArgs = {
  request: AssetRatingProfileCreateRequestInput;
};

export type MutationUpdateAssetRatingProfileArgs = {
  id: Scalars['String'];
  request: AssetRatingProfileUpdateRequestInput;
};

export type MutationCreateAssetArgs = {
  request: AssetCreateRequestInput;
};

export type MutationUpdateAssetArgs = {
  id: Scalars['String'];
  request: AssetUpdateRequestInput;
};

export type MutationUpdateAssetAvatarArgs = {
  id: Scalars['String'];
};

export type MutationDeleteAssetAvatarArgs = {
  id: Scalars['String'];
};

export type MutationCreateAssetStateProfileArgs = {
  request: AssetStateProfileCreateRequestInput;
};

export type MutationUpdateAssetStateProfileArgs = {
  id: Scalars['String'];
  request: AssetStateProfileUpdateRequestInput;
};

export type MutationCreateAssetTagArgs = {
  request: AssetTagCreateRequestInput;
};

export type MutationUpdateAssetTagArgs = {
  id: Scalars['String'];
  request: AssetTagUpdateRequestInput;
};

export type MutationCreateCostCentreArgs = {
  request: CostCentreCreateRequestInput;
};

export type MutationUpdateCostCentreArgs = {
  id: Scalars['String'];
  request: CostCentreUpdateRequestInput;
};

export type MutationDeleteCostCentreArgs = {
  id: Scalars['String'];
};

export type MutationCreateDashboardArgs = {
  request: DashboardCreateRequestInput;
};

export type MutationUpdateDashboardArgs = {
  id: Scalars['String'];
  request: DashboardUpdateRequestInput;
};

export type MutationDeleteDashboardArgs = {
  id: Scalars['String'];
};

export type MutationCreateDashboardTemplateArgs = {
  request: DashboardTemplateCreateRequestInput;
};

export type MutationUpdateDashboardTemplateArgs = {
  id: Scalars['String'];
  request: DashboardTemplateUpdateRequestInput;
};

export type MutationCreateDeviceArgs = {
  request: DeviceCreateRequestInput;
};

export type MutationUpdateDeviceArgs = {
  id: Scalars['String'];
  request: DeviceUpdateRequestInput;
};

export type MutationCreateDeviceTypeArgs = {
  request: DeviceTypeCreateRequestInput;
};

export type MutationUpdateDeviceTypeArgs = {
  id: Scalars['String'];
  request: DeviceTypeUpdateRequestInput;
};

export type MutationCreateDeviceProviderArgs = {
  request: DeviceProviderCreateRequestInput;
};

export type MutationUpdateDeviceProviderArgs = {
  id: Scalars['String'];
  request: DeviceProviderUpdateRequestInput;
};

export type MutationCreateExportTaskArgs = {
  request: ExportTaskCreateRequestInput;
};

export type MutationUpdateExportTaskArgs = {
  id: Scalars['String'];
  request: ExportTaskUpdateRequestInput;
};

export type MutationCreateIoTypeArgs = {
  request: IoTypeCreateRequestInput;
};

export type MutationUpdateIoTypeArgs = {
  id: Scalars['String'];
  request: IoTypeUpdateRequestInput;
};

export type MutationCreateMapSetArgs = {
  request: MapsetCreateRequestInput;
};

export type MutationUpdateMapSetArgs = {
  id: Scalars['String'];
  request: MapsetUpdateRequestInput;
};

export type MutationCreateOverspeedProfileArgs = {
  request: OverspeedProfileCreateRequestInput;
};

export type MutationUpdateOverspeedProfileArgs = {
  id: Scalars['String'];
  request: OverspeedProfileUpdateRequestInput;
};

export type MutationCreateReportTemplateArgs = {
  request: ReportTemplateCreateRequestInput;
};

export type MutationUpdateReportTemplateArgs = {
  id: Scalars['String'];
  request: ReportTemplateUpdateRequestInput;
};

export type MutationDeleteReportTemplateArgs = {
  id: Scalars['String'];
};

export type MutationCreateReminderArgs = {
  request: ReminderCreateRequestInput;
};

export type MutationUpdateReminderArgs = {
  id: Scalars['String'];
  request: ReminderUpdateRequestInput;
};

export type MutationDeleteReminderArgs = {
  id: Scalars['String'];
};

export type MutationCreateScheduledReportArgs = {
  request: ScheduledReportCreateRequestInput;
};

export type MutationUpdateScheduledReportArgs = {
  id: Scalars['String'];
  request: ScheduledReportUpdateRequestInput;
};

export type MutationCreateSimCardArgs = {
  request: SimCardCreateRequestInput;
};

export type MutationUpdateSimCardArgs = {
  id: Scalars['String'];
  request: SimCardUpdateRequestInput;
};

export type MutationCreateSmsGatewayProviderArgs = {
  request: SmsGatewayProviderCreateRequestInput;
};

export type MutationUpdateSmsGatewayProviderArgs = {
  id: Scalars['String'];
  request: SmsGatewayProviderUpdateRequestInput;
};

export type MutationCreateEmailProviderArgs = {
  request: EmailProviderCreateRequestInput;
};

export type MutationUpdateEmailProviderArgs = {
  id: Scalars['String'];
  request: EmailProviderUpdateRequestInput;
};

export type MutationCreateThemeArgs = {
  request: ThemeCreateRequestInput;
};

export type MutationUpdateThemeArgs = {
  id: Scalars['String'];
  request: ThemeUpdateRequestInput;
};

export type MutationCreateZoneGroupArgs = {
  request: ZoneGroupCreateRequestInput;
};

export type MutationUpdateZoneGroupArgs = {
  id: Scalars['String'];
  request: ZoneGroupUpdateRequestInput;
};

export type MutationDeleteZoneGroupArgs = {
  id: Scalars['String'];
};

export type MutationCreateZoneArgs = {
  request: ZoneCreateRequestInput;
};

export type MutationUpdateZoneArgs = {
  id: Scalars['String'];
  request: ZoneUpdateRequestInput;
};

export type MutationCreateVideoEventArgs = {
  request: MediaVideoEventCreateRequestInput;
};

export type MutationUpdateVideoEventArgs = {
  owner: Scalars['String'];
  event: Scalars['String'];
  request: MediaVideoEventUpdateRequestInput;
};

export type MutationTestNotificationArgs = {
  request: TestNotificationsRequestInput;
};

export type MutationUpdateCompletedReportArgs = {
  id: Scalars['String'];
  report: CompletedReportUpdateRequestInput;
};

export type MutationGetCompletedReportWithOptionsArgs = {
  id: Scalars['String'];
  options: CompletedReportRequestInput;
};

export type MutationGetCompletedReportDataWithOptionsArgs = {
  id: Scalars['String'];
  filename: Scalars['String'];
  options: CompletedReportDataRequestInput;
};

export type MutationQueueReportArgs = {
  request: QueueReportRequestInput;
};

export type MutationCancelReportArgs = {
  id: Scalars['String'];
};

export type MutationGetOutputForEntityArgs = {
  type: Scalars['String'];
  id: Scalars['String'];
  metric: Scalars['String'];
  options: StatsEntityOutputOptionsInput;
};

export type MutationUpdateIssueArgs = {
  id: Scalars['String'];
  request: HealthIssueUpdateRequestInput;
};

export type MutationCreateTaskArgs = {
  request: TaskCreateRequestInput;
};

export type MutationCancelTaskArgs = {
  id: Scalars['String'];
};

export type NotificationActions = {
  __typename?: 'NotificationActions';
  low?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  high?: Maybe<Scalars['String']>;
};

export type NotificationActionsInput = {
  low?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  high?: Maybe<Scalars['String']>;
};

/** A list of audit event items */
export type NotificationFeedResponse = {
  __typename?: 'NotificationFeedResponse';
  items: Array<Maybe<NotificationResponse>>;
};

export type NotificationResponse = {
  __typename?: 'NotificationResponse';
  /** The unique ID for the notification event */
  id: Scalars['String'];
  /** The owner company of the notification event */
  owner: IdNameType;
  /** The user that triggered the notification event */
  user: IdName;
  /** The UTC date when the notification event was recorded in the system */
  date: Scalars['String'];
  /** A sequence number that can be used to traverse the notification feed */
  sequence: Scalars['Float'];
  /** The type of event in the given class */
  eventType: Scalars['String'];
  /** The text description of the event */
  text: Scalars['String'];
  /** Event specific information */
  data: Scalars['JSONObject'];
};

export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  /** The times in which it is acceptable to send the user an sms */
  smsTime?: Maybe<TimeRange>;
  /** The actions to take for the different alert levels */
  actions?: Maybe<NotificationActions>;
};

export type NotificationSettingsInput = {
  /** The times in which it is acceptable to send the user an sms */
  smsTime?: Maybe<TimeRangeInput>;
  /** The actions to take for the different alert levels */
  actions?: Maybe<NotificationActionsInput>;
};

export type OverspeedBandItem = {
  __typename?: 'OverspeedBandItem';
  /** The unique ID of this overspeed band */
  id: Scalars['String'];
  /** A descriptive name for this overspeed band */
  name: Scalars['String'];
  /** The minimum inclusive value to match this band on */
  min: Scalars['Float'];
};

export type OverspeedBandItemInput = {
  /** The unique ID of this overspeed band */
  id: Scalars['String'];
  /** A descriptive name for this overspeed band */
  name: Scalars['String'];
  /** The minimum inclusive value to match this band on */
  min: Scalars['Float'];
};

export type OverspeedProfileCreateRequestInput = {
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']>;
  /** The overspeed band items for this profile */
  items?: Maybe<Array<Maybe<OverspeedBandItemInput>>>;
  /** The client that owns this Overspeed Profile */
  ownerId: Scalars['String'];
};

export type OverspeedProfileListItem = {
  __typename?: 'OverspeedProfileListItem';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']>;
  /** The overspeed band items for this profile */
  items?: Maybe<Array<Maybe<OverspeedBandItem>>>;
  /** The date the entity was last modified */
  modifiedDate: Scalars['String'];
};

/** A list of overspeed profile items */
export type OverspeedProfileListResponse = {
  __typename?: 'OverspeedProfileListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<OverspeedProfileListItem>>;
};

export type OverspeedProfileResponse = {
  __typename?: 'OverspeedProfileResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']>;
  /** The overspeed band items for this profile */
  items?: Maybe<Array<Maybe<OverspeedBandItem>>>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type OverspeedProfileUpdateRequestInput = {
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']>;
  /** The overspeed band items for this profile */
  items?: Maybe<Array<Maybe<OverspeedBandItemInput>>>;
};

export type PieChartOptions = {
  __typename?: 'PieChartOptions';
  label: ChartLabel;
  series: Scalars['JSONObject'];
  dataLabels?: Maybe<Scalars['Boolean']>;
  data: Scalars['String'];
  fill: Scalars['Boolean'];
};

export type PieChartOptionsInput = {
  label: ChartLabelInput;
  series: Scalars['JSONObject'];
  dataLabels?: Maybe<Scalars['Boolean']>;
  data: Scalars['String'];
  fill: Scalars['Boolean'];
};

export type Policy = {
  __typename?: 'Policy';
  /** The UUID of the policy */
  id: Scalars['String'];
  /** A name describing the policy */
  name: Scalars['String'];
  /** The statements used to create a policy */
  statements: Array<Maybe<PolicyStatement>>;
};

export type PolicyInput = {
  /** The UUID of the policy */
  id: Scalars['String'];
  /** A name describing the policy */
  name: Scalars['String'];
  /** The statements used to create a policy */
  statements: Array<Maybe<PolicyStatementInput>>;
};

export type PolicyStatement = {
  __typename?: 'PolicyStatement';
  /** An optional UUID for the policy statement */
  id?: Maybe<Scalars['String']>;
  /** The type of policy */
  effect: Scalars['String'];
  /** The actions the policy operates on */
  actions: Array<Maybe<Scalars['String']>>;
  /** The resources to which the policy applies */
  resources: Array<Maybe<Scalars['String']>>;
};

export type PolicyStatementInput = {
  /** An optional UUID for the policy statement */
  id?: Maybe<Scalars['String']>;
  /** The type of policy */
  effect: Scalars['String'];
  /** The actions the policy operates on */
  actions: Array<Maybe<Scalars['String']>>;
  /** The resources to which the policy applies */
  resources: Array<Maybe<Scalars['String']>>;
};

export type Query = {
  __typename?: 'Query';
  /** Retrieve a list of clients for a specified vendor. */
  listClients: ClientListResponse;
  /** Returns client details for the specified UUID. */
  getClient: ClientResponse;
  /** Returns the client ID and name for the specified PIN. PINs are generated by
   * the system and can be retrieved via the `pin` property on the
   * `ClientResponse` object retrieved with `getClient`.
   */
  getClientByPin: IdName;
  /** Return the specified client's logo in binary format. Should the client not
   * have a custom logo, the logo of the vendor will be supplied.
   */
  getClientLogo: GetClientLogo;
  /** Returns company details for the specified id */
  getCompany: CompanyResponse;
  /** Return the specified company's logo in binary format. */
  getCompanyLogo: GetCompanyLogo;
  /** Retrieve a list of company groups for a specified owner. */
  listCompanyGroups: CompanyGroupListResponse;
  /** Returns company group details for the specified id */
  getCompanyGroup: CompanyGroupResponse;
  /** Retrieve a list of distributors for a specified owner. */
  listDistributors: DistributorListResponse;
  /** Returns distributor details for the specified id */
  getDistributor: DistributorResponse;
  /** Return the specified distributors's logo in binary format. Should the
   * distributor not have a custom logo, the default Key Telematics logo will be supplied.
   */
  getDistributorLogo: GetDistributorLogo;
  /** Retrieve a list of users for a specified owner. */
  listUsers: UserListResponse;
  /** Returns user details for the specified id */
  getUser: UserResponse;
  /** Return the current user's avatar in binary format */
  getUserAvatar: GetUserAvatar;
  /** Retrieve a list of user roles for a specified owner. */
  listUserRoles: UserRoleListResponse;
  /** Returns export task details for the specified id */
  getUserRole: UserRoleResponse;
  /** Retrieve a list of vendors for a specified owner. */
  listVendors: VendorListResponse;
  /** Returns vendor details for the specified id */
  getVendor: VendorResponse;
  /** Return the specified vendor's logo in binary format. Should the vendor not
   * have a custom logo, the logo of the distributor will be supplied.
   */
  getVendorLogo: GetVendorLogo;
  /** Selects a user from a list that has already been authenticated. */
  selectUser: SelectedUserTokenResponse;
  /** Retrieves the currently authenticated user's profile */
  getUserProfile: UserProfileResponse;
  /** Retrieve the notification feed for the current user. */
  getNotificationFeed: NotificationFeedResponse;
  /** Retrieve the current state for all assets for the specified client. */
  getLocationFeed: TelemetryFeedResponse;
  /** Retrieve a trip feed for the specified asset. */
  getTripFeed: TripFeedResponse;
  /** Retrieve an event feed for the specified asset. */
  getEventFeed: EventFeedResponse;
  /** Retrieve an audit log feed for the specified client and entity. */
  getAuditFeedForEntity: AuditEventFeedResponse;
  /** Retrieve an alert feed for the specified asset. */
  getAlertFeed: EventFeedResponse;
  /** Retrieve telemetry records between two dates. */
  getTelemetryHistory: TelemetryListResponse;
  /** Retrieve event records between two dates. */
  getEventHistory: EventListResponse;
  /** Retrieve alert records between two dates. */
  getAlertHistory: EventListResponse;
  /** Retrieve trip records between two dates. */
  getTripHistory: TripListResponse;
  /** Retrieve a list of alerts for a specified owner. */
  listAlerts: AlertListResponse;
  /** Returns alert details for the specified id */
  getAlert: AlertResponse;
  /** Retrieve a list of asset categories for a specified owner. */
  listAssetCategories: AssetCategoryListResponse;
  /** Returns asset category details for the specified id */
  getAssetCategory: AssetCategoryResponse;
  /** Retrieve a list of asset groups for a specified owner. */
  listAssetGroups: AssetGroupListResponse;
  /** Returns asset group details for the specified id */
  getAssetGroup: AssetGroupResponse;
  /** Retrieve a list of asset rating profiles for a specified owner. */
  listAssetRatingProfiles: AssetRatingProfileListResponse;
  /** Returns asset rating profile details for the specified id */
  getAssetRatingProfile: AssetRatingProfileResponse;
  /** Retrieve a list of assets for a specified owner. */
  listAssets: AssetListResponse;
  /** Returns asset details for the specified id */
  getAsset: AssetResponse;
  /** Returns the latest known telemetry record for an asset */
  getAssetLocation: TelemetryStateResponse;
  /** Return the asset avatar in binary format */
  getAssetAvatar: GetAssetAvatar;
  /** Retrieve a list of asset state profiles for a specified owner. */
  listAssetStateProfiles: AssetStateProfileListResponse;
  /** Returns asset state profile details for the specified id */
  getAssetStateProfile: AssetStateProfileResponse;
  /** Retrieve a list of Asset Tags for a specified owner. */
  listAssetTags: AssetTagListResponse;
  /** Returns Asset Tag details for the specified id */
  getAssetTag: AssetTagResponse;
  /** Retrieve a list of asset tag types for a specified owner. */
  listAssetTagTypes: AssetTagTypeListResponse;
  /** Returns asset tag type details for the specified id */
  getAssetTagType: AssetTagTypeResponse;
  /** Retrieve a list of asset types for a specified owner. */
  listAssetTypes: AssetTypeListResponse;
  /** Returns asset type details for the specified id */
  getAssetType: AssetTypeResponse;
  /** Retrieve a list of cost centres for a specified owner. */
  listCostCentres: CostCentreListResponse;
  /** Returns cost centre details for the specified id */
  getCostCentre: CostCentreResponse;
  /** Retrieve a list of Dashboards for a specified owner. */
  listDashboards: DashboardListResponse;
  /** Returns dashboard entity details for the specified id */
  getDashboard: DashboardResponse;
  /** Retrieve a list of Dashboard templates for a specified owner. */
  listDashboardTemplates: DashboardTemplateListResponse;
  /** Returns dashboard template entity details for the specified id */
  getDashboardTemplate: DashboardTemplateResponse;
  /** Retrieve a list of devices for a specified owner. */
  listDevices: DeviceListResponse;
  /** Returns device details for the specified id */
  getDevice: DeviceResponse;
  /** Retrieve a list of device types for a specified owner. */
  listDeviceTypes: DeviceTypeListResponse;
  /** Returns device type details for the specified id */
  getDeviceType: DeviceTypeResponse;
  /** Retrieve a list of device providers for a specified owner. */
  listDeviceProviders: DeviceProviderListResponse;
  /** Returns device provider details for the specified id */
  getDeviceProvider: DeviceProviderResponse;
  /** Retrieve a list of device provider types for a specified owner. */
  listDeviceProviderTypes: DeviceProviderTypeListResponse;
  /** Returns device provider type details for the specified id */
  getDeviceProviderType: DeviceProviderTypeResponse;
  /** Retrieve a list of export tasks for a specified owner. */
  listExportTasks: ExportTaskListResponse;
  /** Returns export task details for the specified id */
  getExportTask: ExportTaskResponse;
  /** Retrieve a list of I/O types for a specified owner. */
  listIoTypes: IoTypeListResponse;
  /** Returns I/O type details for the specified id */
  getIoType: IoTypeResponse;
  /** Retrieve a list of lookups for a specified entity. */
  getLookups: Scalars['JSONObject'];
  /** Retrieve a list of map sets for a specified owner. */
  listMapSets: MapSetListResponse;
  /** Retrieve a map set by it's ID. */
  getMapSet: MapSetResponse;
  /** Retrieve a list of overspeed profiles for a specified owner. */
  listOverspeedProfiles: OverspeedProfileListResponse;
  /** Returns overspeed profile details for the specified id */
  getOverspeedProfile: OverspeedProfileResponse;
  /** Retrieve a list of report definitions for a specified owner. */
  listReportDefinitions: ReportDefinitionListResponse;
  /** Retrieve a report definition by it's ID. */
  getReportDefinition: ReportDefinitionResponse;
  /** Retrieve a list of report templates for a specified owner. */
  listReportTemplates: ReportTemplateListResponse;
  /** Retrieve a report template by it's ID. */
  getReportTemplate: ReportTemplateResponse;
  /** Retrieve a list of reminders for a specified owner. */
  listReminders: ReminderListResponse;
  /** Retrieve a reminder by it's ID. */
  getReminder: ReminderResponse;
  /** Retrieve a list of scheduled reports for a specified owner. */
  listScheduledReports: ScheduledReportListResponse;
  /** Returns scheduled report details for the specified id */
  getScheduledReport: ScheduledReportResponse;
  /** Retrieve a list of SIM cards for a specified owner. */
  listSimCards: SimCardListResponse;
  /** Returns SIM card details for the specified id */
  getSimCard: SimCardResponse;
  /** Retrieve a list of sms gateway providers. */
  listSmsGatewayProviders: SmsGatewayProviderListResponse;
  /** Returns sms gateway provider details for the specified id */
  getSmsGatewayProvider: SmsGatewayProviderResponse;
  /** Retrieve a list of sms gateway provider types. */
  listSmsGatewayProviderTypes: SmsGatewayProviderTypeListResponse;
  /** Returns sms gateway provider type details for the specified id */
  getSmsGatewayProviderType: SmsGatewayProviderTypeResponse;
  /** Retrieve a list of email providers. */
  listEmailProviders: EmailProviderListResponse;
  /** Returns email provider details for the specified id */
  getEmailProvider: EmailProviderResponse;
  /** Retrieve a list of email provider types. */
  listEmailProviderTypes: EmailProviderTypeListResponse;
  /** Returns email provider type details for the specified id */
  getEmailProviderType: EmailProviderTypeResponse;
  /** Retrieve a list of themes for a specified owner. */
  listThemes: ThemeListResponse;
  /** Returns Theme details for the specified id */
  getTheme: ThemeResponse;
  /** Returns client details for the specified id */
  getThemeForDomain: DomainThemeResponse;
  /** Retrieve a list of zone groups for a specified owner. */
  listZoneGroups: ZoneGroupListResponse;
  /** Returns zone group details for the specified id */
  getZoneGroup: ZoneGroupResponse;
  /** Retrieve a list of zones for a specified owner. */
  listZones: ZoneListResponse;
  /** Retrieve a Map Set by it's ID. */
  getZone: ZoneResponse;
  /** Retrieve a list of media information items for the specified owner. */
  listMedia: MediaInfoListResponse;
  /** Retrieve a media information item. */
  getMedia: MediaInfoResponse;
  /** Retrieve a media item. */
  getMediaFile: Scalars['JSONObject'];
  /** Returns information for a media item. */
  getMediaInfo: MediaInfoResponse;
  /** [DEPRECATED] Use getMediaInfo instead */
  getMediaInfoDeprecated: MediaInfoResponse;
  /** Retrieve a list of queued reports for a specified owner. */
  listCompletedReports: CompletedReportListResponse;
  /** Retrieve a queued report by it's ID. */
  getCompletedReport: CompletedReportResponse;
  /** Retrieve the binary data for a queued report by it's ID. */
  getCompletedReportData: GetCompletedReportData;
  /** Retrieve a list of queued reports for a specified owner. */
  listQueuedReports: QueuedReportListResponse;
  /** Retrieve a queued report by it's ID. */
  getQueuedReport: QueuedReportResponse;
  /** Perform a free text search against the entities available to the user. */
  searchEntities: EntitySearchResponse;
  /** Retrieve a list of health issues. */
  listIssues: HealthIssueListResponse;
  /** Returns a health issue for the specified id */
  getIssue: HealthIssueResponse;
  /** Retrieve a list of ACTIVE tasks for a specific entity. */
  listTasks: TaskListResponse;
  /** Returns a task for the specified id */
  getTask: TaskResponse;
  /** Exports the language strings for the specified language. */
  exportLanguage: Scalars['JSONObject'];
  /** Perform a free text search against map addresses and zones. */
  geocodeForward: ForwardGeocodeResponse;
  /** Perform a reverse geocode text search against map addresses. */
  geocodeReverse: ReverseGeocodeResponse;
  /** Route between two points on a map. */
  getRoute: MapRouteResponse;
  /** Returns the current server time */
  time?: Maybe<Scalars['String']>;
  /** Throws a sample error message */
  error?: Maybe<Scalars['String']>;
};

export type QueryListClientsArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  counts?: Maybe<Scalars['String']>;
};

export type QueryGetClientArgs = {
  id: Scalars['String'];
};

export type QueryGetClientByPinArgs = {
  pin: Scalars['String'];
};

export type QueryGetClientLogoArgs = {
  id: Scalars['String'];
  size?: Maybe<Scalars['String']>;
};

export type QueryGetCompanyArgs = {
  id: Scalars['String'];
};

export type QueryGetCompanyLogoArgs = {
  id: Scalars['String'];
  size: Scalars['String'];
  recurse?: Maybe<Scalars['Boolean']>;
};

export type QueryListCompanyGroupsArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetCompanyGroupArgs = {
  id: Scalars['String'];
};

export type QueryListDistributorsArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  counts?: Maybe<Scalars['String']>;
};

export type QueryGetDistributorArgs = {
  id: Scalars['String'];
};

export type QueryGetDistributorLogoArgs = {
  id: Scalars['String'];
  size?: Maybe<Scalars['String']>;
};

export type QueryListUsersArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetUserArgs = {
  id: Scalars['String'];
};

export type QueryListUserRolesArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetUserRoleArgs = {
  id: Scalars['String'];
};

export type QueryListVendorsArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  counts?: Maybe<Scalars['String']>;
};

export type QueryGetVendorArgs = {
  id: Scalars['String'];
};

export type QueryGetVendorLogoArgs = {
  id: Scalars['String'];
  size?: Maybe<Scalars['String']>;
};

export type QuerySelectUserArgs = {
  id: Scalars['String'];
};

export type QueryGetNotificationFeedArgs = {
  sequence: Scalars['Float'];
  direction: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
};

export type QueryGetLocationFeedArgs = {
  client: Scalars['String'];
  sequence: Scalars['Float'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetTripFeedArgs = {
  client: Scalars['String'];
  sequence: Scalars['Float'];
  direction: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  asset?: Maybe<Scalars['String']>;
};

export type QueryGetEventFeedArgs = {
  client: Scalars['String'];
  sequence: Scalars['Float'];
  direction: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  asset?: Maybe<Scalars['String']>;
};

export type QueryGetAuditFeedForEntityArgs = {
  company: Scalars['String'];
  entity: Scalars['String'];
  sequence: Scalars['Float'];
  direction: Scalars['String'];
  limit: Scalars['Float'];
};

export type QueryGetAlertFeedArgs = {
  client: Scalars['String'];
  sequence: Scalars['Float'];
  direction: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  asset?: Maybe<Scalars['String']>;
  alert?: Maybe<Scalars['String']>;
};

export type QueryGetTelemetryHistoryArgs = {
  id: Scalars['String'];
  start: Scalars['String'];
  end: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
};

export type QueryGetEventHistoryArgs = {
  id: Scalars['String'];
  start: Scalars['String'];
  end: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
};

export type QueryGetAlertHistoryArgs = {
  id: Scalars['String'];
  start: Scalars['String'];
  end: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
};

export type QueryGetTripHistoryArgs = {
  id: Scalars['String'];
  start: Scalars['String'];
  end: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
};

export type QueryListAlertsArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetAlertArgs = {
  id: Scalars['String'];
};

export type QueryListAssetCategoriesArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetAssetCategoryArgs = {
  id: Scalars['String'];
};

export type QueryListAssetGroupsArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetAssetGroupArgs = {
  id: Scalars['String'];
};

export type QueryListAssetRatingProfilesArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetAssetRatingProfileArgs = {
  id: Scalars['String'];
};

export type QueryListAssetsArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetAssetArgs = {
  id: Scalars['String'];
};

export type QueryGetAssetLocationArgs = {
  id: Scalars['String'];
};

export type QueryGetAssetAvatarArgs = {
  id: Scalars['String'];
};

export type QueryListAssetStateProfilesArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetAssetStateProfileArgs = {
  id: Scalars['String'];
};

export type QueryListAssetTagsArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetAssetTagArgs = {
  id: Scalars['String'];
};

export type QueryListAssetTagTypesArgs = {
  owner: Scalars['String'];
};

export type QueryGetAssetTagTypeArgs = {
  id: Scalars['String'];
};

export type QueryListAssetTypesArgs = {
  owner: Scalars['String'];
};

export type QueryGetAssetTypeArgs = {
  id: Scalars['String'];
};

export type QueryListCostCentresArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetCostCentreArgs = {
  id: Scalars['String'];
};

export type QueryListDashboardsArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetDashboardArgs = {
  id: Scalars['String'];
};

export type QueryListDashboardTemplatesArgs = {
  owner: Scalars['String'];
  recurse?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetDashboardTemplateArgs = {
  id: Scalars['String'];
};

export type QueryListDevicesArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetDeviceArgs = {
  id: Scalars['String'];
};

export type QueryListDeviceTypesArgs = {
  owner: Scalars['String'];
  recurse?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetDeviceTypeArgs = {
  id: Scalars['String'];
  owner?: Maybe<Scalars['String']>;
};

export type QueryListDeviceProvidersArgs = {
  owner: Scalars['String'];
  recurse?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetDeviceProviderArgs = {
  id: Scalars['String'];
};

export type QueryListDeviceProviderTypesArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetDeviceProviderTypeArgs = {
  id: Scalars['String'];
};

export type QueryListExportTasksArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetExportTaskArgs = {
  id: Scalars['String'];
};

export type QueryListIoTypesArgs = {
  owner: Scalars['String'];
  recurse?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetIoTypeArgs = {
  id: Scalars['String'];
};

export type QueryGetLookupsArgs = {
  entity: Scalars['String'];
};

export type QueryListMapSetsArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetMapSetArgs = {
  id: Scalars['String'];
};

export type QueryListOverspeedProfilesArgs = {
  owner: Scalars['String'];
  recurse?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetOverspeedProfileArgs = {
  id: Scalars['String'];
};

export type QueryListReportDefinitionsArgs = {
  owner: Scalars['String'];
};

export type QueryGetReportDefinitionArgs = {
  id: Scalars['String'];
};

export type QueryListReportTemplatesArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetReportTemplateArgs = {
  id: Scalars['String'];
};

export type QueryListRemindersArgs = {
  owner: Scalars['String'];
  target?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetReminderArgs = {
  id: Scalars['String'];
};

export type QueryListScheduledReportsArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetScheduledReportArgs = {
  id: Scalars['String'];
};

export type QueryListSimCardsArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetSimCardArgs = {
  id: Scalars['String'];
};

export type QueryListSmsGatewayProvidersArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetSmsGatewayProviderArgs = {
  id: Scalars['String'];
};

export type QueryListSmsGatewayProviderTypesArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetSmsGatewayProviderTypeArgs = {
  id: Scalars['String'];
};

export type QueryListEmailProvidersArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetEmailProviderArgs = {
  id: Scalars['String'];
};

export type QueryListEmailProviderTypesArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetEmailProviderTypeArgs = {
  id: Scalars['String'];
};

export type QueryListThemesArgs = {
  owner: Scalars['String'];
  recurse?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetThemeArgs = {
  id: Scalars['String'];
};

export type QueryGetThemeForDomainArgs = {
  domain: Scalars['String'];
};

export type QueryListZoneGroupsArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetZoneGroupArgs = {
  id: Scalars['String'];
};

export type QueryListZonesArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  bounds?: Maybe<Scalars['String']>;
};

export type QueryGetZoneArgs = {
  id: Scalars['String'];
};

export type QueryListMediaArgs = {
  owner: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetMediaArgs = {
  id: Scalars['String'];
};

export type QueryGetMediaFileArgs = {
  asset: Scalars['String'];
  filename: Scalars['String'];
};

export type QueryGetMediaInfoArgs = {
  asset: Scalars['String'];
  filename: Scalars['String'];
};

export type QueryGetMediaInfoDeprecatedArgs = {
  owner: Scalars['String'];
  asset: Scalars['String'];
  filename: Scalars['String'];
};

export type QueryListCompletedReportsArgs = {
  start: Scalars['String'];
  end: Scalars['String'];
  client?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type QueryGetCompletedReportArgs = {
  id: Scalars['String'];
};

export type QueryGetCompletedReportDataArgs = {
  id: Scalars['String'];
  filename: Scalars['String'];
};

export type QueryListQueuedReportsArgs = {
  client?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  server?: Maybe<Scalars['String']>;
};

export type QueryGetQueuedReportArgs = {
  id: Scalars['String'];
};

export type QuerySearchEntitiesArgs = {
  query: Scalars['String'];
  deleted?: Maybe<Scalars['Boolean']>;
};

export type QueryListIssuesArgs = {
  owner?: Maybe<Scalars['String']>;
  targetId?: Maybe<Scalars['String']>;
  resolved?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
};

export type QueryGetIssueArgs = {
  id: Scalars['String'];
};

export type QueryListTasksArgs = {
  entity: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetTaskArgs = {
  id: Scalars['String'];
};

export type QueryExportLanguageArgs = {
  language: Scalars['String'];
};

export type QueryGeocodeForwardArgs = {
  client: Scalars['String'];
  query: Scalars['String'];
};

export type QueryGeocodeReverseArgs = {
  client: Scalars['String'];
  lat: Scalars['Float'];
  lon: Scalars['Float'];
};

export type QueryGetRouteArgs = {
  client: Scalars['String'];
  strategy: Scalars['String'];
  coords: Scalars['String'];
  src?: Maybe<Scalars['String']>;
};

/** A list of map set items */
export type QueuedReportListResponse = {
  __typename?: 'QueuedReportListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<QueuedReportResponse>>;
};

export type QueuedReportResponse = {
  __typename?: 'QueuedReportResponse';
  /** The unique ID of this report definition */
  id: Scalars['String'];
  /** The entity that owns this report */
  owner: IdNameType;
  /** The user that queued this report definition */
  user: IdName;
  /** The client for which the report was run */
  client: IdName;
  /** The base report name */
  name: Scalars['String'];
  /** The report title as set by the user */
  title: Scalars['String'];
  /** The reporting subsystem that generates this report */
  source: Scalars['String'];
  /** The status of this report */
  status: Scalars['String'];
  /** The ISO date/time that this report was queued */
  queueDate: Scalars['String'];
  /** The ISO date/time that this report's state last changed */
  updateDate: Scalars['String'];
  /** The output format for this report */
  outputFormat: Scalars['String'];
  /** The progress percentage of this report */
  progress: Scalars['Float'];
  /** report queue order, will change sometimes while queued */
  order: Scalars['Float'];
  /** The report priority: 0 = urgent, 1 = high, 2 = normal, 3 = low, 4+ = none (sorted as an integer) */
  priority: Scalars['Float'];
  /** The report configuration */
  config: ReportConfig;
  /** What triggered the report */
  triggeredBy: Scalars['String'];
};

export type QueueReportRequestInput = {
  clientId: Scalars['String'];
  source: Scalars['String'];
  serverId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  title: Scalars['String'];
  outputFormat: Scalars['String'];
  config: ReportConfigInput;
};

export type ReminderCreateRequestInput = {
  /** A unique name for this reminder */
  name?: Maybe<Scalars['String']>;
  /** The target entity to which this reminder applies. Usually an asset. */
  target?: Maybe<IdNameTypeInput>;
  /** Specify the trigger type of the reminder, which can be either time, odometer or engine hours. */
  type?: Maybe<Scalars['String']>;
  /** The reminder mode. If set to once, the reminder will be disabled as soon as it has triggered at least once. */
  mode?: Maybe<Scalars['String']>;
  /** The timezone to use for time based triggers. */
  timeZoneId?: Maybe<Scalars['String']>;
  /** The value to trigger at. This could be a date/time or and odometer or engine hours value. */
  trigger?: Maybe<ReminderTriggerValueInput>;
  reset?: Maybe<ReminderResetInput>;
  /** Whether the reminder is still enabled (will be false for reminders with mode set to `once` that have already triggered. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The client that owns this reminder */
  ownerId: Scalars['String'];
};

export type ReminderListItem = {
  __typename?: 'ReminderListItem';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A unique name for this reminder */
  name?: Maybe<Scalars['String']>;
  /** The target entity to which this reminder applies. Usually an asset. */
  target?: Maybe<IdNameType>;
  /** Specify the trigger type of the reminder, which can be either time, odometer or engine hours. */
  type?: Maybe<Scalars['String']>;
  /** The reminder mode. If set to once, the reminder will be disabled as soon as it has triggered at least once. */
  mode?: Maybe<Scalars['String']>;
  /** The timezone to use for time based triggers. */
  timeZoneId?: Maybe<Scalars['String']>;
  /** The value to trigger at. This could be a date/time or and odometer or engine hours value. */
  trigger?: Maybe<ReminderTriggerValue>;
  reset?: Maybe<ReminderReset>;
  /** Whether the reminder is still enabled (will be false for reminders with mode set to `once` that have already triggered. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The date the entity was last modified */
  modifiedDate: Scalars['String'];
};

/** A list of reminder items */
export type ReminderListResponse = {
  __typename?: 'ReminderListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<ReminderListItem>>;
};

export type ReminderReset = {
  __typename?: 'ReminderReset';
  /** The type of reset for the reminder. Affects which of the reset fields need to be completed. */
  type?: Maybe<Scalars['String']>;
  counter?: Maybe<Scalars['Float']>;
  /** Daily reset parameters */
  daily?: Maybe<ReminderResetDaily>;
  /** Weekly reset parameters */
  weekly?: Maybe<ReminderResetWeekly>;
  /** Monthly reset parameters */
  monthly?: Maybe<ReminderResetMonthly>;
};

export type ReminderResetDaily = {
  __typename?: 'ReminderResetDaily';
  recurrence: Scalars['Float'];
};

export type ReminderResetDailyInput = {
  recurrence: Scalars['Float'];
};

export type ReminderResetInput = {
  /** The type of reset for the reminder. Affects which of the reset fields need to be completed. */
  type?: Maybe<Scalars['String']>;
  counter?: Maybe<Scalars['Float']>;
  /** Daily reset parameters */
  daily?: Maybe<ReminderResetDailyInput>;
  /** Weekly reset parameters */
  weekly?: Maybe<ReminderResetWeeklyInput>;
  /** Monthly reset parameters */
  monthly?: Maybe<ReminderResetMonthlyInput>;
};

export type ReminderResetMonthly = {
  __typename?: 'ReminderResetMonthly';
  months: Array<Maybe<Scalars['Float']>>;
  day: Scalars['Float'];
};

export type ReminderResetMonthlyInput = {
  months: Array<Maybe<Scalars['Float']>>;
  day: Scalars['Float'];
};

export type ReminderResetWeekly = {
  __typename?: 'ReminderResetWeekly';
  days: Array<Maybe<Scalars['Float']>>;
};

export type ReminderResetWeeklyInput = {
  days: Array<Maybe<Scalars['Float']>>;
};

export type ReminderResponse = {
  __typename?: 'ReminderResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A unique name for this reminder */
  name?: Maybe<Scalars['String']>;
  /** The target entity to which this reminder applies. Usually an asset. */
  target?: Maybe<IdNameType>;
  /** Specify the trigger type of the reminder, which can be either time, odometer or engine hours. */
  type?: Maybe<Scalars['String']>;
  /** The reminder mode. If set to once, the reminder will be disabled as soon as it has triggered at least once. */
  mode?: Maybe<Scalars['String']>;
  /** The timezone to use for time based triggers. */
  timeZoneId?: Maybe<Scalars['String']>;
  /** The value to trigger at. This could be a date/time or and odometer or engine hours value. */
  trigger?: Maybe<ReminderTriggerValue>;
  reset?: Maybe<ReminderReset>;
  /** Whether the reminder is still enabled (will be false for reminders with mode set to `once` that have already triggered. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The date/time when the reminder last triggered. */
  lastTriggered?: Maybe<Scalars['String']>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type ReminderTriggerValue = {
  __typename?: 'ReminderTriggerValue';
  /** For odometer and hours values, specify the starting value from which to base subsequent increments */
  start?: Maybe<Scalars['String']>;
  /** For odometer and hours, the increment from start to trigger at. */
  value?: Maybe<Scalars['String']>;
};

export type ReminderTriggerValueInput = {
  /** For odometer and hours values, specify the starting value from which to base subsequent increments */
  start?: Maybe<Scalars['String']>;
  /** For odometer and hours, the increment from start to trigger at. */
  value?: Maybe<Scalars['String']>;
};

export type ReminderUpdateRequestInput = {
  /** A unique name for this reminder */
  name?: Maybe<Scalars['String']>;
  /** The target entity to which this reminder applies. Usually an asset. */
  target?: Maybe<IdNameTypeInput>;
  /** Specify the trigger type of the reminder, which can be either time, odometer or engine hours. */
  type?: Maybe<Scalars['String']>;
  /** The reminder mode. If set to once, the reminder will be disabled as soon as it has triggered at least once. */
  mode?: Maybe<Scalars['String']>;
  /** The timezone to use for time based triggers. */
  timeZoneId?: Maybe<Scalars['String']>;
  /** The value to trigger at. This could be a date/time or and odometer or engine hours value. */
  trigger?: Maybe<ReminderTriggerValueInput>;
  reset?: Maybe<ReminderResetInput>;
  /** Whether the reminder is still enabled (will be false for reminders with mode set to `once` that have already triggered. */
  enabled?: Maybe<Scalars['Boolean']>;
};

/** The configuration settings for the queued report */
export type ReportConfig = {
  __typename?: 'ReportConfig';
  /** The language that this report is being generated in */
  language?: Maybe<Scalars['String']>;
  /** The ID of the report defininition that was used to create this report */
  definitionId?: Maybe<Scalars['String']>;
  /** The style of the report that was selected */
  styleId?: Maybe<Scalars['String']>;
  /** The parameters that were passed to the report */
  parameters?: Maybe<Scalars['JSONObject']>;
};

/** The configuration settings for the queued report */
export type ReportConfigInput = {
  /** The language that this report is being generated in */
  language?: Maybe<Scalars['String']>;
  /** The ID of the report defininition that was used to create this report */
  definitionId?: Maybe<Scalars['String']>;
  /** The style of the report that was selected */
  styleId?: Maybe<Scalars['String']>;
  /** The parameters that were passed to the report */
  parameters?: Maybe<Scalars['JSONObject']>;
};

/** Describes column options */
export type ReportDefinitionColumnOptions = {
  __typename?: 'ReportDefinitionColumnOptions';
  /** The id of the column */
  id: Scalars['String'];
  /** The title of the column */
  title: Scalars['String'];
  /** The width of the column */
  width: Scalars['Float'];
  /** Indicates whether this column is suggested as a default */
  def: Scalars['Boolean'];
  /** Indicates whether this column is required */
  required: Scalars['Boolean'];
  /** The text alignment, either 'Left', 'Center' or 'Right' */
  align: Scalars['String'];
  /** The calculated measures expression to use */
  expression?: Maybe<Scalars['String']>;
};

/** Describes the fields that are used for a particular grouping option */
export type ReportDefinitionGroupingOptions = {
  __typename?: 'ReportDefinitionGroupingOptions';
  /** The id of this grouping option */
  id: Scalars['String'];
  /** The display name of this grouping option */
  name: Scalars['String'];
  /** The dataset fields that will be combined for this group */
  fields: Array<Maybe<Scalars['String']>>;
};

/** Describes the report columns and other layout options */
export type ReportDefinitionLayoutOptions = {
  __typename?: 'ReportDefinitionLayoutOptions';
  /** Describes the fields that are used for a particular grouping option */
  grouping: Array<Maybe<ReportDefinitionGroupingOptions>>;
  /** Describes column options */
  columns: Array<Maybe<ReportDefinitionColumnOptions>>;
  autoColumns: Array<Maybe<Scalars['String']>>;
};

/** A list of map set items */
export type ReportDefinitionListResponse = {
  __typename?: 'ReportDefinitionListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<ReportDefinitionResponse>>;
};

/** A ReportDefinition describes a runnable report, it's parameters and layout options */
export type ReportDefinitionResponse = {
  __typename?: 'ReportDefinitionResponse';
  /** The unique ID of this report definition */
  id: Scalars['String'];
  /** The entity that owns this report definition */
  owner: IdNameType;
  /** A unique name for this report */
  name: Scalars['String'];
  /** A description of the report */
  description: Scalars['String'];
  /** A list of tags that describe this report */
  tags: Array<Maybe<Scalars['String']>>;
  /** The reporting subsystem that generates this report */
  source: Scalars['String'];
  /** Indicates whether this report can be scheduled */
  schedulable: Scalars['Boolean'];
  /** One or more report styles that can be selected for this report */
  styles: Array<Maybe<ReportDefinitionStyleOptions>>;
};

/** Describes limits for this report */
export type ReportDefinitionStyleLimits = {
  __typename?: 'ReportDefinitionStyleLimits';
  /** The maximum number of days that can be selected for this report */
  days: Scalars['Float'];
};

/** Describes a particular style of a report */
export type ReportDefinitionStyleOptions = {
  __typename?: 'ReportDefinitionStyleOptions';
  /** The ID of this report style */
  id: Scalars['String'];
  /** The name of this report style */
  name: Scalars['String'];
  /** Indicates whether this report can be scheduled */
  schedulable: Scalars['Boolean'];
  /** Describes limits for this report */
  limits: ReportDefinitionStyleLimits;
  /** A JSON string that describes the parameter input fields for this report */
  parameters: Scalars['String'];
  /** Describes the report columns and other layout options */
  layout: ReportDefinitionLayoutOptions;
};

export type ReportOutputOptions = {
  __typename?: 'ReportOutputOptions';
  charts?: Maybe<Array<Maybe<AnalyticsOutputSettings>>>;
  table?: Maybe<Scalars['JSONObject']>;
};

export type ReportOutputOptionsInput = {
  charts?: Maybe<Array<Maybe<AnalyticsOutputSettingsInput>>>;
  table?: Maybe<Scalars['JSONObject']>;
};

export type ReportTemplateConfig = {
  __typename?: 'ReportTemplateConfig';
  /** The output format for this report */
  outputFormat: Scalars['String'];
  /** The ID of the report defininition that was used to create this report */
  definitionId: Scalars['String'];
  /** The style of the report that was selected */
  styleId: Scalars['String'];
  /** The parameters that were passed to the report */
  parameters: Scalars['JSONObject'];
  /** The report fields that should be visible and modifyable by the end user */
  editableFields: Array<Maybe<Scalars['String']>>;
};

export type ReportTemplateConfigInput = {
  /** The output format for this report */
  outputFormat: Scalars['String'];
  /** The ID of the report defininition that was used to create this report */
  definitionId: Scalars['String'];
  /** The style of the report that was selected */
  styleId: Scalars['String'];
  /** The parameters that were passed to the report */
  parameters: Scalars['JSONObject'];
  /** The report fields that should be visible and modifyable by the end user */
  editableFields: Array<Maybe<Scalars['String']>>;
};

export type ReportTemplateCreateRequestInput = {
  /** A unique name for this report template */
  name?: Maybe<Scalars['String']>;
  /** A description of the report */
  description?: Maybe<Scalars['String']>;
  /** A list of tags that describe this report template */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The preset configuration for this template */
  config?: Maybe<ReportTemplateConfigInput>;
  ownerId: Scalars['String'];
};

/** A list of report template items */
export type ReportTemplateListResponse = {
  __typename?: 'ReportTemplateListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<ReportTemplateResponse>>;
};

export type ReportTemplateResponse = {
  __typename?: 'ReportTemplateResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A unique name for this report template */
  name?: Maybe<Scalars['String']>;
  /** A description of the report */
  description?: Maybe<Scalars['String']>;
  /** A list of tags that describe this report template */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The preset configuration for this template */
  config?: Maybe<ReportTemplateConfig>;
};

export type ReportTemplateUpdateRequestInput = {
  /** A unique name for this report template */
  name?: Maybe<Scalars['String']>;
  /** A description of the report */
  description?: Maybe<Scalars['String']>;
  /** A list of tags that describe this report template */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The preset configuration for this template */
  config?: Maybe<ReportTemplateConfigInput>;
};

export type ReverseGeocode = {
  __typename?: 'ReverseGeocode';
  /** Road name */
  rd?: Maybe<Scalars['String']>;
  /** Suburb name */
  sb?: Maybe<Scalars['String']>;
  /** Town name */
  tw?: Maybe<Scalars['String']>;
  /** Province or state namee */
  pr?: Maybe<Scalars['String']>;
  /** Post code */
  pc?: Maybe<Scalars['String']>;
  /** City name */
  ct?: Maybe<Scalars['String']>;
};

/** A list of search results */
export type ReverseGeocodeResponse = {
  __typename?: 'ReverseGeocodeResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<ReverseGeocodeResult>>;
};

/** A reverse geocode search result item */
export type ReverseGeocodeResult = {
  __typename?: 'ReverseGeocodeResult';
  type: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  lon: Scalars['Float'];
  lat: Scalars['Float'];
  bounds?: Maybe<GeocoderBounds>;
  address?: Maybe<GeocoderAddress>;
  geometry?: Maybe<Array<Maybe<ZonePoint>>>;
  /** speed limit in km/h */
  speed?: Maybe<Scalars['Float']>;
};

export type Schedule = {
  __typename?: 'Schedule';
  /** The type of schedule */
  scheduleType?: Maybe<Scalars['String']>;
  /** The time of schedule initation. Also sets the time at which reports run. */
  startTime?: Maybe<Scalars['String']>;
  /** For daily and hourly scheduled, every {{every}} x hours/days */
  every?: Maybe<Scalars['Float']>;
  /** For weekly schedules, the days of the week in which to run (0-6 where 0 =
   * sunday). For monthly schedule, a single value with the day of the month to run on.
   */
  days?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** For monthly schedules, the month number (1-12) in which to run. */
  months?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type ScheduledReportCreateRequestInput = {
  /** The user who's security credentials to use when running this schedule. */
  user?: Maybe<IdNameInput>;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']>;
  /** The type of scheduled report. */
  scheduleType?: Maybe<Scalars['String']>;
  /** The time schedule on which to run */
  schedule?: Maybe<ScheduleInput>;
  /** The reports that should be run when the schedule fires. */
  reports?: Maybe<Scalars['JSONObject']>;
  /** The recipients that will receive an email with the completed reports */
  recipients?: Maybe<Array<Maybe<IdNameTypeInput>>>;
  /** The client or vendor that owns this entity */
  ownerId: Scalars['String'];
};

export type ScheduledReportListItem = {
  __typename?: 'ScheduledReportListItem';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The user who's security credentials to use when running this schedule. */
  user?: Maybe<IdName>;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The current state of this entity. */
  state?: Maybe<Scalars['String']>;
  /** The type of scheduled report. */
  scheduleType?: Maybe<Scalars['String']>;
  /** The time schedule on which to run. */
  schedule?: Maybe<Schedule>;
  /** The number of reports attached to this schedule. */
  reports?: Maybe<Scalars['Float']>;
  /** The number of recipients that will receive an email with the completed reports. */
  recipients?: Maybe<Scalars['Float']>;
  /** The date the entity was last modified */
  modifiedDate: Scalars['String'];
};

/** A list of scheduled report items */
export type ScheduledReportListResponse = {
  __typename?: 'ScheduledReportListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<ScheduledReportListItem>>;
};

export type ScheduledReportResponse = {
  __typename?: 'ScheduledReportResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The user who's security credentials to use when running this schedule. */
  user?: Maybe<IdName>;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']>;
  /** The type of scheduled report. */
  scheduleType?: Maybe<Scalars['String']>;
  /** The time schedule on which to run */
  schedule?: Maybe<Schedule>;
  /** The reports that should be run when the schedule fires. */
  reports?: Maybe<Scalars['JSONObject']>;
  /** The recipients that will receive an email with the completed reports */
  recipients?: Maybe<Array<Maybe<IdNameType>>>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type ScheduledReportUpdateRequestInput = {
  /** The user who's security credentials to use when running this schedule. */
  user?: Maybe<IdNameInput>;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']>;
  /** The type of scheduled report. */
  scheduleType?: Maybe<Scalars['String']>;
  /** The time schedule on which to run */
  schedule?: Maybe<ScheduleInput>;
  /** The reports that should be run when the schedule fires. */
  reports?: Maybe<Scalars['JSONObject']>;
  /** The recipients that will receive an email with the completed reports */
  recipients?: Maybe<Array<Maybe<IdNameTypeInput>>>;
};

export type ScheduleInput = {
  /** The type of schedule */
  scheduleType?: Maybe<Scalars['String']>;
  /** The time of schedule initation. Also sets the time at which reports run. */
  startTime?: Maybe<Scalars['String']>;
  /** For daily and hourly scheduled, every {{every}} x hours/days */
  every?: Maybe<Scalars['Float']>;
  /** For weekly schedules, the days of the week in which to run (0-6 where 0 =
   * sunday). For monthly schedule, a single value with the day of the month to run on.
   */
  days?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** For monthly schedules, the month number (1-12) in which to run. */
  months?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type SelectedMeasureValues = {
  __typename?: 'SelectedMeasureValues';
  /** The key of the measure */
  key: Scalars['String'];
  /** The name of the measure */
  value: Scalars['String'];
  /** Whether or not the measure is generic. This is useful to indicate that the measure is specific to a client
   * and should not be used in dashboard templates
   */
  generic?: Maybe<Scalars['Boolean']>;
  /** The date YYYY/MM/DD HH:mm:ss (UTC) from which the measure is valid */
  validFrom?: Maybe<Scalars['String']>;
  /** The date YYYY/MM/DD HH:mm:ss (UTC) to which the measure is valid */
  validTo?: Maybe<Scalars['String']>;
};

export type SelectedMeasureValuesInput = {
  /** The key of the measure */
  key: Scalars['String'];
  /** The name of the measure */
  value: Scalars['String'];
  /** Whether or not the measure is generic. This is useful to indicate that the measure is specific to a client
   * and should not be used in dashboard templates
   */
  generic?: Maybe<Scalars['Boolean']>;
  /** The date YYYY/MM/DD HH:mm:ss (UTC) from which the measure is valid */
  validFrom?: Maybe<Scalars['String']>;
  /** The date YYYY/MM/DD HH:mm:ss (UTC) to which the measure is valid */
  validTo?: Maybe<Scalars['String']>;
};

export type SelectedUserTokenResponse = {
  __typename?: 'SelectedUserTokenResponse';
  /** The JWT token is represented as a JSON Web Token (JWT). The token
   * contains claims about the identity of the authenticated user. Additional information is
   * also included in this token such as the default client id and user's full name and surname.
   * The id token expires one hour after the user authenticates. You should not process the
   * access token in your client or web API after it has expired.
   */
  idToken: Scalars['String'];
  /** The JWT token is represented as a JSON Web Token (JWT). The token
   * contains claims about the identity of the authenticated user. The access token expires one hour after the user
   * authenticates. You should not process the access token in your client or web API after it has expired.
   */
  accessToken: Scalars['String'];
  /** The JWT token is represented as a JSON Web Token (JWT). The token can be used to refresh a user's access and
   * id tokens. The access token expires one hour after the user authenticates and the refresh token is valid for 30 days.
   * You should not process the access token in your client or web API after it has expired.
   */
  refreshToken: Scalars['String'];
  /** The user that was selected */
  user?: Maybe<AuthUserResponse>;
};

export type Shift = {
  __typename?: 'Shift';
  /** A UUID V4 id for the shift */
  id: Scalars['String'];
  /** The name of the shift */
  name: Scalars['String'];
  /** The start time of the shift (hh:mm). This time is inclusive. */
  start: Scalars['String'];
  /** The end time of the shift (hh:mm). This time is exclusive */
  end: Scalars['String'];
};

export type ShiftInput = {
  /** A UUID V4 id for the shift */
  id: Scalars['String'];
  /** The name of the shift */
  name: Scalars['String'];
  /** The start time of the shift (hh:mm). This time is inclusive. */
  start: Scalars['String'];
  /** The end time of the shift (hh:mm). This time is exclusive */
  end: Scalars['String'];
};

export type ShiftPattern = {
  __typename?: 'ShiftPattern';
  /** A array of shifts making up a daily shift pattern. Shifts cannot overlap and
   * the total time accross shifts must add up to 24 hours
   */
  dailyShift: Array<Maybe<Shift>>;
};

export type ShiftPatternInput = {
  /** A array of shifts making up a daily shift pattern. Shifts cannot overlap and
   * the total time accross shifts must add up to 24 hours
   */
  dailyShift: Array<Maybe<ShiftInput>>;
};

export type SignOutRequestInput = {
  /** Pass a session ID if you want to sign out a particular session, leave empty to sign out from the current session */
  session?: Maybe<Scalars['String']>;
};

export type SimCardCreateRequestInput = {
  /** The serial number of the SIM card that is used to uniquely identify it. */
  name?: Maybe<Scalars['String']>;
  /** The name of the telecommunications network. */
  networkName: Scalars['String'];
  /** The direct mobile number for this SIM card. Must be in international format starting with +. */
  number: Scalars['String'];
  /** A short description of the sim card. */
  description: Scalars['String'];
  /** The current state of the object */
  state?: Maybe<Scalars['String']>;
  /** A number of custom field values for this simcard. */
  fields?: Maybe<Scalars['JSONObject']>;
  ownerId: Scalars['String'];
};

export type SimCardListItem = {
  __typename?: 'SimCardListItem';
  /** The unique UUID for this SIM card */
  id: Scalars['String'];
  /** The client that owns this SIM card */
  owner: IdName;
  /** The serial number of the SIM card that is used to uniquely identify it. */
  name: Scalars['String'];
  /** The name of the telecommunications network. */
  networkName: Scalars['String'];
  /** The direct mobile number for this SIM card. Must be in international format starting with +. */
  number: Scalars['String'];
  /** A short description of the sim card. */
  description: Scalars['String'];
  /** The current state of the object */
  state: Scalars['String'];
  /** The device to which this SIM card has been assigned. */
  device: IdName;
  /** A number of custom field values for this simcard. */
  fields: Scalars['JSONObject'];
};

/** A list of SIM card items */
export type SimCardListResponse = {
  __typename?: 'SimCardListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  /** The list of SIM card items in this response */
  items: Array<Maybe<SimCardListItem>>;
};

export type SimCardResponse = {
  __typename?: 'SimCardResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The serial number of the SIM card that is used to uniquely identify it. */
  name?: Maybe<Scalars['String']>;
  /** The name of the telecommunications network. */
  networkName?: Maybe<Scalars['String']>;
  /** The direct mobile number for this SIM card. Must be in international format starting with +. */
  number?: Maybe<Scalars['String']>;
  /** A short description of the sim card. */
  description?: Maybe<Scalars['String']>;
  /** The current state of the object */
  state?: Maybe<Scalars['String']>;
  /** A number of custom field values for this simcard. */
  fields?: Maybe<Scalars['JSONObject']>;
  /** The device to which this SIM card has been assigned.
   * Can only be modified using the `updateDevice` operation.
   */
  device?: Maybe<DeviceResponse>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type SimCardUpdateRequestInput = {
  /** The serial number of the SIM card that is used to uniquely identify it. */
  name?: Maybe<Scalars['String']>;
  /** The name of the telecommunications network. */
  networkName?: Maybe<Scalars['String']>;
  /** The direct mobile number for this SIM card. Must be in international format starting with +. */
  number?: Maybe<Scalars['String']>;
  /** A short description of the sim card. */
  description?: Maybe<Scalars['String']>;
  /** The current state of the object */
  state?: Maybe<Scalars['String']>;
  /** A number of custom field values for this simcard. */
  fields?: Maybe<Scalars['JSONObject']>;
};

export type SmsGatewayProviderCreateRequestInput = {
  /** The name of the sms gateway provider */
  name?: Maybe<Scalars['String']>;
  /** The type of the sms gateway provider */
  type?: Maybe<IdNameInput>;
  /** Whether or not the sms gateway is oneway or twoway */
  direction?: Maybe<Scalars['String']>;
  /** The state this entity */
  state?: Maybe<Scalars['String']>;
  /** A dictionary of entity specific parameters */
  parameters?: Maybe<Scalars['JSONObject']>;
  /** The client that owns this sms gateway provider */
  ownerId: Scalars['String'];
};

export type SmsGatewayProviderListItem = {
  __typename?: 'SmsGatewayProviderListItem';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The name of the sms gateway provider */
  name?: Maybe<Scalars['String']>;
  /** The type of the sms gateway provider */
  type?: Maybe<IdName>;
  /** Whether or not the sms gateway is oneway or twoway */
  direction?: Maybe<Scalars['String']>;
  /** The state this entity */
  state?: Maybe<Scalars['String']>;
  /** The date the entity was last modified */
  modifiedDate: Scalars['String'];
};

/** A list of sms gateway provider items */
export type SmsGatewayProviderListResponse = {
  __typename?: 'SmsGatewayProviderListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<SmsGatewayProviderListItem>>;
};

export type SmsGatewayProviderResponse = {
  __typename?: 'SmsGatewayProviderResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The name of the sms gateway provider */
  name?: Maybe<Scalars['String']>;
  /** The type of the sms gateway provider */
  type?: Maybe<IdName>;
  /** Whether or not the sms gateway is oneway or twoway */
  direction?: Maybe<Scalars['String']>;
  /** The state this entity */
  state?: Maybe<Scalars['String']>;
  /** A dictionary of entity specific parameters */
  parameters?: Maybe<Scalars['JSONObject']>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type SmsGatewayProviderTypeListItem = {
  __typename?: 'SmsGatewayProviderTypeListItem';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The name of the sms gateway provider type */
  name: Scalars['String'];
  /** The tag to apply to the sms gateway provider type */
  tag: Scalars['String'];
  /** The parameters used by the sms gateway type */
  parameterDefinition?: Maybe<Scalars['String']>;
  /** Whether or not the sms gateway type is oneway or twoway */
  direction?: Maybe<Scalars['String']>;
  /** The state this entity */
  state?: Maybe<Scalars['String']>;
  /** The date the entity was last modified */
  modifiedDate: Scalars['String'];
};

/** A list of sms gateway provider type items */
export type SmsGatewayProviderTypeListResponse = {
  __typename?: 'SmsGatewayProviderTypeListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<SmsGatewayProviderTypeListItem>>;
};

export type SmsGatewayProviderTypeResponse = {
  __typename?: 'SmsGatewayProviderTypeResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The name of the sms gateway provider type */
  name: Scalars['String'];
  /** The tag to apply to the sms gateway provider type */
  tag: Scalars['String'];
  /** The parameters used by the sms gateway type */
  parameterDefinition?: Maybe<Scalars['String']>;
  /** Whether or not the sms gateway type is oneway or twoway */
  direction?: Maybe<Scalars['String']>;
  /** The state this entity */
  state?: Maybe<Scalars['String']>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type SmsGatewayProviderUpdateRequestInput = {
  /** The name of the sms gateway provider */
  name?: Maybe<Scalars['String']>;
  /** The type of the sms gateway provider */
  type?: Maybe<IdNameInput>;
  /** Whether or not the sms gateway is oneway or twoway */
  direction?: Maybe<Scalars['String']>;
  /** The state this entity */
  state?: Maybe<Scalars['String']>;
  /** A dictionary of entity specific parameters */
  parameters?: Maybe<Scalars['JSONObject']>;
};

export type SoftLimits = {
  __typename?: 'SoftLimits';
  entities?: Maybe<Scalars['JSONObject']>;
};

export type SoftLimitsInput = {
  entities?: Maybe<Scalars['JSONObject']>;
};

export type SpeedLimits = {
  __typename?: 'SpeedLimits';
  /** likely road speed */
  rd: Scalars['Float'];
  /** maximum road speed in the vicinity */
  mx: Scalars['Float'];
  /** speed unit (0 = kph, 1 = mph) */
  un: Scalars['Float'];
};

export type StatChartOptions = {
  __typename?: 'StatChartOptions';
  data: Scalars['String'];
  key?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  trend?: Maybe<Scalars['String']>;
  sparkline?: Maybe<Scalars['Boolean']>;
};

export type StatChartOptionsInput = {
  data: Scalars['String'];
  key?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  trend?: Maybe<Scalars['String']>;
  sparkline?: Maybe<Scalars['Boolean']>;
};

export type StatsEntityOutputOptionsInput = {
  groupLevel: Scalars['String'];
  rowLevel: Scalars['String'];
  time: Scalars['String'];
  flip: Scalars['Boolean'];
};

export type Subscription = {
  __typename?: 'Subscription';
  /** Returns the current time for the specified interval */
  time?: Maybe<Scalars['String']>;
  /** Throws a sample error message */
  error?: Maybe<Scalars['String']>;
  getLocationFeed?: Maybe<Array<Maybe<TelemetryStateResponse>>>;
  getRecentTripFeed?: Maybe<Array<Maybe<TripResponse>>>;
  getRecentEventFeed?: Maybe<Array<Maybe<EventResponse>>>;
  getRecentAlertFeed?: Maybe<Array<Maybe<EventResponse>>>;
  getRecentAuditFeed?: Maybe<Array<Maybe<AuditEventResponse>>>;
  getRecentNotificationFeed?: Maybe<Array<Maybe<NotificationResponse>>>;
  getTaskFeed?: Maybe<Array<Maybe<TaskResponse>>>;
};

export type SubscriptionTimeArgs = {
  interval: Scalars['Float'];
};

export type SubscriptionGetLocationFeedArgs = {
  client: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type SubscriptionGetRecentTripFeedArgs = {
  client: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  asset?: Maybe<Scalars['String']>;
};

export type SubscriptionGetRecentEventFeedArgs = {
  client: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  asset?: Maybe<Scalars['String']>;
};

export type SubscriptionGetRecentAlertFeedArgs = {
  client: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  asset?: Maybe<Scalars['String']>;
  alert?: Maybe<Scalars['String']>;
};

export type SubscriptionGetRecentAuditFeedArgs = {
  company: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  entity?: Maybe<Scalars['String']>;
};

export type SubscriptionGetRecentNotificationFeedArgs = {
  limit?: Maybe<Scalars['Float']>;
};

export type SubscriptionGetTaskFeedArgs = {
  entity?: Maybe<Scalars['String']>;
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  /** A generic success message */
  message?: Maybe<Scalars['String']>;
};

export type TaskCreateRequestInput = {
  ownerId: Scalars['String'];
  /** The type of the task */
  type: Scalars['String'];
  /** A number of task execution options */
  options: TaskOptionsInput;
  /** A number of task specific options */
  data: Scalars['JSONObject'];
};

/** Task date information */
export type TaskDates = {
  __typename?: 'TaskDates';
  /** The date that the task was created */
  created: Scalars['String'];
  /** The date that the task was last updated */
  updated: Scalars['String'];
  /** The date at which the task was completed */
  completed?: Maybe<Scalars['String']>;
};

/** A list of task items */
export type TaskListResponse = {
  __typename?: 'TaskListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  /** The list of task items in this response */
  items: Array<Maybe<TaskResponse>>;
};

/** A number of task execution options */
export type TaskOptions = {
  __typename?: 'TaskOptions';
  /** The number of retries that should be attempted if this task fails */
  retries: Scalars['Float'];
  /** The number of minutes to wait after failure before trying again */
  delay: Scalars['Float'];
  /** Any task in the executing state for more than `timeout` minutes will be automatically failed. */
  timeout: Scalars['Float'];
};

/** A number of task execution options */
export type TaskOptionsInput = {
  /** The number of retries that should be attempted if this task fails */
  retries: Scalars['Float'];
  /** The number of minutes to wait after failure before trying again */
  delay: Scalars['Float'];
  /** Any task in the executing state for more than `timeout` minutes will be automatically failed. */
  timeout: Scalars['Float'];
};

export type TaskResponse = {
  __typename?: 'TaskResponse';
  /** A unique GUID for the task */
  id: Scalars['String'];
  /** The company that owns this task */
  owner: IdNameType;
  /** The user that created this task (optional) */
  user?: Maybe<IdName>;
  /** A number of entities that are linked to this task */
  linked: Array<Maybe<IdNameType>>;
  /** A number of task execution options */
  options: TaskOptions;
  /** The state of the task */
  state: Scalars['String'];
  /** Date information */
  date: TaskDates;
  /** The type of the task */
  type: Scalars['String'];
  /** A number of task specific options */
  data: Scalars['JSONObject'];
  /** A number of task specific results */
  results?: Maybe<Scalars['JSONObject']>;
};

/** A list of telemetry items */
export type TelemetryFeedResponse = {
  __typename?: 'TelemetryFeedResponse';
  /** sequence for the feed */
  sequence: Scalars['Float'];
  /** number of items in the feed */
  count: Scalars['Float'];
  items: Array<Maybe<TelemetryStateResponse>>;
};

/** A list of telemetry items */
export type TelemetryListResponse = {
  __typename?: 'TelemetryListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  /** ISO start date of the request */
  start: Scalars['String'];
  /** ISO end date of the request */
  end: Scalars['String'];
  /** Telemetry items */
  items: Array<Maybe<TelemetryResponse>>;
};

export type TelemetryLocation = {
  __typename?: 'TelemetryLocation';
  /** The WGS84 longitude in decimal degrees */
  lon: Scalars['Float'];
  /** The WGS84 latitude in decimal degrees */
  lat: Scalars['Float'];
  /** The speed in km/h */
  speed: Scalars['Float'];
  /** The altitude above sea level in meters */
  altitude: Scalars['Float'];
  /** The heading in degrees */
  heading: Scalars['Float'];
  /** The accuracy of the location (usually the number of satellites visible, but varies by device) */
  accuracy: Scalars['Float'];
  /** The reverse geocoded address of this location. May also be the name of a zone that is currently been entered. */
  address?: Maybe<Scalars['String']>;
  /** The age of the gps position. Not sent by all devices. */
  age?: Maybe<Scalars['Float']>;
  /** Detailed reverse geoding information */
  gc?: Maybe<ReverseGeocode>;
};

export type TelemetryResponse = {
  __typename?: 'TelemetryResponse';
  /** The device that generated this telemetry message */
  origin?: Maybe<IdName>;
  /** The ISO8601 UTC date that the message was generated by the device */
  date: Scalars['String'];
  /** The ISO8601 UTC date that the message was received by the system */
  received: Scalars['String'];
  /** True if the device is in an active state */
  active: Scalars['Boolean'];
  /** A list of assets linked to this telemetry message */
  linked: Array<Maybe<IdNameType>>;
  /** A list of zones which are relevant to this location */
  zones: Array<Maybe<IdNameType>>;
  /** A list of routes which are relevant to this location */
  routes: Array<Maybe<IdName>>;
  /** The relevant asset state for this message */
  state: Scalars['JSONObject'];
  /** If road speed is enabled, the speed limit information retrieved from reverse geocoding */
  spd?: Maybe<SpeedLimits>;
  /** The current location where this message was generated */
  location: TelemetryLocation;
  /** A list of telemetry states and values for this message */
  telemetry: Scalars['JSONObject'];
  /** A list of counter values for this message */
  counters: Scalars['JSONObject'];
  /** Parsed IO values for this message */
  io: Scalars['JSONObject'];
};

export type TelemetryStateResponse = {
  __typename?: 'TelemetryStateResponse';
  /** The device that generated this telemetry message */
  origin?: Maybe<IdName>;
  /** The ISO8601 UTC date that the message was generated by the device */
  date: Scalars['String'];
  /** The ISO8601 UTC date that the message was received by the system */
  received: Scalars['String'];
  /** True if the device is in an active state */
  active: Scalars['Boolean'];
  /** A list of assets linked to this telemetry message */
  linked: Array<Maybe<IdNameType>>;
  /** A list of zones which are relevant to this location */
  zones: Array<Maybe<IdNameType>>;
  /** A list of routes which are relevant to this location */
  routes: Array<Maybe<IdName>>;
  /** The relevant asset state for this message */
  state: Scalars['JSONObject'];
  /** If road speed is enabled, the speed limit information retrieved from reverse geocoding */
  spd?: Maybe<SpeedLimits>;
  /** The current location where this message was generated */
  location: TelemetryLocation;
  /** A list of telemetry states and values for this message */
  telemetry: Scalars['JSONObject'];
  /** A list of counter values for this message */
  counters: Scalars['JSONObject'];
  /** Parsed IO values for this message */
  io: Scalars['JSONObject'];
  /** A dictionary of meta information generated in the system during processing */
  meta?: Maybe<Scalars['JSONObject']>;
  /** The asset that this information belongs to */
  object?: Maybe<IdNameType>;
  /** Details about the last in progress trip */
  trip?: Maybe<TelemetryTrip>;
  /** The last time that the asset moved. */
  lastMovement?: Maybe<Scalars['String']>;
};

export type TelemetryTrip = {
  __typename?: 'TelemetryTrip';
  start: Scalars['String'];
  startAddress: Scalars['String'];
  startLon: Scalars['Float'];
  startLat: Scalars['Float'];
  distance: Scalars['Float'];
  lastLon: Scalars['Float'];
  lastLat: Scalars['Float'];
};

export type TestNotificationsRequestInput = {
  /** The id of the company from which to send the test notification */
  ownerId?: Maybe<Scalars['String']>;
  /** A list of notification channels to test */
  notificationChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The url of the client application */
  siteUrl: Scalars['String'];
};

export type ThemeCreateRequestInput = {
  /** The ID of the base theme CSS */
  theme?: Maybe<Scalars['String']>;
  /** The name of the theme */
  name?: Maybe<Scalars['String']>;
  /** An optional description of the theme */
  description?: Maybe<Scalars['String']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']>;
  /** The list of css custom properties */
  variables?: Maybe<Scalars['JSONObject']>;
  /** The list of adjustable settings */
  settings?: Maybe<Scalars['JSONObject']>;
  /** The custom overwrites that will be injected after the theme css */
  customStyles?: Maybe<Scalars['String']>;
  /** The client or vendor that owns this entity */
  ownerId: Scalars['String'];
};

export type ThemeInfo = {
  __typename?: 'ThemeInfo';
  /** The unique ID of the theme */
  id?: Maybe<Scalars['String']>;
  /** The name of the theme */
  name?: Maybe<Scalars['String']>;
  /** [DEPRECATED] A set of theme specific overrides */
  settings?: Maybe<Scalars['JSONObject']>;
};

export type ThemeInfoInput = {
  /** The unique ID of the theme */
  id?: Maybe<Scalars['String']>;
  /** The name of the theme */
  name?: Maybe<Scalars['String']>;
  /** [DEPRECATED] A set of theme specific overrides */
  settings?: Maybe<Scalars['JSONObject']>;
};

export type ThemeListItem = {
  __typename?: 'ThemeListItem';
  /** The unique ID of the theme */
  id: Scalars['String'];
  /** The entity that owns this theme */
  owner: IdNameType;
  /** The ID of the base theme CSS */
  theme: Scalars['String'];
  /** The name of the theme */
  name: Scalars['String'];
  /** An optional description of the theme */
  description: Scalars['String'];
};

/** A list of vendor items */
export type ThemeListResponse = {
  __typename?: 'ThemeListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<ThemeListItem>>;
};

export type ThemeResponse = {
  __typename?: 'ThemeResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The ID of the base theme CSS */
  theme?: Maybe<Scalars['String']>;
  /** The name of the theme */
  name?: Maybe<Scalars['String']>;
  /** An optional description of the theme */
  description?: Maybe<Scalars['String']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']>;
  /** The list of css custom properties */
  variables?: Maybe<Scalars['JSONObject']>;
  /** The list of adjustable settings */
  settings?: Maybe<Scalars['JSONObject']>;
  /** The custom overwrites that will be injected after the theme css */
  customStyles?: Maybe<Scalars['String']>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type ThemeUpdateRequestInput = {
  /** The ID of the base theme CSS */
  theme?: Maybe<Scalars['String']>;
  /** The name of the theme */
  name?: Maybe<Scalars['String']>;
  /** An optional description of the theme */
  description?: Maybe<Scalars['String']>;
  /** The current state of this entity */
  state?: Maybe<Scalars['String']>;
  /** The list of css custom properties */
  variables?: Maybe<Scalars['JSONObject']>;
  /** The list of adjustable settings */
  settings?: Maybe<Scalars['JSONObject']>;
  /** The custom overwrites that will be injected after the theme css */
  customStyles?: Maybe<Scalars['String']>;
};

export type TimeRange = {
  __typename?: 'TimeRange';
  /** The start time range */
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};

export type TimeRangeInput = {
  /** The start time range */
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};

export type TokenResponse = {
  __typename?: 'TokenResponse';
  /** The JWT token is represented as a JSON Web Token (JWT). The token
   * contains claims about the identity of the authenticated user. Additional information is
   * also included in this token such as the default client id and user's full name and surname.
   * The id token expires one hour after the user authenticates. You should not process the
   * access token in your client or web API after it has expired.
   */
  idToken: Scalars['String'];
  /** The JWT token is represented as a JSON Web Token (JWT). The token
   * contains claims about the identity of the authenticated user. The access token expires one hour after the user
   * authenticates. You should not process the access token in your client or web API after it has expired.
   */
  accessToken: Scalars['String'];
  /** The JWT token is represented as a JSON Web Token (JWT). The token can be used to refresh a user's access and
   * id tokens. The access token expires one hour after the user authenticates and the refresh token is valid for 30 days.
   * You should not process the access token in your client or web API after it has expired.
   */
  refreshToken: Scalars['String'];
};

/** A list of trip items */
export type TripFeedResponse = {
  __typename?: 'TripFeedResponse';
  /** sequence for the feed */
  sequence: Scalars['Float'];
  /** number of items in the feed */
  count: Scalars['Float'];
  items: Array<Maybe<TripResponse>>;
};

/** A list of trip items */
export type TripListResponse = {
  __typename?: 'TripListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  /** ISO start date of the request */
  start: Scalars['String'];
  /** ISO end date of the request */
  end: Scalars['String'];
  /** Trip items */
  items: Array<Maybe<TripResponse>>;
};

export type TripLocation = {
  __typename?: 'TripLocation';
  /** The WGS84 longitude in decimal degrees */
  lon: Scalars['Float'];
  /** The WGS84 latitude in decimal degrees */
  lat: Scalars['Float'];
  /** The reverse geocoded address of this location. May also be the name of a zone that is currently been entered. */
  address: Scalars['String'];
  /** The telemetry counter values at this point */
  counters: Scalars['JSONObject'];
};

export type TripRating = {
  __typename?: 'TripRating';
  profile: Scalars['String'];
  score: Scalars['Float'];
  penalties: Array<Maybe<TripRatingPenalty>>;
};

export type TripRatingPenalty = {
  __typename?: 'TripRatingPenalty';
  /** The unique id for this penalty */
  id: Scalars['String'];
  /** Name of the penalty */
  name: Scalars['String'];
  /** Number of instances that this penalty occurred */
  instances: Scalars['Float'];
  /** Score for this penalty */
  score: Scalars['Float'];
  max: Scalars['Float'];
  /** The monetary cost incurred each time this incident occurs */
  cost: Scalars['Float'];
};

export type TripResponse = {
  __typename?: 'TripResponse';
  /** Unique id for this trip */
  id: Scalars['String'];
  /** The asset that generated this trip */
  asset: IdName;
  /** The type of this asset */
  assetType: IdName;
  /** The type of this trip */
  tripType: Scalars['String'];
  /** The ISO8601 UTC date that the trip was started */
  dateStart: Scalars['String'];
  /** The ISO8601 UTC date that the trip was ended */
  dateEnd: Scalars['String'];
  /** The location where the trip started */
  start: TripLocation;
  /** The location where the trip ended */
  end: TripLocation;
  /** Statistics about the trip */
  stats: TripStats;
  /** Trip rating information */
  rating?: Maybe<TripRating>;
  /** The number of telemetry records that comprised this trip */
  records: Scalars['Float'];
  /** Any other assets that were linked to this trip */
  linkedAssets: Array<Maybe<IdNameType>>;
  /** Max values for the trip */
  maxes: Scalars['JSONObject'];
};

export type TripStats = {
  __typename?: 'TripStats';
  /** The number of seconds spend driving (active and speed > 0) */
  driveTime: Scalars['Float'];
  /** The number of seconds spend idling (active and speed < 0) */
  idleTime: Scalars['Float'];
  /** The distance driven in km */
  distance: Scalars['Float'];
};

/** An unexpected error is thrown when the API experiences an unexpected internal exception. */
export type UnexpectedError = {
  __typename?: 'UnexpectedError';
  status?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSONObject']>;
  stack?: Maybe<Scalars['String']>;
};

export type UpdateUserPasswordRequestInput = {
  /** The user's current password. */
  oldPassword: Scalars['String'];
  /** The user's new password */
  newPassword: Scalars['String'];
};

export type UserApiKeyCreateRequestInput = {
  name: Scalars['String'];
  policies: Array<Maybe<PolicyInput>>;
};

export type UserApiKeyCreateResponse = {
  __typename?: 'UserApiKeyCreateResponse';
  name: Scalars['String'];
  policies: Array<Maybe<Policy>>;
  key: Scalars['String'];
};

export type UserCreateRequestInput = {
  name?: Maybe<Scalars['String']>;
  /** The user's email address, used to log into the system */
  emailAddress?: Maybe<Scalars['String']>;
  /** An optional mobile number used for SMS notifications */
  mobile?: Maybe<Scalars['String']>;
  /** The time zone identifier for the user (uses the tz datbase for timezones, see https://en.wikipedia.org/wiki/Tz_database) */
  timeZoneId?: Maybe<Scalars['String']>;
  /** The language code for this user */
  language?: Maybe<Scalars['String']>;
  /** The state of the user object */
  state?: Maybe<Scalars['String']>;
  /** notification settings */
  notifySettings?: Maybe<NotificationSettingsInput>;
  /** The default client to load for this user */
  defaultClient?: Maybe<IdNameInput>;
  /** A list of user roles that apply to this user */
  roles?: Maybe<Array<Maybe<IdNameInput>>>;
  /** The cost centre that this user belongs to */
  costCentre?: Maybe<IdNameInput>;
  /** When OpenId Connect is enabled for a client, you need to tie the user to the issuer's internal user ID. Specificy them
   * in the oidcTags bucket
   */
  oidcTags?: Maybe<Scalars['JSONObject']>;
  /** The company that owns this user */
  ownerId: Scalars['String'];
  /** The users password */
  password?: Maybe<Scalars['String']>;
  /** Populate only if you wish to send an invitation email on account creation.
   * The full URL where the user will be redirected for completing their profile.
   * Include a {token} template variable so the API can insert the reset token,
   * and optionally a {domain} template variable into which the configured domain for this user will be inserted.
   * i.e. https://{domain}/reset?token={token}
   */
  inviteUrl?: Maybe<Scalars['String']>;
};

export type UserCreateResponse = {
  __typename?: 'UserCreateResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  name?: Maybe<Scalars['String']>;
  /** The user's email address, used to log into the system */
  emailAddress?: Maybe<Scalars['String']>;
  /** An optional mobile number used for SMS notifications */
  mobile?: Maybe<Scalars['String']>;
  /** The time zone identifier for the user (uses the tz datbase for timezones, see https://en.wikipedia.org/wiki/Tz_database) */
  timeZoneId?: Maybe<Scalars['String']>;
  /** The language code for this user */
  language?: Maybe<Scalars['String']>;
  /** The state of the user object */
  state?: Maybe<Scalars['String']>;
  /** notification settings */
  notifySettings?: Maybe<NotificationSettings>;
  /** The default client to load for this user */
  defaultClient?: Maybe<IdName>;
  /** A list of user roles that apply to this user */
  roles?: Maybe<Array<Maybe<IdName>>>;
  /** The cost centre that this user belongs to */
  costCentre?: Maybe<IdName>;
  /** When OpenId Connect is enabled for a client, you need to tie the user to the issuer's internal user ID. Specificy them
   * in the oidcTags bucket
   */
  oidcTags?: Maybe<Scalars['JSONObject']>;
  /** An optional set of API keys generated by the user */
  apiKeys?: Maybe<Scalars['JSONObject']>;
  entity: EntityInfo;
  /** If you've populate the `inviteUrl` property when creating the user, and there
   * was an error sending the invite email, it will be
   * available in this field.
   */
  inviteError?: Maybe<UnexpectedError>;
};

export type UserListItem = {
  __typename?: 'UserListItem';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  name?: Maybe<Scalars['String']>;
  /** The user's email address, used to log into the system */
  emailAddress?: Maybe<Scalars['String']>;
  /** An optional mobile number used for SMS notifications */
  mobile?: Maybe<Scalars['String']>;
  /** The time zone identifier for the user (uses the tz datbase for timezones, see https://en.wikipedia.org/wiki/Tz_database) */
  timeZoneId?: Maybe<Scalars['String']>;
  /** The language code for this user */
  language?: Maybe<Scalars['String']>;
  /** The state of the user object */
  state?: Maybe<Scalars['String']>;
  /** The default client to load for this user */
  defaultClient?: Maybe<IdName>;
  /** The cost centre that this user belongs to */
  costCentre?: Maybe<IdName>;
  /** The date the user was last modified */
  modifiedDate: Scalars['String'];
};

/** A list of users */
export type UserListResponse = {
  __typename?: 'UserListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<UserListItem>>;
};

export type UserProfileResponse = {
  __typename?: 'UserProfileResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  name?: Maybe<Scalars['String']>;
  /** The user's email address, used to log into the system */
  emailAddress?: Maybe<Scalars['String']>;
  /** An optional mobile number used for SMS notifications */
  mobile?: Maybe<Scalars['String']>;
  /** The time zone identifier for the user (uses the tz datbase for timezones, see https://en.wikipedia.org/wiki/Tz_database) */
  timeZoneId?: Maybe<Scalars['String']>;
  /** The language code for this user */
  language?: Maybe<Scalars['String']>;
  /** The state of the user object */
  state?: Maybe<Scalars['String']>;
  /** notification settings */
  notifySettings?: Maybe<NotificationSettings>;
  /** The default client to load for this user */
  defaultClient?: Maybe<IdName>;
  /** A list of user roles that apply to this user */
  roles?: Maybe<Array<Maybe<IdName>>>;
  /** The cost centre that this user belongs to */
  costCentre?: Maybe<IdName>;
  /** When OpenId Connect is enabled for a client, you need to tie the user to the issuer's internal user ID. Specificy them
   * in the oidcTags bucket
   */
  oidcTags?: Maybe<Scalars['JSONObject']>;
  /** An optional set of API keys generated by the user */
  apiKeys?: Maybe<Scalars['JSONObject']>;
  entity: EntityInfo;
  /** A list of user session information */
  sessions: Array<Maybe<UserSession>>;
};

export type UserResetRequestInput = {
  /** The full URL where the user will be redirected for password reset. Include a
   * {token} template variable so the API can insert the reset token,
   * and optionally a {domain} template variable into which the configured domain for this user will be inserted.
   * i.e. https://{domain}/#/login?token={token}
   */
  resetUrl?: Maybe<Scalars['String']>;
};

export type UserResetResponse = {
  __typename?: 'UserResetResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  name?: Maybe<Scalars['String']>;
  /** The user's email address, used to log into the system */
  emailAddress?: Maybe<Scalars['String']>;
  /** An optional mobile number used for SMS notifications */
  mobile?: Maybe<Scalars['String']>;
  /** The time zone identifier for the user (uses the tz datbase for timezones, see https://en.wikipedia.org/wiki/Tz_database) */
  timeZoneId?: Maybe<Scalars['String']>;
  /** The language code for this user */
  language?: Maybe<Scalars['String']>;
  /** The state of the user object */
  state?: Maybe<Scalars['String']>;
  /** notification settings */
  notifySettings?: Maybe<NotificationSettings>;
  /** The default client to load for this user */
  defaultClient?: Maybe<IdName>;
  /** A list of user roles that apply to this user */
  roles?: Maybe<Array<Maybe<IdName>>>;
  /** The cost centre that this user belongs to */
  costCentre?: Maybe<IdName>;
  /** When OpenId Connect is enabled for a client, you need to tie the user to the issuer's internal user ID. Specificy them
   * in the oidcTags bucket
   */
  oidcTags?: Maybe<Scalars['JSONObject']>;
  /** An optional set of API keys generated by the user */
  apiKeys?: Maybe<Scalars['JSONObject']>;
  entity: EntityInfo;
  /** If there were any errors initiating the reset email the `resetError` property will be populated. */
  resetError?: Maybe<UnexpectedError>;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  name?: Maybe<Scalars['String']>;
  /** The user's email address, used to log into the system */
  emailAddress?: Maybe<Scalars['String']>;
  /** An optional mobile number used for SMS notifications */
  mobile?: Maybe<Scalars['String']>;
  /** The time zone identifier for the user (uses the tz datbase for timezones, see https://en.wikipedia.org/wiki/Tz_database) */
  timeZoneId?: Maybe<Scalars['String']>;
  /** The language code for this user */
  language?: Maybe<Scalars['String']>;
  /** The state of the user object */
  state?: Maybe<Scalars['String']>;
  /** notification settings */
  notifySettings?: Maybe<NotificationSettings>;
  /** The default client to load for this user */
  defaultClient?: Maybe<IdName>;
  /** A list of user roles that apply to this user */
  roles?: Maybe<Array<Maybe<IdName>>>;
  /** The cost centre that this user belongs to */
  costCentre?: Maybe<IdName>;
  /** When OpenId Connect is enabled for a client, you need to tie the user to the issuer's internal user ID. Specificy them
   * in the oidcTags bucket
   */
  oidcTags?: Maybe<Scalars['JSONObject']>;
  /** An optional set of API keys generated by the user */
  apiKeys?: Maybe<Scalars['JSONObject']>;
  entity: EntityInfo;
};

export type UserRoleCreateRequestInput = {
  /** The name of the user role */
  name?: Maybe<Scalars['String']>;
  /** An optional description for the role */
  description?: Maybe<Scalars['String']>;
  /** The api rights of the policy. Legacy only */
  legacyRights?: Maybe<Scalars['JSONObject']>;
  /** This applies to v2 API only */
  inlinePolicies?: Maybe<Array<Maybe<PolicyInput>>>;
  ownerId: Scalars['String'];
};

export type UserRoleListItem = {
  __typename?: 'UserRoleListItem';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The name of the user role */
  name?: Maybe<Scalars['String']>;
  /** An optional description for the role */
  description?: Maybe<Scalars['String']>;
  /** The date the user role was last modified */
  modifiedDate?: Maybe<Scalars['String']>;
};

/** A list of user role items */
export type UserRoleListResponse = {
  __typename?: 'UserRoleListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  /** The list of user role items in this response */
  items: Array<Maybe<UserRoleListItem>>;
};

export type UserRoleResponse = {
  __typename?: 'UserRoleResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The name of the user role */
  name?: Maybe<Scalars['String']>;
  /** An optional description for the role */
  description?: Maybe<Scalars['String']>;
  /** The api rights of the policy. Legacy only */
  legacyRights?: Maybe<Scalars['JSONObject']>;
  /** This applies to v2 API only */
  inlinePolicies?: Maybe<Array<Maybe<Policy>>>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type UserRoleUpdateRequestInput = {
  /** The name of the user role */
  name?: Maybe<Scalars['String']>;
  /** An optional description for the role */
  description?: Maybe<Scalars['String']>;
  /** The api rights of the policy. Legacy only */
  legacyRights?: Maybe<Scalars['JSONObject']>;
  /** This applies to v2 API only */
  inlinePolicies?: Maybe<Array<Maybe<PolicyInput>>>;
};

/** Information about a logged in user session */
export type UserSession = {
  __typename?: 'UserSession';
  /** The unique ID of this session */
  id: Scalars['String'];
  /** The user UUID */
  userId: Scalars['String'];
  /** The ISO date when the session was started */
  start: Scalars['String'];
  /** The expiry date of the session (can be extended with token refreshes) */
  expiry: Scalars['String'];
  /** The IP address of the user that initiated or last refreshed the session */
  ip: Scalars['String'];
  /** The hostname of the front-end from which the session was created */
  hostname: Scalars['String'];
  /** The browser user agent string of the user */
  agent: Scalars['String'];
  /** A flag indicating if the session has been invalidated */
  invalidated?: Maybe<Scalars['Boolean']>;
  /** True if this session is the same session from which this request was made */
  current: Scalars['Boolean'];
};

export type UserSessionResponse = {
  __typename?: 'UserSessionResponse';
  /** The JWT token is represented as a JSON Web Token (JWT). The token
   * contains claims about the identity of the authenticated user. Additional information is
   * also included in this token such as the default client id and user's full name and surname.
   * The id token expires one hour after the user authenticates. You should not process the
   * access token in your client or web API after it has expired.
   */
  idToken: Scalars['String'];
  /** The JWT token is represented as a JSON Web Token (JWT). The token
   * contains claims about the identity of the authenticated user. The access token expires one hour after the user
   * authenticates. You should not process the access token in your client or web API after it has expired.
   */
  accessToken: Scalars['String'];
  /** The JWT token is represented as a JSON Web Token (JWT). The token can be used to refresh a user's access and
   * id tokens. The access token expires one hour after the user authenticates and the refresh token is valid for 30 days.
   * You should not process the access token in your client or web API after it has expired.
   */
  refreshToken: Scalars['String'];
  /** An array of users that match the authentication information */
  users: Array<Maybe<AuthUserResponse>>;
};

export type UserUpdateRequestInput = {
  name?: Maybe<Scalars['String']>;
  /** The user's email address, used to log into the system */
  emailAddress?: Maybe<Scalars['String']>;
  /** An optional mobile number used for SMS notifications */
  mobile?: Maybe<Scalars['String']>;
  /** The time zone identifier for the user (uses the tz datbase for timezones, see https://en.wikipedia.org/wiki/Tz_database) */
  timeZoneId?: Maybe<Scalars['String']>;
  /** The language code for this user */
  language?: Maybe<Scalars['String']>;
  /** The state of the user object */
  state?: Maybe<Scalars['String']>;
  /** notification settings */
  notifySettings?: Maybe<NotificationSettingsInput>;
  /** The default client to load for this user */
  defaultClient?: Maybe<IdNameInput>;
  /** A list of user roles that apply to this user */
  roles?: Maybe<Array<Maybe<IdNameInput>>>;
  /** The cost centre that this user belongs to */
  costCentre?: Maybe<IdNameInput>;
  /** When OpenId Connect is enabled for a client, you need to tie the user to the issuer's internal user ID. Specificy them
   * in the oidcTags bucket
   */
  oidcTags?: Maybe<Scalars['JSONObject']>;
  /** The users password */
  password?: Maybe<Scalars['String']>;
};

export type VendorCreateRequestInput = {
  /** The display name of the company */
  name?: Maybe<Scalars['String']>;
  /** A list of custom ID's for this company. Can be queried using the
   * getClientByTag, getVendorByTag and getDistributorByTag methods.
   */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The company website (if available) */
  website?: Maybe<Scalars['String']>;
  /** The state of this company */
  state?: Maybe<Scalars['String']>;
  /** Entity specific metadata */
  entity?: Maybe<EntityInfoInput>;
  /** Soft limits that apply to this company. This includes overrides only, see `getLookups` for defaults. */
  limits?: Maybe<SoftLimitsInput>;
  /** A set of user defined flags */
  flags?: Maybe<Scalars['JSONObject']>;
  /** The theme that this company uses */
  theme?: Maybe<ThemeInfoInput>;
  /** Address information for this company */
  address?: Maybe<CompanyAddressInput>;
  /** The default timezone for this company */
  timeZoneId?: Maybe<Scalars['String']>;
  /** A set of custom fields for this company */
  customFields?: Maybe<Scalars['JSONObject']>;
  /** A list of custom domains to use for this company */
  domains?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The default language to user for this client. */
  language?: Maybe<Scalars['String']>;
  /** Support contact information that will be displayed to user of this company */
  support?: Maybe<CompanySupportDetailsInput>;
  /** Customized messages that are displayed to users of this company. */
  messages?: Maybe<CompanyMessagesInput>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: Maybe<Scalars['JSONObject']>;
  /** A set of features that are enabled for this distributor. */
  features?: Maybe<Scalars['JSONObject']>;
  /** The group to which this vendor belongs */
  group?: Maybe<Scalars['String']>;
  /** A list of map sets that are made available to the client's of this vendor. */
  availableMapSets?: Maybe<Array<Maybe<IdNameInput>>>;
  /** The default map set that has been defined for this vendor. It may be empty in
   * which case the distributor default will be used.
   */
  defaultMapSet?: Maybe<IdNameInput>;
  /** UUID's of the device types that this vendor is allowed to use. An empty list means all device types. */
  availableDeviceTypes?: Maybe<Array<Maybe<IdNameInput>>>;
  /** The configuration of a custom email server for this vendor */
  emailServer?: Maybe<EmailServerConfigurationInput>;
  /** [DEPRECATED] Use the listCompanyGroups operation instead */
  clientGroups?: Maybe<Array<Maybe<IdNameInput>>>;
  ownerId: Scalars['String'];
};

export type VendorDetailsUpdateRequestInput = {
  /** The theme that this company uses */
  theme?: Maybe<ThemeInfoInput>;
  /** Address information for this company */
  address?: Maybe<CompanyAddressInput>;
  /** The default timezone for this company */
  timeZoneId?: Maybe<Scalars['String']>;
  /** A set of custom fields for this company */
  customFields?: Maybe<Scalars['JSONObject']>;
  /** A list of custom domains to use for this company */
  domains?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The default language to user for this client. */
  language?: Maybe<Scalars['String']>;
  /** Support contact information that will be displayed to user of this company */
  support?: Maybe<CompanySupportDetailsInput>;
  /** Customized messages that are displayed to users of this company. */
  messages?: Maybe<CompanyMessagesInput>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: Maybe<Scalars['JSONObject']>;
  /** The configuration of a custom email server for this vendor */
  emailServer?: Maybe<EmailServerConfigurationInput>;
  /** [DEPRECATED] Use the listCompanyGroups operation instead */
  clientGroups?: Maybe<Array<Maybe<IdNameInput>>>;
};

/** Summary vendor information returned in lists. */
export type VendorListItem = {
  __typename?: 'VendorListItem';
  /** The vendor's unique ID */
  id: Scalars['String'];
  /** The vendor's display name */
  name: Scalars['String'];
  /** The vendor website (if available) */
  website: Scalars['String'];
  /** The vendor that owns this vendor */
  owner: IdName;
  /** The group to which this vendor belongs */
  group: Scalars['String'];
  /** The state of this vendor */
  state: Scalars['String'];
  /** A dictionary of child entity names and their counts, present if the `counts` parameter is supplied. */
  counts?: Maybe<Scalars['JSONObject']>;
  /** The date this vendor was created */
  creationDate: Scalars['String'];
  /** The date that this vendor was last modified */
  modifiedDate: Scalars['String'];
};

/** A list of vendor items */
export type VendorListResponse = {
  __typename?: 'VendorListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<VendorListItem>>;
};

export type VendorResponse = {
  __typename?: 'VendorResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** The display name of the company */
  name?: Maybe<Scalars['String']>;
  /** A list of custom ID's for this company. Can be queried using the
   * getClientByTag, getVendorByTag and getDistributorByTag methods.
   */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The company website (if available) */
  website?: Maybe<Scalars['String']>;
  /** The state of this company */
  state?: Maybe<Scalars['String']>;
  /** entity specific metadata */
  entity: EntityInfo;
  /** Soft limits that apply to this company. This includes overrides only, see `getLookups` for defaults. */
  limits?: Maybe<SoftLimits>;
  /** A set of user defined flags */
  flags?: Maybe<Scalars['JSONObject']>;
  /** The theme that this company uses */
  theme?: Maybe<ThemeInfo>;
  /** Address information for this company */
  address?: Maybe<CompanyAddress>;
  /** The default timezone for this company */
  timeZoneId?: Maybe<Scalars['String']>;
  /** A set of custom fields for this company */
  customFields?: Maybe<Scalars['JSONObject']>;
  /** A list of custom domains to use for this company */
  domains?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The default language to user for this client. */
  language?: Maybe<Scalars['String']>;
  /** Support contact information that will be displayed to user of this company */
  support?: Maybe<CompanySupportDetails>;
  /** Customized messages that are displayed to users of this company. */
  messages?: Maybe<CompanyMessages>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: Maybe<Scalars['JSONObject']>;
  /** A set of features that are enabled for this distributor. */
  features?: Maybe<Scalars['JSONObject']>;
  /** The group to which this vendor belongs */
  group?: Maybe<Scalars['String']>;
  /** A list of map sets that are made available to the client's of this vendor. */
  availableMapSets?: Maybe<Array<Maybe<IdName>>>;
  /** The default map set that has been defined for this vendor. It may be empty in
   * which case the distributor default will be used.
   */
  defaultMapSet?: Maybe<IdName>;
  /** UUID's of the device types that this vendor is allowed to use. An empty list means all device types. */
  availableDeviceTypes?: Maybe<Array<Maybe<IdName>>>;
  /** The configuration of a custom email server for this vendor */
  emailServer?: Maybe<EmailServerConfiguration>;
  /** [DEPRECATED] Use the listCompanyGroups operation instead */
  clientGroups?: Maybe<Array<Maybe<IdName>>>;
};

export type VendorUpdateRequestInput = {
  /** The display name of the company */
  name?: Maybe<Scalars['String']>;
  /** A list of custom ID's for this company. Can be queried using the
   * getClientByTag, getVendorByTag and getDistributorByTag methods.
   */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The company website (if available) */
  website?: Maybe<Scalars['String']>;
  /** The state of this company */
  state?: Maybe<Scalars['String']>;
  /** Entity specific metadata */
  entity?: Maybe<EntityInfoInput>;
  /** Soft limits that apply to this company. This includes overrides only, see `getLookups` for defaults. */
  limits?: Maybe<SoftLimitsInput>;
  /** A set of user defined flags */
  flags?: Maybe<Scalars['JSONObject']>;
  /** The theme that this company uses */
  theme?: Maybe<ThemeInfoInput>;
  /** Address information for this company */
  address?: Maybe<CompanyAddressInput>;
  /** The default timezone for this company */
  timeZoneId?: Maybe<Scalars['String']>;
  /** A set of custom fields for this company */
  customFields?: Maybe<Scalars['JSONObject']>;
  /** A list of custom domains to use for this company */
  domains?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The default language to user for this client. */
  language?: Maybe<Scalars['String']>;
  /** Support contact information that will be displayed to user of this company */
  support?: Maybe<CompanySupportDetailsInput>;
  /** Customized messages that are displayed to users of this company. */
  messages?: Maybe<CompanyMessagesInput>;
  /** A set of OpenId Connect issuers that are able to authenticate users on our behalf. */
  oidc?: Maybe<Scalars['JSONObject']>;
  /** A set of features that are enabled for this distributor. */
  features?: Maybe<Scalars['JSONObject']>;
  /** The group to which this vendor belongs */
  group?: Maybe<Scalars['String']>;
  /** A list of map sets that are made available to the client's of this vendor. */
  availableMapSets?: Maybe<Array<Maybe<IdNameInput>>>;
  /** The default map set that has been defined for this vendor. It may be empty in
   * which case the distributor default will be used.
   */
  defaultMapSet?: Maybe<IdNameInput>;
  /** UUID's of the device types that this vendor is allowed to use. An empty list means all device types. */
  availableDeviceTypes?: Maybe<Array<Maybe<IdNameInput>>>;
  /** The configuration of a custom email server for this vendor */
  emailServer?: Maybe<EmailServerConfigurationInput>;
  /** [DEPRECATED] Use the listCompanyGroups operation instead */
  clientGroups?: Maybe<Array<Maybe<IdNameInput>>>;
};

export type ZoneBounds = {
  __typename?: 'ZoneBounds';
  top: Scalars['Float'];
  left: Scalars['Float'];
  bottom: Scalars['Float'];
  right: Scalars['Float'];
};

export type ZoneCenter = {
  __typename?: 'ZoneCenter';
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type ZoneCreateRequestInput = {
  /** A unique name for this entity */
  name: Scalars['String'];
  /** The zone group to which this zone belongs */
  group?: Maybe<IdNameInput>;
  /** The cost centre to which this zone belongs */
  costCentre?: Maybe<IdNameInput>;
  /** The state of this zone */
  state?: Maybe<Scalars['String']>;
  /** The type of zone */
  zoneType?: Maybe<Scalars['String']>;
  /** A speed limit (in km/h) to apply to this zone. Any asset that enters this zone
   * will have the road speed limit overriden by the zone speed limit.
   */
  speed?: Maybe<Scalars['Float']>;
  /** For zone proximity alerts, specifiy a distance in km from the center of the zone. */
  radius?: Maybe<Scalars['Float']>;
  /** The points for the zone polygon/polyline. Only populated on `get*` routes. */
  points?: Maybe<Array<Maybe<ZonePointInput>>>;
  /** The tag entity */
  tag?: Maybe<Scalars['String']>;
  /** The client that owns this entity */
  ownerId: Scalars['String'];
};

export type ZoneGroupCreateRequestInput = {
  /** A unique name for this entity */
  name: Scalars['String'];
  /** The parent of this entity */
  parent?: Maybe<IdNameInput>;
  /** The client that owns this entity */
  ownerId: Scalars['String'];
};

export type ZoneGroupListItem = {
  __typename?: 'ZoneGroupListItem';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The parent of this entity */
  parent?: Maybe<IdName>;
  /** entity specific metadata */
  modifiedDate: Scalars['String'];
};

/** A list of zone group items */
export type ZoneGroupListResponse = {
  __typename?: 'ZoneGroupListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<ZoneGroupListItem>>;
};

export type ZoneGroupResponse = {
  __typename?: 'ZoneGroupResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The parent of this entity */
  parent?: Maybe<IdName>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type ZoneGroupUpdateRequestInput = {
  /** A unique name for this entity */
  name?: Maybe<Scalars['String']>;
  /** The parent of this entity */
  parent?: Maybe<IdNameInput>;
};

export type ZoneListItem = {
  __typename?: 'ZoneListItem';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A unique name for this zone */
  name?: Maybe<Scalars['String']>;
  /** The zone group to which this zone belongs */
  group?: Maybe<IdName>;
  /** The cost centre to which this zone belongs */
  costCentre?: Maybe<IdName>;
  /** The state of this zone */
  state?: Maybe<Scalars['String']>;
  /** The type of zone */
  zoneType?: Maybe<Scalars['String']>;
  /** A speed limit (in km/h) to apply to this zone. Any asset that enters this zone
   * will have the road speed limit overriden by the zone speed limit.
   */
  speed?: Maybe<Scalars['Float']>;
  /** For zone proximity alerts, specifiy a distance in km from the center of the zone. */
  radius?: Maybe<Scalars['Float']>;
  /** The points for the zone polygon/polyline. Only populated on `get*` routes. */
  points?: Maybe<Array<Maybe<ZonePoint>>>;
  /** The tag entity */
  tag?: Maybe<Scalars['String']>;
  /** The center of the zone (this is automatically calculated from the zone polygon. */
  center?: Maybe<ZoneCenter>;
  /** The rectangular bounds that contains the zone polygon. */
  bounds?: Maybe<ZoneBounds>;
  /** entity specific metadata */
  modifiedDate: Scalars['String'];
};

/** A list of zone  items */
export type ZoneListResponse = {
  __typename?: 'ZoneListResponse';
  /** total number of records in the result set */
  count: Scalars['Float'];
  /** the offset into the result set */
  offset: Scalars['Float'];
  /** the max number of items returned in the result set */
  limit: Scalars['Float'];
  items: Array<Maybe<ZoneListItem>>;
};

export type ZonePoint = {
  __typename?: 'ZonePoint';
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type ZonePointInput = {
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type ZoneResponse = {
  __typename?: 'ZoneResponse';
  /** The unique UUID of this entity */
  id: Scalars['String'];
  /** The company entity that owns this entity */
  owner: IdNameType;
  /** A unique name for this zone */
  name?: Maybe<Scalars['String']>;
  /** The zone group to which this zone belongs */
  group?: Maybe<IdName>;
  /** The cost centre to which this zone belongs */
  costCentre?: Maybe<IdName>;
  /** The state of this zone */
  state?: Maybe<Scalars['String']>;
  /** The type of zone */
  zoneType?: Maybe<Scalars['String']>;
  /** A speed limit (in km/h) to apply to this zone. Any asset that enters this zone
   * will have the road speed limit overriden by the zone speed limit.
   */
  speed?: Maybe<Scalars['Float']>;
  /** For zone proximity alerts, specifiy a distance in km from the center of the zone. */
  radius?: Maybe<Scalars['Float']>;
  /** The points for the zone polygon/polyline. Only populated on `get*` routes. */
  points?: Maybe<Array<Maybe<ZonePoint>>>;
  /** The tag entity */
  tag?: Maybe<Scalars['String']>;
  /** The center of the zone (this is automatically calculated from the zone polygon. */
  center?: Maybe<ZoneCenter>;
  /** The rectangular bounds that contains the zone polygon. */
  bounds?: Maybe<ZoneBounds>;
  /** entity specific metadata */
  entity: EntityInfo;
};

export type ZoneUpdateRequestInput = {
  /** A unique name for this zone */
  name?: Maybe<Scalars['String']>;
  /** The zone group to which this zone belongs */
  group?: Maybe<IdNameInput>;
  /** The cost centre to which this zone belongs */
  costCentre?: Maybe<IdNameInput>;
  /** The state of this zone */
  state?: Maybe<Scalars['String']>;
  /** The type of zone */
  zoneType?: Maybe<Scalars['String']>;
  /** A speed limit (in km/h) to apply to this zone. Any asset that enters this zone
   * will have the road speed limit overriden by the zone speed limit.
   */
  speed?: Maybe<Scalars['Float']>;
  /** For zone proximity alerts, specifiy a distance in km from the center of the zone. */
  radius?: Maybe<Scalars['Float']>;
  /** The points for the zone polygon/polyline. Only populated on `get*` routes. */
  points?: Maybe<Array<Maybe<ZonePointInput>>>;
  /** The tag entity */
  tag?: Maybe<Scalars['String']>;
};
export type AssetStateGetLocationFeedSubscriptionVariables = {
  client: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};

export type AssetStateGetLocationFeedSubscription = {
  __typename?: 'Subscription';
} & {
  getLocationFeed: Maybe<
    Array<
      Maybe<
        { __typename?: 'TelemetryStateResponse' } & Pick<
          TelemetryStateResponse,
          | 'date'
          | 'received'
          | 'active'
          | 'state'
          | 'telemetry'
          | 'counters'
          | 'io'
          | 'meta'
          | 'lastMovement'
        > & {
            object: Maybe<
              { __typename?: 'IdNameType' } & Pick<IdNameType, 'id' | 'name'>
            >;
            origin: Maybe<
              { __typename?: 'IdName' } & Pick<IdName, 'id' | 'name'>
            >;
            linked: Array<
              Maybe<
                { __typename?: 'IdNameType' } & Pick<
                  IdNameType,
                  'id' | 'name' | 'type'
                >
              >
            >;
            zones: Array<
              Maybe<
                { __typename?: 'IdNameType' } & Pick<
                  IdNameType,
                  'id' | 'name' | 'type'
                >
              >
            >;
            routes: Array<
              Maybe<{ __typename?: 'IdName' } & Pick<IdName, 'id' | 'name'>>
            >;
            spd: Maybe<
              { __typename?: 'SpeedLimits' } & Pick<
                SpeedLimits,
                'rd' | 'mx' | 'un'
              >
            >;
            location: { __typename?: 'TelemetryLocation' } & Pick<
              TelemetryLocation,
              | 'lon'
              | 'lat'
              | 'speed'
              | 'altitude'
              | 'heading'
              | 'accuracy'
              | 'address'
            >;
            trip: Maybe<
              { __typename?: 'TelemetryTrip' } & Pick<
                TelemetryTrip,
                | 'start'
                | 'startAddress'
                | 'startLon'
                | 'startLat'
                | 'distance'
                | 'lastLon'
                | 'lastLat'
              >
            >;
          }
      >
    >
  >;
};

export type FeedsGetTaskFeedSubscriptionVariables = {
  entity?: Maybe<Scalars['String']>;
};

export type FeedsGetTaskFeedSubscription = { __typename?: 'Subscription' } & {
  getTaskFeed: Maybe<
    Array<
      Maybe<
        { __typename?: 'TaskResponse' } & Pick<
          TaskResponse,
          'id' | 'type' | 'state' | 'data' | 'results'
        > & {
            date: { __typename?: 'TaskDates' } & Pick<
              TaskDates,
              'created' | 'updated' | 'completed'
            >;
            owner: { __typename?: 'IdNameType' } & Pick<
              IdNameType,
              'id' | 'name' | 'type'
            >;
            linked: Array<
              Maybe<
                { __typename?: 'IdNameType' } & Pick<
                  IdNameType,
                  'id' | 'name' | 'type'
                >
              >
            >;
            user: Maybe<
              { __typename?: 'IdName' } & Pick<IdName, 'id' | 'name'>
            >;
          }
      >
    >
  >;
};

export type FeedsGetRecentAlertFeedSubscriptionVariables = {
  client: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  asset?: Maybe<Scalars['String']>;
  alert?: Maybe<Scalars['String']>;
};

export type FeedsGetRecentAlertFeedSubscription = {
  __typename?: 'Subscription';
} & {
  getRecentAlertFeed: Maybe<
    Array<
      Maybe<
        { __typename?: 'EventResponse' } & Pick<
          EventResponse,
          | 'id'
          | 'eventDate'
          | 'modifiedDate'
          | 'eventClass'
          | 'eventType'
          | 'details'
        > & {
            owner: { __typename?: 'IdNameType' } & Pick<
              IdNameType,
              'id' | 'name' | 'type'
            >;
            alerts: Array<
              Maybe<
                { __typename?: 'IdNameType' } & Pick<
                  IdNameType,
                  'id' | 'name' | 'type'
                >
              >
            >;
            media: Array<
              Maybe<
                { __typename?: 'EventMedia' } & Pick<
                  EventMedia,
                  'input' | 'date' | 'mediaType' | 'filename' | 'error'
                > & {
                    user: Maybe<
                      { __typename?: 'IdName' } & Pick<IdName, 'id' | 'name'>
                    >;
                  }
              >
            >;
            handledBy: Maybe<
              { __typename?: 'EventHandled' } & Pick<EventHandled, 'date'> & {
                  user: { __typename?: 'IdName' } & Pick<IdName, 'id' | 'name'>;
                }
            >;
            comments: Array<
              Maybe<
                { __typename?: 'EventComment' } & Pick<
                  EventComment,
                  'date' | 'comment' | 'values'
                > & {
                    user: { __typename?: 'IdName' } & Pick<
                      IdName,
                      'id' | 'name'
                    >;
                  }
              >
            >;
          }
      >
    >
  >;
};

export type FeedsGetRecentAuditFeedSubscriptionVariables = {
  company: Scalars['String'];
  entity?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
};

export type FeedsGetRecentAuditFeedSubscription = {
  __typename?: 'Subscription';
} & {
  getRecentAuditFeed: Maybe<
    Array<
      Maybe<
        { __typename?: 'AuditEventResponse' } & Pick<
          AuditEventResponse,
          'id' | 'date' | 'eventClass' | 'eventType'
        > & {
            owner: { __typename?: 'IdNameType' } & Pick<
              IdNameType,
              'id' | 'name' | 'type'
            >;
            user: { __typename?: 'IdName' } & Pick<IdName, 'id' | 'name'>;
            entity: { __typename?: 'IdNameType' } & Pick<
              IdNameType,
              'id' | 'name' | 'type'
            >;
            changes: Array<
              Maybe<
                { __typename?: 'AuditEventChange' } & Pick<
                  AuditEventChange,
                  'action' | 'field' | 'name'
                > & {
                    oldValue: Maybe<
                      { __typename?: 'AuditEventKeyValue' } & Pick<
                        AuditEventKeyValue,
                        'key' | 'value'
                      >
                    >;
                    newValue: Maybe<
                      { __typename?: 'AuditEventKeyValue' } & Pick<
                        AuditEventKeyValue,
                        'key' | 'value'
                      >
                    >;
                  }
              >
            >;
          }
      >
    >
  >;
};

export type FeedsGetRecentEventFeedSubscriptionVariables = {
  client: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  asset?: Maybe<Scalars['String']>;
};

export type FeedsGetRecentEventFeedSubscription = {
  __typename?: 'Subscription';
} & {
  getRecentEventFeed: Maybe<
    Array<
      Maybe<
        { __typename?: 'EventResponse' } & Pick<
          EventResponse,
          | 'id'
          | 'eventDate'
          | 'modifiedDate'
          | 'eventClass'
          | 'eventType'
          | 'details'
        > & {
            owner: { __typename?: 'IdNameType' } & Pick<
              IdNameType,
              'id' | 'name' | 'type'
            >;
            origin: { __typename?: 'IdNameType' } & Pick<
              IdNameType,
              'id' | 'name' | 'type'
            >;
            alerts: Array<
              Maybe<
                { __typename?: 'IdNameType' } & Pick<
                  IdNameType,
                  'id' | 'name' | 'type'
                >
              >
            >;
            media: Array<
              Maybe<
                { __typename?: 'EventMedia' } & Pick<
                  EventMedia,
                  'input' | 'date' | 'mediaType' | 'filename' | 'error'
                > & {
                    user: Maybe<
                      { __typename?: 'IdName' } & Pick<IdName, 'id' | 'name'>
                    >;
                  }
              >
            >;
            handledBy: Maybe<
              { __typename?: 'EventHandled' } & Pick<EventHandled, 'date'> & {
                  user: { __typename?: 'IdName' } & Pick<IdName, 'id' | 'name'>;
                }
            >;
            comments: Array<
              Maybe<
                { __typename?: 'EventComment' } & Pick<
                  EventComment,
                  'date' | 'comment' | 'values'
                > & {
                    user: { __typename?: 'IdName' } & Pick<
                      IdName,
                      'id' | 'name'
                    >;
                  }
              >
            >;
          }
      >
    >
  >;
};

export type FeedsGetRecentNotificationFeedSubscriptionVariables = {
  limit?: Maybe<Scalars['Float']>;
};

export type FeedsGetRecentNotificationFeedSubscription = {
  __typename?: 'Subscription';
} & {
  getRecentNotificationFeed: Maybe<
    Array<
      Maybe<
        { __typename?: 'NotificationResponse' } & Pick<
          NotificationResponse,
          'id' | 'date' | 'sequence' | 'eventType' | 'text' | 'data'
        > & {
            owner: { __typename?: 'IdNameType' } & Pick<
              IdNameType,
              'id' | 'name' | 'type'
            >;
            user: { __typename?: 'IdName' } & Pick<IdName, 'id' | 'name'>;
          }
      >
    >
  >;
};

export type FeedsGetRecentTripFeedSubscriptionVariables = {
  client: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  asset?: Maybe<Scalars['String']>;
};

export type FeedsGetRecentTripFeedSubscription = {
  __typename?: 'Subscription';
} & {
  getRecentTripFeed: Maybe<
    Array<
      Maybe<
        { __typename?: 'TripResponse' } & Pick<
          TripResponse,
          'id' | 'tripType' | 'dateStart' | 'dateEnd'
        > & {
            linkedAssets: Array<
              Maybe<
                { __typename?: 'IdNameType' } & Pick<IdNameType, 'id' | 'name'>
              >
            >;
            start: { __typename?: 'TripLocation' } & Pick<
              TripLocation,
              'address'
            >;
            end: { __typename?: 'TripLocation' } & Pick<
              TripLocation,
              'address'
            >;
            stats: { __typename?: 'TripStats' } & Pick<TripStats, 'distance'>;
            rating: Maybe<
              { __typename?: 'TripRating' } & Pick<TripRating, 'score'>
            >;
          }
      >
    >
  >;
};

export const AssetStateGetLocationFeedDocument = gql`
  subscription AssetStateGetLocationFeed(
    $client: String!
    $offset: Float
    $limit: Float
    $sort: String
    $filter: String
  ) {
    getLocationFeed(
      client: $client
      offset: $offset
      limit: $limit
      sort: $sort
      filter: $filter
    ) {
      object {
        id
        name
      }
      origin {
        id
        name
      }
      date
      received
      active
      linked {
        id
        name
        type
      }
      zones {
        id
        name
        type
      }
      routes {
        id
        name
      }
      state
      spd {
        rd
        mx
        un
      }
      location {
        lon
        lat
        speed
        altitude
        heading
        accuracy
        address
      }
      telemetry
      counters
      io
      meta
      trip {
        start
        startAddress
        startLon
        startLat
        distance
        lastLon
        lastLat
      }
      lastMovement
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AssetStateGetLocationFeedGQL extends Apollo.Subscription<
  AssetStateGetLocationFeedSubscription,
  AssetStateGetLocationFeedSubscriptionVariables
> {
  document = AssetStateGetLocationFeedDocument;
}
export const FeedsGetTaskFeedDocument = gql`
  subscription FeedsGetTaskFeed($entity: String) {
    getTaskFeed(entity: $entity) {
      id
      date {
        created
        updated
        completed
      }
      owner {
        id
        name
        type
      }
      linked {
        id
        name
        type
      }
      user {
        id
        name
      }
      type
      state
      data
      results
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class FeedsGetTaskFeedGQL extends Apollo.Subscription<
  FeedsGetTaskFeedSubscription,
  FeedsGetTaskFeedSubscriptionVariables
> {
  document = FeedsGetTaskFeedDocument;
}
export const FeedsGetRecentAlertFeedDocument = gql`
  subscription FeedsGetRecentAlertFeed(
    $client: String!
    $limit: Float
    $asset: String
    $alert: String
  ) {
    getRecentAlertFeed(
      client: $client
      limit: $limit
      asset: $asset
      alert: $alert
    ) {
      id
      owner {
        id
        name
        type
      }
      eventDate
      modifiedDate
      alerts {
        id
        name
        type
      }
      eventClass
      eventType
      details
      media {
        input
        date
        user {
          id
          name
        }
        mediaType
        filename
        error
      }
      handledBy {
        date
        user {
          id
          name
        }
      }
      comments {
        date
        user {
          id
          name
        }
        comment
        values
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class FeedsGetRecentAlertFeedGQL extends Apollo.Subscription<
  FeedsGetRecentAlertFeedSubscription,
  FeedsGetRecentAlertFeedSubscriptionVariables
> {
  document = FeedsGetRecentAlertFeedDocument;
}
export const FeedsGetRecentAuditFeedDocument = gql`
  subscription FeedsGetRecentAuditFeed(
    $company: String!
    $entity: String
    $limit: Float
  ) {
    getRecentAuditFeed(company: $company, entity: $entity, limit: $limit) {
      id
      date
      owner {
        id
        name
        type
      }
      eventClass
      eventType
      user {
        id
        name
      }
      entity {
        id
        name
        type
      }
      changes {
        action
        field
        name
        oldValue {
          key
          value
        }
        newValue {
          key
          value
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class FeedsGetRecentAuditFeedGQL extends Apollo.Subscription<
  FeedsGetRecentAuditFeedSubscription,
  FeedsGetRecentAuditFeedSubscriptionVariables
> {
  document = FeedsGetRecentAuditFeedDocument;
}
export const FeedsGetRecentEventFeedDocument = gql`
  subscription FeedsGetRecentEventFeed(
    $client: String!
    $limit: Float
    $asset: String
  ) {
    getRecentEventFeed(client: $client, limit: $limit, asset: $asset) {
      id
      owner {
        id
        name
        type
      }
      origin {
        id
        name
        type
      }
      eventDate
      modifiedDate
      alerts {
        id
        name
        type
      }
      media {
        input
        date
        user {
          id
          name
        }
        mediaType
        filename
        error
      }
      eventClass
      eventType
      details
      handledBy {
        date
        user {
          id
          name
        }
      }
      comments {
        date
        user {
          id
          name
        }
        comment
        values
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class FeedsGetRecentEventFeedGQL extends Apollo.Subscription<
  FeedsGetRecentEventFeedSubscription,
  FeedsGetRecentEventFeedSubscriptionVariables
> {
  document = FeedsGetRecentEventFeedDocument;
}
export const FeedsGetRecentNotificationFeedDocument = gql`
  subscription FeedsGetRecentNotificationFeed($limit: Float) {
    getRecentNotificationFeed(limit: $limit) {
      id
      owner {
        id
        name
        type
      }
      user {
        id
        name
      }
      date
      sequence
      eventType
      text
      data
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class FeedsGetRecentNotificationFeedGQL extends Apollo.Subscription<
  FeedsGetRecentNotificationFeedSubscription,
  FeedsGetRecentNotificationFeedSubscriptionVariables
> {
  document = FeedsGetRecentNotificationFeedDocument;
}
export const FeedsGetRecentTripFeedDocument = gql`
  subscription FeedsGetRecentTripFeed(
    $client: String!
    $limit: Float
    $asset: String
  ) {
    getRecentTripFeed(client: $client, limit: $limit, asset: $asset) {
      id
      tripType
      dateStart
      dateEnd
      linkedAssets {
        id
        name
      }
      start {
        address
      }
      end {
        address
      }
      stats {
        distance
      }
      rating {
        score
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class FeedsGetRecentTripFeedGQL extends Apollo.Subscription<
  FeedsGetRecentTripFeedSubscription,
  FeedsGetRecentTripFeedSubscriptionVariables
> {
  document = FeedsGetRecentTripFeedDocument;
}
