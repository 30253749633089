import { Component, Input, HostBinding, Output, EventEmitter } from '@angular/core';
import { AnalyticsChartPieData } from './pie.service';

@Component({
    selector: 'key-analytics-chart-pie',
    templateUrl: './pie.component.html',
})
export class AnalyticsChartPieComponent {
    @Input() data: AnalyticsChartPieData;

    @Output() onMeasureSelection = new EventEmitter<string>();

    @HostBinding('class') hostClassnames = 'd-flex flex-column flex-1';

    constructor() {
    }
}
