import { Directive, Input, HostListener, OnDestroy, OnInit, EventEmitter, Output, HostBinding, SimpleChanges, OnChanges } from '@angular/core';
import { NavigationService } from 'app/services';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[key-page-nav-link]',
})
export class NavPageLinkDirective implements OnInit, OnDestroy, OnChanges {
    @Input() page: string;
    @Input() isParent: boolean;
    @Input() keepActiveOpen: boolean;
    @Input() child: string;

    @Output() clicked = new EventEmitter<'navigate' | 'open'>();

    @HostBinding('class.active') active = false;
    @HostBinding('class.open') open = false;

    private sub: Subscription;

    constructor(
        private navigationService: NavigationService
    ) {
    }

    ngOnInit() {
        this.sub = this.navigationService.activeRoot$.subscribe(root => {
            const segments = root.split('/');

            // if no child only check the page else evaluate child too and make sure we check the first segment and the whole root of the page
            this.active = (this.child ? this.child === segments[1] : true) && this.page && (segments[0] === this.page || root === this.page);

            // close open children if parent is not active
            this.setOpen(false);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.keepActiveOpen) {
            this.setOpen(this.keepActiveOpen ? this.open : false);
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    @HostListener('click') onClick() {
        if (this.page) {
            if (this.isParent) {
                this.setOpen(!this.open);
                this.clicked.emit('open');
            } else {
                this.navigationService.navigateToPage(this.page, this.child);
                this.clicked.emit('navigate');
            }
        }
    }

    setOpen(state: boolean) {
        this.open = this.keepActiveOpen && this.active || state;
    }
}
