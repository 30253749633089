import { Component, Input, TemplateRef, EventEmitter, Output, ElementRef, AfterViewInit, HostBinding } from '@angular/core';
import { IKuiDropdownMenuItem, KuiDropdownEvent, KuiDropdownPosition } from './dropdown.model';


@Component({
    selector: 'kui-dropdown-ref',
    templateUrl: './dropdown-ref.component.html',
})
export class KuiDropdownRefComponent implements AfterViewInit {
    get translate() {
        return this.position ? `translate(${this.position.x}px, ${this.position.y}px)` : 'translate(0, 0)';
    }

    // @ContentChild(TemplateRef, { static: true })

    @Input() customContent: TemplateRef<any>;
    @Input() customContentContext: { [key: string]: any };
    @Input() customContentLocation: 'TOP' | 'BOTTOM' = 'BOTTOM';
    @Input() menu: IKuiDropdownMenuItem[];
    @Input() position: KuiDropdownPosition;
    @HostBinding('class') @Input() classNames: string;

    @Output() onAction = new EventEmitter<KuiDropdownEvent>();
    @Output() onMounted = new EventEmitter<void>();

    constructor(
        public el: ElementRef
    ) {
    }

    ngAfterViewInit() {
        this.onMounted.emit();
    }

    dispatchToggleAction(item: IKuiDropdownMenuItem, event: MouseEvent) {
        item.checked = !item.checked;
        (event.target as any).checked = item.checked;
        this.onAction.emit({ item, event });
    }
}


