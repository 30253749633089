<div class="p-4 mx-2 d-flex">
    <div class="pr-4">
        <kui-icon name="check" fixedWidth="true" size="xxl"></kui-icon>
    </div>
    <div class="flex-1">
        <div>
            {{ 'MEDIA.VIDEO_REQUEST.MODAL.COMPLETE' | translate }}
            <div class="summary-list">
                <strong>{{ 'MEDIA.VIDEO_REQUEST.MODAL.FIELDS.ASSET' | translate }}</strong>
                <p>{{ asset().name }}</p>
                <strong>{{ 'MEDIA.VIDEO_REQUEST.MODAL.FIELDS.CAMERAS' | translate }}</strong>
                <div>
                    @for(camera of cameraList(); track camera) {
                        <p>{{camera}}</p>
                    }
                </div>
                <strong>{{ 'MEDIA.VIDEO_REQUEST.MODAL.FIELDS.VIDEO_START' | translate }}</strong>
                <p>{{ this.clipSettings().startDate| date: 'yyyy-MM-dd HH:mm:ss' }}</p>
                <strong>{{ 'MEDIA.VIDEO_REQUEST.MODAL.FIELDS.VIDEO_END' | translate }}</strong>
                <p>{{ this.clipSettings().endDate | date: 'yyyy-MM-dd HH:mm:ss' }}</p>
            </div>
            {{ 'MEDIA.VIDEO_REQUEST.MODAL.SUBMIT_REQUEST' | translate }}
        </div>
    </div>
</div>