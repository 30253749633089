<div class="d-flex flex-1"
    id="container"
    [class.fullscreen]="isFullscreen"
    #container
    (ngxResize)="onResized($event)">
    <kui-alert type="danger"
        class="mb-0 position-absolute w-100 key-alert-wrapper"
        style="z-index: 2000"
        *ngIf="errorMessage">
        <kui-icon name="ban"
            size="sm"></kui-icon>
        <span>{{errorMessage}}</span>
    </kui-alert>

    <div key-content-container
        class="map flex-1"></div>
    <kui-dropdown #contextMenuRef
        class="context-menu"
        [style]="'none'"
        [menu]="contextMenu"
        [parentElement]="containerRef.nativeElement">
    </kui-dropdown>

    <key-map-toolbar [controls]="activeControls"
        [isFullscreen]="isFullscreen"
        [baseLayers]="toolbarBaseLayers"
        [overlays]="overlays"
        [mapHeight]="hostHeight"
        [supportsMarkerClustering]="enableMarkerClustering"
        (enterFullscreen)="enterFullscreen()"
        (exitFullscreen)="exitFullscreen()"
        (zoomIn)="map.zoomIn()"
        (zoomOut)="map.zoomOut()"
        (toolbarToggled)="onToolbarToggled.emit($event)"
        (reset)="resetMap()">
    </key-map-toolbar>
    <div class="loading-overlay d-flex flex-stretch justify-content-center align-items-center"
        *ngIf="loading">
        <kui-loader></kui-loader>
    </div>
</div>