<kui-tabs (tabChanged)="handleTabChange($event)">
    @for (camera of cameras(); track camera.cameraId; let first = $first) {
        <kui-tab [title]="camera.cameraName" [isActive]="first">
            @if(camera.cameraName === activeCamera()) {   
                <key-livestream
                    class="flex-1 video-player"
                    [assetId]="camera.assetId"
                    [camera]="camera.cameraId"
                    duration="60">
                </key-livestream>
            }
        </kui-tab>
    }
</kui-tabs>