<div *ngIf="options?.exportOptions"
    class="form-group inline align-center border-bottom pb-3">
    <label class="form-label">
        {{ 'ANALYTICS.SETTINGS.EXPORT_AS' | translate }}
    </label>
    <div class="ml-auto">
        <button *ngFor="let exportType of options.exportOptions"
            class="ml-1"
            kui-button
            color="secondary"
            (click)="onExport.emit(exportType)">
            <kui-icon [name]="getExportIcon(exportType)"
                size="sm"
                class="mr-1"></kui-icon> {{ exportType | uppercase }}
        </button>
    </div>
</div>

<key-form-builder [form]="form"
    [values]="values"
    (onChange)="changeFormSetting($event)">
</key-form-builder>

<ng-container *ngIf="show.tableLayout">
    <h4 class="border-bottom pb-2 mb-4">{{ 'ANALYTICS.SETTINGS.TABLE_LAYOUT' | translate }}</h4>
    <div class="row layout-select">
        <label *ngFor="let layout of tableLayouts"
            class="col-4 layout-select__item">
            <div [innerHTML]="getLayoutIcon(layout)"
                [class.selected]="tableLayout === layout"></div>
            <div class="form-check-label mt-2">
                <input kui-radio="tableLayout"
                    [id]="'tableLayout' + layout"
                    [value]="layout"
                    (change)="changeLayout(layout)"
                    [checked]="tableLayout === layout">
                {{ 'ANALYTICS.SETTINGS.' + (layout | uppercase) + '_TABLE' | translate }}
            </div>
        </label>
    </div>
</ng-container>