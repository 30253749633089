import * as Bluebird from 'bluebird';
import { Component, ChangeDetectionStrategy, Input, OnDestroy, ViewChild, ChangeDetectorRef, OnChanges, Output, EventEmitter } from '@angular/core';
import { ReportDefinitionColumnOptions, IoTypeListItem, CustomFields } from '@key-telematics/fleet-api-client';
import { DragulaService } from 'ng2-dragula';
import { KuiTreeSelectComponent, KuiTreeSelectNode } from 'app/key-ui/tree-select/tree-select.component';
import { TreeNode } from '@ali-hm/angular-tree-component';
import { KuiDropdownComponent } from 'app/key-ui/dropdown/dropdown.component';
import { TranslateService } from '@ngx-translate/core';
import { AssetGroupingItem, AssetGroupingService, EventsService } from 'app/services';
import { AppService } from 'app/app.service';
import { skipWhile, take } from 'rxjs/operators';
import { ModalService } from '../../modal';


@Component({
    selector: 'key-reporting-builder-columns',
    templateUrl: './columns.component.html',
    styleUrls: ['./columns.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportingBuilderColumnsComponent implements OnDestroy, OnChanges {

    @Input() columns: ReportDefinitionColumnOptions[];
    @Input() availableColumns: ReportDefinitionColumnOptions[];
    @Input() autoColumns: string[];
    @Output() onUpdateColumns = new EventEmitter<ReportDefinitionColumnOptions[]>();

    @ViewChild(KuiDropdownComponent, { static: true }) filterDropdown: KuiDropdownComponent;

    treeNodes: KuiTreeSelectNode[];
    ioTypes: IoTypeListItem[];
    ioTypeConfig: { [key: string]: { prefix?: string, types?: string[], type?: string } } = {
        io_start: { prefix: 'ios_', types: ['digital_input', 'digital_output', 'analog_input', 'temperature_input', 'can_input', 'counter_input'] },
        io_end: { prefix: 'ioe_', types: ['digital_input', 'digital_output', 'analog_input', 'temperature_input', 'can_input', 'counter_input'] },
        io_min: { prefix: 'iom_', types: ['analog_input', 'temperature_input', 'can_input'] },
        io_max: { prefix: 'iox_', types: ['analog_input', 'temperature_input', 'can_input'] },
        io_average: { prefix: 'ioa_', types: ['analog_input', 'temperature_input', 'can_input'] },
        io_total: { prefix: 'iot_', types: ['counter_input'] },
        io_rate: { prefix: 'ior_', types: ['counter_input'] },
        digital_input: { prefix: 'io_', type: 'digital_input' },
        digital_output: { prefix: 'io_', type: 'digital_output' },
        analog_input: { prefix: 'io_', type: 'analog_input' },
        temperature_input: { prefix: 'io_', type: 'temperature_input' },
        can_input: { prefix: 'io_', type: 'can_input' },
        counter_input: { prefix: 'io_', type: 'counter_input' },
    };

    constructor(
        public app: AppService,
        public grouping: AssetGroupingService,
        private i18n: TranslateService,
        private eventsService: EventsService,
        private dragulaService: DragulaService,
        private ref: ChangeDetectorRef,
        private modal: ModalService
    ) {

        this.dragulaService.createGroup('columns', {
            removeOnSpill: true,
        });
    }

    ngOnDestroy() {
        this.dragulaService.destroy('columns');
    }

    ngOnChanges() {
        this.buildTree().then(nodes => {
            this.treeNodes = nodes;
            this.ref.markForCheck();
        });
    }

    sortColumns(columns: ReportDefinitionColumnOptions[]) {
        this.columns = columns;
        this.onUpdateColumns.emit(columns);
    }

    removeColumn(column: ReportDefinitionColumnOptions) {
        const idx = this.columns.indexOf(column);
        if (idx !== -1) {
            this.columns.splice(idx, 1);
            this.ref.markForCheck();
        }
    }

    resetColumns() {
        this.columns.splice(0, this.columns.length);
        this.availableColumns.filter(x => x.def).forEach(column => {
            this.columns.push(column);
        });
    }

    async nodeSelected(event: { eventName: string, node: TreeNode }, dropdown: KuiDropdownComponent, tree: KuiTreeSelectComponent) {
        if (event.eventName === 'activate') {
            const { data, id } = event.node.data;
            if (data && data.column) {
                dropdown.toggle();
                tree.clearSelection();

                if (id === 'custom') {
                    // let user set an expression before adding custom column
                    const update = await this.updateColumnExpression({
                        ...data.column,
                        // give each new custom column a unique id like custom_1, custom_2 etc.
                        id: id + '_' + (this.columns.filter(x => x.id.includes('custom_')).length + 1),
                    });
                    if (update) {
                        this.columns.push(update);
                    }
                } else {
                    // only add the column if it hasn't already been added before
                    if (!this.columns.find(x => x.id === data.column.id)) {
                        this.columns.push(data.column);
                    }
                }

                this.ref.markForCheck();
            }
        }
    }

    buildTree(): Bluebird<KuiTreeSelectNode[]> {

        const nodes: KuiTreeSelectNode[] = [];

        const node: KuiTreeSelectNode = {
            id: 'standard',
            name: this.i18n.instant('REPORTING.BUILDER.COLUMNS.TREE.STANDARD'),
            children: this.availableColumns.map(column => ({
                id: column.id,
                name: column.title,
                children: null,
                data: { column },
            })),
        };
        nodes.push(node);

        return Bluebird.map(this.autoColumns || [], autoColumn => {

            switch (autoColumn) {
                case 'digital_input':
                case 'digital_output':
                case 'analog_input':
                case 'temperature_input':
                case 'can_input':
                case 'counter_input':
                case 'digital_input':
                case 'io_start':
                case 'io_end':
                case 'io_min':
                case 'io_max':
                case 'io_average':
                case 'io_total':
                case 'io_rate':
                    return this.getIoNode(autoColumn, autoColumn.startsWith('io_'));
                case 'event_count':
                    return this.getEventCountNode();
                case 'linked_asset':
                    return this.getLinkedAssetsNode();
                case 'custom_fields_asset':
                case 'custom_fields_device':
                case 'custom_fields_event':
                case 'custom_fields_asset_tag':
                case 'custom_fields_sim_card':
                    return this.getCustomFieldsNode(autoColumn);
                case 'custom':
                    return this.getCustomNode();
                default:
                    return null;
            }

        }, { concurrency: 1 }).then(items => {
            return nodes.concat(items.filter(x => x));
        });


    }

    getIoTypes(): Promise<IoTypeListItem[]> {
        if (this.ioTypes) {
            return Promise.resolve(this.ioTypes);
        } else {
            return this.app.api.entities.listIoTypes(this.app.client.id, true, 0, 300, 'name', 'state=active').then(result => {
                this.ioTypes = result.items;
                return this.ioTypes;
            });
        }
    }

    getIoNode(autoColumn: string, group: boolean): Promise<KuiTreeSelectNode> {

        const ioTypeToNode = (config: { prefix?: string } , ioType: IoTypeListItem, name: string) => {
            return {
                id: config.prefix + ioType.id,
                name: ioType.name,
                children: null,
                data: {
                    column: {
                        id: config.prefix + ioType.id,
                        title: group ? `${name} ${ioType.name}` : ioType.name,
                        width: 40,
                        align: 'Right',
                        def: false,
                        required: false,
                    },
                },
            };
        }

        const config = this.ioTypeConfig[autoColumn];
        if (config) {
            const name = this.i18n.instant(`REPORTING.BUILDER.COLUMNS.TREE.${autoColumn.toUpperCase()}`);
            return this.getIoTypes().then(ioTypes => {
                const allIoTypes = ioTypes.filter(x => group ? config.types.indexOf(x.type) !== -1 : config.type === x.type);
                const vendorIoTypes = allIoTypes.filter(f => f.owner?.type === 'vendor');
                const clientIoTypes = allIoTypes.filter(f => f.owner?.type === 'client');

                return {
                    id: autoColumn,
                    name: name,
                    children: [
                        vendorIoTypes?.length > 0 && {
                            id: config.prefix + 'vendor',
                            name: 'Vendor',
                            children: vendorIoTypes.map(ioType => ioTypeToNode(config, ioType, name))
                        },
                        clientIoTypes?.length > 0 && {
                            id: config.prefix + 'client',
                            name: 'Client',
                            children: clientIoTypes.map(ioType => ioTypeToNode( config, ioType, name))
                        }
                    ].filter(x => x)
                };
            });
        } else {
            return Promise.resolve(null);
        }
    }


    getEventCountNode(): Promise<KuiTreeSelectNode> {
        const eventTypes = this.eventsService.getEventTypes();
        return Promise.resolve({
            id: 'event_count',
            name: this.i18n.instant('REPORTING.BUILDER.COLUMNS.TREE.EVENT_COUNT'),
            children: eventTypes.map(eventType => {
                return {
                    id: eventType.id,
                    name: eventType.name,
                    children: null,
                    data: {
                        column: {
                            id: `evt_cnt_${eventType.id}`,
                            title: this.i18n.instant('REPORTING.BUILDER.COLUMNS.TREE.EVENT_COUNT_TITLE', { eventName: eventType.name }),
                            width: 30,
                            align: 'Center',
                            def: false,
                            required: false,
                        },
                    },
                };
            }),
        });

    }

    getLinkedAssetsNode(): Promise<KuiTreeSelectNode> {
        return this.grouping.getAssetTypes().then(assetTypes => {
            return {
                id: 'linked',
                name: this.i18n.instant('REPORTING.BUILDER.COLUMNS.TREE.LINKED'),
                children: assetTypes.map(assetType => {
                    const assetTypeName = this.i18n.instant(`SHARED.ASSET_TYPES.${assetType.name.toUpperCase().replace(/ /g, '_')}`);
                    return {
                        id: assetType.id,
                        name: assetTypeName,
                        children: null,
                        data: {
                            column: {
                                id: `linked_asset_${assetType.id}`,
                                title: assetTypeName,
                                width: 100,
                                align: 'Left',
                                def: false,
                                required: false,
                            },
                        },
                    };
                }),
            };
        });
    }

    
    isCustomFieldValid(autocolumn: string, x: string, assetTypes: AssetGroupingItem[]): boolean {
        return autocolumn === 'custom_fields_asset' && (x === 'asset' || assetTypes.map(at => at.id).indexOf(x) !== -1)
         || autocolumn === 'custom_fields_event' &&  x === 'event'
         || autocolumn === 'custom_fields_device' && x === 'device'
         || autocolumn === 'custom_fields_sim_card' && x === 'simcard'
         || autocolumn === 'custom_fields_asset_tag' && x === 'assettag';
    }

    getCustomFieldsNode(autoColumn: string): Promise<KuiTreeSelectNode> {

        const mapFields = (fields: CustomFields, key: string, label?: string) => {
            
            label = label ? ` (${label})` : '';

            return (fields[key] || []).map(field => {
                return {
                    id: field.id,
                    title: field.title,
                    name: field.title + label,
                    type: key,
                    owner: field.owner
                };
            });
        };

        const getFieldsFromCompany = (type: 'client' | 'vendor', customFields: CustomFields, column: string, assetTypes: AssetGroupingItem[]) => {

            if (!customFields) { return []; }

            return  Object.keys(customFields).filter(x => this.isCustomFieldValid(column, x, assetTypes)).map(key => {
                const assetType = assetTypes.find(x => x.id === key) ;
                if (assetType) {
                    const assetTypeLabel = this.i18n.instant(`SHARED.ASSET_TYPES.${assetType.name.toUpperCase().replace(/ /g, '_')}`);
                    return mapFields(customFields, key, assetTypeLabel);
                } else if (key === 'asset') {
                    return mapFields(customFields, key, this.i18n.instant(`SHARED.ASSET_TYPES.ASSET`));
                }
                return mapFields(customFields, key);
            }).reduce((p, c) => p.concat(c), []).filter(f => f.owner === type)
        };

        const fieldToNode = (field: {id: string, title: string, name: string, type: string, owner: string}) => {
            let type: string;
            switch (field.type) {
                case 'event':
                    type = `event_custom_field_${field.id}`;
                    break;
                case 'device':
                    type = `device_custom_field_${field.id}`;
                    break;
                case 'assettag':
                    type = `asset_tag_custom_field_${field.id}`;
                    break;
                case 'simcard':
                    type = `sim_card_custom_field_${field.id}`;
                    break;
                default:
                    type = `custom_field_${field.id}`;
            }

            return {
                id: field.id,
                name: field.name,
                children: null,
                data: {
                    column: {
                        id: type,
                        title: field.title,
                        width: 80,
                        align: 'Left',
                        def: false,
                        required: false,
                    },
                },
            };
        };

        return this.app.client$.pipe(skipWhile(client => !client), take(1)).toPromise().then(async client => {

            const assetTypes = await this.grouping.getAssetTypes();
           
            const clientFields = getFieldsFromCompany('client', client.customFields, autoColumn, assetTypes);
            const vendorFields = getFieldsFromCompany('vendor', client.customFields, autoColumn, assetTypes);
            const fields = [...clientFields, ...vendorFields];

            if (fields.length > 0) {
                return {
                    id: 'custom_fields',
                    name: this.i18n.instant('REPORTING.BUILDER.COLUMNS.TREE.CUSTOM_FIELDS'),
                    children: [
                        vendorFields?.length > 0 && {
                            id: 'vendor',
                            name: this.i18n.instant('ADMIN.ENTITIES.VENDOR'),
                            children: vendorFields.map(fieldToNode)
                        },
                        clientFields?.length > 0 && {
                            id: 'client',
                            name: this.i18n.instant('ADMIN.ENTITIES.CLIENT'),
                            children: clientFields.map(fieldToNode)
                        }
                    ].filter(x => x)
                };
            }
            return null;
        });

    }

    async getCustomNode(): Promise<KuiTreeSelectNode> {
        const name = this.i18n.instant('REPORTING.BUILDER.COLUMNS.TREE.CUSTOM');
        return {
            id: 'custom',
            name,
            children: null,
            data: {
                column: {
                    id: 'custom',
                    title: name,
                    width: 100,
                    align: 'Left',
                    def: false,
                    required: false,
                },
            },
        };
    }

    async updateColumnExpression(column: ReportDefinitionColumnOptions): Promise<ReportDefinitionColumnOptions> {
        const translate = key => this.i18n.instant(`REPORTING.BUILDER.COLUMNS.MODAL.CUSTOM.${key}`);
        const res = await this.modal.form({
            groups: [{
                name: translate('TITLE'),
                fields: [{ type: 'memo', id: 'expression', title: translate('FIELD_EXPRESSION.TITLE'), description: translate('FIELD_EXPRESSION.DESC'), required: true }],
            }],
        }, { expression: column.expression });

        if (!res) { return null; }
        column.expression = res.expression;
        return column;
    }
}
