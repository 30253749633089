import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KuiAlertComponent } from './alert.component';
import { KuiAlertPanelComponent } from './alert-panel.component';
import { KuiIconModule } from 'app/key-ui/icon';
import { TranslateModule } from '@ngx-translate/core';
import { KuiButtonModule } from '../button';

@NgModule({
  imports: [CommonModule, KuiIconModule, TranslateModule, KuiButtonModule],
  exports: [KuiAlertComponent, KuiAlertPanelComponent],
  declarations: [KuiAlertComponent, KuiAlertPanelComponent],
})
export class KuiAlertModule { }
