import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild, computed, input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { KeyFormBuilderComponent } from 'app/shared/components/form-builder';
import { KeyFormBuilderModule } from 'app/shared/components/form-builder/form-builder.module';
import { VideoAsset } from '../asset-selection/asset-selection.component';
import { Validate } from '../validate.model';

@Component({
    selector: 'key-camera-selection',
    templateUrl: 'camera-selection.component.html',
    styleUrls: ['camera-selection.component.scss'],
    imports: [CommonModule, TranslateModule, KeyFormBuilderModule],
    standalone: true,
})
export class CameraSelectionComponent implements OnInit, Validate {

    asset = input.required<VideoAsset>();

    @Output() cameraSelected = new EventEmitter<{ cameras: string[] }>();

    @ViewChild(KeyFormBuilderComponent, { static: true }) formBuilder: KeyFormBuilderComponent;

    form = computed(() => {
        return {
            groups: [{
                fields: [
                    {
                        id: 'cameras',
                        type: 'checklist',
                        title: this.i18n.instant('MEDIA.VIDEO_REQUEST.MODAL.FIELDS.CAMERAS'),
                        required: true,
                        values: this.asset()?.cameras
                    },
                ]
            }]
        };
    });

    formValues = computed(() => {
        return {
            cameras: [], // default to none
        }
    });

    constructor(
        public i18n: TranslateService,
    ) { }

    ngOnInit() { }

    handleSettingsChange() {
        this.cameraSelected.emit(this.formValues());
    }

    isValid(): boolean {
        return this.formBuilder.validate();
    }

}
