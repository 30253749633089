import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { KuiSelectComponent } from './select.component';

@NgModule({
  imports: [CommonModule, FormsModule, NgxBootstrapMultiselectModule],
  exports: [KuiSelectComponent],
  declarations: [KuiSelectComponent],
})
export class KuiSelectModule {}
