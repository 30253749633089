
import { Component, Input, ChangeDetectorRef, OnChanges, AfterViewInit, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MediaInfoResponse } from '@key-telematics/fleet-api-client';
import { AppService } from 'app/app.service';
import { TranslateService } from '@ngx-translate/core';
import { getDateFromNow } from '../../admin/entities/utils';

@Component({
    selector: 'key-video',
    templateUrl: './video.component.html',
    styleUrls: ['video.component.scss'],

})
export class VideoComponent implements OnChanges, AfterViewInit, OnDestroy {

    @Input() asset: string;
    @Input() video: { url: string, filename: string, type: string, isDeleted: boolean };
    @Input() muted = true;
    @Input() controls = false;
    @Input() download = true;

    @Output() onLoaded = new EventEmitter<VideoComponent>();
    @Output() onStatusChanged = new EventEmitter<VideoComponent>();
    @Output() onProgress = new EventEmitter<VideoComponent>();
    @Output() infoUpdated = new EventEmitter<MediaInfoResponse>();

    loaded = false;
    status: MediaInfoResponse;
    requesterName: string;
    requesterDate: string;
    requesterType: string;
    changedByUser: string;
    error: string;
    videoElement: HTMLVideoElement;
    isPlaying = false;
    progress = 0;
    currentTime: string;
    durationTime: string;
    timer: any;

    get duration(): number {
        return this.videoElement.duration;
    }

    constructor(
        private el: ElementRef,
        private app: AppService,
        private i18l: TranslateService,
        private ref: ChangeDetectorRef
    ) {
    }

    ngOnChanges() {
        if (this.videoElement) {
            this.videoElement.load(); // force any changes to the video element to load
        }
        if (this.video && this.video.isDeleted) {
            this.handleMediaError('deleted');
        }
    }

    ngOnDestroy() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    ngAfterViewInit() {

        this.videoElement = this.el.nativeElement.querySelectorAll('video')[0];
        this.videoElement.controls = this.controls;

        if (this.videoElement) {

            this.videoElement.onplaying = () => {
                this.isPlaying = true;
                this.onStatusChanged.emit(this);
            };

            this.videoElement.onpause = () => {
                this.isPlaying = false;
                this.onStatusChanged.emit(this);
            };

            this.videoElement.onended = () => {
                this.isPlaying = false;
                this.onStatusChanged.emit(this);
            };

            this.videoElement.ontimeupdate = () => {
                this.progress = 100 * this.videoElement.currentTime / this.videoElement.duration;
                this.currentTime = this.formatTime(Math.ceil(this.videoElement.currentTime));
                this.durationTime = this.formatTime(Math.ceil(this.videoElement.duration));
                this.onProgress.emit(this);
            };

            this.videoElement.addEventListener('error', this.handleMediaError.bind(this), true);
            this.videoElement.onerror = this.handleMediaError.bind(this);

            this.videoElement.onloadeddata = () => {
                // videos must be muted on iOS in order to play multiple videos. on all other OS's we will play the sound of the first video only to avoid a mishmash of sounds.
                // NOTE: the muted attr can't be assigned in the template because of the dynamic nature of angular templates... some further reading on the subject: https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
                // this.videoElement.muted = this.matchMedia.deviceFeatures.os === DeviceFeaturesOs.IOS || index > 0;
                this.videoElement.muted = this.muted;
                this.loaded = true;
                this.onLoaded.emit(this);
                this.ref.markForCheck();
            };

        }
        this.ref.markForCheck();


    }

    handleMediaError(err: any): boolean {

        console.error('video.onError', err);

        this.loaded = true;
        this.onLoaded.emit(this);

        if (this.video) {

            const updateMediaStatus = async () => {

                try {
                    const info = await this.app.api.media.getMediaInfo(this.asset, this.video.filename);
                    if (info.status !== 'ready') {
                        this.status = info;
                        const by = this.status.status && this.status.status + 'By';
                        this.changedByUser = ['deleted', 'canceled'].includes(this.status?.status) ? this.status?.data[by].name : '';
                        if (info.requester) {
                            this.requesterName = info.requester.type !== 'device' ? info.requester.name : '';
                            this.requesterType = this.i18l.instant('ADMIN.ENTITIES.' + info.requester.type.toUpperCase()).toLowerCase();
                            this.requesterDate = getDateFromNow(info.entity.creationDate);
                        }
                        this.error = null;

                        if (info.status !== 'failed') {
                            // We don't need to retry if the request failed...
                            this.timer = setTimeout(() => updateMediaStatus(), 10e3); // update again in a little bit
                        }
                    } else {
                        this.status = null;
                        this.error = null;

                        // this forces the video to get recreated and play, gonna need some more testing with dual camera systems
                        this.video = {
                            ...this.video,
                        };
                        setTimeout(() => this.ngAfterViewInit(), 100);
                    }
                    this.infoUpdated.emit(info);
                } catch (err) {
                    this.status = null;
                    if (err.name === 'ForbiddenError') {
                        this.error = this.i18l.instant('MEDIA.ERRORS.FORBIDDEN');
                    } else {
                        this.error = this.i18l.instant('MEDIA.ERRORS.GENERIC');
                    }
                } finally {
                    this.ref.markForCheck();
                }
            };
            updateMediaStatus();
            return true;

        }

        this.error = this.i18l.instant('MEDIA.ERRORS.GENERIC');
        return false;

    }

    formatTime(time: number) {
        time = Math.ceil(time);
        const seconds = time % 60;
        const minutes = Math.floor(time / 60 % 60);
        function addZero(number) {
            return number.toString().length > 1 ? number : '0' + number;
        }
        return addZero(minutes) + ':' + addZero(seconds);
    }

    play() {
        this.videoElement.play();
    }

    pause() {
        this.videoElement.pause();
    }

    seek(type: 'start' | 'end' | 'forward' | 'backward' | 'exact', value?: number) {

        switch (type) {
            case 'start':
                this.videoElement.currentTime = 0;
                break;
            case 'end':
                this.videoElement.currentTime = this.videoElement.duration;
                break;
            case 'forward':
                this.videoElement.currentTime += value;
                break;
            case 'backward':
                this.videoElement.currentTime -= value;
                break;
            case 'exact':
                this.videoElement.currentTime = value;
                break;
        }
    }



}


