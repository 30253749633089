import { ElementRef, Renderer2, Directive } from '@angular/core';

@Directive({
  selector: '[kui-checkbox]',
})
export class KuiCheckbox {
  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.renderer.addClass(this.el.nativeElement, 'form-check-input');
    this.renderer.setAttribute(this.el.nativeElement, 'type', 'checkbox');
  }
}
