<kui-input-group *ngIf="hasSearch"
    [cleanAddons]="true"
    class="filter">
    <input kui-input
        [placeholder]="'FORMS.CHECKLIST.SEARCH_LIST' | translate"
        [ngModel]="searchTerm"
        (ngModelChange)="debouncedDoSearch($event)">
    <kui-icon kui-input-group-addon
        *ngIf="!searchTerm && !searching"
        name="search"></kui-icon>
    <kui-icon kui-input-group-addon
        *ngIf="searchTerm && !searching"
        kui-action
        name="times"
        (click)="doSearch(null)"></kui-icon>
    <kui-loader *ngIf="searching"
        kui-input-group-addon
        size="small"></kui-loader>
</kui-input-group>

<div class="position-relative"
    [class.disabled]="field.readonly">
    <kui-dropdown *ngIf="!field.readonly"
        class="dropdown pt-2"
        [style]="'none'"
        [title]="'FORMS.CHECKLIST.MULTI_SELECTION' | translate"
        [menu]="multiSelectDropdownMenu">
        <kui-icon kui-action
            class="p-2"
            name="ellipsis-v"></kui-icon>
    </kui-dropdown>

    <div class="checklist-input border-width p-2 overflow-auto"
        style="max-height: 200px"
        [class.border-danger]="error || (field.required && touched && checkedCount === 0)">

        <div *ngIf="!items.length"
            class="pl-1 muted">
            {{ 'FORMS.CHECKLIST.EMPTY' | translate }}
        </div>

        <div *ngFor="let item of items"
            [style.margin-left]="(item.indent * 15) + 'px'"
            [style.align-items]="'start'"
            class="pl-1 d-flex">
            <!-- the kui-checkbox doesn't work with overflow auto, so used default input checkbox here -->
            <div [style.padding-top]="'0.1rem !important'">
                <input type="checkbox"
                    [disabled]="field.readonly || item?.readonly === 'disabled' || item?.readonly === 'checked'"
                    [checked]="item?.readonly === 'checked' || isChecked(item.key)"
                    [indeterminate]="cascade && item.readonly === 'indeterminate' && !isChecked(item.key)"
                    (change)="checkItem($event, item.key)"
                    class="mr-1"
                    [id]="uniqueId + item.key">
            </div>
            <label [for]="uniqueId + item.key"
                class="ml-1 mb-0 flex-1">
                {{ item.value }}
            </label>

        </div>

    </div>

    <div *ngIf="field.required && touched && checkedCount === 0"
        class="invalid-feedback d-block">
        <div>{{ 'FORMS.SHARED.REQUIRED' | translate:{ field: field.title } }}</div>
    </div>
    <div *ngIf="error"
        class="invalid-feedback d-block">
        <div>{{ error }}</div>
    </div>
</div>