import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MeasurementUnitsService } from 'app/services';
import { MapEventItem } from 'app/shared/components';
import { getAndTranslateEventText } from 'app/shared/components/feed/event-feed/event-translation-dictionary';

@Pipe({
    name: 'eventDisplay',
    standalone: true,
})
export class EventDisplayPipe implements PipeTransform {

    constructor(        
        private i18n: TranslateService,
        private units: MeasurementUnitsService) {}

    transform(event: MapEventItem): string {
        return getAndTranslateEventText('title', event, this.i18n, this.units);
    }

}
