import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { KuiModule } from 'app/key-ui';

import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from 'app/shared/pipes/pipe.module';
import { KeyListingModule } from '../../listing/listing.module';
import { KeySearchResultsListModule } from '../../search-results-list/search-results-list.module';
import { KeyMapOptionsModule } from '../map-options/map-options.module';
import { MapToolbarComponent } from './map-toolbar.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule.forChild(),
        KuiModule,
        KeySearchResultsListModule,
        KeyListingModule,
        PipeModule,
        KeyMapOptionsModule
    ],
    exports: [
        MapToolbarComponent
    ],
    declarations: [
        MapToolbarComponent
    ],
})
export class KeyMapToolbarModule { }
