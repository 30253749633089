import * as Bluebird from 'bluebird';
import { Component, Input, OnInit, ChangeDetectorRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormBuilderField, BaseFormBuilderFieldComponent } from 'app/shared/components/form-builder';
import { AppService } from 'app/app.service';
import { AssetGroupingService } from 'app/services';
import { KuiTreeSelectNode, KuiTreeSelectComponent } from 'app/key-ui/tree-select/tree-select.component';
import { TreeNode } from '@ali-hm/angular-tree-component';
import { KuiDropdownComponent } from 'app/key-ui/dropdown/dropdown.component';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY_GUID } from '../../admin/entities/utils';
import { ICONS } from '../../admin/entities/icons';

interface TagItem {
    id: string;
    name: string;
}

@Component({
    selector: 'key-form-builder-label-filter-field',
    templateUrl: './labelfilter.component.html',
    styleUrls: ['./labelfilter.component.scss'],
})
export class KeyFormBuilderLabelFilterFieldComponent implements OnInit, BaseFormBuilderFieldComponent {

    @Input() field: FormBuilderField;
    @Input() values: { [key: string]: any };
    @Input() error: string; // set this error value externally to have the default error highligh and display kick in

    touched = false;
    dirty = false;
    isLoading = false;
    overlay: boolean;
    ownerId: string;
    entity?: string;
    exclusive = false;

    selectedItems: TagItem[] = [];
    autoCompleteItems: TagItem[] = [];

    @Output() onChange: EventEmitter<{ value: TagItem[], dirty: boolean }> = new EventEmitter();

    groups: Bluebird<TagItem[]>;
    treeNodes: KuiTreeSelectNode[];

    @ViewChild(KuiDropdownComponent) filterDropdown: KuiDropdownComponent;
    @ViewChild(KuiTreeSelectComponent) tree: KuiTreeSelectComponent;

    constructor(
        private app: AppService,
        public grouping: AssetGroupingService,
        private i18n: TranslateService,
        private ref: ChangeDetectorRef
    ) {
    }

    validate(): boolean {
        this.dirty = true;
        this.touched = true;
        this.ref.markForCheck();
        return !this.field.required || this.selectedItems.length > 0;
    }

    async ngOnInit() {

        this.ownerId = (this.field.options && this.field.options.ownerId) || this.app.client.id || this.app.user.owner.id;
        this.entity = this.field.options?.entity || null;
        this.exclusive = this.field.options?.exclusive || this.exclusive;


        const items = await this.app.api.entities.listLabels(this.ownerId, true, 0, 100, 'name', "state=active", this.app.api.cacheFor(10));
        const labels = items.items.filter(x => this.entity === null || x.entities.includes(this.entity));
        
        this.treeNodes = [];
        for (const item of labels) {
            if (item.values && item.values.length) {
                for (const sub of item.values) {
                    if (sub) {
                        const id = item.label + '/' + sub.label;
                        const name = [item.name, sub.name].filter(x => x).join(': ');
                        this.treeNodes.push({
                            id,
                            name,
                            icon: ICONS.label,
                            data: { id, name }
                        });
                    }
                }
            } else {
                this.treeNodes.push({
                    id: item.label,
                    name: item.name,
                    icon: ICONS.label,
                    data: { id: item.label, name: item.name }
                });
            }
        }


        if (this.field.max === 1) { // if max is one we go into single label selection mode
            const id = this.values[this.field.id] = this.field.getValue ? this.field.getValue(this.field, this.values) : this.values[this.field.id] || this.field.value;

            if (id && id !== EMPTY_GUID) {
                this.isLoading = true;

                this.app.api.entities.getLabel(id).then(item => {
                    this.selectedItems = [{
                        id: item.id,
                        name: item.name,
                    }];
                    this.isLoading = false;
                    this.ref.markForCheck();
                });
            } else {
                this.selectedItems = [];
            }


        } else {
            this.values[this.field.id] = this.field.getValue ? this.field.getValue(this.field, this.values) : this.values[this.field.id] || this.field.value || [];

            this.selectedItems = this.values[this.field.id].map(x => ({
                id: x.key || x.id || x.actorId,
                name: x.text || x.name,
            }));

            setTimeout(() => { // avoid ExpressionChangedAfterItHasBeenCheckedError
                this.field.description = this.field.description || this.i18n.instant('FORMS.LABELFILTER.DESCRIPTION');
                this.ref.markForCheck();
            });

        }
    }

    updateValues() {
        this.dirty = true;
        this.touched = true;

        if (this.field.max === 1) {
            if (this.field.setValue) {
                this.field.setValue(this.field, this.values, this.selectedItems[0]);
            } else {
                this.values[this.field.id] = this.selectedItems[0] && this.selectedItems[0].id;
            }
        } else {
            if (this.field.setValue) {
                this.field.setValue(this.field, this.values, this.selectedItems);
            } else {
                this.values[this.field.id] = this.selectedItems.map(x => ({ id: x.id, name: x.name }));
            }
        }

        this.onChange.emit({ value: this.values[this.field.id], dirty: this.dirty });
    }

    addTag(value: TagItem) {
        if (this.field.max === 1) {
            this.selectedItems = [value];
        } else {
            // remove any existing labels that are sub labels of this label
            const [root] = value.id.split('/');
            this.selectedItems = [...this.selectedItems.filter(x => !x.id.startsWith(root + '/')), value];
        }
        this.updateValues();
    }

    removeTag(value: TagItem) {
        const idx = this.selectedItems.findIndex(x => x.id === value.id);
        this.selectedItems.splice(idx, 1);
        this.updateValues();
    }

    updateAutoCompletionList(searchTerm: string) {
        this.dirty = true;
        this.autoCompleteItems = this.treeNodes.map(x => ({
            id: x.id,
            name: x.name,
        })).filter(x => x.name.toLowerCase().includes(searchTerm.toLowerCase()));
        return this.autoCompleteItems;
    }

    nodeSelected(event: { eventName: string, node: TreeNode }) {
        this.dirty = true;

        const { data, id } = event.node.data;
        if (data && !this.selectedItems.find(x => x.id === id)) {
            this.addTag({
                id: id,
                name: data.name,
            });
            if (event.eventName === 'activate') {
                // single select mode still remembers the selection state
                // we want to clear that after activation rather
                this.tree.clearSelection();
            }
            this.filterDropdown.toggle();
        }
    }

}
