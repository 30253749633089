import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({ selector: 'kui-card-text' })
export class KuiCardTextDirective {
    constructor(private el: ElementRef, private renderer: Renderer2) {
        this.renderer.addClass(this.el.nativeElement, 'card-text');
    }
}

@Directive({ selector: 'kui-card-title' })
export class KuiCardTitleDirective {
    private classNames: string[] = ['content-card-title', 'card-title', 'd-block'];

    constructor(private el: ElementRef, private renderer: Renderer2) {
        this.classNames.forEach((className) => {
            this.renderer.addClass(this.el.nativeElement, className);
        });
    }
}

@Directive({ selector: 'kui-card-subtitle' })
export class KuiCardSubTitleDirective {
    private classNames: string[] = ['content-card-subtitle', 'card-subtitle', 'muted', 'h6', 'd-block'];

    constructor(private el: ElementRef, private renderer: Renderer2) {
        this.classNames.forEach((className) => {
            this.renderer.addClass(this.el.nativeElement, className);
        });
    }
}
