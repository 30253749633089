
import { Component, Input, ChangeDetectorRef, OnChanges } from '@angular/core';

@Component({
    selector: 'key-video-thumbnail',
    templateUrl: './video-thumbnail.component.html',
})
export class VideoThumbnailComponent implements OnChanges {

    @Input() url: string;
    @Input() width = '100%';

    imgUrl: string;
    hasError = false;
    isVideo = false;

    constructor(private ref: ChangeDetectorRef) {
    }

    ngOnChanges() {
        this.imgUrl = this.url;
        this.isVideo = this.url.includes('.mp4') || this.url.includes('.mpg') || this.url.includes('.mpeg');


        if (!this.isVideo && this.width === '100%') {
            this.width = '240px'; // make image thumbnails the same size as our video thumbnails
        }

    }

    onEnter(_event: Event) {
        if (!this.isVideo || this.hasError) { return; }
        this.imgUrl = this.url.replace('.jpg', '.gif');
        this.ref.markForCheck();
    }
    
    onLeave(_event: Event) {
        if (!this.isVideo || this.hasError) { return; }
        this.imgUrl = this.url;
        this.ref.markForCheck();
    }

    onError(_event: Event) {
        this.imgUrl = 'assets/images/video-thumbnail.png';
        this.hasError = true;
        this.ref.markForCheck();
    }


}


