import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { ClientService } from 'app/services/client/client.service';
import { SpatialUtils } from 'app/shared/components/map/map.utils';
import { AssetStateGetLocationFeedGQL } from 'app/shared/graphql';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { EntityService } from '../entity/entity.service';
import { AssetFilterService, FiltersResponse } from './asset-filter.service';
import { AssetService } from './asset.service';
import { AssetListItem } from './assets.model';




export interface AssetListDictionary {
    [key: string]: AssetListItem;
}

@Injectable()
export class AssetStateService {

    // we've disabled graphql for location updates as a memory leak in the apollo components in the fleet-api is problematic for large asset fleets
    private enableGraphQL = false;

    private subscriptions: Subscription[] = [];

    pollingInterval = 15000;
    /** crude way of making sure our timers run on the correct filter */
    filterIndex = 0;

    prevFilterHash = '';

    private assetsSubject = new Subject<AssetListItem[]>();
    // NOTE: the assets$ observable returns latest changes to state and will not always be the full list of assets
    get assets$(): Observable<AssetListItem[]> {
        return this.assetsSubject.asObservable();
    }
    // keeps the full list of assets and it's latest state for lookup purposes
    assetListIndex: AssetListDictionary = {};
    get assets(): AssetListItem[] {
        return Object.keys(this.assetListIndex).map(key => this.assetListIndex[key]); // polyfil for Object.values is broken on older browsers
    }

    count: number;

    lastError: Error = null; // keep track of the last error to prevent duplicate events from being fired
    private onErrorSubject: Subject<Error> = new Subject();
    get onError$(): Observable<Error> {
        return this.onErrorSubject.asObservable();
    }

    constructor(
        private app: AppService,
        private clientService: ClientService,
        private auth: AuthService,
        private assetService: AssetService,
        private filters: AssetFilterService,
        private entities: EntityService
    ) {
        this.filters.filters$
            .pipe((debounceTime(100)))
            .subscribe(filter => this.setFilter(filter));

        this.entities.entityUpdated$.subscribe(entity => {
            if (['asset', 'device'].includes(entity && entity._type)) {
                this.setFilter(this.filters.getFilters());
            }
        });
    }

    setFilter(filter: FiltersResponse) {
        this.setError(null);
        if (filter && filter.clientId) {
            if (this.hasFiltersChanged(filter)) {
                // Only increment the filterIndex when the filters have changed
                this.filterIndex++;
                this.initialize(this.filterIndex, filter.clientId, filter.limit, filter.offset, filter.sort, this.filters.getFilterRqlString())
                    .then(() => this.setError(null))
                    .catch(error => this.setError(error));
            }
        }
    }

    hasFiltersChanged(filter: FiltersResponse) {
        const filterHash = JSON.stringify([filter.clientId, filter.limit, filter.offset, filter.sort, this.filters.getFilterRqlString(), filter.point]);
        // tslint:disable-next-line: tsr-detect-possible-timing-attacks
        if (filterHash !== this.prevFilterHash) {
            this.prevFilterHash = filterHash;
            return true;
        }
        return false;
    }

    async initialize(index: number, clientId: string, limit: number, offset: number, sort: string, filter: string): Promise<void> {

        this.assetListIndex = {};
        this.assetsSubject.next(null); // send a clear command

        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.subscriptions = [];

        if (this.enableGraphQL && this.app.api.websocketConnected) {
            await this.initializeSubscription(index, clientId, limit, offset, sort, filter);
        } else {
            await this.initializePolling(index, clientId, limit, offset, sort, filter);
        }

        this.subscriptions.push(this.assetService.cached$.subscribe(asset => {
            const existing = this.assetListIndex[asset.id];
            if (existing) {
                const item = {
                    ...existing,
                    ...asset,
                };
                this.assetListIndex[asset.id] = item;
                this.assetsSubject.next([item]);
            }
        }));

    }

    private async initializeSubscription(index: number, clientId: string, limit: number, offset: number, sort: string, filter: string): Promise<void> {


        return this.app.api.entities.listAssets(clientId, offset, limit, sort, filter)
            .then(result => {
                if (this.filterIndex !== index) { return; } // stop the polling if we're not on the latest filter
                this.count = result.count;

                // save assets locally and return the subset that updated on state updates
                result.items.forEach(item => {
                    const asset = this.updateDistance(this.assetService.toAssetListItem(item));
                    this.assetListIndex[item.id] = asset;
                    this.assetService.cacheAsset(asset);
                });
            })
            .then(() => {
                if (this.filterIndex !== index) { return; } // stop the polling if we're not on the latest filter

                this.subscriptions.push(
                    new AssetStateGetLocationFeedGQL(this.app.api.gql)
                        .subscribe({ client: clientId, offset, limit, sort, filter })
                        .subscribe(result => {
                            if (this.filterIndex !== index) { return; } // ignore the results if we're not on the latest filter
                            const updated = result.data.getLocationFeed.map(record => {
                                const asset = this.assetListIndex[record.object.id];
                                asset['telemetry'] = {
                                    ...record,
                                    object: { id: record.object.id, name: record.object.name, type: 'asset' },
                                } as any;
                                return this.updateDistance(asset);
                            });
                            this.assetsSubject.next(updated);
                            this.setError(null);

                        }, (error) => {
                            this.setError(error);
                        })
                );
            });


    }

    private async initializePolling(index: number, clientId: string, limit: number, offset: number, sort: string, filter: string): Promise<void> {

        return this.app.api.entities.listAssets(clientId, offset, limit, sort, filter)
            .then(result => {
                if (this.filterIndex !== index) { return; } // stop the polling if we're not on the latest filter
                this.count = result.count;

                // save assets locally and return the subset that updated on state updates
                result.items.forEach(item => {
                    const asset = this.updateDistance(this.assetService.toAssetListItem(item));
                    this.assetListIndex[item.id] = asset;
                    this.assetService.cacheAsset(asset);
                });
            })
            .then(() => {
                if (this.filterIndex !== index) { return; } // stop the polling if we're not on the latest filter
                // Create a new timer based on the current sequence
                const run = (sequence: number, timeout: number = 0) => {
                    if (this.filterIndex !== index) { return; } // stop the polling if we're not on the latest filter
                    setTimeout(() => {
                        if (this.filterIndex !== index) { return; } // stop the polling if we're not on the latest filter
                        return this.updateAssetsState(index, clientId, sequence, offset, limit, sort, filter)
                            .then(updatedSeq => {
                                this.setError(null);
                                run(updatedSeq, this.pollingInterval);
                            })
                            .catch(error => {
                                this.setError(error);
                                return this.auth.isAuthenticated().then(authed => { // stop running the timer if we're no longer authenticated
                                    if (authed) {
                                        run(sequence, this.pollingInterval);
                                    }
                                });

                            });

                    }, timeout);
                };
                run(0);
            });
    }

    updateAssetsState(index: number, clientId: string, sequence: number, offset: number, limit: number, sort: string, filter: string): Promise<number> {
        return this.app.api.data.getLocationFeed(clientId, sequence, offset, limit, sort, filter)
            .then(result => {
                if (this.filterIndex !== index) {
                    return 0; // stop the polling if we're not on the latest filter
                }
                const updated = result.items.map(item => {
                    const asset = this.assetListIndex[item.object.id];
                    if (!asset) return
                    asset['telemetry'] = item;
                    return this.updateDistance(asset);
                }).filter(x => !!x);
                this.assetsSubject.next(updated);
                this.setError(null);
                return result.sequence;
            });
    }

    setError(err: Error) {
        if (err !== this.lastError) {
            this.lastError = err;
            this.onErrorSubject.next(err);
        }
    }

    stopPolling() {

        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.subscriptions = [];

        this.filterIndex++;
        this.assetListIndex = {};
        this.assetsSubject.next(null); // send a clear command
    }


    getAsset(id: string): Promise<AssetListItem> {
        if (!id) {
            return Promise.resolve(null);
        }
        if (this.assetListIndex[id]) {
            return Promise.resolve(this.assetListIndex[id]);
        } else {
            // if we don't have asset in our list, load it from the api. Check it's client id and switch clients if necessary.
            return this.assetService.getAsset(id).then(asset => {
                if (asset.owner.id !== this.app.client.id && !asset.sharedWith.find(x => x.id === this.app.client.id)) {
                    this.stopPolling(); // we're about to change clients, make sure we've stopped any active polling
                    return this.clientService.loadClient(asset.owner.id).then(() => asset);
                }
                return asset;
            });
        }

    }

    updateAsset(asset: Pick<AssetListItem, 'id' | 'name' | 'color'>) {
        if (this.assetListIndex[asset.id]) {
            const item = {
                ...this.assetListIndex[asset.id],
                ...asset,
            };
            this.assetListIndex[asset.id] = item;
            this.assetsSubject.next([item]);
        }
    }


    updateDistance(asset: AssetListItem): AssetListItem {
        if (this.filters.point && asset?.telemetry?.location) {
            const { lon, lat } = asset.telemetry.location;
            asset.distance = SpatialUtils.getDistanceFromLatLonInKm(lat, lon, this.filters.point.lat, this.filters.point.lon);
        } else {
            delete asset.distance;
        }

        // TODO: this is debug code, it must be removed sometime in the future.
        if (asset?.telemetry?.telemetry && asset?.owner?.id === 'ac5c3b72-2f7c-40b3-b702-034273dc9dea') { // simulated in UK1
            let value = Math.floor(Math.random() * 500);
            if (value < 100) {
                value = -1;
            }
            asset.telemetry.telemetry['gps_acc'] = value;
        }

        return asset;
    }

}
