<kui-card>
    <kui-card-body class="text-center">
        <kui-card-body class="text-center" *ngIf="searching">
            <kui-loader size="small">
            </kui-loader>
        </kui-card-body>
        <div *ngIf="!searching && !results?.length">{{'LEAFLET.NO_RESULTS' | translate}}</div>
        <key-search-results-list *ngIf="!searching && results?.length"
            [list]="results"
            [highlightValue]="term$ | async"
            (onSelected)="hideSearchList()">
        </key-search-results-list>
    </kui-card-body>
</kui-card>