import { Injectable, OnDestroy } from '@angular/core';
import { AnalyticsOutputRequest, CompletedReportResponse, AnalyticsOutputSettings, CompletedReportUpdateRequest, AnalyticsOutputFilter, AnalyticsOutputRangeFilter } from '@key-telematics/fleet-api-client';
import * as objectHash from 'object-hash';
import { YAxisData, GraphData } from 'app/shared/components/analytics';
import { Dataset, Average, DatasetType } from 'app/shared/components/graph/graph.model';
import { removeSpaces, toTitleCase } from 'app/shared/utils/string.utils';
import { SeriesSettings } from 'app/shared/components/analytics/settings/series/series.component';
import * as Color from 'color';
import { AnalyticsCellSet, AnalyticsCellValue, AnalyticsSettings, KeyValue, AnalyticsCellSetFilters } from '../analytics.model';
import { AppService } from 'app/app.service';
import { generateDummyCellsetData } from './dummy-data';
import { SubscriptionLike } from 'rxjs';
import { YAxisMinMax } from '../settings/y-axis/y-axis.component';
import { THEME_GRAPH_PALETTES } from '../../theme/theme-defaults';
import { get, merge } from 'lodash';

interface HeaderValues {
    values: { value: string, uniquename: string, dim: string }[];
}

@Injectable()
export class AnalyticsService implements OnDestroy {
    yAxesLimits: { left?: YAxisMinMax, right?: YAxisMinMax } = {};
    cache: { [key: string]: AnalyticsCellSet } = {};
    filtersCache: { [key: string]: Promise<AnalyticsCellSetFilters> } = {};
    themeColors: string[];
    theme$: SubscriptionLike;
    RETRY_DELAY = 15;
    constructor(
        private app: AppService
    ) {
        this.theme$ = this.app.theme$
            .subscribe(theme => {
                this.themeColors = THEME_GRAPH_PALETTES[get(theme.settings, 'graph.palette', 'default')] || THEME_GRAPH_PALETTES.default;
            });
    }

    ngOnDestroy() {
        this.theme$.unsubscribe();
    }

    retry<T>(request: (...args: any) => Promise<T>, args: any[], cb: (response: T) => boolean, opts: { retries: number, delaySeconds: number , }): Promise<T> {
        return new Promise<T>((resolve, reject) => {

            const makeRequest = (count: number): void => {
                request(...args).then(res => {
                    if (cb(res) || count >= opts.retries) {
                        return resolve(res);
                    }
                    setTimeout(() => makeRequest(count + 1), opts.delaySeconds * 1000);
                }).catch(reject);
            };
            
            setTimeout(() => makeRequest(0), 0);
        });
    }

    queryCellset(id: string, outputOptions: AnalyticsOutputRequest): Promise<any> {
        return this.app.api.reports.getCompletedReportDataWithOptions(id, `data-${id}.json`, { outputOptions });
    }

    getCompletedAnalyticsCellset(id: string, outputOptions: AnalyticsOutputRequest): Promise<AnalyticsCellSet> {
        return this.retry<AnalyticsCellSet>(this.queryCellset.bind(this), [id, outputOptions], data => {
            return data?.cellset?.status !== 'generating';
        }, { retries: 7, delaySeconds: this.RETRY_DELAY}).then(data => {
            return data as AnalyticsCellSet;
        });
    }

    getCompletedAnalyticsReport(id: string): Promise<CompletedReportResponse> {
        return this.app.api.reports.getCompletedReport(id);
    }

    updateCompletedAnalyticsReport(id: string, report: CompletedReportUpdateRequest): Promise<CompletedReportResponse> {
        return this.app.api.reports.updateCompletedReport(id, report);
    }

    getFiltersList(reportId, options: AnalyticsSettings): Promise<AnalyticsCellSetFilters> {
        const { row, group, ...opts } = options; // remove row and group when creating hash as they will always be the same
        const hash = objectHash({ ...opts, reportId });
        if (!this.filtersCache[hash]) {
            this.filtersCache[hash] = this.getCellsetData(reportId, merge({}, options, {
                row: { level: (row.levels && row.levels.headings && row.levels.headings.length - 1) || 0 },
                group: { level: (group.levels && group.levels.headings && group.levels.headings.length - 1) || 0 },
                filters: null,
            })).then(({ cellset }) => cellset.filters);
        }
        return this.filtersCache[hash];
    }

    getCellsetData(reportId: string, options: AnalyticsSettings, bypassCache?: boolean): Promise<AnalyticsCellSet> {
        // we return dummy data if no reportId was passed through
        if (!reportId) {
            return Promise.resolve(generateDummyCellsetData());
        }

        const opts: AnalyticsOutputRequest = {
            rowLevel: options.row.level || 0,
            row: options.row.dim,
            groupLevel: options.group.level || 0,
            group: options.group.dim,
            measures: options.table.measures.map(({ key }) => key),
            time: options.dateRange,
            flipped: options.flipped,
            sort: options.sort,
            filters: options.filters,
        };

        const hash = objectHash({ ...opts, reportId });
        const cached = !bypassCache && this.cache[hash];

        return (cached ? Promise.resolve(cached) : this.getCompletedAnalyticsCellset(reportId, opts))
            .then(result => {
                this.cache[hash] = result;
                return result;
            });
    }

    async saveReportChanges(reportId: string, report: CompletedReportResponse, options: AnalyticsSettings): Promise<CompletedReportResponse> {

        const { group, row, ...settings } = options;
        const charts = report && report.outputOptions && report.outputOptions.charts || [];
        charts[0] = {
            ...charts[0],
            ...settings,
            row: { ...charts[0].row, ...row },
            group: { ...charts[0].group, ...group },
        };

        try {
            const response = await this.updateCompletedAnalyticsReport(reportId, {
                outputOptions: {
                    ...report.outputOptions,
                    charts,
                },
            });
            return response;
        } catch (err) {
            if (err.data) {
                err.name = err.data.name;
                err.message = err.data.response?.body?.message || err.data.message;
            }
            throw err;
        }

    }

    convertOutputToAnalyticsSettings(options: AnalyticsOutputSettings, allowCustomDateRange: boolean): AnalyticsSettings {
        const { sort, graph, measures, pie, stat, filters, timeOptions, updates, ...restSettings } = options;

        const getFilter = (axis: 'row' | 'group') => {
            const { level, levels } = restSettings[axis];
            const lev = (levels.headings || [])[level];
            const { mode, items, range } = filters && filters[axis] || {} as AnalyticsOutputFilter;
            const { start, end } = range || {} as AnalyticsOutputRangeFilter;

            return {
                mode: mode || 'exclude', // default is exclude so that the settings will populate everything with an empty array
                items: items || [],
                range: {
                    level: (lev || '').toLowerCase(),
                    start: start || '8',
                    end: end || '17',
                },
            };
        };

        const defaultLabel = {
            visible: graph.label.visible,
            placement: graph.label.placement,
            position: graph.label.position || 'bottom',
            overlay: graph.label.overlay,
        };

        // HACK ALERT!!! This conversion of levels takes care of legacy implementation when levels were simple string[]
        // TODO: there is supposed to be a flag on the api which, if set, returns the new interface of levels which is { name: string; headings: string[] }
        // when that flag actually works... remove the isArray checks and do it correctly!!!
        if (Array.isArray(restSettings.row.levels)) {
            restSettings.row.levels = {
                name: toTitleCase(restSettings.row.dim),
                headings: restSettings.row.levels,
            };
        }
        if (Array.isArray(restSettings.group.levels)) {
            restSettings.group.levels = {
                name: toTitleCase(restSettings.group.dim),
                headings: restSettings.group.levels,
            };
        }

        // Legacy support... we need to check if the report settings has hours as a time options or change hour unit to day if not
        const dateRange = restSettings.dateRange === '24h' && !(timeOptions && timeOptions.includes('hours')) ? '1d' : restSettings.dateRange;

        return {
            ...restSettings,
            timeOptions,
            measures,
            dateRange: allowCustomDateRange ? dateRange : dateRange.includes(':') && '7d' || dateRange,
            sort: {
                ...sort,
                measureKey: sort.measureKey || measures[0] && measures[0].value,
            },
            
            updates: {
                ...updates,
                daily: {
                    time: updates && updates.daily && updates.daily.time || '03:00',
                },
            },

            filters: {
                row: getFilter('row'),
                group: getFilter('group'),
            },
            graph: {
                xAxis: graph.xAxis || {
                    labels: {
                        layout: 'auto',
                    },
                },
                yAxis: {
                    left: graph.yAxis && graph.yAxis.left || { min: undefined, max: undefined },
                    right: graph.yAxis && graph.yAxis.right || { min: undefined, max: undefined },
                },

                stacked: graph.stacked,
                dataLabels: graph.dataLabels,

                label: defaultLabel,

                series: !!Object.keys(graph.series).length
                    ? Object.entries(graph.series)
                        .reduce((all, [key, value]) => { // remove all series items with null values
                            return value ? {
                                ...all,
                                [key]: value,
                            } : all;
                        }, {} as { [key: string]: SeriesSettings; })
                    : {
                        default: {
                            visible: true,
                            type: 'bar',
                            orientation: 'vertical', // TODO: this does not currently do anything
                            fill: true,
                            color: null,
                            yAxis: 'left',
                        },
                    },

                seriesColors: this.getSeriesColors(measures, graph.series as any), // graph.series here is of type ChartSeries which is an empty object, but its keys will always be the same as SeriesSettings
            },
            pie: pie || {
                fill: true,
                series: {},
                dataLabels: false,
                data: 'average',
                label: defaultLabel,
            },
            stat: stat || {
                data: 'last',
                color: this.themeColors[0],
                trend: 'percentage',
                sparkline: true,
            },
        };
    }

    getSeriesColors(measures: KeyValue[], series?: { [key: string]: SeriesSettings; }): { [key: string]: string } {
        return measures.reduce((all, measure, index) => {
            const key: string = removeSpaces(measure && measure.value.toLowerCase());
            return {
                ...all,
                [key]: series && series[key] && series[key].color || this.themeColors[index],
            };
        }, {});
    }

    convertCellsetToGraph(cellset: AnalyticsCellValue[][], options: AnalyticsSettings): GraphData {
        const columnHeaders: HeaderValues[] = [];
        const rowHeaders: HeaderValues[] = [];
        let yAxesOptions: { left: YAxisData; right: YAxisData } = {
            left: {
                max: 0,
                timeLabels: true,
            },
            right: {
                max: 0,
                timeLabels: true,
            },
        };
        let rowLevel: number;
        let colLevel: number;
        let datasets: Dataset[];

        const addToHeaderValues = (value: AnalyticsCellValue, index: number, headerType: 'row' | 'column') => {
            if (value && value.value !== 'null') {
                const object = headerType === 'row' ? rowHeaders : columnHeaders;
                // use index as the key to maintain the correct order
                const column = object[index];
                if (column) {
                    const hasValue = column.values.some(x => value.properties.uniquename === x.uniquename);

                    if (!hasValue) {
                        object[index].values.push({ value: value.value as string, uniquename: value.properties.uniquename, dim: value.properties.dimension });
                    }
                } else {
                    // first column in row
                    object.push({ values: [{ value: value.value as string, uniquename: value.properties.uniquename, dim: value.properties.dimension }] });
                }
            }
        };
        const shouldShowOnChart = ({ properties }) => properties && !properties.doNotShowOnChart;

        // loop though each row and then every column per row
        cellset.forEach((row, rowIndex) => {
            row
                .filter(shouldShowOnChart)
                .forEach((column, colIndex) => {

                    // build up indexes for each ROW_HEADER and COLUMN_HEADER
                    // we use these to:
                    //     1. get the header depths
                    //     3. get proper labels for x-axis, y-axes, tooltips and legends
                    if (column.type === 'ROW_HEADER') {
                        addToHeaderValues(column, colIndex, 'row');
                    }

                    if (column.type === 'COLUMN_HEADER') {
                        addToHeaderValues(column, rowIndex, 'column');
                    }

                    if (column.type === 'DATA_CELL') {

                        if (!rowLevel && !colLevel) {

                            rowLevel = columnHeaders.length - 1;
                            colLevel = rowHeaders.length - 1;

                            // build our base dataset array from columnHeaders with empty data key (data will be added afterwards)
                            // we do this in revese because all the higher up header columns should contain the lower header column items (which is the measures)
                            // note: this loop only happens once
                            datasets = columnHeaders.reverse().reduce((sets: Dataset[], header: HeaderValues, headerIndex: number) => {
                                switch (headerIndex) {
                                    case 0:
                                        return this.getBaseGraphDatasetsFromMeasures(options.table.measures as KeyValue[], options.graph.series, options.graph.seriesColors);
                                    case 1:
                                        return header.values.reduce((all, cur, index) => ([
                                            ...all,
                                            ...sets.map(value => ({
                                                ...value,
                                                uniquename: cur.uniquename,
                                                color: Color(value.color).alpha(this.getAlphaFromIndex(index)).string(),
                                                label: decodeURIComponent(cur.uniquename).replace('All|', '').replace(/\|+/g, ' | ') + ' | ' + value.label,
                                            })),
                                        ]), []);
                                    default:
                                        return sets;
                                }
                            }, [] as Dataset[]);
                        }

                        // add current data cell value to the correct dataset
                        const cell = datasets[colIndex - (colLevel + 1)];
                        if (cell) {
                            cell.data = [
                                ...cell.data,
                                { raw: +column.properties.raw, value: column.value ? column.value.toString() : '-' }, // if column.value is empty it doesn't mean 0 and shouldn't display it as that on the graph
                            ];
                            const raw = parseInt(column.properties.raw as string, 0);
                            yAxesOptions[cell.yAxis].max = Math.max(yAxesOptions[cell.yAxis].max, raw);

                            // we only check this while not false yet
                            if (yAxesOptions[cell.yAxis].timeLabels) {
                                yAxesOptions[cell.yAxis].timeLabels = column.value.toString().includes(':');
                            }
                        }
                    }
                });
        });

        // only update limits if they changed
        const { left, right } = this.yAxesLimits;
        if ((!left && !right) || (left && left.max !== yAxesOptions.left.max.toString()) || (right && right.max !== yAxesOptions.right.max.toString())) {
            this.yAxesLimits = {
                left: { min: null, max: yAxesOptions.left.max.toString() },
                right: { min: null, max: yAxesOptions.right.max.toString() },
            };

            yAxesOptions = {
                left: {
                    ...yAxesOptions.left,
                    maxLimit: yAxesOptions.left.max.toString(),
                },
                right: {
                    ...yAxesOptions.right,
                    maxLimit: yAxesOptions.right.max.toString(),

                },
            };
        }

        // time labels need a little more context eg. instead of `Jun` we add year `Jun '20` and for day `20` we add month `20 Jun`
        const formatTimeLabel = (value: string, uniquename: string): string => {
            const levels = decodeURIComponent(uniquename).split('|');
            // we determine the level by the index of the last pipe in uniquename and modify the type if needed, which is only on month(index = 2) and day(index = 3)
            const modifier = {
                2: val => `${val} '${levels[1].substring(2)}`, // month
                3: val => val + ' ' + levels[2], // day
            };
            const level = modifier[levels.length - 1];
            return level ? level(value) : value;
        };

        return {
            datasets,
            yAxesOptions,
            labels: rowHeaders[colLevel].values.map(x => ({
                value: x.dim === 'time' ? formatTimeLabel(x.value, x.uniquename) : x.value,
                // uniquename is something like `All|2020|Nov|19`... we convert it to `19 Nov 2020` for the title
                tooltipTitle: decodeURIComponent(x.uniquename).split('|')
                    .map((y, i) => x.dim === 'time' && i === 4 ? `${y}:00` : y) // on time dimensions we need to find the time and add the minutes to it
                    .filter((y, _i, a) => y !== 'All' || a.length === 1) // remove 'All' value except if it is the only value there ie. length === 1
                    .reverse().join(' '),
            })),
        };
    }

    getBaseGraphDatasetsFromMeasures(measures: KeyValue[], series: { [key: string]: SeriesSettings }, seriesColors: { [key: string]: string; }): Dataset[] {
        return measures.map(({ value }) => {
            const id = removeSpaces(value && value.toLocaleLowerCase());
            const settings = series[id] || series['default'];

            return {
                label: value,
                id,
                data: [],
                fill: settings.type === 'line' ? settings.fill : true, // bar should always be filled
                type: settings.type as DatasetType,
                yAxis: settings.yAxis,
                color: settings.color || seriesColors[id],
                hidden: !settings.visible,
            };
        });
    }

    getGraphAverages(measures: KeyValue[], series: { [key: string]: SeriesSettings }, cellset: AnalyticsCellValue[][], seriesColors: { [key: string]: string; }): Average[] {
        const lastRow = cellset[cellset.length - 1];
        const averageCellset = lastRow.slice(lastRow.length - measures.length, lastRow.length);

        return measures.reduce((averages, { value }, index) => {
            const id = removeSpaces(value && value.toLowerCase());
            const seriesItem = series[id];
            
            if (seriesItem && seriesItem.average) {
                const cell = averageCellset[index];
                averages = [...averages, {
                    color: Color(seriesItem.color || seriesColors[id]).darken(.2).string(),
                    label: cell.value.toString(),
                    value: cell.properties.raw as number,
                    axis: seriesItem.yAxis,
                }];
            }

            if (seriesItem && seriesItem.targetLines) {
                seriesItem.targetLines.forEach(line => {
                    if (line && averages) {
                        averages = [...averages, line];
                    }
                });
            }
            
            return averages;
        }, []);
    }

    getAlphaFromIndex(index: number): number {
        return +(1 - ((index % 5) * .2)).toFixed(1);
    }
}
