import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { KuiModule } from 'app/key-ui';
import { TranslateModule } from '@ngx-translate/core';
import { KeyFormBuilderComponent } from './form-builder.component';
import { KeyFormBuilderFieldComponent } from './field/field.component';
import { KeyNumberValidatorDirective } from './validators/number-validator.directive';
import { KeyFormBuilderAssetFilterFieldComponent } from './assetfilter/assetfilter.component';
import { KeyFormBuilderClientFilterFieldComponent } from './clientfilter/clientfilter.component';
import { KeyFormBuilderCheckboxFieldComponent } from './checkbox/checkbox.component';
import { KeyFormBuilderChecklistFieldComponent } from './checklist/checklist.component';
import { KeyFormBuilderComboFieldComponent } from './combo/combo.component';
import { KeyFormBuilderTextFieldComponent } from './text/text.component';
import { KeyFormBuilderNumberFieldComponent } from './number/number.component';
import { KeyFormBuilderMemoFieldComponent } from './memo/memo.component';
import { KeyFormBuilderDateTimeFieldComponent } from './datetime/datetime.component';
import { KeyFormBuilderTagsFieldComponent } from './tags/tags.component';
import { KeyFormBuilderZoneFilterFieldComponent } from './zonefilter/zonefilter.component';
import { KeyFormBuilderColorFieldComponent } from './color/color.component';
import { KeyFormBuilderColorComboFieldComponent } from './color-combo/color-combo.component';
import { KeyFormBuilderToggleFieldComponent } from './toggle/toggle.component';
import { KeyFormBuilderRadioFieldComponent } from './radio/radio.component';
import { KeyFormBuilderMinMaxFieldComponent } from './minmax/minmax.component';
import { KeyFormBuilderListFieldComponent } from './list/list.component';
import { KeyFormBuilderEventFilterFieldComponent } from './eventfilter/eventfilter.component';
import { KeyFormBuilderImageFieldComponent } from './image/image.component';
import { KeyFormBuilderSwatchesFieldComponent } from './swatches/swatches.component';
import { KeyImageEditorModule } from '../image-editor';
import { EventsService } from 'app/services';
import { ColorPickerModule } from 'ngx-color-picker';
import { KeySearchResultsListModule } from '../search-results-list/search-results-list.module';
import { KeyFormBuilderSearchSelectFieldComponent } from './searchselect/searchselect.component';
import { KeyFormBuilderDayTimeFieldComponent } from './daytime/daytime.component';
import { KeyFormBuilderEventFilterSearchableTreeComponent } from './eventfilter/searchable-tree/searchable-tree.component';
import { KeyFormBuilderGeoFilterFieldComponent } from './eventfilter/geofilter.component';
import { MarkdownModule } from 'ngx-markdown';
import { KeyFormBuilderEventFilterConditionComponent } from './eventfilter/condition/condition.component';
import { KeyFormBuilderEventFilterConditionPrefixPipe } from './eventfilter/condition-prefix.pipe';
import { KeyFormBuilderLabelFilterFieldComponent } from './labelfilter/labelfilter.component';

const COMPONENTS = [
    KeyFormBuilderComponent,
    KeyFormBuilderFieldComponent,
    KeyNumberValidatorDirective,
    KeyFormBuilderCheckboxFieldComponent,
    KeyFormBuilderComboFieldComponent,
    KeyFormBuilderTextFieldComponent,
    KeyFormBuilderTagsFieldComponent,
    KeyFormBuilderNumberFieldComponent,
    KeyFormBuilderMemoFieldComponent,
    KeyFormBuilderDateTimeFieldComponent,
    KeyFormBuilderListFieldComponent,
    KeyFormBuilderChecklistFieldComponent,
    KeyFormBuilderAssetFilterFieldComponent,
    KeyFormBuilderLabelFilterFieldComponent,
    KeyFormBuilderZoneFilterFieldComponent,
    KeyFormBuilderClientFilterFieldComponent,
    KeyFormBuilderColorFieldComponent,
    KeyFormBuilderColorComboFieldComponent,
    KeyFormBuilderToggleFieldComponent,
    KeyFormBuilderRadioFieldComponent,
    KeyFormBuilderMinMaxFieldComponent,
    KeyFormBuilderEventFilterFieldComponent,
    KeyFormBuilderGeoFilterFieldComponent,
    KeyFormBuilderImageFieldComponent,
    KeyFormBuilderSearchSelectFieldComponent,
    KeyFormBuilderDayTimeFieldComponent,
    KeyFormBuilderSwatchesFieldComponent,
];

const STANDALONE_COMPONENTS = [
    KeyFormBuilderEventFilterSearchableTreeComponent,
    KeyFormBuilderEventFilterConditionComponent
]


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        KuiModule,
        TranslateModule,
        ColorPickerModule,
        KeyImageEditorModule,
        KeySearchResultsListModule,
        MarkdownModule.forRoot(),
        ...STANDALONE_COMPONENTS,
        KeyFormBuilderEventFilterConditionPrefixPipe
    ],
    exports: [
        ...COMPONENTS,
        ...STANDALONE_COMPONENTS
    ],
    declarations: COMPONENTS,
    providers: [EventsService],
})
export class KeyFormBuilderModule { }
