<kui-modal size='md'
    class="details-modal"
    [title]="'ADMIN.MODALS.BULK_APPLY_CONFIRM.TITLE' | translate"
    (onClose)="detachModal()"
    [actions]="modalActions">

    <div class="p-4">

        <div>
            {{ 'ADMIN.MODALS.BULK_APPLY_CONFIRM.INFO' | translate: { count: data.count } }}
        </div>
        <div class="p-3">
            <table>
                <tr *ngFor="let item of data.changes">
                    <td class='name'>
                        {{ item.name }}
                    </td>
                    <td  class='value'>
                        {{ item.value }}
                    </td>
                </tr>
            </table>
        </div>

        <div>
            {{ 'ADMIN.MODALS.BULK_APPLY_CONFIRM.CONFIRM' | translate }}
        </div>

    </div>
</kui-modal>