import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'snackbarAnimation'
})
export class KuiSnackbarAnimationPipe implements PipeTransform {
    transform(duration: number, pause: boolean): string {
        if (pause || !duration || duration < 0) {
            return 'none';
        }
        return `fadeout ${duration / 1000}s ease-in 2s forwards`;
    }
}