import { trigger, style, animate, transition, query, animateChild, animation, useAnimation, AnimationReferenceMetadata, AnimationTriggerMetadata } from '@angular/animations';

/*
* wait for animations to finish within a component that uses ngIf.
* add ngIfAnimation to the parent component containing the ngIf.
*/
export const ngIfAnimation: AnimationTriggerMetadata = trigger('ngIfAnimation', [
    transition(':enter, :leave', [
        query('@*', animateChild()),
    ]),
]);

export const slideInOutAnimation: AnimationReferenceMetadata = animation([
    style({ transform: 'translate3d({{start}}, 0, 0)' }),
    animate('{{duration}} {{easing}}', style({ transform: 'translate3d({{end}}, 0, 0)' })),
], {
    params: {
        easing: 'cubic-bezier(0.47, 0, 0.745, 0.715)',
        duration: '.2s',
        start: '100%',
        end: 0,
}});

export const slideInOut: AnimationTriggerMetadata = trigger('slideInOut', [
    transition('void => right', [ useAnimation(slideInOutAnimation, { params: { start: '100%', end: 0 } }) ]),
    transition('right => void', [ useAnimation(slideInOutAnimation, { params: { start: 0, end: '100%' } }) ]),

    transition('void => left', [ useAnimation(slideInOutAnimation, { params: { start: '-100%', end: 0 } }) ]),
    transition('left => void', [ useAnimation(slideInOutAnimation, { params: { start: 0, end: '-100%' } }) ]),
]);
