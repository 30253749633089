import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KuiHeaderComponent } from './header.component';
import { KuiHeaderBrandComponent } from './header-brand.component';
import { KuiHeaderNavComponent } from './header-nav.component';
import { KuiHeaderNavButtonsComponent } from './header-nav-buttons.component';
import { KuiHeaderNavLinksComponent } from './header-nav-links.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    KuiHeaderComponent,
    KuiHeaderBrandComponent,
    KuiHeaderNavComponent,
    KuiHeaderNavButtonsComponent,
    KuiHeaderNavLinksComponent,
  ],
  declarations: [
    KuiHeaderComponent,
    KuiHeaderBrandComponent,
    KuiHeaderNavComponent,
    KuiHeaderNavButtonsComponent,
    KuiHeaderNavLinksComponent,
  ],
})
export class KuiHeaderModule {  }
