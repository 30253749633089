import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, OnDestroy, output, Signal, signal, WritableSignal, type OnInit } from '@angular/core';
import { EventFilterCondition } from '@key-telematics/fleet-api-client';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'app/app.service';
import { KuiActionModule, KuiDropdownModule, KuiIconModule } from 'app/key-ui';
import { KuiDropdownComponent } from 'app/key-ui/dropdown/dropdown.component';
import { KuiLoaderModule } from 'app/key-ui/loader/loader.module';
import { KuiTreeSelectNode } from 'app/key-ui/tree-select/tree-select.component';
import { EventFilterService, MeasurementUnitsService } from 'app/services';
import * as moment from 'moment-timezone';
import { from, mergeMap, Subject, takeUntil } from 'rxjs';
import { KeyFormBuilderEventFilterConditionPrefixPipe } from '../condition-prefix.pipe';
import { KeyFormBuilderEventFilterSearchableTreeComponent } from '../searchable-tree/searchable-tree.component';
import { ChecklistFormatPipe } from './checklist-format.pipe';


export interface Part {
    key: string;
    order: number;
    type: string;
    text: any;
    value?: any;
    values?: any[];
    unit?: string;
    linkedTo?: string;
}

@Component({
    selector: 'key-form-builder-eventfilter-condition',
    standalone: true,
    imports: [
        CommonModule,
        KuiIconModule,
        KuiDropdownModule,
        KeyFormBuilderEventFilterSearchableTreeComponent,
        KuiLoaderModule,
        KuiActionModule,
        KeyFormBuilderEventFilterConditionPrefixPipe,
        ChecklistFormatPipe,
    ],
    templateUrl: './condition.component.html',
    styleUrl: './condition.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyFormBuilderEventFilterConditionComponent implements OnInit, OnDestroy {

    filterCondition = input<EventFilterCondition>();
    index = input<number>();
    ownerId = input<string>();

    removed = output<EventFilterCondition>();
    changed = output();


    orderedParts: Signal<Part[]> = computed(() => {
        return this.parts().sort((a: Part, b: Part) => {
            return a.order - b.order;
        });
    });

    private parts: WritableSignal<Part[]> = signal([]);

    private updatePart$ = new Subject<{
        key: string,
        index: number
    }>();
    private destroyed$ = new Subject<boolean>();

    constructor(
        private app: AppService,
        private i18n: TranslateService,
        private units: MeasurementUnitsService,
        private filters: EventFilterService,
    ) { }

    ngOnInit(): void {
        this.parseCondition(this.filterCondition());
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
    }

    parseCondition(filterCondition: EventFilterCondition): void {
        const text = this.i18n.instant(`SHARED.ALERTS.CONDITIONS.${filterCondition.type.toUpperCase()}.TEXT`) as string;
        const textParts = text.split(' '); // "time is {mode} {time1} and {time2} on {dow}"

        // Set the initial parts array with some values,
        // any parts that need information loaded are marked as 'placeholder' types
        this.parts.set(
            textParts.map((part, index) => {
                let key = part;
                let isPlaceholder = false;
                if (part.startsWith('{')) {
                    key = part.replace(/[\{\}]/g, '');
                    isPlaceholder = true;
                }

                // check to see if we already know what value the placeholder will have
                const text = this.filterCondition().text[key] ?? part;
                return {
                    key: key,
                    order: index,
                    type: isPlaceholder ? 'placeholder' : 'text',
                    text: text,
                }
            })
        );

        // start listening for any requested updates to parts
        this.updatePart$.pipe(
            takeUntil(this.destroyed$),
            mergeMap(({ key, index }) => from(this.loadPartInformation(key, index)))
        ).subscribe(newPart => {
            this.parts.update(parts => {
                return [...parts.filter(part => part.order !== newPart.order), newPart];
            });
        });

        // request an update for each part
        textParts.forEach((part, index) => {
            this.updatePart$.next({ key: part, index })
        });
    }

    private async loadPartInformation(part: string, index: number): Promise<Part> {
        if (part.startsWith('{')) {
            const key = part.replace(/[\{\}]/g, '');
            const options = await this.filters.getConditionOptions(this.filterCondition().type, key, this.filterCondition().values);
            if (options != null) {
                const result: Part = {
                    key: key,
                    order: index,
                    type: options.type,
                    text: this.filterCondition().text[key] || this.i18n.instant(`SHARED.ALERTS.CONDITIONS.${this.filterCondition().type.toUpperCase()}.${key.toUpperCase()}`),
                    value: this.filterCondition().values[key],
                    values: options.values as any[],
                    unit: null,
                };
                if (options.linkedTo) {
                    result.linkedTo = options.linkedTo;
                }
                switch (options.type) {
                    case 'checklist':
                        const vals = this.filterCondition().values[key] || [];
                        result.values = result.values.map(item => ({
                            text: item.name,
                            type: 'toggle',
                            checked: vals.indexOf(item.id) > -1,
                            action: (event: any) => this.onConditionToggled(item, event.target.checked, result),
                        }));
                        break;
                    case 'distance':
                        result.unit = this.units.format(0, 'distance').unit;
                        break;
                    case 'duration':
                        result.unit = this.i18n.instant('SHARED.DATETIME.SECONDS').toLowerCase();
                        break;
                    case 'speed':
                        result.unit = this.units.format(0, 'speed').unit;
                        break;
                }
                return result;
            }
        }
        return Promise.resolve<Part>({
            key: '',
            order: index,
            type: 'text',
            text: part,
        });
    }

    onConditionOptionSelected(dropdown: KuiDropdownComponent, event: KuiTreeSelectNode, part: Part) {
        if (event.eventName === 'activate') {
            const item = event.node.data;

            let id = item.id;
            let text = item.name;

            if (this.filterCondition().type === 'zone' && item.data && item.data.targetTypeId) { // if this is a target filter, create a special kind if id (yes, i know).
                id = `${item.data.targetTypeId}:${item.data.targetSelectionType}:${item.data.targetId}`;
                text = item.data.text;
            }

            if (this.filterCondition().type === 'linked' && item.data && item.data.actorTypeId) { // if this is a actor filter, create a special kind if id (yes, i know).
                id = `${item.data.actorTypeId}:${item.data.actorSelectionType}:${item.data.actorId}`;
                text = item.data.text;
            }

            part.value = id;
            part.text = text;

            this.filterCondition().values[part.key] = part.value;
            this.filterCondition().text[part.key] = part.text;

            // we've just changed something thats linked to something else. We must reset the data
            if (part.linkedTo) {
                this.filterCondition().values[part.linkedTo] = null;
                this.filterCondition().text[part.linkedTo] = null;

                const linkedPart = this.parts().find(p => p.key === part.linkedTo);

                this.updatePart$.next({
                    key: `{${part.linkedTo}`,
                    index: linkedPart.order
                });
            }

            dropdown.toggle();
            this.changed.emit();
        }
    }

    onConditionEditorUpdated(dropdown: KuiDropdownComponent, value: string, part: Part) {
        if (this.filterCondition().type === 'time') { // time editors require some special handling

            if (value.length === 5) {
                value = value + ':00';
            }
            // if the user enters a nonsense time, then just reset it to 00:00:00
            if (!moment(value, 'HH:mm:ss', true).isValid()) {
                value = '00:00:00';
            }

            delete this.filterCondition().values['timeZone'];
            this.filterCondition().values['timeZoneId'] = this.app.user.timeZoneId;
        }

        if (this.filterCondition().type === 'speed' || this.filterCondition().type === 'speedlimit') {
            this.filterCondition().values['multiplier'] = this.units.format(0, 'speed').multiplier;
        }
        if (this.filterCondition().type === 'distance') {
            this.filterCondition().values['multiplier'] = this.units.format(0, 'distance').multiplier;
        }

        part.value = value;
        part.text = value + (part.unit ? ' ' + part.unit : '');

        this.filterCondition().values[part.key] = part.value;
        this.filterCondition().text[part.key] = part.text;
        dropdown.toggle();
        this.changed.emit();
    }

    onConditionToggled(item: { id: string, name: string }, value: boolean, part: Part) {
        // this is some really shitty code, but time pressure means it's the best it's gonna get for now
        part.value = part.value || [];
        part.text = Array.isArray(part.text) ? part.text : [];
        if (value) {
            part.value.push(item.id);
            part.text.push(item.name);
        } else {
            part.value.splice(part.value.indexOf(item.id), 1);
            part.text.splice(part.text.indexOf(item.name), 1);
        }

        this.filterCondition().values[part.key] = part.value;
        this.filterCondition().text[part.key] = part.text;
        this.changed.emit();
    }

    removeCondition() {
        this.removed.emit(this.filterCondition());
    }

    isValid(): boolean {
        return this.parts().filter(part => part.type !== 'text').every(part => part.value);
    }

}
