<ng-container *ngIf="data">
    <kui-alert-panel *ngIf="errorMessage; else statMarkup"
        [message]="errorMessage"></kui-alert-panel>

    <ng-template #statMarkup>
        <div class="stat stat--{{size}}">
            <div class="stat__value">
                <svg viewBox="0 0 100 12"
                    *ngIf="data.targetValue && data.targetValue !== 0"
                    class="stat__value-text">
                    <text x="80%"
                        y="10" [attr.fill]="data.color">{{data.targetValue}}</text>
                </svg>
                <svg [attr.viewBox]="data.targetValue && data.targetValue !== 0 ? '0 0 90 12': '0 0 56 12'"
                    class="stat__value-text">
                    <text x="50%"
                        y="10">{{stat}}</text>
                </svg>
                <div *ngIf="data.data === 'last' && trend?.visible"
                    class="stat__trend">
                    <kui-icon [name]="trend?.icon"
                        [color]="trend?.color"
                        [size]="size === 'large' ? 'md': 'sm'"></kui-icon>
                    <span class="ml-1">{{trend?.value}}</span>
                </div>
            </div>
        </div>

        <key-graph *ngIf="data.sparkline && dataset"
            [datasets]="[dataset]"
            [xAxis]="{ hidden: true }"
            [yAxes]="{ left: sparklineYAxis }"
            [averages]="[sparklineAverage]"
            tooltipMode="none"
            [labels]="data.labels"></key-graph>
    </ng-template>
</ng-container>