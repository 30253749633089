import { Component, Input, ChangeDetectionStrategy, HostBinding } from '@angular/core';

@Component({
  selector: 'key-listing-avatar',
  templateUrl: './listing-avatar.component.html',
  styleUrls: ['./listing-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyListingAvatarComponent {
  @Input() type: 'icon' | 'image';
  @Input() url: string;
  @Input() icon: string;

  @HostBinding('class') hostClasses = 'listing__icon';


}
