<kui-select
    *ngIf="selectionValues"
    [options]="selectionValues"
    (change)="changeSelection($event)"
    class="color-indicator"
    [class.show]="selectedSeriesColor"
    [style.border-color]="selectedSeriesColor"
></kui-select>
<key-form-builder
    *ngIf="values"
    [form]="form"
    class="d-block mt-3"
    [values]="values"
    autoTranslatePrefix="ANALYTICS.SETTINGS"
    (onChange)="changeFormSetting($event)"
></key-form-builder>

<!-- RESET SELECTED SERIES -->
<div
    class="d-flex align-items-center"
    *ngIf="showReset(options.selection.selected)">
    <button
        class="reset"
        kui-button
        (click)="resetSeries(options.selection.selected)">
        {{ 'SHARED.RESET_ENTITY' | translate: { entity: getCurrentOverriddenText(options.selection.selected) } }}
    </button>
    <kui-icon
        kui-action
        class="ml-2 muted-more"
        name="trash-alt"
        size="sm"
        [title]="'SHARED.REMOVE_ENTITY' | translate: { entity: getCurrentOverriddenText(options.selection.selected) }"
        (click)="removeSeries(options.selection.selected)"></kui-icon>
</div>

<!-- VALUES OVERRIDDEN MESSAGE ON DEFAULT SELECTION -->
<kui-alert
    type="info"
    class="key-alert-wrapper"
    *ngIf="overriddenValues && !!overriddenValues.length && options.selection.selected === 'default'"
>
    <kui-icon
        class="align-self-start mt-2"
        name="lightbulb"
        size="md"
    ></kui-icon>
    <div>
        <div class="alert-text-container">
            <ng-container *ngIf="overriddenValues.length === 1">
                <strong class="alert-link" (click)="changeSelection(overriddenValues[0])">{{ overriddenValues[0] | titlecase }}</strong>
                {{ 'ANALYTICS.SETTINGS.OVERRIDDEN_SINGLE' | translate }}
            </ng-container>

            <ng-container *ngIf="overriddenValues.length > 1">
                <ng-container *ngFor="let value of overriddenValues; let l = last;">
                    <strong class="alert-link" (click)="changeSelection(value)">{{ value | titlecase }}</strong>
                    <strong *ngIf="!l">, </strong>
                </ng-container>
                {{ 'ANALYTICS.SETTINGS.OVERRIDDEN_MULTIPLE' | translate }}
            </ng-container>
        </div>

        <button
            kui-button
            size="sm"
            class="mt-2"
            (click)="onClear.emit(overriddenValues)"
        >
            {{ 'ANALYTICS.SETTINGS.CLEAR_ALL' | translate }}
        </button>
    </div>
</kui-alert>
