export function getLayoutIconSvgString(type: string) {

    const getTable = (tableString: string): string => {
        /* tslint:disable */
        return `<svg viewBox="0 0 65 81" class="settings-icon" version="1.1">
            <rect x="0.5" y="0.5" width="64" height="80" opacity="0.3" fill="none"/>
            <g opacity="0.3">
                <rect width="16.8235" height="2.67033" transform="translate(3.05859 3.56055)" stroke="none" />
                <rect width="17.8235" height="8.9011" transform="translate(44.1172 3.56055)" stroke="none" />
                <rect width="38.7647" height="1.78022" transform="translate(3.05859 8.01123)" stroke="none" />
                <rect width="38.7647" height="1.78022" transform="translate(3.05859 10.6812)" stroke="none" />
            </g>
            <path opacity="0.3" d="M7.64706 0L14.5294 8.45604L24.0882 2.67033L31.7353 9.79121L46.2647 4.00549L58.8824 16.467H0L7.64706 0Z" transform="translate(3.05859 16.4668)" stroke="none"/>
            ${tableString}
        </svg>`;
        /* tslint:enable */
    };

    switch (type) {
        case 'table-data':
            /* tslint:disable */
            return getTable(`
                <rect width="58.8824" height="4.23077" transform="translate(3.05859 37.6045)" stroke="none"/>
                <rect width="8.88235" height="1.23077" transform="translate(14.0586 43.6045)" stroke="none"/>
                <rect width="8.88235" height="1.23077" transform="translate(3.05859 43.6045)" stroke="none"/>
                <rect width="8.88235" height="1.23077" transform="translate(25.0586 43.6045)" stroke="none"/>
                <rect width="25.8824" height="1.23077" transform="translate(36.0586 43.6045)" stroke="none"/>
                <rect width="8.88235" height="1.23077" transform="translate(14.0586 46.6045)" stroke="none"/>
                <rect width="8.88235" height="1.23077" transform="translate(3.05859 46.6045)" stroke="none"/>
                <rect width="8.88235" height="1.23077" transform="translate(25.0586 46.6045)" stroke="none"/>
                <rect width="25.8824" height="1.23077" transform="translate(36.0586 46.6045)" stroke="none"/>
                <rect width="8.88235" height="1.23077" transform="translate(14.0586 49.6045)" stroke="none"/>
                <rect width="8.88235" height="1.23077" transform="translate(3.05859 49.6045)" stroke="none"/>
                <rect width="8.88235" height="1.23077" transform="translate(25.0586 49.6045)" stroke="none"/>
                <rect width="25.8824" height="1.23077" transform="translate(36.0586 49.6045)" stroke="none"/>
                <rect width="58.8824" height="4.23077" transform="translate(3.05859 52.6045)" stroke="none"/>
                <rect width="8.88235" height="1.23077" transform="translate(14.0586 58.6045)" stroke="none"/>
                <rect width="8.88235" height="1.23077" transform="translate(3.05859 58.6045)" stroke="none"/>
                <rect width="8.88235" height="1.23077" transform="translate(25.0586 58.6045)" stroke="none"/>
                <rect width="25.8824" height="1.23077" transform="translate(36.0586 58.6045)" stroke="none"/>
                <rect width="8.88235" height="1.23077" transform="translate(14.0586 61.6045)" stroke="none"/>
                <rect width="8.88235" height="1.23077" transform="translate(3.05859 61.6045)" stroke="none"/>
                <rect width="8.88235" height="1.23077" transform="translate(25.0586 61.6045)" stroke="none"/>
                <rect width="25.8824" height="1.23077" transform="translate(36.0586 61.6045)" stroke="none"/>
                <rect width="8.88235" height="1.23077" transform="translate(14.0586 64.6045)" stroke="none"/>
                <rect width="8.88235" height="1.23077" transform="translate(3.05859 64.6045)" stroke="none"/>
                <rect width="8.88235" height="1.23077" transform="translate(25.0586 64.6045)" stroke="none"/>
                <rect width="25.8824" height="1.23077" transform="translate(36.0586 64.6045)" stroke="none"/>
                <rect width="58.8824" height="4.23077" transform="translate(3.05859 67.6045)" stroke="none"/>
                <rect width="8.88235" height="1.23077" transform="translate(14.0586 73.6045)" stroke="none"/>
                <rect width="8.88235" height="1.23077" transform="translate(3.05859 73.6045)" stroke="none"/>
                <rect width="8.88235" height="1.23077" transform="translate(25.0586 73.6045)" stroke="none"/>
                <rect width="25.8824" height="1.23077" transform="translate(36.0586 73.6045)" stroke="none"/>
                <rect width="8.88235" height="1.23077" transform="translate(14.0586 76.6045)" stroke="none"/>
                <rect width="8.88235" height="1.23077" transform="translate(3.05859 76.6045)" stroke="none"/>
                <rect width="8.88235" height="1.23077" transform="translate(25.0586 76.6045)" stroke="none"/>
                <rect width="25.8824" height="1.23077" transform="translate(36.0586 76.6045)" stroke="none"/>
            `);
            /* tslint:enable */

        case 'table-pivot':
            /* tslint:disable */
            return getTable(`
                <rect x="0.5" y="0.5" width="57.8824" height="4.9329" transform="translate(3.05859 37.6045)"/>
                <rect x="0.5" y="0.5" width="57.8824" height="5.40846" transform="translate(3.05859 48.6045)" fill="none"/>
                <rect x="0.5" y="0.5" width="57.8824" height="5.40846" transform="translate(3.05859 59.6045)" fill="none"/>
                <rect x="0.5" y="0.5" width="57.8824" height="5.40846" transform="translate(3.05859 71.6045)" fill="none"/>
                <rect x="0.5" y="0.5" width="28.4667" height="39.8352" transform="translate(3.05859 37.6045)" fill="none"/>
                <rect x="0.5" y="0.5" width="14.6" height="39.8352" transform="translate(3.05859 37.6045)" fill="none"/>
                <rect x="0.5" y="0.5" width="6.6" height="39.8352" transform="translate(3.05859 37.6045)"/>
                <rect x="0.5" y="0.5" width="29.3333" height="39.8352" transform="translate(31.6592 37.6045)" fill="none"/>
                <rect x="0.5" y="0.5" width="14.6" height="39.8352" transform="translate(31.6592 37.6045)" fill="none"/>
            `);
            /* tslint:enable */

        default:
            return `<svg></svg>`;
    }
}
