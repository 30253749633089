<kui-content-header [icon]="mobileNavigationAction?.icon"
    class="mobile-header"
    (action)="mobileNavigationAction?.click()"
    [tabs]="tabs"
    [activeTab]="activeTab"
    [title]="isMobile ? sectionTitle : (style === 'header' && pageTitle)"
    [actions]="actions">

    <!-- LOGO -->
    <div kui-content-header-custom-content
        class="logo ml-2"
        [ngClass]="{
            'logo--left': logoStyle === 'left',
            'logo--right': logoStyle === 'right',
            'mr-2': logoStyle === 'right'
        }"
        *ngIf="!isMobile && logo && style !== 'hidden' && logoStyle !== 'none'">
        <img [src]="logo"
            alt="logo"
            (error)="logo = null">
    </div>

    <!-- DESKTOP NAVIGATION -->
    <div *ngIf="!isMobile && style === 'navigation'"
        kui-content-header-title
        class="d-flex align-items-center top-nav-navigation">

        <kui-dropdown class="mr-3"
            #navdropdown
            [style]="'none'"
            classNames="top-nav-dropdown-menu"
            [customContent]="topNavDropdownMenuAction"
            [customContentContext]="{ component: navdropdown }">

            <div kui-content-header-title
                class="d-flex align-items-center">
                <kui-icon [size]="1.5"
                    class="page-icon pr-2"
                    [fixedWidth]="true"
                    [name]="pageIcon"></kui-icon>
                <span>
                    {{ pageTitle }}
                </span>
                <kui-icon class="ml-2 title__caret-icon"
                    name="caret-down"></kui-icon>
            </div>
        </kui-dropdown>
        <ng-template #topNavDropdownMenuAction
            let-component="component">
            <div class="top-nav-dropdown">

                <div *ngFor="let group of navigationGroups; let idx = index">
                    <div class='top-nav-dropdown-title muted'>
                        {{ group.name }}</div>

                    <div class='top-nav-dropdown-section'>
                        <div *ngFor="let item of group.items"
                            class="nav-section__item">
                            <a kui-action
                                kui-nav-link
                                key-page-nav-link
                                [page]="item.id"
                                [isParent]="!!item.children?.length"
                                (clicked)="$event === 'navigate' && component.toggle()">
                                <kui-icon [size]="1.5"
                                    [fixedWidth]="true"
                                    [name]="item.icon"></kui-icon>
                                <span>{{ item.name }}</span>
                                <kui-icon *ngIf="!!item.children?.length"
                                    name="chevron-right"
                                    class="top-nav-dropdown-arrow"></kui-icon>
                            </a>
                            <div kui-nav-sub
                                *ngIf="!!item.children?.length">
                                <a *ngFor="let child of item.children"
                                    kui-nav-link
                                    kui-action
                                    key-page-nav-link
                                    [page]="item.id"
                                    [child]="child.id"
                                    (clicked)="$event === 'navigate' && component.toggle()">
                                    <span kui-nav-link-text>{{ child.name }}</span>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </ng-template>
    </div>

    <kui-content-header-actions>
        <!-- TOPNAV SPECIFIC ACTIONS -->
        <kui-dropdown *ngIf="user && style === 'navigation'"
            class="mr-2 ml-2"
            #profiledropdown
            [style]="'none'"
            classNames="top-nav-dropdown-menu"
            [customContent]="topNavDropdownSubAction"
            [customContentContext]="{ component: profiledropdown }">

            <div kui-action
                class="user-icon">
                <img *ngIf="avatar"
                    src="{{avatar}}"
                    class="profile-image d-inline-block"
                    alt="{{ user.name }}"
                    (error)="avatar = null">
                <div *ngIf="!avatar"
                    class="default-pic profile-image-text d-inline-block bg-primary color-text-inverse text-center">
                    <strong>{{ user.name[0] }}</strong>
                </div>
                <div *ngIf="notification"
                    class="user-icon__notification"></div>
            </div>

        </kui-dropdown>
        <ng-template #topNavDropdownSubAction
            let-component="component">

            <div class="top-nav-dropdown">


                <ng-container *ngIf="user.owner.type !== 'client'">
                    <div class='top-nav-dropdown-title muted'>
                        {{ clientname }}</div>
                    <div class='top-nav-dropdown-section'>
                        <a kui-action
                            (click)="onProfileAction.emit('client-select'); component.toggle()">
                            <kui-icon [size]="1"
                                [fixedWidth]="true"
                                name="home"></kui-icon>
                            <span>{{ 'MENU.CHANGE_CLIENT' | translate }}</span>
                        </a>
                    </div>
                </ng-container>

                <div class='top-nav-dropdown-title muted'>
                    {{ user.name }}</div>
                <div class='top-nav-dropdown-section'>
                    <a kui-action
                        (click)="onProfileAction.emit('profile'); component.toggle()">
                        <kui-icon [size]="1"
                            [fixedWidth]="true"
                            name="user"></kui-icon>
                        <span>{{ 'MENU.PROFILE' | translate }}</span>
                    </a>
                    <a kui-action
                        (click)="onProfileAction.emit('notifications'); component.toggle()">
                        <kui-icon [size]="1"
                            [fixedWidth]="true"
                            [notification]="notification"
                            name="bells"></kui-icon>
                        <span>{{ 'MENU.USER_NOTIFICATIONS' | translate }}</span>
                    </a>
                    <a kui-action
                        (click)="onProfileAction.emit('logout'); component.toggle()">
                        <kui-icon [size]="1"
                            [fixedWidth]="true"
                            name="power-off"></kui-icon>
                        <span>{{ 'MENU.LOGOUT' | translate }}</span>
                    </a>
                </div>

            </div>
        </ng-template>

    </kui-content-header-actions>
</kui-content-header>