<kui-card-header [active]="active" (click)="onClick.emit()">
    <kui-card-title>
        <kui-icon *ngIf="icon" [name]="icon" class="mr-1"></kui-icon>
        {{ title }}
    </kui-card-title>
    <ul class="content-card-actions">
        <li>
            <kui-icon name="chevron-up"></kui-icon>
        </li>
    </ul>
</kui-card-header>
<kui-card-body>
    <kui-card-text>
        <ng-content></ng-content>
    </kui-card-text>
</kui-card-body>