import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { DataTableSection, DataTableCellset } from 'app/shared/components/analytics/data-table/data-table.component';
import { toTitleCase, removeSpaces } from 'app/shared/utils/string.utils';
import { AnalyticsCellValue, KeyValue } from '../analytics.model';

export interface TablesData {
    cellset: AnalyticsCellValue[][];
    rowLevels: number;
    columnLevels: number;
    measures: KeyValue[];
    fixedHeaderTitle: string;
    colors: { [key: string]: string };
}

@Component({
    selector: 'key-analytics-tables',
    templateUrl: './tables.component.html',
})
export class AnalyticsTablesComponent implements OnChanges {
    formattedCellset: AnalyticsCellValue[][] | DataTableSection[];

    @Input() selectedMeasure: string;
    @Input() type: 'data' | 'pivot';
    @Input() data: TablesData;
    @Input() section: string;
    @Output() onMeasureSelection = new EventEmitter<string>();

    constructor(
    ) { }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.data || changes.section) {
            const { cellset, rowLevels, measures, fixedHeaderTitle, columnLevels } = this.data;
            this.formattedCellset = this.type === 'data'
                ? this.formatCellsetToDataTable(cellset, rowLevels, measures, fixedHeaderTitle)
                : this.formatCellsetToPivotTable(cellset, rowLevels, columnLevels, measures);
        }
    }

    // each data table row in DataTableSection[index].table.section makes up a single meaure column and its values.
    // I decided on this as it is the easiest way to keep each column width the same while using a flexbox model
    formatCellsetToDataTable(cellset: AnalyticsCellValue[][], rowHeaderLevels: number, measures: KeyValue[], fixedHeaderTitle: string): DataTableSection[] {
        const cells = [...cellset];

        let colHeaderLevels = 0;
        for (let i = 0; i < cells.length; i++) {
            const row = cells[i];
            if (row[0].type !== 'COLUMN_HEADER' && row[0].type !== 'ROW_HEADER_HEADER') { break; }
            colHeaderLevels++;
        }

        const rows = cells.splice(0, colHeaderLevels);
        const titles: Map<string, { title: string, subTitles: string[] }> = new Map();

        // the second last row will be the title and all the ones before it will make up the subTitle
        rows[colHeaderLevels - 2]
            .filter(row => row.type === 'COLUMN_HEADER' && !this.isNonValue(row.value.toString(), 'column'))
            .forEach(row => {
                const key = row.properties.uniquename;
                if (!titles.has(key)) {
                    const subTitles = decodeURIComponent(row.properties.uniquename).split('|');
                    subTitles.pop();

                    titles.set(key, {
                        title: row.value.toString(),
                        subTitles: subTitles.filter(item => item && !this.isNonValue(item, 'column')),
                    });
                }
            });

        const measureLength = measures.length;
        // headers that will be displayed by each table
        const headers = [...rows[colHeaderLevels - 1]].slice(rowHeaderLevels, rows[colHeaderLevels - 1].length);

        const filterBySection = ({ title }) => {
            switch (this.section) {
                case 'AVERAGE':
                case 'TOTAL':
                    return title === this.section;
                default:
                    return title !== 'AVERAGE' && title !== 'TOTAL';
            }
        };

        return [...titles].map(x => x[1]) // returns the values { title: string, subTitles: string[] }
            .reduce((list: DataTableSection[], title, index) => {
                const table: DataTableCellset[] = [];
                let tableIndex = 0;

                // make index 1 base and not 0 base, because the measureLength also starts at 1
                index++;

                // first add the headers
                table[tableIndex] = {
                    section: [
                        [{ value: toTitleCase(fixedHeaderTitle), type: 'ROW_HEADER_HEADER', properties: {} }],
                        ...headers
                            .slice((index * measureLength) - measureLength, index * measureLength)
                            .map(x => {
                                x.properties['color'] = this.data.colors[removeSpaces(x.value.toString().toLowerCase())];
                                return [x];
                            }),
                    ],
                };

                cells.forEach(row => {
                    const data = [...row];

                    const rowHeaders = data.splice(0, rowHeaderLevels);
                    // get the last level item of rowHeader
                    const rowHeader = rowHeaders[rowHeaderLevels - 1];
                    // get the table section header from its properties.uniquename
                    const uniquename = decodeURIComponent(rowHeader.properties.uniquename).split('|');
                    const tableHeaderValue = uniquename
                        .filter((_x, i) => i !== uniquename.length - 1) // remove actual value
                        .filter(x => x !== 'All') // remove all
                        .join(' ');

                    // if tableHeaderValue is not the same as our current table section create a new section
                    if (!!tableHeaderValue && table[tableIndex].header !== tableHeaderValue) {
                        tableIndex++;
                        table[tableIndex] = {
                            header: tableHeaderValue,
                            section: [
                                [],
                                ...headers
                                    .slice((index * measureLength) - measureLength, index * measureLength)
                                    .map(() => []), // create an array count of the amount of headers with empty buckets to push to in every iteration
                            ],
                        };
                    }

                    const scrollableColumns = data.slice((index * measureLength) - measureLength, index * measureLength);

                    // don't display TOTAL column on AVERAGE table and vice versa
                    if (row.find(x => x.value === 'TOTAL') && title.title === 'AVERAGE' || row.find(x => x.value === 'AVERAGE') && title.title === 'TOTAL') {
                        return;
                    }

                    // reduce scrollableColumns into one column and add to the fixed list
                    table[tableIndex].section[0].push(rowHeader);

                    const isTotalOrAverage = row.find(x => x.value === 'TOTAL' || x.value === 'AVERAGE');
                    scrollableColumns.forEach((column, i) => {
                        column.properties['color'] = isTotalOrAverage && 'color-bg-table-header';
                        // we need to start the index at 1, because 0 is where rowHeader goes
                        table[tableIndex].section[++i].push(column);
                    });
                });

                return [
                    ...list,
                    {
                        ...title,
                        table,
                    },
                ];
            }, [])
            .filter(filterBySection);

    }

    formatCellsetToPivotTable(cellset: AnalyticsCellValue[][], rowHeaderLevels: number, columnHeaderLevels: number, measures: KeyValue[]): AnalyticsCellValue[][] {
        const rowHeaderValues: Set<string> = new Set();

        // handle any classnames you'd like to add to a specific column in here
        const addClassnames = (classnames: string[] = [], index: number, exclude: string[] = []): string[] => {
            if (!((index - rowHeaderLevels) % measures.length)) {
                classnames.push('section-divider');
            }
            return classnames.filter(x => !exclude.includes(x));
        };

        const filterBySection = row => {
            const rowHeaders = row.slice(0, rowHeaderLevels + 1);
            switch (this.section) {
                case 'AVERAGE':
                    return [...rowHeaders, ...row.slice(row.length - measures.length, row.length)];
                case 'TOTAL':
                    return [...rowHeaders, ...row.slice(row.length - (measures.length * 2), row.length - measures.length)];
                default:
                    return [...rowHeaders, ...row.slice(rowHeaderLevels + 1, row.length - (measures.length * 2))];
            }
        };

        // we find the column that contains 'All' using the row and column levels
        const hasAllValue = {
            row: cellset[0][rowHeaderLevels].value === 'All',
            column: rowHeaderLevels !== 1 && cellset[columnHeaderLevels][0].value === 'All',
        };

        const removeFirstFromArray = (arr: any[], predicate: boolean) => {
            return predicate ? arr.slice(1, arr.length) : arr;
        };

        // reduce levels by 1 if they had an all on them
        rowHeaderLevels = rowHeaderLevels - (+hasAllValue.column);
        columnHeaderLevels = columnHeaderLevels - (+hasAllValue.row);

        return removeFirstFromArray(cellset, hasAllValue.row)
            .map(filterBySection)
            .map(row => removeFirstFromArray(row, hasAllValue.column))
            .reduce((rows: AnalyticsCellValue[][], row: AnalyticsCellValue[]) => {
                if (row[0].type === 'COLUMN_HEADER') {
                    const columns: Map<string, AnalyticsCellValue> = new Map();

                    // lookup how many times the same column is in the row and add it to colspan which will become the colspan of that column
                    row.forEach((column: AnalyticsCellValue, index: number) => {
                        const { uniquename } = column.properties;
                        let { value } = column;
                        value = value.toString();
                        column.properties['classnames'] = addClassnames(column.properties.classnames, index);

                        if (columns.has(uniquename)) {
                            const col = columns.get(uniquename);
                            columns.set(uniquename, {
                                ...col,
                                properties: {
                                    ...col.properties,
                                    colspan: ++col.properties.colspan,
                                },
                            });
                        } else {
                            columns.set(uniquename, {
                                ...column,
                                // handle null values
                                value: value === 'null' ? null : value,
                                properties: {
                                    ...column.properties,
                                    colspan: 1,
                                },
                            });
                        }
                    });

                    const values = [...columns].map(([_key, column]) => column);
                    // only push rows that contains columns with actual values, so empty strings and null columns will return false here
                    if (!!values.filter(x => !!x.value).join()) {
                        rows.push(values);
                    }

                    return rows;
                }

                // handle table cells here
                rows.push(row.reduce((columns: AnalyticsCellValue[], column: AnalyticsCellValue, index: number) => {
                    column.properties['classnames'] = addClassnames(column.properties.classnames, index, [
                        column.type === 'ROW_HEADER' || column.type === 'ROW_HEADER_HEADER' ? 'section-divider' : '',
                    ]);

                    // add colors to the last row that contains COLUMN_HEADER cells... These are the measures
                    if (column.type === 'COLUMN_HEADER') {
                        column.properties['color'] = this.data.colors[removeSpaces(column.value && column.value.toString().toLowerCase())];
                    }

                    // for row header that is not the lowest down we have to check if we've rendered the header already in which case we empty the extra ones
                    if (column.type === 'ROW_HEADER' && index < rowHeaderLevels - (+hasAllValue.column)) {
                        const getValue = (c) => {
                            if (!c.value || rowHeaderValues.has(c.value)) {
                                return null;
                            }
                            rowHeaderValues.add(c.value);
                            return c.value;
                        };
                        column.value = getValue(column);
                    }

                    if (row.find(x => x.value === 'TOTAL' || x.value === 'AVERAGE')) {
                        column.properties['color'] = 'color-bg-table-header';
                    }

                    return [...columns, column];
                }, []));

                return rows;

            }, []);
    }

    isNonValue(value: string, dim: 'row' | 'column'): boolean {
        const nonValues = new Set(['null', 'All']);
        if (value === 'All') {
            return this.data[`${dim}Levels`] !== 1;
        }
        return nonValues.has(value);
    }

    emitMeasureSelection(event: AnalyticsCellValue) {
        const measure = removeSpaces(event.value.toString().toLowerCase());
        this.onMeasureSelection.emit(measure);
    }

}
