<div class="search-results"
    [style.max-height]="maxHeight + 'px'"
    #results>
    <div *ngFor="let item of list"
        kui-action
        class="p-2 search-results__item"
        [class.active]="item.active"
        (click)="selectResult(item)">
        <kui-icon *ngIf="item.icon"
            [name]="item.icon"
            size="sm"
            class="mr-3 muted-more"></kui-icon>
        <span [innerHTML]="highlightTerm(item.value)"></span>
    </div>
</div>