import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { KuiDropdownComponent } from './dropdown.component';
import { KuiDropdownButtonComponent } from './dropdown-button.component';
import { KuiButtonModule } from './../button';
import { KuiIconModule } from './../icon';
import { KuiCheckboxModule } from './../checkbox';
import { KuiToggleModule } from './../toggle';
import { KuiDropdownRefComponent } from './dropdown-ref.component';

const DROPDOWN_COMPONENTS = [
  KuiDropdownComponent,
  KuiDropdownButtonComponent,
  KuiDropdownRefComponent,
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        KuiButtonModule,
        KuiIconModule,
        KuiCheckboxModule,
        KuiToggleModule,
    ],
    exports: [DROPDOWN_COMPONENTS],
    declarations: [DROPDOWN_COMPONENTS]
})
export class KuiDropdownModule {}
