import { Component, OnInit } from '@angular/core';
import { KuiModalRefComponent } from 'app/key-ui/modal/modal-ref.component';
import { IKuiModalAction } from 'app/key-ui';
import { TranslateService } from '@ngx-translate/core';


export interface ErrorModalOptions {
    title: string;
    message: string;
    error: Error;
}

@Component({
    templateUrl: 'error.component.html',
})
export class ErrorModalComponent extends KuiModalRefComponent<ErrorModalOptions> implements OnInit {

    modalActions: IKuiModalAction[];

    constructor(
        private i18n: TranslateService
    ) {
        super();
    }
   
    ngOnInit() {

        this.modalActions = [{
            text: this.i18n.instant('DIALOG.OK'),
            style: 'primary',
            keypress: 27,
            action: () => {
                this.actions.close(true);
            },
        }];

    }

    detachModal() {
        this.actions.close(false);
    }



}
