import { Component, Input, OnChanges, SimpleChange, Output, EventEmitter } from '@angular/core';

export interface PageList {
    text: string;
    index?: number;
    active?: boolean;
}

@Component({
    selector: 'kui-paginate',
    templateUrl: './paginate.component.html',
})
export class KuiPaginateComponent implements OnChanges {
    @Input() page = 1;
    @Input() perPage = 1;
    @Input() total = 1;
    @Input() collapse: boolean;

    @Output() pageChanged = new EventEmitter<number>();

    public pageList: PageList[];

    ngOnChanges(_changes: { [propertyName: string]: SimpleChange }) {
        const noPages = Math.ceil(this.total / this.perPage);

        this.pageList = [];

        if (this.page > 1) {
            this.pageList.push({ text: 'Previous', index: this.page - 2 });
        }

        for (let i = 0, t = noPages; i < t; i++) {
            const active = i === (this.page - 1);
            const first = i === 0;
            const last = i === (t - 1);

            if (this.collapse && noPages > 5) {

                if (first) { // always add first number
                    this.pageList.push({ text: String(i + 1), index: i, active: active });

                    if (1 !== (this.page - 1) && !active) { // add space behind first if second page is not active
                        this.pageList.push({ text: '...' });
                    }

                    if (active) { // add second number if first number is active
                        this.pageList.push({ text: String(i + 2), index: i + 1, active: false });
                    }
                }

                if (last) { // always add last number
                    if ((i - 1) !== (this.page - 1) && !active) { // add space before last if second last page is not active
                        this.pageList.push({ text: '...' });
                    }

                    if (active) { // add second last number if last first number is active
                        this.pageList.push({ text: String(i), index: i - 1, active: false });
                    }

                    this.pageList.push({ text: String(i + 1), index: i, active: active });
                }

                if (active && !(first || last)) { // add active that is not first or last and a number before and after it
                    if ((i - 1) !== 0) { // only add number before active if it is not first
                        this.pageList.push({ text: String(i), index: i - 1, active: false });
                    }

                    this.pageList.push({ text: String(i + 1), index: i, active: active });

                    if ((i + 1) !== (t - 1)) { // only add number after active if it is not last
                        this.pageList.push({ text: String(i + 2), index: i + 1, active: false });
                    }
                }
            } else {
                this.pageList.push({ text: String(i + 1), index: i, active: active });
            }
        }

        if (this.page < noPages) {
            this.pageList.push({ text: 'Next', index: this.page });
        }
    }

    changePage(page?: number) {
        if (page === undefined) { return false; }
        this.pageChanged.emit(page + 1);
    }
}
