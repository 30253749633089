import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KuiSnackbarComponent } from './snackbar.component';
import { KuiSnackbarItemComponent } from './snackbar-item.component';
import { KuiActionModule } from '../action';
import { KuiSnackbarService } from './snackbar.service';
import { KuiButtonModule } from '../button';
import { KuiIconModule } from '../icon';
import { KuiSnackbarAnimationPipe } from './snackbar-animation.pipe';

const COMPONENTS = [
    KuiSnackbarComponent,
    KuiSnackbarItemComponent,
];

@NgModule({
    imports: [
        CommonModule,
        KuiActionModule,
        KuiButtonModule,
        KuiIconModule,
    ],
    exports: [COMPONENTS],
    declarations: [COMPONENTS, KuiSnackbarAnimationPipe],
    providers: [KuiSnackbarService, KuiSnackbarAnimationPipe]
})
export class KuiSnackbarModule { }
