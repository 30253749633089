import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormBuilderField, BaseFormBuilderFieldComponent } from 'app/shared/components/form-builder';
import { FormBuilderKeyValue } from 'app/shared/components/form-builder/form-builder.model';
import { get, set } from 'lodash';

@Component({
    selector: 'key-form-builder-combo-field',
    templateUrl: './combo.component.html',
})
export class KeyFormBuilderComboFieldComponent implements BaseFormBuilderFieldComponent, OnInit {
    selected: string;

    @Input() field: FormBuilderField;
    @Input() values: { [key: string]: any };
    @Input() error: string; // set this error value externally to have the default error highligh and display kick in

    @Output() onSelected = new EventEmitter<{ value: string | number, dirty: boolean }>();

    touched = false;
    dirty = false;

    get value(): any {
        return this.field.getValue ? this.field.getValue(this.field, this.values) : get(this.values, this.field.id, undefined);
    }
    set value(val: any) {
        if (this.field.setValue) {
            const promise = this.field.setValue(this.field, this.values, val);
            if (promise && promise.then) {
                promise.then(() => this.ref.markForCheck());
            }
        } else {
            set(this.values, this.field.id, val);
        }
    }


    _comboValues: FormBuilderKeyValue[];
    get comboValues(): FormBuilderKeyValue[] {
        return this._comboValues;
    }

    set comboValues(values: FormBuilderKeyValue[]) {
        this._comboValues = values;
        this.ref.markForCheck();
    }

    constructor(private ref: ChangeDetectorRef) { }

    validate(): boolean {
        this.touched = true;
        this.ref.markForCheck();
        return !this.field.required || !!this.value;
    }

    ngOnInit() {
        this.comboValues = this.field.values;
        this.value = this.value || this.field.value; // || (this.field.values[0] && this.field.values[0].key); -- this is bad for a lot of places, don't assume the first item
        setTimeout(() => { // avoid ExpressionChangedAfterItHasBeenCheckedError
            this.onSelected.emit({ value: this.value, dirty: false }); // we need to fire the event if we've set a value in order to deal with sub fields
        });
    }

    comboChanged(value: string) {
        this.touched = true;
        this.dirty = true;
        this.value = value || undefined;
        this.onSelected.emit({ value: value, dirty: this.dirty });
        this.ref.markForCheck();
    }

}
