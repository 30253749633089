import { DeviceResponse } from "@key-telematics/fleet-api-client";
import { ApiService } from "./api.service";
import { EMPTY_GUID } from "app/shared/components/admin/entities/utils";

export async function getDeviceConfiguration(api: ApiService, device: DeviceResponse): Promise<Pick<DeviceResponse, 'parameters' | 'settings' | 'features' | 'accessories'>> {
    if (device.configProfile && device.configProfile.id !== EMPTY_GUID) {
        const config = await api.entities.getDeviceConfigProfile(device.configProfile.id, api.cacheFor(5));
        return config;
    }
    return device;
}