import * as saferEval from 'safer-eval';
import { merge } from 'lodash';
import { THEME_DEFAULTS } from './theme-defaults';
import { ThemeColor } from './theme-color';
import { Dictionary } from '@key-telematics/fleet-api-client';


const allowedFunctions = ['var('];
const colorPrefix = ['#', 'rgb(', 'rgba(', 'hsl(', 'hwb('];

export function attempt(func: () => void) {
    try {
        return func();
    } catch (err) {
        console.error(err);
        return '#000000';
    }
}

export function getvar(input: string | ThemeColor): ThemeColor | string {
    if (typeof input === 'string' && colorPrefix.find(x => input.startsWith(x))) {
        return new ThemeColor(input);
    } else {
        return input;
    }
}

// used as a fallback for IE
function unsafeEval() {
    return function (operation) {
        // tslint:disable-next-line:no-eval
        try {
            return eval('(function () { return ' + operation.replace('this', '_this') + '})()');
        } catch (err) {
            console.error(err);
            return {};
        }
    };
}


export class ThemeEngine {

    private variables: Dictionary;
    private formulaCache: Dictionary = {};

    settingsToVariables(settings: Dictionary): Dictionary {
        return this.resolveVariables(this.themeSettingsToVariables(this.themeSettingsMergedWithDefault(settings)));
    }

    resolveFormula(formula: string): string {
        if (!this.variables) {
            throw new Error('Cannot resolve formula until you\'ve resolved variables');
        }
        if (!this.formulaCache[formula]) {
            this.resolveVariables({
                ...this.variables,
                '--single-formula': formula,
            });
        }
        return this.formulaCache[formula] || '#000000';
    }

    resolveVariables(variables: Dictionary): Dictionary {

        this.variables = variables;

        // this function will recursively replace variable names with the
        // contents of that variable, thereby nexting the calls and flattening
        // the recursion. so `var(--something)` will become `getvar(getvar(getvar(#123)).darken(0.4))`
        const unpack = (val: string, depth = 0) => {
            if (depth === 10) { return '"#000000"'; } // prevent stack overflows
            if (allowedFunctions.find(x => (val || '').includes(x))) {
                val = val.replace('var(', 'getvar(');
                const matches = /getvar\(([^)]+)\)/.exec(val);
                if (matches && matches[1]) {
                    const variableName = matches[1];
                    const sub = unpack(variables[variableName] || '#000000', ++depth);
                    val = val.replace(variableName, sub);
                    return val;
                } else {
                    return '"#000000"'; // something went wrong here
                }
            }
            return `"${val}"`;
        };

        const flattened = {};
        Object.keys(variables).forEach(key => {
            flattened[key] = unpack(variables[key]);
        });

        let evaled: Dictionary;
        const code = `{ ${Object.keys(flattened).map(key => (`"${key}": attempt(function() { return getvar(${flattened[key]}); })\n`))} }`;
        try {
            evaled = saferEval(code, { getvar: getvar });
        } catch (err) {
            // our safe eval failed, we're probably on internet explorer, try an unsafe eval
            const func = unsafeEval();
            evaled = func(code);
        }

        const result = Object.keys(evaled).reduce((obj, key) => {
            const val = evaled[key];
            const str = typeof val === 'string' ? val : val.hex();
            this.formulaCache[variables[key]] = str;
            obj[key] = str;
            return obj;
        }, {});

        return result;
    }

    themeSettingsToVariables(settings: Dictionary, separator = '-'): Dictionary {

        const flat = (obj) => (
            Object.keys(obj || {}).reduce((acc, key) => {
                if (typeof obj[key] !== 'object') {
                    return {
                        ...acc,
                        [`${key}`]: obj[key],
                    };
                }
                const flattenedChild = flat(obj[key]);
                return {
                    ...acc,
                    ...Object.keys(flattenedChild).reduce((childAcc, childKey) => ({ ...childAcc, [`${key}${separator}${childKey}`]: flattenedChild[childKey] }), {}),
                };
            }, {})
        );

        const flattened = flat(settings);
        return Object.keys(flattened).reduce((obj, key) => {
            obj[`${separator}${separator}${key}`] = flattened[key];
            return obj;

        }, {});

    }

    themeSettingsMergedWithDefault(settings: any): any {
        return merge({}, THEME_DEFAULTS, settings);
    }

}




