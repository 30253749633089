<input *ngIf="field.readonly"
    kui-input="{{ field.id }}"
    type="text"
    [disabled]="true"
    [value]="field.getText && field.getText(field, values)">
<kui-tag-input *ngIf="!field.readonly"
    [invalid]="error || (field.required && (dirty && touched) && selectedItems.length === 0)"
    [placeholder]="'FORMS.CLIENTFILTER.PLACEHOLDER' | translate"
    [loading]="isLoading"
    [autoCompleteItems]="autoCompleteItems"
    [remoteSearch]="true"
    [selectedItems]="selectedItems"
    (itemAdded)="addTag($event)"
    (itemRemoved)="removeTag($event)"
    (searchTermChanged)="updateAutoCompletionList($event)"
    [multiple]="field.max !== 1"></kui-tag-input>
<div *ngIf="field.required && (dirty && touched) && selectedItems.length === 0"
    class="invalid-feedback d-block">
    <div>{{ 'FORMS.SHARED.REQUIRED' | translate:{ field: field.title } }}</div>
</div>
<div *ngIf="error"
    class="invalid-feedback d-block">
    <div>{{ error }}</div>
</div>