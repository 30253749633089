import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[kui-nav-link-text]',
})
export class KuiNavLinkTextDirective {
  constructor(el: ElementRef, renderer: Renderer2) {
    renderer.addClass(el.nativeElement, 'nav-link-text');
  }
}
