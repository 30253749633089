import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormBuilderField } from 'app/shared/components/form-builder';

@Component({
    selector: 'key-form-builder-radio-field',
    templateUrl: './radio.component.html',
})
export class KeyFormBuilderRadioFieldComponent implements OnInit, OnChanges {
    radioValues: { value: string; id: string; }[];
    selected: string;
    dirty: boolean;

    @Input() field: FormBuilderField;
    @Input() values: { [key: string]: any };
    @Input() error: string; // set this error value externally to have the default error highligh and display kick in

    @Output() onChange: EventEmitter<{ value: string, dirty: boolean }> = new EventEmitter();

    ngOnInit() {
        if (this.field.value) {
            this.values[this.field.id] = this.values[this.field.id] || this.field.value;
        }

        this.radioValues = this.field.values && this.field.values.map(value => ({
            id: value.key,
            value: value.value.toString(),
        })) || [];
        this.updateRadioSelected();
    }

    ngOnChanges() {
        if (this.selected && this.selected !== this.values[this.field.id]) {
            this.updateRadioSelected();
        }
    }

    validate(): boolean {
        return true;
    }

    updateRadioSelected() {
        const selected = this.radioValues.find(({ id }) => this.values[this.field.id] === id);
        this.changeValue(selected && selected.id);
        this.dirty = true;
    }

    changeValue(value: string) {
        this.selected = value;
        this.values[this.field.id] = value;
        this.onChange.emit({ value, dirty: this.dirty });
    }
}
