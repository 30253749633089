import {
    Component,
    Input,
    OnInit,
    ChangeDetectionStrategy,
    HostBinding,
    Output,
    EventEmitter
} from '@angular/core';

@Component({
    selector: 'kui-toggle',
    templateUrl: './toggle.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KuiToggleComponent implements OnInit {
    @Input() checked: boolean;
    @Input() disabled: boolean;
    @Input() forId: string;
    @Input() size: 'sm' | 'md' | 'lg' = 'md';

    @Output() onChange: EventEmitter<boolean> = new EventEmitter();

    @HostBinding('class.sm') classSm = false;
    @HostBinding('class.md') classMd = false;
    @HostBinding('class.lg') classLg = false;

    ngOnInit() {
        this.classSm = this.size === 'sm';
        this.classMd = this.size === 'md';
        this.classLg = this.size === 'lg';
    }

    emitChange(state: boolean) {
        this.checked = state;
        this.onChange.emit(state);
    }
}
