import { Component, EventEmitter, HostBinding, Input, Output, OnChanges, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ErrorLoggerService, NotificationService, Poll, PollService } from 'app/services';
import { BaseComponent } from 'app/shared/components';
import { DashboardWidget, Dictionary } from '@key-telematics/fleet-api-client';
import { debounce } from 'lodash';
import { AnalyticsService } from 'app/shared/components/analytics/services/analytics.service';
import { AnalyticsCellValue, AnalyticsSettings, AnalyticsCellSetStatus, AnalyticsCellSet, AnalyticsCellSetFilters } from 'app/shared/components/analytics/analytics.model';
import { AnalyticsChartType, AnalyticsChartData, AnalyticsChartComponent } from 'app/shared/components/analytics/chart/chart.component';
import { FormBuilderDefinition } from 'app/shared/components/form-builder';
import { ModalService } from 'app/shared/components/modal';

export enum WIDGET_SIZES {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

export interface ShareWidgetEvent {
    cellset: AnalyticsCellValue[][];
    settings: AnalyticsSettings;
    id: string;
    definitionId: string;
}

export interface SelectWidgetSettingsEvent {
    id: string;
    type: AnalyticsChartType;
    inherited: boolean;
    settings: AnalyticsSettings;
    filters: AnalyticsCellSetFilters;
}

export interface UpdateWidgetEvent {
    id: string;
    update: DashboardWidget;
}

export interface WidgetAnalyticsSettings extends AnalyticsSettings {
    widgetType?: 'chart' | 'grid' | 'text' | 'stat';
}

@Component({
    selector: 'key-dashboard-widget',
    templateUrl: './widget.component.html',
    styleUrls: ['./widget.component.scss'],
})
export class DashboardWidgetComponent extends BaseComponent implements OnChanges, OnInit, OnDestroy {
    SIZES = WIDGET_SIZES; // enable template to get access to enum

    errorMessage: string;
    errorIcon: string;
    errorAction: { text: string, action: () => any; };
    loading: boolean;

    cellset: AnalyticsCellValue[][];
    settings: AnalyticsSettings;
    filters: AnalyticsCellSetFilters;
    parameterDefinition: FormBuilderDefinition;
    parameters: Dictionary;

    statusPoll: Poll<{ sourceId: string, options: AnalyticsSettings }, AnalyticsCellSet>;

    debouncedUpdateWidget = debounce(this.doUpdateWidget, 2500, { trailing: true, leading: false });
    debounceSetCellsetData = debounce(this.setCellsetData, 1000, { trailing: true, leading: false });

    @Input() dashboardId: string;
    @Input() widgetId: string;
    @Input() widget: DashboardWidget;
    @Input() useSampleData: boolean; // whether or not to show sample data when no real data is available or show notice of the real data's progress i.e. running, queued.
    @Input() showSettings = true;
    @Input() showDelete = true;
    @Input() showShare = true;
    @Input() showReportLink = false;
    @Input() size: string;

    @Output() onDelete = new EventEmitter<{ id: string, name: string }>();
    @Output() onSettingsSelected = new EventEmitter<SelectWidgetSettingsEvent>();
    @Output() onShare = new EventEmitter<ShareWidgetEvent>();
    @Output() onUpdate = new EventEmitter<UpdateWidgetEvent>();
    @Output() onShowReport = new EventEmitter<string>();
    @Output() onDataLoaded = new EventEmitter<{ cellset: AnalyticsCellValue[][], filters: AnalyticsCellSetFilters }>();
    @Output() onChartLoaded = new EventEmitter<AnalyticsChartData>();

    @Output() onStartPolling = new EventEmitter<string>();
    @Output() onStopPolling = new EventEmitter<string>();

    @HostBinding('class') hostClassnames = 'd-flex flex-column flex-1';
    @ViewChild(AnalyticsChartComponent) chart: AnalyticsChartComponent;

    constructor(
        private error: ErrorLoggerService,
        private analytics: AnalyticsService,
        private i18n: TranslateService,
        private notify: NotificationService,
        private modal: ModalService,
        private poll: PollService
    ) {
        super();
    }

    ngOnInit() {
        this.statusPoll = this.poll.create<{ sourceId: string, options: AnalyticsSettings }, AnalyticsCellSet>(6e4 * 5, async ({ sourceId, options }) => {
            const result = await this.analytics.getCellsetData(sourceId, options, true);
            const { status, cellset } = result.cellset || {} as any;
            if (status !== 'running') {
                this.showError(null);
                this.cellset = cellset;
                this.statusPoll.stop();
            }
            return cellset;
        });

    }

    ngOnChanges() {
        if (this.widget) {
            this.load();
        }
    }

    ngOnDestroy() {
        if (this.statusPoll) {
            this.statusPoll.stop();
        }
    }

    load(debounce: boolean = false) {
        this.errorMessage = null;
        try {
            const { parameterDefinition, parameters } = this.widget && this.widget.dataSource && this.widget.dataSource.options && this.widget.dataSource.options.analytics;
            this.parameters = parameters;
            this.parameterDefinition = parameterDefinition && JSON.parse(parameterDefinition);

            if (debounce) {
                this.debounceSetCellsetData(this.widget);
            } else {
                this.setCellsetData(this.widget);
            }
        } catch (err) {
            console.error(err);
            this.errorMessage = err.message;
        }
    }

    async setCellsetData(widget: DashboardWidget): Promise<void> {
        try {
            const options = widget.options && widget.options.analytics;
            this.settings = this.analytics.convertOutputToAnalyticsSettings({
                ...options,
                name: widget.name,
                description: widget.description,
            }, false);

            const dataSourceId = widget.dataSource && widget.dataSource.id;
            const definitionId = widget.dataSource && widget.dataSource.options && widget.dataSource.options.analytics && widget.dataSource.options.analytics.definitionId;

            const { cellset, status, filters } = await this.getCellsetData(dataSourceId || definitionId, widget) || {} as any; // give default in case of timeout requests

            if (!this.useSampleData) {
                if (!this.parameters && !!this.parameterDefinition) {
                    this.showError(this.i18n.instant('DASHBOARDS.ERRORS.CONFIGURATION_REQUIRED'), null, {
                        text: this.i18n.instant('DASHBOARDS.WIDGET.CONFIGURE'),
                        action: this.doSettingsSelection.bind(this),
                    });
                    return null;
                }

                // start and stop a polling of the dashboard while at least one widget still needs a widget.dataSource.id
                // TODO: if we ever implement some web socket type thing on the backend this type of polling can be removed
                if ((this.parameters || !this.parameterDefinition) && !widget.dataSource.id) {
                    this.showError(this.i18n.instant('DASHBOARDS.ERRORS.REPORT_QUEUED'), 'clock');
                    this.onStartPolling.emit(this.widgetId);
                } else {
                    this.onStopPolling.emit(this.widgetId);
                }

                if (status === 'running') {
                    this.showError(this.i18n.instant('DASHBOARDS.ERRORS.REPORT_RUNNING'), 'info-circle');
                    // poll cellset data until the report completed
                    this.statusPoll.start({
                        sourceId: dataSourceId || definitionId,
                        options: widget.options.analytics,
                    });
                }
            }

            this.cellset = cellset;
            this.filters = filters;
            this.onDataLoaded.emit({ cellset, filters });
        } catch (err) {
            console.error(err);
            this.errorMessage = err.message;
        }

    }

    async getCellsetData(sourceId: string, widget: DashboardWidget): Promise<{ cellset: AnalyticsCellValue[][], status: AnalyticsCellSetStatus, filters: AnalyticsCellSetFilters }> {
        this.setLoading(true);
        this.showError(null);

        try {
            const [data, filters] = await Promise.all([
                this.analytics.getCellsetData(sourceId, widget.options.analytics),
                this.analytics.getFiltersList(sourceId, widget.options.analytics),
            ]);
            const { cellset, status } = data && data.cellset;

            if (!cellset) {
                this.showError(this.i18n.instant('DASHBOARDS.ERRORS.NO_RESULTS'));
                return { cellset: null, status, filters };
            }

            return { cellset, status, filters };
        } catch (err) {
            // the error message for no data to aggregate is ugly... So I replace it here
            if (err.message.includes('No data to aggregate for report')) {
                err = this.i18n.instant('DASHBOARDS.ERRORS.NO_RESULTS');
            }
            this.showError(err);
            return null;
        } finally {
            this.setLoading(false);
        }
    }

    showError(error: any, icon?: string, action?: { text: string, action: () => any; }) {
        if (!error) {
            this.errorMessage = null;
            this.errorIcon = null;
            this.errorAction = null;
            return;
        }

        if (typeof error !== 'string') {
            this.error.trackException(error);
        }
        this.errorMessage = this.notify.translateError(error);
        this.errorIcon = icon;
        this.errorAction = action;
    }

    setLoading = (state: boolean) => {
        this.loading = state;
    }

    updateWidgetSettings(settings: WidgetAnalyticsSettings, debounce?: boolean) {
        const { name, description, widgetType } = settings;
        const update = {
            name,
            description,
            widgetType,
            options: {
                analytics: {
                    name,
                    description,
                    ...settings,
                },
            },
        };
        this.widget = { ...this.widget, ...update };

        this.debouncedUpdateWidget(update);
        this.load(debounce);
    }

    updateWidgetDefinitionParameters(parameters: Dictionary) {
        this.widget.dataSource.options.analytics.parameters = parameters;

        const { id, ...update } = this.widget.dataSource; // remove id from dataSource update to prevent overwriting when we update the parameters
        this.doUpdateWidget({
            dataSource: update,
        });
    }

    async doSettingsSelection(): Promise<void> {
        if (!this.useSampleData && !this.parameters && this.parameterDefinition) {
            this.parameterDefinition.groups[0].name = this.i18n.instant('DASHBOARDS.FORM_MODALS.MISSING_PARAMS');
            const values = await this.modal.form(this.parameterDefinition, {});
            this.updateWidgetDefinitionParameters(values);
        } else {
            this.onSettingsSelected.emit({
                id: this.widgetId,
                type: this.widget.widgetType,
                inherited: this.widget.inherited,
                settings: this.settings,
                filters: this.filters,
            });
        }
    }

    private doUpdateWidget(update: DashboardWidget) {
        this.onUpdate.emit({
            id: this.widgetId,
            update,
        });
    }
}
