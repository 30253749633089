import { Component, Input, HostBinding } from '@angular/core';

export interface FeedEntryAction {
    icon: string;
    onClick: () => any;
}

@Component({
    selector: 'key-feed-entry-actions',
    templateUrl: './feed-entry-actions.component.html',
})
export class KeyFeedEntryActionsComponent {
    @HostBinding('class.d-flex') flex = true;
    @HostBinding('class.flex-column') column = true;

    @Input() actions: FeedEntryAction[];
}
