import * as moment from 'moment-timezone';

/**
 * Breaks two dates into time buckets of a given size (default 24 hours).
 */
export function getTimeBuckets(start: string, end: string, size = 24 * 60 * 60): string[][] {
    if (moment.duration(moment.utc(end).diff(moment.utc(start))).asSeconds() <= size) {
        return [
            [moment.utc(start).toISOString(), moment.utc(end).toISOString()],
        ];
    }

    let endDate = moment.utc(start).add(size, 'seconds').toISOString();
    const buckets = [[moment.utc(start).toISOString(), endDate]];

    while (true) {
        const diff = moment.duration(moment.utc(end).diff(moment.utc(endDate))).asSeconds();

        if (diff > size) {
            const newEnd = moment.utc(endDate).add(size, 'seconds').toISOString();
            buckets.push([endDate, newEnd]);
            endDate = newEnd;

            continue;
        }

        const newEnd = moment.utc(endDate).add(diff, 'seconds').toISOString();
        buckets.push([endDate, newEnd]);
        break;
    }

    return buckets;
}
