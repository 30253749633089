import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KuiIconModule } from 'app/key-ui/icon/icon.module';
import { KuiButtonModule } from 'app/key-ui/button/button.module';
import { KuiPaginateComponent } from './paginate.component';
import { KuiPaginatePipe } from './paginate.pipe';

@NgModule({
    imports: [CommonModule, KuiIconModule, KuiButtonModule],
    exports: [
        KuiPaginateComponent,
        KuiPaginatePipe,
    ],
    declarations: [
        KuiPaginateComponent,
        KuiPaginatePipe,
    ],
})
export class KuiPaginateModule { }
