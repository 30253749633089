<kui-card class="routing-card flex-1 d-flex">
    <kui-card-body class="flex-1 d-flex flex-column">
        <ng-container *ngFor="let item of markers; index as i">
            <div class="position-relative">
                <key-map-toolbar-routing-input [placeholder]="getInputPlaceholderText(i, markers)"
                    [text]="item.input"
                    [id]="item.marker.id"
                    (onSelected)="markerUpdated(i, $event)"></key-map-toolbar-routing-input>
                <kui-icon *ngIf="isNotFirstInput(i) && item.marker.lat && item.marker.lon"
                    kui-action
                    name="sort-alt"
                    [title]="'LEAFLET.ROUTING.INPUT.SWOP_POINTS' | translate"
                    (click)="swopPointsAtIndexes(i)"
                    class="routing-card__input-swop-icon"></kui-icon>
            </div>
        </ng-container>

        <div *ngIf="loading || errorMessage || !!routes?.length"
            class="routing-card__info flex-1 d-flex flex-column">
            <h5>{{ 'LEAFLET.ROUTING.TITLE' | translate }}</h5>
            <kui-loader *ngIf="loading; else loadedContent"></kui-loader>
            <ng-template #loadedContent>
                <kui-alert-panel *ngIf="errorMessage"
                    [message]="errorMessage"></kui-alert-panel>
                <div *ngIf="!errorMessage"
                    class="routing-card__info-list flex-1 overflow-auto">
                    <key-listing *ngFor="let route of routes"
                        [hover]="true"
                        [active]="route.active"
                        (click)="setActiveRoute(route.id)">
                        <key-listing-avatar>
                            <kui-icon name="directions"
                                [size]="'md'"></kui-icon>
                        </key-listing-avatar>
                        <key-listing-stat [class.text-success]="route.strategy === 'fastest'">{{'LEAFLET.ROUTING.STRATEGY.' + (route.strategy | uppercase) | translate}}</key-listing-stat>
                        <key-listing-title class="mb-1">{{route.description}}</key-listing-title>
                        <key-listing-description>{{route.distance | units: 'distance' }} | {{route.time | formatSeconds}}</key-listing-description>
                    </key-listing>
                </div>
            </ng-template>

            <div class="actions">
                <button kui-button
                    *ngIf="activeRoute"
                    color="primary"
                    class="mr-1"
                    (click)="saveSelectedRoute(activeRoute)">{{ 'LEAFLET.ROUTING.SAVE' | translate }}</button>
                <button kui-button
                    color="secondary"
                    (click)="onClose.emit()">{{ 'DIALOG.CLOSE' | translate }}</button>
            </div>
        </div>
    </kui-card-body>
</kui-card>