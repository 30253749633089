import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { KuiSnackbarItem } from './snackbar.model';
import * as uuid from 'uuid';

@Component({
    selector: 'kui-snackbar',
    templateUrl: 'snackbar.component.html',
    styleUrls: ['snackbar.component.scss'],
    animations: [
        trigger('fade', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate(200, style({ opacity: 1 })),
            ])
        ])
    ]
})
export class KuiSnackbarComponent implements OnChanges {
    _items: KuiSnackbarItem[] = [];

    @Input() items: KuiSnackbarItem[];
    @Input() limit: number;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.items && this.items) {
            this.items.forEach(item => {
                this.addItem(item);
            });
        }
    }

    handleActionClicked(item: KuiSnackbarItem) {
        item.action(item);
        this.removeItem(item);
    }

    addItem(item: KuiSnackbarItem) {
        if (!this.limit || this._items.length < this.limit) {
            this._items.push({
                ...item,
                id: item.id || uuid.v4(),
            });
        }
    }

    removeItem(item: KuiSnackbarItem) {
        const index = this._items.findIndex(x => x.id === item.id);
        this._items.splice(index, 1);
        if (item.onClose) {
            item.onClose(item);
        }
    }
}
