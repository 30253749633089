<div class="report d-flex flex-column flex-1 overflow-auto position-relative">
    <div *ngIf="loading" class="loading-overlay">
        <kui-loader></kui-loader>
    </div>

    <kui-card class="report__view">
        <kui-card-header class="p-4">
            <div class="flex-1 pr-4">
                <ng-container *ngIf="report">
                    <h4 class="mb-1">{{ report.title || report.name }}</h4>
                    <div class="muted">
                        {{ report.client?.name }} ({{ report.user?.name }})
                    </div>
                </ng-container>
            </div>
            <kui-icon name="chart-line" size="xxl"></kui-icon>

        </kui-card-header>

        <kui-card-body class="p-0 flex-1 flex-container" style="min-height: 0">
            <!-- NO RESULTS MESSAGE -->
            <kui-alert-panel *ngIf="noResults && !validateError"
                [message]="'ANALYTICS.ERRORS.NO_RESULTS' | translate"></kui-alert-panel>

            <!-- VALIDATION ERROR -->
            <div class="px-4 pt-4" *ngIf="validateError">
                <kui-alert type="danger">
                    <kui-icon name="ban" size="sm"></kui-icon>
                    <span class="ml-3 flex-1" style="min-width: 0;">
                        {{'ANALYTICS.ERRORS.REQUIRED_FIELDS' | translate}}
                    </span>
                    <button kui-button (click)="validateError = null" class="ml-auto" color="light" size="sm">{{ 'DIALOG.DISMISS' | translate }}</button>
                </kui-alert>
            </div>

            <ng-container *ngIf="!noResults">

                <!-- MOBILE -->
                <ng-container *ngIf="isMobile; else isDesktop">
                    <!-- GRAPH -->
                    <div *ngIf="cellset && settings" class="d-flex p-4 mt-2 flex-1" style="height: 200px;">
                        <key-analytics-chart [cellset]="cellset"
                            [settings]="settings"
                            type="chart"
                            (onMeasureSelection)="changeActiveSeriesSelection($event)"></key-analytics-chart>
                    </div>

                    <!-- TABLE -->
                    <kui-content-header>
                        <kui-tab-bar [items]="tableTabs" [selected]="this.state.activeTableTab" (selection)="setActiveTableTab($event)"></kui-tab-bar>
                    </kui-content-header>
                    <key-analytics-tables
                        *ngIf="tablesData"
                        [data]="tablesData"
                        [section]="this.state.activeTableTab | uppercase"
                        [type]="tableType"
                        [selectedMeasure]="selectedSeries"
                        class="data-table flex-1 border-top d-flex overflow-auto" style="min-height: 0"
                        (onMeasureSelection)="changeActiveSeriesSelection($event)">
                    </key-analytics-tables>
                </ng-container>

                <!-- DESKTOP -->
                <ng-template #isDesktop>
                    <key-layout-grid-columns direction="vertical">

                        <!-- GRAPH -->
                        <key-layout-grid-column
                            [size]="state.graphHeight + 'px'"
                            (onResized)="updateGraphHeight($event)"
                            [resizable]="!isMobile"
                            direction="vertical"
                            minSize="150">

                            <div key-layout-grid-column-inner class="p-4 overflow-hidden graph">
                                <kui-icon kui-action
                                    size="sm"
                                    class="graph__share"
                                    name="share-alt"
                                    (click)="addGraphToDashboard()"></kui-icon>
                                <key-analytics-chart [cellset]="cellset"
                                    *ngIf="cellset && settings"
                                    [settings]="settings"
                                    type="chart"
                                    (onMeasureSelection)="changeActiveSeriesSelection($event)"></key-analytics-chart>
                            </div>
                        </key-layout-grid-column>

                        <!-- TABLE -->
                        <key-layout-grid-column direction="vertical" *ngIf="tablesData" class="flex-column">
                            <div key-layout-grid-column-inner class="w-100">
                                <kui-content-header>
                                    <kui-tab-bar [items]="tableTabs" [selected]="this.state.activeTableTab" (selection)="setActiveTableTab($event)"></kui-tab-bar>
                                </kui-content-header>
                                <div class="overflow-auto flex-1">
                                    <key-analytics-tables
                                        [data]="tablesData"
                                        [section]="this.state.activeTableTab | uppercase"
                                        [type]="tableType"
                                        [selectedMeasure]="selectedSeries"
                                        class="data-table flex-1 d-flex" style="min-height: 0"
                                        (onMeasureSelection)="changeActiveSeriesSelection($event)">
                                    </key-analytics-tables>
                                </div>
                            </div>
                        </key-layout-grid-column>

                    </key-layout-grid-columns>
                </ng-template>

            </ng-container>
        </kui-card-body>

    </kui-card>
</div>

<key-analytics-settings
    *ngIf="showSettings"
    class="d-flex"
    [title]="isMobile ? ('ANALYTICS.SETTINGS.SETTINGS' | translate) : null"
    [settings]="settings"
    [show]="visibleSettings"
    [activeTab]="activeSettingsTab"
    [absolute]="isMobile"
    [datesMinMax]="minMaxDates"
    [yAxesLimits]="yAxesLimits"
    [exportOptions]="['csv']"
    [allowCustomDates]="true"
    [selectedSeries]="selectedSeries"
    [filters]="filters"
    (onExport)="exportReport($event)"
    (onSeriesClear)="clearSeriesItems($event)"
    (onSettingsChange)="updateSettings($event)"
    (onSeriesSelectionChange)="changeActiveSeriesSelection($event)"
    (onHide)="onSettingsVisiblityToggle.emit(!showSettings)"
></key-analytics-settings>