<div *ngIf="config?.customisableOperator" class="sticky pt-3 px-3">
    <div class="d-flex justify-content-between align-items-center">
        <span>{{ 'SHARED.FILTER' | translate }}</span>
        <div class="d-flex">
            <label class="form-check-label ml-2">
                <input
                    [kui-radio]="config.id"
                    value="OR"
                    (change)="updateOperator('OR')"
                    [checked]="operator === 'OR'">
                OR
            </label>
            <label class="form-check-label ml-2">
                <input
                    [kui-radio]="config.id"
                    value="AND"
                    (change)="updateOperator('AND')"
                    [checked]="operator === 'AND'">
                AND
            </label>
        </div>
    </div>
    <hr class="w-100 mt-2 mb-0" />
</div>
<div class="item-list pt-3 px-3">
    <key-tree-view
        [nodes]="root.children">
    </key-tree-view>
</div>