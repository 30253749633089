<kui-modal
    [title]="data.title"
    [actions]="modalActions"
    (onClose)="closeModal()"
>
    <div class="p-3">
        <key-form-builder
            *ngIf="data"
            [form]="data.form"
            [values]="data.values"
        ></key-form-builder>
    </div>
</kui-modal>
