import { Component, Input, Output, EventEmitter, HostBinding, HostListener, Inject, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LayoutGridColumnService } from './layout-grid-column.service';
import { BaseComponent } from '../base/base.component';

@Component({
    selector: 'key-layout-grid-column',
    templateUrl: './layout-grid-column.component.html',
})
export class LayoutGridColumnComponent extends BaseComponent {
    private _size: number | string;

    isDragging: boolean;
    unit = 'px';

    @Input() direction: 'horizontal' | 'vertical' = 'horizontal';

    @Input()
    set size(size: number | string) {
        if (typeof size === 'string' && size.replace(/\d+|\./g, '').length > 0) {
            this.unit = size.replace(/\d+|\./g, '');
            size = parseInt(size, 0);
        }

        this.setFlexBindings(size as number);
        this._size = size;
    }
    get size(): number | string {
        return this._size;
    }

    @Input() resizable: boolean;
    @Input() minSize: number;
    @Input() maxSize: number;

    @Output() onResized: EventEmitter<number | string> = new EventEmitter();

    @HostBinding('class.lg-column') columnClass = true;
    @HostBinding('class.d-flex') displayFlex = true;

    @HostBinding('style.min-height') flexFix = '1px';
    @HostBinding('style.flex-basis') flexBasis = 'auto';
    @HostBinding('style.flex-grow') flexGrow = 1;

    @HostListener('document:mousemove', ['$event']) mouseMove(e: MouseEvent) {
        this.drag(e);
    }
    @HostListener('document:mouseup', ['$event']) mouseUp(_e: MouseEvent) {
        this.setDragging(false);
    }

    @HostListener('document:touchmove', ['$event']) touchMove(e: Event) {
        this.drag(e);
    } // type should technically be TouchEvent, but Safari is being a dick
    @HostListener('document:touchend', ['$event']) touchEnd(_e: Event) {
        this.setDragging(false);
    }

    constructor(
        public el: ElementRef,
        @Inject(DOCUMENT) public document: HTMLDocument,
        public layoutGridService: LayoutGridColumnService
    ) {
        super();
    }

    drag(e) {
        const el = this.el.nativeElement;

        if (this.isDragging) {
            const { top, left } = el.getBoundingClientRect();
            const clientX = (e.type === 'touchmove') ? e.touches[0].clientX : e.clientX;
            const clientY = (e.type === 'touchmove') ? e.touches[0].clientY : e.clientY;
            let size = Math.max(this.minSize || 4, (this.direction === 'horizontal') ? clientX - (left || el.clientLeft) : clientY - (top || el.clientTop));

            if (this.unit === '%') {
                const pw = this.direction === 'horizontal' ? el.parentElement.clientWidth : el.parentElement.clientHeight;
                size = Math.round(size / pw * 100);
            }
            if (this.minSize && size < this.minSize) {
                size = this.minSize;
            }
            if (this.maxSize && size > this.maxSize) {
                size = this.maxSize;
            }
            this.setFlexBindings(size);
            this._size = size;
            
            this.document.body.classList.add('disable-user-select');
        }

    }

    endDragging() {
        this.onResized.emit(this.size);
        this.layoutGridService.emitGridLayoutResize(this.size);
        this.document.body.classList.remove('disable-user-select');
    }

    setDragging(state: boolean) {
        if (this.isDragging && !state) { // make sure this only ever gets called once, when an active drag is ended
            this.endDragging();
        }
        this.isDragging = state;
    }

    handleDoubleClick() { }

    setFlexBindings(size: number, unit?: string) {
        if (!size || this.size === 'auto') {
            return false;
        }

        this.flexGrow = 0;
        this.flexBasis = size + (unit || this.unit);
    }

}
