import * as Bluebird from 'bluebird';
import { Component, Input, OnInit, ChangeDetectorRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { BaseFormBuilderFieldComponent, FormBuilderField } from 'app/shared/components/form-builder';
import { AppService } from 'app/app.service';
import { from as fromPromise } from 'rxjs';
import { AssetGroupingService } from 'app/services';
import { KuiTreeSelectNode, KuiTreeSelectComponent } from 'app/key-ui/tree-select/tree-select.component';
import { TreeNode } from '@ali-hm/angular-tree-component';
import { KuiDropdownComponent } from 'app/key-ui/dropdown/dropdown.component';
import { TranslateService } from '@ngx-translate/core';
import { EventFilterService } from 'app/services/events/event-filter.service';
import { ZoneListItem, ZoneListResponse } from '@key-telematics/fleet-api-client';
import { escapeRqlValue } from 'app/shared/utils/rql';

interface TagItem {
    id: string;
    name: string;
    filter: any;
}

interface ZoneType {
    id: string;
    name: string;
}

@Component({
    selector: 'key-form-builder-zone-filter-field',
    templateUrl: './zonefilter.component.html',
    styleUrls: ['./zonefilter.component.scss'],
})
export class KeyFormBuilderZoneFilterFieldComponent implements BaseFormBuilderFieldComponent, OnInit {

    @Input() field: FormBuilderField;
    @Input() values: { [key: string]: any };
    @Input() error: string; // set this error value externally to have the default error highligh and display kick in
    
    truncatedList: { count: number, limit: number };
    touched = false;
    dirty = false;
    isLoading = false;
    overlay: boolean;
    ownerId: string;
    dropdownContext: { component?: KuiDropdownComponent, tree?: KuiTreeSelectComponent } = {};
    zoneTypes: ZoneType[] = [];

    @Output() onChange: EventEmitter<{ value: string, dirty: boolean }> = new EventEmitter();

    groups: Bluebird<TagItem[]>;
    treeNodes: KuiTreeSelectNode[];
    selectedItems: TagItem[] = [];
    autoCompleteItems: TagItem[] = [];

    filterDropdown: KuiDropdownComponent;
    tree: KuiTreeSelectComponent;
    @ViewChild(KuiDropdownComponent, { static: true }) set filterDropdownRef(dropdown: KuiDropdownComponent) {
        this.filterDropdown = dropdown;
        this.dropdownContext.component = dropdown;
    }
    @ViewChild(KuiTreeSelectComponent) set treeRef(tree: KuiTreeSelectComponent) {
        this.tree = tree;
        this.dropdownContext.tree = tree;
    }

    constructor(
        private app: AppService,
        private i18n: TranslateService,
        private grouping: AssetGroupingService,
        private filters: EventFilterService,
        private ref: ChangeDetectorRef
    ) {
    }

    validate(): boolean {
        this.touched = true;
        this.dirty = true;
        this.ref.markForCheck();
        return !this.field.required || this.selectedItems.length > 0;
    }

    async ngOnInit() {

        this.ownerId = (this.field.options && this.field.options.ownerId) || this.app.client.id || this.app.user.owner.id;

        this.zoneTypes = [
            { id: 'location', name: this.i18n.instant('SHARED.ZONE_TYPES.LOCATION') },
            { id: 'nogo', name: this.i18n.instant('SHARED.ZONE_TYPES.NOGO') },
            { id: 'keepin', name: this.i18n.instant('SHARED.ZONE_TYPES.KEEPIN') },
            { id: 'route', name: this.i18n.instant('SHARED.ZONE_TYPES.ROUTE') },
        ];

        this.zoneTypes.sort((a, b) => a.name.localeCompare(b.name));

        if (this.field.max === 1) { // if max is one we go into single asset selection mode
            const id = this.values[this.field.id] = this.values[this.field.id] || this.field.value;

            if (id) {
                this.app.api.entities.getZone(id).then(item => {
                    this.selectedItems = [{
                        id: item.id,
                        name: item.name,
                        filter: null,
                    }];
                });
            } else {
                this.selectedItems = [];
            }
            
            const limit = 100;
            // not handling errors here... will just show an empty list if it fails
            const res = await this.app.api.entities.listZones(this.ownerId, 0, limit, 'name:asc', 'state=active');
            const { items, count } = res || {} as ZoneListResponse;
            this.truncatedList = count > limit && { limit, count };
            this.treeNodes = (items || []).map(x => ({
                id: x.id,
                name: x.name,
                data: this.getSpecificZoneFilter(x),
            }));
        } else {
            this.values[this.field.id] = this.values[this.field.id] || this.field.value || [];

            this.selectedItems = this.values[this.field.id].map(x => ({
                id: x.key || x.id || x.targetId,
                name: x.text || x.name,
                filter: x,
            }));

            setTimeout(() => { // avoid ExpressionChangedAfterItHasBeenCheckedError
                this.field.description = this.field.description || this.i18n.instant('FORMS.ZONEFILTER.DESCRIPTION');
                this.ref.markForCheck();
            });


            this.groups = Bluebird.all([
                this.grouping.getZoneGroups(this.ownerId),
            ]).then(([zoneGroups]) => {
                this.treeNodes = this.filters.buildZoneSelectionTree(zoneGroups);
                const groups: TagItem[] = [];

                this.zoneTypes.forEach(zoneType => {
                    const anyDesc = this.i18n.instant('FORMS.ZONEFILTER.GROUP_DESC.ANY', { zoneType: zoneType.name });
                    groups.push({
                        id: 'any.' + zoneType.id,
                        name: anyDesc,
                        filter: {
                            key: 'any.' + zoneType.id,
                            targetId: '00000000-0000-0000-0000-000000000000',
                            targetSelectionType: 'any',
                            targetType: 'zone',
                            targetTypeId: zoneType.id,
                            targetTypeName: zoneType.name,
                            name: anyDesc,
                            text: anyDesc,
                        },
                    });

                    zoneGroups.forEach(item => {
                        const desc = this.i18n.instant('FORMS.ZONEFILTER.GROUP_DESC.GROUP', { zoneType: zoneType.name, name: item.name });
                        groups.push({
                            id: zoneType.id + '-' + item.id,
                            name: desc,
                            filter: {
                                key: zoneType.id + '-' + item.id,
                                targetId: item.id,
                                targetName: item.name,
                                targetSelectionType: 'group',
                                targetType: 'zone',
                                targetTypeId: zoneType.id,
                                targetTypeName: zoneType.name,
                                id: item.id,
                                name: item.name,
                                text: desc,
                            },
                        });
                    });
                });

                return groups;
            });
        }
    }

    updateValues() {
        this.dirty = true;
        this.touched = true;

        if (this.field.max === 1) {
            this.values[this.field.id] = this.selectedItems[0] && this.selectedItems[0].id;
        } else {
            this.values[this.field.id] = this.selectedItems.map(x => x.filter);
        }

        this.onChange.emit({ value: this.values[this.field.id], dirty: this.dirty });
    }

    addTag(value: TagItem) {
        if (this.field.max === 1) {
            this.selectedItems = [value];
        } else {
            this.selectedItems = [...this.selectedItems, value];
        }
        this.updateValues();
    }

    removeTag(value: TagItem) {
        const idx = this.selectedItems.findIndex(x => x.id === value.id);
        this.selectedItems.splice(idx, 1);
        this.updateValues();
    }

    updateAutoCompletionList(searchTerm: string) {
        const filter = searchTerm ? escapeRqlValue(searchTerm) : '';

        this.isLoading = true;
        this.dirty = true;

        return fromPromise(
            Promise.all([
                this.groups,
                this.app.api.entities.listZones(this.ownerId, 0, 3, 'name:asc', 'state=active' + (!!searchTerm.length ? `&name=*${filter}*` : '')),
            ]).then(([groups, zones]) => {
                const staticResults = groups ? groups.filter(x => {
                    return searchTerm.split(' ').reduce((p, word) => {
                        return p + (x.name.toLowerCase().indexOf(word.toLowerCase()) !== 1 ? 1 : 0);
                    }, 0);
                }) : [];

                this.autoCompleteItems = zones.items.map(x => ({
                    id: x.id,
                    name: x.name,
                    filter: this.getSpecificZoneFilter(x),
                }))
                    .concat(staticResults)
                    .filter(x => !this.selectedItems.find(y => y.id === x.id) && x.filter.text.toLowerCase().includes(searchTerm.toLowerCase()));

                this.isLoading = false;
                this.ref.markForCheck();

                return this.autoCompleteItems;
            }).catch(err => {
                console.error(err);
                return [];
            })
        );
    }

    getSpecificZoneFilter(zone: ZoneListItem): { [key: string]: any } {
        return {
            targetId: zone.id,
            targetName: zone.name,
            targetSelectionType: 'specific',
            targetType: 'zone',
            targetTypeId: zone.zoneType,
            targetTypeName: zone.zoneType,
            id: zone.id,
            name: zone.name,
            text: `${this.zoneTypes.find(y => y.id === zone.zoneType).name} "${zone.name}"`,
        };
    }

    nodeSelected(event: { eventName: string, node: TreeNode }, dropdown: KuiDropdownComponent, tree: KuiTreeSelectComponent) {
        this.dirty = true;

        const { data, id } = event.node.data;
        if (data && !this.selectedItems.find(x => x.id === id)) {
            this.addTag({
                id: id,
                name: data.text,
                filter: data,
            });
        }
        if (event.eventName === 'activate') {
            // single select mode still remembers the selection state
            // we want to clear that after activation rather
            tree.clearSelection();
        }
        dropdown.toggle();
    }
}
