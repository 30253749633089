import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KuiModule } from 'app/key-ui';

import { PipeModule } from 'app/shared/pipes/pipe.module';
import { GetKeyPipe } from 'app/shared/pipes/getkey.pipe';
import { KeyImageEditorComponent } from './image-editor.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { KeyImageEditorModalComponent } from './image-editor-modal.component';
import { KeyImageDropTargetDirective } from './imagedrop.directive';

const COMPONENTS = [
    KeyImageEditorComponent,
    KeyImageEditorModalComponent,
    KeyImageDropTargetDirective,
];

@NgModule({
    imports: [
        CommonModule,
        KuiModule,
        TranslateModule.forChild(),
        PipeModule.forRoot(),
        ImageCropperModule,
    ],
    exports: COMPONENTS,
    declarations: COMPONENTS,
    providers: [GetKeyPipe]
})
export class KeyImageEditorModule { }
