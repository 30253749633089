import { Component, HostBinding } from '@angular/core';
import { Params, Router } from '@angular/router';
import { BaseComponent } from 'app/shared/components';
import { TopNavService, TopNavSettings } from 'app/key-ui/top-nav';
import { MatchMediaService } from 'app/services';
import { AppService } from 'app/app.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { StaticPageService } from 'app/shared/components/static-page/static-page.service';
import { CurrentClient } from 'app/shared/model';

@Component({
    selector: 'key-page-external-tabs',
    templateUrl: './external.component.html',
})
export class ExternalTabsComponent extends BaseComponent {

    @HostBinding('class') hostClassnames = 'd-flex flex-column flex-1';
    @HostBinding('class.hidden') hidden = true;

    tabs: { id: string, name: string, url: SafeResourceUrl, activated: boolean }[];
    active = '';
    mobileHeader: TopNavSettings;
    params: Params;

    constructor(
        private app: AppService,
        private router: Router,
        private staticPageService: StaticPageService,
        private topnav: TopNavService,
        public media: MatchMediaService,
        private sanitizer: DomSanitizer
    ) {

        super();

        // clear our tabs if the client changes
        this.on(this.app.client$, (client) => {
            this.tabs = null;
            this.active = '';

            if (client) {
                this.setActiveTab(client.id);
            }
            
        });

        this.on(this.staticPageService.route$, snapshot => {
            if (snapshot && snapshot.params && snapshot.params.tab !== '') {
                this.params = snapshot.params;
            }
            const lastClient = this.app.client ? this.app.client : this.app.appStore.get<CurrentClient>('client');
            this.hidden = !(snapshot && snapshot.url[0].path === 'external');
            this.setActiveTab(lastClient.id);

        });
    }

    setActiveTab(clientId: string) {
        if (!this.hidden) {

            if (!this.tabs) {

                this.tabs = this.app.features.page.external.tabs.split('\n').map(str => {
                    const idx = str.indexOf(':');
                    const info = str.substr(0, idx);
                    const [title, icon, section] = info.split('|');
                    let url = str.substr(idx + 1);
                    if (!section) {
                        // substitute some values internal values if they are present in the url
                        url = url
                            .replace('{token}', this.app.api.accessToken)
                            .replace('{language}', this.app.user.language || 'en-us')
                            .replace('{user}', this.app.user.id)
                            .replace('{client}', clientId);

                        return {
                            id: title.replace(/[^a-z0-9]/ig, '').toLowerCase(),
                            name: title,
                            section,
                            url: this.sanitizer.bypassSecurityTrustResourceUrl(url),
                            activated: false,
                            icon,
                        };
                    }
                    return null;
                }).filter(x => x);
            }

            const tab = this.params?.tab?.replace(/[^a-z0-9]/ig, '')?.toLowerCase() || this.tabs[0];
            
            const tabId = this.tabChange(tab);
            
            if (tabId === tab) {
                const config = {
                    tabs: this.tabs.map(x => ({ id: x.id, title: x.name, click: () => this.tabChange(x.id) })),
                    activeTab: tabId,
                };
                this.topnav.update('external', config);
                this.mobileHeader = config;
            }
        }
    }
    
    tabChange(id: string): string {
        const tab = this.tabs.find(x => x.id === id) || this.tabs[0];
        if (tab) {
            tab.activated = true;
            this.active = tab.id;
            setTimeout(() => {
                this.router.navigate(['external', tab.id]);
            });
            return tab.id;
        }
        return null;
    }

}
