import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KuiModule } from 'app/key-ui';
import { TranslateModule } from '@ngx-translate/core';
import { LabelBadgesComponent } from './label-badges.component';

const COMPONENTS = [
    LabelBadgesComponent
];

@NgModule({
    imports: [
        CommonModule,
        KuiModule,
        TranslateModule,
    ],
    declarations: COMPONENTS,
    exports: COMPONENTS,
})
export class KeyLabelsModule { }
