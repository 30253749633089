<span class="feed-entry">
    <div kui-action
        class="d-flex flex-1"
        [ngClass]="classNames"
        (click)="primaryActionClicked.emit()">
        <key-feed-entry-info [icons]="icons"
            [indicators]="indicators">
            <span id="info-title">
                <span [title]="data | getKey: 'date' | date: 'yyyy-MM-dd HH:mm:ss'">{{ data | getKey: 'date' | units: { unit: 'time', variant: 'short' } }}</span>
            </span>
            <span id="info-small-text"
                class="small muted">{{ getDisplayDate(data.date) }}</span>
        </key-feed-entry-info>

        <div class="feed-entry-details">
            <div class="d-flex flex-wrap">
                <div class='entry-text flex-1 mr-3 mb-2'>
                    <div class="entry-title accent d-flex flex-row">
                        <kui-icon name="info-circle"
                            class="pr-2 icon-event"
                            [ngClass]="'icon-event-' + data.class + '-' + data.type"
                            ></kui-icon>
                        <span [innerHTML]="title"></span>
                    </div>
                    <div class="entry-description muted-less">
                        <key-event-feed-item-description [item]="data"></key-event-feed-item-description>
                    </div>
                    <div *ngIf="showSubtext && data && (handledBy || (data.comments && data.comments.length > 0))"
                        class="mt-2">
                        <div class="small"
                            *ngIf="handledBy">
                            <kui-icon name='check'
                                [fixedWidth]="true"
                                class="mr-1"></kui-icon>
                            {{ 'ALERTS.SUMMARY.ACKNOWLEDGED_BY' | translate: { user: handledBy } }},
                            <span [title]="data | getKey: 'handledBy.date' | date: 'yyyy-MM-dd HH:mm:ss'">{{handledDate | timeAgo}}</span>
                        </div>
                        <div class="small"
                            *ngIf="data.comments && data.comments.length > 0">
                            <kui-icon name='comment'
                                [fixedWidth]="true"
                                class="mr-1"></kui-icon>
                            {{ (data.comments.length > 1 ? 'ALERTS.SUMMARY.COMMENT_COUNT' : 'ALERTS.SUMMARY.COMMENT_COUNT_SINGULAR') | translate: { count: data.comments.length } }}
                        </div>
                        <div class="small"
                            *ngFor="let value of values">
                            <kui-icon name='clipboard'
                                [fixedWidth]="true"
                                class="mr-1"></kui-icon>
                            {{ value.title }}: {{ value.value }}
                        </div>
                    </div>
                </div>

                <div class="media-thumbnail mb-2"
                    *ngIf="thumbnailUrls"
                    (click)="onThumbnailClick($event)">
                    <key-video-thumbnail *ngFor="let url of thumbnailUrls"
                        [url]="url">
                    </key-video-thumbnail>
                </div>
            </div>

        </div>
    </div>

    <key-feed-entry-actions *ngIf="actions && actions.length"
        [actions]="actions"></key-feed-entry-actions>
</span>