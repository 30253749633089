import { Injectable } from '@angular/core';
import { Subscription, BehaviorSubject, Observable, Subject } from 'rxjs';
import { CurrentUser } from 'app/shared/model';
import { AppService } from 'app/app.service';
import { FeedsGetRecentNotificationFeedGQL, FeedsGetRecentNotificationFeedSubscription, NotificationResponse } from 'app/shared/graphql';
import { LocalStore } from '../storage/storage.service';
import { SubscriptionResult } from 'apollo-angular';

interface UserNotificationState {
    sequence: { [userId: string]: number };
}

@Injectable()
export class UserNotificationService {


    private unseenSubject = new BehaviorSubject<number>(0);
    unseen$ = this.unseenSubject.asObservable();

    private newSubject = new Subject<NotificationResponse>();
    new$ = this.newSubject.asObservable();

    private gqlSubscription: Subscription;
    private store: LocalStore;
    lastSequence = 0;
    private user: CurrentUser;

    constructor(
        private app: AppService
    ) {
        this.store = this.app.getStore('user-notifications');

        this.app.user$.subscribe(user => {
            this.init(user);
        });

    }

    private init(user: CurrentUser) {

        if (this.gqlSubscription) {
            this.gqlSubscription.unsubscribe();
            this.gqlSubscription = null;
            this.lastSequence = 0;
            this.user = null;
        }

        this.unseenSubject.next(0);

        if (user) {

            this.user = user;
            this.gqlSubscription = this.getNotificationFeed().subscribe(result => {
                const items = result.data.getRecentNotificationFeed.filter(x => x.sequence > this.lastSequence);
                if (items.length > 0) {
                    if (this.lastSequence > 0) { // don't bombard the user with snack's for notifications while he was away
                        items.forEach(item => {
                            this.newSubject.next(item);
                        });
                    }
                    this.lastSequence = Math.max(...items.map(x => x.sequence)) || 0;
                    const userLastSeenSequence = this.getLastSeenSequence();
                    const userUnseenCount = items.filter(x => x.sequence > userLastSeenSequence).length;
                    this.unseenSubject.next(userUnseenCount);
                }
            }, error => {
                // ignore any errors here, we don't have a good way of displaying them.
                console.error(error);
            });

        }
    }

    getNotificationFeed(): Observable<SubscriptionResult<FeedsGetRecentNotificationFeedSubscription>> {
        return new FeedsGetRecentNotificationFeedGQL(this.app.api.gql).subscribe({
            limit: 10,
        });
    }

    resetUnseen() {
        if (this.lastSequence > 0) {
            this.setLastSeen(this.lastSequence);
        }
        this.unseenSubject.next(0);
    }

    getLastSeenSequence(): number {
        const state = this.store.get<UserNotificationState>('state');
        if (this.user && state) {
            return state.sequence[this.user.id];
        }
        return 0;
    }

    setLastSeen(value: number) {
        if (this.user) {
            const state = this.store.get<UserNotificationState>('state') || {
                sequence: {},
            };
            state.sequence[this.user.id] = value;
            this.store.set('state', state);
        }
    }




}
