import { Directive, Renderer2, ElementRef } from '@angular/core';

@Directive({
    selector: '[kui-list]',
})
export class KuiListDirective {
    constructor(private el: ElementRef, private renderer: Renderer2) {
        this.renderer.addClass(this.el.nativeElement, 'list-group');
    }
}
