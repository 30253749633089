import { EditorHandler } from './entity.handler';
import { Injector } from '@angular/core';

export const ENTITY_HANDLER_PROVIDERS = {};
// Decorator that will populate the ENTITY_HANDLER_PROVIDERS dictionary with entity names and classes. This became
// necessary to prevent circular references caused by the different handlers needing access to each other in order
// to link to each other.
export function RegisterEntityHandler(name: string) {
    return (target: Object) => {
        ENTITY_HANDLER_PROVIDERS[name] = target;
    };
}

export function InjectEntityHandler(injector: Injector, type: string): EditorHandler {
    const entityClass = ENTITY_HANDLER_PROVIDERS[type] || ENTITY_HANDLER_PROVIDERS[type.replace(/s$/, '')]  || ENTITY_HANDLER_PROVIDERS[type.replace(/ies$/, 'y')];
    if (!entityClass) {
        throw new Error(`Handler for entity '${type}' was not found.`);
    }
    return injector.get(entityClass);
}
