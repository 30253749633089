import { Component, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
    selector: 'key-layout-grid-column-resizer',
    templateUrl: './layout-grid-column-resizer.component.html',
    styleUrls: ['./layout-grid-column-resizer.component.scss'],
})
export class LayoutGridColumnResizerComponent {

    @Output() isSelected: EventEmitter<boolean> = new EventEmitter();
    @Output() isDoubleClicked: EventEmitter<any> = new EventEmitter();

    // @HostBinding('class.border-color') boderColor = true;
    // @HostBinding('class.border-width-more') boderWidth = true;

    @HostListener('mousedown') mouseDown() { this.selected(true); }
    @HostListener('touchstart') touchStart() { this.selected(true); }
    @HostListener('mouseup') mouseUp() { this.selected(false); }
    @HostListener('touchend') touchEnd() { this.selected(false); }
    @HostListener('dblclick') doubleClick() { this.doubleClicked(); }

    selected(state: boolean) {
        this.isSelected.emit(state);
    }

    doubleClicked() {
        this.isDoubleClicked.emit();
    }
}
