import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilderDefinition } from 'app/shared/components/form-builder';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { getPresetIconSvgString } from './preset.icons';
import { KuiModalService } from 'app/key-ui/modal/modal.service';
import { AnalyticsSettingsPresetsModalComponent } from './modal/modal.component';

export interface PresetSettings {
    iconType: 'icon' | 'svg';
    iconName: string;
    title: string;
    formDefinition?: FormBuilderDefinition;
    values: { [key: string]: any };
}

@Component({
    selector: 'key-analytics-settings-presets',
    templateUrl: './preset.component.html',
    styleUrls: ['./preset.component.scss'],
})
export class AnalyticsSettingsPresetComponent {
    @Input() options: PresetSettings[];
    @Output() onSelected: EventEmitter<{ [key: string]: any; }> = new EventEmitter();

    constructor(private sanitizer: DomSanitizer, private modal: KuiModalService) {
    }

    selectPreset(preset: PresetSettings) {
        if (preset.formDefinition) {
            this.modal.open(AnalyticsSettingsPresetsModalComponent, {
                data: {
                    title: preset.title,
                    values: preset.values,
                    form: preset.formDefinition,
                },
                actions: {
                    close: () => this.modal.close(),
                    apply: () => {
                        this.modal.close();
                        this.onSelected.emit(preset.values);
                    },
                },
            });
        } else {
            this.onSelected.emit(preset.values);
        }
    }

    getPresetIcon(name: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(getPresetIconSvgString(name));
    }
}
