import * as Bluebird from 'bluebird';
import { Injectable, EventEmitter, Injector } from '@angular/core';
import { EditorHandler, FormSectionHandler } from './entities/entity.handler';
import { AppService } from 'app/app.service';
import { AdminProgressModalComponent } from './progress/progress-modal.component';
import { AdminBulkConfirmModalComponent } from './confirm/confirm-modal.component';
import { FormBuilderDefinition } from 'app/shared/components/form-builder';
import { cloneDeep } from 'lodash';
import { MeasurementUnitsService, ErrorLoggerService, NotificationService } from 'app/services';
import { ApiService } from 'app/services/api/api.service';
import { TranslateService } from '@ngx-translate/core';
import { AdminWizardModalComponent } from './wizard/wizard-modal.component';
import { InjectEntityHandler } from './entities';
import { AdminSelectModalComponent } from './select/select.component';
import { HttpClient } from '@angular/common/http';
import { IdName, ClientResponse, UserResponse } from '@key-telematics/fleet-api-client';
import { CurrentClient, CurrentUser } from 'app/shared/model';
import { ModalService } from 'app/shared/components/modal';
import { EntityService } from 'app/services/entity/entity.service';
import { KeyTranslateLoader } from 'app/services/translate/translate-loader';
import { ERRORS } from 'app/shared/model/errors.model';
import { THEME_DEFAULTS } from '../theme/theme-defaults';
import { AdminEditorModalComponent } from './modal/editor-modal.component';

export interface TimeZoneEntry {
    id: string;
    name: string;
    offset: number;
}


@Injectable()
export class AdminEditorService {

    constructor(
        protected app: AppService,
        public entities: EntityService,
        protected http: HttpClient,
        protected injector: Injector,
        public units: MeasurementUnitsService,
        public modal: ModalService,
        public i18n: TranslateService,
        protected error: ErrorLoggerService,
        protected notify: NotificationService
    ) {

        this.app.theme$
            .subscribe(theme => {
                this.primaryColor = (theme && theme.variables && theme.variables['--color-primary-color']) || THEME_DEFAULTS.color.primary.color;
            });
    }

    get currentClient(): CurrentClient {
        return this.app.client;
    }

    get features() { // this is intentionally not typed, as typescript can infer the types from the undelying merged classes
        return this.app.features;
    }

    get flags() { // this is intentionally not typed, as typescript can infer the types from the undelying merged classes
        return this.app.flags;
    }


    primaryColor = THEME_DEFAULTS.color.primary.color;

    private timeZones: TimeZoneEntry[] = null;
    private countries: IdName[] = null;

    selectedItems: { id: string, name: string }[] = [];

    lastSearchParams: { [key: string]: any } = {};

    cache = {};

    setSelection(items: any[]) {
        this.selectedItems = items;
    }

    translate(key: string, values?: Object): string {
        return this.i18n.instant(`ADMIN.${key}`, values);
    }

    get user(): CurrentUser {
        return this.app.user;
    }

    get apiEndpoint(): string {
        return this.app.env.apiEndpoint;
    }

    get apiAccessToken(): string {
        return this.app.api.accessToken;
    }

    get isStaging(): boolean {
        return this.app.isStaging;
    }

    // caches promises, thus preventing race conditions when multiple things ask for the same item at the same time.
    getCached<T>(id: string, missFunction: (api: ApiService) => Promise<T>, ttlSeconds?: number, bypassCache?: boolean): Promise<T> {
        return this.entities.getCached(id, missFunction, ttlSeconds, bypassCache);
    }

    applyUpdate<T>(updateFunction: (api: ApiService) => Promise<T>, additionalProperties?: Object): Promise<T> {
        return this.entities.applyUpdate(updateFunction, additionalProperties);
    }

    clientUpdated(client: ClientResponse) {
        if (this.currentClient.id === client.id) {
            // updates the client in localStorage
            this.app.setClient({
                ...this.currentClient,
                name: client.name,
                theme: client.theme,
                measurementUnits: client.measurementUnits,
                mapSetId: client.mapSet && client.mapSet.id,
                flags: client.meta.mergedFlags,
            });
        }
    }

    userUpdated(user: UserResponse) {
        if (this.app.user.id === user.id) {
            this.app.setUser({
                ...this.app.user,
                name: user.name,
                emailAddress: user.emailAddress,
                defaultClientId: user.defaultClient.id,
                costCentre: user.costCentre,
                timeZoneId: user.timeZoneId,
                language: user.language,
            });
        }
    }

    confirmBulkUpdates(count: number, changes: { name: string, value: string }[]): Promise<boolean> {

        return new Promise<boolean>((resolve, _reject) => {

            this.modal.open(AdminBulkConfirmModalComponent, {
                data: {
                    changes: changes,
                    count: count,
                },
                actions: {
                    close: () => {
                        this.modal.close();
                        resolve(false);
                    },
                    apply: () => {
                        this.modal.close();
                        resolve(true);
                    },
                },
            });
        });
    }

    applyBulkUpdates(applyFunc: (entity: any, payload: any) => Promise<any>, items: { id: string, name?: string }[], payload: any): Promise<void> {

        const progress = new EventEmitter<{ progress: number, total: number, text: any, errorMessage?: string }>();
        let aborted = false;

        this.modal.open(AdminProgressModalComponent, {
            data: {
                progress: progress,
            },
            actions: {
                abort: () => {
                    aborted = true;
                },
                close: () => {
                    this.modal.close();
                },
            },
        });

        let count = 0;
        return Promise.resolve().then(() => {
            return Bluebird.delay(100).then(() => { // give the modal time to open
                return Bluebird.map(items, item => {
                    if (aborted) { throw new Error('Action was aborted by user'); }
                    progress.emit({ progress: count, total: items.length, text: this.i18n.instant('ADMIN.MODALS.PROGRESS.UPDATING', { name: item.name || '' }) });
                    return applyFunc(item, payload).then(() => {
                        count++;
                        // return Bluebird.delay(1000); // only update one item a second or we risk triggering the rate limiter
                    });
                }, { concurrency: 1 }).then(() => {
                    progress.emit({ progress: count, total: items.length, text: this.i18n.instant('ADMIN.MODALS.PROGRESS.DONE') });
                    // return Bluebird.delay(1000).then(() => {
                        this.modal.close();
                    // });
                }).catch(error => {
                    this.error.trackException(error, [ERRORS.VALIDATE_ERROR]);
                    const errorMessage = this.getErrorMessage(error);
                    progress.emit({ progress: count, total: items.length, text: this.i18n.instant('ADMIN.MODALS.PROGRESS.ERROR'), errorMessage: errorMessage });
                });
            });
        });

    }

    applyBulkDeletes(handler: EditorHandler, items: { id: string, name: string }[]): Bluebird<void> {

        const progress = new EventEmitter<{ progress: number, total: number, text: string, errorMessage?: string }>();
        let aborted = false;

        this.modal.open(AdminProgressModalComponent, {
            data: {
                progress: progress,
            },
            actions: {
                abort: () => {
                    aborted = true;
                },
                close: () => {
                    this.modal.close();
                },
            },
        });

        let count = 0;
        return Bluebird.delay(100).then(() => { // give the modal time to open
            return Bluebird.map(items, item => {
                if (aborted) { throw new Error('Action was aborted by user'); }
                progress.emit({ progress: count, total: items.length, text: this.i18n.instant('ADMIN.MODALS.PROGRESS.DELETING', { name: item.name }) });
                return handler.setEntityState(item, 'deleted').then(() => {
                    count++;
                    return Bluebird.delay(1000); // only update one item a second or we risk triggering the rate limiter
                });
            }, { concurrency: 1 }).then(() => {
                progress.emit({ progress: count, total: items.length, text: this.i18n.instant('ADMIN.MODALS.PROGRESS.DONE') });
                return Bluebird.delay(1000).then(() => {
                    this.modal.close();
                });
            }).catch(error => {
                this.error.trackException(error);
                const errorMessage = this.getErrorMessage(error);
                progress.emit({ progress: count, total: items.length, text: this.i18n.instant('ADMIN.MODALS.PROGRESS.ERROR'), errorMessage: errorMessage });
            });
        });

    }

    getErrorMessage(error: any) {
        if (error && error.name === ERRORS.VALIDATE_ERROR && error.data && error.data.fields) {
            const errors = Object.keys(error.data.fields).reduce((result, field) => {
                result.push(error.data.fields[field].message);
                return result;
            }, []);
            return errors.join(', ');
        } else {
            return this.notify.translateError(error);
        }
    }


    showFormModal<T>(form: FormBuilderDefinition, values: T): Promise<T> {
        const data = cloneDeep(values);
        return this.modal.form(form, data);
    }

    showEditorModal<T>(editor: FormSectionHandler<T, any>, entity: any): Promise<T> {
        return new Promise<T>((resolve, _reject) => {
            this.modal.open(AdminEditorModalComponent, {
                data: {
                    editor,
                    entity,
                },
                actions: {
                    close: () => {
                        this.modal.close();
                        resolve(null);
                    },
                    apply: (result: T) => {
                        this.modal.close();
                        resolve(result);
                    },
                },
            });
        });
    }

    showCreateWizardModal<T>(ownerId: string, handler: EditorHandler): Promise<T> {
        return new Promise<any>((resolve, _reject) => {
            this.modal.open(AdminWizardModalComponent, {
                data: {
                    ownerId: ownerId,
                    handler: handler,
                },
                actions: {
                    close: () => {
                        this.modal.close();
                        resolve(null);
                    },
                    apply: (result: T) => {
                        this.modal.close();
                        resolve(result);
                    },
                },
            });
        });
    }


    showSelectEntitiesModal<T>(ownerId: string, handlerType: string): Promise<T[]> {

        const handler = InjectEntityHandler(this.injector, handlerType);

        return new Promise<any>((resolve, _reject) => {
            this.modal.open(AdminSelectModalComponent, {
                data: {
                    ownerId: ownerId,
                    handler: handler,
                },
                actions: {
                    close: () => {
                        this.modal.close();
                        resolve([]);
                    },
                    apply: (items: T[]) => {
                        this.modal.close();
                        resolve(items);
                    },
                },
            });
        });
    }

    async getTimeZones(): Promise<TimeZoneEntry[]> {
        if (!this.timeZones) {
            this.timeZones = await this.http.get(`assets/docs/timezones.json`).toPromise() as any;
            this.timeZones = this.timeZones.map(x => ({ ...x, name: x.id }));
            this.timeZones.sort((a, b) => a.name.localeCompare(b.name));
        }
        return this.timeZones;
    }

    async getCountries(): Promise<IdName[]> {
        if (!this.countries) {
            const result = await this.http.get(`assets/docs/countries.json`).toPromise();
            this.countries = Object.keys(result).map(key => ({ id: key, name: result[key] }));
            this.countries.sort((a, b) => a.name.localeCompare(b.name));
        }
        return this.countries;
    }


    getLanguages(): Promise<IdName[]> {
        const items = KeyTranslateLoader.getLanguages();
        return Promise.resolve(items.sort((a, b) => a.name.localeCompare(b.name)));
    }


}
