import { Component, Input } from '@angular/core';

import { combineLatest } from 'rxjs';
import { MatchMediaService } from 'app/services/match-media/match-media.service';

@Component({
  selector: '[key-viewport]',
  templateUrl: './viewport.component.html',
})
export class ViewportComponent {
  @Input() isMobile: boolean;
  @Input() isTablet: boolean;
  @Input() isDesktop: boolean;

  observable$: any;

  isVisible: boolean;

  constructor(private media: MatchMediaService) {
    this.observable$ = combineLatest(
      this.media.isMobile,
      this.media.isTablet,
      this.media.isDesktop
    )
      .subscribe(checks => {
        const [mobile, tablet, desktop] = checks;

        const mobileIsVisible = this.isMobile ? mobile : false;
        const tabletIsVisible = this.isTablet ? tablet : false;
        const desktopIsVisible = this.isDesktop ? desktop : false;

        this.isVisible = mobileIsVisible || tabletIsVisible || desktopIsVisible; // show content if any of the viewports are set to show
      });
  }
}
