import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KuiIconComponent } from './icon.component';

@NgModule({
  imports: [CommonModule],
  exports: [KuiIconComponent],
  declarations: [KuiIconComponent],
})
export class KuiIconModule {}
