import { Component, Input } from '@angular/core';

@Component({
  selector: 'kui-badge',
  templateUrl: './badge.component.html',
})
export class KuiBadgeComponent {
  @Input() style = 'default';
}

