import { Point } from './map.component';

export class SpatialUtils {

    static earthRadius = 6367; // radius in km

    static degToRad(x: number): number {
        return x * Math.PI / 180.0;
    }

    static radToDeg(x: number): number {
        return x * 180.0 / Math.PI;
    }

    static calculateCoord(origin: Point, brng: number, arcLength: number): Point {
        const lat1 = SpatialUtils.degToRad(origin.y);
        const lon1 = SpatialUtils.degToRad(origin.x);
        const centralAngle = arcLength / SpatialUtils.earthRadius;
        const lat2 = Math.asin(Math.sin(lat1) * Math.cos(centralAngle) + Math.cos(lat1) * Math.sin(centralAngle) * Math.cos(SpatialUtils.degToRad(brng)));
        const lon2 = lon1 + Math.atan2(Math.sin(SpatialUtils.degToRad(brng)) * Math.sin(centralAngle) * Math.cos(lat1), Math.cos(centralAngle) - Math.sin(lat1) * Math.sin(lat2));
        return {
            x: SpatialUtils.radToDeg(lon2),
            y: SpatialUtils.radToDeg(lat2),
        };
    }

    static getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number): number {
        const dLat = SpatialUtils.degToRad(lat2 - lat1);
        const dLon = SpatialUtils.degToRad(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(SpatialUtils.degToRad(lat1)) * Math.cos(SpatialUtils.degToRad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
            ;
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = SpatialUtils.earthRadius * c; // Distance in km
        return d;
    }

    static generateRegularPolygon(centerPoint: Point, radius: number, numberOfPoints: number): Point[] {
        const points = [];
        const centralAngle = 360.0 / numberOfPoints;
        const offset = numberOfPoints === 4 ? 45.0 : 0.0;
        for (let i = 0; i < numberOfPoints; i++) {
            points.push(SpatialUtils.calculateCoord(centerPoint, (i * centralAngle + offset) % 360, radius));
        }
        return points;
    }


    static pointInPolygon(point: Point, polygon: Point[]) {
        // ray-casting algorithm based on
        // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html

        const { x, y } = point;

        let inside = false;
        for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {

            const xi = polygon[i].x, yi = polygon[i].y;
            const xj = polygon[j].x, yj = polygon[j].y;

            const intersect = ((yi > y) !== (yj > y))

                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) { inside = !inside; }
        }

        return inside;
    }

}
