<kui-input-group [cleanAddons]="true"
    class="w-100 d-block">
    <kui-tag-input [invalid]="error || (field.required && (dirty && touched) && selectedItems.length === 0)"
        [placeholder]="field.placeholder || 'FORMS.TAGS.PLACEHOLDER' | translate"
        [autoCompleteItems]="autoCompleteItems"
        [freeText]="!autoCompleteItems"
        [selectedItems]="selectedItems"
        (itemAdded)="addTag($event)"
        (itemRemoved)="removeTag($event)"
        [multiple]="true"></kui-tag-input>

    <span kui-input-group-addon
        class="p-0"
        [class.border-danger]="error || (field.required && (dirty && touched) && selectedItems.length === 0)"
        [class.overlay]="overlay"
        *ngIf="field.actions">

        <ng-container *ngFor="let action of field.actions">
            <button *ngIf="!action.dropdownValues && (action.name || action.icon)"
                kui-button
                class="p-0"
                color="none"
                (click)="onActionClick(action)">
                <kui-icon *ngIf="action.icon"
                    [name]="action.icon"
                    size="xs"
                    class="py-2 px-3"></kui-icon>
                {{ action.name }}
            </button>
            <kui-dropdown *ngIf="!!action.dropdownValues && (action.name || action.icon)"
                #dropdown
                [style]="'none'"
                [customContent]="dropdownContent"
                [customContentContext]="{ component: dropdown }"
                (onOpen)="overlay = true"
                (onClose)="overlay = false">
                <kui-icon *ngIf="action.icon"
                    [name]="action.icon"
                    size="xs"
                    class="py-2 px-3"></kui-icon>
                {{ action.name }}
            </kui-dropdown>
            <ng-template #dropdownContent
                let-component="component">
                <div style="min-width: 200px">
                    <kui-tree-select [nodes]="action.dropdownValues"
                        #tree
                        (selectedNodeChanged)="onDropdownClick(component, tree, $event)"></kui-tree-select>
                </div>
            </ng-template>
        </ng-container>
    </span>
</kui-input-group>

<div *ngIf="field.required && (dirty && touched) && selectedItems.length === 0"
    class="invalid-feedback d-block">
    <div>{{ 'FORMS.SHARED.REQUIRED' | translate:{ field: field.title } }}</div>
</div>
<div *ngIf="error"
    class="invalid-feedback d-block">
    <div>{{ error }}</div>
</div>