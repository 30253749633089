import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'app/app.service';
import { KuiSnackbarService } from 'app/key-ui/snackbar/snackbar.service';
import { ErrorLoggerService } from 'app/services';
import { BaseComponent } from 'app/shared/components/base/base.component';
import { MapEventsService } from 'app/shared/components/map/map-events.service';
import { MapToolsService } from 'app/shared/components/map/map-tools.service';
import { MapZonesService } from 'app/shared/components/map/map-zones.service';
import { LayerClickEvent, MapMarker, MapRoute } from 'app/shared/components/map/map.component';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as uuid from 'uuid';
import { MapOptionRoutingService } from '../../map-option-routing.service';
import { RoutingInputText } from './map-toolbar-routing-input.component';

export interface RouteInput {
    lat: number;
    lon: number;
    address: string;
    searchTerm?: string;
}

export interface RouteMarker {
    marker: MapMarker;
    input: RoutingInputText;
}

@Component({
    selector: 'key-map-toolbar-routing',
    templateUrl: './map-toolbar-routing.component.html',
    styleUrls: ['./map-toolbar-routing.component.scss'],
})
export class MapToolbarRoutingComponent extends BaseComponent implements OnInit, OnDestroy {
    markers: RouteMarker[];
    routes: MapRoute[];
    activeRoute: MapRoute;
    loading: boolean;
    errorMessage: string;

    constructor(
        private i18n: TranslateService,
        public mapTools: MapToolsService,
        private ref: ChangeDetectorRef,
        public app: AppService,
        private error: ErrorLoggerService,
        private zones: MapZonesService,
        private snackbar: KuiSnackbarService,
        private mapEvents: MapEventsService,
        private routingService: MapOptionRoutingService,
    ) {
        super();
    }

    @Output() onRoutesUpdated = new EventEmitter<MapRoute[]>();
    @Output() onRoutesClear = new EventEmitter<MapRoute[]>();
    @Output() onSetMarker = new EventEmitter<MapMarker>();
    @Output() onMarkersClear = new EventEmitter<MapMarker[]>();
    @Output() onClose = new EventEmitter<void>();

    ngOnInit(): void {
        const filterRoutes = (obs: Observable<LayerClickEvent>) => obs.pipe(filter(event => event.layer === 'route'))

        this.on(this.mapEvents.layerClick$.pipe(filterRoutes), event => {
            this.setActiveRoute(event.id);
        });
        this.on(this.mapEvents.layerDblClick$.pipe(filterRoutes), event => {
            if (this.activeRoute) {
                if (event.id !== this.activeRoute.id) {
                    // user double clicked on a non active route... do him a favour and make it active and then sommer still open up the save modal why don't we!!
                    this.setActiveRoute(event.id);
                }
                this.saveSelectedRoute(this.activeRoute);
            }
        });
        this.on(this.routingService.routeInput$, input => {
            if (this.markers && !!this.markers.length) {
                this.onMarkersClear.emit(this.markers.map(x => x.marker));
            }
            this.markers = this.getMarkersFromInputs(input);
            this.markers.forEach((routeMarker, index) => {
                const { lat, lon } = routeMarker.marker;
                if (lat && lon) {
                    const marker = this.extendMarker(routeMarker.marker);
                    this.markers[index] = {
                        ...routeMarker,
                        marker,
                    };
                    this.onSetMarker.emit(marker);
                }
            });
            if (input?.length >= 1) {
                // We have a start and end point, create a route.
                this.updateRoutesList(this.markers.map(x => x.marker));
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll();
        // clean up after our selves
        this.onMarkersClear.emit(this.markers.map(x => x.marker));
        this.onRoutesClear.emit(this.routes);
        this.routingService.setRouteInput([]);
    }

    getMarkersFromInputs(inputs: RouteInput[]): RouteMarker[] {
        const getMarker = (lat: number, lon: number, address: string, searchTerm: string) => {
            const id = uuid.v4();
            return {
                input: { address, searchTerm },
                marker: { id, name: address, lat, lon, getIcon: null },
            };
        };
        const res = inputs.map(x => getMarker(x.lat, x.lon, x.address || '', x.searchTerm || ''));
        // add empty field if we only have one filled field
        if (res.length === 1) {
            res.push(getMarker(null, null, '', ''));
        }
        return res;
    }

    getInputPlaceholderText(index: number, inputs: any[]): string {
        // last item will be END, first item START and anything else INTERMEDIATE
        const translations = {
            0: 'START',
            [inputs.length - 1]: 'END',
        };
        return this.i18n.instant(`LEAFLET.ROUTING.INPUT.${translations[index] || 'INTERMEDIATE'}`);
    }

    updateMarkerPosition(index: number, value: RouteMarker) {
        const marker = this.extendMarker(value.marker);
        const routeInput: RouteInput = {
            lat: value.marker.lat,
            lon: value.marker.lon,
            address: value.input.address,
            searchTerm: value.input.searchTerm,
        };
        this.markers[index] = {
            ...value,
            marker,
        };
        this.routingService.updateRouteInput(index, routeInput);
        this.onSetMarker.emit(marker);
    }

    markerUpdated(index: number, value: RouteMarker) {
        this.updateMarkerPosition(index, value);
        this.updateRoutesList(this.markers.map(x => x.marker));
    }

    async updateRoutesList(markers: MapMarker[]) {
        markers = markers.filter(x => x.lat != null && x.lon != null);
        // we only want to search if two or more coords have been set
        if (markers.length <= 1) { return; }

        this.errorMessage = null;
        this.loading = true;

        try {
            this.onRoutesClear.emit(this.routes);
            this.routes = await this.mapTools.doRouting(markers);
            this.setActiveRoute(this.routes.find(x => x.strategy === 'fastest').id);
        } catch (error) {
            this.error.trackException(error);
            this.errorMessage = this.i18n.instant('LEAFLET.ROUTING.ERROR.NO_ROUTES');
            this.onRoutesClear.emit(this.routes);
        } finally {
            this.loading = false;
            this.ref.markForCheck();
        }
    }

    setActiveRoute(id: string) {
        this.routes.forEach(route => {
            route.active = route.id === id;
            if (route.active) {
                this.activeRoute = route;
            }
        });
        this.onRoutesUpdated.emit(this.routes);
    }

    async saveSelectedRoute(route: MapRoute) {
        try {
            const result = await this.zones.newZoneModal('route', route.coords.map(([x, y]) => ({ x, y })));
            if (result) {
                this.snackbar.message(this.i18n.instant('LEAFLET.ROUTING.NOTIFY.SAVED_SUCCESS'), null, 'check');
                this.onMarkersClear.emit(this.markers.map(x => x.marker));
                this.onRoutesClear.emit(this.routes);
                this.onClose.emit();
            }
        } catch (error) {
            this.error.trackException(error);
            this.snackbar.message(this.i18n.instant('LEAFLET.ROUTING.NOTIFY.SAVED_FAIL'), null, 'exclamation');
        }
    }

    // add drag and bound setting functions to a given marker
    private extendMarker(marker: MapMarker): MapMarker {
        return {
            ...marker,
            draggable: true,
            dragged: async (lat, lon) => {
                const result = await this.mapTools.getCoordinatesDetails(lat, lon);
                const index = this.markers.findIndex(x => (x.marker && x.marker.id) === marker.id);
                const m = {
                    ...this.markers[index].marker,
                    lat: result.lat,
                    lon: result.lon,
                    name: result.address,
                };
                this.updateMarkerPosition(index, {
                    input: { ...this.markers[index].input, address: result.address },
                    marker: m,
                });
                this.updateRoutesList(this.markers.map(x => x.marker));
                this.ref.markForCheck();
            },
            getBounds: () => {
                const { lat: firstLat, lon: firstLon } = this.markers[0].marker;
                const { lat: lastLat, lon: lastLon } = this.markers[this.markers.length - 1].marker;
                // the bounds should include all set locations
                return {
                    top: Math.min(...[firstLat, lastLat].filter(x => x != null)),
                    bottom: Math.max(...[firstLat, lastLat].filter(x => x != null)),
                    left: Math.min(...[firstLon, lastLon].filter(x => x != null)),
                    right: Math.max(...[firstLon, lastLon].filter(x => x != null)),
                };
            },
            getIcon: () => ({ size: 0, html: `<i class="location-icon map-font-icon__shadows icon icon-map-marker-alt"></i>` }),
        };
    }

    isNotFirstInput(index: number): boolean {
        return index !== 0;
    }

    swopPointsAtIndexes(lastIndex: number) {
        // this is not voodoo... It is es6 destructuring. Swopping them around like this mutates the original array which is what we want in this case
        [this.markers[lastIndex - 1], this.markers[lastIndex]] = [this.markers[lastIndex], this.markers[lastIndex - 1]];
        // update the 2 markers on the map too
        this.updateMarkerPosition(lastIndex - 1, this.markers[lastIndex - 1]);
        this.updateMarkerPosition(lastIndex, this.markers[lastIndex]);
        this.updateRoutesList(this.markers.map(x => x.marker));
    }
}
