import { Injectable, ViewContainerRef } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { MapOption, MapOptionComponent } from './components';

@Injectable()
export class MapOptionService {
    
    private queue: MapOption<any>[] = [];
    private current: MapOption<any>;

    private _viewContainerRefSubject: ReplaySubject<ViewContainerRef> = new ReplaySubject(1);
    private _currentSubject: ReplaySubject<MapOption<any>> = new ReplaySubject(1);
    private _fullscreenSubject: ReplaySubject<boolean> = new ReplaySubject(1);
    private _closeSubject: ReplaySubject<boolean> = new ReplaySubject(1);
    private _compactSubject: ReplaySubject<boolean> = new ReplaySubject(1);

    current$ = this._currentSubject.asObservable();
    fullscreen$ = this._fullscreenSubject.asObservable();
    close$ = this._closeSubject.asObservable();
    isCompact$ = this._compactSubject.asObservable();

    constructor() { }

    setViewContainerRef(ref: ViewContainerRef) {
        this._viewContainerRefSubject.next(ref);
    }

    load<T>(option: MapOption<T>) {
        // We can't load anything without a ViewContainerRef, so let's subscribe to it.
        this._viewContainerRefSubject.pipe(first()).subscribe(ref => {
            this.queue.push(option);
            this.setCurrent(option);
            ref.clear();
            const component = ref.createComponent<MapOptionComponent<T>>(option.component);
            component.instance.data = option.data;
        })
    }

    back() {
        // remove the one we've just been at.
        this.queue.pop();
        // go to the one before.
        const option = this.queue.pop();

        if (option) {
            this.load(option);
        } else {
            this.clear();
        }
    }

    clear() {
        this.queue.length = 0;
        this.setCurrent(null);
        this._viewContainerRefSubject.pipe(first()).subscribe(ref => {
            ref.clear();
        })
    }

    close() {
        this.clear();
        this._closeSubject.next(true);
    }

    setFullscreen(state: boolean) {
        this._fullscreenSubject.next(state);
    }

    getCurrent(): MapOption<any> {
        return this.current;
    }

    private setCurrent(option: MapOption<any>) {
        this.current = option;
        this._currentSubject.next(option);
    }

    set compact(value: boolean) {
        this._compactSubject.next(value);
    }

}
