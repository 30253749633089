import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { KuiModule } from 'app/key-ui/kui.module';
import { PipeModule } from 'app/shared/pipes/pipe.module';
import { KuiLoaderModule } from 'app/key-ui/loader/loader.module';

import { SelectionComponent } from './selection.component';
import { SelectionModalComponent } from './modal/selection-modal.component';
import { KeyListingModule } from '../listing/listing.module';

const COMPONENTS = [
    SelectionComponent,
    SelectionModalComponent,
];

@NgModule({
    imports: [
        CommonModule,
        KuiModule,
        KeyListingModule,
        FormsModule,
        TranslateModule.forChild(),
        KuiLoaderModule,
        PipeModule.forRoot(),
    ],
    exports: COMPONENTS,
    declarations: COMPONENTS,
    providers: [
    ],
})
    
export class KeySelectionModule { }
