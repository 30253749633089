import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { KuiModule } from "app/key-ui";
import { PipeModule } from "app/shared/pipes/pipe.module";
import { KeyFormBuilderModule } from "../../form-builder/form-builder.module";
import { KeyListingModule } from "../../listing/listing.module";
import { KeySearchResultsListModule } from "../../search-results-list/search-results-list.module";
import {
    MapOptionDirective,
    MapOptionLayerComponent,
    MapOptionLayerService,
    MapOptionListComponent,
    MapOptionMeasurementService,
    MapOptionMeasurementsComponent,
    MapOptionPointInformationComponent,
    MapOptionRoutesComponent,
    MapOptionRoutingComponent,
    MapOptionRoutingService,
    MapOptionSearchComponent,
    MapOptionSearchInputComponent,
    MapOptionSearchService,
    MapOptionSimpleZoneEditorComponent,
    MapOptionZoneEditorComponent,
    MapOptionZoneRemoverComponent,
    MapOptionZoneSelectorComponent,
    MapOptionZonesComponent,
    MapOptionZonesService,
    MapToolbarRoutingComponent,
    MapToolbarRoutingInputComponent,
    PointInformationService
} from "./components";
import { MapOptionsComponent } from "./map-options.component";
import { ShowSearchInputPipe } from "./pipes";
import { ZoneEditorService, ZoneSelectorService } from "./services";
import { MapOptionService } from "./map-option.service";



const EXPORTS = [
    MapOptionsComponent,
    MapOptionLayerComponent,
    MapOptionZonesComponent,
    MapOptionRoutesComponent,
    MapOptionMeasurementsComponent,
    MapOptionPointInformationComponent,
    MapOptionSearchComponent,
    MapOptionRoutingComponent,
]

// These services must be provided by a map instance,
// if not things get funky when there are multiple maps on the screen at once.
export const PROVIDED_SERVICES = [
    MapOptionMeasurementService,
    PointInformationService,
    MapOptionLayerService,
    MapOptionZonesService,
    MapOptionSearchService,
    MapOptionRoutingService,
    ZoneSelectorService,
    ZoneEditorService,
    MapOptionService,
]

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        FormsModule,
        KuiModule,
        KeySearchResultsListModule,
        KeyListingModule,
        PipeModule,
        KeyFormBuilderModule
    ],
    exports: [
        MapOptionsComponent,
        ...EXPORTS
    ],
    declarations: [ 
        MapOptionDirective,
        MapOptionListComponent,
        MapOptionSearchInputComponent,
        MapToolbarRoutingComponent,
        MapToolbarRoutingInputComponent,
        MapOptionZoneSelectorComponent,
        MapOptionZoneEditorComponent,
        MapOptionZoneRemoverComponent,
        MapOptionSimpleZoneEditorComponent,
        ShowSearchInputPipe,
        ...EXPORTS
    ]
})
export class KeyMapOptionsModule { }
