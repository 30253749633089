import { Component, Input, EventEmitter, Output, HostBinding, OnChanges } from '@angular/core';

@Component({
  selector: 'key-options-sidebar-card',
  templateUrl: './options-sidebar-card.component.html',
})
export class OptionsSidebarCardComponent implements OnChanges {

  @HostBinding('class.active') activeClass = false;

  @Input() id: string;
  @Input() title: string;
  @Input() icon: string;
  @Input() active: boolean;
  @Output() onClick = new EventEmitter();

  ngOnChanges() {

    this.activeClass = this.active;

  }

}
