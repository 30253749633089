<table class="pivot-table">
    <tbody>
        <ng-container *ngFor="let row of cellset; let l = last;">
            <tr>
                <ng-container *ngFor="let column of row">
                    <ng-container *ngIf="column.type === 'COLUMN_HEADER' || column.type === 'ROW_HEADER_HEADER' || column.type === 'ROW_HEADER'; else tableCell">
                        <th [attr.colspan]="column.properties.colspan" [ngClass]="{
                                'py-1': true,
                                'px-2': true,
                                'column__header': column.type === 'COLUMN_HEADER',
                                'row__header-header': column.type === 'ROW_HEADER_HEADER',
                                'row__header': column.type === 'ROW_HEADER',
                                'table-header': column.type === 'COLUMN_HEADER' || column.type === 'ROW_HEADER_HEADER' || column.type === 'ROW_HEADER',
                                'column__empty': !l && !column.value && column.type === 'ROW_HEADER',
                                'no-border-top': l && !column.value && column.type === 'ROW_HEADER',
                                'pointer': column.properties.color,
                                'selected': selected === (column.value | removeSpaces | lowercase)
                            }"
                            class="{{convertToClassListString(column.properties.classnames)}}"
                            (click)="doOnHeaderClick(column)"
                        >
                            <div
                                *ngIf="column.properties.color"
                                class="pivot-table__color"
                                [style.background]="column.properties.color">
                            </div>
                            {{ column.value }}
                        </th>
                    </ng-container>
                    <ng-template #tableCell>
                        <td class="column__cell p-2 text-right {{convertToClassListString(column.properties.classnames)}}"
                            [class.table-header]="column.properties.color"
                            [ngStyle]="{
                                'width': column.properties?.format?.width + 'px' || 'auto'
                            }">
                            {{ column.value }}
                        </td>
                    </ng-template>
                </ng-container>
            </tr>
        </ng-container>
    </tbody>
</table>
