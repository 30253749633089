import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AppService } from 'app/app.service';
import { KuiIconModule } from 'app/key-ui';
import { KuiLoaderModule } from 'app/key-ui/loader/loader.module';
import { MediaService, VideoRequestSettings } from 'app/services/media/media.service';
import { SelectionItem } from 'app/shared/components/selection/selection.model';
import { KeySelectionModule } from 'app/shared/components/selection/selection.module';
import { Validate } from '../validate.model';

export type VideoAsset = SelectionItem & VideoRequestSettings;

@Component({
    selector: 'key-video-asset-selection',
    templateUrl: 'asset-selection.component.html',
    styleUrls: ['asset-selection.component.scss'],
    imports: [KeySelectionModule, CommonModule, KuiLoaderModule, KuiIconModule, TranslateModule],
    standalone: true,
})
export class AssetSelectionComponent implements OnInit, Validate {

    clientId: string;

    asset = signal(undefined);
    loading = signal(false);
    warning = signal(false);

    @Output() assetSelected = new EventEmitter<VideoAsset>();

    constructor(app: AppService, private mediaService: MediaService) {
        this.clientId = app.client.id;
    }

    ngOnInit() {}

    selectItem(item: SelectionItem) {
        this.asset.set(item);
        this.loading.set(true);
        this.warning.set(false);
        this.mediaService.getVideoRequestSettings(item.id).then(settings => {
            this.warning.set(!settings);
            this.loading.set(false);
            if (settings) {
                this.assetSelected.emit({
                    ...this.asset(),
                    ...settings
                });
            }
        });
    }

    isValid(): boolean {
        return !!this.asset() && !this.warning() && !this.loading();
    }

}