import { NgModule } from '@angular/core';
import { GridsterModule } from 'angular-gridster2';
import { PollService } from 'app/services';
import { AnalyticsService } from '../analytics/services/analytics.service';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardWidgetComponent } from './components/widget/widget.component';
import { SidePanelService } from './services/side-panel.service';
import { KuiModule } from 'app/key-ui';
import { KeyAnalyticsModule } from '../analytics';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from 'app/shared/pipes/pipe.module';
import { DashboardViewReportModalComponent } from './components/modal/view-report/view-report.component';
import { AdminEditorService } from '../admin/admin.service';

const COMPONENTS = [
    DashboardComponent,
    DashboardWidgetComponent,
    DashboardViewReportModalComponent,
];

@NgModule({
    imports: [
        CommonModule,
        KuiModule,
        KeyAnalyticsModule,
        PipeModule.forRoot(),
        TranslateModule.forChild(),
        GridsterModule,
    ],
    declarations: COMPONENTS,
    exports: COMPONENTS,
    providers: [
        AnalyticsService,
        PollService,
        SidePanelService,
        AdminEditorService,
    ],
})
export class KeyDashboardsModule { }
