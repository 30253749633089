import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { KuiModalRefComponent } from 'app/key-ui/modal/modal-ref.component';
import * as moment from 'moment-timezone';
import { MatchMediaService } from 'app/services';
import { DeviceFeaturesOs } from 'app/services/match-media/match-media.service';
import { MediaService, MediaViewerItem } from 'app/services/media/media.service';
import { ModalService } from '../../modal';
import { VideoComponent } from '../video/video.component';

export type PlaybackAction = 'play' | 'pause' | 'backward' | 'forward' | 'start' | 'end';



// would have loved to keep this on the media service, but a circular depenency would result
export function showMediaViewerModal(modal: ModalService, item: MediaViewerItem) {
    modal.open(MediaViewerModalComponent, {
        data: {
            item: item,
        },
        actions: {
            close: () => modal.close(),
        },
    });
}

@Component({
    selector: 'key-media-viewer-modal',
    templateUrl: 'viewer-modal.component.html',
    styleUrls: ['viewer-modal.component.scss'],
})
export class MediaViewerModalComponent extends KuiModalRefComponent<{ item: MediaViewerItem }> implements OnInit {

    constructor(
        private matchMedia: MatchMediaService,
        private media: MediaService
    ) {
        super();
    }

    title: string;
    isIOS: boolean;

    videos: { url: string, filename: string, type: string }[] = [];
    photos: { url: string, filename: string, type: string }[] = [];

    @ViewChildren(VideoComponent) videoElements: QueryList<VideoComponent>;

    asset: string;
    progress = 0;
    currentTime: string;
    durationTime: string;
    isPlaying = false;
    loadedCount = 0;
    showPlaybackControls = false;

    ngOnInit() {

        this.isIOS = this.matchMedia.deviceFeatures.os === DeviceFeaturesOs.IOS;

        const details = this.data.item.details;
        const media = this.data.item.media;
        if (details) {

            const mediaHost = this.media.getMediaHost();

            this.title = details.asset.name;
            const deviceId = details.telemetry ? details.telemetry.origin.id : 'unknown';
            const ownerId = this.data.item.owner.id;
            this.asset  = details.asset && details.asset.id;
            const date = moment.utc(this.data.item.date).format('YYYYMMDD');

            if (details.attachments) {

                this.videos = details.attachments.filter(x => x.type.indexOf('video/') === 0).map(attachment => ({
                    ...attachment,
                    url: `${mediaHost}/media/video/${attachment.filename}?device=${deviceId}`,
                }));

                this.photos = details.attachments.filter(x => x.type.indexOf('image/') === 0).map(attachment => ({
                    ...attachment,
                    url: `${mediaHost}/media/image?filename=${ownerId}/${this.asset}/${date}/${attachment.filename}`,
                }));

            }

            if (media && media.length > 0) {
                this.videos = media.filter(x =>
                    x.mediaType.includes('video') &&
                    !x.filename.endsWith('.jpg') // Fixed a bug that was in the backend that would confuse pictures and videos. Added here to fix old images.
                ).map(item => ({
                    type: 'video/mp4', // TODO: fix this
                    filename: item.filename,
                    url: this.media.getVideoUrl(this.asset, item.filename),
                }));
            }

        }
    }

    onVideoLoaded(_video: VideoComponent) {
        this.loadedCount++;
        if (this.loadedCount === this.videos.length) {
            setTimeout(() => {
                this.onPlaybackAction('play'); // auto start once all of the videos have been loaded
                this.showPlaybackControls = true;
            }, 500);
        }

    }

    onVideoStatusChanged(video: VideoComponent) {
        if (this.videoElements.first === video) {
            this.isPlaying = video.isPlaying;
        }
    }

    onVideoProgress(video: VideoComponent) {
        if (this.videoElements.first === video) {
            this.progress = video.progress;
            this.currentTime = video.currentTime;
            this.durationTime = video.durationTime;
        }
    }

    detachModal() {
        this.actions.close();
    }

    onPlaybackAction(action: PlaybackAction) {
        switch (action) {
            case 'play':
                this.videoElements.forEach(video => video.play());
                break;
            case 'pause':
                this.videoElements.forEach(video => video.pause());
                break;
            case 'forward':
                this.videoElements.forEach(video => video.seek('forward', 3));
                break;
            case 'backward':
                this.videoElements.forEach(video => video.seek('backward', 3));
                break;
            case 'start':
                this.videoElements.forEach(video => video.seek('start'));
                break;
            case 'end':
                this.videoElements.forEach(video => video.seek('end'));
                break;
            default:
                break;
        }
    }

    onSeek(perc: number) {
        this.videoElements.forEach(video => {
            try {
                if (video.duration > 0) {
                    video.seek('exact', video.duration * (perc / 100));
                }
            } catch {
                // don't let errors bubble up here
            }
        });
    }

}
