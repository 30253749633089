import { Component, Input, ElementRef, Renderer2, OnInit, OnChanges, HostListener, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: '[kui-list-item]',
  templateUrl: './list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KuiListItemComponent implements OnInit, OnChanges {
  styleList: string[] = ['success', 'info', 'warning', 'danger'];

  @Input() type: 'default' | 'action' = 'default';
  @Input() isDisabled: boolean;
  @Input() isActive: boolean;
  @Input() isActiveOnHover: boolean;
  @Input() style: 'success' | 'info' | 'warning' | 'danger';

  @HostListener('mouseenter') onHover() {
    if (this.isActiveOnHover) {
      this.renderer.addClass(this.el.nativeElement, 'active');
    }
  }

  @HostListener('mouseleave') onHoverLeave() {
    if (this.isActiveOnHover) {
      this.renderer.removeClass(this.el.nativeElement, 'active');
    }
}

  constructor(private el: ElementRef, private renderer: Renderer2) {
    renderer.addClass(el.nativeElement, 'list-group-item');
  }

  ngOnInit() {
    if (this.type === 'action') {
      this.renderer.addClass(this.el.nativeElement, 'list-group-item-action');
    }
  }

  ngOnChanges() {
    if (this.isActive) {
        this.renderer.addClass(this.el.nativeElement, 'active');
    } else {
        this.renderer.removeClass(this.el.nativeElement, 'active');
    }

    if (this.isDisabled) {
        this.renderer.addClass(this.el.nativeElement, 'disabled');
    } else {
        this.renderer.removeClass(this.el.nativeElement, 'disabled');
    }

    /*
    * remove all other style classes first
    */
    this.styleList.forEach(style => {
      this.renderer.removeClass(this.el.nativeElement, `list-group-item-${style}`);
    });

    /*
    * add the current style if present
    */
    if (this.style) {
      this.renderer.addClass(this.el.nativeElement, `list-group-item-${this.style}`);
    }
  }
}

