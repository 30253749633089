<key-options-sidebar class="d-flex"
    [activeCard]="activeTab"
    [title]="title"
    [absolute]="absolute"
    (onSelected)="handleTabChange($event)"
    (onHide)="onHide.emit()"
    [align]="align">

    <kui-alert-panel *ngIf="message"
        [icon]="message.icon"
        [message]="message.text">
        <button *ngFor="let button of message?.buttons; let i = index;"
            kui-button
            size="sm"
            class="mb-2"
            [class.ml-1]="i > 0"
            [color]="button.color"
            (click)="button.action($event)">{{button.text}}</button>
    </kui-alert-panel>

    <key-options-sidebar-card [icon]="tab.icon"
        *ngFor="let tab of customTabs"
        [id]="tab.id"
        [title]="tab.title">
        <div class="py-3 px-4">
            <div *ngIf="!!tab?.actions?.length"
                class="d-flex justify-content-end pb-3 mb-3 border-bottom">
                <button kui-button
                    *ngFor="let action of tab.actions"
                    size="sm"
                    class="ml-1"
                    [color]="action.color || 'primary'"
                    (click)="action.click()">
                    {{action.name}}
                </button>
            </div>
            <key-form-builder [form]="tab.form"
                [values]="tab.values"
                (onChange)="updateCustomSettings($event)">
            </key-form-builder>
        </div>
    </key-options-sidebar-card>

    <!-- TODO: ADD PRESETS -->
    <!-- <key-options-sidebar-card title="'PRESETS" id="presets">
        <div class="py-3 px-4">
            <key-analytics-settings-presets
                *ngIf="presetOptions"
                [options]="presetOptions"
                (onSelected)="updatePresetOptions($event)"
            ></key-analytics-settings-presets>
        </div>
    </key-options-sidebar-card> -->
    <key-options-sidebar-card icon="table"
        [title]="'ANALYTICS.SETTINGS.TABLE' | translate"
        id="table"
        [hidden]="!tableSettings">
        <div class="py-3 px-4"
            *ngIf="tableSettings">
            <key-analytics-settings-table [options]="tableSettings"
                [show]="{
                    tableLayout: show.tableLayout,
                    levels: show.levels,
                    flipped: show.flipped,
                    measures: show.measures,
                    daterange: show.daterange,
                    calcMeasures: show.calcMeasures
                }"
                [allowCustomDates]="allowCustomDates"
                [singleMeasureSelection]="singleMeasureSelection"
                (onExport)="onExport.emit($event)"
                (onChange)="updateTableSettings($event)"></key-analytics-settings-table>
        </div>
    </key-options-sidebar-card>
    <key-options-sidebar-card icon="chart-line"
        [title]="'ANALYTICS.SETTINGS.SERIES' | translate"
        id="series"
        [hidden]="!seriesSettings">
        <div class="py-3 px-4"
            *ngIf="seriesSettings">
            <key-analytics-settings-series [options]="seriesSettings"
                [overriddenValues]="overriddenSeriesValues"
                (onChange)="updateSeriesSettings($event)"
                (onClear)="clearOverriddenSeriesValues($event)"
                (onRemove)="removeMesureFromTable($event)"></key-analytics-settings-series>
        </div>
    </key-options-sidebar-card>
    <key-options-sidebar-card icon="filter"
        [title]="'ANALYTICS.SETTINGS.FILTERS' | translate"
        id="filters"
        [hidden]="hideFilters">
        <div class="py-3 px-4">
            <key-analytics-settings-filters *ngIf="filterSettings"
                [filters]="filters"
                [options]="filterSettings"
                (onChange)="updateFilterSettings($event)"></key-analytics-settings-filters>
        </div>
    </key-options-sidebar-card>
    <key-options-sidebar-card icon="sort-amount-up"
        [title]="'ANALYTICS.SETTINGS.SORT' | translate"
        id="sort"
        [hidden]="!sortSettings">
        <div class="py-3 px-4"
            *ngIf="sortSettings">
            <key-analytics-settings-sort [options]="sortSettings"
                (onChange)="updateSortSettings($event)"></key-analytics-settings-sort>
        </div>
    </key-options-sidebar-card>
    <key-options-sidebar-card icon="chart-pie"
        [title]="'ANALYTICS.SETTINGS.PIE' | translate"
        id="pie"
        [hidden]="!pieSettings">
        <div class="py-3 px-4"
            *ngIf="pieSettings">
            <key-analytics-settings-pie [options]="pieSettings"
                (onChange)="updatePieSettings($event)"></key-analytics-settings-pie>
            <key-analytics-settings-legend *ngIf="legendSettings"
                [title]="'ANALYTICS.SETTINGS.LEGEND' | translate"
                [options]="legendSettings"
                (onChange)="updateLegendSettings($event)"></key-analytics-settings-legend>
        </div>
    </key-options-sidebar-card>
    <key-options-sidebar-card icon="percent"
        [title]="'ANALYTICS.SETTINGS.STAT' | translate"
        id="stat"
        [hidden]="!statSettings">
        <div class="py-3 px-4"
            *ngIf="statSettings">
            <key-analytics-settings-stat [options]="statSettings"
                (onChange)="updateStatSettings($event)"></key-analytics-settings-stat>
        </div>
    </key-options-sidebar-card>
    <key-options-sidebar-card icon="chart-bar"
        [title]="'ANALYTICS.SETTINGS.CHART' | translate"
        id="chart"
        [hidden]="!show.chart">
        <div class="py-3 px-4"
            *ngIf="show.chart">
            <key-analytics-settings-chart *ngIf="chartSettings"
                [options]="chartSettings"
                [show]="{
                    stacked: show.stacked,
                    dataLabels: show.dataLabels
                }"
                (onChange)="updateChartSettings($event)"></key-analytics-settings-chart>
            <key-analytics-settings-y-axis *ngIf="yAxisSettings"
                [options]="yAxisSettings"
                [show]="{
                    stacked: show.stacked,
                    yAxes: show.yAxes
                }"
                [limits]="yAxesLimits"
                (onChange)="updateYAxisSettings($event)"></key-analytics-settings-y-axis>
            <key-analytics-settings-x-axis *ngIf="xAxisSettings"
                title="X_AXIS"
                [options]="xAxisSettings"
                (onChange)="updateXAxisSettings($event)"></key-analytics-settings-x-axis>
            <key-analytics-settings-legend *ngIf="legendSettings"
                title="LEGEND"
                [options]="legendSettings"
                (onChange)="updateLegendSettings($event)"></key-analytics-settings-legend>
        </div>
    </key-options-sidebar-card>
    <key-options-sidebar-card icon="info"
        [title]="'ANALYTICS.SETTINGS.INFO' | translate"
        id="info"
        [hidden]="!app.isSystemUser && settings.id">
        <div class="py-3 px-4">
            <key-analytics-settings-info [reportId]="settings.id"></key-analytics-settings-info>
        </div>
    </key-options-sidebar-card>
</key-options-sidebar>