import { AppService } from 'app/app.service';
import { HttpClient } from '@angular/common/http';
import { urlToDataUrl } from 'app/shared/utils/dataUrl';
import { Injectable } from '@angular/core';

@Injectable()
export class ImageService {

    constructor(private app: AppService, private http: HttpClient) {
    }

    cache: { [key: string]: string } = {};

    clear(id?: string) {
        if (id) {
            Object.keys(this.cache).filter(url => url.includes(id)).forEach(url => {
                delete this.cache[url];
            });
        } else {
            this.cache = {};
        }
    }

    async load(url: string): Promise<string> {
        let item = this.cache[url];
        if (!item) {
            try {
                item = await this.asDataUrl(this.http, url, this.app.api.accessToken);
            } catch (err) {
                console.error(url, err);
                item = null; 
            }
            this.cache[url] = item;
        }
        return item;
    }

    asDataUrl(http: HttpClient, url: string, accessToken?: string): Promise<string> { // wrapper to allow testing
        return urlToDataUrl(http, url, accessToken);
    }

    async getForUser(id: string): Promise<string> {
        return id ? this.load(`${this.app.env.apiEndpoint}/accounts/users/${id}/avatar`) : null;
    }

    async getForClient(client: { id: string }): Promise<string> {
        return client ? this.load(`${this.app.env.apiEndpoint}/accounts/clients/${client.id}/logo`) : null;
    }

    async getForAsset(assetId: string): Promise<string> {
        return assetId ? this.load(`${this.app.env.apiEndpoint}/entities/assets/${assetId}/avatar`) : null;
    }
}
