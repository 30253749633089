import { Component, Output, EventEmitter, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilderDefinition, FormBuilderFieldChangeEvent } from 'app/shared/components/form-builder';
import { TranslateService } from '@ngx-translate/core';

export type XAxisLabelLayoutType = 'horizontal' | 'auto';
export interface XAxisSettings {
    labels: {
        layout: XAxisLabelLayoutType;
    };
}

export interface XAxisValues {
    labelsLayout: XAxisLabelLayoutType;
}

@Component({
    selector: 'key-analytics-settings-x-axis',
    templateUrl: './x-axis.component.html',
})
export class AnalyticsSettingsXAxisComponent implements OnInit, OnChanges {
    form: FormBuilderDefinition;

    values: XAxisValues;

    @Input() options: XAxisSettings;
    @Input() title: string;
    @Output() onChange: EventEmitter<{ [key: string]: any; }> = new EventEmitter();

    constructor(private i18n: TranslateService) {
    }

    ngOnInit() {
        this.form = {
            'groups': [{
                name: this.title.toUpperCase(),
                fields: [{
                    type: 'radio', id: 'labelsLayout', title: 'LABELS_LAYOUT', values: [
                        { key: 'auto', value: this.i18n.instant('ANALYTICS.SETTINGS.AUTO') },
                        { key: 'horizontal', value: this.i18n.instant('ANALYTICS.SETTINGS.HORIZONTAL') },
                    ],
                }],
            }],
        };
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.options && this.options) {
            const { labels, ...opts } = this.options;
            this.values = {
                ...opts,
                labelsLayout: labels && labels.layout || 'auto',
            };
        }
    }

    changeFormSetting(change: FormBuilderFieldChangeEvent) {
        if (change.dirty) {
            const { id } = change.field;
            if (id === 'labelsLayout') {
                this.onChange.emit({
                    labels: { layout: change.values[id] },
                });
            } else {
                this.onChange.emit({
                    [id]: change.values[id],
                });
            }
        }
    }
}
