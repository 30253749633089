<kui-input-group [cleanAddons]="true"
    class="w-100 d-block">
    <div kui-input>
        <span (click)="openPresets()">{{ getDayPresetText(dayValue) }}</span>
        <span *ngIf="!showTimeInput"
            class="ml-3">{{ timeValue }}</span>
        <input *ngIf="showTimeInput"
            type="time"
            class="time-input ml-1"
            [ngModel]="timeValue"
            (ngModelChange)="changeTime($event)">
    </div>

    <span kui-input-group-addon
        class="p-0">

        <kui-dropdown [menu]="dayPresetDropdownValues"
            [style]="'none'">
            <kui-icon name="calendar-day"
                size="xs"
                class="py-2 px-3"></kui-icon>
        </kui-dropdown>

        <kui-datetime #timeEl
            *ngIf="timeAsISOValue"
            [date]="timeAsISOValue"
            type="time"
            inputStyle="none"
            class="time-icon"
            (onChange)="changeTime($event)"></kui-datetime>
    </span>
</kui-input-group>

<div *ngIf="invalidTimeError"
    class="invalid-feedback d-block">
    <div>{{ 'FORMS.DAYTIME.INVALID_TIME' | translate }}</div>
</div>
<div *ngIf="error"
    class="invalid-feedback d-block">
    <div>{{ error }}</div>
</div>