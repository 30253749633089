import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { KuiModule } from 'app/key-ui';
import { KeyFilterPanelComponent } from './filter-panel.component';
import { KeyFilterSectionComponent } from './filter-section.component';
import { KeyCheckboxFilterComponent } from './filters/checkbox-filter/checkbox-filter.component';
import { KeyRadioFilterComponent } from './filters/radio-filter/radio-filter.component';
import { KeyTreeFilterComponent } from './filters/tree-filter/tree-filter.component';
import { TreeFilterService } from './filters/tree-filter/tree-filter.service';
import { KeyTreeViewComponent } from './filters/tree-filter/tree-view/tree-view.component';

const COMPONENTS = [
    KeyFilterPanelComponent,
    KeyCheckboxFilterComponent,
    KeyRadioFilterComponent,
    KeyFilterSectionComponent,
    KeyTreeFilterComponent,
    KeyTreeViewComponent,
];


@NgModule({
    imports: [
        CommonModule,
        KuiModule,
        FormsModule,
        TranslateModule,
    ],
    exports: COMPONENTS,
    declarations: COMPONENTS,
    providers: [    
        TreeFilterService
    ],
        
})
export class KeyFilterPanelModule { }
