<div class="p-3 root">

    <div *ngIf="config.searchable"
        class="pb-2">
        <kui-input-group>
            <input kui-input
                [ngModel]="searchValue"
                (ngModelChange)="debouncedUpdateSearchValue($event)"
                [placeholder]="'SHARED.SEARCH_ELLIPSES' | translate">
            <button kui-button
                kui-input-group-addon
                color="primary"
                (click)="clearSearch()">
                <kui-icon name="times"></kui-icon>
            </button>
        </kui-input-group>
    </div>

    <div *ngIf="selectedItems.length > 0"
        class="border-bottom mb-2 pb-2">
        <div *ngFor="let item of selectedItems">
            <label class="form-check-label"
                (click)="itemDeselected(item)">
                <span [ngStyle]="{ 'padding-left': (item.level * 18) + 'px' }"></span>
                <input [checked]="true"
                    kui-checkbox> {{ item.name }}
            </label>
        </div>
    </div>

    <div class="item-list">
        <div *ngFor="let item of deselectedItems">
            <label class="form-check-label"
                (click)="itemSelected(item)">
                <span [ngStyle]="{ 'padding-left': (item.level * 18) + 'px' }"></span>
                <input [checked]="false"
                    kui-checkbox> {{ item.name }}
            </label>
        </div>
        <div *ngIf="deselectedItems.length === 0">No more items</div>
    </div>
</div>