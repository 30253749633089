import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { KuiModule } from 'app/key-ui';

import { LeafletMapComponent } from './leaflet.component';

import { PipeModule } from 'app/shared/pipes/pipe.module';
import { KuiLoaderModule } from 'app/key-ui/loader/loader.module';
import { KeyModalModule } from '../modal';
import { KeyMapToolbarModule } from './map-toolbar/map-toolbar.module';
import { KeyMapOptionsModule } from './map-options/map-options.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        KuiModule,
        TranslateModule.forChild(),
        PipeModule,
        KuiLoaderModule,
        KeyModalModule,
        KeyMapToolbarModule,
        KeyMapOptionsModule,
    ],
    exports: [
        LeafletMapComponent,
    ],
    declarations: [
        LeafletMapComponent,
    ],
})
export class KeyMapModule { }
