import { Component, OnInit } from '@angular/core';
import { KuiModalRefComponent } from 'app/key-ui/modal/modal-ref.component';
import { FormSectionHandler } from '../../admin/entities/entity.handler';

@Component({
    templateUrl: 'editor-modal.component.html',
})
export class AdminEditorModalComponent extends KuiModalRefComponent<{ editor: FormSectionHandler<any, any>, entity: any }> implements OnInit {

    constructor() {
        super();
    }

    title: string;

    ngOnInit() {
        this.title = this.data.editor.title;
    }

    onEditing(_editing: boolean) {
    }

    onSaved(item: any) {
        this.actions.apply(item);
    }

    onCanceled() {
        this.actions.close();
    }

    detachModal() {
        this.actions.close();
    }
}
