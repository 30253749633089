import { Injectable } from '@angular/core';
import { KuiModalService, DynamicModalConfig } from 'app/key-ui/modal/modal.service';
import { FormBuilderDefinition } from '../form-builder';
import { FormModalComponent } from './form/form-modal.component';
import { ConfirmModalOptions, ConfirmModalComponent } from './confirm/confirm.component';
import { ErrorModalOptions, ErrorModalComponent } from './error/error.component';
import { TranslateService } from '@ngx-translate/core';
import { AlertModalOptions, AlertModalComponent } from './alert/alert.component';
import { IFrameModalComponent, IFrametModalOptions } from './iframe/iframe.component';
import { JsonModalComponent, JsonModalOptions } from './json/json.component';

@Injectable()
export class ModalService {

    constructor(
        private i18n: TranslateService,
        private modalService: KuiModalService
    ) { }

    open<TOptions>(component: any, config: DynamicModalConfig<TOptions>) {
        return this.modalService.open(component, config);
    }

    close() {
        this.modalService.close();
    }

    form<T>(form: FormBuilderDefinition, values: T): Promise<T> {
        return new Promise<T>((resolve) => {
            this.modalService.open(FormModalComponent, {
                data: {
                    form: form,
                    values: values,
                },
                actions: {
                    close: () => {
                        this.close();
                        resolve(null);
                    },
                    apply: () => {
                        this.close();
                        resolve(values);
                    },
                },
            });
        });
    }

    confirm(message: string): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.modalService.open<ConfirmModalOptions>(ConfirmModalComponent, {
                data: {
                    message: message,
                },
                actions: {
                    close: (result: boolean) => {
                        resolve(result);
                        this.close();
                    },
                },
            });
        });
    }

    alert(message: string, heading?: string): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.modalService.open<AlertModalOptions>(AlertModalComponent, {
                data: {
                    message,
                    heading,
                },
                actions: {
                    close: (result: boolean) => {
                        resolve(result);
                        this.close();
                    },
                },
            });
        });
    }

    json(object: any, heading?: string): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.modalService.open<JsonModalOptions>(JsonModalComponent, {
                data: {
                    object,
                    heading,
                },
                actions: {
                    close: (result: boolean) => {
                        resolve(result);
                        this.close();
                    },
                },
            });
        });
    }

    iframe(title: string, url: string): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.modalService.open<IFrametModalOptions>(IFrameModalComponent, {
                data: {
                    title,
                    url,
                },
                actions: {
                    close: (result: boolean) => {
                        resolve(result);
                        this.close();
                    },
                },
            });
        });
    }

    unexpectedError(error?: Error): Promise<boolean> {
        return this.error('ERROR.UNEXPECTED_ERROR_OCCURRED', error);
    }

    error(message: string, error?: Error, title?: string): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.modalService.open<ErrorModalOptions>(ErrorModalComponent, {
                data: {
                    message: message && this.i18n.instant(message),
                    title: title && this.i18n.instant(title),
                    error: error,
                },
                actions: {
                    close: (result: boolean) => {
                        resolve(result);
                        this.close();
                    },
                },
            });
        });
    }


}
