<kui-card>
    <kui-card-body *ngIf="(distance$ | async) as distance">
        <h5>{{ 'LEAFLET.MEASUREMENTS.TITLE' | translate }}: {{ distance }}</h5>
        <p>{{ 'LEAFLET.MEASUREMENTS.DESC' | translate }}</p>
        <button kui-button
            color="primary"
            class="mr-1 mt-1"
            (click)="copyToClipboard(distance)">{{ 'DIALOG.COPY' | translate }}</button>
        <button kui-button
            color="secondary"
            class="mr-1 mt-1"
            (click)="undo()">{{ 'LEAFLET.DRAW.DELETE_LAST_POINT' | translate }}</button>
        <button kui-button
            color="secondary"
            class="mr-1 mt-1"
            (click)="close()">{{ 'DIALOG.CLOSE' | translate }}</button>
    </kui-card-body>
</kui-card>