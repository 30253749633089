<key-view-report-modal *ngIf="baseReportId"
    [id]="baseReportId"
    [settings]="baseReportSettings"
    [isMobile]="isMobile"
    (onClose)="hideReport($event)">
</key-view-report-modal>

<div class="flex-1 wrapper"
    *ngIf="!!items.length">
    <gridster [options]="options"
        [style.transform]="transformX">
        <gridster-item [item]="item"
            *ngFor="let item of items"
            [id]="item?.widgetId"
            [class.unselected]="selectedWidget && item?.widgetId !== selectedWidget.id"
            [class.selected]="selectedWidget && item?.widgetId === selectedWidget.id">
            <kui-card>
                <kui-card-body class="d-flex flex-column flex-1">
                    <div class="drag"
                        [class.dragging]="isDragging"></div>
                    <div class="non-drag">
                        <key-dashboard-widget [dashboardId]="id"
                            [widget]="widgets[item.widgetId]"
                            [widgetId]="item.widgetId"
                            [showSettings]="!viewOnly && showSettings"
                            [showDelete]="!viewOnly && showDelete"
                            [showShare]="!viewOnly && showShare"
                            [showReportLink]="!viewOnly && !widgets[item.widgetId]?.inherited && !isDashboardTemplate"
                            [useSampleData]="useSampleData"
                            [size]="item.size"
                            (onStartPolling)="addWidgetToPollQueue.emit($event)"
                            (onStopPolling)="removeWidgetFromPollQueue.emit($event)"
                            (onSettingsSelected)="setSelectedWidget($event)"
                            (onChartLoaded)="handleChartLoad($event)"
                            (onUpdate)="onUpdateWidget.emit($event)"
                            (onDelete)="removeWidget($event)"
                            (onShare)="shareWidgetToDashboard($event)"
                            (onShowReport)="showReportFromWidgetId($event)"
                            (onDataLoaded)="selectedWidgetFilters = $event.filters"></key-dashboard-widget>
                    </div>
                </kui-card-body>
            </kui-card>
        </gridster-item>
    </gridster>
</div>

<key-analytics-settings *ngIf="selectedWidget && selectedWidgetSettings"
    [title]="'DASHBOARDS.WIDGET_SETTINGS' | translate"
    [settings]="selectedWidgetSettings"
    [show]="visibleWidgetSettings"
    [customTabs]="customWidgetSettings"
    [allowCustomDates]="false"
    [absolute]="true"
    [align]="'right'"
    [message]="settingsMessage"
    [activeTab]="selectedWidgetTab"
    [pieSegments]="pieSegments"
    [statSeries]="statSeries"
    [singleMeasureSelection]="selectedWidget.type === 'stat'"
    [filters]="selectedWidgetFilters"
    [disableFilters]="disableFilterSettings"
    (onTabChange)="selectedWidgetTab = $event"
    (onHide)="clearSelectedWidget()"
    (onSeriesClear)="clearSeriesItems($event)"
    (onSettingsChange)="updateWidgetSettings($event)"
    class="d-flex"></key-analytics-settings>

<kui-alert-panel *ngIf="!hasWidgets"
    icon="tachometer"
    [size]="9"
    [message]="'DASHBOARDS.ERRORS.NO_OPEN_WIDGETS' | translate">
    <button kui-button
        (click)="navigation.navigateToPage('reporting')"
        page="reporting">{{ 'DASHBOARDS.OPEN_REPORTS' | translate }}</button>
</kui-alert-panel>