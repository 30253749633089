import { Injectable } from '@angular/core';
import { Observable , fromEvent, BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, mergeMap, startWith, tap } from 'rxjs/operators';
import { DeviceDetectorService, DEVICES, OS, BROWSERS } from 'ngx-device-detector';

// doing this to keep anything else in the software to use ngx-device-detector directly
export const DeviceFeaturesOs = OS;
export const DeviceFeaturesBrowser = BROWSERS;

export interface Breakpoint {
    media: string;
    alias: string;
}

export interface MediaQuery {
    min?: number;
    max?: number;
    unit?: string;
}

export interface DeviceFeatures {
    touch?: boolean;
    colorpicker?: boolean;
    datepicker?: boolean;
    os?: string;
    browser?: string; 
}

@Injectable()
export class MatchMediaService {

    breakpoints: Breakpoint[] = [
        { media: this.convertToMediaQuery({ max: 320 }), alias: 'xs' },
        { media: this.convertToMediaQuery({ min: 321, max: 576 }), alias: 'sm' },
        { media: this.convertToMediaQuery({ min: 576, max: 768 }), alias: 'md' },
        { media: this.convertToMediaQuery({ min: 768, max: 992 }), alias: 'lg' },
        { media: this.convertToMediaQuery({ min: 993 }), alias: 'xl' },
    ];

    breakpoint: Observable<Breakpoint>;
    isMobile: Observable<boolean>;
    isTablet: Observable<boolean>;
    isMobileOrTablet: Observable<boolean>;
    isDesktop: Observable<boolean>;

    deviceFeatures: DeviceFeatures = {};

    currentBreakpointSubject = new BehaviorSubject<Breakpoint>({} as Breakpoint);
    get current(): Breakpoint {
        return this.currentBreakpointSubject.getValue();
    }

    constructor(private deviceService: DeviceDetectorService) {
        this.breakpoint = this.observer().pipe(
            map(_ => this.breakpoints
                .filter(pb => this.mediaDoesMatch(pb.media))),
            mergeMap(pb => pb),
            tap(bp => this.currentBreakpointSubject.next(bp)),
            distinctUntilChanged()
        );

        this.isMobile = this.breakpoint
            .pipe(map((bp: Breakpoint) => bp.alias === 'xs' || bp.alias === 'sm'));

        this.isTablet = this.breakpoint
            .pipe(map((bp: Breakpoint) => bp.alias === 'md'));

        this.isMobileOrTablet = this.breakpoint
            .pipe(map((bp: Breakpoint) => bp.alias === 'xs' || bp.alias === 'sm' || bp.alias === 'md'));

        this.isDesktop = this.breakpoint
            .pipe(map((bp: Breakpoint) => bp.alias === 'lg' || bp.alias === 'xl'));

        this.deviceFeatures = {
            colorpicker: /* Modernizr.inputtypes.color && */ (this.deviceService.device === DEVICES.ANDROID),
            datepicker: [DEVICES.ANDROID || DEVICES.IPHONE || DEVICES.iPad].some(x => x === this.deviceService.device),
            touch: this.deviceService.isMobile() || this.deviceService.isTablet(),
            os: this.deviceService.os,
            browser: this.deviceService.browser,
        };

    }

    observer() {
        return fromEvent(window, 'resize').pipe(
            debounceTime(100),
            startWith(new CustomEvent('resize')),
        );
    }

    private convertToMediaQuery({ max, min, unit = 'px' }: MediaQuery): string {
        const mn = min ? `(min-width: ${min}${unit})` : '';
        const mx = max ? `(max-width: ${max}${unit})` : '';

        return [mn, mx].reduce((all, item) => {
            const q = all ? `${all} and ${item}` : item; // add 'and' after all if there are previous queries
            return item ? q : all;
        }, '');
    }

    private mediaDoesMatch(query: string): boolean {
        return window.matchMedia(query).matches;
    }
}
