<kui-accordion class="flex-1">

    <key-filter-section *ngFor="let filter of filters"
        [title]="filter.title"
        [expanded]="!filter.collapsed">
        <key-checkbox-filter *ngIf="filter.type === 'checkbox'"
            [config]="filter"></key-checkbox-filter>
        <key-radio-filter *ngIf="filter.type === 'radio'"
            [config]="filter"></key-radio-filter>
        <key-tree-filter *ngIf="filter.type === 'tree'"
            [config]="filter"></key-tree-filter>
    </key-filter-section>

</kui-accordion>