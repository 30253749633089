<div
    *ngFor="let option of options"
    kui-action
    class="action"
    [ngClass]="{'compact': compact}"
    (click)="load(option)">
    <kui-icon
        *ngIf="option.icon"
        class="action__icon"
        [size]="compact ? 'sm' : 'lg'"
        color="default"
        [name]="option.icon">
    </kui-icon>
    <span class="action__title mx-4">
        {{option.title | translate}}
    </span>
    <kui-icon
        class="action__indicator"
        name="chevron-right">
    </kui-icon>
</div>