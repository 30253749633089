<kui-modal size='md'
    class="details-modal"
    [title]="title"
    (onClose)="close()"
    [actions]="modalActions">

    <div class="p-4">

        <div *ngIf="errorMessage"
            class="d-flex">
            <div>
                <kui-icon class="pr-4"
                    name="ban"
                    size="lg"></kui-icon>
            </div>
            <div class="flex-1">
                <div>{{ text }}</div>
                <div class='text-danger mt-3'>{{errorMessage}}</div>
            </div>
        </div>

        <div *ngIf="!errorMessage">
            <div>{{ text }}</div>
            <div class="mt-3">
                <kui-progress [value]="progress"
                    type="success"
                    striped="true"></kui-progress>
            </div>
        </div>
    </div>
</kui-modal>