import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KuiCardModule } from '../card';
import { KuiButtonModule } from '../button';
import { KuiIconModule } from '../icon';
import { KuiTooltipModule } from '../tooltip';
import { KuiTabBarModule } from '../tab-bar';
import { KuiSidePanelComponent } from './side-panel.component';

import { KuiSidePanelItemComponent } from './side-panel-item/side-panel-item.component';
import { KuiSidePanelTitleComponent } from './side-panel-title/side-panel-title.component';
import { KuiSidePanelLabelDirective } from './side-panel-label.directive';
import { KuiSidePanelSpanDirective } from './side-panel-span.directive';
import { TranslateModule } from '@ngx-translate/core';

const SIDE_PANEL_COMPONENTS = [
    KuiSidePanelComponent,
    KuiSidePanelItemComponent,
    KuiSidePanelTitleComponent,
    KuiSidePanelLabelDirective,
    KuiSidePanelSpanDirective,
];

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        KuiCardModule,
        KuiTabBarModule,
        KuiButtonModule,
        KuiIconModule,
        KuiTooltipModule,
    ],
    exports: SIDE_PANEL_COMPONENTS,
    declarations: SIDE_PANEL_COMPONENTS,
})
export class KuiSidePanelModule { }
