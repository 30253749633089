<div class="d-flex flex-1 flex-column">

    <div class="map d-flex border-width">
        <key-trip-preview-map *ngIf="telemetry" [isMobile]="isMobile" [telemetry]="telemetry" [events]="events" [asset]="asset"></key-trip-preview-map>
        <div class="loading-overlay d-flex flex-stretch justify-content-center align-items-center" *ngIf="!telemetry">
            <kui-loader>
            </kui-loader>
        </div>
    </div>

    <div class="details d-block mt-0 pb-3 border-top">

        <key-details-overview-card [assetId]="asset.id" [icon]="getIcon()" [title]="" *ngIf="trip">
            <h4>{{ 'HISTORY.START_TO_END' | translate: { start: trimAddress(trip.addressStart), end: trimAddress(trip.addressEnd)} }} </h4>
            <div class="mt-1">
                {{ 'HISTORY.DETAILS.TRIP_COMPLETED_IN_FROM_TO' | translate: { distance: trip.distance | units: 'distance', time: trip.duration
                | formatSeconds, start: trip.addressStart, end: trip.addressEnd } }}
            </div>
        </key-details-overview-card>

    </div>
</div>