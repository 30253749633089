import { FeedResult, FeedHistoryResult, NotificationFeedItem } from './../feed.model';

import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { AuthService, MeasurementUnitsService } from 'app/services';
import { FeedService, FeedOriginType } from '../feed.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeedsGetRecentNotificationFeedGQL, NotificationResponse } from 'app/shared/graphql';

@Injectable()
export class NotificationFeedService extends FeedService<NotificationFeedItem> {

    constructor(app: AppService, public auth: AuthService, private units: MeasurementUnitsService) {
        super(app, auth);
    }

    getFeedSubscription(_clientId: string, _originType: FeedOriginType, _originId: string, limit: number): Observable<FeedResult<NotificationFeedItem>> {
        return new FeedsGetRecentNotificationFeedGQL(this.app.api.gql).subscribe({
            limit: limit,
        }).pipe(map(result => {
            const items = result.data.getRecentNotificationFeed;
            return {
                count: items.length,
                sequence: [
                    items.length && Math.min(...items.map(item => this.dateToSequence(item.date))) || 0,
                    items.length && Math.max(...items.map(item => this.dateToSequence(item.date))) || 0,
                ],
                items: items.map(item => this.mapResponse(item as any)),
            };
        }));
    }


    getFeed(_clientId: string, _originType: FeedOriginType, _originId: string, sequence: number, direction: 'forward' | 'backward', limit: number): Promise<FeedResult<NotificationFeedItem>> {
        return this.app.api.data.getNotificationFeed(sequence, direction, limit)
            .then(result => ({
                count: result.items.length,
                sequence: [
                    result.items.length && Math.min(...result.items.map(item => item.sequence)) || 0,
                    result.items.length && Math.max(...result.items.map(item => item.sequence)) || 0,
                ],
                items: result.items.map(item => this.mapResponse(item)),
            }));
    }

    protected getFeedHistory(_clientId: string, _originType: FeedOriginType, _originId: string, _start: string, _end: string, _limit: number): Promise<FeedHistoryResult<NotificationFeedItem>> {
        return Promise.reject('Notification history not available');
    }


    mapResponse(item: NotificationResponse): NotificationFeedItem {
        return {
            id: item.id,
            date: item.date,
            section: {
                title: this.units.format(item.date, 'date', 'long').format,
                date: item.date,
            },
            owner: item.owner,
            type: item.eventType,
            user: item.user,
            data: item.data,
        };
    }
}
