import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KuiAccordionComponent } from './accordion.component';

@NgModule({
  imports: [CommonModule],
  exports: [KuiAccordionComponent],
  declarations: [KuiAccordionComponent],
})
export class KuiAccordionModule { }
