import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";

import { MapMarker } from "app/shared/components/map/map.component";
import { Observable, ReplaySubject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { MapOptionService } from "../../../../map-option.service";
import { MapOptionRoutingComponent, RouteInput } from "../../../map-option-routing";
import { MapOptionSearchService } from "../../map-option-search.service";

@Component({
    selector: 'key-map-search-input',
    styleUrls: ['./map-option-search-input.component.scss'],
    templateUrl: './map-option-search-input.component.html',
})
export class MapOptionSearchInputComponent implements OnInit, OnDestroy {

    @ViewChild('searchInput') searchInput: ElementRef;

    @Input() routing: boolean;

    @HostBinding('class.show_border') @Input() border = true;

    private destroy$ = new ReplaySubject<void>(1);

    searching$: Observable<boolean>;
    showRouting$: Observable<boolean>;
    term$: Observable<string>;
    selectedSearchItem$: Observable<MapMarker>;

    constructor(
        private searchService: MapOptionSearchService,
        private mapOptionService: MapOptionService) { }

    ngOnInit(): void {
        this.term$ = this.searchService.searchTerm$;
        this.searching$ = this.searchService.searching$;
        this.selectedSearchItem$ = this.searchService.lastSelectedSearchItem$;

        // We must show results when the user enters a valid search term
        this.term$.pipe(
            takeUntil(this.destroy$),
            filter(term => !!term),
        ).subscribe(_ => {
            this.searchService.showResults();
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.searchService.clear(false);
    }

    updateSearchTerm(term: string) {
        this.searchService.setSearchTerm(term);
    }

    clearSearch() {
        this.searchService.clear();
    }

    openRouting(start: RouteInput) {
        // if we open routing from search we need to clear the search to prevent 2 markers
        this.clearSearch();
        this.mapOptionService.load({
            component: MapOptionRoutingComponent,
            data: {
                routeInputs: start && [start]
            },
            id: MapOptionRoutingComponent.ID,
            title: MapOptionRoutingComponent.TITLE,
            icon: MapOptionRoutingComponent.ICON
        })
    }

    onInputFocus(term: string) {
        if (term) {
            this.searchService.showResults();
        }
    }
}
