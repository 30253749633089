<kui-alert-panel [message]="errorMessage">
    <button kui-button (click)="initializeFeed()" *ngIf="allowRetry" class="ml-auto" color="primary">
        <kui-icon name="sync" class="pr-1"></kui-icon>
        {{ 'DIALOG.RETRY' | translate }}
    </button>
</kui-alert-panel>

<div class="feed" *ngIf="!errorMessage">

    <kui-alert type="none" class="m-1 d-block key-alert-wrapper bordered"
        *ngIf="(!sectionList || !sectionList.length) && !loading && !errorMessage">
        <kui-icon name="info-circle" size="sm"></kui-icon>
        <span>{{ 'SHARED.FEED.NO_RECENT.' + type.toUpperCase() | translate }}</span>
    </kui-alert>

    <div *ngFor="let section of sectionList">
        <ng-container *ngIf="hasValidItems(section)">
            <h5 class="feed-title">{{section.title}}</h5>
            <ng-container *ngFor="let item of section.items">
                <ng-container *ngIf="filterItemBy ? filterItemBy(item) : true">
                    <ng-container [ngSwitch]="type">
                        <key-trip-feed-item *ngSwitchCase="'trip'" class="feed-item-trip" [data]="item"
                            (primaryActionClicked)="clickPrimaryAction(item)" [actions]="getItemActions(item)"
                            [class.selected]="item.id === selectedItem?.id"></key-trip-feed-item>
                        <key-event-feed-item *ngSwitchCase="'event'"
                            [titleType]="(item && item.alerts && item.alerts.length) ? 'alert' : 'event'"
                            class="feed-item-event" [data]="item" (primaryActionClicked)="clickPrimaryAction(item)"
                            [actions]="getItemActions(item)"
                            [class.selected]="item.id === selectedItem?.id"></key-event-feed-item>
                        <key-event-feed-item *ngSwitchCase="'alert'" class="feed-item-alert" [data]="item"
                            (primaryActionClicked)="clickPrimaryAction(item)" [actions]="getItemActions(item)"
                            [class.selected]="item.id === selectedItem?.id" titleType="alert"></key-event-feed-item>
                        <key-audit-feed-item *ngSwitchCase="'audit'" class="feed-item-event" [data]="item"
                            (primaryActionClicked)="clickPrimaryAction(item)" [actions]="getItemActions(item)"
                            [class.selected]="item.id === selectedItem?.id"></key-audit-feed-item>
                        <key-log-feed-item *ngSwitchCase="'log'" class="feed-item-event" [data]="item"
                            (primaryActionClicked)="clickPrimaryAction(item)" [actions]="getItemActions(item)"
                            [class.selected]="item.id === selectedItem?.id"></key-log-feed-item>
                        <key-notification-feed-item *ngSwitchCase="'notification'" class="feed-item-event" [data]="item"
                            (primaryActionClicked)="clickPrimaryAction(item)" [actions]="getItemActions(item)"
                            [class.selected]="item.id === selectedItem?.id"></key-notification-feed-item>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>

    @if(loading) {
        <div class="feed-footer p-3">
            <kui-loader size="small"></kui-loader>
        </div>
    }
    
    @if(!loading && hasMore) {   
        <div class="feed-footer p-3">
            <a (click)="loadMore()" kui-action class="text-link">{{ 'SHARED.FEED.SHOW_MORE' | translate }}</a>
        </div>
    }

    <div class="feed-footer p-1" key-intersection-observer (intersecting)="loadMoreRequest($event)"></div>
    
</div>