import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class MapOptionLayerService {

    markerClustering = true;
    private _markerClusterSubject = new ReplaySubject<boolean>(1);

    labels = true;
    private _labelsSubject = new ReplaySubject<boolean>(1);

    showLabels$ = this._labelsSubject.asObservable();
    markerClustering$ = this._markerClusterSubject.asObservable();

    setMarkerClustering(state: boolean) {
        this._markerClusterSubject.next(state);
    }

    toggleMarkerClustering() {
        this.markerClustering = !this.markerClustering;
        this.setMarkerClustering(this.markerClustering);
    }

    setLabels(state: boolean) {
        this._labelsSubject.next(state);
    }

    toggleLabels() {
        this.labels = !this.labels;
        this.setLabels(this.labels);
    }

}
