import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { SelectionItem, EntityResult, EntityListOptions } from '../selection.model';

@Injectable()
export abstract class SelectionService<T extends SelectionItem> {
    private _options: EntityListOptions;

    id: string;

    get options(): EntityListOptions { return this._options; }

    constructor(public app: AppService) {
        this._options = this.getDefaultEntityOptions();
    }

    updateEntityOption<TValue>(key: string, value: TValue) {
        this._options = { ...this._options, [key]: value };
    }

    /**
     * a wrapper for the api call to a specific entity
     */
    abstract getEntityList(id: string, options: EntityListOptions): Promise<EntityResult<T>>;

    /**
     * looks up an entity from the cached list or from the api if not found
     */
    abstract getEntity(id: string): Promise<T>;


    /**
     * returns options object that the api calls will use as params
     */
    abstract getDefaultEntityOptions(): EntityListOptions;

    /**
     * returns the entity specific filter string
     */
    abstract getFilterRqlString(value: string): string;

}
