import { AnalyticsCellSet } from '../analytics.model';
import { AnalyticsOutputSettings } from '@key-telematics/fleet-api-client';

// TODO: Both generateDummySettings & generateDummyCellsetData functions will be replaced
// by getting the data from the reporting server soon as a route becomes available
export function generateDummySettings(): AnalyticsOutputSettings {
    /* tslint:disable */
    return {
        "name": "Asset Utilization Analytics",
        "description": "Analytics summary of asset utilization.",
        "dateRange": "07.11.2018:13.11.2018d",
        "row": {
            "dim": "time",
            "level": 3,
            "filter": [],
            "levels": {
                "name": "Time",
                "headings": [],
            },
        },
        "group": {
            "dim": "asset",
            "level": 1,
            "filter": [],
            "levels": {
                "name": "Asset",
                "headings": [],
            },
        },
        "flipped": false,
        "measures": [
            { key: "% Active", value: "% Active" },
            { key: "% Parked", value: "% Parked" },
            { key: "% Driving", value: "% Driving" },
            { key: "% Idling", value: "% Idling" },
        ],
        "table": {
            "measures": [
                { key: "% Parked", value: "% Parked" },
                { key: "% Idling", value: "% Idling" },
            ],
            "layout": "data"
        },
        "sort": {
            "type": "alphabetic",
            "direction": "asc",
            "measureKey": "Distance ({distance})"
        },
        "graph": {
            "yAxis": {
                "left": {},
                "right": {}
            },
            "xAxis": null,
            "stacked": false,
            "label": {
                "visible": true,
                "overlay": true,
                "placement": "asLegend",
                "position": "right"
            },
            "series": {
                "default": {
                    "visible": true,
                    "type": "bar",
                    "orientation": "vertical",
                    "fill": true,
                    "color": null,
                    "yAxis": "left",
                    "average": false
                }
            }
        }
    }
    /* tslint:enable */
}

export function generateDummyCellsetData(): AnalyticsCellSet {
    /* tslint:disable */
    return {
        "cellset": {
            "rowLevels": 4,
            "cellset": [
                [
                    {
                        "value": "null",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "type": "data"
                        }
                    },
                    {
                        "value": "null",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "type": "data"
                        }
                    },
                    {
                        "value": "null",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "type": "data"
                        }
                    },
                    {
                        "value": "null",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "type": "data"
                        }
                    },
                    {
                        "value": "All",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "uniquename": "All",
                            "dimension": "asset",
                            "level": "All",
                            "type": "data"
                        }
                    },
                    {
                        "value": "All",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "uniquename": "All",
                            "dimension": "asset",
                            "level": "All",
                            "type": "data"
                        }
                    },
                    {
                        "value": "",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "uniquename": "All",
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "uniquename": "All",
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "uniquename": "All",
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    },
                    {
                        "value": "",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "uniquename": "All",
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    }
                ],
                [
                    {
                        "value": "null",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "type": "data"
                        }
                    },
                    {
                        "value": "null",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "type": "data"
                        }
                    },
                    {
                        "value": "null",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "type": "data"
                        }
                    },
                    {
                        "value": "null",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "type": "data"
                        }
                    },
                    {
                        "value": "Vehicle",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "uniquename": "All|Vehicle",
                            "dimension": "asset",
                            "level": "All|Vehicle",
                            "type": "data"
                        }
                    },
                    {
                        "value": "Vehicle",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "uniquename": "All|Vehicle",
                            "dimension": "asset",
                            "level": "All|Vehicle",
                            "type": "data"
                        }
                    },
                    {
                        "value": "TOTAL",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "uniquename": "All",
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "TOTAL",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "uniquename": "All",
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "AVERAGE",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "uniquename": "All",
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    },
                    {
                        "value": "AVERAGE",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "uniquename": "All",
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    }
                ],
                [
                    {
                        "value": "All",
                        "type": "ROW_HEADER_HEADER",
                        "properties": {
                            "level": "All",
                            "type": "data"
                        }
                    },
                    {
                        "value": "Year",
                        "type": "ROW_HEADER_HEADER",
                        "properties": {
                            "level": "Year",
                            "type": "data"
                        }
                    },
                    {
                        "value": "Month",
                        "type": "ROW_HEADER_HEADER",
                        "properties": {
                            "level": "Month",
                            "type": "data"
                        }
                    },
                    {
                        "value": "Day",
                        "type": "ROW_HEADER_HEADER",
                        "properties": {
                            "level": "Day",
                            "type": "data"
                        }
                    },
                    {
                        "value": "% Parked",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "uniquename": "% Parked",
                            "dimension": "Measures",
                            "level": "[Measures].[MeasuresLevel]",
                            "format": {
                                "align": "left",
                                "width": 100
                            },
                            "type": "data"
                        }
                    },
                    {
                        "value": "% Idling",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "uniquename": "% Idling",
                            "dimension": "Measures",
                            "level": "[Measures].[MeasuresLevel]",
                            "format": {
                                "align": "left",
                                "width": 100
                            },
                            "type": "data"
                        }
                    },
                    {
                        "value": "% Parked",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "uniquename": "% Parked",
                            "dimension": "Measures",
                            "level": "[Measures].[MeasuresLevel]",
                            "format": {
                                "align": "left",
                                "width": 100
                            },
                            "type": "total"
                        }
                    },
                    {
                        "value": "% Idling",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "uniquename": "% Idling",
                            "dimension": "Measures",
                            "level": "[Measures].[MeasuresLevel]",
                            "format": {
                                "align": "left",
                                "width": 100
                            },
                            "type": "total"
                        }
                    },
                    {
                        "value": "% Parked",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "uniquename": "% Parked",
                            "dimension": "Measures",
                            "level": "[Measures].[MeasuresLevel]",
                            "format": {
                                "align": "left",
                                "width": 100
                            },
                            "type": "average"
                        }
                    },
                    {
                        "value": "% Idling",
                        "type": "COLUMN_HEADER",
                        "properties": {
                            "uniquename": "% Idling",
                            "dimension": "Measures",
                            "level": "[Measures].[MeasuresLevel]",
                            "format": {
                                "align": "left",
                                "width": 100
                            },
                            "type": "average"
                        }
                    }
                ],
                [
                    {
                        "value": "All",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All",
                            "dimension": "time",
                            "level": "All",
                            "type": "data"
                        }
                    },
                    {
                        "value": "2018",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All|2018",
                            "dimension": "time",
                            "level": "All|2018",
                            "type": "data"
                        }
                    },
                    {
                        "value": "Nov",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All|2018|Nov",
                            "dimension": "time",
                            "level": "All|2018|Nov",
                            "type": "data"
                        }
                    },
                    {
                        "value": "07",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All|2018|Nov|07",
                            "dimension": "time",
                            "level": "All|2018|Nov|07",
                            "type": "data"
                        }
                    },
                    {
                        "value": "9.61",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": 9.611614129219763,
                            "format": {
                                "align": "left",
                                "width": 100
                            },
                            "type": "data"
                        }
                    },
                    {
                        "value": "90.35",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": 90.34887100380058,
                            "format": {
                                "align": "left",
                                "width": 100
                            },
                            "type": "data"
                        }
                    },
                    {
                        "value": "-",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "-",
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "-",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "-",
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "9.61",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "9.61",
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    },
                    {
                        "value": "90.35",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "90.35",
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    }
                ],
                [
                    {
                        "value": "All",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All",
                            "dimension": "time",
                            "level": "All",
                            "type": "data"
                        }
                    },
                    {
                        "value": "2018",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All|2018",
                            "dimension": "time",
                            "level": "All|2018",
                            "type": "data"
                        }
                    },
                    {
                        "value": "Nov",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All|2018|Nov",
                            "dimension": "time",
                            "level": "All|2018|Nov",
                            "type": "data"
                        }
                    },
                    {
                        "value": "08",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All|2018|Nov|08",
                            "dimension": "time",
                            "level": "All|2018|Nov|08",
                            "type": "data"
                        }
                    },
                    {
                        "value": "23.84",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": 23.83738425925926,
                            "format": {
                                "align": "left",
                                "width": 100
                            },
                            "type": "data"
                        }
                    },
                    {
                        "value": "76.12",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": 76.12245370370371,
                            "format": {
                                "align": "left",
                                "width": 100
                            },
                            "type": "data"
                        }
                    },
                    {
                        "value": "-",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "-",
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "-",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "-",
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "23.84",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "23.84",
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    },
                    {
                        "value": "76.12",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "76.12",
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    }
                ],
                [
                    {
                        "value": "All",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All",
                            "dimension": "time",
                            "level": "All",
                            "type": "data"
                        }
                    },
                    {
                        "value": "2018",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All|2018",
                            "dimension": "time",
                            "level": "All|2018",
                            "type": "data"
                        }
                    },
                    {
                        "value": "Nov",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All|2018|Nov",
                            "dimension": "time",
                            "level": "All|2018|Nov",
                            "type": "data"
                        }
                    },
                    {
                        "value": "09",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All|2018|Nov|09",
                            "dimension": "time",
                            "level": "All|2018|Nov|09",
                            "type": "data"
                        }
                    },
                    {
                        "value": "45.07",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": 45.06933281389095,
                            "format": {
                                "align": "left",
                                "width": 100
                            },
                            "type": "data"
                        }
                    },
                    {
                        "value": "48.21",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": 48.21226260239417,
                            "format": {
                                "align": "left",
                                "width": 100
                            },
                            "type": "data"
                        }
                    },
                    {
                        "value": "-",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "-",
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "-",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "-",
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "45.07",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "45.07",
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    },
                    {
                        "value": "48.21",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "48.21",
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    }
                ],
                [
                    {
                        "value": "All",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All",
                            "dimension": "time",
                            "level": "All",
                            "type": "data"
                        }
                    },
                    {
                        "value": "2018",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All|2018",
                            "dimension": "time",
                            "level": "All|2018",
                            "type": "data"
                        }
                    },
                    {
                        "value": "Nov",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All|2018|Nov",
                            "dimension": "time",
                            "level": "All|2018|Nov",
                            "type": "data"
                        }
                    },
                    {
                        "value": "10",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All|2018|Nov|10",
                            "dimension": "time",
                            "level": "All|2018|Nov|10",
                            "type": "data"
                        }
                    },
                    {
                        "value": "35.20",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": 35.196975008888984,
                            "format": {
                                "align": "left",
                                "width": 100
                            },
                            "type": "data"
                        }
                    },
                    {
                        "value": "58.89",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": 58.888118095493844,
                            "format": {
                                "align": "left",
                                "width": 100
                            },
                            "type": "data"
                        }
                    },
                    {
                        "value": "-",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "-",
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "-",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "-",
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "35.20",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "35.20",
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    },
                    {
                        "value": "58.89",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "58.89",
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    }
                ],
                [
                    {
                        "value": "All",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All",
                            "dimension": "time",
                            "level": "All",
                            "type": "data"
                        }
                    },
                    {
                        "value": "2018",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All|2018",
                            "dimension": "time",
                            "level": "All|2018",
                            "type": "data"
                        }
                    },
                    {
                        "value": "Nov",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All|2018|Nov",
                            "dimension": "time",
                            "level": "All|2018|Nov",
                            "type": "data"
                        }
                    },
                    {
                        "value": "11",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All|2018|Nov|11",
                            "dimension": "time",
                            "level": "All|2018|Nov|11",
                            "type": "data"
                        }
                    },
                    {
                        "value": "41.87",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": 41.86854288499025,
                            "format": {
                                "align": "left",
                                "width": 100
                            },
                            "type": "data"
                        }
                    },
                    {
                        "value": "53.39",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": 53.38974171539961,
                            "format": {
                                "align": "left",
                                "width": 100
                            },
                            "type": "data"
                        }
                    },
                    {
                        "value": "-",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "-",
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "-",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "-",
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "41.87",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "41.87",
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    },
                    {
                        "value": "53.39",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "53.39",
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    }
                ],
                [
                    {
                        "value": "All",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All",
                            "dimension": "time",
                            "level": "All",
                            "type": "data"
                        }
                    },
                    {
                        "value": "2018",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All|2018",
                            "dimension": "time",
                            "level": "All|2018",
                            "type": "data"
                        }
                    },
                    {
                        "value": "Nov",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All|2018|Nov",
                            "dimension": "time",
                            "level": "All|2018|Nov",
                            "type": "data"
                        }
                    },
                    {
                        "value": "12",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All|2018|Nov|12",
                            "dimension": "time",
                            "level": "All|2018|Nov|12",
                            "type": "data"
                        }
                    },
                    {
                        "value": "30.07",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": 30.068457082675092,
                            "format": {
                                "align": "left",
                                "width": 100
                            },
                            "type": "data"
                        }
                    },
                    {
                        "value": "61.67",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": 61.66594260136914,
                            "format": {
                                "align": "left",
                                "width": 100
                            },
                            "type": "data"
                        }
                    },
                    {
                        "value": "-",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "-",
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "-",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "-",
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "30.07",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "30.07",
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    },
                    {
                        "value": "61.67",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "61.67",
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    }
                ],
                [
                    {
                        "value": "All",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All",
                            "dimension": "time",
                            "level": "All",
                            "type": "data"
                        }
                    },
                    {
                        "value": "2018",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All|2018",
                            "dimension": "time",
                            "level": "All|2018",
                            "type": "data"
                        }
                    },
                    {
                        "value": "Nov",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All|2018|Nov",
                            "dimension": "time",
                            "level": "All|2018|Nov",
                            "type": "data"
                        }
                    },
                    {
                        "value": "13",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "All|2018|Nov|13",
                            "dimension": "time",
                            "level": "All|2018|Nov|13",
                            "type": "data"
                        }
                    },
                    {
                        "value": "38.96",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": 38.96378460371635,
                            "format": {
                                "align": "left",
                                "width": 100
                            },
                            "type": "data"
                        }
                    },
                    {
                        "value": "53.05",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": 53.052806219188476,
                            "format": {
                                "align": "left",
                                "width": 100
                            },
                            "type": "data"
                        }
                    },
                    {
                        "value": "-",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "-",
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "-",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "-",
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "38.96",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "38.96",
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    },
                    {
                        "value": "53.05",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": "53.05",
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    }
                ],
                [
                    {
                        "value": "",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "",
                            "dimension": "",
                            "level": "",
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "",
                            "dimension": "",
                            "level": "",
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "",
                            "dimension": "",
                            "level": "",
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "TOTAL",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "TOTAL",
                            "dimension": "",
                            "level": "",
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "-",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": null,
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "-",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": null,
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "-",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": null,
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "-",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": null,
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "-",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": null,
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    },
                    {
                        "value": "-",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": null,
                            "doNotShowOnChart": true,
                            "type": "total"
                        }
                    }
                ],
                [
                    {
                        "value": "",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "",
                            "dimension": "",
                            "level": "",
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    },
                    {
                        "value": "",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "",
                            "dimension": "",
                            "level": "",
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    },
                    {
                        "value": "",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "",
                            "dimension": "",
                            "level": "",
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    },
                    {
                        "value": "AVERAGE",
                        "type": "ROW_HEADER",
                        "properties": {
                            "uniquename": "AVERAGE",
                            "dimension": "",
                            "level": "",
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    },
                    {
                        "value": "32.09",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": 32.08801296894868,
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    },
                    {
                        "value": "63.10",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": 63.09717084876417,
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    },
                    {
                        "value": "-",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": null,
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    },
                    {
                        "value": "-",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": null,
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    },
                    {
                        "value": "32.09",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": 32.08801296894866,
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    },
                    {
                        "value": "63.10",
                        "type": "DATA_CELL",
                        "properties": {
                            "raw": 63.09717084876422,
                            "doNotShowOnChart": true,
                            "type": "average"
                        }
                    }
                ]
            ],
            "generating": false,
        }
    }
    /* tslint:enable */
}
