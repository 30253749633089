import { Component, Output, EventEmitter, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilderDefinition, FormBuilderFieldChangeEvent } from 'app/shared/components/form-builder';
import { ChartLabel } from '@key-telematics/fleet-api-client';

@Component({
    selector: 'key-analytics-settings-legend',
    templateUrl: './legend.component.html',
})
export class AnalyticsSettingsLegendComponent implements OnInit, OnChanges {
    form: FormBuilderDefinition;
    values: { [key: string]: any };

    @Input() options: ChartLabel;
    @Input() title: string;
    @Output() onChange: EventEmitter<{ [key: string]: any; }> = new EventEmitter();

    constructor(
        private i18n: TranslateService
    ) {
    }

    ngOnInit() {
        this.form = {
            'groups': [{
                name: this.title.toUpperCase(),
                fields: [{
                    type: 'toggle', id: 'visible', title: 'VISIBLE',
                }, {
                    type: 'combo', id: 'position', title: 'POSITION', values: ['left', 'right', 'bottom', 'top'].map((position) => ({
                        key: position, value: this.i18n.instant('ANALYTICS.SETTINGS.' + position.toUpperCase()),
                    })),
                }, {
                    type: 'toggle', id: 'overlay', title: 'FLOAT',
                }],
            }],
        };
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.options && this.options) {
            this.values = { ...this.options };
        }
    }

    changeFormSetting(change: FormBuilderFieldChangeEvent) {
        if (change.dirty) {
            const { id } = change.field;
            this.onChange.emit({
                [id]: change.values[id],
            });
        }
    }
}
