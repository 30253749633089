import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KuiModule } from 'app/key-ui';

import { PipeModule } from 'app/shared/pipes/pipe.module';
import { GetKeyPipe } from 'app/shared/pipes/getkey.pipe';
import { KeyFormBuilderModule } from '../form-builder/form-builder.module';
import { ReportListItemComponent } from './list-item/report-item.component';
import { KeyListingModule } from '../listing/listing.module';
import { KeyModalModule } from '../modal';
import { ReportingBuilderColumnsComponent } from './columns/columns.component';
import { DragulaModule } from 'ng2-dragula';
import { FormsModule } from '@angular/forms';
import { ReportParameterFormComponent } from './parameter-form/report-parameter-form.component';
import { ReportParameterModalComponent } from './parameter-modal/report-parameter-modal.component';
import { ReportSelectModalComponent } from './select-modal/report-select-modal.component';

const COMPONENTS = [
    ReportListItemComponent,
    ReportingBuilderColumnsComponent,
    ReportParameterFormComponent,
    ReportParameterModalComponent,
    ReportSelectModalComponent,
];

@NgModule({
    imports: [
        CommonModule,
        KuiModule,
        TranslateModule.forChild(),
        PipeModule.forRoot(),
        KeyFormBuilderModule,
        KeyListingModule,
        KeyModalModule,
        DragulaModule.forRoot(),
        FormsModule,
    ],
    exports: COMPONENTS,
    declarations: COMPONENTS,
    providers: [GetKeyPipe]
})
export class KeySharedReportsModule { }
