import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KuiModalComponent } from './modal.component';
import { KuiModalHeaderComponent } from './modal-header.component';
import { KuiModalBodyComponent } from './modal-body.component';
import { KuiModalFooterComponent } from './modal-footer.component';
import { KuiIconModule } from './../icon';
import { KuiButtonModule } from './../button';
import { KuiModalService } from 'app/key-ui/modal/modal.service';
import { KuiModalRefComponent } from 'app/key-ui/modal/modal-ref.component';
import { KuiLoaderModule } from '../loader/loader.module';

const KUI_MODAL_MODULES = [
    KuiModalComponent,
    KuiModalBodyComponent,
    KuiModalFooterComponent,
    KuiModalHeaderComponent,
    KuiModalRefComponent,
];

@NgModule({
    imports: [
        CommonModule,
        KuiIconModule,
        KuiButtonModule,
        KuiLoaderModule,
    ],
    exports: [KUI_MODAL_MODULES],
    declarations: [KUI_MODAL_MODULES],
    providers: [KuiModalService],
})
export class KuiModalModule { }
