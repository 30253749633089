import {
    Component,
    Input,
    OnInit,
    ElementRef,
    Renderer2,
    HostBinding,
} from '@angular/core';
import { KuiCardService } from './card.service';

export interface IKuiCardJumbotronIcon {
    set: string;
    name: string;
}

@Component({
    selector: 'kui-card-jumbotron',
    templateUrl: './card-jumbotron.component.html',
})
export class KuiCardJumbotron {
    @Input() icon: IKuiCardJumbotronIcon;
}

@Component({
    selector: 'kui-card-actions',
    templateUrl: './card-actions.component.html',
})
export class KuiCardActions { }

@Component({
    selector: 'kui-card-header',
    templateUrl: './card-inner.component.html',
})
export class KuiCardHeader implements OnInit {
    private classNames: string[] = ['content-card-header', 'card-header', 'd-flex', 'align-items-center'];
    @Input() align: string;
    @HostBinding('class.active') @Input() active: boolean;

    constructor(
        private service: KuiCardService,
        private renderer: Renderer2,
        private el: ElementRef) { }

    ngOnInit() {
        this.classNames.push(this.service.getAligmentClass(this.align));
        this.classNames.forEach((classname => {
            this.renderer.addClass(this.el.nativeElement, classname);
        }));
    }
}

@Component({
    selector: 'kui-card-body',
    templateUrl: './card-inner.component.html',
})
export class KuiCardBody implements OnInit {
    private classNames: string[] = ['content-card-block', 'card-block'];
    @Input() align: string;

    constructor(
        private service: KuiCardService,
        private renderer: Renderer2,
        private el: ElementRef) { }

    ngOnInit() {
        this.classNames.push(this.service.getAligmentClass(this.align));
        this.classNames.forEach((classname => {
            this.renderer.addClass(this.el.nativeElement, classname);
        }));
    }
}

@Component({
    selector: 'kui-card-footer',
    templateUrl: './card-inner.component.html',
})
export class KuiCardFooter implements OnInit {
    private classNames: string[] = ['content-card-footer', 'card-footer'];
    @Input() align: string;

    constructor(
        private service: KuiCardService,
        private renderer: Renderer2,
        private el: ElementRef) { }

    ngOnInit() {
        this.classNames.push(this.service.getAligmentClass(this.align));
        this.classNames.forEach((classname => {
            this.renderer.addClass(this.el.nativeElement, classname);
        }));
    }
}

@Component({
    selector: 'kui-card',
    templateUrl: './card.component.html',
})
export class KuiCard { }
