import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { MapMarker, MapRoute } from '../../../map.component';
import { RouteInput } from './components/routing/map-toolbar-routing.component';


@Injectable()
export class MapOptionRoutingService {
    
    private inputs: RouteInput[] = [];

    private _routeInputSubject = new ReplaySubject<RouteInput[]>(1);
    private _markerAddSubject = new ReplaySubject<MapMarker>(1);
    private _markersRemoveSubject = new ReplaySubject<MapMarker[]>(1);
    private _updateRoutesSubject = new ReplaySubject<MapRoute[]>(1);
    private _clearRoutesSubject = new ReplaySubject<MapRoute[]>(1);

    routeInput$ = this._routeInputSubject.asObservable();
    markerAdd$ = this._markerAddSubject.asObservable();
    markersRemove$ = this._markersRemoveSubject.asObservable();
    updateRoutes$ = this._updateRoutesSubject.asObservable();
    clearRoutes$ = this._clearRoutesSubject.asObservable();

    constructor() { }

    setRouteInput(routeInput: RouteInput[]) {
        this.inputs = routeInput;
        this._routeInputSubject.next(this.inputs);
    }

    updateRouteInput(index: number, input: RouteInput) {
        this.inputs[index] = input;
    }

    getRouteInputs() {
        return this.inputs;
    }

    addMarker(marker: MapMarker) {
        this._markerAddSubject.next(marker);
    }

    clearMultipleMarkers(markers: MapMarker[]) {
        this._markersRemoveSubject.next(markers);
    }

    updateRoutes(routes: MapRoute[]) {
        this._updateRoutesSubject.next(routes);
    }

    clearRoutes(routes: MapRoute[]) {
        this._clearRoutesSubject.next(routes);
    }

}
