<div kui-action
    class="d-flex flex-1"
    (click)="onClicked()">

    <key-feed-entry-info [icons]="icons">
        <span id="info-title">
            <span [title]="data | getKey: 'date' | date: 'yyyy-MM-dd HH:mm:ss'">{{ data | getKey: 'date' | units: { unit: 'time', variant: 'short' } }}</span>
        </span>
        <span id="info-small-text"
            class="small muted">{{timeAgo}}</span>
    </key-feed-entry-info>

    <div class="feed-entry-details">
        <div class="d-flex flex-wrap">

            <kui-icon [name]="icon" class="icon mr-3"
                size="xl"></kui-icon>

            <div class='entry-text flex-1 mr-3'>
                <div class="entry-title"
                    [innerHTML]="title"></div>
                <div class="entry-description muted-less">
                    {{ description }}
                </div>
            </div>
        </div>
    </div>
</div>

<key-feed-entry-actions *ngIf="actions && actions.length"
    [actions]="actions"></key-feed-entry-actions>