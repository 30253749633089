import { NgModule } from '@angular/core';
import { KuiTagInputComponent } from './tag-input.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { KuiIconModule } from '../icon/icon.module';
import { KuiBadgeModule } from '../badge/badge.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { KuiLoaderModule } from 'app/key-ui/loader/loader.module';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AutoCompleteModule,
        KuiIconModule,
        KuiBadgeModule,
        KuiLoaderModule,
        TranslateModule,
    ],
    declarations: [KuiTagInputComponent],
    exports: [KuiTagInputComponent],
})
export class KuiTagInputModule { }
