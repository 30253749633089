<kui-modal #modal
    size="md"
    [title]="data.title"
    [actions]="modalActions"
    (onClose)="detachModal()">
    <div class="content d-flex"
        style="min-height: 350px">

        <div class="flex-1">
            <key-report-parameter-form [clientId]="data.clientId"
                [report]="data.sourceReport"
                [reportDefinition]="data.reportDefinition"
                [parameters]="data.parameters"
                [isSheduledReport]="data.isSheduledReport"
                [showHiddenFields]="data.showHiddenFields"> </key-report-parameter-form>

        </div>

    </div>
</kui-modal>