import { Pipe, PipeTransform } from '@angular/core';

import { MeasurementUnitsService } from 'app/services';

/*
* format value of specified units
*
* usage:
*    {{ 10.2 | unit: 'distance' }}
*    {{ 100 | unit: 'speed' }}
*    {{ 50 | unit: 'altitude' }}
*    {{ 32 | unit: 'temperature' }}
*/
@Pipe({
    name: 'units',
})
export class UnitsPipe implements PipeTransform {

    constructor(public units?: MeasurementUnitsService) { }

    // date and time units accepts long and short variants respectively and can be accessed by giving the args an object.
    // eg: {{ DATE: units: { unit: 'date', variant: 'long' } }} | {{ DATE: units: { unit: 'time', variant: 'short' } }}
    transform(value: any, args?: string | { unit: string, variant: string }): string {
        let unit = value;

        if (args) {
            unit = typeof args === 'string' ? this.units.format(value, args) : this.units.format(value, args.unit, args.variant);
        }

        return !!unit.format ? unit.format : value;
    }

}
