<kui-modal size='lg'
    class="details-modal"
    [class.multiple]="videos.length + photos.length > 1"
    [title]="title"
    (onClose)="detachModal()">

    <div class="videos d-flex flex-wrap">
        <key-video *ngFor="let video of videos; let i = index"
            class="video"
            [asset]="asset"
            [video]="video"
            [muted]="i !== 0 || isIOS"
            (onLoaded)="onVideoLoaded($event)"
            (onProgress)="onVideoProgress($event)"
            (onStatusChanged)="onVideoStatusChanged($event)">
        </key-video>
    </div>

    <div class="photos d-flex flex-wrap">
        <div class="photo"
            *ngFor="let photo of photos; let i = index">
            <img class="w-100"
                [src]="photo.url" />
            <a kui-button
                class="download"
                [href]="photo.url">
                <kui-icon name="download"></kui-icon>
                <span class="d-sm-inline-block ml-2">{{ 'DIALOG.DOWNLOAD' | translate }}</span>
            </a>
        </div>
    </div>

    <kui-progress *ngIf="videos.length > 0"
        class='inline-progress'
        [value]="progress"
        type="primary"
        canSeek="true"
        (onSeek)="onSeek($event)"></kui-progress>
    <div class="modal-footer d-flex">

        <div class="media-playback d-flex flex-1"
            *ngIf="showPlaybackControls">
            <div class="playback-bar overflow-hidden"
                *ngIf="videos.length > 0">
                <div class="playback-bar__item">
                    <kui-icon kui-action
                        name="step-backward"
                        (click)="onPlaybackAction('start')"
                        size="sm"></kui-icon>
                </div>
                <div class="playback-bar__item">
                    <kui-icon kui-action
                        name="backward"
                        (click)="onPlaybackAction('backward')"
                        size="sm"></kui-icon>
                </div>
                <div kui-action
                    class="playback-bar__item">
                    <kui-icon name="play"
                        *ngIf="!isPlaying"
                        (click)="onPlaybackAction('play')"
                        size="sm"></kui-icon>
                    <kui-icon name="pause"
                        *ngIf="isPlaying"
                        (click)="onPlaybackAction('pause')"
                        size="sm"></kui-icon>
                </div>
                <div class="playback-bar__item">
                    <kui-icon kui-action
                        name="forward"
                        (click)="onPlaybackAction('forward')"
                        size="sm"></kui-icon>
                </div>
                <div class="playback-bar__item">
                    <kui-icon kui-action
                        name="step-forward"
                        (click)="onPlaybackAction('end')"
                        size="sm"></kui-icon>
                </div>

            </div>
            <div class="time-bar d-flex flex-1"
                *ngIf="videos.length > 0">
                <span class="current-time">{{ currentTime }}</span>
                <kui-progress class='flex-1 pl-2 pr-2'
                    [value]="progress"
                    type="primary"
                    canSeek="true"
                    (onSeek)="onSeek($event)"></kui-progress>
                <span class="duration-time">{{ durationTime }}</span>
            </div>

        </div>
        <div>
            <button kui-button
                [color]="'primary'"
                (click)="detachModal()">
                <kui-icon name="times"></kui-icon>
                <span class="d-sm-inline-block ml-2"> {{ 'DIALOG.CLOSE' | translate }}</span>
            </button>
        </div>
    </div>

</kui-modal>