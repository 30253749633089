import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KuiModule } from 'app/key-ui';

import { PipeModule } from 'app/shared/pipes/pipe.module';
import { GetKeyPipe } from 'app/shared/pipes/getkey.pipe';
import { KeyFormBuilderModule } from '../form-builder/form-builder.module';
import { KeyListingModule } from '../listing/listing.module';
import { KeyModalModule } from '../modal';
import { DragulaModule } from 'ng2-dragula';
import { FormsModule } from '@angular/forms';
import { KeySharedReportsModule } from '../reports';

const COMPONENTS = [
];

@NgModule({
    imports: [
        CommonModule,
        KuiModule,
        TranslateModule.forChild(),
        PipeModule.forRoot(),
        KeyFormBuilderModule,
        KeyListingModule,
        KeyModalModule,
        DragulaModule.forRoot(),
        FormsModule,
        KeySharedReportsModule,
    ],
    exports: COMPONENTS,
    declarations: COMPONENTS,
    providers: [GetKeyPipe]
})
export class KeySharedMapSearchModule { }
