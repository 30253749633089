<kui-modal [loading]="loading"
    [title]="'DASHBOARDS.REPORT_VIEWER' | translate"
    [actions]="actions"
    size="fill"
    (onClose)="detachModal()">

    <key-analytics class="d-flex flex-1 w-100"
        *ngIf="report"
        [report]="report"
        [isMobile]="isMobile"
        [visibleSettings]="visibleSettings"
        [showSettings]="showAnalyticsSettings"
        (onGraphShared)="handleSharedWidget($event)"
        (onSettingsVisiblityToggle)="isMobile && showAnalyticsSettings = $event"></key-analytics>
</kui-modal>