import { KuiModalRefComponent } from 'app/key-ui/modal/modal-ref.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { IKuiModalAction } from 'app/key-ui';
import { FormBuilderDefinition, KeyFormBuilderComponent, FormBuilderFieldChangeEvent, FormBuilderField } from 'app/shared/components/form-builder';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsSettings, AnalyticsCellValue } from '../../analytics.model';
import { AnalyticsChartType } from '../../chart/chart.component';
import { AppService } from 'app/app.service';
import { DashboardWidget, DashboardResponse, DashboardTemplateResponse, DashboardTemplateListItem, DashboardListItem, IdNameType } from '@key-telematics/fleet-api-client';
import { NotificationService, DashboardsService, ErrorLoggerService, LocalStore, AssetGroupingService, AssetGroupingItem } from 'app/services';
import { AnalyticsService } from '../../services/analytics.service';
import * as uuid from 'uuid';
import { Router } from '@angular/router';
import { getAnalyticsTimePresets } from '../../settings/utils';

export const CreateWidgetLocalStore = new LocalStore('create-widget-component');

export interface CreateWidgetState {
    dashboards: string[];
    templates: string[];
    shareType: string;
}

export interface AnalyticsCreateWidgetModalOptions {
    name: string;
    description: string;
    definitionId: string;
    type: AnalyticsChartType;
    cellset: AnalyticsCellValue[][];
    settings: AnalyticsSettings;
    dataSourceId: string;
    placement?: { colSpan: number, rowSpan: number };
    excludedDashboardId?: string;
}

@Component({
    templateUrl: 'create-widget.component.html',
    providers: [{ provide: LocalStore, useValue: CreateWidgetLocalStore }],
})
export class AnalyticsCreateWidgetModalComponent extends KuiModalRefComponent<AnalyticsCreateWidgetModalOptions> implements OnInit {

    @ViewChild(KeyFormBuilderComponent) formBuilder: KeyFormBuilderComponent;

    widgetType: AnalyticsChartType;
    dashboards: { id: string, name: string, owner: IdNameType }[];
    form: FormBuilderDefinition;
    formValues: {
        name: string;
        description: string;
        widgetType: AnalyticsChartType;
        shareType: string;
        dashboards: string[];
        templates: string[];
        dateRange: string;
    };
    settings: AnalyticsSettings;
    cellset: AnalyticsCellValue[][];
    state: CreateWidgetState;
    modalActions: IKuiModalAction[];
    loading = false;
    success: boolean;
    errorMessage: string;
    noResult = false;
    canSaveToTempate = true;

    constructor(
        private i18n: TranslateService,
        private app: AppService,
        private notify: NotificationService,
        private dashboardsService: DashboardsService,
        private analytics: AnalyticsService,
        private errorLogger: ErrorLoggerService,
        private store: LocalStore,
        private router: Router,
        private grouping: AssetGroupingService
    ) {
        super();

        this.state = this.store.watchState<CreateWidgetState>('state', {
            dashboards: [],
            templates: [],
            shareType: 'dashboard',
        });
    }

    ngOnInit() {
        this.widgetType = this.data.type;
        this.settings = this.data.settings;
        this.cellset = this.data.cellset;
        this.canSaveToTempate = !this.settings.doNotTemplate && this.app.user.owner.type !== 'client';
        this.loading = true;
        Promise.all([
            this.dashboardsService.getDashboards(this.app.client.id),
            this.canSaveToTempate ? this.dashboardsService.getDashboardTemplates(this.app.client.id) : [],
            this.grouping.getCostCentres(this.app.client.id),
            this.analytics.getCompletedAnalyticsReport(this.data.dataSourceId),
        ]).then(([dashboards, templates, costCentres, report]) => {
            const reportCostCentre = costCentres.find(centre => centre.id === report.config.parameters.filters?.accessGroup);
            const widgetCostCentre = reportCostCentre || costCentres.find(centre => centre.id === this.app.user.costCentre?.id) || costCentres.find(centre => centre.parent === 'root');
            
            if ((this.state.shareType && this.state.shareType === 'dashboard') || this.app.user.owner.type === 'client') {
                dashboards = this.filterDashboardsByCostCentre(dashboards, costCentres, widgetCostCentre);
            }

            this.loading = false;
            this.form = {
                groups: [{
                    name: null,
                    fields: [
                        {
                            type: 'radio', id: 'widgetType', title: 'TYPE', values: [
                                { key: 'chart', value: this.i18n.instant('ANALYTICS.CREATE_WIDGET.CHART') },
                                { key: 'pie', value: this.i18n.instant('ANALYTICS.CREATE_WIDGET.PIE') },
                                { key: 'stat', value: this.i18n.instant('ANALYTICS.CREATE_WIDGET.STAT') },
                                //         { key: 'grid', value: this.i18n.instant('ANALYTICS.CREATE_WIDGET.GRID') },
                                //         { key: 'text', value: this.i18n.instant('ANALYTICS.CREATE_WIDGET.TEXT') },
                            ],
                        },
                        this.getTagsField(dashboards, templates),
                        { id: 'name', type: 'text', title: 'NAME', required: true, max: 250, min: 1 },
                        { id: 'description', type: 'memo', title: 'DESCRIPTION', required: true, max: 1024, min: 1 },
                        { id: 'dateRange', type: 'combo', title: 'DATE_RANGE', values: getAnalyticsTimePresets(this.settings.timeOptions, this.i18n) },
                    ],
                }],
            };
        });

        this.modalActions = [{
            text: this.i18n.instant('DIALOG.CANCEL'),
            style: 'secondary',
            keypress: 27,
        }, {
            text: this.i18n.instant('DIALOG.SHARE'),
            style: 'primary',
            action: async () => {
                this.errorMessage = null;

                if (this.formBuilder.validate()) {

                    try {
                        this.loading = true;
                        const isTemplate = this.formValues.shareType === 'template';
                        const dashboards: string[] = this.formValues[isTemplate ? 'templates' : 'dashboards'];
                        const widget = await this.createWidget();
                        await this.addWidgetToDashboards(widget, dashboards, isTemplate);
                        this.showSuccessMessage(dashboards, isTemplate);
                    } catch (err) {
                        this.errorMessage = this.notify.translateError(err.message);
                    } finally {
                        this.state.dashboards = this.formValues.dashboards;
                        this.state.templates = this.formValues.templates;
                        this.state.shareType = this.formValues.shareType;
                        this.loading = false;

                    }

                }
                return true;
            },
        }];

        let { dateRange } = this.settings;
        dateRange = dateRange.includes(':') ? '7d' : dateRange;

        this.formValues = {
            name: null,
            description: null,
            widgetType: this.data.type,
            shareType: this.canSaveToTempate ? this.state.shareType : 'dashboard',
            dashboards: this.state.dashboards,
            templates: this.state.templates,
            dateRange,
        };

    }

    filterDashboardsByCostCentre(dashboards: DashboardListItem[], costCentres: AssetGroupingItem[], costCentre: AssetGroupingItem): DashboardListItem[] {
        const costCentreGroup = [];
        const findCostCentreParents = (item) => {
            if (item?.parent && item?.parent !== 'root') {
                costCentreGroup.push(item.id);
                const parent = costCentres.find(centre => centre.id === item.parent);
                if (parent.parent) {
                    costCentreGroup.push(parent.id);
                    const grandParent = costCentres.find(centre => centre.id === parent.parent);
                    findCostCentreParents(grandParent);
                }
            } else {
                costCentreGroup.push(item?.id || item);
            }
        };
        findCostCentreParents(costCentre);

        return dashboards.filter((dashboard) => {
            if (!dashboard.costCentre) { return true; }
            return costCentreGroup.some(item => item === dashboard.costCentre.id);
        });
    }

    detachModal() {
        this.actions.close();
    }

    getTagsField(dashboards: DashboardListItem[], templates: DashboardTemplateListItem[]): FormBuilderField {
        const dashboardField = {
            type: 'tags', id: 'dashboards', title: 'DASHBOARDS', required: true,
            placeholder: 'ENTER_DASHBOARD_NAME',
            getValue: (field, values) => {
                this.dashboards = dashboards.map(({ id, name, owner }) => ({ id, name, owner }));
                const value = values[field.id];
                return field.values
                    .filter(x => value.find(y => y === x.key))
                    .map(x => ({ id: x.key, name: x.value }));
            },
            values: dashboards.filter(({ id }) => id !== this.data.excludedDashboardId).map(({ id, name }) => ({ key: id, value: name })),
            actions: [{ icon: 'plus', dropdownValues: dashboards.filter(({ id }) => id !== this.data.excludedDashboardId).map(({ id, name }) => ({ id, name, hasChildren: false })) }],
        };

        const tagValues = [
            { key: 'dashboard', value: this.i18n.instant('ANALYTICS.CREATE_WIDGET.DASHBOARD'), fields: [dashboardField] },
            {
                key: 'template', value: this.i18n.instant('ANALYTICS.CREATE_WIDGET.TEMPLATE'), fields: [{
                    type: 'tags', id: 'templates', title: 'TEMPLATES', required: true,
                    placeholder: 'ENTER_TEMPLATE_NAME',
                    getValue: (field, values) => {
                        this.dashboards = templates.map(({ id, name, owner }) => ({ id, name, owner }));
                        const value = values[field.id];
                        return field.values
                            .filter(x => value.find(y => y === x.key))
                            .map(x => ({ id: x.key, name: x.value }));
                    },
                    values: templates.map(({ id, name }) => ({ key: id, value: name })),
                    actions: [{ icon: 'plus', dropdownValues: templates.map(({ id, name }) => ({ id, name, hasChildren: false })) }],
                }],
            },
        ];
        
        return this.canSaveToTempate ? { id: 'shareType', type: 'radio', title: 'SHARE_TO', values: tagValues } :  dashboardField;
    }

    async createWidget(): Promise<DashboardWidget> {
        const { widgetType, name, description, dateRange } = this.formValues;
        return {
            description,
            name,
            options: {
                analytics: {
                    ...this.settings,
                    name,
                    description,
                    dateRange,
                },
            },
            widgetType,
            placement: {
                col: 0,
                row: 0,
                colSpan: this.data.placement && this.data.placement.colSpan || 2,
                rowSpan: this.data.placement && this.data.placement.rowSpan || 1,
            },
        };
    }

    addWidgetToDashboards(widget: DashboardWidget, dashboards: string[], template: boolean): Promise<DashboardResponse | DashboardTemplateResponse[]> {
        const id = uuid.v4();
        return Promise.all(dashboards.map(dashboardId => {
            if (template) {
                return this.app.api.entities.updateDashboardTemplate(dashboardId, {
                    widgets: {
                        [id]: {
                            ...widget,
                            inherited: true,
                            sourceId: id,
                            dataSource: {
                                id: this.data.dataSourceId, // the api requires me to set the datasource id to do a bunch of processing on the specific widget and remove client specific data
                                type: 'analytics-mock',
                                options: {
                                    analytics: {
                                        definitionId: this.data.definitionId,
                                    },
                                },
                            },
                        },
                    },
                } as any);
            } else {
                return this.app.api.entities.updateDashboard(dashboardId, {
                    widgets: {
                        [id]: {
                            ...widget,
                            dataSource: {
                                id: this.data.dataSourceId,
                                type: (this.data.dataSourceId ? 'analytics-report' : 'analytics-mock'),
                                options: {
                                    analytics: {
                                        definitionId: this.data.definitionId,
                                    },
                                },
                            },
                        },
                    },
                });
            }
        }));
    }

    showSuccessMessage(dashboards: string[], template: boolean) {
        this.success = true;
        const actions: IKuiModalAction[] = [{
            text: this.i18n.instant('DIALOG.CLOSE'),
            style: 'primary',
            keypress: 27,
        }];

        if (dashboards.length === 1) {
            const { id, owner } = this.dashboards.filter(x => dashboards.find(y => y === x.id))[0];
            if (template) {
                actions.unshift({
                    text: this.i18n.instant('ANALYTICS.CREATE_WIDGET.EDIT'),
                    style: 'secondary',
                    action: () => {
                        if (this.actions.shared) {
                            this.actions.shared();
                        }
                        this.router.navigate(['admin', owner.type, owner.id, 'dashboardtemplates', 'grid', 'dashboardtemplate', id]);
                    },
                });
            } else {
                actions.unshift({
                    text: this.i18n.instant('ANALYTICS.CREATE_WIDGET.VIEW_ON'),
                    style: 'secondary',
                    action: () => {
                        if (this.actions.shared) {
                            this.actions.shared();
                        }
                        this.dashboardsService.openDashboard(id, false);
                    },
                });
            }
        }

        this.modalActions = actions;
    }

    onFormChanges(event: FormBuilderFieldChangeEvent) {
        if (event.field.id === 'widgetType') {
            this.widgetType = this.formValues.widgetType;
        }
        if (event.field.id === 'dateRange') {
            this.settings.dateRange = this.formValues.dateRange;
            this.updateCellset(this.data.dataSourceId, this.settings);
        }
    }

    updateCellset(dataSourceId: string, settings: AnalyticsSettings) {
        this.loading = true;
        this.noResult = false;

        this.analytics.getCellsetData(dataSourceId, settings).then(({ cellset }) => {
            if (!cellset.cellset) {
                this.noResult = true;
                return null;
            }
            this.cellset = cellset.cellset;
        }).catch(error => {
            if (error) {
                this.errorLogger.trackException(error);
            } else {
                this.noResult = true;
            }
        }).finally(() => {
            this.loading = false;
        });
    }

    getDashboardsString(dashboards: string[]): string {
        return this.dashboards
            .filter(x => dashboards.find(y => y === x.id))
            .reduce((all, cur, i, src) => all ? `${all}${i === src.length - 1 ? ' & ' : ', '}${cur.name}` : cur.name, '');
    }
}
