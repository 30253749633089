import { Injectable } from '@angular/core';
import { MeasurementUnitsService } from 'app/services';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class MapOptionMeasurementService {

    private _measuringSubject = new ReplaySubject<boolean>(1);
    private _undoSubject = new ReplaySubject<boolean>(1);
    private _measurementSubject = new ReplaySubject<string>(1);
    private zeroDistance = '0.00 km';

    distance$ = this._measurementSubject.asObservable();
    measuring$ = this._measuringSubject.asObservable();
    undo$ = this._undoSubject.asObservable();

    constructor(private units: MeasurementUnitsService) {
        const unit = this.units.format(0, 'distance').unit;
        this.zeroDistance = `0.00 ${unit}`
        this.setDistance(this.zeroDistance);
    }

    start() {
        this._measuringSubject.next(true);
    }

    stop() {
        this._measuringSubject.next(false);
        this.setDistance(this.zeroDistance);
    }

    setDistance(distance: string) {
        this._measurementSubject.next(distance);
    }

    undo() {
        this._undoSubject.next(true);
    }

}
 