import { Component, ChangeDetectionStrategy, Input, HostBinding, OnChanges, SimpleChanges, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { mergeWith, debounce, cloneDeep } from 'lodash';
import { AppService } from 'app/app.service';
import { CompletedReportResponse } from '@key-telematics/fleet-api-client';
import { MeasurementUnitsService, LocalStore, ErrorLoggerService, NotificationService } from 'app/services';
import { SettingsDatesMinMax } from 'app/shared/components/analytics/settings/settings.component';
import { removeSpaces } from 'app/shared/utils/string.utils';
import { YAxisMinMax } from 'app/shared/components/analytics/settings/y-axis/y-axis.component';
import { TablesData } from 'app/shared/components/analytics/tables/tables.component';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from 'app/shared/components/analytics/services/analytics.service';
import { AnalyticsCellValue, AnalyticsSettings, AvailableSettings, AnalyticsCellSetFilters } from 'app/shared/components/analytics';
import { KuiModalService } from 'app/key-ui/modal/modal.service';
import { AnalyticsCreateWidgetModalComponent, AnalyticsCreateWidgetModalOptions } from 'app/shared/components/analytics/modals/create-widget/create-widget.component';
import { ERRORS } from 'app/shared/model/errors.model';

export interface AnalyticsState {
    graphHeight: number;
    activeTableTab: string;
}

export const AnalyticsLocalStore = new LocalStore('analytics-component');

@Component({
    selector: 'key-analytics',
    templateUrl: './analytics.component.html',
    styleUrls: ['./analytics.component.scss'],
    providers: [{ provide: LocalStore, useValue: AnalyticsLocalStore }],
    changeDetection: ChangeDetectionStrategy.Default,
})
// TODO: This component currently only support one cellset and its options, although in future this will become an array of cellsets to render onto the page
export class AnalyticsComponent implements OnChanges {

    state: AnalyticsState;
    settings: AnalyticsSettings;
    activeSettingsTab = 'table';
    selectedSeries: string;

    tableTabs: string[];

    tableType: string;
    tablesData: TablesData;
    cellset: AnalyticsCellValue[][];
    filters: AnalyticsCellSetFilters;

    loading: boolean;
    noResults: boolean;
    validateError: boolean;
    updatedCalcMeasures = false;

    yAxesLimits: { left?: YAxisMinMax, right?: YAxisMinMax } = {};

    debouncedUpdateSettings = debounce(this.doUpdateSettings, 1000, { trailing: true, leading: false });
    debounceAddUpdateSettings = debounce(this.doUpdateSettings, 2000, { trailing: true, leading: false });
    debouncedSaveReportChanges = debounce(this.saveReportChanges, 3000, { trailing: true, leading: false });

    @Input() showSettings: boolean;
    @Input() visibleSettings: AvailableSettings;
    @Input() report: CompletedReportResponse;
    @Input() isMobile: boolean;
    @Input() persistChanges: boolean;

    @Output() onSettingsVisiblityToggle = new EventEmitter<boolean>();
    @Output() onGraphShared = new EventEmitter<boolean>(); // boolean is true if we should redirect

    @HostBinding('class.position-relative') positionRelative = true;

    get minMaxDates(): SettingsDatesMinMax {
        const params = this.report && this.report.config && this.report.config.parameters;

        return params
            ? { min: params['startDate'], max: params['endDate'] }
            : { min: null, max: null };
    }

    constructor(
        public app: AppService,
        public units: MeasurementUnitsService,
        public analytics: AnalyticsService,
        private changesRef: ChangeDetectorRef,
        private errorLogger: ErrorLoggerService,
        private store: LocalStore,
        private i18n: TranslateService,
        private modal: KuiModalService,
        private notify: NotificationService
    ) {
        this.state = this.store.watchState<AnalyticsState>('state', {
            graphHeight: 200,
            activeTableTab: this.i18n.instant('ANALYTICS.TABLES.TABS.DATA'),
        });

        this.tableTabs = [
            this.i18n.instant('ANALYTICS.TABLES.TABS.DATA'),
            this.i18n.instant('ANALYTICS.TABLES.TABS.TOTAL'),
            this.i18n.instant('ANALYTICS.TABLES.TABS.AVERAGE'),
        ];
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.report && changes.report.currentValue) {
            const report = changes.report.currentValue;
            this.settings = this.getSettingsFromReport(report);

            if (this.settings) {
                this.updateCellset(report.id, this.settings);
            }
        }
    }

    getSettingsFromReport(report: CompletedReportResponse): AnalyticsSettings {
        if (!report || !report.outputOptions || !report.outputOptions.charts || !report.outputOptions.charts[0]) {
            return null;
        }
        return {
            id: report.id,
            ...this.analytics.convertOutputToAnalyticsSettings(report.outputOptions.charts[0], true)
        };
    }

    async saveCalcMeasuresAndUpdateSettings(update) {
        try {
            const response = await this.analytics.saveReportChanges(this.report.id, this.report, this.settings);
            this.settings = this.getSettingsFromReport(response);
            this.updatedCalcMeasures = true;
        } catch (err) {
            this.updatedCalcMeasures = false;
            this.notify.error(err, null);

            if (err.data?.response?.body?.message.includes('Bad custom measure') && update.calcMeasureUpdate.added) {
                this.settings.calculatedMeasures.measures = this.settings.calculatedMeasures.measures.filter(x => x.id !== update.calcMeasureUpdate.id);
                update.table.measures = update.table.measures.filter(x => x.key !== update.calcMeasureUpdate.id);
            }
        }
        this.doUpdateSettings(update);
    }

    updateCellset(reportId: string, settings: AnalyticsSettings) {
        this.loading = true;

        Promise.all([
            this.updatedCalcMeasures ? this.analytics.getCellsetData(reportId, settings, true) : this.analytics.getCellsetData(reportId, settings),
            this.analytics.getFiltersList(reportId, settings),
        ]).then(([data, filters]) => {
            this.resetUi();

            this.cellset = data.cellset.cellset;
            this.filters = filters;

            if (!this.cellset) {
                this.noResults = true;
                return null;
            }

            const { rowLevels } = data.cellset;

            this.tableType = settings.table.layout;
                this.tablesData = {
                    cellset: cloneDeep(this.cellset),
                    rowLevels: settings.flipped ? settings.group.level + 1 : rowLevels,
                    columnLevels: settings.flipped ? rowLevels : settings.group.level + 1,
                    measures: settings.table.measures,
                    fixedHeaderTitle: settings.flipped ? settings.group.dim : settings.row.dim,
                    colors: settings.graph.seriesColors,
                };
    
            this.yAxesLimits = this.analytics.yAxesLimits;
            
            return this.persistChanges && this.debouncedSaveReportChanges(this.report.id, this.report, settings);
        }).catch(error => {
            if (error) {
                if (error.name === ERRORS.VALIDATE_ERROR) {
                    // TODO: show the list of fields required from the err object...
                    // for some reason when trying to find err.fields it returns undefined
                    this.validateError = true;
                }
                this.errorLogger.trackException(error);
            } else {
                this.resetUi();
                this.noResults = true;
            }
        }).finally(() => {
            this.loading = false;
            this.changesRef.markForCheck();
        });
    }

    resetUi() {
        this.noResults = false;
        this.validateError = null;

        this.tablesData = null;

        this.changesRef.markForCheck();
    }

    async saveReportChanges(reportId: string, report: CompletedReportResponse, settings: AnalyticsSettings): Promise<CompletedReportResponse> {
        try {
            return await this.analytics.saveReportChanges(reportId, report, settings);
        } catch (err) {
            this.notify.error(err, null);
        }
    }

    updateSettings(update: { [key: string]: any; }) {
        // graph and table layout settings will get a cached cellset, so no need to debounce them
        if (update.graph || update.table && update.table.layout) {
            this.doUpdateSettings(update);
        } else if (update.calcMeasureUpdate) {
            this.saveCalcMeasuresAndUpdateSettings(update);
        } else if (update.table && update.table.measures.length > this.settings.table.measures.length) {
            this.debounceAddUpdateSettings(update);
        } else {
            this.debouncedUpdateSettings(update);
        }
    }

    doUpdateSettings(update: { [key: string]: any; }) {

        const escapeNonObjectValue = (_obj, src) => {
            // we want to replace the changed value instead of merging it
            if (!(src instanceof Object) || Array.isArray(src)) {
                return src;
            }
        };

        this.settings = { ...mergeWith(this.settings, update, escapeNonObjectValue) };
        if (update.graph && update.graph.series) {
            this.settings.graph.seriesColors = this.analytics.getSeriesColors(this.settings.measures, this.settings.graph.series);
        }
        this.updateCellset(this.report.id, this.settings);
    }

    clearSeriesItems(keys: string[]) {
        this.settings = {
            ...this.settings,
            graph: {
                ...this.settings.graph,
                series: Object.entries(this.settings.graph.series)
                    .reduce((series, item) => {
                        const shouldAdd = !keys.find(key => item[0] === removeSpaces(key.toLowerCase()));
                        return shouldAdd ? { ...series, [item[0]]: item[1] } : series;
                    }, {}),
                seriesColors: this.analytics.getSeriesColors(this.settings.measures),
            },
        };

        this.updateCellset(this.report.id, this.settings);
    }

    updateGraphHeight(height: number) {
        this.state.graphHeight = height;
    }

    async exportReport(type: string) {
        // not catching this. we are getting data from cache, so by the time this code runs it won't get any server errors
        const { cellset } = await this.analytics.getCellsetData(this.report.id, this.settings);

        if (type === 'csv') {
            this.downloadBlob(
                this.convertCellsetJSONToCSV(cellset.cellset, cellset.rowLevels),
                (this.report.title || this.report.name).replace(/\W/g, '_'),
                'csv'
            );

        }

        if (type === 'pdf') {
            // TODO: handle pdf exports here
        }
    }

    convertCellsetJSONToCSV(cellset: AnalyticsCellValue[][], rowHeaderLevels: number): string {
        const PERCENTAGE = '___PERCENTAGE___';
        const nonValues = ['', 'null', 'TOTAL', 'AVERAGE', 'All'];
        const { levels, level } = this.settings.flipped ? this.settings.row : this.settings.group;
        const measureLength = this.settings.table.measures.length;
        const groupLevels: { value: string, selected: boolean }[] = levels.headings.map(value => ({ value, selected: value === levels.headings[level] }));

        cellset = cloneDeep(cellset); // clone cellset

        const extractUniqueValues = (arr: AnalyticsCellValue[], fn?: (AnalyticsCellValue) => string): string[] => {
            const set = new Set((arr || []).map(x => fn ? fn(x) : x.value.toString()));
            nonValues.forEach(x => {
                set.delete(x);
            });
            return [...set];
        };

        const colHeadersLength = groupLevels
            .findIndex(x => x.selected) + 1;

        const slicedHeader = cellset.splice(0, colHeadersLength);

        const groupHeaders = extractUniqueValues(slicedHeader[slicedHeader.length - 1], x => {
            // take out % sign(followed by a space) before decoding the URI and put it back afterwards... Also, and this is important, don't decode undefined as it will convert it to a string 'undefined', rather replace it with an empty string
            const uniquename = (x.properties.uniquename || '').replace('% ', PERCENTAGE);
            return decodeURIComponent(uniquename).replace(PERCENTAGE, '% ') || 'null';
        });

        if (!groupHeaders.length) {
            // By default, GroupHeader "All" is filtered out as we expect a GroupHeader to be set, like "By Year"
            // which will result in "All" and All|2021", or "By Month" which will result in "All", "All|20201|Nov" and "All|20201|Dec"
            // In these scenarios, "All" would result in all the data being written to the csv file, and then it would write it all again grouped
            // by a value like "All|2021". To avoid writing everything and then the grouped data, "All" is removed to leave just "All|2021"
            //
            // In the scenario that the "All" option is selected instead of "By Year", "By Month" or similar, we are left with no groupHeaders.
            // This forces "All" to be added to the groupHeaders so that we can produce a file with all the data in it. Without this, the csv
            // is empty.
            groupHeaders.push('All');
        }

        const headers = [
            ...groupLevels
                .filter(({ value }, index) => value !== 'All' && index < colHeadersLength)
                .map(({ value }) => value),
            ...extractUniqueValues(cellset.splice(0, 1)[0]),
        ];

        const columns = groupHeaders.reduce((cols: string[][], currentLevel: string, index: number): string[][] => {
            // add headers before all else
            if (index === 0) {
                cols.push(headers);
            }

            index++;
            const groupLevelItems = currentLevel.split('|').filter(l => !nonValues.find(x => x === l));
            cellset
                .filter(row => row[rowHeaderLevels - 1].value !== 'AVERAGE' && row[rowHeaderLevels - 1].value !== 'TOTAL')
                .forEach(row => {
                    // make a clone to cut in pieces without having to worry about mutating the array that was passed as a param
                    const clone = cloneDeep(row);

                    const rowLeves = clone
                        .splice(0, rowHeaderLevels)
                        .map(col => col.value.toString())
                        .filter(col => col !== 'All');

                    const cells = clone
                        .splice(index * measureLength - measureLength, measureLength)
                        .map(col => col.value.toString());


                    cols.push([
                        ...groupLevelItems, // start with the group level values
                        ...rowLeves, // add the row level values
                        ...cells, // add the values that matches the current group and row levels
                    ]);
                }, [] as string[][]);

            return cols;
        }, []);

        const csv = columns
            .map(row => row
                .map(field => JSON.stringify(field))
                .join(','));

        return csv.join('\r\n');
    }

    downloadBlob(text: string, filename: string = 'report', filetype: string) {
        const blob = new Blob([text], { type: `text/${filetype};charset=utf-8;` });
        const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link);

        if (link.download !== undefined) {
            link.setAttribute('href', URL.createObjectURL(blob));
            link.setAttribute('download', filename + '.' + filetype);
            link.click();
        } else {
            // fallback for iOS Safari
            text = `data:text/${filetype};charset=utf-8,` + text;
            window.open(encodeURI(text));
        }
        document.body.removeChild(link);
    }

    changeActiveSeriesSelection(id: string) {
        if (this.selectedSeries !== id) {
            // when assinging activeSettingsTab to 'series' if it was series already will not trigger change
            // making it null first so that series will always trigger
            this.activeSettingsTab = id !== 'default' ? null : this.activeSettingsTab;
            this.selectedSeries = null;
            setTimeout(() => {
                this.activeSettingsTab = id !== 'default' ? 'series' : this.activeSettingsTab;
                this.selectedSeries = id;
                this.changesRef.markForCheck();
            });
        }
    }

    setActiveTableTab(active: string) {
        this.state.activeTableTab = this.tableTabs[this.tableTabs.indexOf(active)];
    }

    addGraphToDashboard() {
        this.modal.open<AnalyticsCreateWidgetModalOptions>(AnalyticsCreateWidgetModalComponent, {
            data: {
                cellset: this.cellset,
                settings: this.settings,
                name: this.report.title || this.report.name,
                description: this.report.outputOptions.charts[0].description,
                type: 'chart',
                dataSourceId: this.report.id,
                definitionId: this.report.config.definitionId,
            },
            actions: {
                shared: () => {
                    this.onGraphShared.emit(true);
                },
                close: () => {
                    this.modal.close();
                    this.onGraphShared.emit(false);
                },
            },
        });
    }
}
