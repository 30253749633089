import { Component, Input, Output, EventEmitter, ElementRef, AfterViewInit, OnChanges } from '@angular/core';
import { AnalyticsCellValue } from '../analytics.model';

export interface DataTableCellset {
    header?: string;
    section: AnalyticsCellValue[][];
}

export interface DataTableSection {
    title: string;
    subTitles: string[];
    table: DataTableCellset[];
}

@Component({
    selector: 'key-analytics-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss'],
})
export class AnalyticsDataTableComponent implements AfterViewInit, OnChanges {
    headerWidth: string;

    @Input() cellset: DataTableSection[];
    @Input() selected: string;
    @Output() onHeaderClick = new EventEmitter<AnalyticsCellValue>();

    constructor(
        private el: ElementRef
    ) { }

    doOnHeaderClick(header: AnalyticsCellValue) {
        if (header.properties.color) {
            this.onHeaderClick.emit(header);
        }
    }

    ngOnChanges() {
        setTimeout(() => {
            this.updateHeaderWidth();
        });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.updateHeaderWidth();
        });
    }

    // table headings doesn't fill overflow sections... this forces it to be the same as the actual table
    updateHeaderWidth() {
        const table = this.el.nativeElement.querySelector('.data-table__table');
        this.headerWidth = table && table.clientWidth + 'px';
    }
}
