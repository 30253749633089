import { CurrentTheme } from 'app/shared/model';

export interface IconHtmlOptions {
    fill?: string;
    secondaryColorFill?: string;
    strokeFill?: string;
    rotation?: string | number;
    size?: number;
    dropShadowAlpha?: number;
}

/*
* these are the available color presets returned from the payload
*/
export enum MAP_COLORS_ENUM {
    'blue',
    'lightblue',
    'green',
    'olive',
    'orange',
    'pink',
    'purple',
    'red',
    'teal',
    'yellow',
    'lightgray',
    'gray',
}

/*
* these are the better display color substitues for the available color presets
 * Note: Most of these are copics, or slight adjusted ones for usability.
*/
export const MAP_COLORS: string[] = [
    '#058FD0', // blue
    '#62CBE8', // lightblue
    '#72C156', // green
    '#98A786', // olive
    '#F26B3C', // orange
    '#CE218E', // pink
    '#9721CE', // purple
    '#EF5151', // red
    '#01B1C9', // teal
    '#FFE455', // yellow
    '#999', // light gray
    '#666', // gray
];

export type IconType = 'stop' | 'idle' | 'direction' | string;

export function getIconSvgString(theme: CurrentTheme, iconType?: IconType, options?: IconHtmlOptions): string {
    
    const iconSet = theme?.settings?.icons?.location?.set || 'default';

    switch (iconSet) {
        case 'kinesis':
            return getRadiusIconSvgString(iconType, options);
        default: return getKeyIconSvgString(iconType, options);
    }
}

export function getTripIconSvgString(theme: CurrentTheme, iconType?: IconType, options?: IconHtmlOptions): string {

    const iconSet = theme?.settings?.icons?.location?.set || 'default';

    switch (iconSet) {
        case 'kinesis':
            return getRadiusTripIconSvgString(iconType, options);
        default: return getKeyIconSvgString(iconType, options);
    }
}

export function getKeyIconSvgString(type?: string, options?: IconHtmlOptions) {
    options = {
        ...{
            fill: '#333333',
            secondaryColorFill: '#ffffff',
            strokeFill: '#ffffff',
            size: 50,
            rotation: 0,
            dropShadowAlpha: 0,
        }, ...options,
    };

    switch (type) {
        case 'stop':
            /* tslint:disable */
            return `<svg id="stop" width="${options.size}" height="${options.size}" viewBox="0 0 30 30" version="1.1">
            <g
                transform="translate(12,6)"
                style="fill:none;fill-rule:evenodd">
                <circle
                    style="fill:${options.fill};stroke:${options.strokeFill};stroke-width:1;stroke-location:outside;stroke-miterlimit:4;stroke-dasharray:none"
                    cx="3"
                    cy="9"
                    r="10" />
                <g transform="matrix(1.2435685,0,0,0.67151445,-2.4268539,7.4173964)" style="fill-rule:nonzero" />
                <ellipse
                    style="fill:${options.secondaryColorFill};stroke:${options.secondaryColorFill};stroke-width:6.219;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:stroke markers fill"
                    cx="2.9499993"
                    cy="9.0500002"
                    rx="5.2317243"
                    ry="5.2317247" />
                <g
                    aria-label="S"
                    style="font-style:normal;font-weight:normal;font-size:40px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:${options.fill};fill-opacity:1;stroke:none">
                <path
                    d="M 5.0888777,6.8408443 Q 4.7955444,6.467511 4.3155444,6.2541777 3.8488777,6.027511 3.4088777,6.027511 q -0.2266666,0 -0.4666666,0.04 -0.2266667,0.04 -0.4133334,0.16 -0.1866666,0.1066667 -0.32,0.2933333 -0.12,0.1733334 -0.12,0.4533334 0,0.24 0.093333,0.4 0.1066666,0.16 0.2933333,0.2799999 0.2,0.12 0.4666667,0.2266667 0.2666666,0.093333 0.5999999,0.2 0.48,0.16 1,0.36 0.52,0.1866667 0.9466667,0.5066667 0.4266666,0.3199999 0.7066666,0.7999999 0.28,0.4666671 0.28,1.1733331 0,0.813334 -0.3066666,1.413334 -0.2933334,0.586666 -0.8,0.973333 -0.5066667,0.386667 -1.16,0.573333 -0.6533333,0.186667 -1.3466666,0.186667 -1.0133333,0 -1.95999999,-0.346667 -0.94666664,-0.36 -1.5733333,-1.013333 l 1.4933333,-1.52 q 0.34666669,0.426667 0.90666669,0.72 0.5733333,0.28 1.1333333,0.28 0.2533333,0 0.4933333,-0.05333 0.24,-0.05333 0.4133333,-0.173334 0.1866667,-0.12 0.2933333,-0.32 0.1066667,-0.2 0.1066667,-0.48 0,-0.266666 -0.1333333,-0.453333 -0.1333334,-0.186667 -0.3866667,-0.333333 -0.24,-0.16 -0.6133333,-0.28 -0.36,-0.1333337 -0.8266667,-0.2800004 -0.4533333,-0.1466667 -0.8933333,-0.3466667 -0.42666662,-0.2 -0.77333328,-0.5066666 -0.33333333,-0.32 -0.54666666,-0.76 -0.19999999,-0.4533333 -0.19999999,-1.0933333 0,-0.7866667 0.31999999,-1.3466666 0.31999999,-0.56 0.83999998,-0.92 0.51999996,-0.36 1.17333326,-0.52 0.6533334,-0.1733333 1.32,-0.1733333 0.8,0 1.6266666,0.2933333 0.84,0.2933333 1.4666667,0.8666666 z"
                    style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:13.33333302px;font-family:'Avenir Next';-inkscape-font-specification:'Avenir Next Bold';fill:${options.fill}" />
                </g>
          </g>
        </svg>`;
        /* tslint:enable */

        case 'idle':
            /* tslint:disable */
            return `<svg id="idle" width="${options.size}" height="${options.size}" viewBox="0 0 30 30" version="1.1">
            <g
                transform="translate(12,6)"
                style="fill:none;fill-rule:evenodd">
                <circle
                    style="fill:${options.fill};stroke-width:1;stroke:${options.strokeFill};stroke-location:outside;stroke-miterlimit:4;stroke-dasharray:none"
                    cx="3"
                    cy="9"
                    r="10" />
                <circle style="fill:${options.secondaryColorFill};" cx="3" cy="9" r="8" />
                <circle style="fill:${options.fill};" cx="3" cy="9" r="5" />
          </g>
        </svg>`;
        /* tslint:enable */

        case 'direction':
            /* tslint:disable */
            return `<svg id="direction" width="${options.size}" height="${options.size}" style="transform:rotate(${options.rotation}deg)" viewBox="0 0 30 30" version="1.1">
            <g
                transform="translate(12,6)"
                style="fill:none;fill-rule:evenodd">
                <path
                    style="fill:${options.fill};stroke:${options.strokeFill};stroke-width:1;stroke-location:outside;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:stroke markers fill"
                    d="M 15,0.30444516 C 15,0.30444516 5,8.1513272 5,15 5,20.522857 9.4771523,25 15,25 20.522857,25 25,20.522857 25,15 25,7.9745504 15,0.30444516 15,0.30444516 Z M 15,7.5 c 4.142167,0 7.5,3.992632 7.5,8.134766 C 22.5,19.776933 19.142167,22.5 15,22.5 10.857867,22.5 7.5,19.142167 7.5,15 7.5,10.857867 10.857867,7.5 15,7.5 Z"
                    transform="translate(-12,-6)" />

                <g transform="matrix(1.2435685,0,0,0.67151445,-2.4268539,7.4173964)" style="fill-rule:nonzero" />
                <circle style="fill:${options.secondaryColorFill};" cx="3" cy="9" r="8" />
                <circle style="fill:${options.fill};" cx="3" cy="9" r="5" />
            </g>
          </svg>`;
        /* tslint:enable */

        default:
            return `<svg></svg>`;
    }
}

export function getRadiusIconSvgString(type?: string, options?: IconHtmlOptions) {
    options = {
        ...{
            fill: '#333333',
            secondaryColorFill: '#ffffff',
            strokeFill: '#ffffff',
            size: 50,
            rotation: 0,
            dropShadowAlpha: 0,
        }, ...options,
    };

    // original = 0 0 24 35 -- we've offset it to make the icon square, otherwise we have issues
    const viewBox = `-5 0 30 35`;

    switch (type) {
        case 'stop':
            /* tslint:disable */
            return `<svg width="${options.size}" height="${options.size}" viewBox="${viewBox}" anchor="${options.size / 2} ${options.size}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>idling</title>
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="idling" fill-rule="nonzero">
                                <path d="M12.5,0 C19.4035594,0 25,5.75633893 25,12.8571429 C25,19.9579468 16.5403563,26.3950444 12.5,36 C8.4339961,26.4161996 0,19.9579468 0,12.8571429 C0,5.75633893 5.59644063,0 12.5,0 Z" id="Path" fill="#FC8939"></path>
                                <path d="M11.3995117,8.4 L7.46669922,8.4 L7.46669922,18 L11.3995117,18 L11.3995117,8.4 Z M17.5333008,8.4 L13.6004883,8.4 L13.6004883,18 L17.5333008,18 L17.5333008,8.4 Z" id="Combined-Shape" fill="#FFFFFF"></path>
                            </g>
                        </g>
                    </svg>`;
        /* tslint:enable */

        case 'idle':
            /* tslint:disable */
            return `<svg width="${options.size}" height="${options.size}" viewBox="${viewBox}" anchor="${options.size / 2} ${options.size}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>stopped</title>
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="stopped" fill-rule="nonzero">
                            <path d="M12.5,0 C19.4035594,0 25,5.75633893 25,12.8571429 C25,19.9579468 16.5403563,26.3950444 12.5,36 C8.4339961,26.4161996 0,19.9579468 0,12.8571429 C0,5.75633893 5.59644063,0 12.5,0 Z" id="Path" fill="#F44055"></path>
                            <polygon id="Path" fill="#FFFFFF" points="17.3 8.4 7.7 8.4 7.7 18 17.3 18"></polygon>
                        </g>
                    </g>
                </svg>`;
        /* tslint:enable */

        case 'direction':
            /* tslint:disable */
            return `<svg width="${options.size}" height="${options.size}" viewBox="${viewBox}" anchor="${options.size / 2} ${options.size}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>moving</title>
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="moving" fill-rule="nonzero">
                                <path d="M12.5,0 C19.4035594,0 25,5.75633893 25,12.8571429 C25,19.9579468 16.5403563,26.3950444 12.5,36 C8.4339961,26.4161996 0,19.9579468 0,12.8571429 C0,5.75633893 5.59644063,0 12.5,0 Z" id="Path" fill="#00BE73"></path>
                                <polygon id="Path" fill="#FFFFFF" points="8.77834254 6.64355091 8.77834254 21.6 20 14.1217755"></polygon>
                            </g>
                        </g>
                    </svg>`;
        /* tslint:enable */

        default:
            return `<svg></svg>`;
    }
}

export function getRadiusTripIconSvgString(type?: string, options?: IconHtmlOptions) {
    options = {
        ...{
            fill: '#333333',
            secondaryColorFill: '#ffffff',
            strokeFill: '#ffffff',
            size: 50,
            rotation: 0,
            dropShadowAlpha: 0,
        }, ...options,
    };

    switch (type) {
        case 'stop':
            /* tslint:disable */
            return `<svg width="${options.size}" height="${options.size}" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>pin / trip-history / idling</title>
                        <g id="pin-/-trip-history-/-idling" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <circle id="Oval" fill="#FC8939" cx="12.5" cy="12.5" r="8"></circle>
                            <path d="M11.5161134,17 L8,17 L8,8 L11.5161134,8 L11.5161134,17 Z M17,17 L13.4838866,17 L13.4838866,8 L17,8 L17,17 Z" id="Combined-Shape" fill="#FFFFFF" fill-rule="nonzero" transform="translate(5, 5) scale(0.6)"></path>
                        </g>
                    </svg>`;
        /* tslint:enable */

        case 'idle':
            /* tslint:disable */
            return `<svg width="${options.size}" height="${options.size}" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>pin / trip-history / stopped</title>
                        <g id="pin-/-trip-history-/-stopped" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <circle id="Oval" fill="#F44055" cx="12.5" cy="12.5" r="8"></circle>
                            <rect id="Rectangle" fill="#FFFFFF" fill-rule="nonzero" transform="translate(5, 5) scale(0.6) " x="8" y="8" width="9" height="9"></rect>
                        </g>
                    </svg>`;
        /* tslint:enable */

        case 'direction':
            /* tslint:disable */
            return `<svg width="${options.size}" height="${options.size}" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>pin / trip-history / moving</title>
                        <g id="pin-/-trip-history-/-moving" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                            transform="rotate(${options.rotation},12.5,12.5)"
                        >
                            <circle id="Oval" fill="#00BE73" cx="12.5" cy="12.5" r="8"></circle>
                            <polygon id="Triangle" fill="#FFFFFF" fill-rule="nonzero" transform="translate(12.500000, 12.00000) scale(0.6) translate(-12.500000, -11.000000) " points="12.5 4 20 18 12.4338882 13.0987632 5 18"></polygon>
                        </g>
                    </svg>`;
        /* tslint:enable */

        default:
            return `<svg></svg>`;
    }
}
