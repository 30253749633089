import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'kui-snackbar-item',
    templateUrl: 'snackbar-item.component.html',
    styleUrls: ['snackbar-item.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class KuiSnackbarItemComponent {
    pause = false;

    @Input() icon: string;
    @Input() title: string;
    @Input() description: string;
    @Input() actionText: string;
    @Input() duration: number;

    @Output() onActionClicked = new EventEmitter<void>();
    @Output() onClosed = new EventEmitter<void>();
}
