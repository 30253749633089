<key-form-builder [ownerId]="clientId"
    [inline]="!isMobile"
    [form]="reportForm"
    [values]="reportValues"></key-form-builder>

<key-form-builder class="pt-2"
    [ownerId]="clientId"
    [inline]="!isMobile"
    [form]="parametersForm"
    [values]="parameterValues"></key-form-builder>

<div *ngIf="hasLayout">

    <key-form-builder [inline]="!isMobile"
        [ownerId]="clientId"
        [form]="layoutForm"
        [values]="layoutFormValues"></key-form-builder>

    <div *ngIf="columns && columns.length > 0"
        class="form-group"
        [class.inline]="!isMobile"
        [class.hidden]="layoutForm?.groups[0]?.visible === false">
        <label class='form-label'>{{ 'REPORTING.BUILDER.FORMS.COLUMNS' | translate }}</label>
        <div class="form-field">
            <key-reporting-builder-columns [columns]="columns"
                [availableColumns]="reportDefinition.layout.columns"
                [autoColumns]="reportDefinition.layout.autoColumns"
                (onUpdateColumns)="columns = $event"
                class="text-left"></key-reporting-builder-columns>
        </div>
    </div>
</div>