import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class StaticPageService {

    private routeSubject = new Subject<ActivatedRouteSnapshot>();
    route$ = this.routeSubject.asObservable();

    emitShapshot(snapshot: ActivatedRouteSnapshot) {
        this.routeSubject.next(snapshot);
    }


}
