import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { Subject, Observable } from 'rxjs';
import { MapCoordinates, MapMarker, MapRoute } from './map.component';
import { SearchResult } from '../search-results-list/search-results-list.component';
import * as uuid from 'uuid';
import * as simplify from 'simplify-geometry';
import { TranslateService } from '@ngx-translate/core';
export type MapToolsLoading = 'coordinates' | 'search';

@Injectable({
    providedIn: 'root',
})
export class MapToolsService {
    private promisesCache: { [key: string]: Promise<any> } = {};

    loadingSubject = new Subject<MapToolsLoading>();
    loading$: Observable<MapToolsLoading> = this.loadingSubject.asObservable();

    constructor(
        public app: AppService,
        private i18n: TranslateService
    ) {
    }

    async getCoordinatesDetails(lat: number, lon: number): Promise<MapCoordinates> {
        this.loadingSubject.next('coordinates');

        try {
            const id = `map.geocodeReverse/${this.app.client.id}/${lat}/${lon}`;
            const cache = await this.promisesCache[id];
            const response = cache || await this.app.api.map.geocodeReverse(this.app.client.id, lat, lon).then(x => (x.items || [])[0]);

            this.promisesCache[id] = response;

            // return the response values or default to the lat and lon from the parameter.
            return response ? {
                address: response.text,
                lat: response.lat,
                lon: response.lon,
                speed: response.speed,
            } : {
                lat,
                lon
            };
        } catch (error) {
            return { error };
        } finally {
            this.loadingSubject.next(null);
        }
    }

    async doSearch(term: string, action: (marker: MapMarker) => void): Promise<SearchResult<MapCoordinates>[]> {
        if (!term) { return; }

        this.loadingSubject.next('search');

        try {
            const icons = {
                location: 'location-arrow',
                keepin: 'draw-square',
                nogo: 'do-not-enter',
                route: 'route',
                address: 'map-marker-alt',
            };

            const id = `map.geocodeForward/${this.app.client.id}/${term}`;
            const cache = await this.promisesCache[id];
            const response = cache || await this.app.api.map.geocodeForward(this.app.client.id, term).then(x => x.items || []);

            this.promisesCache[id] = response;

            return response.map(x => ({
                id: x.id,
                value: x.text,
                meta: { address: x.text, lon: x.lon, lat: x.lat },
                icon: icons[x.type],
                action: () => {
                    const marker = {
                        id: x.id,
                        name: x.text,
                        lon: x.lon,
                        lat: x.lat,
                        bounds: x.bounds,
                        getIcon: () => ({ size: 0, html: `<i class="location-icon map-font-icon__shadows icon icon-${icons[x.type]}"></i>` }),
                    };
                    action(marker);
                },
            }));
        } catch (error) {
            console.error(error);
            // empty list will show message of no items found which is okay
            return [];
        } finally {
            this.loadingSubject.next(null);
        }
    }


    async doRouting(points: { name?: string, lon: number, lat: number }[]): Promise<MapRoute[]> {

        const trim = (str: string) => str?.split(',')[0] || '';
        const coords = points.reduce((all, x, i) => {
            if (x.lat != null && x.lon != null) {
                all += `${i > 0 ? ',' : ''}[${x.lon},${x.lat}]`;
            }
            return all;
        }, ''); // returns coords like `[1,2],[3,4]`


        const result = await this.app.api.map.getRoute(this.app.client.id, 'fastest,shortest', coords);
        const uniqueRoutes = new Set();
        const routes = result.routes
            // sort to move fastest item to the top of the list
            .sort((_a, b) => b.strategy === 'fastest' ? 1 : -1)
            // filter out duplicate routes
            .filter(x => {
                const { distance, time } = x.segments[0];
                const key = distance + time;
                if (uniqueRoutes.has(key)) {
                    return false;
                }
                uniqueRoutes.add(key);
                return true;
            })
            .map(x => ({
                id: uuid.v4(),
                strategy: x.strategy,
                // TODO: for now we accept that segments always returns a single item array, but this can change in future
                time: x.segments[0].time,
                coords: simplify(x.segments[0].geom.coordinates, 0.0001), // reduce coords points with an error tolerance of 11.1m between points
                distance: x.segments[0].distance,
                active: x.strategy === 'fastest',
                description: this.i18n.instant(`LEAFLET.ROUTING.DESC.${x.strategy.toUpperCase()}`, {
                    start: trim(points[0].name),
                    end: trim(points[points.length - 1].name),
                }),
            }));
        return routes;
    }

}
