import { Injectable, Injector, Inject, ComponentFactoryResolver, EmbeddedViewRef, ApplicationRef, ComponentRef, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { KuiSnackbarItem, KuiSnackbarBanner } from './snackbar.model';
import { KuiSnackbarComponent } from './snackbar.component';
import * as uuidv3 from 'uuid/v3';
import { EMPTY_GUID } from 'app/shared/components/admin/entities/utils';

@Injectable()
export class KuiSnackbarService implements OnDestroy {
    componentRef: ComponentRef<KuiSnackbarComponent>;
    component: KuiSnackbarComponent;
    cache = new Set<string>();

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector,
        @Inject(DOCUMENT) private document: HTMLDocument
    ) {
        setTimeout(() => this.init());
    }

    ngOnDestroy() {
        this.destroy();
    }

    setLimit(limit: number) {
        this.component.limit = limit;
    }

    // carte blance to add your own options
    add(item: KuiSnackbarItem) {
        this.component.addItem(item);
    }

    // message is just a simple description, title(optional) and icon(optional) that goes away after 5 seconds
    message(description: string, title?: string, icon?: string): Promise<void> {
        return new Promise(resolve => {
            const id = uuidv3(title + description, EMPTY_GUID);
            if (!this.cache.has(id)) {
                this.cache.add(id);
                this.component.addItem({
                    title,
                    description,
                    icon,
                    duration: 5000,
                    onClose: () => {
                        resolve();
                        this.cache.delete(id);
                    },
                });
            }
        });
    }

    // banner has an action and can either go away or stay there until actioned depending on the use case
    banner({ title, description, icon, actionText, keepOpen }: KuiSnackbarBanner = {}): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            const id = uuidv3(title + description, EMPTY_GUID);
            if (!this.cache.has(id)) {
                this.cache.add(id);
                this.component.addItem({
                    title,
                    description,
                    icon,
                    actionText,
                    duration: !keepOpen && 10000,
                    action: () => {
                        resolve(true);
                        this.cache.delete(id);
                    },
                    onClose: () => {
                        resolve(false);
                        this.cache.delete(id);
                    },
                });
            }
        });
    }

    private init() {
        this.destroy();

        this.componentRef = this.componentFactoryResolver
            .resolveComponentFactory(KuiSnackbarComponent)
            .create(this.injector);

        this.component = this.componentRef.instance;

        this.appRef.attachView(this.componentRef.hostView);
        const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;

        this.document.body.appendChild(domElem);
    }

    private destroy() {
        if (this.componentRef) {
            this.appRef.detachView(this.componentRef.hostView);
            this.componentRef.destroy();
        }
    }
}
