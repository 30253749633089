import { Component, Input, OnInit, ChangeDetectorRef, Output, EventEmitter, ViewChild, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { FormBuilderField, FormBuilderFieldChangeEvent, BaseFormBuilderFieldComponent, FormBuilderAction } from '../form-builder.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'key-form-builder-field',
    templateUrl: './field.component.html',
})
export class KeyFormBuilderFieldComponent implements OnInit, OnDestroy {

    @Input() field: FormBuilderField;
    @Input() values: { [key: string]: any };
    @Input() error: string; // set this error value externally to have the default error highligh and display kick in

    @Input() inline = true;

    @Output() onChange: EventEmitter<FormBuilderFieldChangeEvent> = new EventEmitter();
    @ViewChild('fieldComponent') fieldComponent: BaseFormBuilderFieldComponent;
    @ViewChildren('subFieldComponent') subFields: QueryList<KeyFormBuilderFieldComponent>;


    subfields: FormBuilderField[] = [];

    loading = true;
    errorMessage: string;

    constructor(
        private i18n: TranslateService,
        private ref: ChangeDetectorRef
    ) {
    }

    validate(): boolean {
        try {
            const validationError = this.field.validate && this.field.validate(this.field, this.values, this.field.getValue ? this.field.getValue(this.field, this.values) : this.values[this.field.id]);
            if (validationError) {
                this.error = validationError;
            }
            return this.fieldComponent.validate()
                && this.subFields.reduce((result, form) => result + (form.validate() ? 0 : 1), 0) === 0
                && !validationError;
        } catch (err) {
            console.log(this.field);
            console.error(err);
            return true;
        }
    }

    ngOnInit() {
        this.field.component = this.fieldComponent;
        if (this.field.lookupFunc) {
            this.field.lookupFunc()
                .then(items => {
                    this.field.values = [...this.field.values || [], ...items];
                    this.show();
                })
                .catch(err => {
                    console.error(err);
                    this.errorMessage = this.i18n.instant('FORMS.SHARED.LOOKUP_FAILED');
                    this.show();
                });
        } else {
            this.show();
        }
    }

    ngOnDestroy() {
        this.field.component = undefined;
    }

    show() {
        this.loading = false;
        this.ref.markForCheck();
        setTimeout(() => { // need to wait for component to be shown before the reference is valid
            this.field.component = this.fieldComponent;
        });
    }

    comboChanged(id: string | number, dirty: boolean) {
        const field = this.field.values.find(x => x.key === id);
        setTimeout(() => {
            this.subfields = (field && field.fields) || [];
            this.emitFieldChange(dirty);
        });
    }

    toggleChanged(state: boolean, dirty: boolean) {
        if (state) {
            const field = this.field.values && this.field.values[0];
            this.subfields = (field && field.fields) || [];
        } else {
            this.subfields = [];
        }

        this.emitFieldChange(dirty);
    }

    searchSelectChanged(dirty?: boolean) {
        const { subfields } = this.field.options || {} as any;
        this.subfields = subfields || [];
        this.emitFieldChange(dirty);
    }

    emitFieldChange(dirty?: boolean, field?: FormBuilderField) {
        if (this.field.onChange) {
            this.field.onChange(this.field, this.values).then(() => this.ref.markForCheck());
        }
        this.onChange.emit({
            field: field || this.field,
            values: this.values,
            dirty,
        });
    }

    onActionClick(action: FormBuilderAction, id?: string) {
        const promise = action.click(id || action.id, this.field, this.values);
        (promise || Promise.resolve()).then(() => {
            // force update on fieldComponent... pick up changes to this.values on items that are of type arrays
            if (this.fieldComponent.update) {
                this.fieldComponent.update();
            }
            this.emitFieldChange(true);
            this.ref.markForCheck();
        });
    }

}
