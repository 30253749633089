<kui-card>
    <kui-card-body>
        <div
            class="heading"
            [ngClass]="{'compact': compact}">
            <kui-icon
                color="default"
                class="mr-3"
                [size]="compact ? 'sm' : 'lg'"
                name="trash-can-xmark">
            </kui-icon>
            <h5 class="m-0">
                {{'LEAFLET.ZONES.CLICK_REMOVE' | translate}}
            </h5>
        </div>
        <button kui-button
            (click)="save()">
            {{'LEAFLET.DRAW.SAVE' | translate}}
        </button>
        <button kui-button color="secondary" (click)="cancel()">{{'LEAFLET.DRAW.CANCEL' | translate}}</button>
    </kui-card-body>
</kui-card>