import { Directive, HostBinding, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatchMediaService } from 'app/services';

@Directive({
    selector: '[key-content-container]',
})
export class KeyContentContainerDirective implements OnInit {
    @Input() scroll: boolean;

    @HostBinding('class.flex-1') flex1 = true;
    @HostBinding('class.position-relative') positionRelative = true;
    @HostBinding('class.overflow-scroll') overflowScroll: boolean;
    @HostBinding('class.overflow-auto') overflowAuto: boolean;
    @HostBinding('class.overflow-hidden') overflowHidden: boolean;

    constructor(private media: MatchMediaService, private ref: ChangeDetectorRef) { }

    ngOnInit() {
        this.overflowHidden = !this.scroll;


        if (this.scroll) {
            this.media.breakpoint
                .subscribe(bp => {
                    if (bp.alias === 'xs' || bp.alias === 'sm') {
                        this.overflowScroll = true;
                        this.overflowAuto = false;
                    } else {
                        this.overflowScroll = false;
                        this.overflowAuto = true;
                    }
                    this.ref.markForCheck();
                });
        }
    }
}
