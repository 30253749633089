import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, Signal, ViewChild, WritableSignal, input, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { KuiButtonModule, KuiContentHeaderModule, KuiIconModule } from 'app/key-ui';
import { MatchMediaService } from 'app/services';
import { TripItem } from 'app/services/trips/trips.service';
import { KeyMapModule } from 'app/shared/components';
import * as moment from 'moment';
import { ReplaySubject, takeUntil } from 'rxjs';
import { VideoAsset } from '../asset-selection/asset-selection.component';
import { EventSelectorComponent } from '../event-selector/event-selector.component';
import { TripListComponent } from '../trip-list/trip-list.component';
import { Validate } from '../validate.model';

export interface ClipSettings {
    duration: number,
    offset: number,
    eventDate: string,
    startDate: string,
    endDate: string,
}

@Component({
    selector: 'key-clip-builder',
    templateUrl: 'clip-builder.component.html',
    styleUrls: ['clip-builder.component.scss'],
    imports: [
        TripListComponent,
        CommonModule,
        EventSelectorComponent,
        KeyMapModule,
        KuiIconModule,
        KuiButtonModule,
        KuiContentHeaderModule,
        TranslateModule
    ],
    standalone: true,
})
export class ClipBuilderComponent implements OnInit, Validate {

    asset = input.required<VideoAsset>();
    reset = input.required<boolean>();
    @Output() settingsChanged = new EventEmitter<ClipSettings>();

    trip: WritableSignal<TripItem> = signal<TripItem>(undefined);
    isMobile: Signal<Boolean> = signal(false);
    date: WritableSignal<string> = signal(moment.utc().toISOString());

    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    @ViewChild(EventSelectorComponent, { static: false }) eventSelectorComponent: EventSelectorComponent;

    constructor(
        public i18n: TranslateService,
        public media: MatchMediaService,
    ) {
        toObservable(this.reset).pipe(
            takeUntil(this.destroyed$)
        ).subscribe(_ => {
            this.trip.set(null);
        });
        this.isMobile = toSignal(media.isMobile);
    }

    ngOnInit(): void { }

    isValid(): boolean {
        if (this.eventSelectorComponent) {
            return this.eventSelectorComponent.isValid();
        }
        return false;
    }

    handleSettingsChange(settings: ClipSettings) {
        this.settingsChanged.emit(settings);
    }

    handleTripSelection(trip: TripItem) {
        this.trip.set(trip);
        this.date.set(trip?.dateStart);
    }

}
