import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { escapeRqlValue } from 'app/shared/utils/rql';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStore } from '../storage/storage.service';

export enum SORT_DIRECTION {
    ASC = 'asc',
    DESC = 'desc',
}

export type FilterType = 'costcentre' | 'assetgroup' | 'assetcategory' | 'assettype' | 'name' | string;

export type QueryOperator = 'eq' ; // future proof, hopefully...

export type LogicalOperator = 'AND' | 'OR';

export interface TreeListItem {
    id: string;
    name: string;
    parent: string;
}

export interface QueryMetaData {
    [key: string]: any;
}

export interface Query {
    key: string;
    value: string;
    operator: QueryOperator;
    exact: boolean;
    display: string;
    metaData?: QueryMetaData;
}

export interface Filter {
    queries: Query[];
    operator: LogicalOperator;
}

export interface AssetFilter {
    [type: FilterType]: Query | Filter;
}

export interface SortObject {
    value: string;
    direction: SORT_DIRECTION;
}

export interface FilterPoint {
    lon: number;
    lat: number;
    address: string;
}

export interface FiltersResponse {
    filters: AssetFilter;
    sort: string;
    limit: number;
    offset: number;
    clientId: string;
    point?: FilterPoint;
}

export function isQuery(query: Query | Filter): query is Query {
    return (query as Query)?.value !== undefined;
}

@Injectable({
    providedIn: 'root',
})
export class AssetFilterService {

    filters: AssetFilter = {};
    searchType: FilterType;

    sort: SortObject = {
        value: 'name',
        direction: SORT_DIRECTION.ASC,
    };
    limit = this.app.features.page.overview.pageSize || 300;
    offset = 0;
    clientId: string;
    point: FilterPoint;

    store: LocalStore;

    // listens to any param change be it filter, sort, limit or offset and returns an object with the latest values
    private filtersSubject: BehaviorSubject<FiltersResponse> = new BehaviorSubject(this.getFilters());

    get filters$(): Observable<FiltersResponse> {
        return this.filtersSubject.asObservable();
    }

    constructor(private app: AppService) {
        this.store = this.app.getStore('asset-filter');
    }

    loadSavedFilters(clientId: string): void {
        this.clientId = clientId;

        const clients = this.store.get('state') || {};

        if (clients && clients[clientId]) {
            this.filters = clients[clientId].filters || {};

            // setup search term from saved filters
            Object.entries(this.filters)
                .filter(([_, filter]) => isQuery(filter) && filter?.metaData?.isSearch)
                .forEach(([type, query]: ([string, Query])) => this.setSearchTerm(type, query.value));
        } else {
            this.filters = {};
        }
    }

    resetFilterValues() {
        this.filters = {};
        this.searchType = null;
        this.sort = {
            value: 'name',
            direction: SORT_DIRECTION.ASC,
        };
        this.offset = 0;

        this.emitChanges();
    }

    setFilter(type: FilterType, queries: Query[], logicalOperator: LogicalOperator = 'OR'): void {
        if (!type || !queries) {
            // I don't know what you want me to do...
            return;
        }

        // create a filter when we have more than one value
        if (queries.length > 1) {
            const filter: Filter = {
                queries,
                operator: logicalOperator,
            }
            this.filters[type] = filter;
            this.emitChanges();
        } else {
            // there's only one value, make a query instead
            this.setQuery(type, queries[0]);
        }
    }

    setQuery(type: FilterType, query: Query): void {
        this.filters[type] = query;
        this.emitChanges();
    }

    createQuery(type: FilterType, value: string, display: string, metaData?: QueryMetaData, operator: QueryOperator = 'eq', exact: boolean = true): Query {
        const key = this.getTypeKey(type);
        return {
            key,
            value,
            display,
            metaData,
            operator,
            exact
        };
    }

    clearFilterType(type: FilterType) {
        delete this.filters[type];
        this.emitChanges();
    }

    setSearchTerm(type: FilterType, value: string): void {
        // remove old search query
        delete this.filters[this.searchType];

        // update new search type
        this.searchType = type;
        const searchQuery = this.createQuery(type, value, value, { isSearch: true }, 'eq', false)
        this.setQuery(type, searchQuery);
    }


    setSortValue(value: string) {
        this.sort.value = value;
        this.emitChanges();
    }

    changeSortDirection() {
        if (this.sort.direction === SORT_DIRECTION.ASC) {
            this.sort.direction = SORT_DIRECTION.DESC;
        } else {
            this.sort.direction = SORT_DIRECTION.ASC;
        }

        this.emitChanges();
    }

    setLimit(value: number) {
        this.limit = value;
        this.emitChanges();
    }

    setOffset(value: number) {
        this.offset = value;
        this.emitChanges();
    }

    setPoint(value: FilterPoint) {
        this.point = value;
        this.emitChanges();
    }

    // return all necessary params to be used in an asset request
    getFilters(): FiltersResponse {
        return {
            limit: this.limit,
            sort: this.getSortString(),
            offset: this.offset,
            clientId: this.clientId,
            filters: this.filters,
            point: this.point,
        };
    }

    getFilterValueByType(type: FilterType): string {
        const match = this.filters[type];
        return (match && isQuery(match)) ? match.value : null;
    }

    getSortString(): string {
        return `${this.sort.value}:${this.sort.direction}`;
    }

    getFilterRqlString(): string {
        let str = 'state=active';
        const rql = Object.entries(this.filters)
            .filter(([_, filter]) => filter)
            .map(([_, filter]) => {
                return this.getRql(filter);
            })
            .join(',');

        if (rql) {
            str = `${str},${rql}`;
        }
        return str;
    }

    private getRql(filter: Filter | Query): string {
        if (isQuery(filter)) {
            return this.getRqlQuery(filter);
        } else {
            return this.getRqlFilter(filter);
        }
    }

    private getRqlFilter(filter: Filter): string {
        const queries = filter.queries.map(query => this.getRqlQuery(query));
        return `(${queries.join(this.getFilterSymbol(filter.operator))})`;
    }

    private getRqlQuery(query: Query): string {
        const key = query.key;
        const operator = this.getQuerySymbol(query.operator);
        const value = escapeRqlValue(query.value);
        if (!query.exact) {
            return `${key}${operator}*${value}*`;
        }
        return `${key}${operator}${value}/*`;
    }

    private getTypeKey(type: FilterType) {
        switch (type) {
            case 'assetcategory': return 'categories.id';
            case 'assetgroup': return 'groups.id';
            case 'costcentre': return 'accessGroup.id';
            case 'assettype': return 'assetType.id';
            default: return type;
        }
    }

    private getQuerySymbol(operator: QueryOperator) {
        switch (operator) {
            case 'eq': return '=';
            default: return '=';
        }
    }

    private getFilterSymbol(operator: LogicalOperator) {
        switch (operator) {
            case 'OR': return '|';
            case 'AND': return '&';
            default: return ',';
        }
    }

    private emitChanges() {
        this.storeClientFilters(this.clientId);
        this.filtersSubject.next(this.getFilters());
    }

    private storeClientFilters(clientId: string) {
        const clients = this.store.get('state') || {};

        clients[clientId] = { filters: this.filters };

        try {
            this.store.set('state', clients);
        } catch (error) {
            console.error(error);
        }
    }
}
