import { Component, Input, HostBinding, OnChanges, SimpleChanges, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
import { EventFeedItem } from 'app/shared/components/feed/feed.model';
import { TelemetryResponse, IdName } from '@key-telematics/fleet-api-client';
import { ASSET_ICON_INDEX } from 'app/shared/model';
import { getAndTranslateEventText } from 'app/shared/components/feed/event-feed/event-translation-dictionary';
import { MeasurementUnitsService, AssetService } from 'app/services';
import { FeedEntryInfoIcon } from 'app/shared/components/feed/feed-entry-info/feed-entry-info.component';
import { KeyDetailsBadge } from 'app/shared/components/cards';
import { AppService } from 'app/app.service';
import { MediaService } from 'app/services/media/media.service';
import { ModalService } from 'app/shared/components/modal';

interface ValueItem {
    title: string;
    value: string;
}

interface LinkDetails {
    title: string;
    url: string;
    target: string;
    modal: boolean;
}

@Component({
    selector: 'key-event-details-summary',
    templateUrl: 'summary.component.html',
    styleUrls: ['summary.component.scss'],
})
export class EventDetailsSummaryComponent implements OnChanges {

    asset: { id: string, name: string };
    assetBadges: KeyDetailsBadge[];

    assetType: { id: string, name: string };
    telemetry: TelemetryResponse;

    dateString: string;
    alertText: string;

    ackIcon: FeedEntryInfoIcon = { id: 'ack', name: 'check', notification: 0, tooltip: '', color: 'info' };
    commentIcon: FeedEntryInfoIcon = { id: 'comment', name: 'comment', notification: 0, tooltip: '', color: 'info' };

    hasVideoRequest = false;

    comments: {
        date: string;
        user: IdName;
        text: string;
        values: ValueItem[];
    }[];
    values: ValueItem[];
    color: string = null;

    links: LinkDetails[];

    @Input() showActions = true;
    @Input() event: EventFeedItem;
    @Output() onAcknowledgeClicked = new EventEmitter<EventFeedItem>();
    @Output() onCommentClicked = new EventEmitter<EventFeedItem>();
    @Output() onRequestVideoClicked = new EventEmitter<EventFeedItem>();

    @HostBinding('class.details-overview__summary') summaryClass = true;

    constructor(
        private app: AppService,
        private assetService: AssetService,
        private i18n: TranslateService,
        private units: MeasurementUnitsService,
        private media: MediaService,
        private modal: ModalService,
        private ref: ChangeDetectorRef
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.event && this.event) {
            this.hasVideoRequest = false;
            this.asset = this.event.details.asset;
            this.assetType = this.event.details.assetType;
            this.telemetry = this.event.details.telemetry;
            this.alertText = this.getAlertText('description');
            this.dateString = this.units.format(this.event.date, 'date', 'long').format + ', ' + this.units.format(this.event.date, 'time', 'short').format;
            this.assetBadges = [];
            this.color = null;

            this.links = (this.event.details?.links || []).filter(x => ['internal', 'external', 'download', 'modal'].includes(x.type)).map(x => ({
                title: x.title,
                url: x.url,
                target: x.type === 'internal' ? '' : '_blank',
                modal: x.type === 'modal',
            }));

            const customFields = this.app.client?.customFields?.event || [];
            const mapValue = (key: string, value: any) => ({
                title: customFields.find(x => x.id === key)?.title || key,
                value: value,
            });

            const rawValues = {};
            this.comments = this.event.comments.map(item => {
                return {
                    date: item.date,
                    user: item.user,
                    text: item.comment,
                    values: Object.keys(item.values || {}).map(key => {
                        rawValues[key] = item.values[key];
                        return mapValue(key, item.values[key]);
                    }) || [],
                };
            });
            this.values = Object.keys(rawValues).map(key => mapValue(key, rawValues[key]));

            return Promise.all([
                this.assetService.getAsset(this.asset.id).then(asset => {
                    this.color = asset?.color;
                    if (asset && asset.owner.id !== this.app.client.id) {
                        this.assetBadges.push({
                            text: asset.owner.name,
                            icon: 'home',
                            hint: this.i18n.instant('STATUS.DETAILS.SHARED_HINT', { client: asset.owner.name }),
                        });
                        this.ref.markForCheck();
                    }
                }),
                this.media.getVideoRequestSettings(this.asset.id).then(settings => {
                    this.hasVideoRequest = !!settings;
                    this.ref.markForCheck();
                }),
            ]);

        }
    }

    getAlertText(textSection: 'title' | 'description' = 'title'): string {
        return getAndTranslateEventText(textSection, this.event, this.i18n, this.units);
    }

    getIcon(): { name: string, color: string } {
        const name = (this.assetType && this.assetType.name) || 'default';
        return {
            name: ASSET_ICON_INDEX[name.toLowerCase()],
            color: this.color,
        };
    }

    ago(date: string): string {
        return moment.utc(date).fromNow();
    }

    linkClicked(event: Event, link: LinkDetails) {
        if (link.modal) {
            this.modal.iframe(link.title, link.url);
            event.stopPropagation();
            event.preventDefault();
        }
    }

}
