import { CommonModule, LOCATION_INITIALIZED } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule, Injectable } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { RouteReuseNavigationGuard } from 'app/routing/reuse-navigation-guard';
import { SimpleReuseStrategy } from 'app/routing/simple-reuse-strategy';

import { OAuthModule } from 'angular-oauth2-oidc';
import { ApolloModule } from 'apollo-angular';

import {
    AssetGroupingService,
    AssetService,
    GoogleAnalyticsService,
    MeasurementUnitsService,
    MobileNavigationActionsService,
    NotificationService,
    ErrorLoggerService,
    DashboardsService,
    AssetStateService
} from 'app/services';
import { AuthService } from 'app/services/auth/auth.service';
import { ClientService } from 'app/services/client/client.service';
import { NavigationService } from 'app/services/navigation/navigation.service';


import { environment } from 'environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';

import { ErrorLoggerHandler } from './shared/utils/error-logger-handler';
import { InitializeFeatureDefaults } from './app.features';
import { ImageService } from './services/images/image.service';
import { EntityService } from './services/entity/entity.service';
import { TopNavService } from './key-ui/top-nav';
import { KeyTranslateLoader } from './services/translate/translate-loader';
import { UserNotificationService } from './services/user-notification/user-notification.service';
import { ExternalPageModule } from './pages/external/external.module';
import { StaticPageModule } from './shared/components/static-page/static-page.module';
import { MatchMediaServiceModule } from './services/match-media/match-media.module';
import { MediaService } from './services/media/media.service';
import { DecimalDegreesToDegreesPipe } from './shared/pipes';
import { MapSearchService } from './shared/components/mapsearch';
import { ReportingService } from './shared/components/reports';
import { CacheModule } from 'ionic-cache';
import { IonicStorageModule } from '@ionic/storage-angular';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new KeyTranslateLoader(http, '/assets/i18n/', `.json?v=${environment.version}`);
}

export function LoadAppServiceConfiguration(app: AppService): () => Promise<void> {
    return () => app.loadConfiguration().then(async () => {
        await app.getDomainTheme(document.location.hostname);
        InitializeFeatureDefaults();
    });
}

// load our translation files BEFORE the application is intialized, or we're gonna have half baked translations
export function LoadTranslations(translate: TranslateService, injector: Injector): () => Promise<void> {
    return () => new Promise<any>((resolve: any) => {
        const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
        locationInitialized.then(() => {
            translate.setDefaultLang('en');
            translate.use('en').subscribe(() => {
                const languageEndpoint = KeyTranslateLoader.getLanguageEndpoint();
                if (languageEndpoint) {
                    translate.use(languageEndpoint.lang).subscribe(
                        () => { resolve(null); },
                        _error => { resolve(null); }
                    );
                } else {
                    resolve(null);
                }
            });
        });
    });
}

// this fixes an iOS bug where horizontal panning breaks vertical scrolling
@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
    overrides = <any>{
        'pinch': { enable: false },
        'rotate': { enable: false },
        'pan': {
            threshold: 200,
        },
    };
}


@NgModule({
    declarations: [AppComponent],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        ApolloModule,
        AppRoutingModule,
        OAuthModule.forRoot(),

        /**
         * I18n Translation
         *
         * Uses HTTP loader and searches for translation files at "/assets/i18n/{lang}.json",
         * where {lang} is the two-letter language code.
         */
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),

        StaticPageModule,
        ExternalPageModule,
        MatchMediaServiceModule,
        IonicStorageModule.forRoot(),
        CacheModule.forRoot({
            keyPrefix: 'key-app-cache-',
        }),
    ],
    providers: [

        // Add global error handler
        { provide: ErrorHandler, useClass: ErrorLoggerHandler },
        { provide: RouteReuseStrategy, useClass: SimpleReuseStrategy },
        { provide: APP_INITIALIZER, useFactory: LoadAppServiceConfiguration, deps: [AppService], multi: true },
        { provide: APP_INITIALIZER, useFactory: LoadTranslations, deps: [TranslateService, Injector], multi: true },
        { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },

        AppService,
        AuthService,
        ClientService,
        NavigationService,
        NotificationService,
        RouteReuseNavigationGuard,
        AssetService,
        AssetStateService,
        AssetGroupingService,
        ImageService,
        TopNavService,
        MediaService,
        ReportingService,
        MapSearchService,
        MeasurementUnitsService,
        MobileNavigationActionsService,
        GoogleAnalyticsService,
        ErrorLoggerService,
        DashboardsService,
        EntityService,
        UserNotificationService,
        DecimalDegreesToDegreesPipe,
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
