<kui-modal size='md'
    class="details-modal"
    [title]="title"
    [loading]="loading"
    (onClose)="detachModal()"
    [actions]="modalActions">
    <div class="p-4 mx-2 d-flex"
        style="min-height: 350px">
        <div class="pr-4"
            *ngIf="!isMobile && (icons || errorMessage)">
            <kui-icon [name]="errorMessage ? 'ban' : icons[index]"
                [fixedWidth]="true"
                size="xxl"></kui-icon>
        </div>
        <div class="flex-1">
            <div *ngFor="let form of forms; let i = index"
                [class.hidden]="i != index || errorMessage">
                <key-form-builder *ngIf="i === index"
                    id="form_{{i}}"
                    [errors]="errors[i]"
                    [form]="form"
                    [values]="values[i]"></key-form-builder>
            </div>

            <div [class.hidden]="errorMessage || !forms || index < forms.length">
                {{ 'ADMIN.MODALS.WIZARD.COMPLETION_TEXT' | translate:{ entity: entityName } }}
                <div class="p-3">
                    <table>
                        <tr *ngFor="let item of changes">
                            <td class='name'>
                                {{ item.name }}
                            </td>
                            <td class='value'>
                                {{ item.value }}
                            </td>
                        </tr>
                    </table>
                </div>
                {{ 'ADMIN.MODALS.WIZARD.FINISH_PROMPT' | translate:{ entity: entityName } }}
            </div>
            <div *ngIf="errorMessage">
                <b>{{ errorMessage }}</b>
                <div class="p-3">
                    <table>
                        <tr *ngFor="let item of changes">
                            <td class='name'>
                                {{ item.name }}
                            </td>
                            <td class='value text-danger'>
                                {{ item.value }}
                            </td>
                        </tr>
                    </table>
                </div>
                {{ 'ADMIN.MODALS.WIZARD.ERROR_PROMPT' | translate }}

            </div>

        </div>
    </div>
</kui-modal>