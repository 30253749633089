import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { IKuiModalAction } from 'app/key-ui';
import { TranslateService } from '@ngx-translate/core';
import { ReportingService } from 'app/shared/components/reports';
import { KuiModalComponent } from 'app/key-ui/modal/modal.component';
import { CompletedReportResponse, AnalyticsOutputSettings } from '@key-telematics/fleet-api-client';
import { AvailableSettings } from 'app/shared/components/analytics';

@Component({
    selector: 'key-view-report-modal',
    templateUrl: 'view-report.component.html',
    styleUrls: ['view-report.component.scss'],
})
export class DashboardViewReportModalComponent implements OnInit {
    actions: IKuiModalAction[];
    loading = true;
    visibleSettings: AvailableSettings;
    report: CompletedReportResponse;
    showAnalyticsSettings: boolean;
    shouldUpdateParent: boolean;

    @Input() id: string;
    @Input() settings: AnalyticsOutputSettings;
    @Input() isMobile: string;
    @Output() onClose = new EventEmitter<{ shouldUpdateParent: boolean }>();
    @ViewChild(KuiModalComponent, { static: true }) modal: KuiModalComponent;

    constructor(
        private i18n: TranslateService,
        private reportingService: ReportingService
    ) {
    }

    ngOnInit() {
        this.showAnalyticsSettings = !this.isMobile;
        this.setVisibleWidgetSettings(!this.isMobile);
        this.modal.open();
        this.actions = [
            ...(this.isMobile ? [{
                icon: 'cog',
                hideTextOnMobile: true,
                action: () => {
                    this.showAnalyticsSettings = !this.showAnalyticsSettings;
                    this.setVisibleWidgetSettings(!this.showAnalyticsSettings);
                    return true;
                },
            }] : []),
            {
                text: this.i18n.instant('DIALOG.CLOSE'),
                style: 'secondary',
                keypress: 27,
            }];
        this.load();
    }

    async load() {
        try {
            const report = await this.reportingService.getCompletedReport(this.id, false);
            if (this.settings) {
                report.outputOptions.charts[0] = {
                    ...report.outputOptions.charts[0],
                    ...this.settings,
                };
            }
            this.report = report;
            this.loading = false;
        } catch (error) {
            // on error close the modal so that you can see the error
            this.detachModal();
            throw error;
        }
    }
    
    handleSharedWidget(close: boolean) {
        this.shouldUpdateParent = true;
        if (close) {
            this.detachModal();
        }
    }

    detachModal() {
        this.modal.close();
        this.onClose.emit({ shouldUpdateParent: this.shouldUpdateParent });
    }
    
    setVisibleWidgetSettings(show: boolean) {
        this.visibleSettings = {
            daterange: show,
            flipped: show,
            legend: show,
            levels: show,
            measures: show,
            presets: false,
            series: show,
            sort: show,
            chart: show,
            stacked: show,
            yAxes: show,
            xAxis: show,
            pie: show,
            stat: show,
            dataLabels: show,
            filters: show,
            calcMeasures: false,
        };
    }
}
