import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { KuiModalRefComponent } from 'app/key-ui/modal/modal-ref.component';
import { IKuiModalAction } from 'app/key-ui';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilderDefinition, KeyFormBuilderComponent } from 'app/shared/components/form-builder';
import { FormSectionHandler, EditorHandler } from '../entities/entity.handler';
import { MatchMediaService, ErrorLoggerService, NotificationService } from 'app/services';
import { Subscription } from 'rxjs';
import { ERRORS } from 'app/shared/model/errors.model';



@Component({
    templateUrl: 'wizard-modal.component.html',
    styleUrls: ['wizard-modal.component.scss'],
})
export class AdminWizardModalComponent extends KuiModalRefComponent<{
    ownerId: string,
    handler: Pick<EditorHandler, 'getCreationSections' | 'createEntity'>,
}> implements OnInit, OnDestroy {

    constructor(
        private matchMedia: MatchMediaService,
        private error: ErrorLoggerService,
        private notify: NotificationService,
        private i18n: TranslateService
    ) {
        super();
        this.subscriptions = [
            this.matchMedia.isMobile.subscribe(isMobile => { this.isMobile = isMobile; }),
        ];
    }

    formBuilder: KeyFormBuilderComponent;
    @ViewChild(KeyFormBuilderComponent) set setCurrentFormBuilder(content: KeyFormBuilderComponent) {
        this.formBuilder = content;
    }

    subscriptions: Subscription[];
    isMobile: boolean;
    loading = true;
    title = this.i18n.instant('ADMIN.MODALS.WIZARD.TITLE_LOADING');
    entityName: string;
    modalActions: IKuiModalAction[];

    sections: FormSectionHandler<any, any>[];
    forms: FormBuilderDefinition[];

    errors: { [field: string]: string }[] = [];
    errorMessage: string;

    _index = -1;
    get index() {
        return this._index;
    }
    set index(value: number) {
        this._index = Math.min(this.length, Math.max(0, value));
        this.modalActions = [
            this.buttons.cancel,
            this._index > 0 && this.buttons.back,
            this._index < this.length && this.buttons.next,
            this._index === this.length && this.buttons.finish,
        ].filter(x => x);
    }

    length = 0;
    entity: any;
    values: any[];
    icons: string[];
    changes: { name: string, value: string }[] = [];
    buttons: { [key: string]: IKuiModalAction } = {
        cancel: {
            text: this.i18n.instant('DIALOG.CANCEL'),
            style: 'secondary',
            keypress: 27,
            action: () => {
                this.actions.close();
            },
        },
        back: {
            text: this.i18n.instant('DIALOG.BACK'),
            style: 'secondary',
            action: () => {
                this.prevPage();
                return true;
            },
        },
        next: {
            text: this.i18n.instant('DIALOG.NEXT'),
            style: 'primary',
            // keypress: 13, -- do not capture enter as it messes with the controls on the page
            action: () => {
                this.nextPage();
                return true;
            },
        },
        finish: {
            text: this.i18n.instant('DIALOG.FINISH'),
            style: 'primary',
            // keypress: 13, -- do not capture enter as it messes with the controls on the page
            action: () => {
                this.save();
                return true;
            },
        },
    };


    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    ngOnInit() {

        this.loading = true;
        this.modalActions = [this.buttons.cancel];
        this.data.handler.getCreationSections(this.data.ownerId).then(result => {
            this.sections = result.sections;
            this.entityName = this.i18n.instant('ADMIN.ENTITIES.' + result.entityType.toUpperCase());
            this.title = this.i18n.instant('ADMIN.MODALS.WIZARD.TITLE', { entity: this.entityName });
            this.icons = [...this.sections.map(x => x.icon), 'check'];
            this.entity = result.entity;
            return Promise.all(this.sections.map(section => section.mapEntityToEditor(this.entity))).then(values => {
                this.length = this.sections.length;
                this.values = values;
                this.errors = new Array(this.length);
                this.forms = new Array(this.length);
                this.loading = false;
                this.nextPage();
            });
        }).catch(err => {
            this.showError(err);
        });

    }

    async nextPage() {
        try {
            if (this.index >= 0) {
                if (!this.formBuilder.validate()) {
                    return;
                }
                // save the values from the previous page to the entity
                this.loading = true;
                await this.sections[this.index].mapEditorToEntity(this.entity, this.values[this.index]);
                this.loading = false;
            }
            this.index = Math.min(this.length, this.index + 1);
            if (this.index < this.length) {
                this.buildForm(this.index);
            } else {
                this.errorMessage = null;
                this.errors = new Array(this.length);
                this.changes = [];
                this.forms.forEach((form, index) => {
                    form.groups.forEach(group => {
                        group.fields.forEach(field => {
                            const value = field.getText ? field.getText(field, this.values[index]) : this.values[index][field.id];
                            if (value) {
                                this.changes.push({ name: field.title, value: value });
                            }
                        });
                    });
                });
            }
        } catch (err) {
            this.showError(err);
        }
    }

    prevPage() {
        this.index = Math.max(0, this.index - 1);
        this.errorMessage = null;
        this.buildForm(this.index);
    }

    buildForm(index: number): void {
        // forms need to be dynamically rebuilt each time as their content may depend on
        // fields selected in previous pages
        this.loading = true;
        const section = this.sections[index];
        section.getFields(this.entity).then(fields => {
            this.forms[index] = {
                groups: [{
                    name: null, // section.title,
                    description: section.description,
                    fields: fields,
                }],
            };
            this.loading = false;
        }).catch(err => {
            this.showError(err);
        });
    }

    detachModal() {
        this.actions.close();
    }


    save() {
        this.loading = true;
        this.errorMessage = null;
        this.errors = new Array(this.length);
        this.data.handler.createEntity(this.data.ownerId, this.entity).then(result => {
            this.actions.apply(result);
            this.loading = false;
        }).catch(err => {
            this.showError(err);
        });
    }

    showError(error: any) {
        this.loading = false;
        this.changes = [];
        this.errors = new Array(this.length);

        if (error && error.name === ERRORS.VALIDATE_ERROR && error.data && error.data.fields) {

            const errors = Object.keys(error.data.fields).reduce((result, field) => {
                result[field] = error.data.fields[field].message;
                return result;
            }, {});

            this.errorMessage = this.i18n.instant('ADMIN.MODALS.WIZARD.VALIDATION_ERRORS');

            this.forms.forEach((form, index) => {
                this.errors[index] = {};
                form.groups.forEach(group => {
                    group.fields.forEach(field => {
                        if (errors[field.id]) {
                            this.errors[index][field.id] = errors[field.id];
                            this.changes.push({ name: field.title, value: errors[field.id] });
                            delete errors[field.id];
                        }
                    });
                });
                if (this.sections[index].mapValidationErrors) {
                    this.errors[index] = this.sections[index].mapValidationErrors(this.errors[index]);
                }
            });
            // also show errors that can't be mapped to known fields
            Object.keys(errors).forEach(key => {
                this.changes.push({ name: key, value: errors[key] });
            });

        } else {
            this.error.trackException(error, [ERRORS.FORBIDDEN_ERROR, ERRORS.BAD_REQUEST_ERROR]);
            this.errorMessage = error && this.notify.translateError(error);
        }

    }

}
