import { Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AppService } from 'app/app.service';
import { AssetFilterService } from 'app/services';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { BaseComponent } from '../../base/base.component';
import {
    MapOption,
    MapOptionLayer,
    MapOptionLayerComponent,
    MapOptionMeasurementsComponent,
    MapOptionPointInformationComponent,
    MapOptionRoutingComponent,
    MapOptionService,
    MapOptionSimpleZoneEditorComponent,
    MapOptionZoneConfig,
    MapOptionZoneEditorComponent,
    MapOptionZoneSelectorComponent,
    MapOptionZonesComponent,
    ZoneEditorConfig,
    ZoneSelectorService
} from '../map-options';
import { MapOptionRoutesComponent } from '../map-options/components/map-option-routes';
import { MapZonesService } from '../map-zones.service';
import { MapControls } from '../map.component';


@Component({
    selector: 'key-map-toolbar',
    templateUrl: './map-toolbar.component.html',
    styleUrls: ['./map-toolbar.component.scss'],
})
export class MapToolbarComponent extends BaseComponent implements OnChanges, OnInit, OnDestroy {

    constructor(
        private appService: AppService,
        private filterService: AssetFilterService,
        private mapOptionService: MapOptionService,
        private zoneSelectorService: ZoneSelectorService,
        private mapZonesService: MapZonesService,
    ) {
        super();
    }

    controlsIndex: {
        zoom?: boolean;
        recenter?: boolean;
        fullscreen?: boolean;
        zoneedit?: boolean;
        zonedelete?: boolean;
        zoneadd?: boolean;
        simplezoneedit?: boolean;
        layers?: boolean;
        measurements?: boolean;
        googlemaps?: boolean;
        search?: boolean;
        coordinates?: boolean;
        routing?: boolean;
        findclosest?: boolean;
        mapsearch?: boolean;
        labels?: boolean;
    } = {};

    zoneOptionConfig: MapOptionZoneConfig;
    canToggle = true;
    current$: Observable<MapOption<any>>;
    customOptions: MapOption<any>[] = [];
    current: MapOption<any>;

    @HostBinding('class.expanded') expanded = false;
    @HostBinding('class.compact') compact: boolean;

    @Input() mapHeight: number;
    @Input() controls: MapControls[];
    @Input() baseLayers: MapOptionLayer[];
    @Input() overlays: MapOptionLayer[];
    @Input() isFullscreen: boolean;
    @Input() zonesEditable: boolean;
    @Input() supportsMarkerClustering: boolean;

    @Output() zoomIn = new EventEmitter<void>();
    @Output() zoomOut = new EventEmitter<void>();
    @Output() reset = new EventEmitter<void>();
    @Output() enterFullscreen = new EventEmitter<void>();
    @Output() exitFullscreen = new EventEmitter<void>();
    @Output() toolbarToggled = new EventEmitter<boolean>();

    ngOnInit(): void {
        this.current$ = this.mapOptionService.current$;
        this.on(this.mapOptionService.close$, _ => {
            this.toggle(false);
        });

        this.on(this.appService.client$, _client => {
            // user has changed client, reset toolbar
            this.toggle(false);
            this.mapOptionService.clear();
        });

        // when a user double clicks on a zone, show the edit option when applicable.
        this.on(this.mapZonesService.zoneDoubleClicked$.pipe(
                withLatestFrom(this.current$),
                filter(([_, currentOption]) => this.controlsIndex.zoneedit && ![MapOptionZoneSelectorComponent.ID, MapOptionRoutingComponent.ID].includes(currentOption?.id)),
                map(([event, _]) => event)
            ),
            event => {
                const isRoute = event?.options?.type === 'route';
                const editOption: MapOption<ZoneEditorConfig> = {
                    component: MapOptionZoneEditorComponent,
                    data: {
                        editorAction: 'zoneedit',
                        zoneId: event.id,
                    },
                    id: MapOptionZoneEditorComponent.ID,
                    title: isRoute ? 'LEAFLET.ZONES.EDIT_ROUTE' : 'LEAFLET.ZONES.EDIT_ZONE',
                    icon: MapOptionZoneEditorComponent.ICON
                };
                this.toggle(true);
                this.mapOptionService.load(editOption);
            }
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.mapHeight) {
            this.compact = this.mapHeight <= 420;
            this.mapOptionService.compact = this.compact;
        }

        if (this.controls) {
            this.addControlsToIndex(this.controls);
            this.canToggle = this.showToggle(this.controls);
            this.configureMapOptions();
        }

        if (changes.overlays) {
            this.zoneSelectorService.setLayerOverlays(this.overlays);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeAll();
    }

    showToggle(controls: MapControls[]): boolean {
        const checkList = [
            MapControls.zoneadd,
            MapControls.zonedelete,
            MapControls.zoneedit,
            MapControls.simplezoneedit,
            MapControls.layers,
            MapControls.measurements,
            MapControls.googlemaps,
            MapControls.search,
            MapControls.coordinates,
        ];
        return controls.some(x => checkList.includes(x));
    }

    addControlsToIndex(controls: MapControls[]) {
        this.controlsIndex = controls.reduce((result, control) => {
            result[control] = controls.includes(control);
            return result;
        }, {});
    }

    toggle(state: boolean) {
        this.expanded = state;
        if (!this.expanded) {
            this.mapOptionService.clear();
            this.filterService.setPoint(null);
        }
        this.toolbarToggled.emit(state);
    }

    configureMapOptions() {
        const showZones = this.controlsIndex.zoneedit || this.controlsIndex.zoneadd || this.controlsIndex.zonedelete;

        this.customOptions = [
            this.controlsIndex.layers && {
                component: MapOptionLayerComponent,
                data: {
                    baseLayers: this.baseLayers,
                    overlays: this.overlays,
                    supportsMarkerClustering: this.supportsMarkerClustering,
                    showLabelsToggle: this.controlsIndex.labels
                },
                id: MapOptionLayerComponent.ID,
                title: MapOptionLayerComponent.TITLE,
                icon: MapOptionLayerComponent.ICON
            },
            showZones && {
                component: MapOptionZonesComponent,
                data: {
                    zoneAdd: this.controlsIndex.zoneadd,
                    zoneEdit: this.controlsIndex.zoneedit,
                    zoneDelete: this.controlsIndex.zonedelete
                },
                id: MapOptionZonesComponent.ID,
                title: MapOptionZonesComponent.TITLE,
                icon: MapOptionZonesComponent.ICON
            },
            showZones && {
                component: MapOptionRoutesComponent,
                data: {
                    zoneAdd: this.controlsIndex.zoneadd,
                    zoneEdit: this.controlsIndex.zoneedit,
                    zoneDelete: this.controlsIndex.zonedelete
                },
                id: MapOptionRoutesComponent.ID,
                title: MapOptionRoutesComponent.TITLE,
                icon: MapOptionRoutesComponent.ICON
            },
            this.controlsIndex.simplezoneedit && {
                component: MapOptionSimpleZoneEditorComponent,
                data: undefined,
                id: MapOptionSimpleZoneEditorComponent.ID,
                title: 'SHARED.EDIT',
                icon: MapOptionSimpleZoneEditorComponent.ICON
            },
            this.controlsIndex.measurements && {
                component: MapOptionMeasurementsComponent,
                data: undefined,
                id: MapOptionMeasurementsComponent.ID,
                title: MapOptionMeasurementsComponent.TITLE,
                icon: MapOptionMeasurementsComponent.ICON,
            },
            this.controlsIndex.coordinates && {
                component: MapOptionPointInformationComponent,
                data: {
                    hideCrosshair: false,
                    findclosest: this.controlsIndex.findclosest,
                    googlemaps: this.controlsIndex.googlemaps,
                    mapsearch: this.controlsIndex.mapsearch,
                    routing: this.controlsIndex.routing,
                },
                id: MapOptionPointInformationComponent.ID,
                title: MapOptionPointInformationComponent.TITLE,
                icon: MapOptionPointInformationComponent.ICON
            },
        ].filter(x => x);
    }

    onBack() {
        this.mapOptionService.back();
    }
}
