<ng-container *ngIf="{ 
    item: selectedSearchItem$ | async,
    term: term$ | async,
    searching: searching$ | async,
    routing: routing
} as search">
    <kui-input-group [cleanAddons]="true">
        <input kui-input
            #searchInput
            type="text"
            [placeholder]="'SHARED.SEARCH_ELLIPSES' | translate"
            [ngModel]="term$ | async"
            (ngModelChange)="updateSearchTerm($event)"
            (focus)="onInputFocus(search.term)">
        <kui-icon
            *ngIf="!search.searching && !search.term"
            kui-input-group-addon
            name="search"
            [size]="1"></kui-icon>
        <kui-icon
            *ngIf="search.item && search.routing"
            kui-input-group-addon
            kui-action
            class="pr-2"
            color="primary"
            name="directions"
            [title]="'LEAFLET.ROUTING.GET_ROUTE' | translate"
            (click)="openRouting({
                searchTerm: search.term,
                address: search.item.name,
                lat: search.item.lat,
                lon: search.item.lon
            })"></kui-icon>
        <kui-icon
            *ngIf="!search.searching && !!search.term"
            kui-input-group-addon
            kui-action
            name="times"
            class="pl-2"
            [title]="'DIALOG.CLEAR' | translate"
            (click)="clearSearch()"
            [size]="1"></kui-icon>
        <kui-loader *ngIf="search.searching"
            kui-input-group-addon
            size="small"></kui-loader>
    </kui-input-group>
</ng-container>