import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KuiModule } from 'app/key-ui';
import { OptionsSidebarComponent } from './options-sidebar.component';
import { OptionsSidebarCardComponent } from './options-sidebar-card.component';

const COMPONENTS = [
  OptionsSidebarComponent,
  OptionsSidebarCardComponent,
];

@NgModule({
  imports: [CommonModule, KuiModule],
  exports: COMPONENTS,
  declarations: COMPONENTS,
  providers: [],
})
export class KeyOptionsSidebarModule {}
