import { Router } from '@angular/router';
import { Component, AfterViewInit, Input, ChangeDetectionStrategy, ViewChild, OnDestroy, OnInit, SimpleChanges, Output, EventEmitter, OnChanges } from '@angular/core';
import { BaseComponent } from 'app/shared/components/base/base.component';
import { MapComponent, MapControls } from 'app/shared/components/map/map.component';
import { MapZonesService } from 'app/shared/components/map/map-zones.service';
import { EventFeedItem } from '../../../feed.model';
import { getAndTranslateEventText, getEventLinkedMarkers } from 'app/shared/components/feed/event-feed/event-translation-dictionary';
import { TranslateService } from '@ngx-translate/core';
import { MeasurementUnitsService } from 'app/services/measurement-units/measurement-units.service';

@Component({
    selector: 'key-event-map',
    templateUrl: './map.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MapZonesService],
})
export class EventDetailsMapComponent extends BaseComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {

    empty = true;
    mapControls = [
        MapControls.zoom,
        MapControls.fullscreen,
        MapControls.recenter,
        MapControls.zoneedit,
        MapControls.zonedelete,
        MapControls.zoneadd,
        MapControls.googlemaps,
        MapControls.layers,
        MapControls.measurements,
        MapControls.search,
        MapControls.labels
    ];

    @ViewChild('map', { static: true }) map: MapComponent;

    @Input() selectedEvent: EventFeedItem;
    @Input() events: EventFeedItem[];

    @Input() isMobile: boolean;

    @Output() onEventSelected = new EventEmitter<EventFeedItem>();

    constructor(
        public router: Router,
        public zonesService: MapZonesService,
        private i18n: TranslateService,
        private units: MeasurementUnitsService
    ) {
        super();
    }

    ngOnInit() {

    }

    ngOnDestroy() {
        this.zonesService.detachMap();
    }


    ngAfterViewInit() {
        this.zonesService.attachMap(this.map);
        setTimeout(() => {
            this.map.invalidate(); // make sure the map redraws itself once the DOM has settled
        }, 100);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedEvent) {
            setTimeout(() => {
                if (this.isMobile) {
                    this.reset(); // mobile shows only the selected event
                }
                this.updateMarker(this.selectedEvent, true);
            });
        }
        if (changes.events) {
            setTimeout(() => {

                if (this.events && this.events.length > 0) {
                    this.events.forEach(event => this.updateMarker(event, false));
                    if (!this.selectedEvent && this.empty) {
                        setTimeout(() => this.map.zoomToBounds(this.map.getMarkerBounds()), 100); // 100ms as per ferdi's calculations
                    }
                } else {
                    this.map.clearMarkers();
                }
                this.map.invalidate();
                this.empty = false;
            });
        }
    }

    resize() {
        this.map.invalidate();
    }

    reset() {
        this.events = [];
        this.map.clearMarkers();
        this.map.invalidate();
        this.empty = true;
    }

    getIconHtml(event: EventFeedItem, focused: boolean = false): { size: number, html: string } {
        const iconSize = 30;
        const alertType: { [key: string]: { classes: string, shape: 'circle' | 'triangle' } } = {
            low: { classes: 'icon-info-circle text-success', shape: 'circle' },
            medium: { classes: 'icon-exclamation-circle text-warning', shape: 'circle' },
            high: { classes: 'icon-exclamation-triangle text-danger', shape: 'triangle' },
        };

        const type = alertType[event.alerts && event.alerts[0] && event.alerts[0].type || 'low'];

        return {
            size: iconSize,
            html: `
                <div class="map-font-icon ${focused ? 'selected' : ''}">
                    <i class="icon icon__fill ${type.classes}"></i>
                    <i class="icon icon__fill icon-${type.shape} map-font-icon__background map-font-icon__shadows"></i>
                </div>
            `,
        };

    }

    updateMarker(event: EventFeedItem, selected: boolean) {
        if (event && event.details && event.details.telemetry && event.details.telemetry.location) {
            const asset = (event.details.asset && event.details.asset.name) || '';
            const lat = event.details.telemetry.location.lat;
            const lon = event.details.telemetry.location.lon;
            this.map.setMarker({
                id: event.id,
                name: [asset, event.alerts[0] ? event.alerts[0].name : this.getEventText()].join(' - '),
                lat: lat,
                lon: lon,
                links: getEventLinkedMarkers(event),
                click: () => {
                    this.onEventSelected.emit(event);
                },
                getIcon: (isSelected: boolean) => {
                    return this.getIconHtml(event, isSelected);
                },
            });
            if (selected) {
                const z = Math.max(this.map.getZoom(), 15);
                this.map.selectMarker(event.id, true, z);
            }
        }
    }

    getEventText(): string {
        return getAndTranslateEventText('title', this.selectedEvent, this.i18n, this.units);
    }

}
