import { Component, Input, HostBinding, Output, EventEmitter, OnChanges, OnInit } from '@angular/core';
import { AnalyticsChartStatData, AnalyticsChartStatDataType, AnalyticsChartStatService, AnalyticsChartStatTrendType } from './stat.service';
import { Dataset, DataPoint, Average, YAxis, AXIS_ID } from 'app/shared/components/graph/graph.model';
import * as Color from 'color';
import { TranslateService } from '@ngx-translate/core';

interface Trend {
    icon: string;
    value: string;
    color: string;
    visible: boolean;
}

@Component({
    selector: 'key-analytics-chart-stat',
    templateUrl: './stat.component.html',
    styleUrls: ['./stat.component.scss'],
})
export class AnalyticsChartStatComponent implements OnChanges, OnInit {
    dataset: Dataset;
    stat: string;
    trend: Trend;
    sparklineAverage: Average;
    sparklineYAxis: YAxis;
    translatedDataType: { [type: string]: string };
    errorMessage: string;

    @Input() data: AnalyticsChartStatData;
    @Input() size: string;

    @Output() onMeasureSelection = new EventEmitter<string>();

    @HostBinding('class') hostClassnames = 'd-flex flex-column flex-1 position-relative';

    constructor(
        private chartService: AnalyticsChartStatService,
        private i18n: TranslateService
    ) {
    }

    ngOnInit() {
        const translate = (str: string) => this.i18n.instant(`ANALYTICS.CHART.${str.toUpperCase()}`);
        this.translatedDataType = {
            average: translate('average'),
            total: translate('total'),
            first: translate('first'),
            last: translate('last'),
        };
    }

    ngOnChanges() {
        this.errorMessage = null;

        if (this.data) {
            const { datasets, key, color, data, trend } = this.data;
            this.dataset = this.getDataset(datasets, key, color);
            if (this.dataset) {
                this.stat = this.getStatValue(data, this.dataset && this.dataset.data);
                this.trend = this.getTrend(this.dataset && this.dataset.data, trend);
                this.sparklineAverage = {
                    color: Color(color).fade(.7).string(),
                    value: this.chartService.getAverageFromData(this.dataset && this.dataset.data),
                    axis: AXIS_ID.Y_LEFT,
                };
                this.sparklineYAxis = {
                    hidden: true,
                    // add double the padding of sparline width so that it doesn't cut off
                    max: Math.max(...this.dataset.data.map(x => x.raw)) + 2,
                    min: Math.min(...this.dataset.data.map(x => x.raw)) - 2,
                };
            }
        }
    }

    getDataset(datasets: Dataset[], key: string, color: string): Dataset {
        const dataset = datasets.find(x => x.uniquename === key) || datasets[0];
        if (!dataset) {
            this.errorMessage = this.i18n.instant('ANALYTICS.ERRORS.NO_RESULTS');
            return null;
        }
        return {
            ...dataset,
            yAxis: AXIS_ID.Y_LEFT,
            color,
            type: 'line',
            fill: false,
        };
    }

    getInvertedColor(color): string {
        if (Color(color).luminosity() === 1) {
            return '#666666';
        }
        return Color(color).isDark() ? '#ffffff' : '#333333';
    }

    getTrend(data: DataPoint[], type: AnalyticsChartStatTrendType): Trend {
        const value = this.chartService.getTrendValue(data, type === 'percentage');
        const result = {
            icon: 'caret-up',
            color: 'success',
            value: Math.abs(value) + (type === 'percentage' ? '%' : ''),
            visible: type !== 'none',
        };

        if (value < 0) {
            result.icon = 'caret-down';
            result.color = 'danger';
        }

        return result;
    }

    getStatValue(type: AnalyticsChartStatDataType, data: DataPoint[]): string {
        switch (type) {
            case 'first':
                return data[0].value;
            case 'last':
                return data[data.length - 1].value;
            case 'average':
                const [refenceAverage] = data.filter(data => data.raw !== 0) || data;
                return this.chartService.convertRawToValue(
                    this.chartService.getAverageFromData(data), refenceAverage?.value
                );
            case 'total':
                const [referenceTotal] = data.filter(data => data.raw !== 0) || data;
                return this.chartService.convertRawToValue(
                    this.chartService.getTotalFromData(data), referenceTotal?.value
                );
        }
    }
}
