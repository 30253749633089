import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { SelectionService } from './selection.service';
import { SelectionItem, EntityResult, EntityListOptions } from '../selection.model';
import { AuthService } from 'app/services';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AccountSelectionService extends SelectionService<SelectionItem>  {

    constructor(public app: AppService, private i18n: TranslateService, public authService: AuthService) {
        super(app);
    }

    private items: SelectionItem[] = [];

    async getEntityList(_id: string, { offset, limit, filter }: EntityListOptions): Promise<EntityResult<SelectionItem>> {
        this.items = this.authService.users.filter(x => {
            return (!filter || x.name.toLowerCase().includes(filter) || x.companyName.toLowerCase().includes(filter));
        }).map(x => this.mapUser(x));

        const total = this.items.length;

        this.items = this.items.slice(offset, offset + limit);

        return {
            items: this.items,
            total: total,
        };

    }

    async getEntity(id: string): Promise<SelectionItem> {
        return this.items.find(x => x.id === id);
    }

    private mapUser(item: { companyId: string, companyName: string, companyType: string, id: string, name?: string }): SelectionItem {
        return {
            ownerId: item.companyId,
            id: item.id,
            name: item.name,
            avatarPath: `${this.app.env.apiEndpoint}/accounts/users/${item.id}/avatar`,
            description: `${this.i18n.instant(`ADMIN.ENTITIES.${item.companyType.toUpperCase()}`)}: ${item.companyName}`,
        };
    }

    getDefaultEntityOptions(): EntityListOptions {
        return {
            offset: 0,
            limit: 10,
            sort: 'name:asc',
            filter: '',
        };
    }

    getFilterRqlString(value?: string): string {
        return value;
    }
}
