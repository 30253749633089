import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormBuilderField, BaseFormBuilderFieldComponent, FormBuilderAction } from 'app/shared/components/form-builder';
import { KuiDropdownComponent } from 'app/key-ui/dropdown/dropdown.component';
import { KuiTreeSelectNode } from 'app/key-ui/tree-select/tree-select.component';
import { get, set } from 'lodash';

export interface ListFieldValue<T> {
    id?: string;
    name?: string;
    icon?: string;
    color?: string; // color of the icon
    description?: string;
    data?: T;
    modified?: boolean;
}

@Component({
    selector: 'key-form-builder-list-field',
    templateUrl: './list.component.html',
    styleUrls: ['list.component.scss'],
})
export class KeyFormBuilderListFieldComponent implements BaseFormBuilderFieldComponent, OnInit {

    @Input() field: FormBuilderField;
    @Input() values: { [key: string]: any };
    @Input() error: string; // set this error value externally to have the default error highligh and display kick in

    @Output() onChange: EventEmitter<{ value: { [key: string]: any }, dirty: boolean }> = new EventEmitter();

    touched = false;

    get items(): ListFieldValue<any>[] {
        return this.field.getValue ? this.field.getValue(this.field, this.values) : get(this.values, this.field.id, undefined);
    }
    set items(val: ListFieldValue<any>[]) {
        if (this.field.setValue) {
            const promise = this.field.setValue(this.field, this.values, val);
            if (promise && promise.then) {
                promise.then(() => this.ref.markForCheck());
            }
        } else {
            set(this.values, this.field.id, val);
        }
    }

    constructor(private ref: ChangeDetectorRef) { }

    validate(): boolean {
        this.touched = true;
        this.ref.markForCheck();
        return !this.field.required || this.items.length > 0;
    }

    ngOnInit() {
        this.values[this.field.id] = this.values[this.field.id] || this.field.value || [];
    }

    async deleteItem(item: ListFieldValue<any>) {
        const values = [...this.items];
        const idx = values.findIndex(({ id }) => item.id === id);
        if (idx > -1) {
            const action = this.field.actions.find(x => x.id === 'delete');
            if (!action || await action.click('delete', this.field, this.values, item)) {
                values.splice(idx, 1);
                this.items = values;
                this.ref.markForCheck();
            }
        }
        this.onChange.emit({ value: this.values, dirty: true });
    }

    onItemClick(item: ListFieldValue<any>) {
        const action = this.field.actions.find(x => x.id === 'edit');
        if (action) {
            action.click('edit', this.field, this.values, item).then(() => {
                this.ref.markForCheck();
                this.onChange.emit({ value: this.values, dirty: true });
            });
        }
    }

    onActionClick(action: FormBuilderAction, id?: string) {
        const promise = action.click(id || action.id, this.field, this.values);
        (promise || Promise.resolve()).then(() => {
            this.ref.markForCheck();
            this.onChange.emit({ value: this.values, dirty: true });
        });
    }

    onDropdownClick(dropdown: KuiDropdownComponent, action: FormBuilderAction, event: KuiTreeSelectNode) {
        if (event.eventName === 'activate') {
            // optionally keep the dropdown open if keepOpen is set
            if (!(event.node && event.node.data && event.node.data.keepOpen)) {
                dropdown.toggle();
            }
            this.onActionClick(action, event.node.data.id);
        }
    }




}
