<div *ngFor="let section of cellset"
    class="mb-3 px-md-3">
    <div class="d-flex px-3 pt-3">
        <p class="mb-0">
            <span *ngFor="let subTitle of section.subTitles">
                {{ subTitle }}<span class="muted mx-2">|</span>
            </span>
        </p>
        <h4>{{ section.title }}</h4>
    </div>

    <div class="data-table">
        <ng-container *ngFor="let table of section.table">

            <div *ngIf="table.header"
                class="data-table__header table-header border-bottom data-table__section">
                <div [style.width]="headerWidth" class="section__header">
                    {{ table.header }}
                </div>
            </div>

            <div class="data-table__table">
                <div *ngFor="let column of table.section"
                    class="data-table__section">
                    <div *ngFor="let cell of column"
                        [ngClass]="{
                            'border-top': (cell.type === 'COLUMN_HEADER' || cell.type === 'ROW_HEADER_HEADER'),
                            'section__column-header': cell.type === 'ROW_HEADER_HEADER' || cell.type === 'ROW_HEADER',
                            'table-header': (cell.type === 'COLUMN_HEADER' || cell.type === 'ROW_HEADER_HEADER' || cell.type === 'ROW_HEADER') || cell.properties.color,
                            'section__header': (cell.type === 'COLUMN_HEADER' || cell.type === 'ROW_HEADER_HEADER' || cell.type === 'ROW_HEADER') || cell.properties.color,
                            'section__cell': cell.type === 'DATA_CELL',
                            'border-bottom': true,
                            'pointer': cell.properties.color && cell.type === 'COLUMN_HEADER',
                            'selected': selected === (cell.value | removeSpaces | lowercase)
                        }"
                        [title]="cell.value"
                        (click)="doOnHeaderClick(cell)">
                        <div *ngIf="cell.properties.color && cell.type === 'COLUMN_HEADER'"
                            class="data-table__section--color"
                            [style.background]="cell.properties.color">
                        </div>
                        {{ cell.value }}
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>