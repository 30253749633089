<div class="row align-items-end text-center">
    <div
        *ngFor="let preset of options"
        class="col-4 mb-3"
    >
        <button
            kui-button
            color="secondary"
            size="lg"
            class="w-100"
            [title]="preset.title"
            (click)="selectPreset(preset)"
        >
            <kui-icon
                *ngIf="preset.iconType === 'icon'"
                [name]="preset.iconName"
                size="lg"
            ></kui-icon>

            <span
                *ngIf="preset.iconType === 'svg'"
                [innerHTML]="getPresetIcon(preset.iconName)"
            ></span>
        </button>
        <div class="mt-1">{{ preset.title }}</div>
    </div>
</div>
