import { Component, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { MapOption } from './components/map-option';
import { MapOptionDirective } from './components/map-option.directive';
import { MapOptionService } from './map-option.service';

@Component({
    selector: 'key-map-options',
    styleUrls: ['./map-options.component.scss'],
    templateUrl: './map-options.component.html',
})
export class MapOptionsComponent implements OnInit, OnDestroy {

    current$: Observable<MapOption<any>>;

    @Input() options: MapOption<any>[];
    @Input() search: boolean;
    @Input() routing: boolean;
    @HostBinding('class.compact') @Input() compact: boolean;

    @ViewChild(MapOptionDirective, { static: true }) optionHost!: MapOptionDirective;

    constructor(
        private optionService: MapOptionService
    ) { }

    ngOnInit(): void {
        this.optionService.setViewContainerRef(this.optionHost.viewContainerRef);
        this.current$ = this.optionService.current$;
    }

    ngOnDestroy(): void {
        this.optionService.clear();
    }

    onBack() {
        this.optionService.back();
    }

    onExit() {
        this.optionService.close();
    }

}
