import { FormBuilderKeyValue } from '../../form-builder';
import { TimeOption } from '../analytics.model';
import { TranslateService } from '@ngx-translate/core';

export const getAnalyticsTimePresets = (timeOptions: TimeOption[] = [], i18n: TranslateService): FormBuilderKeyValue[] => ([
    ...(timeOptions.includes('hours') ? [
        { key: '1h', value: i18n.instant('ANALYTICS.SETTINGS.LAST_HOUR') },
        { key: '3h', value: i18n.instant('ANALYTICS.SETTINGS.LAST_3_HOURS') },
        { key: '8h', value: i18n.instant('ANALYTICS.SETTINGS.LAST_8_HOURS') },
        { key: '24h', value: i18n.instant('ANALYTICS.SETTINGS.LAST_24_HOURS') },
    ] : [ 
        { key: '1d', value: i18n.instant('ANALYTICS.SETTINGS.YESTERDAY') },
    ]),
    { key: '7d', value: i18n.instant('ANALYTICS.SETTINGS.LAST_7_DAYS') },
    { key: '30d', value: i18n.instant('ANALYTICS.SETTINGS.LAST_30_DAYS') },
    { key: 'thismonth', value: i18n.instant('ANALYTICS.SETTINGS.THIS_MONTH') },
    { key: 'thisquarter', value: i18n.instant('ANALYTICS.SETTINGS.THIS_QUARTER') },
    { key: '3m', value: i18n.instant('ANALYTICS.SETTINGS.LAST_3_MONTHS') },
]);

export const shouldTranslateDimension = (dimension: string): boolean => {
    switch (dimension) {
        case 'costCentre':
        case `zoneCostCentre`:
        case 'assetGroup':
        case `zoneGroup`:
        case `assetCategory0`:
        case `assetCategory1`:
        case `assetCategory2`:
        case `assetCategory3`:
        case `assetCategory4`:
            return false;
        default:
            return true;
    }
};
