import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subject, SubscriptionLike as ISubscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import * as Color from 'color';
import { MatchMediaService } from 'app/services';

@Component({
    selector: 'kui-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
})
export class KuiColorPickerComponent implements OnDestroy {
    private debounceSubject: Subject<string> = new Subject();
    private debounce: ISubscription;

    uniqueId = Math.floor(Math.random() * 1e8).toString();
    hasNativePicker = false;

    backgroundColor: string;
    borderColor: string;

    @Input() set color(color: string) {
        const sanitizedColor = (color || '').replace(' ', ''); // when getting a color property programatically from a css property there might be unwanted spaces
        this.borderColor = Color(sanitizedColor).darken(.2).string();
        this.backgroundColor = sanitizedColor;
    }
    @Input() size: 'small' | 'large' = 'small';
    @Input() position: 'right' | 'left' | 'top' | 'bottom' = 'bottom'; // @Note: only applies to fallback color picker

    @Output() onChange: EventEmitter<string> = new EventEmitter();

    constructor(private matchmedia: MatchMediaService) {
        this.debounce = this.debounceSubject.pipe(
            debounceTime(300)
        ).subscribe((value: string) => this.onChange.emit(value));

        this.hasNativePicker = this.matchmedia.deviceFeatures.colorpicker;
    }

    ngOnDestroy() {
        this.debounce.unsubscribe();
    }

    debounceChange(value: string) {
        this.debounceSubject.next(value);
    }
}
