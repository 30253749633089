import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KuiContentHeaderActionsComponent } from './content-header-actions.component';

@NgModule({
  imports: [CommonModule],
  exports: [KuiContentHeaderActionsComponent],
  declarations: [KuiContentHeaderActionsComponent],
})
export class KuiContentHeaderActionsModule { }
