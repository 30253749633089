<div key-content-container
    class="d-flex"
    [ngClass]="{'flex-container': true }"
    *ngIf="event">

    <div class="border-width"
        *ngIf="isMobile">
        <key-event-map class="d-flex class.flex-stretch class.flex-column"
            [isMobile]="isMobile"
            [selectedEvent]="event"
            singleMode="true"></key-event-map>
    </div>

    <div class="tabs d-block flex-1 flex-container mt-0 overflow-auto">

        <kui-content-header class="tabs-header">
            <kui-tab-bar [items]="tabs"
                [selected]="tabs[tabIndex]"
                (selection)="tabIndex = tabs.indexOf($event)"></kui-tab-bar>
            <kui-content-header-actions>
                <kui-icon *ngIf="!isMobile"
                    name="times"
                    [title]="('DIALOG.CLOSE' | translate)"
                    (click)="close()"></kui-icon>
            </kui-content-header-actions>
        </kui-content-header>

        <div class="overflow-auto flex-stretch position-relative bg-content"
            [hidden]="tabIndex !== 0">
            <key-event-details-summary [showActions]="showActions"
                [event]="event"
                (onRequestVideoClicked)="showRequestVideoModal($event)"
                (onAcknowledgeClicked)="showAcknowledgeModal($event)"
                (onCommentClicked)="showCommentModal($event)"></key-event-details-summary>
        </div>
        <div class="overflow-auto flex-stretch position-relative bg-content"
            [hidden]="tabIndex !== 1">
            <key-event-details-contacts [assetId]="event && event.details && event.details['asset'] && event.details['asset']['id']"></key-event-details-contacts>
        </div>

        <key-event-details-iframe *ngFor="let link of linkTabs; let idx = index"
            class="h-100"
            [hidden]="tabIndex !== idx+2"
            [event]="event"
            [link]="link"></key-event-details-iframe>

    </div>
</div>

<key-event-comment-modal></key-event-comment-modal>