import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TableModule } from 'primeng/table';

import { KuiTableComponent } from './table.component';
import { KuiTableCellHtmlComponent } from './table-cell-html.component';
import { KuiIconModule } from './../icon';
import { KuiAlertModule } from './../alert';
import { KuiTableService } from './table.service';
import { KuiSelectModule } from '../select/select.module';
import { KuiInputModule } from '../input/input.module';
import { KuiDropdownModule } from '../dropdown';
import { TranslateModule } from '@ngx-translate/core';
import { KuiRadioModule } from '../radio';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        KuiIconModule,
        KuiAlertModule,
        KuiSelectModule,
        KuiInputModule,
        KuiRadioModule,
        KuiDropdownModule,
        TableModule,
        TranslateModule,
    ],
    exports: [
        KuiTableComponent,
        KuiTableCellHtmlComponent,
    ],
    declarations: [
        KuiTableComponent,
        KuiTableCellHtmlComponent,
    ],
    providers: [KuiTableService],
})
export class KuiTableModule { }
