import { Component, Input, ElementRef, Output, EventEmitter, ChangeDetectorRef, OnDestroy, Renderer2, HostBinding, Inject, TemplateRef, ComponentRef, ApplicationRef, ComponentFactoryResolver, Injector, EmbeddedViewRef } from '@angular/core';

import { IKuiDropdownMenuItem } from './';
import { DOCUMENT } from '@angular/common';
import { KuiDropdownRefComponent } from './dropdown-ref.component';
import { debounce } from 'lodash';
import { Subscription } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';


@Component({
    selector: 'kui-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
})
export class KuiDropdownComponent implements OnDestroy {
    isOpen = false;
    componentRef: ComponentRef<KuiDropdownRefComponent>;
    componentSubscriptions: Subscription[];
    dropUp: boolean;

    @HostBinding('class.w-100')
    @Input() isFormControl = false;

    @Input() style = 'secondary';
    @Input() hideDropdown: boolean;
    @Input() outlined: boolean;
    @Input() buttonSize: 'sm' | 'lg';
    @Input() menu: IKuiDropdownMenuItem[];
    @Input() classNames: string;
    @Input() parentElement: HTMLElement = this.document.body;
    @Input() disabled: boolean = false;


    /**
     * custom content is a reference to a template tag eg.
     * <kui-dropdown [customContent]="custom"></kui-dropdown>
     * <ng-template #custom>...</ng-template>
     */
    @Input() customContent: TemplateRef<any>;


    /**
     * passes context to the custom content template which can be accessed by the let keyword followed by the key eg.
     *  <kui-dropdown [customContentContext]="{ myKey: ..., anotherKey: ... }"></kui-dropdown> ===> send the context object to the ngTemplateOutlet
     *  <ng-template #myCustomContentTemplate
     *     let-myKey="myKey"
     *     let-anotherKey="anotherKey"> ===> get the item in object via its key name
     */
    @Input() customContentContext: { [key: string]: any };

    @Input() customContentLocation: 'TOP' | 'BOTTOM' = 'BOTTOM';

    @Output() onOpen = new EventEmitter<void>();
    @Output() onClose = new EventEmitter<void>();

    listeners: Function[];

    constructor(
        public el: ElementRef,
        private renderer: Renderer2,
        private appRef: ApplicationRef,
        private resolver: ComponentFactoryResolver,
        private injector: Injector,
        private ref: ChangeDetectorRef,
        @Inject(DOCUMENT) private document: Document,
        private router: Router
    ) { 

        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.toggle(false);
            }
        });
    }

    // add a little debounce... adding too much makes the jerking on scroll and resize a bit hectic eventhough the performance will be alot better
    // so this is a trade of somewhere in between
    debouncedMoveDropdownPosition = debounce(this.setDropdownPosition, 10, { trailing: true, leading: false });

    ngOnDestroy() {
        this.close();
    }

    registerListeners() {
        this.unregisterListeners();
        this.listeners = [
            this.renderer.listen('document', 'click', (e) => this.closeOnOutsideEvents(e.target)),
            this.renderer.listen('document', 'touchstart', (e) => this.closeOnOutsideEvents(e.target)),
            this.renderer.listen('window', 'resize', this.debouncedMoveDropdownPosition.bind(this)),
        ];

        // being forced to do the scroll event like this in order to capture any scroll event
        // seen as we need to know when any scroll event happened on any parent element
        document.addEventListener('scroll', this.debouncedMoveDropdownPosition.bind(this), true);
    }

    setDropdownPosition() {
        if (this.componentRef) {
            const button = this.el.nativeElement.getBoundingClientRect();
            const parent = this.parentElement.getBoundingClientRect();
            const dropdown = this.componentRef.instance.el.nativeElement.querySelector('.dropdown-menu').getBoundingClientRect();
            const position = { x: 0, y: 0 };

            // calculate position of the dropdown relative to the parent element.
            const relativeTop = button.top - parent.top;
            const relativeLeft = button.left - parent.left;

            // if the width of the dropdown will fit inside viewport at the start position of the button then align left, otherwise align right
            position.x = (relativeLeft + dropdown.width) < parent.width
                ? relativeLeft
                : (relativeLeft + button.width) - dropdown.width;

            // if the height of the dropdown will fit inside viewport at the end position of the button then align bottom, otherwise align top
            position.y = (relativeTop + button.height + dropdown.height) < parent.height
                ? relativeTop + button.height
                : relativeTop - dropdown.height;

            // either show down or up arrow depending on where the dropdown is
            this.dropUp = position.y < button.y;

            this.componentRef.instance.position = position;
            this.componentRef.hostView.detectChanges();

            this.ref.markForCheck();
        }
    }

    unregisterListeners() {
        if (this.listeners) {
            this.listeners.forEach(func => func());
            document.removeEventListener('scroll', this.debouncedMoveDropdownPosition.bind(this), true);
            this.listeners = null;
        }
    }

    dispatchAction(item: IKuiDropdownMenuItem, event: MouseEvent) {
        if (item.closeDropdownOnClicked) {
            setTimeout(() => { // allow toggle animation to finish before closing
                this.toggle(false);
            }, 100);
        }
        item.action(event);
    }

    closeOnOutsideEvents(target: HTMLElement) {
        // determine if we've clicked anywhere outside of our component & dropdown and close the dropdown
        // HTMLElement.contains() fails when used with dropdowns that are absolute and relative to a node
        // outside of this component, so we're using compareDocumentPosition instead.
        const dropdownEl = this.document.querySelector('kui-dropdown-ref .dropdown-menu');
        const dropdownPosition = dropdownEl && dropdownEl.compareDocumentPosition(target);
        const position = this.el.nativeElement.compareDocumentPosition(target);
        if (position <= 16 && dropdownPosition <= 16) { // DOCUMENT_POSITION_CONTAINS
            this.toggle(false);
        }
    }

    toggle(state?: boolean) {
        if (!this.disabled) {   
            this.isOpen = state !== undefined ? state : !this.isOpen;
            if (this.isOpen) {
                this.open();
            } else {
                this.close();
            }
            this.ref.markForCheck();
        }
    }

    private open() {
        if (!this.componentRef) {
            this.registerListeners();

            // create factory
            this.componentRef = this.resolver
                .resolveComponentFactory(KuiDropdownRefComponent)
                .create(this.injector);

            // assign inputs
            this.componentRef.instance.menu = this.menu;
            this.componentRef.instance.customContent = this.customContent;
            this.componentRef.instance.classNames = this.classNames;
            this.componentRef.instance.customContentContext = this.customContentContext;
            this.componentRef.instance.customContentLocation = this.customContentLocation;

            // subscribe to outputs
            const { onAction, onMounted } = this.componentRef.instance;
            this.componentSubscriptions = [
                onAction.subscribe(({ item, event }) => {
                    this.dispatchAction(item, event);
                }),
                onMounted.subscribe(() => {
                    setTimeout(() => {
                        // setup initial position
                        this.setDropdownPosition();
                        // show only after position has been set to avoid dropdowns jumping when you open 2 different ones straight after each other
                        this.parentElement.classList.add('show');
                    });
                }),
            ];

            // add to DOM
            this.appRef.attachView(this.componentRef.hostView);

            const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
                .rootNodes[0] as HTMLElement;

            this.parentElement.appendChild(domElem);

            this.onOpen.emit(null);
        }
    }

    private close() {
        if (this.componentRef) {
            this.unregisterListeners();
            this.appRef.detachView(this.componentRef.hostView);
            this.componentSubscriptions.forEach(x => x.unsubscribe());
            this.componentRef.destroy();
            this.componentRef = null;
            this.onClose.emit(null);
            this.parentElement?.classList?.remove('show');
        }
    }
}


