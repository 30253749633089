<kui-card [@slideInOut]="align">
  <kui-card-header *ngIf="tabs" class="p-0">
    <kui-tab-bar [stretchItems]="true" [items]="tabs" (selection)="tabSelection.emit($event)"></kui-tab-bar>
  </kui-card-header>

  <kui-card-body class="overflow-auto">
    <ng-content></ng-content>
  </kui-card-body>

  <kui-card-footer *ngIf="undo || redo || cancel || save" align="right">
    <button *ngIf="undo" (click)="onUndo.emit($event)" kui-button color="none" [kui-tooltip]="'SHARED.UNDO' | translate"><kui-icon name="reply"></kui-icon></button>
    <button *ngIf="redo" (click)="onRedo.emit($event)" kui-button color="none" [kui-tooltip]="'SHARED.REDO' | translate"><kui-icon name="redo"></kui-icon></button>
    <button *ngIf="cancel" (click)="onCancel.emit($event)" kui-button color="danger" [kui-tooltip]="'SHARED.CANCEL' | translate"><kui-icon name="ban"></kui-icon></button>
    <button *ngIf="save" (click)="onSave.emit($event)" kui-button color="success" [kui-tooltip]="'SHARED.SAVE' | translate"><kui-icon name="save"></kui-icon></button>
  </kui-card-footer>
</kui-card>




