import { Component, Input, HostBinding } from '@angular/core';

@Component({
    selector: 'kui-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
})
export class KuiIconComponent  {
    fontSizes: { [key: string]: number } = {
        xxs: .5,
        xs: .875,
        sm: 1.25,
        md: 1.75,
        lg: 2,
        xl: 2.5,
        xxl: 3,
    };

    @Input() name: string;
    @Input() disabled: boolean;
    @Input() color: 'default' | 'primary' | 'success' | 'info' | 'warning' | 'danger' = 'default';
    @Input() size: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | number = 'xs';
    // overwrite theme icon weight and force solid/filled
    // this is for special cases where a thin icon set just doesn't work
    @Input() fill: boolean;
    @Input() brand: boolean;
    @Input() fixedWidth = false;
    @HostBinding('class.position-relative') @Input() notification = false;

    @Input() prefix = 'icon';

    getFontSize(size: string | number): number {
        if (typeof size === 'string') {
            return this.fontSizes[size];
        } else {
            return size;
        }
    }
}

