import { Component, ViewChild, ChangeDetectorRef, OnInit } from '@angular/core';
import { KuiModalRefComponent } from 'app/key-ui/modal/modal-ref.component';
import { TranslateService } from '@ngx-translate/core';
import { FeedItem, EventFeedItem } from 'app/shared/components/feed/feed.model';
import { IKuiModalAction } from 'app/key-ui';
import { EventDetailsComponent } from 'app/shared/components/feed/event-feed/details/details.component';
import { NavigationService } from 'app/services';
import { MediaService } from 'app/services/media/media.service';
import { AppService } from 'app/app.service';

@Component({
    selector: 'key-event-details-modal',
    templateUrl: 'modal.component.html',
    styleUrls: ['modal.component.scss'],
})
export class EventDetailsModalComponent extends KuiModalRefComponent<any> implements OnInit {
    modalActions: IKuiModalAction[] = [];

    @ViewChild(EventDetailsComponent) eventDetails: EventDetailsComponent;

    constructor(
        private i18l: TranslateService,
        private changeRef: ChangeDetectorRef,
        private media: MediaService,
        private navigation: NavigationService,
        private app: AppService) {
        super();
    }

    async ngOnInit() {
        this.modalActions = await this.getDetailsModalActions(null); // get a base set of options, the next line will load more if necessary
        this.getDetailsModalActions(this.data.selectedItem).then(actions => {
            this.modalActions = actions;
        });
    }

    async getDetailsModalActions(item: FeedItem): Promise<IKuiModalAction[]> {
        const actions = [];

        if (item && item['alerts']) { // this is an event or an alert
            if (await this.media.getVideoRequestSettings(this.data.id)) {
                actions.push({
                    text: this.i18l.instant('ALERTS.BUTTONS.VIDEO'),
                    style: 'secondary',
                    action: async () => {
                        this.eventDetails.showRequestVideoModal(item as EventFeedItem).then(result => {
                            if (result) {
                                this.actions.updateFeed([result]);
                            }
                        });
                        this.changeRef.markForCheck();
                        return true;
                    },
                });
            }
            actions.push({
                text: this.i18l.instant('ALERTS.BUTTONS.COMMENT'),
                style: 'secondary',
                action: () => {
                    this.eventDetails.showCommentModal(item as EventFeedItem).then(result => {
                        if (result) {
                            this.actions.updateFeed([result]);
                        }
                    });
                    this.changeRef.markForCheck();
                    return true;
                },
            });
            if (item['alerts'].length > 0) { // this is an alert
                actions.push({
                    text: this.i18l.instant('ALERTS.BUTTONS.ACKNOWLEDGE'),
                    style: 'secondary',
                    action: () => {
                        this.eventDetails.showAcknowledgeModal(item as EventFeedItem).then(result => {
                            if (result) {
                                this.actions.updateFeed([result]);
                            }
                        });
                        this.changeRef.markForCheck();
                        return true;
                    },
                });
            }
        }

        if (item && item['dateStart']) { // must be a trip
            if (this.app.features.page.replay.enabled) {
                
                actions.push({
                    text: this.i18l.instant('ALERTS.BUTTONS.TRIP'),
                    icon: 'external-link-alt',
                    style: 'secondary',
                    hideTextOnMobile: true,
                    action: () => {
                        this.navigation.navigateToTrip(this.data.id, item as any);
                    },
                });
            }
        }

        actions.push({
            text: this.i18l.instant('DIALOG.CLOSE'),
            icon: 'times',
            style: 'primary',
            keypress: 27,
            hideTextOnMobile: true,
            hideIconOnDesktop: true,
        });
        return actions;
    }

    detachModal() {
        this.actions.close();
    }
}
