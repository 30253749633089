import {
    Component,
    Input,
    ChangeDetectionStrategy,
    ContentChildren,
    QueryList,
    AfterViewInit,
    Output,
    EventEmitter
} from '@angular/core';
import { KuiTabComponent } from './tab.component';

@Component({
    selector: 'kui-tabs',
    templateUrl: './tabs.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KuiTabsComponent implements AfterViewInit {
    @Input() height: number;

    @ContentChildren(KuiTabComponent) tabList: QueryList<KuiTabComponent>;

    @Output() tabChanged = new EventEmitter<KuiTabComponent>();

    ngAfterViewInit() {
        if (!this.tabList || this.tabList.length < 1) {
            return;
        }

        const tabs = this.tabList.toArray();

        const activeTabs = tabs.filter(tab => tab.isActive);

        if (activeTabs.length !== 1) {
            setTimeout(() => {
                tabs.forEach((tab, idx) => tab.isActive = idx === 0);
            }, 0);
        }
    }

    changeTab(selectedTab) {
        this.tabList.toArray().forEach(tab => {
            if (tab.title === selectedTab) {
                tab.isActive = true;
                this.tabChanged.emit(tab);
            } else {
                tab.isActive = false;
            }
        });
    }
}
