import { Injectable } from '@angular/core';
import { AnalyticsChartData } from '../chart.component';
import { Legend, LegendPosition, Label } from 'app/shared/components/graph/graph.model';
import { AnalyticsChartService } from '../chart.service';
import { AnalyticsService } from '../../services/analytics.service';
import { AnalyticsCellValue } from '../../analytics.model';
import { AnalyticsOutputSettings } from '@key-telematics/fleet-api-client';

export interface AnalyticsChartPieData extends AnalyticsChartData {
    fill: boolean;
    labels: Label[];
    legend: Legend;
    segments: number;
    showLabelsOnGraph: boolean;
}

@Injectable()
export class AnalyticsChartPieService extends AnalyticsChartService<AnalyticsChartPieData> {
    constructor(public analytics: AnalyticsService) {
        super(analytics);
    }

    getData(cellset: AnalyticsCellValue[][], settings: AnalyticsOutputSettings): AnalyticsChartPieData {
        const { measures } = settings.table;
        const { label, dataLabels, data, series, fill } = settings.pie;

        const datasets = [{
            colors: series && series.default && series.default.color,
            data: [],
            label: '',
        }];
        const labels = [];

        // average is always the last item down and accross and total is the second last, so set the index to 1 or 0 depending on how many you'll have to go back from the last item
        const dataLevel = +(data === 'total');

        // get the amount of rows and cells inside each row as index based for convenience
        const cellCount = cellset[0].length - 1;
        const rowCount = cellset.length - 1;

        // get the amount of column header levels
        let colHeaderLevels = 0;
        for (let i = 0; i < cellset.length; i++) {
            const row = cellset[i];
            if (row[0].type !== 'COLUMN_HEADER' && row[0].type !== 'ROW_HEADER_HEADER') { break; }
            colHeaderLevels++;
        }

        // get the amount of row header levels with the help of colHeaderLevels
        const rowHeaderLevels = cellset[colHeaderLevels].filter(x => x.type === 'ROW_HEADER').length;

        // more than one measure means we have to plot measures against each other
        if (measures.length > 1) {
            const dataCellLevel = measures.length * (dataLevel + 1);
            // total or average row depending on dataLevel
            const valueRow = cellset[rowCount - dataLevel];

            // the row containing ROW_HEADER_HEADER's and its parent which will make up the labels
            const labelRow = cellset[colHeaderLevels - 1];

            measures.forEach((_measure, i) => {
                // average/total value cell
                const valueCell = valueRow[cellCount - (dataCellLevel - (i + 1))];
                datasets[0].data.push({ value: valueCell.value, raw: parseFloat(valueCell.properties.raw as string) || null });

                // cell that contains the label value
                const lableCell = labelRow[cellCount - (dataCellLevel - (i + 1))];
                labels.push({ value: lableCell.value, hideTooltipTitle: true });
            });
        }

        // only one measure means we have to plot row averages/totals of that specific measure
        if (measures.length === 1) {
            // get the rows containing values by remove the headers and filter out the average and total rows which we do not need
            const valueRows = cellset.slice(colHeaderLevels, cellset.length).filter(row => {
                return row[0].properties.type !== 'average' && row[0].properties.type !== 'total';
            });

            valueRows.forEach(row => {
                // average/total value cell
                const valueCell = row.find(x => x.properties.type === data);
                datasets[0].data.push({ value: valueCell.value, raw: parseFloat(valueCell.properties.raw as string) || null });

                // get label from the uniquename
                const uniquename = decodeURIComponent(row[rowHeaderLevels - 1].properties.uniquename);
                labels.push({
                    value: uniquename.replace('All|', '').replace(/\|+/g, ' | '),
                    hideTooltipTitle: true,
                });
            });
        }

        return {
            fill,
            datasets,
            labels,
            segments: datasets[0].data.length,
            legend: {
                show: label.visible,
                position: label.position as LegendPosition,
                overlay: label.overlay,
            },
            showLabelsOnGraph: dataLabels,
        };
    }
}
