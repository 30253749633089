export function getPresetIconSvgString(type: string) {

    const getChart = (chartString: string): string => {
        /* tslint:disable */
        return `<svg viewBox="0 0 400 300" fill="none" class="settings-icon">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M390.625 262.5C395.781 262.5 400 266.719 400 271.875V290.625C400 295.781 395.781 300 390.625 300H9.375C4.21875 300 0 295.781 0 290.625V9.375C0 4.21875 4.21875 0 9.375 0H28.125C33.2812 0 37.5 4.21875 37.5 9.375V262.5H390.625Z"/>
            ${ chartString }
        </svg>`;
        /* tslint:enable */
    };

    switch (type) {
        case 'bar-asc':
            /* tslint:disable */
            return getChart(`
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.85042 0H38.819C42.5867 0 45.6694 3.08269 45.6694 6.85042V66.2207C45.6694 69.9884 42.5867 73.0711 38.819 73.0711H6.85042C3.08269 73.0711 0 69.9884 0 66.2207V6.85042C0 3.08269 3.08269 0 6.85042 0Z" transform="translate(338.81 151.124) scale(-1 1)"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.85042V93.6223C0 97.3901 3.08269 100.473 6.85042 100.473H38.819C42.5868 100.473 45.6694 97.3901 45.6694 93.6223V6.85042C45.6694 3.08269 42.5868 0 38.819 0H6.85042C3.08269 0 0 3.08269 0 6.85042Z" transform="translate(265.739 123.722) scale(-1 1)"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.85042V139.292C0 143.059 3.08269 146.142 6.85041 146.142H38.819C42.5867 146.142 45.6694 143.059 45.6694 139.292V6.85042C45.6694 3.08269 42.5867 0 38.819 0H6.85041C3.08269 0 0 3.08269 0 6.85042Z" transform="translate(192.668 78.0528) scale(-1 1)"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.85041V203.229C0 206.997 3.08269 210.079 6.85041 210.079H38.819C42.5867 210.079 45.6694 206.997 45.6694 203.229V6.85041C45.6694 3.08269 42.5867 0 38.819 0H6.85041C3.08269 0 0 3.08269 0 6.85041Z" transform="translate(119.597 14.1156) scale(-1 1)"/>
            `);
            /* tslint:enable */
        case 'bar-desc':
            /* tslint:disable */
            return getChart(`
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.85042 0H38.819C42.5867 0 45.6694 3.08269 45.6694 6.85042V66.2207C45.6694 69.9884 42.5867 73.0711 38.819 73.0711H6.85042C3.08269 73.0711 0 69.9884 0 66.2207V6.85042C0 3.08269 3.08269 0 6.85042 0Z" transform="translate(73.9274 151.124)"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.85042V93.6223C0 97.3901 3.08269 100.473 6.85042 100.473H38.819C42.5868 100.473 45.6694 97.3901 45.6694 93.6223V6.85042C45.6694 3.08269 42.5868 0 38.819 0H6.85042C3.08269 0 0 3.08269 0 6.85042Z" transform="translate(146.998 123.722)"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.85042V139.292C0 143.059 3.08269 146.142 6.85041 146.142H38.819C42.5867 146.142 45.6694 143.059 45.6694 139.292V6.85042C45.6694 3.08269 42.5867 0 38.819 0H6.85041C3.08269 0 0 3.08269 0 6.85042Z" transform="translate(220.07 78.0528)"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.85041V203.229C0 206.997 3.08269 210.079 6.85041 210.079H38.819C42.5867 210.079 45.6694 206.997 45.6694 203.229V6.85041C45.6694 3.08269 42.5867 0 38.819 0H6.85041C3.08269 0 0 3.08269 0 6.85041Z" transform="translate(293.141 14.1156)"/>
            `);
            /* tslint:enable */

        default:
            return `<svg></svg>`;
    }
}
