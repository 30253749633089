<kui-dropdown
    #dropdown
    [style]="'none'"
    [outlined]="true"
    [disabled]="disabled()"
    [isFormControl]="this.isFormStyle()"
    classNames="kui-daterange-popup"
    [class.w-100]="this.isFormStyle()"
    [customContent]="filterOperator">
    <div class="input-group selected-values">
        @if(selectedTimePeriod()) {
            <span 
                class="selected-values__period"         
                [class.form-control]="this.isFormStyle()"
            >
                {{selectedTimePeriod().text}}
            </span>
        } @else if (selectedStartDate() && selectedEndDate()) {
            <div 
                class="selected-values__dates"
                [class.form-control]="this.isFormStyle()"
            >
                <span class="date-display__start">{{selectedStartDate()  | date: dateFormat()}}</span>
                <kui-icon class="date-display__divider" name="arrow-right-long"></kui-icon>
                <span class="date-display__end">{{selectedEndDate() | date: dateFormat()}}</span>
            </div>
        } @else {
            <span 
                class="selected-values__placeholder"         
                [class.form-control]="this.isFormStyle()"
            >
                {{noDatePlaceholder() ?? 'SHARED.NONE' | translate}}
            </span>
        }

        @if(icon()) {
            <kui-icon 
                [name]="icon()" 
                class="selected-values__icon"
                [ngClass]="{
                    'input-group-addon': this.isFormStyle(),
                }"/>
        }
    </div>
</kui-dropdown>

<ng-template #filterOperator>
    <div class="date-selector__panel">

        @if(isMobile()) {
            <div class="date-selector__calendar mobile-calendar">
                <p-calendar 
                    panelStyleClass="kui-calendar"
                    [(ngModel)]="mobileDates"
                    (onSelect)="handleMobileDateSelection()"
                    [minDate]="minDate()"
                    [maxDate]="maxDate()"
                    selectionMode="range"
                    [inline]="true"
                    [readonlyInput]="true" />
            </div>
        } @else {
            <div class="date-selector__date-display start-date">
                <h5 class="date-selector__date-display__label">From</h5>
                <span class="date-selector__date-display__value">
                    @if (startCalendarDate) {
                        {{startCalendarDate | date: dateFormat()}}
                    } @else {
                        {{'SHARED.NONE' | translate}}
                    }
                </span>
            </div>

            <div class="date-selector__date-display end-date">
                <h5 class="date-selector__date-display__label">To</h5>
                <span class="date-selector__date-display__value">
                    @if (endCalendarDate) {
                        {{endCalendarDate | date: dateFormat()}}
                    } @else {
                        {{'SHARED.NONE' | translate}}
                    }
                </span>
            </div>

            <div class="date-selector__calendar start-calendar">
                <p-calendar
                    panelStyleClass="kui-calendar"
                    [(ngModel)]="startCalendarDate"
                    (onSelect)="handleCalendarDateSelection()"
                    [minDate]="minDate()"
                    [maxDate]="maxDate()"
                    [inline]="true" 
                    [showTime]="useTime()"
                    [showSeconds]="useTime()"
                    [hourFormat]="hourFormat()" />
            </div>

            <div class="date-selector__calendar end-calendar">
                <p-calendar
                    panelStyleClass="kui-calendar"
                    [(ngModel)]="endCalendarDate"
                    (onSelect)="handleCalendarDateSelection()"
                    [minDate]="minDate() | latestDate: startCalendarDate"
                    [maxDate]="maxDate() | latestDate: maxDiffDate"
                    [inline]="true"
                    [showTime]="useTime()"
                    [showSeconds]="useTime()"
                    [hourFormat]="hourFormat()"/>
            </div>
        }

        @if(timePeriods()) {
            <div kui-list class="date-selector__time-periods time-periods">
                @for (period of timePeriods(); track period.id) {
                    <button kui-list-item 
                        type="action" 
                        [isActive]="period.id === internalTimePeriod()?.id" 
                        (click)="handleTimePeriod(period)"
                    >
                        {{period.text}}
                    </button>
                }
            </div>
        }

        <button 
            kui-button
            class="date-selector__confirm confirm" 
            [disabled]="!startCalendarDate && !endCalendarDate"
            (click)="confirmDates()"
        >
            {{submitButtonText() ?? 'DIALOG.APPLY' | translate}}
        </button>

        <ng-content select="[panel]"></ng-content>
    </div>
</ng-template>