import {
    Component,
    Input,
    Output,
    EventEmitter,
    HostListener,
    ElementRef,
    OnInit,
    ChangeDetectorRef
} from '@angular/core';

import { IKuiModalAction } from './modal.model';

@Component({
    selector: 'kui-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
export class KuiModalComponent implements OnInit {
    displayed: boolean;
    show = false;
    showFade = false; // finer controll over fading animation
    duration = 200;
    body: HTMLElement = document.body;
    modalClassnames: string[] = ['modal-dialog'];

    @Input() title: string;
    @Input() actions: IKuiModalAction[];
    @Input() size: 'sm' | 'lg' | 'fill';
    @Input() loading = false;

    @Output() onClose: EventEmitter<void> = new EventEmitter();
    @Output() onOpen: EventEmitter<void> = new EventEmitter();

    constructor(private el: ElementRef, private chageRef: ChangeDetectorRef) { }

    ngOnInit() {
        if (this.size) {
            this.modalClassnames.push(`modal-${this.size}`);
        }
    }

    @HostListener('click', ['$event']) closeOnOutsideClick(e) {
        const modalContent = this.el.nativeElement.querySelector('.modal-content');
        if (!modalContent.contains(e.target)) {
            // do not close the dialog if a user clicks outside of the area (that isn't standard dialog behaviour). It also causes problems with the map component's layer select.
            // Leaving this here as documentation.
            // this.close();
        }
    }

    @HostListener('document:keyup', ['$event']) closeOnKeyup(e) {
        if (e.key === 'Escape' && !this.loading) {
            this.close();
        }
        if (this.actions && !this.loading) {
            this.actions.forEach(action => {
                if (action.keypress && (action.keypress === e.key || action.keypress === e.keyCode)) {
                    this.handleButtonClick(action);
                }
            });
        }
    }

    handleButtonClick(e: IKuiModalAction) {
        let close = true;
        if (e.action) {
            close = !e.action();
        }
        if (close) {
            this.close();
        }
    }

    close() {
        this.body.classList.remove('modal-open');
        this.body.classList.remove('disable-smooth-scrolling');
        this.showFade = false;

        setTimeout(() => {
            this.show = false;
            this.onClose.emit();
            this.chageRef.markForCheck();
        }, this.duration);
    }

    open() {
        this.body.classList.add('modal-open');
        this.body.classList.add('disable-smooth-scrolling');
        this.show = true;

        setTimeout(() => {
            this.showFade = true;
            this.onOpen.emit();
            this.chageRef.markForCheck();
        }, this.duration);
    }
}
