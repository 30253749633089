import { Injectable } from '@angular/core';
import { TaskResponse, FeedsGetTaskFeedGQL } from 'app/shared/graphql';
import { Observable, of } from 'rxjs';
import { AppService } from 'app/app.service';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

export interface UserTaskItem {
    id: string;
    title: string;
    description: string;
    state: string;
    active: boolean;
    task: TaskResponse;
    error?: string;
}


@Injectable()
export class UserTaskService {

    constructor(private app: AppService, private i18n: TranslateService) {

    }

    getTaskFeed(entityId: string): Observable<UserTaskItem[]> {
        if (!this.app.api.websocketConnected) {
            return of([]);
        }
        return new FeedsGetTaskFeedGQL(this.app.api.gql).subscribe({ entity: entityId }).pipe(map(result => {
            return result.data.getTaskFeed.map(x => this.mapTaskResponse(x as TaskResponse));
        }));
    }

    async cancelTask(id: string): Promise<void> {
        await this.app.api.tasks.cancelTask(id);
    }

    mapTaskResponse(task: TaskResponse): UserTaskItem {
        const result: UserTaskItem = {
            id: task.id,
            title: this.i18n.instant(`TASKS.TYPES.${task.type.toUpperCase()}.TITLE`),
            description: this.i18n.instant(`TASKS.TYPES.${task.type.toUpperCase()}.DESCRIPTION`, task.data),
            state: this.i18n.instant(`TASKS.STATE.${task.state.toUpperCase()}`),
            active: ['pending', 'executing', 'waiting', 'canceling', ].includes(task.state),
            task: task,
            error: task.results && task.results.error && task.results.error.message,
        };
        return result;
    }

}
