<ng-container [ngSwitch]="type">
    <key-analytics-chart-graph *ngSwitchCase="'chart'"
        [data]="data"
        (onLoad)="onLoad.emit($event)"
        (onMeasureSelection)="onMeasureSelection.emit($event)"></key-analytics-chart-graph>
    <key-analytics-chart-pie *ngSwitchCase="'pie'"
        [data]="data"
        (onLoad)="onLoad.emit($event)"
        (onMeasureSelection)="onMeasureSelection.emit($event)"></key-analytics-chart-pie>
    <key-analytics-chart-stat *ngSwitchCase="'stat'"
        [data]="data"
        (onLoad)="onLoad.emit($event)"
        [size]="size"
        (onMeasureSelection)="onMeasureSelection.emit($event)"></key-analytics-chart-stat>
    <kui-alert-panel *ngSwitchDefault
        [message]="'ANALYTICS.ERRORS.UNSUPPORTED_CHART_TYPE' | translate: { type: type | titlecase }"></kui-alert-panel>
</ng-container>