import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KuiListDirective } from './list.directive';
import { KuiListItemComponent } from './list-item.component';
import { KuiListItemHeadingDirective } from './list-item-heading.directive';
import { KuiListItemTextDirective } from './list-item-text.directive';

const KUI_LIST_MODULES = [
  KuiListDirective,
  KuiListItemComponent,
  KuiListItemHeadingDirective,
  KuiListItemTextDirective,
];

@NgModule({
  imports: [CommonModule],
  exports: KUI_LIST_MODULES,
  declarations: KUI_LIST_MODULES,
})
export class KuiListModule {}
