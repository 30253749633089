<key-theme>
  <kui-menu #menu
    [class.side-navigation-right]="sidebarPosition === 'right'"
    *ngIf="showSidebar"
    (toggledMobile)="toggleSidebar($event)"
    (toggledCollapse)="toggleSidebarCollapsed($event)"
    [isToggledMobile]="sidebarToggle$ | async">

    <kui-menu-brand
      [url]="(user$ | async)?.owner.type !== 'client' ? '/client-select' : '/'"
      [image]="clientLogo">
    </kui-menu-brand>

    <kui-user
      [clientname]="clientName"
      [fullname]="(user$ | async).name"
      [username]="(user$ | async).emailAddress"
      [avatar]="userAvatar"
      (companyClicked)="gotoClientSelect()"
      (userClicked)="router.navigate(['/user/profile'])"

    >

      <!-- TODO: get timezone and language and add them to the list -->
      <p>
        <strong>{{ 'MENU.CLIENT' | translate }}</strong>: {{clientName}}
      </p>
    </kui-user>

    <kui-nav>
        <div *ngFor="let group of groups; let idx = index">
            <ng-container *ngIf="!sidebarCollapsed || isMobile || !group.hidden">
                <kui-nav-title
                    [class.hidden-on-collapsed]="idx === 0">
                    {{ group.name }}</kui-nav-title>
                <kui-nav-section>
                    <div *ngFor="let item of group.items"
                    class="position-relative nav-section__item">
                        <a kui-nav-link
                            kui-action
                            key-page-nav-link
                            [isParent]="!!item.children?.length"
                            [keepActiveOpen]="!sidebarCollapsed"
                            [page]="item.id">
                            <kui-icon [size]="1.5"
                                [name]="item.icon"></kui-icon>
                            <span kui-nav-link-text>{{ item.name }}</span>
                            <kui-icon *ngIf="!!item.children?.length"
                                name="chevron-down"
                                class="nav-link-dropdown-arrow"></kui-icon>
                        </a>
                        <div kui-nav-sub
                            [class.p-0]="!(sidebarToggle$ | async) || (isMobile && !item.children?.length)">
                            <a *ngIf="!isMobile"
                                kui-action
                                key-page-nav-link
                                class="sub-nav__title"
                                [isParent]="item.id === 'more'"
                                [child]="!!item.children?.length && item.children[0].id"
                                [page]="item.id">
                                <kui-icon [size]="1.5"
                                    [name]="item.icon"></kui-icon>
                                <span class="sub-nav__title-text">{{ item.name }}</span>
                            </a>
                            <div class="sub-nav__items" *ngIf="!!item.children?.length">
                                <a *ngFor="let child of item.children"
                                    kui-nav-link
                                    kui-action
                                    key-page-nav-link
                                    [isParent]="item.id === 'more' && !!child.children?.length"
                                    [page]="item.id === 'more' ? child.id : item.id"
                                    [child]="item.id !== 'more' && child.id">
                                    <span kui-nav-link-text>{{ child.name }}</span>

                                    <div class="sub-nav__items" *ngIf="!!child.children?.length">
                                        <a *ngFor="let nestedChild of child.children"
                                            kui-nav-link
                                            kui-action
                                            key-page-nav-link
                                            [page]="child.id"
                                            [child]="nestedChild.id">
                                            <span kui-nav-link-text>{{ nestedChild.name }}</span>
                                        </a>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </kui-nav-section>
            </ng-container>
        </div>
    </kui-nav>

    <kui-nav kui-menu-actions #actionsNav>
      <kui-nav-title>{{ (user$ | async)?.name }}</kui-nav-title>

        <kui-nav-section>
            
            <div *ngIf="(user$ | async)?.owner.type !== 'client'"
                class="position-relative nav-section__item">
                <a kui-nav-link kui-action (click)="gotoClientSelect()">
                    <kui-icon [size]="1.5" [fixedWidth]="true" name="home"></kui-icon>
                    <span kui-nav-link-text>{{ 'MENU.CHANGE_CLIENT' | translate }}</span>
                </a>
                <div kui-nav-sub
                    [class.p-0]="!(sidebarToggle$ | async) || isMobile">
                    <a *ngIf="!isMobile"
                        kui-action
                        class="sub-nav__title"
                        (click)="gotoClientSelect()">
                        <kui-icon [size]="1.5"
                            [fixedWidth]="true"
                            name="home"></kui-icon>
                        <span class="sub-nav__title-text">{{ 'MENU.CHANGE_CLIENT' | translate }}</span>
                    </a>
                </div>
            </div>

            <div *ngIf="userHasMultipleAccounts"
                class="position-relative nav-section__item">
                <a kui-nav-link kui-action (click)="switchAccount()">
                    <kui-icon [size]="1.5" [fixedWidth]="true" name="house-user"></kui-icon>
                    <span kui-nav-link-text>{{ 'MENU.CHANGE_ACCOUNT' | translate }}</span>
                </a>
                <div kui-nav-sub
                    [class.p-0]="!(sidebarToggle$ | async) || isMobile">
                    <a *ngIf="!isMobile"
                        kui-action
                        class="sub-nav__title"
                        (click)="switchAccount()">
                        <kui-icon [size]="1.5"
                            [fixedWidth]="true"
                            name="house-user"></kui-icon>
                        <span class="sub-nav__title-text">{{ 'MENU.CHANGE_ACCOUNT' | translate }}</span>
                    </a>
                </div>
            </div>

            <div class="position-relative nav-section__item">
                <a kui-nav-link kui-action key-page-nav-link page="user" child="profile">
                    <kui-icon [size]="1.5" [fixedWidth]="true" name="user"></kui-icon>
                    <span kui-nav-link-text>{{ 'MENU.PROFILE' | translate }}</span>
                </a>
                <div kui-nav-sub
                    [class.p-0]="!(sidebarToggle$ | async) || isMobile">
                    <a *ngIf="!isMobile"
                        kui-action
                        key-page-nav-link
                        class="sub-nav__title"
                        page="user"
                        child="profile">
                        <kui-icon [size]="1.5"
                            [fixedWidth]="true"
                            name="user"></kui-icon>
                        <span class="sub-nav__title-text">{{ 'MENU.PROFILE' | translate }}</span>
                    </a>
                </div>
            </div>

            <div class="position-relative nav-section__item">
                <a kui-nav-link kui-action key-page-nav-link page="user" child="notifications">
                    <kui-icon [size]="1.5" [fixedWidth]="true" name="bells" [notification]="unseedNotifications > 0" ></kui-icon>
                    <span kui-nav-link-text>{{ 'MENU.USER_NOTIFICATIONS' | translate }}</span>
                </a>
                <div kui-nav-sub
                    [class.p-0]="!(sidebarToggle$ | async) || isMobile">
                    <a *ngIf="!isMobile"
                        kui-action
                        key-page-nav-link
                        class="sub-nav__title"
                        page="user"
                        child="notifications">
                        <kui-icon
                            [size]="1.5"
                            [fixedWidth]="true"
                            name="bells"
                            [notification]="unseedNotifications > 0"></kui-icon>
                        <span class="sub-nav__title-text">{{ 'MENU.USER_NOTIFICATIONS' | translate }}</span>
                    </a>
                </div>
            </div>

            <div class="position-relative nav-section__item">
                <a kui-nav-link kui-action (click)="doLogout()">
                    <kui-icon [size]="1.5" [fixedWidth]="true" name="power-off"></kui-icon>
                    <span kui-nav-link-text>{{ 'MENU.LOGOUT' | translate }}</span>
                </a>
                <div kui-nav-sub
                    [class.p-0]="!(sidebarToggle$ | async) || isMobile">
                    <a *ngIf="!isMobile"
                        kui-action
                        class="sub-nav__title"
                        (click)="doLogout()">
                        <kui-icon [size]="1.5"
                            [fixedWidth]="true"
                            name="power-off"></kui-icon>
                        <span class="sub-nav__title-text">{{ 'MENU.LOGOUT' | translate }}</span>
                    </a>
                </div>
            </div>
        </kui-nav-section>

    </kui-nav>
  </kui-menu>

  <div class="app-content" [ngStyle]="{container: showSidebar }">

    <kui-top-nav
      [logo]="clientLogo"
      [clientname]="clientName"
      [user]="user"
      [avatar]="userAvatar"
      [navigationGroups]="groups"
      [notification]="unseedNotifications > 0"
      (onProfileAction)="profileAction($event)"
    ></kui-top-nav>

    <div class="app-content-body d-flex flex-column">
        <kui-card *ngIf="showIE11Message"
            class="position-fixed p-3 w-100"
            style="z-index: 1;">
            <kui-card-body>
                <div class="d-flex py-3 px-4 align-items-center w-100">
                    <span class="flex-1">{{'LOGIN.IE11.MESSAGE' | translate}}</span>
                    <button kui-button
                        (click)="gotoBrowserList()"
                        class="ml-3"
                        size="sm">{{ 'LOGIN.IE11.ACTION' | translate }}</button>
                </div>
            </kui-card-body>
        </kui-card>

      <kui-alert type="danger" class="key-alert-wrapper" *ngIf="errorMessage">
        <kui-icon name="exclamation-triangle" size="sm"></kui-icon>
        <span class="flex-1">{{errorMessage}}</span>
        <button kui-button (click)="doHardRefresh()" class="ml-auto" color="light" size="sm">{{ 'DIALOG.REFRESH' | translate }}</button>
      </kui-alert>
      <kui-alert type="notify" class="key-alert-wrapper" *ngIf="noticeMessage">
        <kui-icon name="child" size="sm"></kui-icon>
        <span class="flex-1">{{noticeMessage}}</span>
        <button kui-button (click)="doHardRefresh()" class="ml-auto mr-3" color="light" size="sm">{{ 'DIALOG.UPDATE' | translate }}</button>
        <kui-icon kui-action name="times" (click)="noticeMessage = null">{{ 'DIALOG.CLOSE' | translate }}</kui-icon>
      </kui-alert>

      <key-page-external-tabs></key-page-external-tabs>
      <key-page-external-tab></key-page-external-tab>

      <router-outlet></router-outlet>
    </div>
  </div>

  <kui-modal #errorModal class='error-modal' [title]="'ERROR.UNEXPECTED_ERROR' | translate">
    <div class="p-4">

      <div class="d-flex flex-row pb-4">
        <div>
            <kui-icon size="xl" name="exclamation-triangle" class="text-primary"></kui-icon>
        </div>
        <div class="flex-1 pl-3">
            {{ 'ERROR.UNEXPECTED_ERROR_OCCURRED_RELOAD' | translate }}
        </div>
      </div>
      <div *ngIf="errorModalInfo">
        <h3>{{ errorModalInfo.name }}</h3>
        <code>{{ errorModalInfo.message }}</code>
      </div>
      <p class="mt-4">{{ 'ERROR.NOTIFIED' | translate }}</p>
    </div>
  </kui-modal>


  <kui-modal #notifyModal class='notify-modal'>
      <div class="p-4" *ngIf="notification">
        <div class="d-flex flex-row">
          <div [ngSwitch]="notification.type">
              <kui-icon *ngSwitchCase="'error'" size="xl" name="exclamation-triangle" class="text-danger"></kui-icon>
              <kui-icon *ngSwitchCase="'warn'" size="xl" name="exclamation-circle" class="text-warning"></kui-icon>
              <kui-icon *ngSwitchCase="'info'" size="xl" name="info-circle" class="text-info"></kui-icon>
          </div>
          <div class="flex-1 pl-3">
              {{ notification.message }}
          </div>
        </div>
      </div>
   </kui-modal>

</key-theme>
