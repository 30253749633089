import * as Color from 'color';
import { MAP_COLORS, MAP_COLORS_ENUM } from '../map/map.markers';
import { THEME_OFF_BLACK, THEME_OFF_WHITE } from '../theme/theme-defaults';

export function getIconStyles(color: string): { color: string, background: string } {
    const background = MAP_COLORS[MAP_COLORS_ENUM[color]] || color;
    return {
        background,
        color: color && (Color(background).luminosity() > .9 ? THEME_OFF_BLACK : THEME_OFF_WHITE), // using luminosity() instead of isDark() for finer control
    };
}
