<ng-container *ngIf="formattedCellset">
    <key-analytics-data-table
        *ngIf="type === 'data'"
        [cellset]="formattedCellset"
        [selected]="selectedMeasure"
        class="flex-1"
        (onHeaderClick)="emitMeasureSelection($event)"></key-analytics-data-table>
    <key-analytics-pivot-table
        *ngIf="type === 'pivot'"
        [cellset]="formattedCellset"
        [selected]="selectedMeasure"
        class="flex-1"
        (onHeaderClick)="emitMeasureSelection($event)"></key-analytics-pivot-table>
</ng-container>