import { Component, Input, OnInit, HostBinding, Output, EventEmitter, OnChanges, SimpleChanges, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { GridsterConfig, GridType, CompactType, DisplayGrid, GridsterItemComponent, GridsterItem, GridsterComponent, GridsterItemComponentInterface } from 'angular-gridster2';
import { mergeWith, isEmpty, set, debounce, cloneDeep } from 'lodash';
import { DashboardWidgetComponent, ShareWidgetEvent, WIDGET_SIZES, SelectWidgetSettingsEvent } from '../widget/widget.component';
import { MatchMediaService, NavigationService } from 'app/services';
import { BaseComponent } from 'app/shared/components';
import { AnalyticsSettings, AvailableSettings, AnalyticsCustomSettings, AnalyticsCellSetFilters } from 'app/shared/components/analytics';
import { removeSpaces } from 'app/shared/utils/string.utils';
import { TranslateService } from '@ngx-translate/core';
import { DashboardsService } from 'app/services/dashboards/dashboards.service';
import { AnalyticsCreateWidgetModalOptions, AnalyticsCreateWidgetModalComponent } from 'app/shared/components/analytics/modals/create-widget/create-widget.component';
import { DashboardWidget, AnalyticsOutputSettings } from '@key-telematics/fleet-api-client';
import { ModalService } from 'app/shared/components/modal';
import { SettingsMessage } from 'app/shared/components/analytics/settings/settings.component';
import { SidePanelService } from '../../services/side-panel.service';
import { IKuiModalAction } from 'app/key-ui';
import { AnalyticsService } from 'app/shared/components/analytics/services/analytics.service';
import { AppService } from 'app/app.service';
import { Router } from '@angular/router';
import { EntityService } from 'app/services/entity/entity.service';
import { KuiSnackbarService } from 'app/key-ui/snackbar/snackbar.service';
import { AnalyticsChartType, AnalyticsChartData } from 'app/shared/components/analytics/chart/chart.component';
import { AnalyticsChartPieData } from 'app/shared/components/analytics/chart/pie/pie.service';

@Component({
    selector: 'key-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent extends BaseComponent implements OnInit, OnChanges {
    get hasWidgets(): boolean {
        return !isEmpty(this.widgets);
    }

    constructor(
        public dashboardsService: DashboardsService,
        private app: AppService,
        private analytics: AnalyticsService,
        private matchMedia: MatchMediaService,
        private i18n: TranslateService,
        private modal: ModalService,
        private sidePanel: SidePanelService,
        private router: Router,
        public navigation: NavigationService,
        private entities: EntityService,
        private snackbar: KuiSnackbarService
    ) {
        super();

        this.on(this.matchMedia.isMobileOrTablet, isMobile => {
            this.isMobile = isMobile;
            this.items.forEach(item => {
                item.size = isMobile ? WIDGET_SIZES.MEDIUM : this.getWidgetSize(item.cols, item.rows);
            });
        });

        this.on(this.sidePanel.openSidePanel$, panel => {
            if (panel !== 'widget-settings') {
                this.clearSelectedWidget();
            }
        });

        this.on(this.navigation.activeRoot$, page => {
            // gridster doesn't detect browser size changes while hidden, we need to issue a
            // resize whenever we navigate back here in case the browser was resized
            // https://git.kt1.io/key-telematics/fleet-ui/issues/328
            setTimeout(() => {
                if (page === 'dashboards' && this.gridster) {
                    this.gridster?.options?.api?.resize();
                }
            }, 50);
        });

    }
    options: GridsterConfig;
    isDragging: boolean;
    isMobile: boolean;

    removeWidgetModalActions: IKuiModalAction[];

    settingsMessage: SettingsMessage;
    selectedWidget: { id: string, type: string };
    selectedWidgetSettings: AnalyticsSettings;
    selectedWidgetFilters: AnalyticsCellSetFilters;
    selectedWidgetTab = 'general';
    customWidgetSettings: AnalyticsCustomSettings[];
    visibleWidgetSettings: AvailableSettings;
    transformX: string;
    pieSegments: number;
    statSeries: string[];
    items: {
        widgetId: string;
        x: number;
        y: number;
        rows: number;
        cols: number;
        size: string;
    }[] = [];
    latestPlacementChanges: { [id: string]: { change: GridsterItem, component: GridsterItemComponentInterface } } = {};
    baseReportId: string;
    baseReportSettings: AnalyticsOutputSettings;

    debouncedUpdateWidgetPlacements = debounce(this.updateWidgetPlacements, 200, { trailing: true, leading: false });

    @Input() widgets: { [id: string]: DashboardWidget };
    @Input() title: string;
    @Input() id: string;
    @Input() viewOnly: boolean;
    @Input() showDelete = true;
    @Input() showShare = true;
    @Input() showSettings = true;
    @Input() useSampleData: boolean;
    @Input() isDashboardTemplate = false;
    @Input() undoDeletion: boolean;

    @Output() onUpdateDashboard = new EventEmitter<void>();
    @Output() onUpdateWidget = new EventEmitter<{ id: string, update: DashboardWidget }>();
    @Output() onRemoveWidget = new EventEmitter<string>();
    @Output() onUpdateWidgetsPlacement = new EventEmitter<{ [id: string]: DashboardWidget }>();

    @Output() addWidgetToPollQueue = new EventEmitter<string>();
    @Output() removeWidgetFromPollQueue = new EventEmitter<string>();

    @ViewChild(GridsterComponent) gridster: GridsterComponent;
    @ViewChildren(GridsterItemComponent) cardRefs: QueryList<GridsterItemComponent>;
    @ViewChildren(DashboardWidgetComponent) widgetRefs: QueryList<DashboardWidgetComponent>;

    @HostBinding('class') hostClassnames = 'd-flex flex-1';

    get disableFilterSettings() {
        return this.isDashboardTemplate && this.app.user.owner.type !== 'system';
    }

    ngOnInit() {
        this.on(this.entities.entityUpdated$, async (entity) => {
            if (entity && entity._type === 'dashboardtemplate') {
                try {
                    // remove template intatiated dashboard from cache so that it can load fresh
                    const dashboard = await this.dashboardsService.getDashboard(this.id); // from cache
                    if (entity.id === (dashboard.parent && dashboard.parent.id)) {
                        this.dashboardsService.dashboardCache.delete(this.id);
                    }
                } catch (error) {
                    console.log('ERROR: getDashboard', error); // don't inform the user about this error, it doesn't really matter if this fails
                }
                return entity;
            }
        });

        this.options = {
            gridType: GridType.ScrollVertical,
            compactType: CompactType.CompactLeftAndUp,
            margin: 15,
            mobileBreakpoint: 640,
            minCols: 6,
            maxCols: 6,
            minRows: 2,
            maxItemCols: 3,
            maxItemRows: 2,
            scrollSensitivity: 10,
            scrollSpeed: 20,
            ignoreMarginInRow: false,
            draggable: {
                enabled: !this.viewOnly,
                dragHandleClass: 'drag',
                ignoreContentClass: 'non-drag',
                start: () => this.isDragging = true,
                stop: () => { this.isDragging = false; },
            },
            resizable: {
                enabled: !this.viewOnly,
                handles: {
                    s: true,
                    e: true,
                    n: false,
                    w: true,
                    se: true,
                    ne: false,
                    sw: true,
                    nw: false,
                },
            },
            swap: false,
            pushItems: true,
            displayGrid: DisplayGrid.OnDragAndResize,
            scrollToNewItems: true,
            itemChangeCallback: (change: GridsterItem, component: GridsterItemComponentInterface) => {
                // add placement change to 'queue' and emit all bundled changes only once
                this.latestPlacementChanges[change.widgetId] = { change, component };
                this.debouncedUpdateWidgetPlacements();
            },
        };
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.widgets && this.widgets) {
            // update settings and only clear selected widget if it does not exist anymore
            if (this.selectedWidget && this.widgets[this.selectedWidget.id]) {
                this.updateSelectedWidgetSettings();
            } else {
                this.clearSelectedWidget();
            }

            this.items = Object.entries(this.widgets).filter(entry => entry[1]).map(([widgetId, widget]) => {
                const placement = widget.placement || { col: 0, row: 0, rowSpan: 1, colSpan: 1 };
                return {
                    widgetId,
                    x: placement.col,
                    y: placement.row,
                    rows: placement.rowSpan,
                    cols: placement.colSpan,
                    size: this.getWidgetSize(placement.colSpan, placement.rowSpan),
                };
            });
        }
    }

    updateSelectedWidgetSettings() {
        const widget = this.widgets[this.selectedWidget.id];
        if (widget) {
            const options = widget.options && widget.options.analytics;
            const { name, description } = widget;
            this.customWidgetSettings[0].values = { name, description };
            this.selectedWidgetSettings = this.analytics.convertOutputToAnalyticsSettings({
                ...options,
                name,
                description,
            }, false);
        }
    }

    updateWidgetPlacements() {
        const placementUpdateEvent = {};

        Object.values(this.latestPlacementChanges).forEach(({ change, component }) => {
            const id = component.el.id;
            const index = this.items.findIndex(x => x.widgetId === id);
            let widget = this.widgets && this.widgets[id];
            if (widget) {
                const update = {
                    placement: {
                        col: change.x,
                        row: change.y,
                        colSpan: change.cols,
                        rowSpan: change.rows,
                    },
                };
                widget = { ...widget, ...update };
                this.items[index].size = this.getWidgetSize(change.cols, change.rows);
                placementUpdateEvent[id] = update;
            }
        });

        this.latestPlacementChanges = {};
        this.onUpdateWidgetsPlacement.emit(placementUpdateEvent);
    }

    async removeWidget(widget: { id: string, name: string }) {
        const index = this.items.findIndex(x => x.widgetId === widget.id);
        const tempCache = cloneDeep(this.items); // save widgets incase of undo

        // visually remove widget immediately and emit to parent component so it can update on the api.
        this.items.splice(index, 1);

        const undo = this.undoDeletion && await this.snackbar.banner({
            description: this.i18n.instant('DASHBOARDS.DELETE_CONFIRM'),
            icon: 'trash-alt',
            actionText: this.i18n.instant('DIALOG.UNDO'),
        });
        if (undo) {
            this.items = tempCache;
        } else {
            this.onRemoveWidget.emit(widget.id);
        }
    }

    addWidget() {
        // TODO: future dude!!
    }

    clearSelectedWidget() {
        this.selectedWidget = null;
        this.selectedWidgetSettings = null;
        this.selectedWidgetTab = null;
        this.transformX = null;
        if (this.options) {
            this.options.resizable.enabled = true;
            this.options.draggable.enabled = true;
            if (this.options.api) {
                this.options.api.optionsChanged();
            }
        }
    }

    setSelectedWidget(event: SelectWidgetSettingsEvent) {
        const { id, settings, type, inherited, filters } = event;

        this.settingsMessage = null;
        this.transformX = null;

        if (this.options) {
            this.options.resizable.enabled = !id;
            this.options.draggable.enabled = !id;
            if (this.options.api) {
                this.options.api.optionsChanged();
            }
        }

        // NOTE: the optionsChanged() method above also resets the transforms, so no need to loop and unset the previous scale transform
        if (id) {
            this.cardRefs.forEach(card => {
                if (card.el.id === id && !this.isMobile) {
                    // the sidebar is 300px wide so we have to check if it will overlay the
                    // selected widget and shift the widget into clear view accordingly
                    const offset = +card.el.style.transform.match(/\d+px/)[0].replace('px', '');
                    const viewport = card.gridster.curWidth;
                    const width = card.width;
                    const sum = viewport - (offset + width);
                    this.transformX = `translateX(${sum < 300 ? `-${335 - sum}px` : '0'})`;
                } else {
                    card.el.style.transform = card.el.style.transform + ' scale(.95)';
                }
            });
        }

        if (inherited) {
            this.customWidgetSettings = null;
            const buttons = [{
                text: this.i18n.instant('DASHBOARDS.CLONE_DASHBOARD'),
                color: 'primary',
                action: () => this.dashboardsService.cloneDashboard(this.id),
            }];
            // add edit template button if user is not client
            if (this.app.user.owner.type !== 'client') {
                buttons.unshift({
                    text: this.i18n.instant('DASHBOARDS.EDIT_TEMPLATE'),
                    color: 'secondary',
                    action: async () => {
                        const { parent } = await this.dashboardsService.getDashboard(this.id); // using cache here
                        const { owner } = await this.dashboardsService.getDashboardTemplate(parent.id);
                        this.router.navigate(['admin', owner.type, owner.id, 'dashboardtemplates', 'grid', 'dashboardtemplate', parent.id]);
                    },
                });
            }
            this.settingsMessage = {
                icon: 'lock',
                text: this.i18n.instant('DASHBOARDS.ERRORS.SETTINGS_FROM_TEMPLATE'),
                buttons,
            };
        } else {
            this.settingsMessage = null;
            const { name, description, updates, timeOptions, shiftsEnabled } = settings || {} as any;
            this.customWidgetSettings = [{
                title: this.i18n.instant('DASHBOARDS.WIDGET.GENERAL'),
                id: 'general',
                icon: 'info-circle',
                actions: [{
                    name: this.i18n.instant('DASHBOARDS.WIDGET.OPEN_REPORT'), color: 'secondary', click: async () => this.showReportFromWidgetId(this.selectedWidget.id),
                }],
                form: {
                    groups: [{
                        fields: [
                            {
                                type: 'radio', id: 'widgetType', title: this.i18n.instant('DASHBOARDS.WIDGET.TYPE'), values: [
                                    { key: 'chart', value: this.i18n.instant('DASHBOARDS.WIDGET.CHART') },
                                    { key: 'pie', value: this.i18n.instant('DASHBOARDS.WIDGET.PIE') },
                                    { key: 'stat', value: this.i18n.instant('DASHBOARDS.WIDGET.STAT') },
                                    //         { key: 'grid', value: this.i18n.instant('DASHBOARDS.WIDGET.GRID') },
                                    //         { key: 'text', value: this.i18n.instant('DASHBOARDS.WIDGET.TEXT') },
                                ],
                            },
                            { type: 'text', id: 'name', title: this.i18n.instant('DASHBOARDS.WIDGET.NAME'), required: true, placeholder: this.i18n.instant('DASHBOARDS.WIDGET.ENTER_NAME') },
                            { type: 'memo', id: 'description', title: this.i18n.instant('DASHBOARDS.WIDGET.DESCRIPTION'), required: true, placeholder: this.i18n.instant('DASHBOARDS.WIDGET.ENTER_DESCRIPTION') },
                        ],
                    }],
                },
                values: {
                    name,
                    description,
                    widgetType: type,
                },
            }];

            // TODO: make visible to everyone when the world is ready for it
            if (this.app.isSystemUser) {
                this.customWidgetSettings.push({
                    title: this.i18n.instant('DASHBOARDS.WIDGET.UPDATE'),
                    id: 'updates',
                    icon: 'sync-alt',
                    form: {
                        groups: [{
                            fields: [
                                ...(!shiftsEnabled ? [
                                    {
                                        type: 'time', id: 'updates.daily.time', title: this.i18n.instant('DASHBOARDS.WIDGET.DAILY_TIME'), setValue: (field, values, value) => {
                                            const [HH, mm] = value.split(':');
                                            set(values, field.id, `${HH}:${mm}`);
                                        },
                                    },
                                ] : []),
                                ...(!shiftsEnabled && (timeOptions || []).includes('hours') ? [
                                    { type: 'number', id: 'updates.hourly.frequency', title: this.i18n.instant('DASHBOARDS.WIDGET.HOURLY_FREQUENCY'), description: this.i18n.instant('DASHBOARDS.WIDGET.FREQUENCY_DESC') },
                                    { type: 'number', id: 'updates.hourly.offset', title: this.i18n.instant('DASHBOARDS.WIDGET.HOURLY_OFFSET'), description: this.i18n.instant('DASHBOARDS.WIDGET.OFFSET_DESC') },
                                ] : []),
                                ...(shiftsEnabled ? [
                                    { type: 'number', id: 'updates.shifts.frequency', title: this.i18n.instant('DASHBOARDS.WIDGET.HOURLY_FREQUENCY'), description: this.i18n.instant('DASHBOARDS.WIDGET.FREQUENCY_DESC') },
                                    { type: 'number', id: 'updates.shifts.offset', title: this.i18n.instant('DASHBOARDS.WIDGET.HOURLY_OFFSET'), description: this.i18n.instant('DASHBOARDS.WIDGET.OFFSET_DESC') },
                                ] : []),
                            ],
                        }],
                    },
                    values: { updates },
                });
            }
        }

        this.setVisibleWidgetSettings(!inherited, type);
        this.selectedWidget = { id, type };
        this.selectedWidgetSettings = settings;
        this.selectedWidgetFilters = filters;
        this.sidePanel.openSidePanel('widget-settings');
    }

    handleChartLoad(event: AnalyticsChartData) {
        if (this.selectedWidget) {
            this.pieSegments = (event as AnalyticsChartPieData).segments;
            this.statSeries = event.datasets.map(({ uniquename }) => uniquename);
        }
    }

    showReportFromWidgetId(id: string) {
        const widget = this.widgets[id];
        
        this.baseReportSettings = cloneDeep(widget.options.analytics);

        const dataSourceId = widget.dataSource.id;
        const definitionId = widget.dataSource.options && widget.dataSource.options.analytics.definitionId;
        this.baseReportId = dataSourceId || definitionId;
    }

    hideReport({ shouldUpdateParent }) {
        this.baseReportId = null;

        if (shouldUpdateParent) {
            this.onUpdateDashboard.emit();
        }
    }

    setVisibleWidgetSettings(show: boolean, type?: AnalyticsChartType) {
        this.visibleWidgetSettings = {
            daterange: show,
            flipped: show,
            legend: show,
            levels: show,
            measures: show,
            presets: false,
            series: show && type === 'chart',
            sort: show,
            chart: show && type === 'chart',
            stacked: show,
            yAxes: show,
            xAxis: show,
            pie: show && type === 'pie',
            stat: show && type === 'stat',
            dataLabels: show,
            filters: show,
            calcMeasures: false,
        };
    }

    clearSeriesItems(keys: string[]) {
        const { series } = this.selectedWidgetSettings.graph;
        const update = {
            graph: {
                ...this.selectedWidgetSettings.graph,
                series: {
                    ...series,
                    ...keys.reduce((all, key) => ({
                        ...all,
                        [removeSpaces(key.toLowerCase())]: null,
                    }), {}),
                },
            },
        };

        this.updateWidgetSettings(update);
    }

    updateWidgetSettings(update: { [key: string]: any; }) {
        const escapeNonObjectValue = (_obj, src) => {
            // we want to replace the changed value instead of merging it
            if (!(src instanceof Object) || Array.isArray(src)) {
                return src;
            }
        };

        const { inherited } = this.widgets[this.selectedWidget.id];

        // get the latest widgetType from the customWidgetSettings object
        const widgetType = this.customWidgetSettings && this.customWidgetSettings[0].values.widgetType;
        this.selectedWidget.type = widgetType;
        this.setVisibleWidgetSettings(!inherited, widgetType);

        this.selectedWidgetSettings = { ...mergeWith(this.selectedWidgetSettings, update, escapeNonObjectValue) };
        const activeWidget = this.widgetRefs && this.widgetRefs.find(x => x.widgetId === this.selectedWidget.id);
        // graph, pie, stat, name and description settings will get a cached cellset, so no need to debounce them
        activeWidget.updateWidgetSettings(this.selectedWidgetSettings, !update.graph && !update.pie && !update.stat && !update.name && !update.description);
    }

    shareWidgetToDashboard(event: ShareWidgetEvent) {
        const { cellset, settings, id, definitionId } = event;
        const { placement, dataSource, widgetType } = this.widgets[id];
        this.modal.open<AnalyticsCreateWidgetModalOptions>(AnalyticsCreateWidgetModalComponent, {
            data: {
                cellset,
                settings,
                definitionId,
                name: settings.name,
                description: settings.description,
                type: widgetType,
                dataSourceId: dataSource && dataSource.id,
                placement: {
                    colSpan: placement && placement.colSpan,
                    rowSpan: placement && placement.rowSpan,
                },
                excludedDashboardId: this.id,
            },
            actions: {
                close: () => {
                    this.modal.close();
                },
            },
        });
    }

    getWidgetSize(colSpan: number, rowSpan: number): string {
        if (rowSpan === 1) {
            if (colSpan === 1) {
                return WIDGET_SIZES.SMALL;
            }
            return WIDGET_SIZES.MEDIUM;
        }
        return WIDGET_SIZES.LARGE;
    }
}
