import { Component, OnDestroy, OnInit } from '@angular/core';

import { MapZone } from 'app/shared/components/map/map.component';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MapZonesService } from '../../../map-zones.service';
import { MapOptionService } from '../../map-option.service';
import { ZoneType } from '../../model';
import { ZoneSelectorService } from '../../services';
import { MapOption } from '../map-option';
import { MapOptionZoneEditorComponent, ZoneEditorConfig } from '../map-option-zone-editor';
import { MapOptionComponent } from '../map-option.component';

export interface ZoneSelectorConfig {
    selectableTypes: ZoneType[];
}

@Component({
    styleUrls: ['./map-option-zone-selector.component.scss'],
    templateUrl: './map-option-zone-selector.component.html',
})
export class MapOptionZoneSelectorComponent implements MapOptionComponent<ZoneSelectorConfig>, OnInit, OnDestroy {

    static ID = 'map-option-zone-selector-component';

    private destroyed: Subject<void> = new Subject();
    compact: boolean = false;
    term: string = '';
    data: ZoneSelectorConfig;

    public zones$: Observable<MapZone[]>;

    constructor(
        private mapOptionService: MapOptionService,
        private zoneSelector: ZoneSelectorService,
        private mapZoneService: MapZonesService,
    ) { }

    ngOnInit(): void {
        this.mapOptionService.isCompact$.pipe(
            takeUntil(this.destroyed)
        ).subscribe(res => {
            this.compact = res;
        });
        this.zoneSelector.setTypeFilter(this.data.selectableTypes);
        this.zones$ = this.zoneSelector.filteredZones$;
        this.mapZoneService.zoneClicked$.pipe(
            takeUntil(this.destroyed),
            filter(layer => this.data.selectableTypes.includes(layer?.options?.type))
        ).subscribe(clickEvent => this.selectZone(clickEvent.id));
    }

    ngOnDestroy(): void {
        this.destroyed.next();
        this.clearSearchTerm();
    }

    updateSearchTerm(term: string) {
        this.term = term;
        this.zoneSelector.setNameFilter(term);
    }

    clearSearchTerm() {
        this.updateSearchTerm('');
    }

    selectZone(zoneId: string) {
        const isRoute = this.data.selectableTypes.includes('route');
        const editOption: MapOption<ZoneEditorConfig> = {
            component: MapOptionZoneEditorComponent,
            data: {
                editorAction: 'zoneedit',
                zoneId,
            },
            id: MapOptionZoneEditorComponent.ID,
            title: isRoute ? 'LEAFLET.ZONES.EDIT_ROUTE' : 'LEAFLET.ZONES.EDIT_ZONE',
            icon: MapOptionZoneEditorComponent.ICON
        }
        this.mapOptionService.load(editOption);
    }

}

