import { Component, Input, Output, EventEmitter, HostBinding, ChangeDetectorRef } from '@angular/core';
import { FormBuilderField } from 'app/shared/components/form-builder';

@Component({
    selector: 'key-form-builder-swatches-field',
    templateUrl: './swatches.component.html',
    styleUrls: ['./swatches.component.scss'],
})
export class KeyFormBuilderSwatchesFieldComponent {

    @Input() field: FormBuilderField;
    @Input() values: { [key: string]: any };
    @Input() error: string; // set this error value externally to have the default error highligh and display kick in

    @Output() onChange: EventEmitter<{ dirty: boolean }> = new EventEmitter();

    @HostBinding('class') classes = 'd-flex align-items-center flex-wrap';

    constructor(private ref: ChangeDetectorRef) { }

    getValue(key: string): string {
        return this.values[key];
    }

    setValue(key: string, value: string) {
        this.values[key] = value;
        this.onChange.emit({ dirty: true });
    }

    validate(): boolean {
        const keys = this.field.values.map(x => x.key);
        return !this.field.required || Object.entries(this.values).filter(x => keys.includes(x[0]) && x[1]).length === keys.length;
    }

    update() {
        this.ref.markForCheck();
    }

}
