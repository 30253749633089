<div class="d-flex align-items-center w-100">

    <kui-dropdown class="flex-1"
        [style]="'secondary'"
        [isFormControl]="true"
        [outlined]="true"
        [menu]="items">

        <kui-dropdown-button class='color-item w-100 d-flex'>
            <span [ngStyle]="{ 'background-color': color, 'border-color': borderColor }"
                class='color-swatch'>
            </span>
            <span class='flex-1 overflow-hidden text-left'>
                {{ text }}
            </span>
        </kui-dropdown-button>

    </kui-dropdown>

    <span style='position: relative'>

        <kui-icon class="picker ml-2"
            size="sm"
            name="fill"
            [(colorPicker)]="color"
            [cpPosition]="'left'"
            (colorPickerChange)="comboChanged($event)"
            (cpFallbackColor)="'#fff'"
            (cpToggleChange)="addCustomColor($event)">
        </kui-icon>
    </span>

</div>

<div *ngIf="field.required && touched && !value"
    class="invalid-feedback d-block">
    <div>{{ 'FORMS.SHARED.REQUIRED' | translate:{ field: field.title } }}</div>
</div>
<div *ngIf="error"
    class="invalid-feedback d-block">
    <div>{{ error }}</div>
</div>