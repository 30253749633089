import { FormBuilderFieldValueHandlers, FormBuilderField } from './form-builder.model';
import { get, set } from 'lodash';
import { CustomFieldDefinition } from '@key-telematics/fleet-api-client';
import * as moment from 'moment-timezone';
import { MeasurementUnitsService } from 'app/services';
import { SectionField } from '../admin/entities/utils';

export const MAGIC_NO_CHANGE_VALUE = '<!--nochange-->';

export function IdNameToComboValues(items: { id: string, name?: string }[]): { key: string, value: string }[] {
    return items.map(x => ({ key: x.id, value: x.name })).sort((a, b) => a.value.localeCompare(b.value));
}



/** Handles combos where the input and output is a plain string */
export const ComboValueHandlers: FormBuilderFieldValueHandlers = {

    getText(field: FormBuilderField, values: any): any {
        const value = get(values, field.id, undefined);
        const item = field.values.find(x => x.key === value);
        return (item && item.value.toString()) || null;
    },

    getValue(field: FormBuilderField, values: any): any {
        return get(values, field.id, undefined);
    },

    setValue(field: FormBuilderField, values: any, value: any): void {
        if (value === MAGIC_NO_CHANGE_VALUE || value === undefined) {
            delete values[field.id];
        } else {
            set(values, field.id, value);
        }
    },

    valuesEqual(_field: FormBuilderField, value1: string, value2: string): boolean {
        return value1 === value2;
    },
};


/** Handles combos where the input and output is an array of string objects */
export const ArrayComboValueHandlers: FormBuilderFieldValueHandlers = {

    getText(field: FormBuilderField, values: any): string {
        const arr = get(values, field.id, []) as string[];
        return arr && arr.join(', ') || null;
    },

    getValue(field: FormBuilderField, values: any): string[] {
        const arr = get(values, field.id, []) as string[];
        return arr || null;
    },

    setValue(field: FormBuilderField, values: any, value: any): void {
        if (value === MAGIC_NO_CHANGE_VALUE || value === undefined) {
            delete values[field.id];
        } else {
            set(values, field.id, value);
        }
    },

    valuesEqual(_field: FormBuilderField, value1: string[], value2: string[]): boolean {
        return JSON.stringify((value1 || []).sort()) === JSON.stringify((value2 || []).sort());
    },

};


/** Handles combos where the input and output is an IdName object */
export const IdNameComboValueHandlers: FormBuilderFieldValueHandlers = {

    getText(field: FormBuilderField, values: any): string {
        const val = values[field.id];
        return val && val.name || null;
    },

    getValue(field: FormBuilderField, values: any): string {
        const val = values[field.id];
        return val && val.id || null;
    },

    setValue(field: FormBuilderField, values: any, value: any): void {
        const v = field.values.find(x => x.key === value);
        if (v && v.key) {
            if (v.key === MAGIC_NO_CHANGE_VALUE || v.key === undefined) {
                delete values[field.id];
            } else {
                values[field.id] = { id: v.key, name: v.value };
            }
        } else {
            if (value === undefined) {
                delete values[field.id]
            }
        }
    },

    valuesEqual(_field: FormBuilderField, value1: { id: string, name?: string }, value2: { id: string, name?: string }): boolean {
        return (value1 && value1.id) === (value2 && value2.id);
    },

};

export const mapResultToIdNameCount = ({ count, items }) => ({
    count,
    items: items.map(x => ({ id: x.id, name: x.name })),
});

/** Handles combos where the input and output is an array of IdName objects */
export const IdNameArrayComboValueHandlers: FormBuilderFieldValueHandlers = {

    getText(field: FormBuilderField, values: any): string {
        const arr = values[field.id] as any[];
        return arr && arr.map(x => x.name).join(', ') || null;
    },

    getValue(field: FormBuilderField, values: any): string[] {
        const arr = values[field.id] as any[];
        return arr && arr.map(x => x.id) || null;
    },

    setValue(field: FormBuilderField, values: any, value: string[]): void {
        values[field.id] = [];
        value.forEach(val => {
            const v = field.values.find(x => x.key === val);
            if (v && v.key) {
                if (v.key === MAGIC_NO_CHANGE_VALUE || v.key === undefined) {
                    delete values[field.id];
                } else {
                    values[field.id].push({ id: v.key, name: v.value });
                }
            }
        });
    },

    valuesEqual(_field: FormBuilderField, value1: { id: string, name?: string }[], value2: { id: string, name?: string }[]): boolean {
        const ids1 = value1 && value1.map(x => x.id);
        const ids2 = value2 && value2.map(x => x.id);
        return JSON.stringify(ids1) === JSON.stringify(ids2);
    },

};

export function customFieldToFormBuilderField(customField: CustomFieldDefinition, measurement: MeasurementUnitsService): SectionField {
    
    switch (customField.type) {
        case 'memo': return { id: customField.id, title: customField.title, type: 'memo', required: customField.required };
        case 'combo': return { id: customField.id, title: customField.title, type: 'combo', required: customField.required, values: customField.values };
        case 'checkbox': return { id: customField.id, title: customField.title, type: 'checkbox', required: customField.required };
        case 'date': return {
            id: customField.id, title: customField.title, type: 'date', required: customField.required,
            getText: (field, values) => measurement.format(values[field.id], 'date', 'long').format,
            getValue: (field, values) => moment(values[field.id], 'YYYY/MM/DD').toISOString(),
            setValue: (field, values, value) => {
                values[field.id] = moment(value).format('YYYY/MM/DD');
            },
        };
        case 'datetime': return {
            id: customField.id, title: customField.title, type: 'datetime', required: customField.required,
            getText: (field, values) => measurement.format(values[field.id], 'date', 'long').format + ' ' + measurement.format(values[field.id], 'time').format,
            getValue: (field, values) => moment.utc(values[field.id], 'YYYY/MM/DD HH:mm:ss').toISOString(),
            setValue: (field, values, value) => {
                values[field.id] = moment(value, 'YYYY-MM-DDTHH:mm:ss').format('YYYY/MM/DD HH:mm:ss');
            },
        };
        case 'url':
            return {
                id: customField.id,
                title: customField.title,
                type: 'url',
                getHref: (field, values) => {
                    return {
                        url: values[field.id],
                        external: true,
                    }
                },
                required: customField.required,
                min: 1,
                max: 255
            };
        case 'text':
        default:
            return { id: customField.id, title: customField.title, type: 'text', required: customField.required, min: 1, max: 255 };
    }

}
