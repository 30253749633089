<div class="p-3 root">

    <div class="item-list">
        <div *ngFor="let item of items">
            <label class="form-check-label"
                (click)="itemSelected(item)">
                <span [ngStyle]="{ 'padding-left': (item.level * 18) + 'px' }"></span>
                <input [checked]="item.id === selectedId"
                    kui-radio> {{ item.name }}
            </label>
        </div>
    </div>
</div>