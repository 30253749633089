import { Component, Input, OnChanges, SimpleChanges, QueryList, ViewChildren, Output, EventEmitter, OnInit } from '@angular/core';
import { FormBuilderDefinition, FormBuilderKeyValue, FormBuilderField, FormBuilderFieldChangeEvent, FormBuilderFieldType, FormBuilderFieldOptions } from './form-builder.model';
import { AppService } from 'app/app.service';
import { EventsService } from 'app/services/events/events.service';
import { KeyFormBuilderFieldComponent } from './field/field.component';
import { TranslateService } from '@ngx-translate/core';
import { EventFilterService } from 'app/services/events/event-filter.service';
import { AssetGroupingService } from 'app/services/assets/asset-grouping.service';
import { mapResultToIdNameCount } from './utils';
import { nullAccessDenied } from 'app/services/api/api.service';

@Component({
    selector: 'key-form-builder',
    templateUrl: './form-builder.component.html',
    styleUrls: ['form-builder.component.scss'],
    providers: [EventFilterService],
})
export class KeyFormBuilderComponent implements OnChanges, OnInit {

    @Input() ownerId: string;
    @Input() form: FormBuilderDefinition;
    @Input() values: { [key: string]: any }; // this is any cause value can be string | number | string[] and possibly others too

    @Input() errors: { [field: string]: string } = {};

    @Input() inline = true;

    /** set the prefix for the translation strings to have the form titles auto translated  */
    @Input() autoTranslatePrefix: string;

    @ViewChildren(KeyFormBuilderFieldComponent) fields: QueryList<KeyFormBuilderFieldComponent>;

    lookupCache: { [key: string]: FormBuilderKeyValue[] } = {};
    knownFields: string[] = [];
    knownSearchSelectFields: FormBuilderFieldType[] = ['vendorfilter', 'distributorfilter', 'userfilter', 'devicefilter', 'costcentrefilter', 'assettagfilter'];

    /**
    * even though @input() values updates automatically when we change a field there are still some times where we would like to
    * be informed that the change happened in order to update view in real time.
    */
    @Output() onChange = new EventEmitter<FormBuilderFieldChangeEvent>();

    constructor(
        private app: AppService,
        private eventsService: EventsService,
        private filters: EventFilterService,
        private i18l: TranslateService,
        private grouping: AssetGroupingService
    ) {
    }

    ngOnInit() {
        this.ownerId = this.ownerId || (this.app.client && this.app.client.id);
        this.filters.setOwnerId(this.ownerId);
    }

    formKeyEnterPress(event: KeyboardEvent) {

        // do not block the enter key on textareas
        if (event.target && event.target['type'] === 'textarea') {
            return;
        }

        event.stopPropagation();
        event.preventDefault();
    }

    /** forces form validation, useful for before submit actions */
    validate(): boolean {
        return this.fields.reduce((result, form) => result + (form.validate() ? 0 : 1), 0) === 0;
    }

    getNonFieldErrors(): string[] {
        return this.errors ? Object.keys(this.errors).filter(field => !this.knownFields.includes(field)).map(field => this.errors[field]) : [];
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.form && this.form) {
            this.knownFields = this.form.groups.map(group => group.fields.map(field => field.id)).reduce((arr, items) => [...arr, ...items], []);
            this.sanitizeFields(this.form);
        }
    }

    /** The legacy api allowed report forms to pass lookup URL's for doing lookups. Since the v1 and v2 api's are nothing alike,
     *  we need to do some special handling to get these values ourselves.
      */
    sanitizeFields(form: FormBuilderDefinition) {

        const parseFields = (fields: FormBuilderField[]) => {
            fields.forEach(field => {

                if (this.autoTranslatePrefix) {
                    field.title = this.i18l.instant(`${this.autoTranslatePrefix}.${field.title}`);
                    field.placeholder = field.placeholder && this.i18l.instant(`${this.autoTranslatePrefix}.${field.placeholder}`);
                }

                if (field.type === 'combo' && field.lookupUrl === 'client/{clientId}/assets') { // this is a single asset selection
                    field.lookupUrl = null;
                    field.type = 'assetfilter';
                    field.max = 1;
                }

                if (field.type === 'combo' && field.lookupUrl === 'zone?owner={clientId}') { // this is a single asset selection
                    field.lookupUrl = null;
                    field.type = 'zonefilter';
                    field.max = 1;
                }

                if (field.type === 'filter:zones') {
                    field.type = 'zonefilter';
                }

                if (field.lookupUrl && !field.lookupFunc) {
                    field.lookupFunc = () => this.lookupFunc(field);
                }

                if (this.knownSearchSelectFields.includes(field.type)) {
                    field.options = {
                        ...field.options,
                        ...this.getSearchSelectFieldPromiseMethods(field),
                    };

                    // check strings to see if they have been translated
                    const isTranslated = (str: string) => !!str.replace(/[A-Z]+|\.|\_/g, '');

                    const description = this.i18l.instant(`FORMS.${field.type.toUpperCase()}.DESCRIPTION_${field.max === 1 ? 'SINGLE' : 'MULTIPLE'}`);
                    field.description = isTranslated(description) && description;

                    const placeholder = this.i18l.instant(`FORMS.${field.type.toUpperCase()}.PLACEHOLDER`);
                    field.placeholder = isTranslated(placeholder) && placeholder;

                    // change the type only after the rest as they rely on the actual type of the field
                    field.type = 'searchselect';

                }

                // make sure all of our fields have the same ownerId set
                if (this.ownerId) {
                    field.options = {
                        ...field.options,
                        ownerId: this.ownerId,
                    };
                }

                // recurse through any sub fields
                if (field.values && field.values.length) {
                    field.values.forEach(value => {
                        if (value.fields && value.fields.length) {
                            parseFields(value.fields);
                        }
                    });
                }

            });
        };


        form.groups.forEach(group => {
            if (this.autoTranslatePrefix && group.name) {
                group.name = this.i18l.instant(`${this.autoTranslatePrefix}.${group.name}`);
            }
            parseFields(group.fields);
        });
    }

   getSearchSelectFieldPromiseMethods(field: FormBuilderField): Pick<FormBuilderFieldOptions, 'searchListPromise' | 'searchItemPromise'> | Error {
        switch (field.type) {
            case 'vendorfilter':
                const anyVendor = this.i18l.instant('FORMS.VENDORFILTER.ANY_VENDOR');
                const getVendorFilter = (name: string, id: string, actorSelectionType: 'specific' | 'any') => ({
                    name: name,
                    actorType: 'vendor',
                    actorTypeId: '',
                    actorTypeName: this.i18l.instant('FORMS.VENDORFILTER.VENDOR'),
                    actorSelectionType: actorSelectionType,
                    actorId: id,
                    text: name,
                });
                const vendorOwnerId = field.options && field.options.ownerId || this.app.user.owner.id;
                return {
                    searchListPromise: async (limit, filter) => {
                        const res = await this.app.api.accounts.listVendors(vendorOwnerId, 0, limit, 'name:asc', 'state=active' + (!!filter ? `&name=*${filter}*` : ''));
                        return {
                            count: res.count,
                            items: [
                                field.max !== 1 && {
                                    name: anyVendor,
                                    id: '00000000-0000-0000-0000-000000000000',
                                    filter: getVendorFilter(anyVendor, '00000000-0000-0000-0000-000000000000', 'any'),
                                },
                                ...res.items.map(x => ({
                                    id: x.id,
                                    name: x.name,
                                    filter: getVendorFilter(x.name, x.id, 'specific'),
                                })),
                            ].filter(x => x),
                        };
                    },
                    searchItemPromise: async id => this.app.api.accounts.getVendor(id).then(x => ({ id: x.id, name: x.name })),
                };
            case 'distributorfilter':
                const anyDistributor = this.i18l.instant('FORMS.DISTRIBUTORFILTER.ANY_DISTRIBUTOR');
                const getDistributorFilter = (name: string, id: string, actorSelectionType: 'specific' | 'any') => ({
                    name: name,
                    actorType: 'distributor',
                    actorTypeId: '',
                    actorTypeName: this.i18l.instant('FORMS.DISTRIBUTORFILTER.DISTRIBUTOR'),
                    actorSelectionType: actorSelectionType,
                    actorId: id,
                    text: name,
                });
                const distributorOwnerId = field.options && field.options.ownerId || this.app.user.owner.id;
                return {
                    searchListPromise: async (limit, filter) => {
                        const res = await this.app.api.accounts.listDistributors(distributorOwnerId, 0, limit, 'name:asc', 'state=active' + (!!filter ? `&name=*${filter}*` : ''));
                        return {
                            count: res.count,
                            items: [
                                field.max !== 1 && {
                                    name: anyDistributor,
                                    id: '00000000-0000-0000-0000-000000000000',
                                    filter: getDistributorFilter(anyDistributor, '00000000-0000-0000-0000-000000000000', 'any'),
                                },
                                ...res.items.map(x => ({
                                    id: x.id,
                                    name: x.name,
                                    filter: getDistributorFilter(x.name, x.id, 'specific'),
                                })),
                            ].filter(x => x),
                        };
                    },
                    searchItemPromise: async id => this.app.api.accounts.getDistributor(id).then(x => ({ id: x.id, name: x.name })),
                };
            case 'userfilter':
                const userOwnerId = field.options && field.options.ownerId || this.ownerId;
                return {
                    searchListPromise: async (limit, filter) => this.app.api.accounts.listUsers(userOwnerId, 0, limit, 'name:asc', 'state=active' + (!!filter ? `&name=*${filter}*` : '')).then(mapResultToIdNameCount),
                    searchItemPromise: async id => this.app.api.accounts.getUser(id).then(x => ({ id: x.id, name: x.name })),
                };
            case 'devicefilter':
                const deviceOwnerId = field.options && field.options.ownerId || this.ownerId || this.app.client.id;
                return {
                    searchListPromise: async (limit, filter) => this.app.api.entities.listDevices(deviceOwnerId, 0, limit, 'name:asc', 'state=active' + (!!filter ? `&name=*${filter}*` : '')).then(mapResultToIdNameCount),
                    searchItemPromise: async id => this.app.api.entities.getDevice(id).then(x => ({ id: x.id, name: x.name })),
                };
            case 'costcentrefilter':
                const costCentreOwnerId = field.options && field.options.ownerId || this.ownerId;
                return {
                    searchListPromise: async (_limit, filter) => { 
                        const [costCentreTree, costCentres] = await Promise.all([
                            this.grouping.getCostCentresAsTree(costCentreOwnerId),
                            this.grouping.getCostCentres(costCentreOwnerId),
                        ]);
                        
                        const userCostCentre = costCentres.find(centre => centre.id === this.app.user.costCentre?.id) || costCentres.find(centre => centre.parent === 'root');
                        const filteredCCTree = this.grouping.findCCInCCTree(costCentreTree, userCostCentre);
                        const filteredCostCentres = this.grouping.getFilteredCostCentres(filteredCCTree, costCentres);

                        filter =  decodeURIComponent(filter || ''); // searchListPromise is called with the filter encoded, causing issues with spaces
                        return {
                            items: filter ? filteredCostCentres.filter(centre => centre.name.toLowerCase().includes(filter.toLowerCase())) : filteredCCTree,
                        } as any;
                    },
                    searchItemPromise: async id => this.app.api.entities.getCostCentre(id),
                };
            case 'assettagfilter':
                const assetTagOwnerId = field.options && field.options.ownerId || this.ownerId || this.app.client.id;
                return {
                    searchListPromise: async (limit, filter) => this.app.api.entities.listAssetTags(assetTagOwnerId, 0, limit, 'name:asc', 'state=active' + (!!filter ? `&name=*${filter}*` : '')).then(mapResultToIdNameCount).catch(nullAccessDenied),
                    searchItemPromise: async id => this.app.api.entities.getAssetTag(id).then(x => ({ id: x.id, name: x.name })).catch(nullAccessDenied),
                };
            default:
                console.warn('UNHANDLED SEARCH SELECT FIELD TYPE', field.type);
                return new Error('We were unable to load the values for this field.');

        }
    }

    lookupFunc(field: FormBuilderField): Promise<FormBuilderKeyValue[]> {
        const cached = this.lookupCache[field.lookupUrl];
        if (cached) {
            return Promise.resolve(cached);
        } else {
            return this.getLookupPromise(field).then(results => {
                results.sort((a, b) => a.value.toString().localeCompare(b.value.toString()));
                this.lookupCache[field.lookupUrl] = results;
                return results;
            });
        }
    }

    getLookupPromise(field: FormBuilderField): Promise<FormBuilderKeyValue[]> {
        switch (field.lookupUrl) {

            case 'event/types':
            case 'event/types?expanded=true':
                return Promise.resolve(this.eventsService.getEventTypes(!field.lookupUrl.includes('?expanded=true')).map(x => ({ key: x.id, value: x.name })));


            case 'client/{clientId}/overspeedprofiles':
            case 'client/{clientId}/overspeedprofiles?recurse=true':
                const recurse = /recurse=true/.test(field.lookupUrl);
                return this.app.client
                    ? this.app.api.entities.listOverspeedProfiles(this.app.client.id, recurse, 0, 100, 'name', 'state=active').then(result => result.items.map(item => ({ key: item.id, value: item.name })))
                    : Promise.resolve([]);

            case 'alert?owner={clientId}':
                return this.app.client
                    ? this.app.api.entities.listAlerts(this.app.client.id, 0, 100, null, 'state=active').then(result => result.items.map(item => ({ key: item.id, value: item.name })))
                    : Promise.resolve([]);

            case 'client/{clientId}/ratingprofiles':
                return this.app.client
                    ? this.app.api.entities.listAssetRatingProfiles(this.app.client.id, 0, 100, 'name', 'state=active').then(result => result.items.map(item => ({ key: item.id, value: item.name })))
                    : Promise.resolve([]);

            case 'client/{clientId}/assetstateprofiles':
                return this.app.client
                    ? this.app.api.entities.listAssetStateProfiles(this.app.client.id, 0, 100, 'name', 'state=active').then(result => result.items.map(item => ({ key: item.id, value: item.name })))
                    : Promise.resolve([]);

            case 'iotype?owner={clientId}&iotype=digital_input':
                return this.app.client
                    ? this.app.api.entities.listIoTypes(this.app.client.id, true, 0, 300, 'name', 'state=active,type=digital_input').then(result => result.items.map(item => ({ key: item.id, value: item.name })))
                    : Promise.resolve([]);

            case 'iotype?owner={clientId}&iotype=digital_input,digital_output':
                return this.app.client
                    ? this.app.api.entities.listIoTypes(this.app.client.id, true, 0, 300, 'name', 'state=active,(type=digital_input|type=digital_output)').then(result => result.items.map(item => ({ key: item.id, value: item.name })))
                    : Promise.resolve([]);

            case 'iotype?owner={clientId}&iotype=analog_input,can_input,temperature_input':
                return this.app.client
                    ? this.app.api.entities.listIoTypes(this.app.client.id, true, 0, 300, 'name', 'state=active,(type=analog_input|type=can_input|type=temperature_input)').then(result => result.items.map(item => ({ key: item.id, value: item.name })))
                    : Promise.resolve([]);
            case 'asset/category?owner={clientId}':
                return this.app.client
                    ? this.app.api.entities.listAssetCategories(this.app.client.id).then(result => result.items.filter(item => item.parent === null).map(item => ({ key: item.id, value: item.name })))
                    : Promise.resolve([]);
            default:
                console.warn('UNHANDLED FIELD LOOKUP', field);
                return Promise.reject(new Error('We were unable to load the values for this field.'));
        }
    }

    onChangeHandler(data: any) {
        this.onChange.emit(data);
    }

}
