import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { MatchMediaService } from 'app/services';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base/base.component';


@Component({
    selector: 'key-image-editor',
    templateUrl: './image-editor.component.html',
})
export class KeyImageEditorComponent extends BaseComponent implements OnChanges, OnInit {
    // we don't want to overwrite @Input() cropWidth, we might need to check it again later
    _cropWidth: number;

    @Input() previewVisible = false;
    @Input() previewWidth = 100;
    @Input() previewHeight = 100;
    @Input() cropWidth = 800;
    @Input() originalImageUrl: string | SafeUrl;
    /** A value between 0 and 1 */
    @Input() paddingPercentage = 1;
    @Input() backgroundColor: string;

    @Output() imageUpdated = new EventEmitter<{ original: any; cropped: any; preview: any; }>();
    @Output() imageRemoved = new EventEmitter();

    aspectRatio = 1; // = width / height
    srcAspectRatio = 1;
    crop: {
        x1: number,
        y1: number,
        x2: number,
        y2: number
    } = { x1: -100, y1: -100, x2: 10000, y2: 10000 };

    isMobile = false;

    imageChangedEvent: any = '';
    croppedImage: any = '';
    cropperReady = false;
    hasExistingImage: boolean = false;

    @ViewChild('original', { static: true }) originalImage;
    @ViewChild('destination', { static: true }) canvas: ElementRef;

    blobImageUrl: any;

    dragging: boolean;
    uploading = false;

    @HostListener('window:paste', ['$event']) closeOnOutsideTouch(e) {
        this.handlePasteEvent(e);
    }

    constructor(
        private matchMedia: MatchMediaService
    ) {
        super();
        this.matchMedia.isMobile
            .pipe(takeUntil(this.destroyed))
            .subscribe(isMobile => {
                this.isMobile = isMobile;
            });
    }

    ngOnInit(): void {
        // The original image url could be an icon... make sure we only show this when we have a user uploaded image
        this.hasExistingImage = this.originalImageUrl?.toString()?.length > 50;
    }

    ngOnChanges() {
        this.aspectRatio = this.previewWidth / this.previewHeight;
        this.processOriginal();
    }

    onDropHandler(object) {
        this.originalImageUrl = object.event.target.result;
    }

    fileChangeEvent(event: any): void {
        this.uploading = true;
        this.cropperReady = false;
        this.imageChangedEvent = event;
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url
            reader.onload = (e) => { // called once readAsDataURL is completed
                this.originalImageUrl = (e.target as FileReader).result;
            };
        }
    }

    async imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.imageUpdated.emit({
            original: this.originalImageUrl,
            cropped: this.croppedImage,
            preview: await this.resizeOriginalToPreview(this.croppedImage, this.previewWidth, this.previewHeight),
        });
    }

    imageLoaded(_image: LoadedImage) {
        this.cropperReady = true;
        this.uploading = false;
    }

    loadImageFailed() {
        console.log('Load failed');
    }

    onBackgroundChanged(color: string) {
        this.backgroundColor = color;
        this.processOriginal();
    }

    processOriginal() {

        if (this.originalImageUrl) {

            const src = this.originalImage.nativeElement as HTMLImageElement;
            const dst = this.canvas.nativeElement as HTMLCanvasElement;

            // prevent stretching of images if src is too small
            this._cropWidth = src.width >= this.cropWidth ? this.cropWidth : src.width;

            this.srcAspectRatio = src.width / src.height;
            const xpadding = (this.srcAspectRatio < this.aspectRatio ? src.width : src.width / 2) * this.paddingPercentage;
            const ypadding = (this.srcAspectRatio < this.aspectRatio ? src.height / 2 : src.height) * this.paddingPercentage;

            dst.width = src.width + (xpadding * 2);
            dst.height = src.height + (ypadding * 2);

            const ctx = dst.getContext('2d');

            ctx.beginPath();
            ctx.rect(0, 0, dst.width, dst.height);
            if (!!this.backgroundColor && this.backgroundColor !== 'transparent') {
                ctx.fillStyle = this.backgroundColor;
                ctx.fill();
            }
            ctx.drawImage(src, xpadding, ypadding, src.width, src.height);
            dst.toBlob((blob) => {
                this.blobImageUrl = blob;
            });
        }

    }

    resizeOriginalToPreview(url, width, height): Promise<string> {
        if (this._cropWidth <= width) {
            // use crop width instead of preview width to prevent stretching of images
            height = Math.round(height / (width / this._cropWidth));
            width = this._cropWidth;
        }
        return new Promise(resolve => {
            const sourceImage = new Image();

            sourceImage.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
                canvas.getContext('2d').drawImage(sourceImage, 0, 0, width, height);
                resolve(canvas.toDataURL('type/png', 1));
            };

            sourceImage.src = url;
        });
    }

    async handlePasteEvent(pasteEvent) {

        if (pasteEvent.clipboardData && pasteEvent.clipboardData.items) {
            const items = pasteEvent.clipboardData.items;
            for (let i = 0; i < items.length; i++) {
                if (items[i].type.indexOf('image') > -1) {
                    // Retrieve image on clipboard as blob
                    const blob = items[i].getAsFile();
                    this.originalImageUrl = await this.blobToDataURL(blob);
                }
            }
        }
    }

    blobToDataURL(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const a = new FileReader();
            a.onload = (e) => {
                resolve((e.target as any).result);
            };
            a.onerror = (e) => {
                reject(e);
            };
            a.readAsDataURL(blob);
        });
    }

}
