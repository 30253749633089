import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'app/app.service';
import { AssetGroupingService, EventFilterService, GeoFilterService, MeasurementUnitsService } from 'app/services';
import { KeyFormBuilderEventFilterFieldComponent } from './eventfilter.component';


@Component({
    selector: 'key-form-builder-geofilter-field',
    templateUrl: './eventfilter.component.html',
    styleUrls: ['eventfilter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: EventFilterService, useExisting: GeoFilterService },
    ]
})
export class KeyFormBuilderGeoFilterFieldComponent extends KeyFormBuilderEventFilterFieldComponent {

    constructor(
        app: AppService,
        i18n: TranslateService,
        units: MeasurementUnitsService,
        grouping: AssetGroupingService,
        ref: ChangeDetectorRef,
        filters: GeoFilterService
    ) {
        super(app, i18n, units, grouping, ref, filters);
        this.minConditions = 1; // must have at least one condition to save
    }

}
