import { KuiIconModule } from './../icon/icon.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KuiContentHeaderComponent } from './content-header.component';
import { KuiContentHeaderTitleDirective } from './content-header-title.directive';
import { KuiActionModule } from '../action/action.module';
import { KuiDropdownModule } from '../dropdown/dropdown.module';
import { KuiContentHeaderActionsModule } from '../content-header-actions/content-header-actions.module';
import { KuiTabBarModule } from '../tab-bar/tab-bar.module';

@NgModule({
    imports: [
        CommonModule,
        KuiIconModule,
        KuiActionModule,
        KuiDropdownModule,
        KuiContentHeaderActionsModule,
        KuiTabBarModule,
    ],
    exports: [
        KuiContentHeaderComponent,
        KuiContentHeaderTitleDirective,
    ],
    declarations: [
        KuiContentHeaderComponent,
        KuiContentHeaderTitleDirective,
    ],
})
export class KuiContentHeaderModule { }
