import { Component, Input, EventEmitter, Output, ElementRef, ViewChild } from '@angular/core';

export type KuiProgressType = 'primary' | 'success' | 'info' | 'warning' | 'danger';

@Component({
    selector: 'kui-progress',
    templateUrl: './progress.component.html',
})
export class KuiProgressComponent {
    /**
     * Amount filled
     */
    @Input() value = 0;

    /**
     * Maximum amount
     * @default 100
     */
    @Input() max = 100;

    /**
     * Styling associated with the bar
     */
    @Input() type: KuiProgressType = 'primary';

    /**
     * Adds striping to the bar
     */
    @Input() striped = false;

    /**
     * Adds animation to the bar
     */
    @Input() animated = false;

    @Input() canSeek = false;
    @Output() onSeek = new EventEmitter<number>();

    @ViewChild('progressBar', { static: true }) progressBar: ElementRef;

    private dragging: boolean;

    onMouse(event: MouseEvent) {

        if (this.canSeek) {

            const emitSeek = () => {
                const perc = Math.ceil((event.offsetX / this.progressBar.nativeElement.offsetWidth) * 100);
                this.value = perc;
                this.onSeek.emit(perc);
            };

            switch (event.type) {
                case 'mousedown':
                    this.dragging = true;
                    emitSeek();
                    break;
                case 'mouseup':
                    if (this.dragging) {
                        emitSeek();
                    }
                    this.dragging = false;
                    break;
                case 'mousemove':
                    if (this.dragging) {
                        emitSeek();
                    }
                    break;
                default:
                    break;
            }
        }
    }
}
