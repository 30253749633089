import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KuiModule } from 'app/key-ui';

import { PipeModule } from 'app/shared/pipes/pipe.module';
import { GetKeyPipe } from 'app/shared/pipes/getkey.pipe';
import { ContactsComponent } from './contacts.component';


const COMPONENTS = [
    ContactsComponent,
];

@NgModule({
    imports: [
        CommonModule,
        KuiModule,
        TranslateModule.forChild(),
        PipeModule.forRoot(),
    ],
    exports: COMPONENTS,
    declarations: COMPONENTS,
    providers: [GetKeyPipe],
})
export class KeyContactsModule { }
