import { Component, Input, Output, EventEmitter, Renderer2, ElementRef } from '@angular/core';
import { IKuiModalAction } from './modal.model';

@Component({
    selector: 'kui-modal-footer',
    templateUrl: './modal-footer.component.html',
})
export class KuiModalFooterComponent {
    @Input() actions: IKuiModalAction[];
    @Output() buttonClicked: EventEmitter<any> = new EventEmitter();

    constructor(private renderer: Renderer2, private el: ElementRef) {
        this.renderer.addClass(this.el.nativeElement, 'modal-footer');
    }
}
