import { ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core';
import { debounce } from 'lodash';
import { BaseComponent } from '../../../base/base.component';
import { FilterPanelConfig, FilterPanelItem } from '../../filter-panel.model';
import { FilterPanelService } from '../../filter-panel.service';


@Component({
    selector: 'key-checkbox-filter',
    styleUrls: ['checkbox-filter.component.scss'],
    templateUrl: 'checkbox-filter.component.html',
})
export class KeyCheckboxFilterComponent extends BaseComponent implements OnInit {

    @Input() config: FilterPanelConfig;

    itemCache = {};

    items: FilterPanelItem[] = [];
    selectedItems: FilterPanelItem[] = [];
    deselectedItems: FilterPanelItem[] = [];

    truncatedList: { count: number, limit: number };
    searchValue: string;

    debouncedUpdateSearchValue = debounce(this.submitSearch, 500, { trailing: true, leading: false });

    @HostListener('keyup', ['$event']) keyUp(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            this.clearSearch();
        }
    }

    constructor(
        private filters: FilterPanelService,
        public ref: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        // fires after data is loaded so we can update our items if necessary
        this.on(this.filters.total$, () => {
            this.getItems(this.searchValue).then(items => {
                this.items = items;
                this.items.sort((a, b) => a.order - b.order);
                this.deselectedItems = this.filteredItems(this.items);
                this.ref.markForCheck();
            });
        });
        this.on(this.filters.filters$, values => {
            this.selectedItems = values.filters[this.config.id] || [];
            this.selectedItems.sort((a, b) => a.order - b.order);
            this.deselectedItems = this.filteredItems(this.items);
            this.ref.markForCheck();
        });
    }

    async getItems(filter: string = ''): Promise<FilterPanelItem[]> {
        if (this.config?.data) {
            this.truncatedList = null;
            return this.config.data.filter(x => x.name.toLowerCase().includes(filter.toLowerCase()));
        } else {
            const result = await this.config.getData(filter);
            this.truncatedList = result.count > result.limit && { limit: result.limit, count: result.count };
            return result.items;
        }
    }

    itemSelected(item: FilterPanelItem) {
        this.filters.updateFilterValues(this.config.id, [...this.selectedItems, item]);
        this.ref.markForCheck();
    }

    itemDeselected(item: FilterPanelItem) {
        const idx = this.selectedItems.findIndex(x => x.id === item.id);
        this.selectedItems.splice(idx, 1);
        this.filters.updateFilterValues(this.config.id, [...this.selectedItems]);
        this.ref.markForCheck();
    }

    filteredItems(items: FilterPanelItem[]) {
        return items.filter(item => {
            return !this.selectedItems.find(x => item.id === x.id);
        });
    }

    submitSearch(value: string) {
        this.searchValue = value;
        this.getItems(value).then(items => {
            this.items = items;
            this.items.sort((a, b) => a.order - b.order);
            this.deselectedItems = this.filteredItems(this.items);
            this.ref.markForCheck();
        });
    }

    clearSearch() {
        this.searchValue = '';
        this.getItems('').then(items => {
            this.items = items;
            this.items.sort((a, b) => a.order - b.order);
            this.deselectedItems = this.filteredItems(this.items);
            this.ref.markForCheck();
        });
    }

}
