import { Component, Input, Output, EventEmitter, HostBinding } from '@angular/core';

@Component({
  selector: 'kui-modal-header',
  templateUrl: './modal-header.component.html',
})
export class KuiModalHeaderComponent {
    @Input() title: string;
    @Output() close: EventEmitter<any> = new EventEmitter();
    @Input() icon = 'times';

    @HostBinding('class') hostClasses = 'modal-header w-100';
}
