import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { KuiModule } from "app/key-ui";
import { KeyDateRangeSelectComponent } from "./date-range-selection.component";
import { KeyDateSelectComponent } from "./date-selection.component";

const COMPONENTS = [
    KeyDateSelectComponent,
    KeyDateRangeSelectComponent,
];

@NgModule({
    imports: [
        KuiModule,
        CommonModule,
        TranslateModule,
    ],
    exports: COMPONENTS,
    declarations: COMPONENTS,
    providers: [
        DatePipe,
    ],
})
export class KeyDatesModule { }
