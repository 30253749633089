export const ASSET_ICON_INDEX = {
    'ship': 'ship',
    'driver': 'male',
    'person': 'male',
    'container': 'square',
    'fixed asset': 'square',
    'aircraft': 'plane',
    'site': 'map-marker',
    'trailer': 'car',
    'vehicle': 'car',
    'default': 'car',
};
