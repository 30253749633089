<div class="contacts"
    *ngIf="contacts && contacts.length">
    <div *ngFor="let contact of contacts"
        class="contact pt-3 pb-3 pr-3"
        [class.compact]="compact" (click)="onClick.emit(contact)">

        <div *ngIf="showDelete" class="pr-3" style='position: absolute; right: 0'>
            <kui-icon kui-action
                name="trash-alt"
                class="p-2 muted-more"
                size="sm"
                (click)="onDelete.emit(contact)"></kui-icon>
        </div>

        <div class="row">
            <span *ngIf="compact">
                <kui-icon name="user"
                    size="sm"></kui-icon>
            </span>
            <span *ngIf="!compact">
                {{ 'SHARED.CONTACTS.TYPES.' + contact.type.toUpperCase() | translate }}
            </span>
            <span>
                <strong class="contact-name">{{ contact.name }}</strong>
            </span>
        </div>
        <div class="row"
            *ngIf="contact.emailAddress && contact.emailAddress.length">
            <span>
                <kui-icon name="paper-plane"
                    size="sm"></kui-icon>
            </span>
            <span>
                <a href="mailto:{{ contact.emailAddress }}"
                    class="text-link contact-email">{{ contact.emailAddress }}</a>
            </span>
        </div>
        <div class="row"
            *ngIf="contact.phoneOffice && contact.phoneOffice.length">
            <span>
                <kui-icon name="phone"
                    size="sm"></kui-icon>
            </span>
            <span>
                <a href="tel:{{ contact.phoneOffice }}"
                    class="text-link contact-phone">{{ contact.phoneOffice }}</a>
            </span>
        </div>
        <div class="row"
            *ngIf="contact.phoneMobile && contact.phoneMobile.length">
            <span>
                <kui-icon name="mobile-alt"
                    size="sm"></kui-icon>
            </span>
            <span>
                <a href="tel:{{ contact.phoneMobile }}"
                    class="text-link contact-mobile">{{ contact.phoneMobile }}</a>
            </span>
        </div>
        <div class="row"
            *ngIf="contact.notes && contact.notes.length">
            <span>
                <kui-icon name="comment"
                    size="sm"></kui-icon>
            </span>
            <span class="contact-notes">{{ contact.notes }}</span>
        </div>
    </div>
</div>