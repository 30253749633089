import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormBuilderField, BaseFormBuilderFieldComponent } from 'app/shared/components/form-builder';
import { IKuiDropdownMenuItem } from 'app/key-ui';
import { DomSanitizer } from '@angular/platform-browser';
import * as Color from 'color';
import { get, set } from 'lodash';

export interface DropdownItem extends IKuiDropdownMenuItem {
    id: string;
}

@Component({
    selector: 'key-form-builder-color-combo-field',
    templateUrl: './color-combo.component.html',
    styleUrls: ['color-combo.component.scss'],
})
export class KeyFormBuilderColorComboFieldComponent implements BaseFormBuilderFieldComponent, OnInit {
    selected: string;

    @Input() field: FormBuilderField;
    @Input() values: { [key: string]: any };
    @Input() error: string; // set this error value externally to have the default error highligh and display kick in

    @Output() onSelected = new EventEmitter<{ value: string | number, dirty: boolean }>();

    items: DropdownItem[] = [];
    touched = false;
    dirty = false;

    toggle = false;
    borderColor = '#ddd';
    color = '#fff';
    text: string;

    get value(): any {
        return this.field.getValue ? this.field.getValue(this.field, this.values) : get(this.values, this.field.id, undefined);
    }
    set value(val: any) {
        if (this.field.setValue) {
            const promise = this.field.setValue(this.field, this.values, val);
            if (promise && promise.then) {
                promise.then(() => this.ref.markForCheck());
            }
        } else {
            set(this.values, this.field.id, val);
        }
    }

    constructor(private ref: ChangeDetectorRef, private sanitizer: DomSanitizer) { }

    validate(): boolean {
        this.touched = true;
        this.ref.markForCheck();
        return !this.field.required || !!this.value;
    }

    ngOnInit() {
        this.items = [
            ...this.field.values,
        ].map(x => this.getDropdownItem(x.key, x.value.toString()));

        this.value = this.value || this.field.value;
        if (this.value && this.value.startsWith('#')) { // add our current value to the dropdown
            this.items.push(this.getDropdownItem(this.value, this.value));
        }
        this.computeColor();
        setTimeout(() => { // avoid ExpressionChangedAfterItHasBeenCheckedError
            this.onSelected.emit({ value: this.value, dirty: false }); // we need to fire the event if we've set a value in order to deal with sub fields
        });
    }


    getDropdownItem(key: string, value: string): DropdownItem {
        const color = this.field.options && this.field.options.computeColor ? this.field.options.computeColor(this.field, this.values, key) : key;
        return {
            id: key,
            type: 'action',
            text: this.sanitizer.bypassSecurityTrustHtml(`<span class='color-swatch' style="background-color: ${color}; border-color: ${this.getBorderColor(color)}"></span>${value}`),
            closeDropdownOnClicked: true,
            action: () => this.comboChanged(key),
        };
    }

    comboChanged(key: string) {
        if (key === 'custom') { // bit of a hack or the color picker throws an error
            this.toggle = true;
        } else {
            this.touched = true;
            this.dirty = true;
            this.value = key || undefined;
            this.computeColor();
            this.borderColor = this.getBorderColor(this.color);
            this.onSelected.emit({ value: this.value, dirty: this.dirty });
        }
        this.ref.markForCheck();
    }

    addCustomColor(open: boolean) {
        if (!open) {
            if (this.value) {
                if (!this.items.find(x => x.id === this.value)) {
                    this.items.push(this.getDropdownItem(this.value, this.value));
                }
            }
        }
    }

    getBorderColor(color: string): string {
        try {
            return Color((color || '').replace(' ', '')).darken(.2).string();
        } catch {
            return '#ddd';
        }
    }


    computeColor() {
        const val = this.value;
        if (this.field.getText) {
            const [color, name] = this.field.getText(this.field, this.values).split(':');
            this.color = color;
            this.text = name || color;
        } else {
            this.color = val;
            this.text = val;

        }
    }


}
