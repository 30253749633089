import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'app/app.service';
import { IKuiModalAction } from 'app/key-ui';
import { KuiModalComponent } from 'app/key-ui/modal/modal.component';
import { KuiModalService } from 'app/key-ui/modal/modal.service';
import { AssetService, ErrorLoggerService } from 'app/services';
import { BaseComponent } from 'app/shared/components/base/base.component';
import * as moment from 'moment-timezone';
import { distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import { AlertFeedService } from './alert-feed/alert-feed.service';
import { AuditFeedService } from './audit-feed/audit-feed.service';
import { EventDetailsComponent } from './event-feed/details/details.component';
import { EventFeedService } from './event-feed/event-feed.service';
import { FeedEntryAction } from './feed-entry-actions/feed-entry-actions.component';
import { FeedItem, EventFeedItem } from './feed.model';
import { FeedOriginType, FeedService } from './feed.service';
import { EventDetailsModalComponent } from './modal/modal.component';
import { TripFeedService } from './trip-feed/trip-feed.service';
import { NotificationFeedService } from './notification-feed/notification-feed.service';
import { BehaviorSubject, interval } from 'rxjs';
import { LogFeedService } from './log-feed/log-feed.service';

export interface FeedSection {
    title: string;
    items: FeedItem[];
}

@Component({
    selector: 'key-feed',
    templateUrl: 'feed.component.html',
    styleUrls: ['feed.component.scss'],
    providers: [TripFeedService, EventFeedService, AlertFeedService, AuditFeedService, NotificationFeedService, LogFeedService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedComponent extends BaseComponent implements OnChanges, OnDestroy {
    errorMessage: string;
    allowRetry: boolean;

    loading: boolean;
    hasMore: boolean;
    moreLoaded = false;

    sectionList: FeedSection[];

    minDate: moment.Moment;
    maxDate: moment.Moment;

    @Input() showDetailsModal = false;
    @Input() selectableItem: boolean;
    @Input() feedService: FeedService<FeedItem>;
    @Input() limit = 10;
    @Input() type: 'alert' | 'event' | 'trip' | 'audit' | 'notification' | 'log';
    @Input() originType: FeedOriginType = 'asset';
    @Input() clientId: string;
    @Input() id: string;
    @Input() dateRange: { start: string; end: string };
    @Input() selectedItem: FeedItem;
    @Input() primaryAction: 'selectItem' | 'detailsModal';
    @Input() filterItemBy: (items: FeedItem) => boolean;

    @Input() poll = true;
    @Input() settings: any;

    @Output() onItemSelected = new EventEmitter<FeedItem>();

    @ViewChild('details') detailsModal: KuiModalComponent;
    @ViewChild(EventDetailsComponent) eventDetails: EventDetailsComponent;

    modalActions: IKuiModalAction[];
    modalTitle: string;
    modalOpen = false;
    
    private footerInView = new BehaviorSubject<boolean>(true);

    constructor(
        private app: AppService,
        private assetService: AssetService,
        private i18n: TranslateService,
        private injector: Injector,
        private changeRef: ChangeDetectorRef,
        private error: ErrorLoggerService,
        private modal: KuiModalService
    ) {
        super();

        interval(30e3)
            .pipe(takeUntil(this.destroyed))
            .subscribe(() => {
                // This is so the html can be re-evaluated and we can get the correct date time ago.
                this.changeRef.markForCheck();
            });

        this.footerInView.asObservable()
            .pipe(
                takeUntil(this.destroyed),
                distinctUntilChanged(),
                filter(inView => this.hasMore && !this.loading && inView),
            ).subscribe(async () => {
                await this.loadMore();
            });
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.id || changes.dateRange || changes.clientId || changes.originType || changes.settings) {

            this.selectedItem = null;
            this.sectionList = [];
            this.minDate = null;
            this.maxDate = null;
            this.moreLoaded = false;
            this.setLoading(true);
            this.setHasMore(false);

            if (!this.feedService) {
                this.feedService = this.injectFeedService();
            }
            this.feedService.updateSettings(this.settings);

            this.feedService.updates$
                .pipe(takeUntil(this.destroyed))
                .subscribe(update => {
                    if (!update.historic) { // don't automatically update when loading more items, they are added manually already
                        this.updateFeed(update.items, this.sectionList.length > 0);
                    }
                });

            this.feedService.onError$
                .pipe(takeUntil(this.destroyed))
                .subscribe(error => this.showError(error));

            this.feedService.stopPolling();

            if (this.dateRange) {
                this.feedService.setDateRange(this.dateRange.start, this.dateRange.end);
                this.feedService.clearFeedItems();
            } else {
                this.feedService.clearDateRange();
            }


            // initialize feed with id (usually an asset id, but can be something else too)
            this.initializeFeed();
        }
    }

    ngOnDestroy() {
        if (this.feedService) {
            this.feedService.stopPolling();
        }
    }

    injectFeedService(): FeedService<FeedItem> {
        // inject the correct feed service based on the type input
        switch (this.type) {
            case 'event': return this.injector.get(EventFeedService);
            case 'alert': return this.injector.get(AlertFeedService);
            case 'trip': return this.injector.get(TripFeedService);
            case 'audit': return this.injector.get(AuditFeedService);
            case 'notification': return this.injector.get(NotificationFeedService);
            case 'log': return this.injector.get(LogFeedService);
            default:
                console.warn('No/unsupported type specified on FeedComponent. To make sure the correct feed service is used please add the correct type to your <key-feed type="FEED_TYPE_HERE" /> tag.');
                return null;
        }
    }

    initializeFeed() {
        this.showError(null);
        this.setLoading(true);
        // if a dateRange exists we call the history between 2 dates else we get items from now
        if (this.dateRange) {
            // TODO: getFeedHistory is currently only implemented on 'event' and 'alert' feedService type. Consider creating an abstract for this to enable feed history on all feed types
            const items = this.feedService && this.feedService.items ? Object.keys(this.feedService.items).map(x => this.feedService.items[x]) : [];
            if (items.length > 0) {
                this.updateFeed(items);
                this.setLoading(false);
            } else {
                this.feedService.getHistory(this.clientId || this.app.client.id, this.originType, this.id, this.dateRange.start, this.dateRange.end, this.limit)
                    .then(res => {
                        if (res.items && !!res.items.length) {
                            if (res.limit === 0 && this.limit >= 0) {
                                // API hasn't applied a limit even though we specified one... assume it has returned all data...
                                this.setHasMore(false);
                            } else {
                                this.setHasMore(res.items.length >= this.limit); 
                            }
                            this.updateFeed(res.items);
                        }
                        this.setLoading(false);
                    })
                    .catch(error => {
                        this.setLoading(false);
                        this.showError(error, false);
                    });
            }
        } else {
            this.feedService.initializeFeed(this.clientId || this.app.client.id, this.originType, this.id, this.poll, this.limit)
                .then(items => {
                    if (items && items.length) {
                        this.setHasMore(items.length >= this.limit);
                        this.updateFeed(items);
                    }
                    this.setLoading(false);
                })
                .catch(error => {
                    this.setLoading(false);
                    this.showError(error, true, this.i18n.instant('SHARED.ERROR_UNABLE_TO_LOAD_FEED'));
                });
        }
    }

    getTrimAmount(newItems: number): number {
        const currentItems = (this.sectionList || []).reduce((items, section) => {
            items = items || [];
            items.push(...section.items);
            return items;
        }, []).length;
        if (this.moreLoaded && currentItems < 1000) {
            // If we've asked for more items, we can't really trim anymore since it may confuse the user. However, not trimming at all could crash a browser
            // if it's left open after clicking more, so start trimming again after at least 1000 items have been loaded. Hopefully users will get tired of 
            // clicking "show more" before they get that far.
            return 0;
        }
        const trim = (currentItems + newItems) - this.limit;
        return Math.max(0, trim); // use Math.max to convert all negative numbers to 0
    }

    updateFeed(items: FeedItem[], trim?: boolean) {

        let trimAmount = items.length;

        items.forEach(item => {
            const dt = moment.utc(item.date);
            this.minDate = this.minDate ? moment.min(this.minDate, dt) : dt;
            this.maxDate = this.maxDate ? moment.max(this.maxDate, dt) : dt;

            // check if section already exists
            const section = this.sectionList.find(s => s.title === item.section.title);
            if (section) {
                // check if item already exists inside section
                const index = section.items.findIndex(x => x.id === item.id);
                if (index >= 0) {
                    // replace existing item
                    section.items[index] = item;
                    // we are only updating this item so one less item to trim
                    trimAmount--;
                } else {
                    // add new item
                    section.items.push(item);
                }

                // sort items inside section correctly: desc on updating feed and asc on history feed
                section.items.sort((a, b) => this.dateRange ? a.date.localeCompare(b.date) : b.date.localeCompare(a.date));
            } else {
                this.sectionList.push({ title: item.section.title, items: [item] });

                // make sure we add the new section in the correct place: desc on updating feed and asc on history feed
                this.sectionList.sort((a, b) => this.dateRange ? a.items[0].section.date.localeCompare(b.items[0].section.date) : b.items[0].section.date.localeCompare(a.items[0].section.date));
            }
        });

        if (trim) {
            this.trimFeed(this.getTrimAmount(trimAmount));
        }

        this.changeRef.markForCheck();
    }

    trimFeed(amount: number) {
        if (this.sectionList.length > 0 && amount > 0) {
            const section = this.sectionList[this.sectionList.length - 1];

            if (section.items.length <= amount) {
                this.sectionList.pop();

                // go to next section with left amount
                const amountLeft = amount - section.items.length;
                if (amountLeft > 0) {
                    this.trimFeed(amountLeft);
                }
            } else {
                section.items.splice(-amount);
            }
        }
    }

    async loadMore(): Promise<boolean> {
        this.moreLoaded = true; // setting this flag prevents the feed from trimming items at the bottom when new items are added.
        this.setLoading(true);
        if (this.dateRange) {
            return this.feedService.getHistory(this.clientId || this.app.client.id, this.originType, this.id, this.maxDate.add(1, 'second').toISOString(), this.dateRange.end, this.limit)
                .then(res => {
                    this.showError(null);
                    this.setLoading(false);
                    if (res.items && res.items.length) {
                        this.setHasMore(res.items.length >= this.limit);
                        this.updateFeed(res.items);
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch(error => {
                    this.setLoading(false);
                    this.showError(error, true, this.i18n.instant('SHARED.ERROR_UNABLE_TO_LOAD_FEED'));
                    return false;
                });
        } else {
            return this.feedService.getOlderFeedItems(this.clientId || this.app.client.id, this.originType, this.id, this.limit)
                .then(items => {
                    this.showError(null);
                    this.setLoading(false);
                    if (items && items.length) {
                        this.setHasMore(items.length >= this.limit);
                        this.updateFeed(items);
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch(error => {
                    this.setLoading(false);
                    this.showError(error, false, this.i18n.instant('SHARED.ERROR_UNABLE_TO_LOAD_OLDER_FEED'));
                    return false;
                });
        }
    }

    setLoading(state: boolean) {
        this.loading = state;
        this.changeRef.markForCheck();
    }

    setHasMore(state: boolean) {
        this.hasMore = state;
        this.changeRef.markForCheck();
    }

    showError(error: any, retry?: boolean, message?: string) {
        this.error.trackException(error);
        this.allowRetry = retry;
        this.errorMessage = message || (error ? error.message || error.error || error : null);
        this.changeRef.markForCheck();
    }

    getItemActions(item: FeedItem): FeedEntryAction[] {
        const actions: FeedEntryAction[] = [];
        if (this.selectableItem && this.primaryAction !== 'selectItem') {
            actions.push({ icon: 'arrow-circle-right', onClick: () => this.itemSelected(item) });
        }
        if (this.showDetailsModal && this.primaryAction !== 'detailsModal') {
            actions.push({ icon: 'window-restore', onClick: () => this.openDetailsModal(item) });
        }
        return actions;
    }

    clickPrimaryAction(item: FeedItem) {
        // TODO: we'll probably have to move the inner html of this component to a seperate reusable component
        // and then use *ngIf="this.primaryAction" to add kui-action to it. Else feed items will always have hover and click states.
        if (!this.primaryAction) { return; }

        if (this.selectableItem && this.primaryAction === 'selectItem') {
            this.itemSelected(item);
        }

        const hasAccess = this.type === 'event' || (this.type === 'alert' && this.app.features.page.alerts.enabled) ||
            (this.type === 'trip' && this.app.features.page.replay.enabled);

        if (this.showDetailsModal && this.primaryAction === 'detailsModal' && hasAccess) {
            this.openDetailsModal(item);
        }
    }

    itemSelected(item: FeedItem) {
        this.selectedItem = item;
        this.onItemSelected.emit(item);
    }

    openDetailsModal(item: FeedItem) {
        return this.assetService.getAsset(this.id).then(asset => {
            if (this.type === 'event' || this.type === 'alert') {
                this.modalTitle = item['alerts'] && item['alerts'].length > 0
                    ? this.i18n.instant('ALERTS.ALERT_DETAILS')
                    : this.i18n.instant('ALERTS.EVENT_DETAILS');
            }
            if (this.type === 'trip') {
                this.modalTitle = asset.name;
            }

            this.modal.open(EventDetailsModalComponent, {
                data: {
                    title: this.modalTitle,
                    type: this.type,
                    selectedItem: item,
                    feedService: this.feedService,
                    id: this.id,
                },
                actions: {
                    close: () => this.modal.close(),
                    updateFeed: result => this.updateFeed(result),
                },
            });
        });
    }

    hasValidItems(section: FeedSection): boolean {
        return section.items.filter((x: EventFeedItem) => this.filterItemBy ? this.filterItemBy(x) : true).length > 0;
    }

    loadMoreRequest(visible: boolean) {
        this.footerInView.next(visible);
    }
}
