
<div class="px-4">
    <div class="d-flex px-4 py-2">
        <div class="position-relative" [ngStyle]="{'left.%': handlePosition()}"> 
            <span style="margin-left: -50%">{{ currentDate() | units: { unit: 'time', variant: 'long'} }}</span>
        </div>
    </div>
    <p-slider #slider 
        styleClass="playback-timeline" 
        [(ngModel)]="sliderValue" 
        (onChange)="handleChange($event)" 
        (onSlideEnd)="handleSlideEnd()" 
        [max]="length()">
    </p-slider>

    <div class="d-flex justify-content-between py-2">
        <span>{{ startDate() | units: { unit: 'time', variant: 'long'} }}</span>
        <span>{{ endDate() | units: { unit: 'time', variant: 'long'} }}</span>
    </div>
</div>
