import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { EventFilter, EventFilterCondition } from '@key-telematics/fleet-api-client';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'app/app.service';
import { KuiDropdownComponent } from 'app/key-ui/dropdown/dropdown.component';
import { KuiTreeSelectNode } from 'app/key-ui/tree-select/tree-select.component';
import { AssetGroupingService, EventFilterService, MeasurementUnitsService } from 'app/services';
import { BaseFormBuilderFieldComponent, FormBuilderField } from 'app/shared/components/form-builder';
import { cloneDeep } from 'lodash';
import { KeyFormBuilderEventFilterConditionComponent } from './condition/condition.component';

@Component({
    selector: 'key-form-builder-eventfilter-field',
    templateUrl: './eventfilter.component.html',
    styleUrls: ['eventfilter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        EventFilterService
    ]
 })
export class KeyFormBuilderEventFilterFieldComponent implements BaseFormBuilderFieldComponent, OnInit {

    @Input() field: FormBuilderField;
    @Input() values: { [key: string]: any };
    @Input() error: string; // set this error value externally to have the default error highligh and display kick in

    @Output() onChange: EventEmitter<{ value: { [key: string]: any }, dirty: boolean }> = new EventEmitter();

    actorNodes: KuiTreeSelectNode[];
    actionNodes: KuiTreeSelectNode[];
    targetNodes: KuiTreeSelectNode[];
    targetNodesType: string;
    eventType: string;
    conditionNodes: KuiTreeSelectNode[];

    minConditions = 0; // increase in descendants if you want to enforce a minimum number of conditions
    ownerId: string;

    touched = false;


    get item(): EventFilter {
        const item = this.field.getValue ? this.field.getValue(this.field, this.values) : this.values[this.field.id];
        return item || {};
    }
    set item(val: EventFilter) {
        if (this.field.setValue) {
            const promise = this.field.setValue(this.field, this.values, val);
            if (promise && promise.then) {
                promise.then(() => this.ref.markForCheck());
            }
        } else {
            this.values[this.field.id] = val;
        }
    }

    get hasActor(): boolean {
        return !this.field.options || this.field.options.actor !== false;
    }

    @ViewChild('actorDropdown') dropdown: KuiDropdownComponent;
    @ViewChildren(KeyFormBuilderEventFilterConditionComponent) conditions: QueryList<KeyFormBuilderEventFilterConditionComponent>;

    constructor(
        public app: AppService,
        protected i18n: TranslateService,
        protected units: MeasurementUnitsService,
        protected grouping: AssetGroupingService,
        protected ref: ChangeDetectorRef,
        protected filters: EventFilterService,
    ) {}

    completed(): boolean {

        if (this.hasActor && !this.item.actor) { return false; }
        if (!this.item.action) { return false; }
        if (this.item.action && this.item.action.targetType && !this.item.target) { return false; }
        if (this.conditions.length < this.minConditions) { return false; }

        return this.conditions.toArray().every(condition => condition.isValid());

    }

    validate(): boolean {
        this.touched = true;
        this.ref.markForCheck();
        return !this.field.required || this.completed();
    }

    changed() {
        this.touched = true;
        this.onChange.emit({ value: this.values, dirty: true });
        this.ref.markForCheck();
    }

    async loadNodes(): Promise<void> {
        const groupTypes = this.field.options?.groupTypes || null;
        const [assetTree, targetTree, actionTree] = await Promise.all([
            this.filters.getAssetSelectionTree(groupTypes),
            this.filters.getTargetNodes(this.targetNodesType),
            this.filters.getActionEventsTree(),
        ]);
        this.actorNodes = assetTree;
        this.targetNodes = targetTree;
        this.actionNodes = actionTree;
    }

    async ngOnInit() {

        this.ownerId = (this.field.options && this.field.options.ownerId) || this.app.client.id || this.app.user.owner.id;
        this.filters.setOwnerId(this.ownerId);

        this.values[this.field.id] = this.values[this.field.id] || this.field.value || {};
        this.targetNodesType = this.item && this.item.action && this.item.action.targetType;
        this.eventType = this.item && this.item.action && this.item.action.eventType;

        if (this.item && this.item.action) {
            this.conditionNodes = this.filters.getConditionsTree(this.item.action);
        }

        await this.loadNodes();
        this.ref.markForCheck();
    }

    onActorSelected(dropdown: KuiDropdownComponent, event: KuiTreeSelectNode) {
        const data = cloneDeep(event.node.data.data);
        this.item = { ...this.item, actor: data as any };
        dropdown.toggle();
        this.changed();
    }

    async onActionSelected(dropdown: KuiDropdownComponent, event: KuiTreeSelectNode) {
        if (event.eventName === 'activate') {
            const data = cloneDeep(event.node.data.data);
            if (data) {
                this.item = { ...this.item, action: data as any };
                if (('targetType' in this.item.action) && data?.targetType === undefined) {
                    // a previous action had a target type,
                    // we must now remove that value as this action doesn't support it.
                    this.item.action.targetType = null;
                }
                this.conditionNodes = this.filters.getConditionsTree(this.item.action);
                if (this.targetNodesType !== data.targetType || this.eventType !== data.eventType) {
                    this.item.target = null;
                    this.targetNodesType = data.targetType;
                    this.eventType = data.eventType;
                    this.targetNodes = await this.filters.getTargetNodes(this.targetNodesType);
                }
                dropdown.toggle();
                this.changed();
            }
        }
    }

    onTargetSelected(dropdown: KuiDropdownComponent, event: KuiTreeSelectNode) {
        const data = cloneDeep(event.node.data.data);
        this.item = { ...this.item, target: data as any };
        dropdown.toggle();
        this.changed();
    }

    async onConditionSelected(dropdown: KuiDropdownComponent, event: KuiTreeSelectNode) {
        if (event.eventName === 'activate') {
            const data = cloneDeep(event.node.data.data);
            dropdown.toggle();
            if (!this.item.conditions) {
                this.item.conditions = [];
            }
            this.item.conditions.push(data);
            this.changed();
        }
    }

    onRemoveCondition(condition: EventFilterCondition) {
        const idx = this.item.conditions.indexOf(condition);
        if (idx > -1) {
            this.item.conditions.splice(idx, 1);
            this.changed();
        }
    }

}