import { Component, Input, ChangeDetectionStrategy, EventEmitter, Output, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'app/shared/components/base/base.component';
import { ASSET_ICON_INDEX } from 'app/shared/model';
import { AppService } from 'app/app.service';
import { TelemetryResponse, EventResponse } from '@key-telematics/fleet-api-client';
import { TripsService, AssetListItem, AssetService } from 'app/services';
import { TripItem } from 'app/services/trips/trips.service';

@Component({
    selector: 'key-trip-preview',
    templateUrl: './preview.component.html',
    styleUrls: ['./preview.component.scss'],
    providers: [TripsService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripPreviewComponent extends BaseComponent implements OnChanges {


    tabIndex = 0;
    tabs: string[];

    asset: AssetListItem;
    trip: TripItem;
    telemetry: TelemetryResponse[];
    events: EventResponse[];

    @Input() showActions = true;
    @Input() isMobile: boolean;

    @Input() assetId: string;
    @Input() dateStart: string;

    @Output() onClose = new EventEmitter();

    constructor(
        public app: AppService,
        private assetService: AssetService,
        private tripsService: TripsService,
        public ref: ChangeDetectorRef,
        public i18n: TranslateService) {
        super();
        this.tabs = [this.i18n.instant('ALERTS.TABS.DETAILS')];
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.assetId) {
            this.assetService.getAsset(this.assetId).then(item => {
                this.asset = item;
                this.ref.markForCheck();
            });
        }

        if (changes.dateStart) {

            this.tripsService.loadTrips(this.assetId, this.dateStart, this.dateStart).then(trips => {
                this.trip = trips[0];
                this.ref.markForCheck();
                if (this.trip) {
                    return Promise.all([
                        this.tripsService.loadTripTelemetry(this.trip),
                        this.tripsService.loadTripEvents(this.trip),
                    ]).then(([telemetry, events]) => {
                        this.telemetry = telemetry;
                        this.events = events;
                        this.ref.markForCheck();
                    });
                }
            });

        }
    }

    close() {
        this.onClose.emit();
    }

    getIcon(): { name: string, color: string } {
        const name = (this.asset.assetType && this.asset.assetType.name) || 'default';
        return {
            name: ASSET_ICON_INDEX[name.toLowerCase()],
            color: this.asset.color,
        };
    }

    trimAddress(text: string): string {
        return text.split(',')[0];
    }

}
