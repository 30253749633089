import { Pipe, PipeTransform } from '@angular/core';

/*
 * Safely retrieve deep value from object
 */
@Pipe({name: 'getKey'})
export class GetKeyPipe implements PipeTransform {
  transform(obj: object, key: string): any {
    const keys = key.split(/\./g).reverse();
    let val = obj, cur;

    while (cur = keys.pop()) {
      if (!val || !val.hasOwnProperty((cur)))  {
        return '';
      }

      val = val[cur];
    }

    return val;
  }
}
