import * as moment from 'moment-timezone';
import { Shift } from '../graphql';
import { Moment } from 'moment';

export interface DateRange {
    start: string;
    end: string;
    shiftIndex?: number;
}

/** UrlDates can be either 20180101 or 20180101Z (which implies UTC) or a custom format like
 *  20180101E0200 or 20180101W0200 that includes timezone information (e=east=+, w=west=-)  */
type UrlDate = string;

export function urlDateToISO(date: UrlDate): string {
    if (date.length === 8) { // 20180101
        // the date does not include a timezone, assume UTC
        date = date + 'e0000';
    }
    if (date.length !== 13) { // 20180101E0200
        throw new Error(`"${date}" is not a valid url date`);
    }
    date = `${date.substr(0, 4)}-${date.substr(4, 2)}-${date.substr(6, 2)}T00:00:00${date.substr(8, 5).toLowerCase().replace('e', '+').replace('w', '-')}`;
    return date;
}

export function userDateToUrlDate(dt: moment.Moment, shiftIndex?: number): UrlDate {
    const iso = dt.format(); // 2018-01-10T00:00:00+02:00
    const date = iso.substr(0, 10).replace(/-/g, '') + iso.substr(19, 6).replace(/:/g, '').replace('+', 'e').replace('-', 'w').replace('Z', '');
    return shiftIndex != null ? `${date}s${shiftIndex}` : date;
}


// format is YYYYMMddTHHmmss
export function urlDateTimeToISO(date: UrlDate): string {
    if (date.length !== 15) { // YYYYMMddTHHmmss
        throw new Error(`"${date}" is not a valid url date`);
    }
    return moment.utc(date, 'YYYYMMDDTHHmmss').toISOString();
}

// has a date range with date and time. this format is ISO only, timezone is not included as GMT0 is assumed
export function userDateToUrlDateTimeRange(dt1: moment.Moment, dt2: moment.Moment): string {
    const parseISO = (dt: moment.Moment) => {
        const iso = dt.toISOString(); // 2018-01-10T00:00:00.000Z
        const date = iso.substr(0, 19).replace(/[^a-z0-9]/ig, '');
        return date;
    };
    return `${parseISO(dt1)}-${parseISO(dt2)}`;
}


export function getRangeFromUrlDate(urlDate: UrlDate): DateRange {
    
    const [date, shift] = urlDate.split('s');
    if (date.length === 31) { // 20230124T000000-20230125T235959
        const [start, end] = date.split('-');
        const dates = {
            start: urlDateTimeToISO(start),
            end: urlDateTimeToISO(end),
        };
        return dates;
    } else {
        const iso = urlDateToISO(date);  // the date could be a custom value that includes the timezone, i.e. 2018-01-01+0200 or 2018-01-01-0200
        const dates = {
            start: moment.utc(iso).toISOString(),
            end: moment.utc(iso).add(24, 'hours').subtract(1, 'millisecond').toISOString(),
            shiftIndex: shift && parseInt(shift, 10),
        };
        return dates;
    }
}

export function ISOToTime(iso: string, format: string = 'HH:mm:ss'): string {
    return moment.utc(iso).tz(moment.defaultZone.name).format(format);
}

function addTimeToDate(d: Moment, time: string, type: 'start' | 'end'): string {
    if (time) {
        const [HH, mm] = time.split(':');
        const byAmount = +(type === 'end'); // 1 for end and 0 for start
        // subtract one minute to the end time and change the seconds to 59
        d.hours(+HH).minutes(+mm).subtract(byAmount, 'minute').seconds(byAmount ? 59 : 0);
    } else {
        if (type === 'start') {
            d.startOf('day');
        }
        if (type === 'end') {
            d.endOf('day');
        }
    }
    return moment.utc(d).toISOString(); // convert time back to correct timezone
}

// NOTE: this function assumes that a daterange is the length of 1 day as this is the current functionality
export function mergeShiftIntoDateRange(dates: DateRange, shifts: Shift[]): { start: string, end: string } {
    let index = dates.shiftIndex;
    if (!index) {
        return { start: dates.start, end: dates.end };
    } else {
        // index 0 should actually be all shifts i.e. returned above
        // so we change the index so the 0 is the first shift of the client
        index--;
    }

    const shift = shifts[index] || {} as Shift;

    const s = moment.utc(dates.start).tz(moment.defaultZone.name);
    const start = addTimeToDate(s, shift.start, 'start');

    const e = moment.utc(dates.end).tz(moment.defaultZone.name);
    // if timeStart is bigger that timeEnd it means the shift starts the day before so we add a day on end
    const end = addTimeToDate(shift.start > shift.end ? e.add(1, 'days') : e, shift.end, 'end');

    return { start, end };
}
