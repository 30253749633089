import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'kui-menu-brand',
  templateUrl: './menu-brand.component.html',
})
export class KuiMenuBrandComponent {
  @Input() url: string;
  @Input() image: string;

  @HostBinding('class.hidden-on-collapsed') hiddenOnCollapsed = true;
  @HostBinding('class.top-menu-show') showInTopMenu = true;
}
