import { Component, Output, EventEmitter, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilderDefinition, FormBuilderFieldChangeEvent } from 'app/shared/components/form-builder';
import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { AnalyticsChartStatTrendType, AnalyticsChartStatDataType } from '../../chart/stat/stat.service';

export interface StatSettings {
    data: AnalyticsChartStatDataType;
    key?: string;
    color?: string;
    trend?: AnalyticsChartStatTrendType;
    sparkline?: boolean;
    series?: { key: string, value: string }[];
    targetValue?: number;
}

@Component({
    selector: 'key-analytics-settings-stat',
    templateUrl: './stat.component.html',
})
export class AnalyticsSettingsStatComponent implements OnInit, OnChanges {
    form: FormBuilderDefinition;
    formLeftAxis: FormBuilderDefinition;
    formRightAxis: FormBuilderDefinition;
    
    values: { [key: string]: any; };

    @Input() options: StatSettings;
    @Output() onChange: EventEmitter<{ [key: string]: any; }> = new EventEmitter();

    constructor(
        public i18n: TranslateService) {
    }

    ngOnInit() {
        this.form = this.getForm();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.options && this.options) {
            this.values = this.options;

            // update series values if they changed
            const { previousValue } = changes.options;
            if (this.form && !isEqual(this.options.series, previousValue && previousValue.series)) {
                this.form = this.getForm();
            }
        }
    }

    changeFormSetting(change: FormBuilderFieldChangeEvent) {
        if (change.dirty) {
            const { id } = change.field;
            this.onChange.emit({ [id]: change.values[id] });
        }
    }

    getForm(): FormBuilderDefinition {
        return {
            groups: [{
                fields: [
                    {
                        type: 'combo', id: 'data', title: 'DATA', values: [
                            { key: 'first', value: this.i18n.instant('ANALYTICS.SETTINGS.FIRST') },
                            {
                                key: 'last', value: this.i18n.instant('ANALYTICS.SETTINGS.LAST'), fields: [
                                    { type: 'combo', id: 'trend', title: 'TREND', values: [
                                        { key: 'none', value: this.i18n.instant('ANALYTICS.SETTINGS.NONE') },
                                        { key: 'percentage', value: this.i18n.instant('ANALYTICS.SETTINGS.PERCENTAGE') },
                                        { key: 'actual', value: this.i18n.instant('ANALYTICS.SETTINGS.ACTUAL') },
                                    ]},
                                ],
                            },
                            { key: 'average', value: this.i18n.instant('ANALYTICS.SETTINGS.AVERAGE') },
                            { key: 'total', value: this.i18n.instant('ANALYTICS.SETTINGS.TOTAL') },
                        ],
                    },
                    { type: 'color', id: 'color', title: 'COLOR' },
                    { type: 'toggle', id: 'sparkline', title: 'SPARKLINE' },
                    { type: 'combo', id: 'key', title: 'SERIES', values: this.options.series },
                    { type: 'number', id: 'targetValue', title: 'TARGET_VALUE', required: false },
                ],
            }],
        };
    }
}
