<kui-modal #modal
    class="report-select-modal"
    size="lg"
    title="{{'REPORTING.SELECT_REPORT' | translate}}"
    [actions]="modalActions"
    (onClose)="detachModal()">
    <div class="d-flex flex-1">

        <div class='categories border-right overflow-auto'>
            <kui-tree-select 
                class="w-100"
                [nodes]="treeNodes"
                [selected]="selectedNode"
                (selectedNodeChanged)="nodeSelected($event)"></kui-tree-select>
        </div>
        <div class="reports flex-1 overflow-auto">
            <div *ngIf='selectedCategory'>
                <key-report-list-item *ngFor="let item of selectedCategory.items"
                    [item]="item"
                    [starred]="item.starred"
                    (onSelect)="selectReport($event)"></key-report-list-item>
            </div>
        </div>

    </div>
</kui-modal>