<div class="event-summary"
    *ngIf="event">

    <key-details-overview-card [assetId]="asset.id"
        [icon]="getIcon()"
        [title]="asset.name"
        [badges]="assetBadges">
        <div>{{ dateString }}</div>
        <div *ngIf="telemetry">{{ telemetry.location.lat | dec2deg}} {{ telemetry.location.lat > 0 ? 'N' : 'S'}} &nbsp;&bull;&nbsp; {{ telemetry.location.lon
            | dec2deg}} {{ telemetry.location.lon > 0 ? 'E' : 'W'}}</div>
        <div *ngIf="values"
            class="mt-2">
            <div *ngFor="let value of values">
                <strong>{{ value.title }}: </strong>{{ value.value }}
            </div>
        </div>
        <div *ngFor="let link of links">
            <a [href]="link.url"
                (click)="linkClicked($event, link)"
                [target]="link.target">{{link.title}}</a>
        </div>


    </key-details-overview-card>

    <div class="px-3">

        <div class="border-top">
            <key-event-feed-item class="feed-item-alert"
                [data]="event"
                [showSubtext]="false"
                [titleType]="event.alerts.length > 0 ? 'alert': 'event'"></key-event-feed-item>
        </div>
        <div class="feed-entry"
            *ngIf="event.handledBy">
            <key-feed-entry-info [icons]="[ackIcon]"
                [indicators]="null">
                <span id="info-title">
                    <span kui-tooltip="{{event.handledBy | getKey: 'date' | date: 'yyyy-MM-dd HH:mm:ss'}}"
                        align="left">{{ event.handledBy | getKey: 'date' | units: { unit: 'time', variant: 'short' } }}</span>
                </span>
                <span id="info-small-text"
                    class="small muted">{{ago(event.handledBy.date)}}</span>
            </key-feed-entry-info>
            <div class="feed-entry-details">
                <div class="entry-title accent">{{ 'ALERTS.SUMMARY.ACKNOWLEDGED' | translate }}</div>
                <div class="entry-description">{{ 'ALERTS.SUMMARY.ACKNOWLEDGED_BY' | translate: { user: event.handledBy.user.name } }}</div>
            </div>
        </div>

        <div class="feed-entry"
            *ngFor="let comment of comments">
            <key-feed-entry-info [icons]="[commentIcon]"
                [indicators]="null">
                <span id="info-title">
                    <span kui-tooltip="{{comment | getKey: 'date' | date: 'yyyy-MM-dd HH:mm:ss'}}"
                        align="left">{{ comment | getKey: 'date' | units: { unit: 'time', variant: 'short' } }}</span>
                </span>
                <span id="info-small-text"
                    class="small muted">{{ago(comment.date)}}</span>
            </key-feed-entry-info>
            <div class="feed-entry-details">
                <div class="entry-title accent">{{ comment.user.name }}</div>
                <div class="entry-description">
                    <div *ngFor="let value of comment.values">
                        <strong>{{ value.title }}: </strong>{{ value.value }}
                    </div>
                    <div *ngIf="comment.text">{{ comment.text }}</div>
                </div>
            </div>
        </div>

        <div class="pt-3 pb-3 text-right"
            *ngIf="showActions">

            <button class='ml-3'
                *ngIf="hasVideoRequest"
                kui-button
                color="secondary"
                (click)="onRequestVideoClicked.emit(event)">
                <span>{{ 'ALERTS.BUTTONS.VIDEO' | translate }}</span>
            </button>
            <button class='ml-3'
                kui-button
                color="secondary"
                (click)="onCommentClicked.emit(event)">
                <span>{{ 'ALERTS.BUTTONS.COMMENT' | translate }}</span>
            </button>
            <button class='ml-3'
                kui-button
                color="primary"
                *ngIf="!event.handledBy"
                (click)="onAcknowledgeClicked.emit(event)">
                <span>{{ 'ALERTS.BUTTONS.ACKNOWLEDGE' | translate }}</span>
            </button>
        </div>
    </div>
</div>