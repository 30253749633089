import { Injectable } from '@angular/core';
import { ZoneCreateRequest, ZoneResponse } from '@key-telematics/fleet-api-client';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'app/app.service';
import { AssetGroupingService, MeasurementUnitsService } from 'app/services';
import { FormBuilderDefinition, IdNameComboValueHandlers } from 'app/shared/components/form-builder';
import { Subject } from 'rxjs';
import { MapZonesService, ZoneProperties } from '../../map-zones.service';
import { Point } from '../../map.component';
import { ZoneEditorAction, ZoneType } from '../model';


export interface ZoneEditEvent {
    action: ZoneEditorAction;
    zoneId?: string;
}

export interface ZoneForm {
    form: FormBuilderDefinition
    values: ZoneProperties,
}

@Injectable()
export class ZoneEditorService {

    private static readonly ZONE_CREATION_ACTIONS: ZoneEditorAction[] = ['zoneaddpolygon', 'zoneaddpolyline']

    private _zoneAutoCompleteGeometry = new Subject<boolean>();
    private _zoneCompleteEditSubject = new Subject<boolean>();
    private _zoneEditCancelSubject = new Subject<boolean>();
    private _zoneEditUndoSubject = new Subject<boolean>();
    private _zoneEditEventSubject = new Subject<ZoneEditEvent>();

    zoneCreated$ = this.zoneService.zoneCreated$;
    polygonEdit$ = this.zoneService.polygonEdit$;
    zoneEditEvent$ = this._zoneEditEventSubject.asObservable();
    autoCompleteGeometry$ = this._zoneAutoCompleteGeometry.asObservable();
    zoneCompleteEdit$ = this._zoneCompleteEditSubject.asObservable();
    zoneEditCancel$ = this._zoneEditCancelSubject.asObservable();
    zoneEditUndo$ = this._zoneEditUndoSubject.asObservable();

    constructor(
        protected app: AppService,
        protected i18n: TranslateService,
        private grouping: AssetGroupingService,
        private units: MeasurementUnitsService,
        private zoneService: MapZonesService,
    ) { }

    fireEditEvent(editEvent: ZoneEditEvent) {
        this._zoneEditEventSubject.next(editEvent);
    }

    autoCompleteGeometry() {
        this._zoneAutoCompleteGeometry.next(true);
    }

    completeMapEdits() {
        this._zoneCompleteEditSubject.next(true);
    }

    cancelMapEditing() {
        this._zoneEditCancelSubject.next(true);
    }

    removeLastPoint() {
        this._zoneEditUndoSubject.next(true);
    }

    async getForm(zoneAction: ZoneEditorAction, zoneId?: string): Promise<ZoneForm> {
        // check if we're editing a zone or creating a new one
        if (!ZoneEditorService.ZONE_CREATION_ACTIONS.includes(zoneAction)) {
            // we are editing an existing zone
            const zone = await this.app.api.entities.getZone(zoneId);
            const values = {
                name: zone.name,
                zoneType: zone.zoneType,
                costCentre: zone.costCentre,
                group: zone.group,
                speed: zone.speed && this.units.fromBackend('speed', zone.speed, 0),
                radius: zone.radius && this.units.fromBackend('distance', zone.radius, 3),
            };
            const form = await this.getFormForType(zone.zoneType);
            return {
                values,
                form
            };
        } else {
            // we are creating a new zone
            const newZoneType = zoneAction === 'zoneaddpolygon' ? 'location' : 'route';
            const values: ZoneProperties = {
                zoneType: newZoneType
            };
            const form = await this.getFormForType(newZoneType);
            return {
                values,
                form
            };
        }
    }

    updateZone(zoneId: string, update: ZoneProperties): Promise<ZoneResponse> {
        return this.zoneService.updateZone(zoneId, update);
    }

    createNewZone(zoneName: string, points: Point[], propeties: ZoneProperties) {
        const request: ZoneCreateRequest = {
            ...propeties,
            ownerId: this.app.client.id,
            name: zoneName,
            points
        }   
        return this.zoneService.createZone(request);
    }

    private async getFormForType(zoneType: ZoneType): Promise<FormBuilderDefinition> {
        const [zoneGroups, costCentres] = await Promise.all([
            this.grouping.getZoneGroupsAsTree(),
            this.grouping.getCostCentresAsTree(),
        ]);
        const zoneTypes = ['location', 'keepin', 'nogo'].map(key => ({ key, value: this.i18n.instant(`SHARED.ZONE_TYPES.${key.toUpperCase()}`) }));
        const translate = (key: string) => this.i18n.instant(`ADMIN.EDITORS.ZONE.DETAILS.FIELDS.${key}`);
        const form = {
            groups: [{
                fields: [
                    {
                        id: 'name',
                        title: translate('NAME'),
                        type: 'text',
                        required: true,
                        min: 1,
                        max: 255,
                    },
                    zoneType === 'route' ? null : {
                        id: 'zoneType',
                        title: translate('TYPE'),
                        type: 'combo',
                        required: true,
                        values: zoneTypes
                    },
                    {
                        id: 'costCentre',
                        title: translate('COST_CENTRE'),
                        type: 'combo',
                        required: true,
                        values: AssetGroupingService.treeToComboValues(costCentres),
                        ...IdNameComboValueHandlers
                    },
                    {
                        id: 'group',
                        title: translate('GROUP'),
                        type: 'combo',
                        required: true,
                        values: AssetGroupingService.treeToComboValues(zoneGroups),
                        ...IdNameComboValueHandlers
                    },
                    zoneType === 'route' ? null : {
                        id: 'speed',
                        title: translate('SPEED'),
                        type: 'number',
                        required: false,
                        min: 0,
                        max: 1000,
                        unit: this.units.format(0, 'speed').unit,
                    },
                    zoneType === 'route' ? null : {
                        id: 'radius',
                        title: translate('RADIUS'),
                        type: 'number',
                        required: false,
                        min: 0,
                        max: 1000,
                        unit: this.units.format(0, 'distance').unit,
                    },
                ].filter(x => x),
            }],
        };
        return form;
    }
}
