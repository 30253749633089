import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
    selector: '[key-number-validator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: KeyNumberValidatorDirective, multi: true }],
})
export class KeyNumberValidatorDirective implements Validator {

    @Input() min: string | number;
    @Input() max: string | number;

    validate(control: AbstractControl): { [key: string]: any } {
        if (this.max !== undefined && this.max !== null && parseFloat(control.value) > parseFloat(this.max.toString())) {
            return { 'max': { value: control.value } };
        }
        if (this.min !== undefined && this.min !== null && parseFloat(control.value) < parseFloat(this.min.toString())) {
            return { 'min': { value: control.value } };
        }
        return null;
    }

}
