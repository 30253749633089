import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AnalyticsCellValue } from '../analytics.model';

@Component({
    selector: 'key-analytics-pivot-table',
    templateUrl: './pivot-table.component.html',
    styleUrls: ['./pivot-table.component.scss'],
})
export class AnalyticsPivotTableComponent {
    @Input() cellset: AnalyticsCellValue[][];
    @Input() selected: string;
    @Output() onHeaderClick = new EventEmitter<AnalyticsCellValue>();

    constructor(
    ) { }

    doOnHeaderClick(header: AnalyticsCellValue) {
        if (header.properties.color) {
            this.onHeaderClick.emit(header);
        }
    }

    convertToClassListString(classnames: string[] = []): string {
        return classnames.reduce((all, cur) => all + ' ' + cur, '');
    }
}
