import { Component, Input } from '@angular/core';
import { TreeFilterService, TreeNode } from '../tree-filter.service';

@Component({
    selector: 'key-tree-view',
    styleUrls: ['tree-view.component.scss'],
    templateUrl: 'tree-view.component.html',
})
export class KeyTreeViewComponent {

    @Input() nodes: TreeNode[];

    constructor(private treeFilterService: TreeFilterService) {}

    nodeChanged(node: TreeNode) {
        node.checked = !node.checked;
        this.treeFilterService.nodeChanged(node);
    }

}