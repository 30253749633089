<div class="form-control py-2 px-3"
    [class.border-danger]="error || (field.required && touched && !completed())">

    <!-- ACTOR -->
    <span *ngIf="hasActor">
        {{ 'SHARED.ALERTS.CONDITIONS.GENERAL.WHEN' | translate }}
        <kui-dropdown #actorDropdown
            [style]="'none'"
            [customContent]="actorDropdownContent"
            [customContentContext]="{ component: actorDropdown }">
            <a [class.unset]="!item.actor">{{ item.actor ? item.actor.text : 'SHARED.ALERTS.CONDITIONS.GENERAL.ACTOR' | translate }}&nbsp;</a>
        </kui-dropdown>
        <ng-template #actorDropdownContent
            let-component="component">
            <div style="min-width: 200px">
                <key-form-builder-eventfilter-searchable-tree entityType="asset"
                    [ownerId]="ownerId"
                    [nodes]="actorNodes"
                    (onChange)="onActorSelected(component, $event)"></key-form-builder-eventfilter-searchable-tree>
            </div>
        </ng-template>
    </span>

    <!-- ACTION -->
    <span>
        <kui-dropdown #actionDropdown
            [style]="'none'"
            [customContent]="actionDropdownContent"
            [customContentContext]="{ component: actionDropdown }">
            <a [class.unset]="!item.action">{{ item.action ? item.action.text : 'SHARED.ALERTS.CONDITIONS.GENERAL.ACTION' | translate }}&nbsp;</a>
        </kui-dropdown>
        <ng-template #actionDropdownContent
            let-component="component">
            <div style="min-width: 200px">
                <kui-tree-select [nodes]="actionNodes"
                    (selectedNodeChanged)="onActionSelected(component, $event)"></kui-tree-select>
            </div>
        </ng-template>
    </span>

    <!-- TARGET -->
    <span *ngIf="item.action && item.action.targetType">
        <kui-dropdown #targetDropdown
            [style]="'none'"
            [customContent]="targetDropdownContent"
            [customContentContext]="{ component: targetDropdown }">
            <a [class.unset]="!item.target">{{ item.target ? item.target.text : ('SHARED.ALERTS.CONDITIONS.GENERAL.TARGET' | translate) }}&nbsp;</a>
        </kui-dropdown>
        <ng-template #targetDropdownContent
            let-component="component">
            <div style="min-width: 200px">
                <key-form-builder-eventfilter-searchable-tree [entityType]="item.action.targetType"
                    [ownerId]="ownerId"
                    [nodes]="targetNodes"
                    (onChange)="onTargetSelected(component, $event)"></key-form-builder-eventfilter-searchable-tree>
            </div>
        </ng-template>
    </span>

    <div class="pl-3">

        @for (condition of item.conditions; track condition.text) {
            <key-form-builder-eventfilter-condition 
                [filterCondition]="condition"
                [ownerId]="ownerId"
                (removed)="onRemoveCondition($event)"
                (changed)="changed()">
            </key-form-builder-eventfilter-condition>
        }

        @if(conditionNodes) {   
            <div class="pt-2">
                <kui-dropdown #conditionDropdown
                    [style]="'none'"
                    [customContent]="conditionDropdownContent"
                    [customContentContext]="{ component: conditionDropdown }">
                    <a class="unset">
                        {{ item?.conditions?.length | conditionPrefix }}...
                    </a>
                </kui-dropdown>
                <ng-template #conditionDropdownContent
                    let-component="component">
                    <div style="min-width: 200px">
                        <kui-tree-select [nodes]="conditionNodes"
                            (selectedNodeChanged)="onConditionSelected(component, $event)"></kui-tree-select>
                    </div>
                </ng-template>
            </div>
        }
    </div>

</div>

<div class="d-flex">

    <div class="flex-1 invalid-feedback d-block">
        <div *ngIf="field.required && touched && !completed()">{{ 'FORMS.SHARED.INCOMPLETE' | translate:{ field: field.title } }}</div>
        <div *ngIf="error">{{ error }}</div>
    </div>

</div>