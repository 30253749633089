import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild, input } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { KuiModule } from 'app/key-ui';
import { TripsService } from 'app/services';
import { TripItem } from 'app/services/trips/trips.service';
import {
    KeyMapModule,
    MapComponent,
    MapControls,
    MapTripService
} from 'app/shared/components';
import { SelectionItem } from 'app/shared/components/selection/selection.model';
import { ReplaySubject, filter, takeUntil } from 'rxjs';
import { PlaybackSliderComponent } from '../playback-slider/playback-slider.component';

@Component({
    selector: 'key-trip-map',
    templateUrl: 'trip-map.component.html',
    styleUrls: ['trip-map.component.scss'],
    imports: [FormsModule, KuiModule, CommonModule, PlaybackSliderComponent, KeyMapModule],
    providers: [TripsService],
    standalone: true,
})
export class TripMapComponent implements OnInit, OnDestroy {

    asset = input.required<SelectionItem>();
    trip = input.required<TripItem>();

    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    mapControls = [
        MapControls.zoom,
        MapControls.fullscreen,
        MapControls.recenter
    ]

    @ViewChild('map', { static: true }) map: MapComponent;

    constructor(
        public tripService: MapTripService
    ) {
        toObservable(this.trip).pipe(
            filter((trip) => !!trip),
            takeUntil(this.destroyed$)
        ).subscribe(trip => {
            this.tripService.setAsset({ id: this.asset().id, color: this.asset().color });
            this.tripService.setTripsTelemetry([
                {
                    id: trip.id,
                    telemetry: trip.telemetry,
                    events: trip.events,
                }
            ]);
        });
    }

    ngOnInit() {
        this.map.disableMarkerClustering();
        this.tripService.attachMap(this.map);
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

}
