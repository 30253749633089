<div class="map-toolbar"
    [class.can-toggle]="canToggle">
    <!-- MAIN ICONS -->
    <div class="map-toolbar__icons">
        <div class="d-flex align-items-center"
            *ngIf="compact && expanded && (current$ | async)"
            kui-action
            (click)="onBack()">
            <kui-icon
                name="chevron-left"
                class="map-toolbar__icons__icon"
                [size]="1"
                [title]="'SHARED.BACK' | translate">
            </kui-icon>
            <span class="pl-1 map-toolbar__text">{{'SHARED.BACK' | translate}}</span>
        </div>

        <span
            *ngIf="controlsIndex.recenter"
            class="map-toolbar__text"
            (click)="reset.emit()"
            kui-action>
            {{'LEAFLET.RESET_MAP' | translate}}
        </span>
        <ng-container *ngIf="controlsIndex.zoom">
            <kui-icon name="minus"
                kui-action
                class="map-toolbar__icons__icon"
                (click)="zoomOut.emit()"
                [size]="1"
                [title]="'LEAFLET.ZOOM.OUT' | translate"></kui-icon>
            <kui-icon name="plus"
                kui-action
                class="map-toolbar__icons__icon"
                (click)="zoomIn.emit()"
                [size]="1"
                [title]="'LEAFLET.ZOOM.IN' | translate"></kui-icon>
        </ng-container>

        <kui-icon *ngIf="controlsIndex.fullscreen"
            [name]="isFullscreen ? 'compress' : 'expand'"
            kui-action
            class="map-toolbar__icons__icon"
            (click)="isFullscreen ? exitFullscreen.emit() : enterFullscreen.emit()"
            [size]="1"
            [title]="(isFullscreen ? 'LEAFLET.FULLSCREEN.EXIT' : 'LEAFLET.FULLSCREEN.ENTER') | translate"></kui-icon>
    </div>

    <!-- TOGGLE -->
    <div class="map-toolbar__toggle"
        (click)="toggle(!expanded)">
        <kui-icon *ngIf="expanded"
            kui-action
            name="chevron-up"
            [title]="'LEAFLET.VIEW_LESS' | translate"
            [size]="1"></kui-icon>
        <kui-icon *ngIf="!expanded"
            kui-action
            name="bars"
            [title]="'LEAFLET.VIEW_MORE' | translate"
            [size]="1"></kui-icon>
    </div>
</div>

<key-map-options
    [ngClass]="{'hidden': !expanded}"
    [compact]="compact"
    [options]="customOptions"
    [search]="controlsIndex.search"
    [routing]="controlsIndex.routing">
</key-map-options>