import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KuiActionComponent } from './action.component';
import { KuiActionDirective } from './action.directive';
import { MatchMediaServiceModule } from 'app/services/match-media/match-media.module';

const COMPONENTS = [KuiActionComponent, KuiActionDirective];

@NgModule({
    imports: [CommonModule, MatchMediaServiceModule],
    exports: COMPONENTS,
    declarations: COMPONENTS
})
export class KuiActionModule { }
