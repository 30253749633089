<ng-container *ngIf="{
        current: current$ | async
    } as data">

    <div class="map-options__header" *ngIf="!compact">
        <div class="map-options__header__back d-flex align-items-center"
            *ngIf="data.current"
            kui-action
            (click)="onBack()">
            <kui-icon
                name="chevron-left"
                class="map-options__header__icon"
                size="sm"
                [title]="'SHARED.BACK' | translate">
            </kui-icon>
            <span class="pl-1">{{'SHARED.BACK' | translate}}</span>
        </div>
        <h5 class="map-options__header__title">
            {{(data.current?.title || 'LEAFLET.MAP_OPTIONS.TITLE') | translate}}
        </h5>
        <kui-icon
            kui-action
            name="close"
            class="map-options__header__exit map-options__header__icon"
            size="sm"
            [title]="'DIALOG.CLOSE' | translate"
            (click)="onExit()">
        </kui-icon>
    </div>

    <key-map-search-input
        *ngIf="search && (data.current | showSearchInput)"
        class="map-options__search"
        [ngClass]="{'compact': compact}"
        [border]="!compact"
        [routing]="routing">
    </key-map-search-input>

    <key-map-options-list
        *ngIf="!data.current"
        [options]="options"
        [compact]="compact">
    </key-map-options-list>

</ng-container>

<ng-template mapOption></ng-template>