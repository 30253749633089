import { Component, Input, Output, EventEmitter, Renderer2, ElementRef } from '@angular/core';

@Component({
  selector: 'kui-modal-body',
  templateUrl: './modal-body.component.html',
})
export class KuiModalBodyComponent {
    @Input() hasCloseButton: boolean;
    @Output() close: EventEmitter<any> = new EventEmitter();

    constructor(private renderer: Renderer2, private el: ElementRef) {
        this.renderer.addClass(this.el.nativeElement, 'modal-body');
    }
}
