import { TranslateService } from '@ngx-translate/core';


export function parseUserNotification(i18n: TranslateService, eventType: string, data: any): { title: string, description: string, url: string, icon: string } {
    const result = {
        title: i18n.instant(`SHARED.FEED_ENTRY.NOTIFICATION.${eventType.replace(/\//g, '_').toUpperCase()}.TITLE`),
        description: i18n.instant(`SHARED.FEED_ENTRY.NOTIFICATION.${eventType.replace(/\//g, '_').toUpperCase()}.DESCRIPTION`, data),
    };
    switch (eventType) {
        case 'report/completed':
            return { ...result, icon: 'file-alt', url: `/reporting/queued/now/viewer/${data.id}` };
        default:
            return null;
    }
}
