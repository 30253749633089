import { Component, Input } from '@angular/core';

import { MapOptionService } from '../../map-option.service';
import { MapOption } from '../map-option';

@Component({
    selector: 'key-map-options-list',
    styleUrls: ['./map-option-list.component.scss'],
    templateUrl: './map-option-list.component.html',
})
export class MapOptionListComponent {
    @Input() options: MapOption<any>[];
    @Input() compact: boolean;

    constructor(private optionService: MapOptionService) { }

    load<T>(option: MapOption<T>) {
        this.optionService.load(option);
    }

}

