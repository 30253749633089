import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    KuiCard,
    KuiCardHeader,
    KuiCardBody,
    KuiCardFooter,
    KuiCardJumbotron,
    KuiCardActions
} from './card.component';
import { KuiCardTitleDirective, KuiCardSubTitleDirective, KuiCardTextDirective } from './card.directive';
import { KuiCardService } from './card.service';
import { KuiIconModule } from './../icon';

const KUI_CARD_MODULES = [
  KuiCard,
  KuiCardActions,
  KuiCardHeader,
  KuiCardBody,
  KuiCardFooter,
  KuiCardJumbotron,
  KuiCardTitleDirective,
  KuiCardSubTitleDirective,
  KuiCardTextDirective,
];

@NgModule({
  imports: [CommonModule, KuiIconModule],
  exports: KUI_CARD_MODULES,
  declarations: KUI_CARD_MODULES,
  providers: [KuiCardService],
})
export class KuiCardModule {}
