import { Injectable } from '@angular/core';
import { AnalyticsChartData } from '../chart.component';
import { YAxes, Legend, Average, LegendPosition, XAxis, Label } from 'app/shared/components/graph/graph.model';
import { AnalyticsChartService } from '../chart.service';
import { AnalyticsService } from '../../services/analytics.service';
import { AnalyticsCellValue, YAxisOptions, KeyValue } from '../../analytics.model';
import { AnalyticsOutputSettings } from '@key-telematics/fleet-api-client';
import { removeSpaces } from 'app/shared/utils/string.utils';

export interface AnalyticsChartGraphData extends AnalyticsChartData {
    labels: Label[];
    yAxes: YAxes;
    xAxis: XAxis;
    stacked: boolean;
    legend: Legend;
    averages: Average[];
    showLabelsOnGraph: boolean;
}

@Injectable()
export class AnalyticsChartGraphService extends AnalyticsChartService<AnalyticsChartGraphData> {
    constructor(public analytics: AnalyticsService) {
        super(analytics);
    }

    convertToTime(time: number): string {
        const secs = time * 60 * 60; // analytics time is in hours. 
        const trim = num => ('0' + num).slice(-2);
        const minutes = Math.floor(secs / 60);
        const hours = Math.floor(minutes / 60);
        return `${hours}:${trim(minutes % 60)}:${trim(secs % 60)}`;
    }

    getData(cellset: AnalyticsCellValue[][], settings: AnalyticsOutputSettings): AnalyticsChartGraphData {
        const graph = this.getGraphDataFromCellset(cellset, settings);

        const { yAxis, stacked, label, series, xAxis, dataLabels } = settings.graph;
        const { averages, datasets, labels, yAxesOptions } = graph;

        return {
            averages,
            datasets,
            labels,
            yAxes: this.getYAxes(series, settings.table.measures, {
                left: { ...yAxis.left, timeLabel: yAxesOptions.left.timeLabels },
                right: { ...yAxis.right, timeLabel: yAxesOptions.right.timeLabels },
            }),
            xAxis: {
                autoLabels: xAxis.labels && xAxis.labels.layout === 'auto',
            },
            stacked,
            legend: {
                show: label.visible,
                position: label.position as LegendPosition,
                overlay: label.overlay,
            },
            showLabelsOnGraph: dataLabels,
        };
    }

    getYAxes(series: { [key: string]: any; }, measures: KeyValue[], yAxes: { left: YAxisOptions, right: YAxisOptions }): YAxes {
        const rest = { ...series };
        delete rest.default;

        // if the table.measures is the same length as series items without default, ignore default
        // else check what default's axis is as well and add that to the list
        const shouldIncludeDefault = Object.keys(rest).filter(x => measures.find(({ value }) => x === removeSpaces(value && value.toLowerCase()))).length !== measures.length;
        const arr = Object.entries(shouldIncludeDefault ? series : rest).filter(([key]) => {
            return measures.find(({ value }) => key === removeSpaces(value && value.toLowerCase())) || key === 'default';
        });

        return Object.entries(yAxes)
            .reduce((result, [key, { min, max, timeLabel }]) => {
                return arr.some(([_key, value]) => value.yAxis === key) ? {
                    ...result,
                    [key]: {
                        min: +min || 0,
                        max: +max || undefined,
                        labelFn: timeLabel ? this.convertToTime : null,
                    },
                } : result;
            }, {} as YAxes);
    }
}
