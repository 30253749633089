<kui-content-header [custom]="true"
    [sub]="true"
    class="datetime-bar no-border-top-nested">
    <span class="position-relative">
        <kui-icon kui-action
            [class.invisible]="!hasPrevDay"
            class="pl-3 pr-3"
            name="chevron-left"
            (click)="selectPreviousDate()"></kui-icon>
    </span>
    <div class="flex-stretch d-flex justify-content-center"
        *ngIf="!localDate">
        <div kui-button
            color="none"
            (click)="doDateSelection('today')">
            {{ currentString }}
            <kui-icon class="pl-2"
                name="calendar"></kui-icon>
        </div>
    </div>
    <div class="flex-stretch d-flex justify-content-center align-items-center"
        *ngIf="localDate">
        <kui-datetime type="date"
            (onChange)="doDateSelection($event)"
            inputStyle="none"
            [date]="localDate"
            [min]="minDate"
            [max]="maxDate"></kui-datetime>
        <ng-container *ngIf="!!shiftsMenu?.length">
            <span class="mx-2">|</span>
            <kui-dropdown [style]="'none'"
                [menu]="shiftsMenu">
                <span>{{ selectedShiftName }}</span>
                <kui-icon kui-action
                    class="ml-2"
                    name="business-time"></kui-icon>
            </kui-dropdown>
        </ng-container>
        <kui-icon kui-action
            class="pl-2"
            name="times"
            (click)="doDateSelection(clearButtonArgument || null)"></kui-icon>
    </div>
    <span class="position-relative">
        <kui-icon kui-action
            class="pl-3 pr-3"
            [class.invisible]="!hasNextDay"
            name="chevron-right"
            (click)="selectNextDate()"></kui-icon>
    </span>
</kui-content-header>