import { Component, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { KeyValue } from '../../analytics.model';
import { AppService } from 'app/app.service';
import { CompletedReportResponse } from '@key-telematics/fleet-api-client';
import { Router } from '@angular/router';

export interface SortSettings {
    measures: KeyValue[];
    type: 'alphabetic' | 'numeric';
    measureKey?: string;
    direction?: 'asc' | 'desc';
    limit?: number;
}

@Component({
    selector: 'key-analytics-settings-info',
    templateUrl: './info.component.html',
})
export class AnalyticsSettingsInfoComponent implements OnInit, OnChanges {

    @Input() reportId: string;

    report: CompletedReportResponse;
    baseReportId: string;

    constructor(
        private app: AppService,
        private router: Router,
    ) {
    }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.reportId) {
            this.loadReport(this.reportId);
        }
    }

    async loadReport(id: string) {
        try {
            this.report = await this.app.api.reports.getCompletedReport(id);
            this.baseReportId = this.report['baseReportId'];
        } catch (err) {
            console.error(err);
            this.report = null;
        }
    }

    navigateToReport() {
        this.router.navigate(['reporting', 'queued', 'now', 'viewer', this.report.id]);
    }

    navigateToBaseReport() {
        this.router.navigate(['admin', 'client', this.app.client.id, 'analyticsbasereports', 'grid', 'analyticsbasereport', this.baseReportId]);
    }

    rerunReport() {
        this.router.navigate(['reporting', 'run', 'now', 'builder', `${this.report.config.definitionId}-${this.report.config.styleId || 'analytics'}`], { queryParams: { source: this.report.id } });
    }

}
