import { Component, EventEmitter, HostBinding, Input, OnChanges, Output } from '@angular/core';
import { GetKeyPipe } from 'app/shared/pipes/getkey.pipe';
import { MediaService } from 'app/services/media/media.service';
import { AppService } from 'app/app.service';
import { MediaInfoResponse } from '@key-telematics/fleet-api-client';
import { TranslateService } from '@ngx-translate/core';
import { FilterPanelService } from 'app/shared/components/filter-panel/filter-panel.service';
import * as moment from 'moment-timezone';

@Component({
    selector: 'key-video-list-item',
    templateUrl: 'list-item.component.html',
    styleUrls: ['./list-item.component.scss'],
})
export class VideoListItemComponent implements OnChanges {

    title: string;
    description: string;
    thumbnailUrls: string[];
    values: { title: string, value: string }[] = [];

    @Input() data: MediaInfoResponse;
    @Input() showFilterByValue = true;
    @Output() selected: EventEmitter<MediaInfoResponse> = new EventEmitter();
    
    @HostBinding('class.muted') muted = false;

    info: { type: string, title: string }[] = [];

    layout = 'default';
    classNames: string[] = [];

    constructor(
        private app: AppService,
        public getKey: GetKeyPipe,
        private i18n: TranslateService,
        private filterService: FilterPanelService,
        private media: MediaService
    ) {
        if (this.app?.features?.page?.overview?.layout === 'basic') {
            this.layout = 'basic';
            this.classNames.push('layout-right');
        }
    }

    ngOnChanges() {
        if (this.data) {
            const by = this.data.status && this.data.status + 'By';
            const byUser = ['deleted', 'canceled'].includes(this.data.status) ? ' by ' + this.data.data[by].name : '';
            
            this.info = [
                { type: 'status', title: this.i18n.instant('MEDIA.STATUS.TITLE.' + this.data.status?.toUpperCase()) + byUser },
                this.data.input && { type: 'input', title: this.data.input },
                this.data.requester && { type: 'requester', title: this.i18n.instant('VIDEOS.LIST.REQUESTED_BY', { requester: this.data.requester.name }) },
                this.data.eventClass && {
                    type: 'eventClass', 
                    title: this.i18n.instant('VIDEOS.LIST.ATTACHED_TO', {
                        event: this.i18n.instant('SHARED.EVENTS.' + this.data.eventClass.toUpperCase() + '.NAME'),
                    }),
                },
            ].filter(x => x);

            this.thumbnailUrls = [this.media.getThumbnailsUrl(this.data.asset.id, {
                date: this.data.date,
                filename: this.data.filename,
                input: this.data.input,
                mediaType: 'video',
            })];

            this.title = this.data.asset.name;
            this.description = this.data.address;

            const customFields = this.app.client?.customFields?.event || [];
            const rawValues = {};

            this.values = Object.keys(this.data.fields || {}).map(key => ({
                title: customFields.find(x => x.id === key)?.title || key,
                value: rawValues[key],
            }));

        }
    }

    getDisplayDate(date: string): string {
        return date ? moment.utc(date).fromNow() : '';
    }

    filterBy(event: Event, type: string) {
        event.stopPropagation();
        switch (type) {
            case 'asset': {
                this.filterService.addFilterValues('assets', [this.data.asset]);
                break;
            }
            case 'requester': {
                this.filterService.addFilterValues('requester', [this.data.requester]);
                break;
            }
            case 'status': {
                this.filterService.addFilterValues('status', [{ id: this.data.status, name: this.i18n.instant('MEDIA.STATUS.TITLE.' + this.data.status?.toUpperCase()) }]);
                break;
            }
            case 'eventClass': {
                this.filterService.addFilterValues('eventClass', [{ id: this.data.eventClass, name: this.i18n.instant('SHARED.EVENTS.' + this.data.eventClass.toUpperCase() + '.NAME') }]);
                break;
            }
            case 'input': {
                this.filterService.addFilterValues('input', [{ id: this.data.input, name: this.data.input }]);
                break;
            }

        }
    }

}

